import React, { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import moment from 'moment';

export const CustomDatePicker = ({
  onChange,
  minDate,
  maxDate,
  label,
  required,
  fieldValue,
  disabled,
  className,
  disablePast
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const minLDate = minDate;
  const maxLDate = maxDate;
  const requiredField = required || false;

  const handleDateChange = (date) => {
    if (date) {
      let formatDate = new Date(date);
  
      // Use Moment.js to format the date to 'YYYY-MM-DD'
      let formattedDate = moment(formatDate).format('YYYY-MM-DD');
  
      setSelectedDate(formattedDate);
      onChange(formattedDate); // Call the onChange callback with the selected date
    }
  };
  

  useEffect(() => {
    if (fieldValue) {
      let formated = fieldValue.replaceAll('-','/')
      setSelectedDate(formated);
    }
  }, [fieldValue]);

  const handleInputChange = (event) => {
    // event.preventDefault();
    // setOpen(true);
    if (!disabled) {
      event.preventDefault();
      setOpen(true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={selectedDate}
        disablePast={disablePast}
        onChange={handleDateChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            sx={{ width: '100%' }}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true
            }}
            InputLabelProps={{
              className: `label-style-create-franchisee ${
                requiredField ? 'required-label' : ''
              }`
            }}
            className={`custom-datepicker input-field-styling ${className} ${
              requiredField ? 'required-field' : ''
            }`}
            onClick={handleInputChange}
          />
        )}
        inputProps={{ readOnly: true }} // Make the input field read-only
        PopperProps={{
          // disablePortal: true // Prevent the Popper component from rendering in a portal
        }}
        minDate={minLDate} // Minimun selectable date
        maxDate={maxLDate} // Maximum selectable date
        open={open}
        onClose={() => setOpen(false)}
        inputFormat="MM-dd-yyyy"
      />
    </LocalizationProvider>
  );
};
