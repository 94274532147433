import React, {useState, useEffect} from 'react';
// import {HeaderContext} from 'src/contexts/HeaderContext';
import {useNavigate} from 'react-router-dom';
import {
    Button,    
    Container,
    Grid,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    CardHeader,Card
  } from '@mui/material';
  
  import Loader from '../../../components/Loader';

function Marketing() { 
  // let headerelm = document.getElementsByClassName('add-btn-container')[0];
  // if(headerelm) headerelm.style.visibility = 'hidden';
  // set the header text
  // const {setHeaderText} = useContext(HeaderContext);
  // setHeaderText('Marketing Management');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Marketing Management';

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);  
  
  const getSegments = async () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllSegments`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => { 
        setLoading(false);       
          let Item = data.SegmentsResponse.Item ;
          setData(Item);      
        
      });
  };

  const navigateCampaign = () => {
        // navigate to /contacts
        navigate('/campaign');
  }

  useEffect(() => {
    // Fetch data from Pipoint API
    getSegments();
  }, []);

  return (
    <>
    <Loader IsLoading={loading} />
      <Container maxWidth="100%" className='main-container'>
        <Grid container justifyContent="end" alignItems="center" className='add-btn-container'>
          <Grid item>
            <Button
              sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
              variant="contained"
              className='btn-primary' 
              onClick={navigateCampaign}
            >
              Campaigns
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 0, md: 0 }, border: '2px solid #003087 !important', color: '#003087 !important', padding: '6px 20px !important' }}
              variant="outlined"
              className='btn-primary'
              disabled
            >
              Manage
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >         
        
          <Grid item width={'100%'}>
          <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}> 
          <CardHeader
              // action={
              // }
              title="Cohorts"
              className="table-header-name"
              sx={{ fontSize: '20px', color: '#003087' }}
            />            
                <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Last Modified Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
             

                  {data.map(segment => (
                    <TableRow key={segment.Id}>
                    <TableCell>{segment.Name}</TableCell>
                    <TableCell>{segment.SegmentType}</TableCell>
                    <TableCell>{segment.LastModifiedDate}</TableCell>
                  </TableRow>
                    ))}                  
                
              </TableBody>
            </Table>
          </TableContainer>
          </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Marketing;