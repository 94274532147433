import React, { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import moment from 'moment';

export const CustomDatePickerForAllBrowser = ({
  onChange,
  minDate,
  maxDate,
  label,
  required,
  fieldValue,
  disabled,
  className,
  disablePast
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = React.useState(false);
  const minLDate = minDate;
  const maxLDate = maxDate;
  const requiredField = required || false;

  const formatDateForComparison = (dateString) => {
    // Parse the input date string and format it to 'YYYY-MM-DDTHH:mm:ss'
    return moment(dateString, 'MM-DD-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss');
  };

  const handleDateChange = (date) => {
    if (date) {
      let formatDate = new Date(date);
      /* Date format you have */
      // let dateMDY = `${formatDate.getMonth() + 1}-${formatDate.getDate()}-${formatDate.getFullYear()}`;
      let month = formatDate.getMonth() + 1;
      let day = formatDate.getDate();
      let year = formatDate.getFullYear();

      // Add leading zeros to month and day if needed
      let formattedMonth = month.toString().padStart(2, '0');
      let formattedDay = day.toString().padStart(2, '0');

      let dateMDY = `${formattedMonth}-${formattedDay}-${year}`;
      /* Date converted to MM-DD-YYYY format */

      setSelectedDate(date);
      onChange(dateMDY); // Call the onChange callback with the selected date
    }
  };

  useEffect(() => {
    if (fieldValue) {
      setSelectedDate(formatDateForComparison(fieldValue));
    }
  }, [fieldValue]);

  const handleInputChange = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={selectedDate}
        disablePast={disablePast}
        onChange={handleDateChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            sx={{ width: '100%' }}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true
            }}
            InputLabelProps={{
              className: `label-style-create-franchisee ${
                requiredField ? 'required-label' : ''
              }`
            }}
            className={`custom-datepicker input-field-styling ${className} ${
              requiredField ? 'required-field' : ''
            }`}
            onClick={handleInputChange}
          />
        )}
        inputProps={{ readOnly: true }} // Make the input field read-only
        PopperProps={
          {
            // disablePortal: true // Prevent the Popper component from rendering in a portal
          }
        }
        minDate={minLDate} // Minimun selectable date
        maxDate={maxLDate} // Maximum selectable date
        open={open}
        onClose={() => setOpen(false)}
        inputFormat="MM-dd-yyyy"
      />
    </LocalizationProvider>
  );
};
