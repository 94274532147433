import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, Box, CircularProgress } from '@mui/material';
import { ApiService } from 'src/services/ApiService';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { useChat } from './context/ChatContext';
import Message from './MessagesTemplates';
import { LoadingButton } from '@mui/lab';

function AllChats() {
  const BASE_URL = process.env.REACT_APP_CHAT_API_END_POINT;

  const locationId = getLocalData('locationId');
  const locationName = getLocalData('locationName');
  const twilioNumber = getLocalData('twilioNumber');
  const userId = getLocalData('userId');
  const roleName = getLocalData('userRole');
  const franchiseeId = getLocalData('franchiseeId');

  const { selectedSearchClient, allUsersChat, setAllUsersChat, isConnected } =
    useChat();

  const textboxRef = useRef(null);
  const scrollableRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [staffProfileData, setStaffProfileData] = useState();

  useEffect(() => {
    fetchUsersChat();
    getStaffProfile();
  }, [selectedSearchClient]);

  // Method to get the staff profile details
  const getStaffProfile = async () => {
    setIsLoading(true);

    let requestData = {
      userId: userId,
      role: roleName,
      ...(roleName === 'Owner' && { franchiseeId: franchiseeId }),
      ...(roleName === 'Manager' && { locationId: locationId })
    };

    let url = `${process.env.REACT_APP_API_END_POINT}staff/getUserDetailsData`;
    try {
      let response = await ApiService.post(url, requestData);
      setStaffProfileData(response?.data?.Items[0] ?? []);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  // Method to fetch the chat between users
  const fetchUsersChat = async () => {
    const cleanedPhoneNumber = selectedSearchClient?.mobilePhone
      ? `+1${selectedSearchClient?.mobilePhone.replace(/-/g, '')}`
      : selectedSearchClient?.recipientNumber
      ? selectedSearchClient?.recipientNumber
      : selectedSearchClient?.to
      ? selectedSearchClient?.to
      : '';

    const cleanedTwilioNumber = twilioNumber?.includes('-')
      ? `+1${twilioNumber.replace(/-/g, '')}`
      : twilioNumber;

    setIsLoading(true);
    try {
      const response = await ApiService.get(
        `${BASE_URL}getAllChats?recipientNumber=${encodeURIComponent(
          cleanedPhoneNumber
        )}&limit=100&senderNumber=${encodeURIComponent(
          cleanedTwilioNumber
        )}&locationId=${encodeURIComponent(locationId)}`
      );
      setAllUsersChat(response?.data ?? []);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  // Method to generate client name
  const getClientName = useCallback(
    () =>
      selectedSearchClient?.clientName ||
      `${selectedSearchClient?.firstName || ''} ${
        selectedSearchClient?.lastName || ''
      }`.trim() ||
      '',
    [selectedSearchClient]
  );

  // Method to send the message
  const addMessage = async () => {
    const messageText = textboxRef.current?.value;
    if (!messageText) return;
    if (!selectedSearchClient?.sortKey && !selectedSearchClient?.clientId) {
      setShowMessage(true);
      setErrorMessage('clientId or sortKey not present of this user!');
      return;
    }
    if (!isConnected) {
      setShowMessage(true);
      setErrorMessage('WebSocket not connected!');
      return;
    }
    setIsSending(true);

    const cleanedPhoneNumber = selectedSearchClient?.mobilePhone
      ? `+1${selectedSearchClient?.mobilePhone?.replace(/-/g, '')}`
      : selectedSearchClient?.recipientNumber
      ? selectedSearchClient?.recipientNumber
      : selectedSearchClient?.to
      ? selectedSearchClient?.to
      : '';

    const cleanedTwilioNumber = twilioNumber?.includes('-')
      ? `+1${twilioNumber.replace(/-/g, '')}`
      : twilioNumber;

    let messagePayload = {
      message: messageText ?? '',
      recipientNumber: cleanedPhoneNumber,
      senderNumber: cleanedTwilioNumber ?? '',
      locationId: locationId ?? '',
      locationName: locationName ?? '',
      clientId: selectedSearchClient?.clientId || '',
      clientName: getClientName(),
      createdBy: userId ?? ''
    };

    if (isFirstMessage) {
      messagePayload = {
        ...messagePayload,
        profileImage: selectedSearchClient?.profileImage
      };
    }

    try {
      const response = await ApiService.post(
        `${BASE_URL}sendMessage`,
        messagePayload
      );
      if (response?.statusCode === 200 && !response?.error) {
        setAllUsersChat((prev) => [...prev, messagePayload]);

        if (isFirstMessage) {
          setIsFirstMessage(false);
        }

        textboxRef.current.value = '';
        scrollToBottom();
      }
    } catch (error) {
      console?.log(error);
      setIsSending(false);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Internal Server Error');
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addMessage();
    }
  };

  const scrollToBottom = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollToBottom();
    }
  };

  if (isLoading) {
    return (
      <div className="chat-loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box display={'flex'} flexDirection={'column'} height={547}>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <Message
        allUsersChat={allUsersChat}
        scrollableRef={scrollableRef}
        staffProfileData={staffProfileData}
      />

      <Box px={4} py={1}>
        <Grid container>
          <Grid item xs={10}>
            <input
              id="inputname"
              className="e-input"
              ref={textboxRef}
              type="text"
              placeholder="Type a message"
              onKeyDown={handleKeyPress}
            />
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              id="btn"
              variant="text"
              onClick={addMessage}
              loading={isSending}
              size="small"
              sx={{
                color: '#000',
                background: '#eee',
                boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.15)'
              }}
            >
              SEND
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AllChats;
