import { ApiService } from '../../../services/ApiService';
import { getLocalData } from '../../../utils';

const franchisorId = 'ZOR#1';
let franchiseeName = '';

const getFranchiseeName = async () => {
  try {
    let response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}franchisees/getFranchiseeDetail?franchiseeId=${encodeURIComponent(getLocalData('franchiseeId'))}`);
    let Item = response && response.data && response.data.Items ? response.data.Items : [];
    franchiseeName = Item.franchiseeName
  } catch (error) {
    console.error(error)
  };
};

function MM_DD_YYYY(forDate) {
  const dateString = forDate;
  const date = new Date(dateString);
  const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

export const getCashBoxData = async (startDate, endDate, setLoading, setShowErrorMessage, setErrorMessage, setTableData, setBalance) => {
  setLoading(true)
  try {
    let response = await ApiService.get(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}cash-reconcile/_transaction-list?locationId=${encodeURIComponent(getLocalData('locationId'))}&startDate=${MM_DD_YYYY(startDate)}&endDate=${MM_DD_YYYY(endDate)}`);
    if (response.statusCode && !response.error) {
      setLoading(false)
      setTableData(response?.data)
      let totalBalance = response?.data?.balance
      setBalance(totalBalance)
    }
  } catch (error) {
    setShowErrorMessage(true)
    setErrorMessage(error.message || 'Something went wrong.')
    setLoading(false)
    if (startDate !== null && endDate !== null) {
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
      }, 500);
    }
  }
};

export const Transaction = async (amount, type, setLoading, setOpen, setShowSuccessMessage, setSuccessMessage, setShowErrorMessage, setErrorMessage) => {
  setLoading(true)
  const forType = type === 'Add Amount' ? 'credit' : 'debit'
  await getFranchiseeName();
  let data = {
    franchisorId: franchisorId,
    locationId: getLocalData('locationId'),
    locationName: getLocalData('locationName'),
    franchiseeId: getLocalData('franchiseeId'),
    franchiseeName: franchiseeName,
    createdBy: getLocalData('userId'),
    createdByName: getLocalData('UserName')
  }
  if (type === 'Add Amount') {
    data['deposit'] = amount;
  } else {
    data['withdrawal'] = amount;
  }
  try {
    let response = await ApiService.post(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}cash-reconcile/_${forType}-payment`, data);
    if (response.statusCode && !response.error) {
      setShowSuccessMessage(true)
      setSuccessMessage(response.message)
      setLoading(false)
      setOpen(false)
    }
  } catch (error) {
    setShowErrorMessage(true)
    setErrorMessage(error.message)
    setLoading(false)
    setOpen(false)
  };
};