import React from 'react';
import { Container, Grid, Card, CardMedia, CardHeader } from '@mui/material';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';

import { LineGraphDashboardWidgets } from '../../../components/MainPageComponents';
import { DashboardCardComponent } from '../../../components/DashboardCardComponent';

const PosDashboard = () => {
  // let navigate = useNavigate();
  // const buttons = [
  //   {
  //     label: 'Check Out'
  //     // onClick: handleButtonClick,
  //   },
  //   {
  //     label: 'Featured'
  //   }
  // ];

  const SalesFromNewClients = [
    { Weeks: 'Week1', SalesPerWeek: 10 },
    { Weeks: 'Week2', SalesPerWeek: 25 },
    { Weeks: 'Week3', SalesPerWeek: 98 },
    { Weeks: 'Week4', SalesPerWeek: 97 },
    { Weeks: 'Week5', SalesPerWeek: 14 },
    { Weeks: 'Week6', SalesPerWeek: 36 },
    { Weeks: 'Week7', SalesPerWeek: 34 },
    { Weeks: 'Week8', SalesPerWeek: 45 },
    { Weeks: 'Week9', SalesPerWeek: 66 },
    { Weeks: 'Week10', SalesPerWeek: 45 },
    { Weeks: 'Week11', SalesPerWeek: 56 },
    { Weeks: 'Week12', SalesPerWeek: 45 }
  ];

  return (
    <>
      <Container maxWidth="100%" sx={{ pb: 2 }}>
        <Header title="POS" />

        {/* <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          gap="16px"
        >
          <Grid item>
             <Typography>POS</Typography>
          </Grid>
          <Grid item>
            <CustomButton
              text="Add New"
              variant="contained"
            // onClick={() => handleOpenScreen(2)}
            >
              Check Out
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton variant="outlined">
              Featured
            </CustomButton>
          </Grid>
        </Grid> */}
        {/* <Card
          sx={{ backgroundColor: '#32B2AC', marginTop: '15px' }}
          className="pos-title-card"
        >
          <Grid container>
            <Grid item md={1} sm={1} className="image-alignment-style">
              <img src="static/images/logo/posLogo.png" alt="logo" />
            </Grid>
            <Grid item md={10} sm={10}>
              <p className="pos-title">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex
              </p>
            </Grid>
            <Grid item md={1} sm={1}>
              <p>
                {' '}
                <a
                  className="pos-title"
                  style={{ textDecoration: 'underline' }}
                >
                  {' '}
                  View Details
                </a>
              </p>
            </Grid>
          </Grid>
        </Card> */}
        <Grid container sx={{ marginTop: '10px' }} spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            <Card
              // className="pos-card"
              sx={{
                backgroundColor: '#F8FBFF',
                border: '0.4px solid #C5C5C5',
                pb: 1
              }}
            >
              <CardHeader
                className="font-18 drodown_menu"
                title="2% OF SALES FROM NEW CLIENTS"
                action={<MoreVertIcon />}
              >
                {/* <Typography className="card-header">Sales in Past 7 Days</Typography> */}
              </CardHeader>
              <Container>
                <CardMedia>
                  {/* <Typography className="pos-text-size-74">111</Typography> */}
                  <LineGraphDashboardWidgets
                    graphData={SalesFromNewClients}
                    xAxisTitle="Weeks"
                    yAxisTitle="SalesPerWeek"
                  />
                </CardMedia>
              </Container>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <DashboardCardComponent
              title="AVG TICKET"
              timeFrame="90 Days"
              units="$15.55"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PosDashboard;
