import { ApiService } from 'src/services/ApiService';
import { getLocalData } from 'src/utils';

import ReturnReceipt from './Receipt';

import ReactDOMServer from 'react-dom/server';
import moment from 'moment';

export const useSalesDetailFunctions = (detail, setActive, selectedOrderId) => {
  const locationId = getLocalData('locationId');
  const franchiseeName = getLocalData('franchiseeName');
  const locationName = getLocalData('locationName');

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date).format('MM-DD-YYYY') : '';
    return utc;
  };

  const getClientDetail = async (setClientDetail, setTableData) => {
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedOrderId = encodeURIComponent(selectedOrderId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/paymentOrderDetail?locationId=${encodedLocationId}&orderId=${encodedOrderId}`
    );
    let Item =
      response && response.data && response.data.Item ? response.data.Item : [];
    setClientDetail(Item);
    if (Item?.items?.length > 0) {
      const tableItem = Item?.items || [];
      setTableData(tableItem);
    }
  };

  const returnFormValidation = (
    clientDetail,
    formData,
    setErrors,
    setShowMessage,
    setErrorMessage,
    setIsLoading,
    setRefundOrderId
  ) => {
    let isValid = true;
    const updatedErrors = {
      refundAmount: null,
      refundMethod: null,
      refundReason: null
    };

    // Validate refundAmount
    if (!formData?.refundAmount || formData?.refundAmount <= 0) {
      updatedErrors.refundAmount = 'Refund amount is required';
      isValid = false;
    } else if (
      parseFloat(formData?.refundAmount) >
      parseFloat(clientDetail?.totalSquareAmountWithTaxInDollar)
    ) {
      updatedErrors.refundAmount =
        'The amount should not be greater than the purchase amount';
      isValid = false;
    }

    // Validate refundMethod
    if (!formData?.refundMethod) {
      updatedErrors.refundMethod = 'Refund method is required';
      isValid = false;
    }

    // Validate refundReason
    if (!formData?.refundReason) {
      updatedErrors.refundReason = 'Refund reason is required';
      isValid = false;
    }

    // Update the state with the new errors
    setErrors(updatedErrors);
    if (isValid) {
      try {
        returnProceedFtn(
          clientDetail,
          formData,
          setShowMessage,
          setErrorMessage,
          setIsLoading,
          setRefundOrderId
        );
      } catch (error) {
        console.log('Error in refund API--->', error);
        setActive(2);
      }
    }
    return isValid;
  };

  const returnProceedFtn = async (
    clientDetail,
    formData,
    setShowMessage,
    setErrorMessage,
    setIsLoading,
    setRefundOrderId
  ) => {
    setIsLoading(true);
    const payload = {
      locationId: locationId,
      locationName: locationName,
      clientId: clientDetail.clientId,
      petId: clientDetail.petId,
      orderId: selectedOrderId,
      refundPaymentSourceType: formData?.refundMethod,
      refundAmount: parseFloat(formData?.refundAmount), // ?.toFixed(2),// clientDetail.totalSquareAmountWithTaxInDollar,
      reason: formData.refundReason,
      clientName: clientDetail?.clientName,
      petName: clientDetail?.petName,
      dateOfPurchase: clientDetail?.dateOfPurchase,
      franchiseeId: clientDetail.franchiseeId,
      franchiseeName: franchiseeName,
      franchisorId: clientDetail.franchisorId,
      totalSquareAmountWithTaxInDollar:
        clientDetail.totalSquareAmountWithTaxInDollar,
      paymentId: clientDetail.paymentId,
      paymentSourceType:
        clientDetail.paymentSourceType === 'CARD - GIFTCARD'
          ? 'CARD'
          : clientDetail.paymentSourceType,
      createdBy: clientDetail.createdBy,
      items: clientDetail.items,
      createdByName: getLocalData('UserName')
    };
    let response = await ApiService.post(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}checkout/returnAndRefund`,
      payload
    );

    if (response?.statusCode === 200 && response?.data !== 'NotAllowed') {
      setActive(3);
      setIsLoading(false);
      setRefundOrderId(response?.data?.refund?.orderId);
    } else if (
      response?.statusCode === 200 &&
      response?.data === 'NotAllowed'
    ) {
      setShowMessage(true);
      setErrorMessage(response?.message);
      setIsLoading(false);
    } else {
      setActive(2);
      setIsLoading(false);
    }
  };

  const printReceipt = (receiptData) => {
    console.log(receiptData, 'Selected is consoled');

    const receiptHtml = ReactDOMServer.renderToStaticMarkup(
      <ReturnReceipt selected={receiptData} />
    );

    const printWindow = window.open('', '', 'width=600,height=600');

    if (printWindow) {
      // Ensure the new window is opened only once
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Return Receipt</title>
          </head>
          <body>
            ${receiptHtml}
          </body>
        </html>
      `);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    } else {
      alert('Pop-up window blocked. Please allow pop-ups for this site.');
    }
  };

  return {
    dateFormate,
    returnProceedFtn,
    getClientDetail,
    returnFormValidation,
    printReceipt
  };
};
