import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Slide,
  Typography,
  Box
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ContractAndMembershipDetails = ({
  openDetailsPopup,
  setOpenDetailsPopup,
  details
}) => {
  const handleClosePopup = () => {
    setOpenDetailsPopup(false);
  };
  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    let result = date.toLocaleDateString('en-US', options);
    return result.replaceAll('/', '-');
  }
  return (
    <>
      <Dialog
        open={openDetailsPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box sx={{ padding: '5px 35px', color: 'black' }}>
            <Typography
              sx={{ fontSize: '18px', fontWeight: 700 }}
              textAlign={'left'}
            >
              Contract Pause Details
            </Typography>

            <Box
              sx={{
                margin: '20px 0px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h4"> Paused By </Typography>
              <Typography>{details?.pausedBy} </Typography>
            </Box>

            <Box
              sx={{
                margin: '20px 0px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h4"> Paused On </Typography>
              <Typography> {convertDateFormat(details?.pausedOn)} </Typography>
            </Box>

            <Box
              sx={{
                margin: '20px 0px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h4"> Paused From </Typography>
              <Typography>{convertDateFormat(details?.pausedFrom)} </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h4"> Paused Till </Typography>
              <Typography>{convertDateFormat(details?.pausedTill)} </Typography>
            </Box>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-success no-hover"
            onClick={() => handleClosePopup()}
          >
            {' '}
            Close{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractAndMembershipDetails;
