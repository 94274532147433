import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { SearchInput } from 'src/components/Global';
import Loader from 'src/components/Loader';

import GlobalData from 'src/global';
import { getLocalData, setFullLocationId } from 'src/utils';

import { Summary } from './Summary';
import { MembershipFilters } from './MembershipFilters';
import { MembershipTable } from './MembershipTable';
import { AddMembership } from './AddMembership';

// eslint-disable-next-line import/no-cycle
import { EnrollMember } from './EnrollMember';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Header from 'src/components/Header';
import { ApiService } from 'src/services/ApiService';
import { Helmet } from 'react-helmet-async';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FranchisorMemberships = () => {
  const title = GlobalData[0].title;
  const BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;
  const userId = getLocalData('userId');
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [membershipsData, setMembershipsData] = useState([]);
  const [allMembershipsData, setAllMembershipsData] = useState([]);
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [allMembershipCategories, setAllMembershipCategories] = useState([]);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [membershipDetails, setMembershipDetails] = useState({});
  const [isMembershipEdit, setIsMembershipEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);

  const [filters, setFilters] = useState({
    status: 1,
    name: null,
    activeMembers: null,
    billingCycle: null
  });

  const [clientFilters, setClientFilters] = useState({
    isActive: null,
    store: null
  });

  const showFilter = () => {
    setIsFilters(!isFilters)
    // if (states.length === 0) getStates();
    setdisplayFilterForm(!displayFilterForm);
    console.log(displayFilterForm, 'displayFilterForm-------');
  }

  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  useEffect(() => {
    const data = membershipsData?.filter((item) =>
      selectedRows?.includes?.(item.id)
    );
    setSelectedRowsData(data);
  }, [selectedRows]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleManageClick = () => {
    setShowScreen(2);
  };

  const buttons = [
    {
      label: 'Add New',
      onClick: handleManageClick,
      theam: true
    }
  ];
  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setIsMembershipEdit(false);
    setMembershipDetails();
    setSelectedRows([]);
    setSelectedRowsData([]);
    setSelectedStatus('');

    // call fetch api for getting latest data of services
    fetchData();
    getMembershipsFilters();
  };

  function findObjectById(id) {
    return membershipsData?.find((obj) => obj.id === id);
  }

  const openDetail = (id, screen, e) => {
    e.preventDefault();
    e.stopPropagation();
    setMembershipDetails(findObjectById(id));
    setIsMembershipEdit(true);
    handleOpenScreen(screen);
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  // function isAnyFilterApplied() {
  //   return Object.values(filters).some((value) => {
  //     if (Array.isArray(value)) {
  //       return value.length > 0;
  //     }
  //     return value !== null && value !== '';
  //   });
  // }
  //   ===============call api here=============

  function filtersQuery(filters) {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === -1 ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getMembershipsFilters = async () => {
    setLoading(true);
    let queryString = filtersQuery(filters);

    try {
      const membershipsResponse = await ApiService.get(
        `${BASE_URL}memberships/allMembership?${queryString}`
      );

      if (!membershipsResponse?.data?.error) {
        const modifiedData = membershipsResponse?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.membershipId
          })
        );
        setMembershipsData(modifiedData);
        setLoading(false);
        // PJ added check if allMembershipsData is empty then only set data for filters dropdown
        if (modifiedData.length > 0 && allMembershipsData.length === 0) {
          setAllMembershipsData(modifiedData);
        }
      } else {
        setLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          membershipsResponse.message || 'Something went wrong with Services'
        );
        // toast.error(
        //   membershipsResponse.message || 'Something went wrong with Services'
        // );
      }

      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };

  const fetchData = async () => {
    setTableLoading(true);
    try {
      const membershipCategoriesResponse = await ApiService.get(`${BASE_URL}memberships/allMembership`);
      if (!membershipCategoriesResponse?.data?.data?.error) {
        const modifiedData =
          membershipCategoriesResponse?.data?.data?.Items?.map((item) => ({
            ...item,
            id: item.sortKey
          }));
        setAllMembershipCategories(modifiedData);
      }
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setTableLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    getMembershipsFilters();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    fetchData();
  }, []);

  function getMembershipIds() {
    let array = [];
    selectedRowsData?.map((item) => array.push(item.sortKey));

    return array;
  }

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    //  setBtnLoading(true);
    setLoading(true);
    const data = {
      status: selectedStatus,
      updatedBy: userId,
      membershipIds: getMembershipIds()
    };

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.put(
        `${BASE_URL}memberships/updateBulkStatus?locationId=${encodedLocationId}`,
        data
      );
      if (!response.data?.error) {
        setOpen(true);
        handleResetStates();
        setIsUpdated(true);
        setLoading(false);
        // setOpen(true);
      } else {
        setLoading(false);
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
          'Something went wrong with update membership status'
        );
      }

      //  setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBtnLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      fetchData();
      if (isUpdated) {
        handleResetStates();
        setIsUpdated(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Franchisor Memberships | {title}</title>
      </Helmet>

      <Container maxWidth="100%" className="custom-container">
        <Loader IsLoading={loading} />
        {showErrorMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowErrorMessage}
          />
        )}

        {showSucessMessage && (
          <SuccessMessage
            message={sucessMessage}
            setShowMessage={setShowSuccessMessage}
          />
        )}

        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  <AddMembership
                    handleResetStates={handleResetStates}
                    isMembershipEdit={isMembershipEdit}
                    setIsMembershipEdit={setIsMembershipEdit}
                    membershipDetails={membershipDetails}
                  />
                </Box>
              );

            case 3:
              return (
                <Box justifyContent="center" direction="column">
                  <Summary
                    handleOpenScreen={handleOpenScreen}
                    handleResetStates={handleResetStates}
                    membershipDetails={membershipDetails}
                  />
                </Box>
              );

            case 4:
              return (
                <Box justifyContent="center" direction="column">
                  <EnrollMember
                    handleOpenScreen={handleOpenScreen}
                    handleResetStates={handleResetStates}
                    // allClientsData={allClientsData}
                    // clientsData={clientsData}
                    allMembershipCategories={allMembershipCategories}
                    filters={clientFilters}
                    setFilters={setClientFilters}
                    loading={loading}
                  />
                </Box>
              );

            default:
              return (
                <>
                  <Loader IsLoading={loading} />
                  <Box justifyContent="center" direction="column">
                    {showScreen === 1 && (
                      <Header
                        title="Franchisor Memberships"
                        buttons={buttons}
                      />
                    )}
                    {/* <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    gap="16px"
                  >
                    <Grid item>
                      <CustomButton
                        variant="contained"
                        onClick={() => handleOpenScreen(4)}
                      >
                        Enroll Member
                      </CustomButton>
                    </Grid>

                    <Grid item>
                      <CustomButton
                        text="Add New"
                        variant="outlined"
                        onClick={() => handleOpenScreen(2)}
                      >
                        Add New
                      </CustomButton>
                    </Grid>
                  </Grid> */}

                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={3}
                      sx={{ paddingLeft: '15px' }}
                    >
                      {/* <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          Enroll Member
                        <CustomButton/>
                      </Grid>
                      </Grid>
                      <Grid item>
                        <CustomButton
                          text="Add New"
                          variant="outlined"
                          onClick={() => handleOpenScreen(2)}
                        >
                          Add New
                        </CustomButton>
                      </Grid>
                    </Grid> */}

                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item width={'100%'}>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-end"
                            >
                              <Grid
                                item
                                sx={{
                                  marginTop: '10px',
                                  marginBottom: '20px'
                                }}
                              >
                                <Button
                                  onClick={showFilter}
                                  variant={displayFilterForm ? 'contained' : 'outlined'}
                                >
                                  Filters
                                  <KeyboardArrowDownRoundedIcon
                                    className={clsx(
                                      !displayFilterForm && classes.closeY,
                                      displayFilterForm && classes.openY
                                    )}
                                  />
                                </Button>
                              </Grid>


                              <Grid
                                item
                                xs={12}
                                sm={6}
                                marginY={{ sm: '20px' }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end'
                                }}
                              >
                                <SearchInput
                                  value={searchQuery}
                                  setSearchQuery={setSearchQuery}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                        <>
                          {isFilters ? (
                            <Grid item width={isFilters ? 300 : 0}>
                              <MembershipFilters
                                filters={filters}
                                setFilters={setFilters}
                                data={allMembershipsData}
                              />
                            </Grid>
                          ) : null}

                          <Grid
                            item
                            width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                          >
                            <MembershipTable
                              tableRows={membershipsData}
                              searchQuery={searchQuery}
                              openDetail={openDetail}
                              loading={tableLoading}
                              selectedRows={selectedRows}
                              setSelectedRows={setSelectedRows}
                              selectedStatus={selectedStatus}
                              setSelectedStatus={setSelectedStatus}
                              handleBulkAdd={handleBulkAdd}
                              btnLoading={btnLoading}
                            />
                          </Grid>
                        </>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  </Box>
                </>
              );
          }
        })()}
      </Container>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Are you sure you want to mark Activate/Deactivate,
                  <Typography className="title-text-sub">
                    all the selected items?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected memberships have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FranchisorMemberships;
