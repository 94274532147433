import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import React from 'react';
import './App.css';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './contexts/UserContext';
import { ChatProvider } from './content/applications/Chat/context/ChatContext';
import { getLocalData } from './utils';
import Chat from './content/applications/Chat/Chat';

function App() {
  const content = useRoutes(router);
  let isLogin = getLocalData('Login_Sucess');
  const isFranchisor = getLocalData('isFranchisor');
  return (
    <ThemeProvider>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* <ToastContainer /> */}
      <CssBaseline />
      <UserProvider>{content}</UserProvider>
      {/* </LocalizationProvider> */}
      {isLogin && isFranchisor === 'false' && (
        <div
          style={{
            fontSize: '50px',
            zIndex: 1000,
            position: 'fixed',
            right: 5,
            bottom: 170,
            borderRadius: '22px'
          }}
        >
          <ChatProvider>
            <Chat />
          </ChatProvider>
        </div>
      )}
    </ThemeProvider>
  );
}

export default App;
