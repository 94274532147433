import { React, useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Card,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Grid,
  Radio,
  CardHeader,
  TablePagination,
  Box
} from '@mui/material';
import './styles.css';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import SearchIcon from '@mui/icons-material/Search';
import { ContractFilter } from 'src/components/Filters/ContractFilter';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { filtersQuery } from '../../../utils/index';

const ContractTable = ({
  setSelectItem,
  contractList,
  getContract,
  setFiltersContractParams,
  selectItem
}) => {
  const [selectedContract, setSelectedContract] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState(''); // Created new state for search functionality
  const [page, setPage] = useState(0);
  const [showTable] = useState(true);
  const handleContractSelect = (contract) => {
    setSelectedContract(contract);
    setSelectItem(contract);
  };

  console.log(selectItem, 'selectItemselectItem');

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredLists =
    contractList &&
    contractList.filter((item) => {
      return Object.values(item).some((value) =>
        value ? value.toString().toLowerCase().includes(searchText) : ''
      );
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedLists =
    contractList &&
    filteredLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  console.log(paginatedLists);
  let [filters, setFilters] = useState({
    status: 1,
    contractName: ''
  });

  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  let queryString = filtersQuery(filters);

  useEffect(() => {
    let filtersParams = queryString ? `&${queryString}` : '';
    setFiltersContractParams(filtersParams);
    if (filtersParams) getContract();
  }, [filters]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
  };

  useEffect(() => {
    if (selectItem?.sortKey) {
      setSelectedContract(selectItem);
      setSelectItem(selectItem);
    }
  }, []);

  return (
    <>
      {showTable && (
        <Grid className="featured-items">
          {/* <Button variant="outlined" className="filter-search filter-btn">
            Filter (All)
            <KeyboardArrowDownRoundedIcon />
          </Button>
          <TextField
            type="search"
            label="Search"
            className="filter-search search-btn"
            InputProps={{ endAdornment: <SearchIcon /> }}
          /> */}
          <Grid xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid
                item
                sx={{
                  marginTop: '10px',
                  marginBottom: '20px'
                }}
              >
                {/* <Button variant="outlined" className="filter-search filter-btn">
                  Filter (All)
                  <KeyboardArrowDownRoundedIcon />
                </Button> */}
                <Button
                  onClick={showFilter}
                  variant={displayFilterForm ? 'contained' : 'outlined'}
                >
                  Filters
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !displayFilterForm && classes.closeY,
                      displayFilterForm && classes.openY
                    )}
                  />
                </Button>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
                className="top-filter-cont"
              >
                <TextField
                  type="search"
                  label="Search"
                  className="filter-search search-btn"
                  InputProps={{ endAdornment: <SearchIcon /> }}
                  onChange={handleSearchTextChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {displayFilterForm === true ? (
              <Grid item width={getFilterSize()} sx={{ marginTop: '30px' }}>
                <ContractFilter
                  setFilters={setFilters}
                  filters={filters}
                  isFeatured
                />
              </Grid>
            ) : null}

            <Grid item sx={{ width: `calc(100% - ${getFilterSize()}px)` }}>
              <Card className="Card">
                {/* <h2 className="table-heading">All Contracts</h2> */}
                <CardHeader
                  title="All Contracts"
                  className="table-header-name font-20 text-primary"
                />
                <TableContainer className="featured-table-container">
                  <Table>
                    <TableHead className="feature-table-head">
                      <TableRow>
                        <TableCell />
                        <TableCell>Contract Name</TableCell>
                        <TableCell>Contract Type</TableCell>
                        {/* <TableCell>Sold Online</TableCell> */}
                        <TableCell>Billing Cycle</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body-data">
                      {paginatedLists &&
                        paginatedLists.map((row) => (
                          <TableRow
                            onClick={() => handleContractSelect(row)}
                            key={row.contractId}
                            selected={
                              selectedContract?.sortKey === row?.sortKey
                            }
                          >
                            <TableCell className="table-cell-data">
                              <Radio
                                color="primary"
                                checked={
                                  selectedContract?.sortKey === row?.sortKey
                                }
                              />
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.contractName}
                            </TableCell>
                            <TableCell className="table-cell-data">
                              {row.contractType}
                            </TableCell>
                            {/* <TableCell className="table-cell-data">
                              {row.soldOnline ? 'Yes' : 'No'}
                            </TableCell> */}
                            <TableCell className="table-cell-data">
                              {row.billingCycle}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box p={2}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={filteredLists && filteredLists.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ContractTable;
