import React, { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField, Box } from '@mui/material';

export const CustomDatePicker = ({
  onChange,
  minDate,
  maxDate,
  label,
  required,
  fieldValue,
  disabled,
  currentDate
}) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [open, setOpen] = React.useState(false);
  const minLDate = minDate;
  const maxLDate = maxDate;
  const requiredField = required || false;

  const handleDateChange = (date) => {
    if (date) {
      let formatDate = new Date(date);
      /* Date format you have */
      // let dateMDY = `${formatDate.getMonth() + 1}-${formatDate.getDate()}-${formatDate.getFullYear()}`;
      let month = formatDate.getMonth() + 1;
      let day = formatDate.getDate();
      let year = formatDate.getFullYear();

      // Add leading zeros to month and day if needed
      let formattedMonth = month.toString().padStart(2, '0');
      let formattedDay = day.toString().padStart(2, '0');

      let dateMDY = `${formattedMonth}/${formattedDay}/${year}`;
      /* Date converted to MM-DD-YYYY format */

      setSelectedDate(date);
      onChange(dateMDY); // Call the onChange callback with the selected date
    }
  };

  useEffect(() => {
    if (fieldValue) {
      setSelectedDate(fieldValue);
    }
  }, [fieldValue]);

  const handleInputChange = (event) => {
    event.preventDefault();
    setOpen(true);
  };
  const CustomIcon = () => {
    // Replace this JSX with your desired icon SVG or any other icon component
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 22c-5.514 0-10-4.486-10-10S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm-2-11h4v5h-4zm1-6c0-.553-.447-1-1-1s-1 .447-1 1v5c0 .553.447 1 1 1s1-.447 1-1V5z" />
      </svg>
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className='scheduleDatePick'>
      <DesktopDatePicker 
        label={label}
        value={currentDate||selectedDate }
        onChange={handleDateChange}
        disabled={disabled}
        keyboardIcon={<CustomIcon />}
        renderInput={(params) => (
          <TextField
            sx={{ width: '100%' }}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: true
            }}
            InputLabelProps={{
              className: requiredField ? 'required-label' : ''
            }}
            className={`custom-datepicker ${
              requiredField ? 'required-field' : ''
            }`}
            onClick={handleInputChange}
          />
        )}
        inputProps={{ readOnly: true }} // Make the input field read-only
        PopperProps={{
          disablePortal: true // Prevent the Popper component from rendering in a portal
        }}
        minDate={minLDate} // Minimun selectable date
        maxDate={maxLDate} // Maximum selectable date
        open={open}
        onClose={() => setOpen(false)}
        inputFormat="EEE, MMM dd, yyyy"
      />      
      </Box>
    </LocalizationProvider>
  );
};
