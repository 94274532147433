import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
 // TextField,
  Card,
  Grid,
  Paper,
  // Autocomplete,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';
// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import './RecieveInventory.css';

const Filter = ({ setFilters, filters, getLocation }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  console.log(getLocation,"getLocationgetLocation")

  // create a custom theme with updated hover and close icon colors

  // const useAutocompleteStyles = makeStyles({
  //   inputRoot: {
  //     // borderRadius: 10, // or any other value you prefer
  //   },
  //   option: {
  //     '&:hover': {
  //       color: 'white'
  //       // or any other color you prefer
  //     }
  //   },
  //   clearIndicator: {
  //     color: '#003087', // or any other color you prefer
  //     backgroundColor: '#F8FBFF',
  //     '&:hover': {
  //       backgroundColor: '#F8FBFF'
  //     }
  //   }
  // });

  const classes = useStyles();
 // const autoCompleteClasses = useAutocompleteStyles();
  const [isLocationOpen, setLocation] = useState(false);
  const [isToLocationOpen, setToLocation] = useState(false);
 
  const handleActiveChange = (value) => {
    setFilters((prevState) => ({ ...prevState, transactionStatus: value }));
  };

  const handleLocationFromChange = (event) => {
    // setFilters((prevState) => ({ ...prevState, fromLocationId: value }));
    let value = event.target.value;
    let encodedLocId = value ? encodeURIComponent(value) : null;
    setFilters((prevState) => ({
      ...prevState,
      fromLocationId: encodedLocId
    }));
  };

  const handleLocationToChange = (event) => {
    // setFilters((prevState) => ({ ...prevState, toLocationId: value }));
    let value = event.target.value;
    let encodedLocId = value ? encodeURIComponent(value) : null;
    setFilters((prevState) => ({
      ...prevState,
      toLocationId: encodedLocId
    }));
  };

  const handleLocationClick = () => {
    setLocation(!isLocationOpen);
    setToLocation(false);
  };

  const handleLocationToClick = () => {
    setToLocation(!isToLocationOpen);
    setLocation(false);
  };

  const handleClearFilter = () => {
    setFilters({
      transactionStatus: filters.status,
      fromLocationId: null,
      toLocationId: null
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel className="status-field-lbl">Status</InputLabel>
          <Select
            value={filters.transactionStatus ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            {/* <MenuItem value={null}>None</MenuItem> */}
            {/* <MenuItem value={'draft'}>Draft</MenuItem>
            <MenuItem value={'sent'}>Sent</MenuItem> */}
            <MenuItem value={'received'}>Received</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: "pointer"
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid onClick={handleLocationClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !isLocationOpen && classes.closeY,
                    isLocationOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>From Location</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {isLocationOpen === true && getLocation && (
          <Box marginTop={1}>
            {/* <Autocomplete
              disablePortal
              classes={autoCompleteClasses}
              value={filters.locationName}
              onChange={(event, newValue) => {
                // setgetCity(newValue);
                handleLocationFromChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                handleLocationFromChange(newInputValue);
              }}
              className="status-field field-label-css"
              options={getLocation.map((option) => option.locationName)}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search Location"
                />
              )}
            /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
              From Location
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="locationId"
                onChange={handleLocationFromChange}
                value={filters.locationName}
                // value={filters.fromLocationId}
                label="From Location"
                className="input-field-styling"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                {getLocation.map((r, i) => (
                  <MenuItem key={i} value={r.locationId}>
                    {r.locationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Grid item onClick={handleLocationToClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !isToLocationOpen && classes.closeY,
                    isToLocationOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>To Location</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {isToLocationOpen === true && getLocation && (
          <Box marginTop={1}>
            {/* <Autocomplete
              disablePortal
              classes={autoCompleteClasses}
              value={filters.locationName}
              onChange={(event, newValue) => {
                // setgetCity(newValue);
                handleLocationToChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                handleLocationToChange(newInputValue);
              }}
              className="status-field field-label-css"
              options={getLocation.map((option) => option.locationName)}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search Location"
                />
              )}
            /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
              To Location
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="locationToId"
                onChange={handleLocationToChange}
                value={filters.locationName}
                // value={filters.toLocationId}
                label="To Location"
                className="input-field-styling"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                {getLocation.map((r, i) => (
                  <MenuItem key={i} value={r.locationId}>
                    {r.locationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

      </Card>
    </>
  );
};

export default Filter;
