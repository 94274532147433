import {
  Grid,
  Typography,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

const SalesHistoryCard = ({ trophyDetails, handleOpenScreen }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (trophyDetails?.items?.length > 0) {
      const tableItem = trophyDetails?.items || [];

      setTableData(tableItem);
    }
  }, [trophyDetails]);

  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date).format('MM-DD-YYYY') : '';
    return utc;
  };

  const renderDetailSection = (firstRow, secondRow) => (
    <Box
      className="section-main"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box>
        <Stack>{firstRow?.[0]} :</Stack>
        <Stack>{firstRow?.[1]}</Stack>
      </Box>
      <Box>
        <Stack>{secondRow?.[0]} :</Stack>
        <Stack>{secondRow?.[1]}</Stack>
      </Box>
    </Box>
  );

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mt={1}
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box
              onClick={() => handleOpenScreen(0)}
              className="back-arrow-close"
              sx={{ marginTop: '6px !important' }}
            >
              <ArrowLeft />
            </Box>
            <Typography className="header-blue-20">Purchase History</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className="main-sales-detail" p={0} mt={2}>
        <Box className="detailbox-main">
          <Box className="detail-section" sx={{ padding: '30px !important' }}>
            {renderDetailSection(
              [
                'DATE',
                trophyDetails?.dateOfPurchase
                  ? dateFormate(trophyDetails?.dateOfPurchase)
                  : '-'
              ],
              ['PAYMENT METHOD', trophyDetails?.paymentSourceType || '-']
            )}
            {renderDetailSection(
              ['DOG', trophyDetails?.petName || '-'],
              ['CARD NUMBER', trophyDetails?.cardNumber || '-']
            )}
            {renderDetailSection(
              [
                'TOTAL AMOUNT',
                trophyDetails?.totalSquareAmountWithTaxInDollar
                  ? `$${parseFloat(trophyDetails?.totalSquareAmountWithTaxInDollar)?.toFixed(2)}`
                  : '$0.00'
              ],
              [
                'DISCOUNT',
                trophyDetails?.totalSquareDiscountAmountInDollar
                  ? `$${parseFloat(trophyDetails?.totalSquareDiscountAmountInDollar)?.toFixed(2)}`
                  : '$0.00'
              ]
            )}
            {renderDetailSection(
              ['PAYMENT STATUS', trophyDetails?.sqaureOrderState],
              [
                'TRANSACTION ID',
                trophyDetails?.transactionId || trophyDetails?.orderId
              ]
            )}
          </Box>
          {trophyDetails?.items && (
            <Box className="detail-table">
              <Card
                sx={{
                  padding: '15px',
                  borderRadius: '19px 19px 0px 0px'
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '12px'
                    }}
                  >
                    <CardHeader
                      title="All Items"
                      className="table-header-name"
                      sx={{ fontSize: '20px', color: '#003087' }}
                    />
                  </Grid>
                </Grid>
                <TableContainer className="location-table-container">
                  <Table>
                    <TableHead>
                      <TableRow className="table-header">
                        <TableCell>Item Name</TableCell>
                        <TableCell>Remaining Credits/Qty</TableCell>
                        <TableCell>Variants</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData &&
                        tableData?.map((tableItem) => {
                          return (
                            <TableRow
                              hover
                              key={tableItem?.itemId}
                              className="table-row cursor-pointe no-hover"
                            >
                              <TableCell
                                className="table-td -flex"
                                sx={{ minWidth: '200px' }}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  sx={{ marginTop: 1 }}
                                  gutterBottom
                                >
                                  {tableItem?.itemName || '-'}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  sx={{ padding: '8px', marginTop: '2px' }}
                                  gutterBottom
                                >
                                  {tableItem?.quantity}
                                </Typography>
                                {/* )} */}
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {tableItem?.variants
                                    ?.filter((value) => value?.value)
                                    ?.map?.((item, index, array) =>
                                      index === array.length - 1
                                        ? item?.value
                                        : `${item?.value}, `
                                    )
                                    .join('') || '-'}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  ${parseFloat(tableItem?.price)?.toFixed(2) || 0.00}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SalesHistoryCard;
