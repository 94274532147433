import React from 'react';
import { Radio, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  radio: {
    color: 'rgba(0, 48, 135, 0.5)', // Change the color to your desired color
    '&$checked': {
      color: '003087' // Change the color when the Radio is checked
    }
  },
  checked: {} ,
  adjustmentReasonItem: {
    color: '#676767',
    fontWeight: '600', 
    fontFamily: 'Myriad Pro, sans-serif',
    lineHeight: '25px',
    fontSize: '16px !important'
  },
  // Empty class name required for "&$checked" selector to work
});

export const AdjustmentReasons = ({ selectedReason, handleChangeReason }) => {
  return (
    <Stack direction="column" spacing={1}>
      <Typography className='adjustment-reason title mb-0 mt-10 primary-color ' variant="variant2" marginBottom={2}>
       <h2>Provide Adjustment Reason</h2>
      </Typography>
      <AdjustmentReasonsItem
        name="Breakage/Waste"
        checked={selectedReason}
        handleChange={handleChangeReason}
      />
      <AdjustmentReasonsItem
        name="Short Expiry Stock"
        checked={selectedReason}
        handleChange={handleChangeReason}
      />
      <AdjustmentReasonsItem
        name="Other (Provide Reason)"
        checked={selectedReason}
        handleChange={handleChangeReason}
      />
    </Stack>
  );
};

const AdjustmentReasonsItem = ({ name, checked, handleChange }) => {
  const classes = useStyles();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="variant4" className={classes.adjustmentReasonItem}>{name}</Typography>
      <Radio
        classes={{ root: classes.radio, checked: classes.checked, name: classes.name}}
        size="medium"
        color="primary"
        checked={checked === name}
        onChange={handleChange}
        value={name}
        name="radio-buttons"
        inputProps={{ 'aria-label': 'A' }}
      />
    </Stack>
  );
};
