import React, { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField, Box, Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const CustomDatePicker = ({
  onChange,
  label,
  required,
  disabled,
  currentDate,
  filterType
}) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [formatDate, setFormatDate] = useState(currentDate);
  const [open, setOpen] = React.useState(false);
  const requiredField = required || false;

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      const formattedDate = formatSelectedDate(date);
      onChange(formattedDate); // Call the onChange callback with the selected date
    }
  };

  const formatSelectedDate = (date) => {
    const options = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    };
    return date.toLocaleDateString('en-US', options);
  };
  const formatCurrentDate = (date) => {
    const options = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric'
    };
    // const firstDate = getFirstDateOfCurrentWeek(date);
    // const lastDate = getLastDateOfCurrentWeek(date);
    const formattedFirstDate = date.toLocaleDateString('en-US', options);
    return formattedFirstDate;
  };
  useEffect(() => {
    try {
      let formatDateStr = '';
      let formatDate = new Date(currentDate);
      let fDate = getFirstDateOfCurrentWeek(formatDate);
      let sDate = getLastDateOfCurrentWeek(formatDate);
      if (filterType === 'day') {
        // formatDateStr = `${months[fDate.getMonth()]} , ${fDate.getFullYear()}`;
        formatDateStr = formatCurrentDate(formatDate);
      } else {
        const monthNamef = fDate.toLocaleString('en-us', { month: 'short' });
        const monthNames = sDate.toLocaleString('en-us', { month: 'short' });
        formatDateStr = `${monthNamef} ${fDate.getDate()}  - ${monthNames} ${sDate.getDate()} `;
      }
      setFormatDate(formatDateStr);
    } catch (e) {
      console.log(e);
    }
  }, [currentDate, filterType]);

  const handleInputChange = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const openCalander = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getFirstDateOfCurrentWeek = (currentDate) => {
    const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ... , 6 = Saturday)
    const daysUntilMonday = (dayOfWeek + 6) % 7; // Calculate how many days to subtract to reach Monday

    const firstDate = new Date(currentDate);
    firstDate.setDate(currentDate.getDate() - daysUntilMonday);

    return firstDate;
  };

  const getLastDateOfCurrentWeek = (currentDate) => {
    const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ... , 6 = Saturday)

    let daysToAdd;
    if (dayOfWeek === 0) {
      // If the current day is Sunday
      daysToAdd = 0; // No days to add as it's already Sunday
    } else {
      daysToAdd = 7 - dayOfWeek; // Calculate how many days to add to reach Sunday
    }

    const lastDate = new Date(currentDate);
    lastDate.setDate(currentDate.getDate() + daysToAdd);

    return lastDate;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="staffTimeOffDatePick">
        <Box className={'staffLabelDate'} mt={1}>
          <Grid container sx={{ color: 'black' }} onClick={openCalander}>
            <Grid item xs={10}>
              <Typography variant="h6" className="selected-month">
                {formatDate}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <KeyboardArrowDownIcon />
            </Grid>
          </Grid>
        </Box>
        <DesktopDatePicker
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              sx={{ width: '150px', visibility: 'hidden' }}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true
              }}
              InputLabelProps={{
                className: requiredField ? 'required-label' : ''
              }}
              className={`custom-datepicker ${
                requiredField ? 'required-field' : ''
              }`}
              onClick={handleInputChange}
            />
          )}
          inputProps={{ readOnly: true }}
          PopperProps={{
            disablePortal: true
          }}
          open={open}
          onClose={() => setOpen(false)}
          inputFormat="EEEE, MMM dd, yyyy" // Desired date format
        />
      </Box>
    </LocalizationProvider>
  );
};
