import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Slide,
  Box
  // Divider
} from '@mui/material';

import LocationListAPI from './LocationListAPI';
import Footer from 'src/components/Footer';
import React, { useEffect, useState, useRef } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import GlobalData from '../../../global';
import AddLocation from './AddLocation';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';
import { getLocalData } from 'src/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function LocationList() {
  const navigate = useNavigate();

  const title = GlobalData[0].title;
  const childRef = useRef(null);

  const [showScreen, setShowScreen] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [franchiseeList, setFranchiseeList] = useState([]);
  const [statesList, SetStatesList] = useState([]);
  const [managerList, SetManagerList] = useState([]);

  useEffect(() => {
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, []);

  // add location page dropdown api calls starts here
  const getFranchiseeLists = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}franchisees?limit=500&status=1`
      );
      if (!response?.data?.error) {
        setFranchiseeList(response?.data?.Items || []);
      } else {
        setShowMessage(true);
        setErrorMessage(
          response.message || 'Something went wrong with business entity'
        );
      }
    } catch (error) {
      console.log(error);
      setShowMessage(true);
      setErrorMessage(error.message || 'Internal Server Error');
    }
  };

  const getStates = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`
      );
      if (!response?.data?.error) {
        SetStatesList(response?.data?.Items || []);
      } else {
        setShowMessage(true);
        setErrorMessage(response.message || 'Something went wrong with state');
      }
    } catch (error) {
      console.log(error);
      setShowMessage(true);
      setErrorMessage(error.message || 'Internal Server Error');
    }
  };

  const getManagerList = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}users/getManagerList`
      );
      if (!response?.data?.error) {
        SetManagerList(response?.data?.Items || []);
      } else {
        setShowMessage(true);
        setErrorMessage(
          response.message ||
            'Something went wrong with assign support specialist'
        );
      }
    } catch (error) {
      console.log(error);
      setShowMessage(true);
      setErrorMessage(error.message || 'Internal Server Error');
    }
  };

  useEffect(() => {
    getFranchiseeLists();
    getStates();
    getManagerList();
  }, []);
  // Method to close the dialog
  const handleClose = () => {
    // setshowForm(false)
  };

  // Method to close the location form
  const closeLocationDialog = () => {
    console.log('dialog closed--add location');
    // trigger the parent method to refresh the data
    childRef.current.refreshLocationData();

    handleClose();
  };
  const handleButtonClick = () => {
    setShowScreen(1);
  };

  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam: true
    }
  ];

  return (
    <>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Helmet>
        <title>Location Management | {title}</title>
      </Helmet>
      {/* maxWidth="lg" */}
      <Box sx={{ pl: '45px', pr: '22px' }}>
        {showScreen === 0 && (
          <Header
            title="Location Management"
            buttons={buttons}
            sx={{ marginX: '10px' }}
          />
        )}
      </Box>
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <Box sx={{ width: '100%' }}>
                <AddLocation
                  LocationAdded={closeLocationDialog}
                  // showForm={setshowForm}
                  Transition={Transition}
                  setShowScreen={setShowScreen}
                  franchiseeList={franchiseeList}
                  managerList={managerList}
                  statesList={statesList}
                />
              </Box>
            );
          default:
            return (
              <>
                <Grid item width={'100%'}>
                  {/* Now draw the location table */}
                  <LocationListAPI
                    ref={childRef}
                    setShowScreen={setShowScreen}
                    showScreen={showScreen}
                    franchiseeList={franchiseeList}
                    managerList={managerList}
                    statesList={statesList}
                  />
                </Grid>
              </>
            );
        }
      })()}

      <Footer />
    </>
  );
}

export default LocationList;
