import React, {useState} from "react";
import {Paper} from "@mui/material";
import SignaturePad from 'react-signature-canvas';



export const SignatureCard = ({width, height, contractPricing, setContractPricing, canvasRef}) => {
    const [signature, setSignature] = useState(null);
    console.log("signature", signature)

    const clearSignature = () => {
        setSignature(null);
        canvasRef.current.clear();
    };
    const saveSignature = () => {
        const image = canvasRef.current.toDataURL();
        console.log()
        setContractPricing({...contractPricing, signatureImage: image})
        console.log("image", image);
        // Do something with the image, such as saving it to the server or displaying it in a modal.
    };
    return (
        <Paper style={{width, height}}>
            <SignaturePad
                ref={canvasRef}
                onClear={clearSignature}
                onSave={saveSignature}
                canvasProps={{
                    className: "mySignatureBox"
                }}
            />

        </Paper>
    );
};
