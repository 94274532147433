import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  Card,
  Grid,
  Paper,
  Autocomplete,
  Divider,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';
import { getLocalData } from '../../../utils';

// Import Datepicker from MUI
// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
//  import LocalizationProvider from '@mui/lab/LocalizationProvider';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import './Staff.css';

const Filter = ({ setFilters, filters, getRoleList, getLocationList, getCities }) => {
  console.log(getCities, getRoleList);

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  console.log(isFranchisor, locationId, isPrimaryOwner);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const classes = useStyles();
  const autoCompleteClasses = useAutocompleteStyles();

  const [locationOpen, setLocationOpen] = useState(false);
  const [payRateOpen, setPayRateOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  // const [showDatePicker, setShowDatePicker] = useState(false);
  // const [isCityOpen, setCity] = useState(false);
  const [locationSelected, setLocationSelected] = useState([]);
  const [openClockInTime, setOpenClockInTime] = useState(false);
  // const [openNotClockInTime, setOpenNotClockInTime] = useState(false);

  // const [getCity, setgetCity] = React.useState('');
  //   const [startDate, setstartDate] = useState(null);
  //   const [endDate, setEndDate] = useState(null);
  // console.log(endDate);
  const handleActiveChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, isActive: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  // const formatDate = (inputDate) => {
  //   let date;
  //   let month;
  //   let year;

  //   date = inputDate.getDate();
  //   month = inputDate.getMonth() + 1;
  //   year = inputDate.getFullYear();

  //   date = date.toString().padStart(2, '0');

  //   month = month.toString().padStart(2, '0');

  //   return `${month}-${date}-${year}`;
  // };

  // Method to handle role change and set filters
  const handlRoleChange = (value) => {
    setFilters((prevState) => ({ ...prevState, role: value }));
  };

  // const handleCityChange = (value) => {
  //   setFilters((prevState) => ({ ...prevState, city: value }));
  // };

  const handlLocationChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, team: value }));
    const locationId = value.map((val) => {
      return val.locationId;
    });

    // Remove the same value of array from teamId
    const uniqueLocationId = locationId.filter(function (item, pos) {
      return locationId.indexOf(item) === pos;
    });

    const encodedLocationId = encodeURIComponent(uniqueLocationId);
    // setFilters((prevState) => ({ ...prevState, filterLocationId: uniqueLocationId }));
    setFilters((prevState) => ({ ...prevState, filterLocationId: encodedLocationId }));
  };

  const handlPayRateChange = (value) => {
    setFilters((prevState) => ({ ...prevState, payRateType: value }));
  };

  const handleClockInTimeChange = (event) => {
    const { checked } = event.target;
    setFilters((prevState) => ({ ...prevState, clockedIn: checked }));
  };

  // const handleNotClockInTimeChange = (event) => {
  //   const { checked } = event.target;
  //   setFilters((prevState) => ({ ...prevState, notClockInTime: checked }));
  // };

  const handleClockInTime = () => {
    setOpenClockInTime(!openClockInTime);
    // setOpenNotClockInTime(false);
    setRoleOpen(false);
    setLocationOpen(false);
    setPayRateOpen(false);
  };

  // const handleNotClockInTime = () => {
  //   setOpenNotClockInTime(!openNotClockInTime);
  //   setOpenClockInTime(false);
  //   setRoleOpen(false);
  //   setLocationOpen(false);
  // };

  // const handleStartDateChange = (date) => {
  //   setstartDate(date);
  //   // let formattedStartDate = formatDate(date);
  //   let formattedStartDate = formatDate(new Date(date));
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     startDate: formattedStartDate
  //   }));
  // };
  // const handleEndDateChange = (date) => {
  //   setEndDate(date);
  //   // let formattedEndDate = formatDate(date);
  //   let formattedEndDate = formatDate(new Date(date));
  //   setFilters((prevState) => ({ ...prevState, endDate: formattedEndDate }));
  // };

  const handleRoleClick = () => {
    setRoleOpen(!roleOpen);
    setLocationOpen(false);
    setOpenClockInTime(false);
    setPayRateOpen(false);
    // setOpenNotClockInTime(false);
    // setLocationOpen(false);
  };

  // const handleCityClick = () => {
  //   setCity(!isCityOpen);
  //   setLocationOpen(false);
  //   // setShowDatePicker(false);
  //   setPayRateOpen(false);
  // };

  const handleLocationClick = () => {
    setLocationOpen(!locationOpen);
    // setCity(false);
    // setShowDatePicker(false);
    setRoleOpen(false);
    setOpenClockInTime(false);
    setPayRateOpen(false);
    // setOpenNotClockInTime(false);
  };
  // const handleOpeningDateClick = () => {
  //   setShowDatePicker(!showDatePicker);
  //   setCity(false);
  //   setTeamOpen(false);
  //   setRegionOpen(false);
  // };

  const handlePayRateClick = () => {
    setPayRateOpen(!payRateOpen);
    // setCity(false);
    // setShowDatePicker(false);
    setRoleOpen(false);
    setLocationOpen(false);
    setOpenClockInTime(false);
    // setOpenNotClockInTime(false);
  };

  const handleClearFilter = () => {
    setLocationSelected([]);
    setOpenClockInTime(false);
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      role: null,
      team: null,
      // filterLocationId: [],
      payRateType: null,
      startDate: null,
      city: null,
      endDate: null,
      clockedIn: null,
      // notClockInTime: null
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel className="status-field-lbl"> Type</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Type"
            className="status-field field-label-css"
          >
            <MenuItem value={-1}>All</MenuItem>
            {/* <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: "pointer"
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* // Role */}

        <Grid xs={12} onClick={handleRoleClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !roleOpen && classes.closeY,
                    roleOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Role</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {roleOpen && (
          <Box marginTop={1}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                width: '100%'
              }}
            >
              <InputLabel>Role</InputLabel>
              <Select
                onChange={(event) => handlRoleChange(event.target.value)}
                value={filters.role ?? ''}
                label="Role"
                className="status-field field-label-css"
              >
                <MenuItem value={-1}>All</MenuItem>

                {getRoleList.map((item) => (
                  //   <MenuItem id={item.role} value={item.role}>
                  //   {item.role}
                  // </MenuItem>
                  (isFranchisor === 'false' && (isPrimaryOwner === 'false' || isPrimaryOwner === 'true')) &&
                    <MenuItem id={item.role} value={item.role}>
                      {item.role}
                    </MenuItem>
                //     (item.role === 'Operations' || item.role === 'Staff') &&
                //     (<MenuItem id={item.role} value={item.role}>
                //       {item.role}
                //     </MenuItem>
                //     ) : (
                //       <MenuItem id={item.role} value={item.role}>
                //         {item.role}
                //       </MenuItem>
                //     )
                 )
                )}
              </Select>
            </FormControl>
          </Box>
        )}

        {/* // Location */}

        <Grid item onClick={handleLocationClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !locationOpen && classes.closeY,
                    locationOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Location</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {locationOpen && (
          <Box marginTop={1}>
            <Autocomplete
              classes={autoCompleteClasses}
              multiple
              value={locationSelected}
              onChange={(event, newValue) => {
                handlLocationChange(newValue);
                setLocationSelected(newValue);
              }}


              className="status-field field-label-css status-field-lbl"
              // options={getUserTeam.map((option) => option.teamName)}
              options={getLocationList}
              getOptionLabel={(option) => option.locationName}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search Location" />
              )}
            />
          </Box>
        )}

        {/* <Grid onClick={handleCityClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !isCityOpen && classes.closeY,
                    isCityOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>City</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {isCityOpen === true && getCities && (
          <Box marginTop={1}>
            <Autocomplete
              disablePortal
              classes={autoCompleteClasses}
              value={filters.city}
              onChange={(event, newValue) => {
                // setgetCity(newValue);
                handleCityChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                handleCityChange(newInputValue);
              }}
              className="status-field field-label-css"
              options={getCities.map((option) => option.city)}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search City"
                />
              )}
            />
          </Box>
        )}
        <Grid item onClick={handleTeamClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !teamOpen && classes.closeY,
                    teamOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Team</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {teamOpen && (
          <Box marginTop={1}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                width: '100%'
              }}
            >
              <InputLabel className="status-field-lbl">Team</InputLabel>
              <Select
                onChange={(event) => handlTeamChange(event.target.value)}
                value={filters.team ?? ''}
                label="Team"
                className="status-field field-label-css"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Team1">Team1</MenuItem>
                <MenuItem value="Team2">Team2</MenuItem> 
              </Select>
            </FormControl>
          </Box>
        )} */}

        <Grid xs={12} onClick={handlePayRateClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !payRateOpen && classes.closeY,
                    payRateOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Payrate Type</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {payRateOpen && (
          <Box marginTop={1}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                width: '100%'
              }}
            >
              <InputLabel className="status-field-lbl">Payrate Type</InputLabel>
              <Select
                onChange={(event) => handlPayRateChange(event.target.value)}
                value={filters.payRateType ?? ''}
                label="Payrate Type"
                className="status-field field-label-css"
              >
                <MenuItem value={-1}>All</MenuItem>
                <MenuItem value="Salaried">Salaried</MenuItem>
                <MenuItem value="Hourly">Hourly</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        {/* // Clocked in */}
        <Grid xs={12} onClick={handleClockInTime} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openClockInTime && classes.closeY,
                    openClockInTime && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Clocked In</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openClockInTime && (
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  ckecked={filters.clockedIn ?? false}
                  className="check-box-span"
                  onChange={handleClockInTimeChange}
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Clocked In</Typography>
              }
            />
          </Box>
        )}

        {/* // Clocked in */}
        {/* <Grid xs={12} onClick={handleNotClockInTime} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openNotClockInTime && classes.closeY,
                    openNotClockInTime && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Not Clocked In</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openNotClockInTime && (
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  value={filters.openNotClockInTime ?? ''}
                  className="check-box-span"
                  onChange={handleNotClockInTimeChange}
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Not Clocked In</Typography>
              }
            />
          </Box>
        )} */}

        {/* <Grid xs={12} onClick={handleOpeningDateClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !showDatePicker && classes.closeY,
                    showDatePicker && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Opening Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}

        {/* {showDatePicker && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box marginTop={1}>
              <DatePicker
                // sx={{ width: '100%' }}
                label="Start Date"
                variant="contained"
                value={filters.startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '100%' }} />
                )}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
            <Box marginTop={1}>
              <DatePicker
                // sx={{ width: '100%' }}
                variant="contained"
                label="End Date"
                minDate={startDate}
                value={filters.endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: '100%' }} />
                )}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Box>
          </LocalizationProvider>
        )} */}
      </Card>
    </>
  );
};

export default Filter;
