import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../services/ApiService';

export const PricingOptionsFilter = ({ setFilters, filters }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  // const [expired, SetExpired] = useState(true);
  // const [service, SetService] = useState([]);

  const [categoryData, SetCategoryData] = useState([]); // getting dynamic list

  const [openFilter, setOpenFilter] = useState({
    // expired: false,
    category: false,
    service: false,
    free: false,
    sellonline: false
  });

  const getCategories = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`
      );
      SetCategoryData(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };

  // const getServicesByCat = async (catId) => {
  //   if (catId) {
  //     try {
  //       const response = await ApiService.get(
  //         `${
  //           process.env.REACT_APP_API_END_POINT
  //         }pricing-option/getSelectedServices?categoryId=${encodeURIComponent(
  //           catId
  //         )}`
  //       );
  //       SetService(response.data.Items);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     SetService([]);
  //   }
  // };

  useEffect(() => {
    // get all categories
    getCategories();
  }, []);

  const changeType = (value) => {
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const ChangeCategory = (value) => {
    setFilters((prevState) => ({ ...prevState, categoryId: value }));
    // getServicesByCat(value);
  };

  // const ChangeService = (value) => {
  //   setFilters((prevState) => ({ ...prevState, serviceId: value }));
  // };

  const ChangeFree = (value) => {
    setFilters((prevState) => ({ ...prevState, free: value }));
  };

  const ChangeSellOnline = (value) => {
    setFilters((prevState) => ({ ...prevState, sellOnline: value }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      // expired: field === 'expired' ? !prevState.expired : false,
      category: field === 'category' ? !prevState.category : false,
      service: field === 'service' ? !prevState.service : false,
      free: field === 'free' ? !prevState.free : false,
      sellonline: field === 'sellonline' ? !prevState.sellonline : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: filters.status,
      // expired: '',
      categoryId: '',
      service: '',
      free: '',
      sellonline: ''
    });

    setOpenFilter({
      // expired: false,
      category: false,
      service: false,
      free: false,
      sellonline: false
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Type</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) => changeType(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem key={1} id={1} value={-1}>
              All
            </MenuItem>
            <MenuItem key={2} id={2} value={1}>
              Active
            </MenuItem>
            <MenuItem key={3} id={3} value={0}>
              Inactive
            </MenuItem>
            {/* <MenuItem key={3} id={3} value={3}>
              Expired
            </MenuItem> */}
          </Select>
        </FormControl>
        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>
            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Expired filter 
                <Grid item xs={12} onClick={() => handleOpenFilter('expired')} pt={0.5}>
                    <Paper>
                        <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            className="pointer"
                        >
                            <Grid item>
                                <KeyboardArrowRightRoundedIcon
                                    className={clsx(
                                        !openFilter.expired && classes.closeY,
                                        openFilter.expired && classes.openY
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Expired</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {openFilter.expired && (
                    <Box marginTop={1}>
                        <FormControl fullWidth variant="outlined">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Switch color="primary" value={expired} defaultValue={expired} onChange={(event,flag) => ChangeExpired(flag)} />
                            </Stack>
                        </FormControl>
                    </Box>
                )}
                */}
        {/* Category filter */}
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('category')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.category && classes.closeY,
                    openFilter.category && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Category</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.category && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select
                onChange={(event) => ChangeCategory(event.target.value)}
                value={filters.categoryId ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Category"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} value="">
                  None
                </MenuItem> */}
                {categoryData.length > 0 &&
                  categoryData.map((item, index) => (
                    <MenuItem key={index} value={item.sortKey}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {/* Service filter */}
        {/* <Grid item xs={12} onClick={() => handleOpenFilter('service')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.service && classes.closeY,
                    openFilter.service && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Service</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
        {/* {openFilter.service && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Service</InputLabel>
              <Select
                onChange={(event) => ChangeService(event.target.value)}
                value={filters.serviceId ?? ''}
                defaultValue={`All`}
                sx={{
                  width: '100%'
                }}
                label="Service"
                className="status-field field-label-css"
              >
                <MenuItem key={0} value="">
                  {' '}
                  None{' '}
                </MenuItem>
                {service.length > 0 &&
                  service.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )} */}
        {/* Free filter */}
        {/* <Grid item xs={12} onClick={() => handleOpenFilter('free')} pt={0.5}>
                    <Paper>
                        <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            className="pointer"
                        >
                            <Grid item>
                                <KeyboardArrowRightRoundedIcon
                                    className={clsx(
                                        !openFilter.free && classes.closeY,
                                        openFilter.free && classes.openY
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>Free</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid> */}
        {openFilter.free && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Free</InputLabel>
              <Select
                onChange={(event) => ChangeFree(event.target.value)}
                value={filters.free ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Free"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} value="">
                  {' '}
                  None{' '}
                </MenuItem> */}
                <MenuItem key={1} id={2} value={'true'}>
                  Free
                </MenuItem>
                <MenuItem key={2} id={3} value={'false'}>
                  Paid
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        {/* SellOnline filter */}
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('sellonline')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.sellonline && classes.closeY,
                    openFilter.sellonline && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Sell Online</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.sellonline && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Sell Online</InputLabel>
              <Select
                onChange={(event) => ChangeSellOnline(event.target.value)}
                value={filters.sellOnline ?? ''}
                sx={{
                  width: '100%'
                }}
                label="sellonline"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} value="">
                  {' '}
                  None{' '}
                </MenuItem> */}
                <MenuItem key={1} id={2} value={'true'}>
                  Yes
                </MenuItem>
                <MenuItem key={2} id={3} value={'false'}>
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};
