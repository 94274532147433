import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import { HiOutlineDocumentText } from 'react-icons/hi';

export function Accordian(props) {
  const { notesData, selectedReason } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        style={{
          padding: '0',
          minHeight: '55px !important',
          alignItems: 'flex-start !important'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            display="flex"
            alignItems="center"
            gap={4}
            sx={{ marginBottom: '0px !important' }}
          >
            <HiOutlineDocumentText className="orange-icons icon-adjustment-summary" />
            <h3 className="accordian-header">Adjustment Reason</h3>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0px 20px 20px 65px',
            color:' #000000 !important',
            fontSize: '16px !important',
            fontWeight: '400 !important',
            lineheight: '19px',
            fontFamily: 'Myriad Pro, sans-serif !important',
            fontStyle: 'normal !important'
          }}
        >
          <Typography sx={{ textAlign: 'left'}}>{selectedReason}</Typography>
          {notesData ? (
            <>
              <Typography sx={{ marginTop: '16px', fontWeight: '600', textAlign: 'left' }}>
                Notes:{' '}
              </Typography>
              <Typography>{notesData}</Typography>
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
