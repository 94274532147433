/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  // Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  TablePagination,
  Box,
  Menu
} from '@mui/material';
import { DateTime } from 'luxon';
import { dateFormat } from '../../utils/index';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ListView = (props) => {
  const { currentDate, data, handleOpenModal, setDialog, dialog, view } = props;

  const [calendarDate, setCalendarDate] = useState();
  const [listItem, setListItem] = useState([]);
  const [itemData, setItemData] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [anchor, setAnchor] = useState(null);



  const openMenu = (event, item) => {
    setItemData(item);
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };

  const onClassDetail = (item, key) => {
    setAnchor(null);
    handleOpenModal(itemData, 'data', key);
  };
  function convertUtcToLocal(utcDateTimeString) {
    // Return the input string without converting to local time
    return utcDateTimeString;
  }

  function convertUtcToUserTimeEdit(utcDateTimeString) {
    const formattedInput = utcDateTimeString?.replace(/\s+/g, ''); // Remove all spaces
    const adjustedFormat = 'M/d/yyyy,h:mma';

    const utcDate = DateTime.fromFormat(formattedInput, adjustedFormat, {
      zone: 'utc'
    });

    if (!utcDate.isValid) {
      return 'Invalid UTC DateTime'; // Handle invalid input
    }

    const formattedTime = utcDate.toFormat('hh:mm a');

    return formattedTime;
  }

  useEffect(() => {
    const compareDate = new Date(currentDate);
    const startOfWeek = new Date(currentDate);
    const endOfWeek = new Date(currentDate);

    const currentDay = compareDate.getDay();
    // Calculate the start and end dates of the current week
    startOfWeek.setDate(currentDate.getDate() - ((currentDay + 1) % 7) + 1);
    endOfWeek.setDate(currentDate.getDate() + (7 - currentDay)); // End on Saturday
    const filteredList = data.filter((item) => {
      if (item.fromDate) {
        try {
          // Parse the API date string from item.fromDate
          const convertedDate = convertUtcToLocal(item.fromDate);
          const itemDate = new Date(convertedDate);
          if (!(itemDate instanceof Date) || Number.isNaN(itemDate)) {
            throw new Error('Invalid Date');
          }
          let isSameDate = new Date();
          if (view === 'week') {
            isSameDate = itemDate >= startOfWeek && itemDate <= endOfWeek;
          } else {
            // Compare the date part (year, month, and day) of the current date and itemDate
            isSameDate = compareDate.toDateString() === itemDate.toDateString();
          }
          return isSameDate;
        } catch (error) {
          console.error(`Error processing item: ${error.message}`);
        }
      }
      return false;
    });

    setListItem(filteredList);
    const dateParts = dateFormat(currentDate).split('-');
    const formattedDate = new Date(
      `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    ).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });

    setCalendarDate(formattedDate);
  }, [data, currentDate, view]);

  const paginatedLists = listItem?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  function formatDateWithMonth(inputDate) {
    const date = new Date(inputDate);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${dayOfWeek}, ${month} ${
      day < 10 ? '0' : ''
    }${day}, ${year}`;

    return formattedDate;
  }
  return (
    <>
      <TableContainer
        className="location-table-container servies-details-table"
        sx={{ borderRadius: '19px 19px 0px 0px', marginTop: '60px' }}
      >
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell colSpan={7}>
                {/* <span>{formatDateWithMonth(currentDate)}</span> */}
                <span>Schedules</span>
                {/* <span>{calendarDate}</span> */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Schedule Name</TableCell>
              <TableCell>Trainer</TableCell>
              <TableCell>Schedule Date</TableCell>
              <TableCell>Schedule Time</TableCell>
              <TableCell>Booking Availability</TableCell>
              <TableCell>Room</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLists?.map((item, index) => {
              let bookingAvailability;
              if (item.scheduleType === 'enrollment') {
                bookingAvailability = `${item.clientDetails?.length ?? 0} / ${
                  item.maximumCapacity
                }`;
              } else {
                bookingAvailability = `${item.clientDetails?.length ?? 0} / ${
                  item.clientDetails?.length
                }`;
              }

              // For other schedule types with clientDetails as an array
              // bookingAvailability = `${item.clientDetails?.length ?? 0} / ${item.maximumCapacity}`;

              return (
                <TableRow
                  key={index}
                 
                >
                  <TableCell>{item.serviceName}</TableCell>
                  <TableCell>{item.trainerName}</TableCell>
                  <TableCell>{formatDateWithMonth(item.fromDate)}</TableCell>
                  <TableCell>{`${convertUtcToUserTimeEdit(
                    item.fromDate
                  )} - ${convertUtcToUserTimeEdit(item.toDate)}`}</TableCell>
                  <TableCell>{bookingAvailability}</TableCell>
                  <TableCell>{item.room.name}</TableCell>
                  <TableCell className="table-td">
                    <MoreVertIcon
                      onClick={(e) => openMenu(e, item)}
                      color="grey"
                      variant="contained"
                      className="three-dots-icon"
                    />
                   
                      <Menu
                        open={Boolean(anchor)}
                        anchorEl={anchor}
                        onClose={closeMenu}
                        keepMounted
                        PaperProps={{
                          style: {
                            maxHeight: 40 * 7,
                            width: '25ch'
                          }
                        }}
                      >
                        <MenuItem onClick={() => onClassDetail(props)}>
                          View Class Details
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            onClassDetail(props, 'outSideData');
                            setDialog({ ...dialog, bookClass: true });
                          }}
                        >
                          Enroll New Pet
                        </MenuItem>
                      </Menu>
                  
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
     
        <Box p={2} sx={{ display: 'flex-end' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={listItem?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </TableContainer>
    </>
  );
};
export default ListView;
