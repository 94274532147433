/* eslint-disable eqeqeq */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useContext } from 'react';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, CircularProgress, Typography} from '@mui/material';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';



import {
  AdjustmentTable,
  AdjustmentFilters
} from 'src/components/Inventory/index';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import GlobalData from 'src/global';
import { CustomButton, SearchInput } from 'src/components/Global';
import { getLocalData, setFullLocationId } from 'src/utils';
import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import Loader from '../../../../components/Loader';
import Header from 'src/components/Header';
import { useNavigate } from 'react-router-dom';

const AdjustmentHistory = () => {
  // change the header values
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Adjustment History');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = '';
  const title = GlobalData[0].title;

  // const BASE_URL = 'https://rma2levr48.execute-api.us-east-1.amazonaws.com/dev';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilters, setIsFilters] = useState(false);
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [filterData, setFilterData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [filters, setFilters] = useState({
    numberOfItems: '',
    totalCost: '',
    adjustmentId: '',
    // updatedBy: '',
    operatorName: '',
    updatedAt: ''
  });

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === -1 ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getFilterData = async () => {
    setLoading(true);
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await fetch(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/adjustInventoryHistory?locationId=${encodedLocationId}`
      );

      if (response.ok) {
        const data = await response.json();
        const modifiedData = data?.data?.Items?.map((item) => ({
          ...item,
          id: item.adjustmentId
        }));
        setFilterData(modifiedData);
        setLoading(false);
      } else {
        setLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.message || 'Something went wrong with Adjustment History'
        );
        // toast.error(
        //   response.message || 'Something went wrong with Adjustment History'
        // );
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };
  const handleCloseDetails = () => {
    navigate('/inventory-adjustment');
  };

  const getAdjustmentHistory = async () => {
    setLoading(true);
    let queryString = filtersQuery();
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await fetch(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/adjustInventoryHistory?locationId=${encodedLocationId}&${queryString}`
      );

      if (response.ok) {
        const data = await response.json();
        const modifiedData = data?.data?.Items?.map((item) => ({
          ...item,
          id: item.adjustmentId
        }));

        setHistoryData(modifiedData);
        setLoading(false);
      } else {
        setLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.message || 'Something went wrong with Adjustment History'
        );
        // toast.error(
        //   response.message || 'Something went wrong with Adjustment History'
        // );
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };

  useEffect(() => {
    getAdjustmentHistory();
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    getFilterData();
  }, []);
  return (
    <>
      {/* <Helmet>
        <title>Adjustment History | {title}</title>
      </Helmet> */}
      <Box sx={{ paddingX: "22px" }} className='mt-10' >
        <Typography
          variant="variant2"
          sx={{
            fontSize: '24px',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
          className='text-primary weight-600'
        >
          <Box
            onClick={() => handleCloseDetails()}
            className="back-arrow-close mt-10"
          > 
            <ArrowLeft />
          </Box>
          <Typography className="header-blue-40 ">
          Adjustment History 
          </Typography>
        </Typography>
      </Box>
      {/* <Header title="Adjustment History" /> */}
      <Loader IsLoading={loading} />
      <Container maxWidth="100%" className="custom-container">
        {showMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowMessage}
          />
        )}
        <Box justifyContent="center" direction="column">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item width={'100%'}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                marginTop="20px"
              >
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <h2
                      className="title mb-0 mt-10"
                      style={{
                        fontFamily: 'Myriad Pro, sans-serif',
                        color: '#525252'
                      }}
                    >
                      Track Inventory Adjustments Record
                    </h2>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item width={'100%'}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item marginY="20px">
                    <CustomButton
                      onClick={() => setIsFilters(!isFilters)}
                      variant={isFilters ? 'contained' : 'outlined'}
                    >
                      Filters
                      <KeyboardArrowDownRoundedIcon
                        className={clsx(
                          !isFilters && classes.closeY,
                          isFilters && classes.openY
                        )}
                      />
                    </CustomButton>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    marginY={{ sm: '20px' }}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <SearchInput
                      value={searchQuery}
                      setSearchQuery={setSearchQuery}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {/* {loading ? (
              <CircularProgress sx={{ marginTop: '80px' }} />
            ) : ( */}
            <>
              {isFilters ? (
                <Grid item width={isFilters ? 300 : 0}>
                  <AdjustmentFilters
                    filters={filters}
                    setFilters={setFilters}
                    data={historyData}
                    filterData={filterData}
                  />
                </Grid>
              ) : null}

              <Grid item width={`calc(100% - ${isFilters ? 300 : 0}px)})`}>
                <AdjustmentTable
                  tableRows={historyData}
                  searchQuery={searchQuery}
                  // openDetail={openDetail}
                  // loading={tableLoading}
                />
              </Grid>
            </>
            {/* )} */}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AdjustmentHistory;
