import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, TablePagination, CardHeader, Card, Grid, Box, Button, IconButton, useTheme,
    
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';


export const CampaignTable = ({
     filters,
    data, label, filterCompoenent,
    handleOnClick = () => { console.log('no event'); },
    selectedLists,
    
}) => {
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keys, setKeys] = useState([]);
    const [displayFilterForm, setdisplayFilterForm] = useState(false);
    const theme = useTheme();

    const useStyles = makeStyles(() => ({
        openY: {
            transform: 'scale(-1)',
            color: '#FFFFFF'
        },
        closeY: {
            transform: 'scale(1)',
            color: '#003087'
        }
    }));
    const classes = useStyles();

    const handleSearchTextChange = (event) => {
        const searchText = event.target.value.toLowerCase();
        setSearchText(searchText);
        setPage(0); // Reset page when search text changes
    };

    const filteredData = data.filter((item) => {
        return Object.values(item).some((value) =>
            value ? value.toString().toLowerCase().includes(searchText) : ''
        );
      
    });


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page when rows per page changes
    };

    const paginatedData = filteredData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

console.log('-----', paginatedData)
    const showFilter = () => {
        setdisplayFilterForm(!displayFilterForm);
    };

    const getFilterSize = () => {
        return displayFilterForm ? 300 : 0;
    };

    const childOnClick = (e, rest) => {
        e.preventDefault();
        e.stopPropagation();
        handleOnClick(rest);
    }

  
    useEffect(() => {
        let key = data && data.length > 0 && data[0] ? Object.keys(data[0]) : '';
        if (key) setKeys(key);
        // setKeys(['name', 'age', 'profession']);
    }, [data]);

   useEffect(()=>{
    setPage(0)
   },[filters])
   

    // Handler for selected items
   

    return (
        <Box sx={{ p: 2 }}>
            {/* Search input with filter */}
            <Grid item xs={12} sx={{ padding: "0 1rem" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Grid
                        item
                        sx={{
                            marginTop: '10px',
                            marginBottom: '20px'
                        }}
                    >
                        {filterCompoenent && (<Button
                            onClick={showFilter}
                            variant={displayFilterForm ? 'contained' : 'outlined'}
                        >
                            Filters
                            <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                    !displayFilterForm && classes.closeY,
                                    displayFilterForm && classes.openY
                                )}
                            />
                        </Button>)}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '10px',
                            marginBottom: '20px'
                        }}
                        className="top-filter-cont">
                       
                        <TextField
                            type="search"
                            className="Search-field"
                            label="Search"
                            InputProps={{
                                style: {},
                                endAdornment: <SearchIcon />
                            }}
                            onChange={handleSearchTextChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* END */}

            <Grid container spacing={2} sx={{ padding: "0 1rem" }}>
                {displayFilterForm === true ? (
                    <Grid item width={getFilterSize()}>
                        {filterCompoenent}
                        
                    </Grid>
                ) : null}

                <Grid item sx={{ width: `calc(100% - ${getFilterSize()}px)` }}>
                    <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
                        {/* <CardHeader title={label} className="table-header-name" sx={{ fontSize: '20px', color: '#003087' }} /> */}
                        {/* Table Header title and status field */}
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={5} className='d-flex align-item-center table-header-title' >
                                <CardHeader
                                    title={label}
                                    className="table-header-name font-20 text-primary"
                                />
                            </Grid>
                           
                        </Grid>
                        <TableContainer className="Custom-Table table-container common-table-container">
                            <Table>
                                <TableHead className='common-header'>
                                    <TableRow key={0} >
                                        {/* Header checkbox */}
                                       
                                        {keys.map((item, indexhead) => (
                                            indexhead > 1 ? <TableCell key={indexhead} className={indexhead < 2 ? 'h-partitionKey h-sortKey' : ''}>{item}</TableCell> : ''
                                        ))}
                                        <TableCell />
                                        {/* Add more table headers as needed */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.map((item, indexpage) => {
                                        const isItemSelected = (selectedLists && selectedLists?.length > 0 && selectedLists.includes(item.sortKey));
                                        const isCampaignCompleted = item?.Status === "Completed";

                                        return (<>
                                            <TableRow key={indexpage} className="table-row cursor-pointer" selected={isItemSelected}>
                                                {/* Checkbox for individual rows */}
                                               
                                                {keys.map((key, indexkey) => (
                                                    indexkey > 1 ? <TableCell key={indexkey} onClick={(e) => childOnClick(e, [item['partitionKey'], item['sortKey'], 'Detail'])} >{item[key]}</TableCell> : ''
                                                ))}
                                                <TableCell className="table-td"  >
                                                {isCampaignCompleted  ? null :
                                                    <IconButton sx={{ '&:hover': { background: theme.colors.primary.lighter }, color: theme.palette.primary.main }}
                                                        color="inherit" size="small"
                                                         className="edit-btn"
                                                        id={'edit_btn_' + keys.sortKey}
                                                        onClick={(e) => childOnClick(e, [item['partitionKey'], item['sortKey'], 'Edit'])} >Edit</IconButton>}
                                                </TableCell>
                                                {/* Add more table cells as needed */}
                                            </TableRow>
                                        </>)
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Box >
    );
};
