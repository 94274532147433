/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CohortsFilter from './CohortsFilter';
import clsx from 'clsx';
import {
  // Tooltip,
  Button,
  // Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  // Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Grid,
  Slide,
  Container,
  Checkbox,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../../../services/ApiService';
import { CustomButton, CustomSelect } from 'src/components/Global';
import moment from 'moment';

// const [showScreen, setShowScreen] = useState(0);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CohortsTable = ({
  CohortList,
  sideBarfilters,
  setSidebarFilters,
  selectedCohortList,
  handleBulkAdd,
  btnLoading,
  getAllFranchisorCohorts,
  setIsLoading,
  setSelectedCohortList,
  selectedStatus,
  setSelectedStatus
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [states, SetStates] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const [searchText, setSearchText] = useState(''); // Created new state for search functionality
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keys, setKeys] = useState([]);
  const [openDescription, setOpenDescription] = useState(false)
  const [openDescriptionTemplate, setOpenDescriptionTemplate] = useState(false)
  const [viewDescription, setViewDescription] = useState(false)
  const [viewDescriptionTemplate, setViewDescriptionTemplate] = useState(false)
  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState(false)
  const [descriptionData, setDescriptionData] = useState('')

  console.log(keys);


  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };


  function convertIsoToNormalDate(isoDate) {
    const normalDate = moment(isoDate).format('MM-DD-YYYY');
    return normalDate;
  }

  useEffect(() => {
    setPage(0);
  }, [CohortList]);

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredCohortsLists = CohortList?.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchText) : ''
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };
  const paginatedCohortLists = filteredCohortsLists.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.log(paginatedCohortLists, 'filteredCohortsLists');
  const showFilter = () => {
    if (states.length === 0)
      setdisplayFilterForm(!displayFilterForm);
    console.log(displayFilterForm, 'displayFilterForm-------');
  };


  const theme = useTheme();


  useEffect(() => {
    let key =
      CohortList && CohortList.length > 1 && CohortList[0]
        ? Object.keys(CohortList[0])
        : '';
    if (key) setKeys(key);
    // setKeys(['name', 'age', 'profession']);
  }, [CohortList]);

  const handleSelectAllCohorts = (event) => {
    setSelectedCohortList(
      event.target.checked
        ? CohortList.map((cohortItem) => cohortItem.sortKey)
        : []
    );
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const handleSelectOneCohortItem = (event, cohortItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedCohortList.includes(cohortItemId)) {
      setSelectedCohortList((prevSelected) => [
        ...prevSelected,
        cohortItemId
      ]);
    } else {
      setSelectedCohortList((prevSelected) =>
        prevSelected.filter((id) => id !== cohortItemId)
      );
    }
  };
  const openMenu = (cohortItem, event) => {
    // setStaffId(staffItem.staffId);
    setDescriptionData(cohortItem)
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);

  };
  console.log(descriptionData.description, 'eventevent');
  const onMenuItemClick = (event, value, cohortItem) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(null);

    if (value === 'Add') {
      setOpenDescription(true);
      setOpenDescriptionTemplate(true)
    }
    if (value === 'View') {
      setViewDescription(true);
      setViewDescriptionTemplate(true)
    }
  };
  const handleDescriptionTemplateClose = () => {
    setOpenDescription(false);
    setDescription()
    setViewDescription(false)
    setViewDescriptionTemplate(false)
    setDescriptionError(false)
    setOpenDescriptionTemplate(false)
  }
  const addDescription = () => {
    if (!description) {
      setDescriptionError('Description is required')
    }
    else {
      addDescriptionData()
    }
  }
  const descriptionSet = (e) => {
    setDescription(e.target.value)
    setDescriptionError('')
  }

  const addDescriptionData = async () => {
    setIsLoading(true)
    let body = {
      partitionKey: descriptionData.partitionKey || '',
      sortKey: descriptionData.sortKey || '',
      description: description || '',
    }
    try {
        let response = await ApiService.post(
            `${process.env.REACT_APP_API_END_POINT}pinpoint/addDescriptionForFranchisor`,body
        );

        if (!response.error) {
          setOpenDescription(false);
          setIsLoading(false);
          setDescriptionError(false)
          setOpenDescriptionTemplate(false)
          getAllFranchisorCohorts()
        }
    } catch (error) {
        console.log(error);
        setIsLoading(false);
    }
};

  const selectedSomeCohortItems =
    selectedCohortList.length > 0 &&
    selectedCohortList.length < CohortList.length;
  const selectedAllCohortItems =
    selectedCohortList.length === CohortList.length;
  return (
    <>

      <Container maxWidth="100%" >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"

        >

          <Grid xs={12} sx={{}}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid
                item
                sx={{
                  marginTop: '10px',
                  marginBottom: '20px'
                }}
              >
                <Button
                  onClick={showFilter}
                  variant={displayFilterForm ? 'contained' : 'outlined'}
                >
                  Filters
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !displayFilterForm && classes.closeY,
                      displayFilterForm && classes.openY
                    )}
                  />
                </Button>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                  marginBottom: '20px'
                }}
                className="top-filter-cont"
              >
                <TextField
                  type="search"
                  className="Search-field"
                  label="Search"
                  InputProps={{
                    style: {},
                    endAdornment: <SearchIcon />
                  }}
                  onChange={handleSearchTextChange}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container >
            {displayFilterForm === true ? (
              <Grid item width={getFilterSize()}>
                <CohortsFilter
                  CohortList={CohortList}
                  filters={sideBarfilters}
                  setFilters={setSidebarFilters}
                />
              </Grid>
            ) : null}

            <Grid
              item
              sx={{
                width: `calc(100% - ${getFilterSize()}px)`
              }}
            >
              <Card
                sx={{
                  padding: '15px',
                  borderRadius: '19px 19px 0px 0px'
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '12px'
                    }}
                  >
                    <CardHeader
                      title="All Cohorts"
                      className="table-header-name"
                      sx={{ fontSize: '20px', color: '#003087' }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '12px'
                    }}
                    className="membership-status-container status-field-ddl"
                  >
                    <CustomSelect
                      fullWidth
                      name="selectedStatus"
                      disabled={!selectedCohortList?.length}
                      value={selectedStatus}
                      onChange={(type, value) =>
                        setSelectedStatus(value)
                      }
                      label="Select Status"
                      options={[
                        {
                          label: 'Shared',
                          value: 'Shared'
                        },
                        {
                          label: 'Private',
                          value: 'Private'
                        }
                      ]}
                    />
                    <CustomButton
                      variant="contained"
                      disabled={
                        selectedStatus === '' ||
                        !selectedCohortList?.length
                      }
                      onClick={handleBulkAdd}
                      loading={btnLoading}
                    >
                      Update
                    </CustomButton>
                  </Grid>
                </Grid>
                <TableContainer className="location-table-container">
                  <Table>
                    <TableHead>
                      <TableRow className="table-header">
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedAllCohortItems}
                            indeterminate={selectedSomeCohortItems}
                            onChange={handleSelectAllCohorts}
                          />
                        </TableCell>
                        <TableCell>Cohorts Name</TableCell>
                        <TableCell>Created</TableCell>
                        {/* <TableCell>Last Modified</TableCell> */}
                        <TableCell >Status</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedCohortLists.map((cohortItem) => {
                        const isCohortItemSelected =
                          selectedCohortList.includes(
                            cohortItem.sortKey
                          );
                        return (
                          <TableRow
                            hover
                            key={cohortItem.sortKey}
                            selected={isCohortItemSelected}
                            className="table-row cursor-pointer"

                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isCohortItemSelected}
                                onChange={(event) =>
                                  handleSelectOneCohortItem(
                                    event,
                                    cohortItem.sortKey
                                  )
                                }
                                value={isCohortItemSelected}
                              />
                            </TableCell>
                            <TableCell
                              className="table-td -flex"
                              sx={{ minWidth: '200px' }}
                            >
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                sx={{ marginTop: 1 }}
                                gutterBottom
                              >
                                {cohortItem.Name}
                              </Typography>
                            </TableCell>
                            <TableCell className="table-td">
                              <Typography>
                                {convertIsoToNormalDate(cohortItem.CreationDate)}
                              </Typography>
                            </TableCell>
                            {/* <TableCell className="table-td">
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {convertIsoToNormalDate(cohortItem.LastModifiedDate)}
                              </Typography>
                            </TableCell> */}
                            <TableCell className="table-td">
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {cohortItem.status}
                              </Typography>
                            </TableCell>
                            <TableCell className="table-td">

                              <MoreVertIcon
                                onClick={(e) => openMenu(cohortItem, e)}
                                color="grey"
                                variant="contained"
                                className="three-dots-icon"
                              />

                              <Menu
                                open={Boolean(anchor)}
                                anchorEl={anchor}
                                onClose={closeMenu}
                                keepMounted
                                PaperProps={{
                                  style: {
                                    maxHeight: 40 * 5,
                                    width: '25ch'
                                  }
                                }}
                              >
                                <MenuItem
                                  onClick={(e) =>
                                    onMenuItemClick(
                                      e,
                                      'Add',
                                      cohortItem
                                    )
                                  }
                                >
                                  Add a description
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) =>
                                    onMenuItemClick(
                                      e,
                                      'View',
                                      cohortItem
                                    )
                                  }
                                >
                                  View description
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box p={2}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={filteredCohortsLists.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Box>
              </Card>
              {openDescription && (
                <Dialog
                  open={openDescriptionTemplate}
                  TransitionComponent={Transition}
                  keepMounted
                  // onClose={() => handleAddteamClose('Cancel')}
                  aria-describedby="details-dialog"
                  className="dialog-box dialog-box-right not "
                  maxWidth="sm"
                  sx={{ maxHeight: '460px', mt: 15, ml: 2 }}
                >
                  <Box>
                    <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
                      <Box
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <Typography className="text-create-template" >ADD A DESCRIPTION</Typography>
                        <Button
                          className="details-remove-icon task-cross p-0 no-hover"
                          onClick={() => handleDescriptionTemplateClose()}
                        >
                          <svg
                            width="30"
                            height="31"
                            viewBox="0 0 30 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                              fill="#E64646"
                            />
                          </svg>
                        </Button>
                      </Box>
                    </DialogTitle>
                  </Box>
                  <DialogContent className="-team-container-white-box">
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      className="alert-decription"
                    >

                      <TextField
                        fullWidth
                        multiline
                        id="my-textarea"
                        name="Description"
                        autocomplete="Description"
                        label="DESCRIPTION"
                        placeholder="Description "
                        variant="outlined"
                        value={description}
                        error={Boolean(descriptionError)}
                        helperText={descriptionError}
                        className="field-top-margin-edit-location "
                        // onChange={(e) => validateMessage(e.target.value)}
                        onChange={(e) => descriptionSet(e)}
                        InputLabelProps={{
                          className: 'label-style-create-franchisee'
                        }}
                        InputProps={{ rows: 6 }}
                      />

                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mt: 2 }}>
                        <Button
                          fullWidth
                          variant="outlined"
                          className="cohort-btn-outline field-top-margin-edit-location"
                          onClick={() => handleDescriptionTemplateClose()}
                        >
                          <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>Cancel</Typography>
                        </Button>
                        <Button
                          type="submit"
                          fullWidth
                          onClick={addDescription}
                          variant="contained"
                          className="button-style-add-team field-top-margin-edit-location"
                        >
                          <Typography className="next-button-text">Save</Typography>
                        </Button>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              )}

            </Grid>
            {viewDescription && (
              <Dialog
                open={viewDescriptionTemplate}
                TransitionComponent={Transition}
                keepMounted
                // onClose={() => handleAddteamClose('Cancel')}
                aria-describedby="details-dialog"
                className="dialog-box dialog-box-right not "
                maxWidth="sm"
                sx={{ maxHeight: '350px', mt: 15, ml: 2 }}
              >
                <Box>
                  <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography className="text-create-template" >VIEW DESCRIPTION</Typography>
                      <Button
                        className="details-remove-icon task-cross p-0 no-hover"
                        onClick={() => handleDescriptionTemplateClose()}
                      >
                        <svg
                          width="30"
                          height="31"
                          viewBox="0 0 30 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                            fill="#E64646"
                          />
                        </svg>
                      </Button>
                    </Box>
                  </DialogTitle>
                </Box>
                <DialogContent className="-team-container-white-box">
                  <DialogContentText
                    id="alert-dialog-slide-description"
                    className="alert-decription"
                  >

                    <TextField
                      fullWidth
                      multiline
                      id="my-textarea"
                      name="Description"
                      autocomplete="Description"
                      label="DESCRIPTION"
                      placeholder="Description "
                      variant="outlined"
                      disabled
                      value={descriptionData.description}
                      error={Boolean(descriptionError)}
                      helperText={descriptionError}
                      className="field-top-margin-edit-location "
                      onChange={(e) => descriptionSet(e)}
                      InputLabelProps={{
                        className: 'label-style-create-franchisee'
                      }}
                      InputProps={{ rows: 6 }}
                    />


                  </DialogContentText>
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

CohortsTable.propTypes = {
  CohortList: PropTypes.array.isRequired
};

CohortsTable.defaultProps = {
  CohortList: []
};

export default CohortsTable;
