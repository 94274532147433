/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable no-useless-computed-key */

/* eslint-disable spaced-comment */

/* eslint-disable no-restricted-syntax */

/* eslint-disable no-else-return */

/* eslint-disable consistent-return */

/* eslint-disable react/self-closing-comp */

/* eslint-disable eqeqeq */

/* eslint-disable no-unused-vars */

import React,{useState} from "react";

import { Box, Button, Dialog, DialogContent, Grid, Slide, TextField ,FormHelperText} from "@mui/material";

import { ApiService } from "../../../services/ApiService";

 

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

 

 

const Transition = React.forwardRef(function Transition(props, ref) {

  return <Slide direction="left" ref={ref} {...props} />;

});

 

export function TemplateModal({ open,tempError, handleClose, value, setValues,isLoading,errorMessage,showErrorMessage,IsTempExists  }) {

  const [templateExistsError, setTemplateExistsError] = useState('');
  const [isError, setIsErrors] = useState(false);
  const [isSpace, setIsSpace] = useState('');
 // const [templateExistsError, setTemplateExistsError] = useState('');

  const handleTemplateNameChange = (val) => {
    let name = val
  setValues({ ...value, templateName: name});
  console.log('nameeee',name)
  const regex =  /^[a-zA-Z0-9\-_]+$/;
  if (!regex.test(name)) {
    setIsSpace('Invalid characters found (space, @, # etc)');
    setIsErrors(true)
  } else {
    setIsSpace('')
    setIsErrors(false)

  }
    if(tempError['templateName']){
      tempError.templateName = '';
    }
   
  }

  const handleTemplatMsgChange = (e) => {
    let msg = e.target.value
    setValues({ ...value, templateMessage: msg })
  if(tempError['templateMessage']){
      tempError.templateMessage = '';
    }

  }

  const handleTemplatDescriptionChange = (e) => {
    let description = e.target.value
    setValues({ ...value, templateDescription: description })
  }

  // check template exists or not

  const checkTemplateNameExist = async (name) => {
    let templateNameCheck = name.value;
    const data = {
      TemplateName: templateNameCheck || ''
    };
    try {

      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/isExistsTemplate`,
        data
      );

      if (response.statusCode && !response.error) {

        console.log(response.data, 'hgghg');

        if (response.data.isExists === true) {

          if(templateNameCheck){ setIsSpace('')

          setIsErrors(false)
           setTemplateExistsError('Template name already exists');
           setValues({ ...value, tempExitsError: 'Template name already exists' })
          }else{
            setTemplateExistsError('')

          }

        }else{
         setTemplateExistsError('')
        }

      }

    } catch (error) {

      console.log(error);

    }

  };


  return (

    <>

      <Dialog

        className="enrollClassDialog templateModal"

        open={open}

        TransitionComponent={Transition}

        keepMounted

        onClose={handleClose}

        aria-describedby="alert-dialog-slide-description"


      >

        <DialogContent className="custom-scroll">

          <Box

            sx={{ width: "100%"}}

            paddingBottom="20px"

            className="Main-container common-form-containers"

          >

            <Box className="">

              <Grid mt={0} marginBottom="8px">

                <Grid

                  container

                  direction="row"

                  justifyContent="space-between"

                  alignItems="center"

                >
                  <Box

                    sx={{

                      display: "flex",

                      justifyContent: "space-between",

                      alignItems: "center",

                      width: "100%",

                      marginBottom: "20px"

                    }}

                  >

                    <h1 className="title" style={{ color: "#000", textTransform: "uppercase", fontSize: "20px" }}>Create

                      Template</h1>

                    <CancelOutlinedIcon
                      color="error"
                      sx={{
                       width: "32px",
                        height: "32px",
                        cursor: "pointer"
                      }}
                     onClick={handleClose}

                    />

                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "30px" }}>

                    <TextField

                      name="templateName"

                      id="outlined-required"                  

                      className="text-field-styling input-field-styling"

                      label={"TEMPLATE NAME *"}

                      value={value.templateName}

                       onChange={(e)=>handleTemplateNameChange(e.target.value)}

                       onKeyUp={(e)=> checkTemplateNameExist(e.target)}

                       error={!!tempError.templateName  || !!templateExistsError}

                       helperText={tempError.templateName ? tempError.templateName : value.tempExitsError ? templateExistsError :''}

                       InputLabelProps={{

                        className: "label-style-text-field label-style-create-franchisee"

                      }}

                    />

                      {isError && (

                      <FormHelperText sx={{marginTop:'-23px'}}>

                        {value.templateName ? isSpace : ''}

                      </FormHelperText>

                      )}

                    <TextField

                      name="templateMessage"

                      InputProps={{

                        rows: 8

                      }}

                      multiline

                      id="outlined-required"

                     

                      label="MESSAGE *"

                      className=""

                      value={value.templateMessage}

                      onChange={handleTemplatMsgChange}

                      error={!!tempError.templateMessage}

                      helperText={tempError.templateMessage}

                      InputLabelProps={{

                        className: 'label-style-text-field'

                      }}

                    />

                    <TextField

                   

                      name="templateDescription"

                      InputProps={{

                        rows: 8

                      }}

                      multiline

                      id="outlined-required"

                      label="DESCRIPTION"

                      className=""

                      value={value.templateDescription}

                      onChange={handleTemplatDescriptionChange}

                      // onChange={(e) =>

                      //   setValues({ ...value, templateDescription: e.target.value })

                       

                      // }

                      InputLabelProps={{

                        className: "label-style-text-field"

                      }}

                    />

                    {showErrorMessage && (

                        <FormHelperText>

                          {errorMessage}

                        </FormHelperText>

                      )}

                    <Button

                      variant="contained"

                      className="top-button-style"

                      isLoading = {isLoading}

                      sx={{ padding: "15px", cursor : "pointer" , mt:0}}

                      disabled={tempError.templateName || isError || templateExistsError}

                     onClick={() => handleClose("save")}

                     // onClick={handleClose("save")}

                    >

                      Save

                    </Button>

                  </Box>

                </Grid>

              </Grid>

 

 

            </Box>

          </Box>

        </DialogContent>

      </Dialog>

    </>

  );

}