import React, { useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@mui/material';
import { ApiService } from 'src/services/ApiService';

const StaffSchedulesbyTimeoffTable = (props) => {
  const { TableHeading, apiData, convertToCustomFormat,setIsDisabled,setShowErrorMessage} = props;
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    async function DataFetching() {
      try {
        let response = await ApiService?.post(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getStaffSchedulesbyTimeoff`,
          apiData
        );
        if (response.statusCode === 200) {
          setUserData(response.data.Items);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (apiData.trainerId && apiData.startTime[0] !== 'N') {
      DataFetching();
    }
  }, [apiData]);
useEffect(()=>{
if(userData?.length>0){
  setIsDisabled(true)
  setShowErrorMessage('This trainer is not available for the selected time.')
} else if(userData.length === 0){
  setIsDisabled(false)
}
},[userData])
  return (
    <>
      {userData?.length > 0 && (
        <div>
          <Typography
            sx={{
              fontWeight: 'bold ',
              color: '#003087 ',
              fontSize: '20px ',
              marginBottom: 2
            }}
          >
            {' '}
            Trainer Schedule
          </Typography>
          <TableContainer
            className="location-table-container servies-details-table"
            sx={{
              borderRadius: '19px 19px 0px 0px',
              maxHeight: 210,
              overflowX: 'hidden'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {TableHeading.map((heading, ind) => {
                    return <TableCell key={ind}>{heading}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              {userData?.map((data, ind) => {
                return (
                  <TableBody key={ind}>
                    <TableCell>{data?.serviceName}</TableCell>
                    <TableCell>
                      {convertToCustomFormat(data?.fromDate)}
                    </TableCell>
                    <TableCell>{convertToCustomFormat(data?.toDate)}</TableCell>
                    <TableCell>{data?.startTime}</TableCell>
                    <TableCell>{data?.endTime}</TableCell>
                  </TableBody>
                );
              })}
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};
export default StaffSchedulesbyTimeoffTable;
