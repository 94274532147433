import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Avatar from '@mui/material/Avatar';
import Cookies from 'universal-cookie';
import { ApiService } from '../../../services/ApiService';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  lighten,
  // Tooltip,
  IconButton,
  darken,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Slide
} from '@mui/material';

// import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
// import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import Loader from 'src/components/Loader';
// import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
// import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { clearLocalData } from '../../../utils';
import StaffProfile from 'src/components/StaffProfile';
import { getLocalData } from '../../../utils';
import { UserContext } from 'src/contexts/UserContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 110px;
`
);

function Sidebar() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { sidebarToggle, toggleSidebar, sidebarToggleHalf, toggleSidebarHalf } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const closeSidebarHalf = () => toggleSidebarHalf();
  const theme = useTheme();
  const locationId = getLocalData('locationId');
  const roleName = getLocalData('userRole');
  const franchiseeId = getLocalData('franchiseeId');
  const [imageUrl,setimageUrl] = useState('/public/static/images/logo/Rectangle.png');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let username = getLocalData("UserName");
  const [staffProfile, setStaffProfile] = useState(false);
  let userId = getLocalData("userId");
  const [open, setOpen] = React.useState(false);
  const {isUserUpdate} = useContext(UserContext)
  // const [staffId, setStaffId] = useState(id);
  
  // Logout handler and clear the session storage
  const logout = () => {
    // check and get the token from session storage
    let token = getLocalData("idToken");
    let sessionToken = sessionStorage.getItem("idToken")
    // check if token is not null
    // if (token !== null || sessionToken !== null) {
      // call the logout api
      logoutApi(token ?? sessionToken);
    // }
  }

  // Logout api call
  const logoutApi = async (token) => {
    // Now show the loader
    setIsLoading(true);

    let body = JSON.stringify({ "idToken": token });

    // call the logout api
    const response = await fetch(`${process.env.REACT_APP_API_AUTH_END_POINT}api/auth/signOut`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": " *"
      },
      body: body
    });
    // get the response
    const data = await response.json();
    // check if the response is success
    if (data.success) {
      // clear the cookies
      cookies.remove('access_token', { path: '/' });
      cookies.remove('refresh_token', { path: '/' });
      // clear the session storage
      sessionStorage.clear();
      clearLocalData();
      // Now hide the loader
      setIsLoading(false);
      navigate('/login');
    } else {
      // clear the cookies
      cookies.remove('access_token', { path: '/' });
      cookies.remove('refresh_token', { path: '/' });
      // clear the session storage
      sessionStorage.clear();
      clearLocalData();
      // Now hide the loader
      setIsLoading(false);
      navigate('/login');
    }
  }
  // Method to open the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the dialog
  const handleClose = () => {
    setStaffProfile(false);
    setOpen(false);
  };

  // View user's profile
  const viewProfile = () => {

    setStaffProfile(true);
    handleClickOpen();
  }

  let half;
  // window width
  if (window.innerWidth < 1280) {
    half = sidebarToggleHalf ? '' : 'sidebar-half'
  } else {
    half = sidebarToggleHalf ? '' : 'sidebar-half';
  }

  // State to check if the sidebar is open or not on window resize
  useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth < 1280) {
        closeSidebarHalf();
      }
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);



  const getStaffProfile = async(id) => {   
    if (!id || !locationId || locationId === 'undefined') return;
    let adminData = {
      "userId": id,
      "role": roleName,
    }

    let ownerData = {
      "userId": id,
      "role": roleName,
      "franchiseeId": franchiseeId
    }
 
    let managerData = {
      "userId": id,
      "role": roleName,
      "locationId": locationId
    }

    let url = `${process.env.REACT_APP_API_END_POINT}staff/getUserDetailsData`;
    try{
      let response = await ApiService.post( url, roleName === 'Admin' || roleName === 'Super Manager' ? adminData : roleName === 'Owner' ? ownerData : managerData );
      let items = response.data.Items;
      if (items) {
        // debugger; // eslint-disable-line no-debugger
        let profileImage = items[0]?.profileImage ?? items[0]?.staffProfileImage;
        if(profileImage && profileImage !== 'undefined') setimageUrl(profileImage);
      }
    } catch(error) {
      console.error('Error:', error);
    }
  };

  // const getStaffProfile = (id) => {
  //   // call the api to get the staff details
  //   if (!id) return;

  //   let encodedLocationId = encodeURIComponent(userId);

  //   // https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev/users/getUser?id=%23USER%232T0OT7vCjZAf6wDdrPV4TPiYZM3
  //   // https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev/staff/getUserDetailsData

  //   let url = `${process.env.REACT_APP_API_END_POINT}users/getUser?id=${encodedLocationId}`;
  //   fetch(`${url}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //     .then((response) => {
  //       // console.log("response", response);
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data) {
  //         // set the location details
  //         let items = data.data.Items;
  //         if (items) {
  //           debugger; // eslint-disable-line no-debugger
  //           console.log(items[0])
  //           setimageUrl(items[0].profileImage)
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };



  useEffect(() => {   
    getStaffProfile(userId);
  },[isUserUpdate])

  return (
    <>
      {/* Start loading */}
      <Loader IsLoading={isLoading} />

      {/* Side bar wrapper Start */}
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
        // className={sidebarToggleHalf ? 'sidebar-wrapper' : 'sidebar-wrapper sidebar-half'}
        className={`sidebar-wrapper ${sidebarToggleHalf ? "" : half}`}
      >
        {/* hamburger-cont start */}
        <Box component="span" sx={{ ml: 2, display: { lg: 'inline-block', xs: 'inline-block' } }} className={`hamburger-cont collapese-arrow ${sidebarToggleHalf ? 'arrow-full' : 'arrow-half'}`} >
          <IconButton color="primary" onClick={closeSidebarHalf} className='hamburger-icon-btn collapese-arrow-btn' title='collapsible-menu'>
            {!sidebarToggleHalf ? (
              <ArrowForwardIosOutlinedIcon fontSize="small" />
            ) : (
              <ArrowBackIosNewOutlinedIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
        {/* hamburger-cont End */}
        <Scrollbar>
          <Box p={2}>
            <Box
              sx={{
                // width: 52
                height: 17
              }}
              onClick={closeSidebarHalf}
            >
              <div className="zoom-room-side-logo ooo">
                <img src={'/static/images/logo/zoom-room-logo-white.png'} className={`logo-image ${sidebarToggleHalf ? '-logo-block' : '-logo-none'}`} alt='zoom-room-logo' />
                <img src={'/static/images/logo/zrlogo.png'} className={`logo-image-small ${sidebarToggleHalf ? '-logo-none' : '-logo-block'}`} width={'50px'} alt='zoom-room-logo' />
              </div>

              {/* <div className="zoom-room-side-logo ooo">
                {sidebarToggleHalf ? (
                  <img src={'/static/images/logo/zoom-room-logo-white.png'} style={{ maxWidth: '100%' }} className="logo-image" alt='zoom-room-logo' />
                ) : (
                  <img src={'/static/images/logo/zrlogo.png'}  className="logo-image-small" alt='zoom-room-logo' />
                )}
              </div> */}
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              ml: 0,
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <SidebarMenu />
        </Scrollbar>

        {/* Divider to show the line between the menu and logout button */}
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        {/* Divider end */}

        <Box p={2} className='profile-container'>
          {/* <Avatar
            alt="profile"
            src="/static/images/avatars/3.jpg"
            sx={{ width: 56, height: 56 }}
            className='profile-img'
          /> */}
          <Avatar onClick={viewProfile} src={imageUrl} sx={{ width: 56, height: 56, background: '#757575' }} className='profile-img  cursor-pointer' />
          {/* if side bar is half and username is not null then show the username */}
          {sidebarToggleHalf && username ? <span className=' cursor-pointer' role='button' tabIndex="0" onClick={viewProfile} onKeyDown={viewProfile} >{username}</span> : null}
          {/* if side bar is half then do not show the logout icon */}
          {sidebarToggleHalf && <Box ml={1} onClick={logout} className='logout-btn-cont absolute'><Logout className='logout-btn' /></Box>}
          {/* <Box ml={1} className='logout-btn-cont'><LockOpenTwoToneIcon className='logout-btn' /></Box> */}
        </Box>
      </SidebarWrapper>
      {/* Side bar wrapper end */}

      {/* Drawer start */}
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5)
          }}
          className='sidebar-wrapper'
        >
          <Scrollbar>
            <Box p={2}>
              <Box
                mx={2}
                sx={{
                  // width: 52
                  height: 17
                }}
              >
                <div className="zoom-room-side-logo">
                  <img src={'/static/images/logo/zoom-room-logo-white.png'} style={{ maxWidth: '100%' }} className="logo-image" alt='zoom-room-logo' />
                </div>
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />

            {/* Sidebar menu component */}
            <SidebarMenu sidebarToggleHalf={sidebarToggleHalf} />
            {/* Sidebar menu component end */}

          </Scrollbar>
          <Divider
            sx={{
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <Box p={2} className='profile-container'>
            {/* <Avatar
              alt="Remy Sharp"
              src="/static/images/avatars/3.jpg"
              sx={{ width: 56, height: 56 }}
              className='profile-img'
            /> */}
            <Avatar onClick={viewProfile} src={imageUrl}
              sx={{ width: 56, height: 56, background: '#757575' }}
              className='profile-img cursor-pointer' />
            {username ? <span className=' cursor-pointer' role='button' tabIndex="0" onClick={viewProfile} onKeyDown={viewProfile} >{username}</span> : null}
            <Box ml={1} onClick={logout} className='logout-btn-cont absolute'><Logout className='logout-btn' /></Box>
          </Box>
        </SidebarWrapper>
      </Drawer>
      {/* Drawer end */}
      {/* Profile details here */}
      {staffProfile && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="details-dialog"
          className="dialog-box dialog-box-right"
          maxWidth="lg"
        >
          <DialogTitle className="alert-title align-right dialog-header">
            <Box>
              {/* remove icon */}
              <Button
                className="details-remove-icon p-0 no-hover"
                onClick={handleClose}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="white" />
                  <path
                    d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                    fill="#003087"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription"
            >
              <Box justifyContent="center" direction="column">
                <StaffProfile Id={userId} setStaffProfile={setStaffProfile} />
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default Sidebar;
