import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogContent, Box, Typography, DialogTitle, FormControl, Grid, TextField, FormHelperText } from '@mui/material';
import { Transaction } from './ApiCall';

export default function AddAndWithdralCash({ heading, setLoading, setShowSuccessMessage, setSuccessMessage, setShowErrorMessage, setErrorMessage }) {
  const [open, setOpen] = useState(false);
  const [IsAmount, setIsAmount] = useState(false);
  const [amount, setAmount] = useState();
  const handleClickOpen = () => {
    setAmount('')
    setOpen(true);
  };
  const handleClose = () => {
    setAmount('')
    setOpen(false);
  };
  function handleSubmit() {
    if (amount) {
      setIsAmount(false)
      Transaction(amount, heading, setLoading, setOpen, setShowSuccessMessage, setSuccessMessage, setShowErrorMessage, setErrorMessage)
    } else {
      setIsAmount(true)
    }
  }

  const isValidInput = (value) => {
    const regex = /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/;
    return regex.test(value);
  };
  return (
    <>
      <Button sx={{ width: '130px', height: '50px' }} variant='contained' onClick={handleClickOpen}>{heading}</Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="details-dialog"
        className="dialog-box-right-addTask"
        maxWidth="sm">
        <DialogTitle
          className="alert-title align-right"
          sx={{ backgroundColor: '#FFFFFF ! important' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className="header-black-20">{heading.toUpperCase()}</Typography>
            {/* remove icon */}
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => handleClose('Close')}>
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646" />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#FFFFFF ! important' }}>
          <Box sx={{ marginTop: '18px' }}>
            <FormControl sx={{ width: '454px', margin: '35px 0px' }}>
              <TextField
                id="outlined-basic"
                label="AMOUNT ($)"
                variant="outlined"
                name="amount"
                value={amount}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (isValidInput(inputValue)) {
                    setAmount(inputValue);
                  }
                }
                }
                className="input-field-styling required-field"
                InputLabelProps={{ className: 'label-style-create-franchisee required-label' }}
              />
              {IsAmount && <FormHelperText className="field-error-text">Amount is require.</FormHelperText>}
            </FormControl>
            <Grid container spacing={2} className="grid-container">
              <Button
                fullWidth
                variant="contained"
                onClick={() => handleSubmit()}
                sx={{ marginTop: '20px', marginLeft: '18px' }}
                className="button-style-add-task field-top-margin-edit-location"
              >
                {heading.toUpperCase()}
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}