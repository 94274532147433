export function getFormattedDate(clockInDate, format) {
  if (!clockInDate) {
    return "";
  }
  ;
  const dateObj = new Date(clockInDate.replace("-", "/"));

  if (format === "date") {
    const date = dateObj.getDate();
    return date.toString().padStart(2, "0");
  } 
  
  if (format === "day") {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[dateObj.getDay()];
  }
    return "";
}

export function formatTime(time) {
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = time.replace("T", " ");
  return new Date(`2000-01-01 ${formattedTime}`).toLocaleTimeString(
    [],
    options
  );
}

export function convertTo24HourFormat(time) {
  const [hour, minute, period] = time ? time.split(/:| /) : '10:10 AM';
  let hour24 = parseInt(hour, 10);

  if (period === "PM" && hour24 !== 12) {
    hour24 += 12;
  } else if (period === "AM" && hour24 === 12) {
    hour24 = 0;
  }

  return `${hour24.toString().padStart(2, "0")}:${minute}`;
}

export function calculateTotalHours(clockOutTime, clockInTime, hours = false) {
  const [outTime, outPeriod] = clockOutTime
    ? clockOutTime.split(" ")
    : ["", ""];
  const [inTime, inPeriod] = clockInTime.split(" ");

  let [outHour, outMinute] = outTime ? outTime.split(":").map(Number) : [0, 0];
  let [inHour, inMinute] = inTime.split(":").map(Number);

  if (outPeriod === "PM") outHour += 12;
  if (inPeriod === "PM") inHour += 12;

  let totalMinutes = outHour * 60 + outMinute - (inHour * 60 + inMinute);

  if (totalMinutes < 0) {
    totalMinutes += 24 * 60; // Add 24 hours to handle negative values
  }

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  if (hours) {
    const decimalHours = totalHours + remainingMinutes / 60;
    return `${decimalHours.toFixed(1)} HRS`;
  } 
  
  return `${totalHours} hours ${remainingMinutes} minutes`;
}
