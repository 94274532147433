import React from 'react';
import ReactScrollableFeed from 'react-scrollable-feed';
import Sendertemplate from './Sendertemplate';
import Receivertemplate from './Receivertemplate';
import { Box } from '@mui/system';
import { getLocalData } from 'src/utils';

const Message = ({ allUsersChat, scrollableRef, staffProfileData }) => {
  const twilioNumber = getLocalData('twilioNumber');
  const cleanedTwilioNumber = twilioNumber?.includes('-')
  ? `+1${twilioNumber.replace(/-/g, '')}`
  : twilioNumber;

  const renderMessage = (data, key) => {
    const isSender = data?.direction === 'inbound' && data?.to === cleanedTwilioNumber;

    return (
      <Box key={key} p={1}>
        {isSender ? (
          <Sendertemplate data={data} />
        ) : (
          <Receivertemplate data={data} staffProfileData={staffProfileData} />
        )}
      </Box>
    );
  };

  return (
    <ReactScrollableFeed ref={scrollableRef}>
      {allUsersChat && allUsersChat?.length > 0 ? (
        allUsersChat?.map((data, index) =>
          renderMessage(data, data?.id || index)
        )
      ) : (
        <Box className="no-chat-available-text">
          You're starting a new conversation
          <br />
          Type your first message below.
        </Box>
      )}
    </ReactScrollableFeed>
  );
};

export default Message;
