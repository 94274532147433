import * as React from 'react';
import { Avatar,Box, Card, CardHeader, CardContent, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';


export default function CustomRevenueCards({
    // eslint-disable-next-line no-unused-vars
    title,
    // eslint-disable-next-line no-unused-vars
    subTitle,
    Icon,
    Units
}) {
    return (
        <Box className="dashboardcard-grid-container-card">
            <Card sx={{ borderRadius: '19px' }}>
                <CardHeader
                    className="card-header-dashboard"
                    sx={{pb:0}}
                    avatar={
                        <Avatar
                            alt="board"
                            // src={icon}
                            sx={{ marginTop: 3 }}
                            className="dashboardcard-avatar-img-card"
                        >
                             {Icon && Icon}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={title}
                    subheader={subTitle}
                />
                <CardContent
                    sx={{
                        paddingTop: 0,
                        paddingLeft:9.5,
                        wordBreak: 'break-all',
                        maxHeight: 60
                    }}
                >
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        className="pos-text-size-12"
                    >
                        {Units}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}