/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Avatar
} from '@mui/material';
import moment from 'moment';

export const TrophyEarnedTable = (props) => {
  const {
    tableRows,
    searchQuery
  } = props;

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Apply search filter
  const applyFilters = (tableRows) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = tableRows.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = tableRows;
    }
    return arrayOfMatchedObjects;
  };

  // Apply filter from object and below is the pagination order
  // const filteredRows = applyFilters(tableRows);
  // const paginatedRows = applyPagination(filteredRows);

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    // utc = (date) ? moment(date).format('MM-DD-YYYY, h:mm A') : '';
    utc = (date) ? moment(date).format('MMM Do, YYYY') : '';
    return utc;
    // let dateFor = date && new Date(date);
    // // let utc = dateFor.toUTCString();
    // let finaldate = dateFor.toLocaleString();
    // return finaldate;
  }

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        {/* <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="table-heade header-blue-20">
              {' '}
              All Trophy Earned
            </Typography>
          </Grid>
        </Grid> */}
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Trophy Name</TableCell>
                <TableCell className='text-transform-none'>Date of Earned</TableCell>
                <TableCell>Trophy Assigned By</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData && paginatedData.length > 0 && paginatedData.map((row) => {
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    key={row.sortKey}
                    role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    // selected={isItemSelected}
                    className="table-row cursor-pointer"
                  // onClick={(e) => openDetail(row.sortKey, 3, e)}
                  >
                    <TableCell className="table-td" sx={{ display: 'flex' }}>
                      <Avatar
                        alt="icon"
                        src={
                          row.trophyImage ||
                          '/static/images/logo/clienticon.png'
                        }
                        sx={{ marginRight: 2, float: 'left' }}
                        className="card-avatar-img align-self-center"
                      />
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row?.trophyName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.createdAt && dateFormate(row?.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.createdByName}
                      </Typography>
                    </TableCell>

                    {/* <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.sortKey}
                        onClick={(e) => openDetail(row.sortKey, 2, e)}
                      >
                        Edit
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              }) || (
                  <TableRow>
                    <TableCell colSpan={6} className="table-td">
                      <Typography

                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        No data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
