/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

 const CohortsFilter = ({
  setFilters,
  filters,
  TemplateList
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
//   const [Date, setDate] = useState(null);
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    channel: false,
    datefilter: false,
  });
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
        channel: field === 'channel' ? !prevState.channel : false,
        datefilter: field === 'datefilter' ? !prevState.datefilter : false,
    }));
  };

  const handleChangeFilter = (value, type) => {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      // isActive: filters.isActive,
      status: 'All',
      channel: null,
      datefilter: null
    });

    setOpenFilter({
        channel: false,
        datefilter: false
    });
  };
  const changeStartDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setstartDate(date);
    setFilters((prevState) => ({
      ...prevState,
      startDate: date
    }));
  };
  const changeEndDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setEndDate(date);
    setFilters((prevState) => ({
      ...prevState,
      endDate: date
    }));
  };


  return (
    <>
      <Card className="filter-content custom-filter-fields">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) =>
              // handleChangeFilter(event.target.value, 'isActive')
              handleChangeFilter(event.target.value, 'status')
            }
            label="Active"
            className="status-field field-label-css"
          >
           <MenuItem key={1} id={1} value="All">All</MenuItem>
            <MenuItem key={2} id={2} value='Shared'>Shared</MenuItem>
            <MenuItem key={3} id={3} value='Private'>Private</MenuItem>

          </Select>
        </FormControl>

        <Divider />

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text filter-text-title"
                sx={{
                  fontWeight: '600',

                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('channel')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.channel && classes.closeY,
                    openFilter.channel && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Channel</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.channel && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Channel</InputLabel>
              <Select
                // multiple
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'channel')
                }
                value={filters.channel}
                sx={{
                  width: '100%'
                }}
                label="Channel"
                className="status-field field-label-css"
              >
                 <MenuItem key={2} id={2} value='ALL'>All</MenuItem>
            <MenuItem key={2} id={2} value='EMAIL'>Email</MenuItem>
            <MenuItem key={3} id={3} value='SMS'>text</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('datefilter')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.datefilter && classes.closeY,
                    openFilter.datefilter && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Created Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.datefilter && (
       
              <>
              <Box marginTop={1}>
         <CustomDatePicker label="Start Date" onChange={changeStartDate} />
       </Box>
       <Box marginTop={1}>
         <CustomDatePicker
           label="End Date"
           onChange={changeEndDate}
           minDate={new Date(startDate)}
           disabled={Boolean(!startDate)}
         />
       </Box>
             </>
             
        )}

       
      </Card>
    </>
  );
};
export default CohortsFilter;