import { Grid, Box } from '@mui/material';
import React, { useState } from 'react';
import { DynamicButton } from 'src/components/Checkout';
import { DynamicTextField } from 'src/components/GlobalComponents';
import { formatPhoneNumber } from 'src/utils';

const GuestForm = ({
  handleGuest,
  selected,
  setSelected
}) => {
  const [guestCheckout, setGuestCheckout] = useState({
    email: '',
    contact: '',
    name: ''
  });
  const [fieldValidation, setFieldValidation] = useState([
    {
      nameError: '',
      phoneError: '',
      emailError: ''
    }
  ]);
  const handleChange = (name, value) => {
    if (name === 'name') {
      if (value) {
        if (!/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
            const allErr = { ...fieldValidation };
            if (/[^a-zA-Z\s]/.test(value)) {
                allErr['nameError'] = 'Numbers or Special characters are not allowed.';
            } else {
                allErr['nameError'] = 'Please enter a valid name.';
            }
            setFieldValidation(allErr);
        } else {
            const allErr = { ...fieldValidation };
            allErr['nameError'] = '';
            setFieldValidation(allErr);
        }
    } else {
        const allErr = { ...fieldValidation };
        allErr['nameError'] = 'Name is required.';
        setFieldValidation(allErr);
      }
      setGuestCheckout({ ...guestCheckout, [name]: value });
    } else if (name === 'email') {
      if (value) {
        if (!/\S+@\S+\.\S+/.test(value)) {
          const allErr = { ...fieldValidation };
          allErr['emailError'] = 'Please enter a valid email address.';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['emailError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['emailError'] = '';
        setFieldValidation(allErr);
      }
      setGuestCheckout({ ...guestCheckout, [name]: value });
    } else if (name === 'contact') {
      let formatWorkPhoneNumber = formatPhoneNumber(value);
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      if (value) {
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          const allErr = { ...fieldValidation };
          allErr['phoneError'] =
            'Please enter a valid phone number in format: xxx-xxx-xxxx';
          setFieldValidation(allErr);
        } else {
          const allErr = { ...fieldValidation };
          allErr['phoneError'] = '';
          setFieldValidation(allErr);
        }
      } else {
        const allErr = { ...fieldValidation };
        allErr['phoneError'] = '';
        setFieldValidation(allErr);
      }
      setGuestCheckout({ ...guestCheckout, [name]: formatWorkPhoneNumber });
    }
  };

  return (
    <>
      <Box 
        mt={1}
        display='flex'
        width='100%'
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
        >
        <Grid container item xs={12} sm={6} md={4}>
          <DynamicTextField
            className="input-field-styling required-field"
            type="text"
            name="name"
            label="NAME *"
            error={guestCheckout?.name === ''}
            helperText={fieldValidation?.nameError}
            fullWidth
            value={guestCheckout?.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid container item xs={12} sm={6} md={4}>
          <DynamicTextField
            className="input-field-styling required-field"
            type="text"
            name="email"
            label="EMAIL"
            helperText={fieldValidation?.emailError}
            fullWidth
            value={guestCheckout?.email}
            onChange={handleChange}
          />
        </Grid>

        <Grid container item xs={12} sm={6} md={4}>
          <DynamicTextField
            className="input-field-styling required-field"
            type="text"
            name="contact"
            label="PHONE NUMBER"
            required={false}
            fullWidth
            helperText={guestCheckout?.contact && fieldValidation?.phoneError}
            value={guestCheckout?.contact}
            onChange={handleChange}
          />
        </Grid>
      </Box>

      <Box
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          bottom: 50
        }}
      >
        <DynamicButton
          title={'Go Back'}
          variant="contained"
          onClick={() => {
              setSelected({ ...selected, guestClient: false });
          }}
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            marginRight: '10px',
            background: '#fff',
            color: '#003087',
            border: '2px solid #003087'
          }}
        />
        <DynamicButton
          title={'Save & Proceed'}
          variant="contained"
          onClick={(e) => {
            handleGuest(e, guestCheckout);
          }}
          disabled={
            guestCheckout.name === '' ||
            fieldValidation?.emailError ||
            fieldValidation?.nameError ||
            fieldValidation?.phoneError
          }
          style={{
            padding: '10px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            maxWidth: 'max-content',
            fontWeight: '600',
            fontSize: '16px',
            border: '2px solid #003087'
          }}
        />
      </Box>
    </>
  );
};

export default GuestForm;