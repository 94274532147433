import { Box } from '@mui/material';
import { useContext } from 'react';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { styled } from '@mui/material/styles';

import { Typography, Grid } from '@mui/material';
import { HeaderButtonContext } from 'src/contexts/HeaderButtonContext';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const { headerText } = useContext(HeaderContext);
  const { showScreen, setShowScreen } = useContext(HeaderButtonContext);
  // ,isToggleComponent,togglePopup
  // toggleHeader, setHeaderText, headerToggle
  // const closeHeader = () => {

  // if(headerToggle) {
  //   setHeaderText("Header Menu");
  // } else {
  //   setHeaderText("Location Mngt");
  // }

  //   toggleHeader();
  // };

  // console.log("headerToggle", headerToggle, closeHeader);
// console.log(isToggleComponent,"isToggleComponentisToggleComponentisToggleComponent");
// console.log(showScreen,"showssjlskdsjk");
// console.log(togglePopup,"togglePopuptogglePopuptogglePopuptogglePopup");

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: 'block',
            md: 'block'
          }
        }}
      >
        {(showScreen === 0 || showScreen === 99 ) ? (
          <Grid item>
            <Typography
              variant="h3"
              component="h3"
              gutterBottom
              className="page-header-text"
              sx={{ fontSize: { xs: '20px' } }}
            >
              {headerText}
            </Typography>
          </Grid>
        ) : (
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Box
                onClick={() => setShowScreen(0)}
                className="back-arrow-close"
              >
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Typography className="header-blue-32">{headerText}</Typography>
            </Box>
          </Grid>
        )}
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;
