
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Checkbox,
  TextField,
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { enterNumber } from 'src/utils/index';

export const TransferInventoryFilter = ({ setFilters, filters, data }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
   
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    price: false,
    categoryName: false
  });

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      price: field === 'price' ? !prevState.price : false,
      categoryName: field === 'categoryName' ? !prevState.categoryName : false
    }));
  };

  const handleChangeFilter = (value, type) => {
    if( type === 'price') {
      value = enterNumber(value);
    }
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      status: '',
      price: '',
      categoryName: null
    });

    setOpenFilter({
      price: false,
      categoryName: false
    });
  };

  return (
    <>
      <Card className="filter-content custom-filter-fields">
        <Typography className="filter-label" pt={2} pb={1}>
          Status
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>All</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) =>
              handleChangeFilter(event.target.value, 'status')
            }
            label="All"
            className="status-field field-label-css height-auto"
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('categoryName')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.categoryName && classes.closeY,
                    openFilter.categoryName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Category</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.categoryName && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              {data?.length ? (
                <>
                  <InputLabel>Select Category</InputLabel>
                  <Select
                    onChange={(event) =>
                      handleChangeFilter(event.target.value, 'categoryName')
                    }
                    value={filters.categoryName}
                    sx={{
                      width: '100%'
                    }}
                    label="Select Category"
                    className="status-field field-label-css height-auto"
                    MenuProps={{
                      PaperProps: {
                          style: {
                              maxHeight: 224,
                              overflowY: 'auto',
                          },
                      },
                    }}
                  >
                    {data?.length > 0 &&
                      [
                        ...new Set(
                          data.map((option) => option?.categoryName)
                        )
                      ]?.map((categoryName) => (
                        <MenuItem id={categoryName} value={categoryName}>
                          {categoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              ) : (
                <Typography pl={3}>No Category available</Typography>
              )}
            </FormControl>
          </Box>
        )}

        <Grid
          item
          onClick={() => handleOpenFilter('price')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.price && classes.closeY,
                    openFilter.price && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Price</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.price && (
          <Box marginTop={1} className='height-auto'>
            {/* <FormControl fullWidth variant="outlined">
              <InputLabel>Select Price</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'price')
                }
                value={filters.price}
                sx={{
                  width: '100%'
                }}
                label="Select Price"
                className="status-field field-label-css"
              >
                {data?.length > 0 &&
                  [...new Set(data.map((option) => option.price))]?.map(
                    (price) => (
                      <MenuItem id={price} value={price}>
                        {price}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl> */}
            <TextField
              className='w-100'
              value={filters.price ?? ''}
              // type='number'
              onChange={(e) => handleChangeFilter(e.target.value, 'price')}
              placeholder="Enter price"
             
            />
          </Box>
        )}
      </Card>
    </>
  );
};
