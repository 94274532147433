import { useState, useEffect } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Autocomplete,
  DialogActions,
  Grid
} from '@mui/material';
import './TrophyAssign.css';
import Loader from '../../../components/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { getLocalData } from '../../../utils';
import { ApiService } from '../../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function TrophyAssign({
  openAddteam,
  setopenAddteam,
  setIsAddTeamOpen,
  getPetItem,
  getEventToRefresh
}) {

  /* Styles for autocomplete */
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
  const autoCompleteClasses = useAutocompleteStyles();
  /* States to set the data */
  /* Team members array to get team names from api */
  const [teamMembers, setteamMembers] = useState([]);
  /* Team members array to store team members name */
  const [teamMembersValue, setteamMembersValue] = useState([]);
  const [description, setdescription] = useState('');
  /* States to set error messages */
  const [teamMembersError, setteamMembersError] = useState('');

  /* State to open confirmation dialog box */
  const [open, setOpen] = useState(false);
  /* State to check whether the value is added/updated  */
  const [isUpdated, setIsUpdated] = useState(false);
  /* State for checking the state of field update */
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [trophyName, setTrophyName] = useState('');
  const [displayOnShelf, setDisplayOnShelf] = useState(false);
  const [trophyImage, setTrophyImage] = useState('');
  
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  useEffect(() => {
    // getTrophyList();
    setOpen(false);
    setopenAddteam(true);
    setIsUpdated(false);
  }, []);

  /* Variable getting user id from local storage */
  let userId = getLocalData('userId');
  let userName = getLocalData('UserName');

  /* Function to get the team members  */
  useEffect(()=> {
    getAllEarnedTrophy()
  },[])

  const getAllEarnedTrophy = async () => {
    let cid = localStorage.getItem('clientId');
    let encodedClientId = encodeURIComponent(getPetItem?.clientId) || encodeURIComponent(cid);
    let encodedPetId = encodeURIComponent(getPetItem?.sortKey);
    try {
      const [
        trophyResponse
      ] = await Promise.all([
        ApiService.get(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}trophy/getTrophyByPet?clientId=${encodedClientId}&petId=${encodedPetId}`
        )
      ]);

      if (!trophyResponse?.error) {
        const modifiedData = trophyResponse?.data?.Items?.map(
          (item) => ({
            trophyId: item.trophyId
          })
        );
        getTrophyList(modifiedData)
      }
    } catch (error) {
      console.warn('API Error', error);
    }
  };

  const getTrophyList = async (modifiedData) => {
    try {
      const response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}trophy`);
      let data = response;
      
      if (data.statusCode && !data.error) {
        const sortKeysExisting = modifiedData?.map(obj => obj?.trophyId);
        const notAssignedTophies = data?.data?.Items?.filter(obj => !sortKeysExisting?.includes(obj?.trophyId));
        const filteredItems = notAssignedTophies?.filter((team) => team?.status === 1);
        // Set the list of Teams
        setteamMembers(filteredItems);
        // Now hide the loader
        // setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* Function to set description */

  const descriptionSet = (description) => {
    if (description) {
      setdescription(description);
      setIsFieldUpdated(true);
    }
  };

  /* Function to add team  */

  const validateAssignTrophy = () => {
    
    if (teamMembersValue.length === 0) {
      setteamMembersError('Please select trophy');
    }
    // teamName && 
    if (teamMembersValue.length > 0) {
      AssignTrophy();
    }
  };

  /* Function to call add team api */

  const AssignTrophy = async () => {
    try {
      // Shwo loader
      setIsLoading(true);

      let data = {
        clientId: getPetItem.clientId,
        petId: getPetItem.sortKey,
        locationId: getPetItem.locationId || locationId,
        franchiseeId: getPetItem.franchiseeId || franchiseeId,
        notes: description,
        trophyId: teamMembersValue,
        trophyName: trophyName,
        createdBy: userId,
        createdByName: userName,
        displayOnShelf: displayOnShelf,
        trophyImage: trophyImage
      };

      const response = await ApiService.post(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}trophy/assignTrophy`, data);
      let resdata = response;

      if (resdata.statusCode === 200 && !resdata.error) {
        setopenAddteam(false);
        setIsUpdated(true);
        // hide loader
        setIsLoading(false);
        setOpen(true);
        getEventToRefresh();
      }
    } catch (error) {
      console.log(error);
      // hide loader
      setIsLoading(false);
    }
  };

  /* Function to close confirm dialog */

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      setopenAddteam(true);
    } else if (val === 'Discard') {
      setIsAddTeamOpen(false);
      setopenAddteam(false);
      setOpen(true);
      setopenAddteam(false);
      setIsFieldUpdated(false);
      clearAll();
    }
  };

  /* Function to clear all fields and error */

  const clearAll = () => {
    setteamMembersValue([]);
    setdescription('');
    setteamMembersError('');
  };

  /* Function to close the add item dialog */

  const handleAddteamClose = () => {
    if (isFieldUpdated) {
      setopenAddteam(true);
      setOpen(true);
    } else {
      setIsAddTeamOpen(false);
      setopenAddteam(false);
      setopenAddteam(false);
      setOpen(false);
      clearAll();
    }
  };

  // Method to handle team change and set filters
  const handleSelectUsers = (value) => {

    // const userId = value.map((val) => {
    //   return val.userId;
    // });
    const userId = value.sortKey;

    // Remove the same value of array from teamId
    // const uniqueUserId = userId.filter(function (item, pos) {
    //   return userId.indexOf(item) === pos;
    // });
    setteamMembersValue(userId);
    setTrophyName(value.title);
    setDisplayOnShelf(value.displayOnShelf);
    setTrophyImage(value.trophyImage);
    if (userId) {
      setteamMembersError('');
    }
    setIsFieldUpdated(true);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        open={openAddteam}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleAddteamClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right not "
        maxWidth="sm"
        sx={{ maxHeight: '530px', mt: 15, ml: 2 }}
      >
        <Box>
          <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
            {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
            <Box
              className="dialog-tex "
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography className="page-header weight-700 space_1_7">Assign Trophy</Typography>
              {/* remove icon */}
              <Button
                className="details-remove-icon task-cross p-0 no-hover"
                onClick={() => handleAddteamClose('Close')}
              >
                <svg
                  width="30"
                  height="31"
                  viewBox="0 0 30 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                    fill="#E64646"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
        </Box>
        <DialogContent className="-team-container-white-box">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <Autocomplete
              // multiple
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                handleSelectUsers(newValue);
                // setTeamSelected(newValue);
              }}
              // onChange={teamMembersSet}
              // options={teamMembers.map(
              //   (option) => option.firstName + ' ' + option.lastName
              // )}
              options={teamMembers}
              getOptionLabel={(option) =>
                option.title
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={params?.label || 'SELECT  TROPHY'}
                  //   error={Boolean(teamMembersError)}
                  helperText={teamMembersError}
                  className="input-field-styling field-top-margin-edit-locatio location-field required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label space_2em'
                  }}
                />
              )}
            />

            <TextField
              fullWidth
              multiline
              id="my-textarea"
              name="Description"
              autocomplete="Description"
              label="NOTES"
              placeholder="Notes here . . . "
              variant="outlined"
              defaultValue={description}
              className="field-top-margin-edit-location "
              onChange={(e) => descriptionSet(e.target.value)}
              InputLabelProps={{
                className: 'label-style-create-franchisee'
              }}
              InputProps={{ rows: 6 }}
            />

            <Grid container spacing={2} className="mt-3">
              <Grid item xs={12} sm={6}>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  className="button-style-cancel field-top-margin-edit-location button-style-add-team"
                  sx={{ border: '2px solid #003087!important' }}
                  onClick={() => handleAddteamClose('Cancel')}
                >
                  <Typography className="next-button-tex weight-700">Cancel</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="button-style-add-team field-top-margin-edit-location"
                  onClick={validateAssignTrophy}
                >
                  <Typography className="next-button-text">Assign</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {!isUpdated ? 'Save Changes' : 'Congratulations'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, Do you want to
                  <Typography className="title-text-sub">
                    {' '}
                    save them?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Trophy has been assigned successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TrophyAssign;
