import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import ProductGridView from './ProductGridView';
import Loader from '../../../components/Loader';
// import { toast } from 'react-toastify';
import axios from 'axios';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
// import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

import { getLocalData } from 'src/utils'; // setFullLocationId

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// props, ref, useImperativeHandle
function ProductListAPI({ ref, showScreen, setShowScreen }) {
  const [productList, setProductlist] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [productArray, setproductArray] = useState([
    { createdAt: '0', productId: '0' }
  ]);
  const [activePagination, setactivePagination] = useState('');
  const [ispopup, setIsPopup] = useState(false);
  const userId = getLocalData('userId');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedProductLists, setSelectedProductLists] = useState([]);
  const [open, setOpen] = React.useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
//  const [showSucessMessage, setShowSuccessMessage] = useState(false);
 // const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [varientType, setvarientType] = useState([]);

  // const[createdAt,setcreatedAt]=useState(null);

  // const[productId,setproductId]=useState(null);
  // let categoryList = [];
  const [categoryList, setcategoryList] = useState([]);
  let [filters, setSidebarFilters] = useState({
    // isDiscontinued: null,
    status: 1,
    categoryName: null,
    isBestSeller: null,
    variantType: null,
    variantValue: null
  });

  // Getting hooks from parent to child
  /* useImperativeHandle(ref, () => ({
     refreshData: () => {
       // Not call the get franchisee method again
       getProducts();
     }
   })); */

  // const removeNullValues = (obj) => {
  //   return Object.fromEntries(
  //     Object.entries(obj)
  //       .filter(([_, value]) => value !== null)
  //       .map(([key, value]) => {
  //         if (typeof value === 'object' && value !== null) {
  //           return [key, removeNullValues(value)];
  //         }
  //         return [key, value];
  //       })
  //   );
  // };
  // const cleanObject = (obj) => {
  //   return Object.fromEntries(
  //     Object.entries(obj).filter(([key, value]) => {
  //       if (typeof value === 'object' && value !== null) {
  //         value = cleanObject(value);
  //       }
  //       return value !== null && value !== '' && value !== undefined;
  //     })
  //   );
  // };

  // const cleanFilters = (obj) => {
  //   return Object.entries(obj).reduce((acc, [key, value]) => {
  //     if (typeof value === 'object' && value !== null) {
  //       const nested = cleanFilters(value);
  //       if (Object.keys(nested).length !== 0) {
  //         acc[key] = nested;
  //       }
  //     } else if (value !== null && value !== '' && value !== undefined) {
  //       acc[key] = value;
  //     }
  //     return acc;
  //   }, {});
  // };

  const getProducts = async (activePage) => {
    try {
      // Now show the loader
      if (!ispopup) setIsLoading(true);
  
      let filtersParams = { ...filters };
  
      Object.keys(filtersParams).forEach((key) => {
        if (
          filtersParams[key] === null ||
          filtersParams[key] === 'All' ||
          filtersParams[key] === 'undefined' ||
          filtersParams[key] === undefined
        ) {
          delete filtersParams[key];
        }
      });
  
      let queryString = Object.keys(filtersParams)
        .map((key) => key + '=' + filtersParams[key])
        .join('&');
  
      let productUrl;
  
      console.log(activePage, 'activePage------------------');
      // TODO- need to check this condition
      /* if (
        activePage !== 0 &&
        activePage !== 'undefined' &&
        activePage !== undefined &&
        activePage !== '' &&
        activePage !== null
      ) {
        let product = productArray[activePage - 1];
        const strProducts = JSON.stringify(product);
        const encodedData = encodeURIComponent(strProducts);
        if (encodedData) {
          console.log(queryString, 'queryString---------------');
          if (queryString) {
            queryString = queryString + '&lastEvaluatedKey=' + encodedData;
          } else {
            queryString = 'lastEvaluatedKey=' + encodedData;
          }
        }
      } */
  
      if (Object.keys(queryString).length === 0 && queryString.constructor === Object) {
        // productUrl = `${process.env.REACT_APP_PRODUCT_API_END_POINT}products?${queryString}`;
        productUrl = `${process.env.REACT_APP_PRODUCT_API_END_POINT}products?limit=1000&${queryString}`;
      } else {
        // eslint-disable-next-line no-unused-vars
        // productUrl = `${process.env.REACT_APP_PRODUCT_API_END_POINT}products?${queryString}`;
        productUrl = `${process.env.REACT_APP_PRODUCT_API_END_POINT}products?limit=1000&${queryString}`;
      }
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(`${productUrl}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
  
      const data = await response.json();
  
      if (response.status !== 200) {
        throw new Error(`Failed to fetch products. Status: ${response.status}, Message: ${data.message || 'Unknown error'}`);
      }
  
      setTotalCount(data.data.Count);
      setProductlist(data.data.Items);
      setIsLoading(false);
  
      const newArray = [
        ...productArray,
        {
          createdAt:
            data.data.LastEvaluatedKey && data.data.LastEvaluatedKey.length > 0
              ? data.data.LastEvaluatedKey.createdAt
              : null,
          productId:
            data.data.LastEvaluatedKey && data.data.LastEvaluatedKey.length > 0
              ? data.data.LastEvaluatedKey.productId
              : null,
        },
      ];
      setproductArray(newArray);
      setIsLoading(false)
      // console.log(productList.length);
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching products:', error);
      // Handle the error or notify the user accordingly
    }
  };
  

  // gets call the method to get the data
  useEffect(() => {
    getProducts(activePagination);
  }, [JSON.stringify(filters)]);

  const getCategories = async () => {
    fetch(
      `${process.env.REACT_APP_PRODUCT_API_END_POINT}category`,

      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          setcategoryList(Item);
        }
      });
  };

  /* Function to get variant types */

  const getVariantType = async () => {
    await fetch(`${process.env.REACT_APP_PRODUCT_API_END_POINT}variants`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data.Items);
        setvarientType(data.data.Items);
      });
  };

  useEffect(() => {
    getCategories();
    getVariantType();

    // getProductList();
  }, []);

  // Handle the event from child to parent. It is being used for refresh the data after edit or delete
  const refreshProductData = () => {
    // When popup is open then not call the loader
    setIsPopup(true);
    // Not call the get location method again
    getProducts(activePagination);
  };
  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshProductData: () => {
      // Not call the get product method again
      getProducts(activePagination);
    }
  }));

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    // show loader
    setIsLoading(true);
    const data = {
      // isActive: selectedStatus === 'Active',
      status: selectedStatus,
      updatedBy: userId,
      productIds: selectedProductLists
    };

    try {
      // const encodedLocationId = encodeURIComponent(locationId);
      // console.log('encodedLocationId', encodedLocationId);
      // ?locationId=${encodedLocationId}
      const response = await axios.put(
        // `${BASE_URL}/memberships/updateBulkStatus?locationId=LOC%23227a3546-10a3-446b-ba5f-0971212d47bf`,
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/updateProductsBulk`,
        data
      );

      if (!response.data?.error) {
        // setShowSuccessMessage(true);
        // setSuccessMessage(response.data?.message || 'Product status updated');
        // toast.success(response.data?.message || 'Product status updated');
        setSelectedStatus('');
        setSelectedProductLists([]);
        setIsUpdated(true);
         setOpen(true);
        // getLocations();
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
            'Something went wrong with update product status'
        );
      }
      setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getProducts();
        setIsUpdated(false);
      }
    }
  };

  return (
    <Grid xs={12}>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {/* {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={getProducts}
        />
      )} */}

      <Loader IsLoading={isLoading} />
      <ProductGridView
        productLists={productList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        totalCount={totalCount}
        categoryList={categoryList}
        paginationProducts={getProducts}
        setactivePagination={setactivePagination}
        refreshProductData={refreshProductData}
        showScreen={showScreen}
        setShowScreen={setShowScreen}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleBulkAdd={handleBulkAdd}
        btnLoading={btnLoading}
        selectedProductLists={selectedProductLists}
        setSelectedProductLists={setSelectedProductLists}
        open={open}
        setOpen={setOpen}
        varientType={varientType}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                Are you sure you want to mark Activate/Deactivate,
                <Typography className="title-text-sub">
                all the selected items?
              </Typography>
              </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected products have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default React.forwardRef(ProductListAPI);
