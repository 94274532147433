import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';
import React, { useState } from 'react';
import { getLocalData } from 'src/utils';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from 'src/services/ApiService';
import Loader from 'src/components/Loader';
import LocationDetailRow from './LocationDetailRow';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const LocationDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

const LocationDetails = ({
  data,
  getItemForEdit,
  refreshLocationData,
  setShowScreen,
  setSearchText
}) => {
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let locationDetails = data;

  // Get the loggedIn user Id
  const userId = getLocalData('userId');

  const [open, setOpen] = useState(false);

  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [sucessMessage, setSuccessMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);

  // Method to open the delete dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the delete dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      deleteLocation();
    } else {
      setOpen(false);
    }
  };

  // Method to open the edit dialog
  const handleClickEdit = (e) => {
    let crossElm = document.getElementsByClassName('details-remove-icon')[0];
    if (crossElm) crossElm.click();

    let target = e && e.target;
    let lid;
    if (target) lid = target.getAttribute('id');

    // find an element from body
    let cls = 'edit_btn_' + lid;
    getItemForEdit(cls);

    setShowScreen(2);
  };
  const handleCloseDetails = () => {
    setSearchText('');
    setShowScreen(0);
  };

  const closePopup = () => {
    setShowScreen(0);
    setSearchText('');
    refreshLocationData();
  };

  // Method to delete the location
  const deleteLocation = () => {
    setIsLoading(true);
    let locationId = locationDetails.sortKey;
    let franchiseeId = locationDetails.franchiseeId;
    if (locationId) {
      let url = `${
        process.env.REACT_APP_API_END_POINT
      }locations/deleteLocation?locationId=${encodeURIComponent(
        locationId
      )}&updatedBy=${encodeURIComponent(
        userId
      )}&franchiseeId=${encodeURIComponent(franchiseeId)}`;

      ApiService.delete(url)
        .then((response) => {
          return response;
        })
        .then((data) => {
          if (data) {
            let crossElm = document.getElementsByClassName(
              'details-remove-icon'
            )[0];
            if (crossElm) crossElm.click();
            setShowSuccessMessage(true);
            setSuccessMessage(
              'Selected location has been deactivated successfully!'
            );
            setOpen(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setShowError(true);
          setErrorMessage(error.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Grid sx={{ padding: '0 2rem' }}>
        <LocationDetailsWrapper
          maxWidth={'100%'}
          className="location-details-wrapper p-0"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ paddingBottom: '15px' }}
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseDetails()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>
                <Typography className="header-blue-32 d-flex">
                  <span className="location-name">
                    {' '}
                    {locationDetails.locationName}{' '}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="details-remove-icon no-hover"
                  onClick={handleClickOpen}
                >
                  <DeleteIcon />
                </Button>

                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  id={locationDetails.locationId}
                  onClick={handleClickEdit}
                  className="btn-primary edit-button"
                >
                  Edit
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box className="location-details-container">
            <Grid container rowGap={4} mt={3} className="location-details-row">
              <LocationDetailRow
                label="LOCATION NAME"
                value={locationDetails?.locationName}
              />
              <LocationDetailRow label="UNIT#" value={locationDetails?.unit} />
              <LocationDetailRow
                label="LOCATION PHONE NUMBER"
                value={locationDetails?.phone}
              />
              <LocationDetailRow
                label="LOCATION EMAIL"
                value={locationDetails?.email}
              />
              <LocationDetailRow
                label="LOCATION NOTES"
                value={locationDetails?.notes}
              />
              <LocationDetailRow
                label="OPENING DATE"
                value={locationDetails?.openingDate}
              />
              <LocationDetailRow
                label="SUPPORT SPECIALIST"
                value={locationDetails?.managerName}
              />
              <LocationDetailRow
                label="BUSINESS ENTITY"
                value={locationDetails?.franchiseeName}
              />
              <LocationDetailRow
                label="STATUS"
                value={locationDetails?.openingStatus}
              />
              <LocationDetailRow
                label="PRIMARY CONTACT NAME"
                value={locationDetails?.primaryContactName}
              />
              <LocationDetailRow
                label="PRIMARY EMAIL"
                value={locationDetails?.primaryContactEmail}
              />
              <LocationDetailRow
                label="PRIMARY PHONE"
                value={locationDetails?.primaryContactPhone}
              />
              <LocationDetailRow
                label="LIVE LOCATION"
                value={locationDetails?.openingFlag ? 'Yes' : 'No'}
              />
              <LocationDetailRow
                label="TWILIO NUMBER"
                value={locationDetails?.twilioNumber}
              />
              <LocationDetailRow
                label="ADDRESS"
                value={`${locationDetails?.addressLine1} ${locationDetails?.addressLine2}, ${locationDetails?.city}, ${locationDetails?.state}, ${locationDetails?.postalCode}`}
              />
              <LocationDetailRow
                label="LOCATION URL"
                value={locationDetails?.locationUrl}
              />
              <LocationDetailRow
                label="FACEBOOK URL"
                value={locationDetails?.facebookUrl}
              />
              <LocationDetailRow
                label="INSTAGRAM HANDLE"
                value={locationDetails?.instagramUrl}
              />
            </Grid>

            {showError && (
              <Grid container mt={3} className="location-details-row">
                <Box className="error-message">{errorMessage}</Box>
              </Grid>
            )}
          </Box>
        </LocationDetailsWrapper>
      </Grid>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LocationDetails;
