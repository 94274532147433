/* eslint-disable no-unused-vars */
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer
} from '@mui/material';
import React from 'react';
import { FiUsers } from 'react-icons/fi';
import { FaCalendarAlt } from 'react-icons/fa';
import { Accordian } from './index';
import { CustomButton } from 'src/components/Global';

export const Summary = (props) => {
  const {
    handleOpenScreen,
    setIsInitialState,
    notesData,
    selectedReason,
    selectedRowsData,
    postData,
    quantity,
    btnLoading
  } = props;
  console.log(quantity);
  const handleSummaryClose = (type) => {
    if (type === 'close') {
      setIsInitialState(true);
    }
    handleOpenScreen(1);
  };

  const date = new Date();
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const currentDate = `${day}-${month}-${year}`;

  return (
    <div className="service-container order-container">
      <Box
        sx={{ width: '100%' }}
        paddingBottom="20px"
        className="Main-container common-form-container"
      >
        <Stack direction="column" className="container">
          <Typography
            variant="variant2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
            className="header-blue-24"
          >
            <Box
              onClick={() => handleSummaryClose()}
              className="back-arrow-close"
              sx={{ marginTop: '0px' }}
            >
              <svg
                width="27"
                height="19"
                viewBox="0 0 27 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                  fill="#003087"
                />
              </svg>
            </Box>
            Inventory Adjustment Summary
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center" gap={4}>
                      <FiUsers className="orange-icons" />
                      <h3 className="accordian-header">Operator</h3>
                    </Box>
                    <Typography className="black-text-14">
                      Franchise Name
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center" gap={4}>
                      <FaCalendarAlt className="orange-icons" />
                      <h3 className="accordian-header">Date Adjusted</h3>
                    </Box>
                    <Typography className="black-text-14">
                      {currentDate}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow>
                <Accordian
                  selectedReason={selectedReason}
                  notesData={notesData}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Stack>

        <Stack direction="column" className="container" marginTop={2}>
          <Typography
            variant="variant2"
            // sx={{ fontSize: '24px', textAlign: 'left' }}
            className="header-blue-24"
          >
            Items Adjusted
          </Typography>
          <TableContainer className="table-container">
            <Table>
              <TableHead className="table-header">
                <TableRow>
                  <TableCell className="accordian-header">Title</TableCell>
                  <TableCell>Categories</TableCell>
                  <TableCell>Variants</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Expiry Date</TableCell>

                  <TableCell className="accordian-header">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRowsData?.map((item) => {
                  return (
                    <>
                      <TableRow className="table-row">
                        <TableCell className="table-td">{item?.fName}</TableCell>
                        <TableCell className="table-td">
                          {item?.categoryName}
                        </TableCell>
                        <TableCell className="table-td">
                          {item?.variants
                            ?.filter((variant) => variant?.value)
                            ?.map?.((subItem, index, array) =>
                              index === array.length - 1
                                ? subItem?.value
                                : `${subItem?.value}, `
                            )
                            ?.join('') || '-'}
                        </TableCell>
                        <TableCell className="table-td">{item?.sku}</TableCell>
                        <TableCell className="table-td">
                          {item?.expiryDate}
                        </TableCell>
                        <TableCell className="table-td">
                          {quantity[item?.sortKey] || 0}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          marginTop={5}
          className="summary-actions summary-page-button"
        >
          <CustomButton
            disabled={btnLoading}
            variant="outlined"
            onClick={handleSummaryClose}
          >
            Revise Adjustment
          </CustomButton>

          <CustomButton
            loading={btnLoading}
            variant="contained"
            onClick={postData}
          >
            Confirm Adjustment
          </CustomButton>
        </Stack>
      </Box>
    </div>
  );
};
