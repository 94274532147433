import {
  Box,
  Typography,
  Container,
  Button,
  IconButton,
  Card,
  InputAdornment,
  TextField,
  styled
} from '@mui/material';
// import Environment from '../../../../environment';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useRef } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import '../../style.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Loader from '../../../../components/Loader';
import GlobalData from '../../../../global';

// import Logo from 'src/components/LogoSign';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function ResetPassword() {
  const navigate = useNavigate();
  
  // Get the title from global data
  const title = GlobalData[0].title;
  // const domain = Environment[0].apiAuthEndPoint;
  const [searchParams] = useSearchParams();
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  let ResetToken = searchParams.get("token");
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (!hasRunRef.current) {
      // Now show the loader
      setIsLoading(true);
      const body = { token: ResetToken };
      axios
        .post(
          `${process.env.REACT_APP_API_AUTH_END_POINT}api/auth/validateResetPasswordToken`,
          body
        )
        .then((response) => {
          console.log("response.status", response.status);
          if (response.status === 200) {
            if (response.data.success) {
              // console.log(response.success);
              // navigate("/passwordReset");
              // Now show the loader
              setIsLoading(false);
            }
          } else {
            // Now show the loader
            setIsLoading(false);
            navigate("/link-expired");
          }
        })
        .catch((error) => {
          console.log(error.response);
          // Now show the loader
          setIsLoading(false);
          if (error.response && error.response.status === 400) {
            navigate("/link-expired");
          }
        });

      hasRunRef.current = true;
    }
  }, [ResetToken, navigate]);

  const [givePass, givePassword] = useState(false);
  const [confirmPass, confirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfimrPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [ConfirmpasswordError, setConfirmPasswordError] = useState("");

  // const [user, setUser] = useState([]);

  const validateConfirmPassword = (passwordValue) => {
    let val = passwordValue;
    console.log("val", val)
    if (confirmpassword === password) {
      setConfirmPasswordError("");
    } else {
      setConfirmPasswordError("New password and confirm password does not match");
    }
  };

  const validateNewpassword = (passwordValue) => {
    let passval = passwordValue;
    const passRex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

    setPassword(passval);

    if (!passval) {
      setPasswordError("Please enter new password");
      return;
    }
    if (!passRex.test(passval)) {
      setPasswordError("Password must contains a combination of uppercase, lowercase letter, number, and special character");
      return;
    }
    if (passval.length <= 7) {
      setPasswordError("Password must be at least 8 characters");
      return;
    }
    setPasswordError("");
  };

  const checkPassword = (val) => {
    // setPassword(passwordValue);

    if (!val) {
      setPasswordError("Please enter new password");
      // return;
    }
  };
  const checkCnfPassword = (val) => {

    if (!val) {
      setConfirmPasswordError("Please enter confirm password");
      // return;
    }
  };

  const passShow = () => {
    if (givePass) {
      givePassword(false);
    } else {
      givePassword(true);
    }
  };

  const confirmPassShow = () => {
    if (confirmPass) {
      confirmPassword(false);
    } else {
      confirmPassword(true);
    }
  };

  const forceChange = () => {
    if (confirmpassword && password) {
      if (password.length >= 7 && confirmpassword.length >= 7 && confirmpassword === password) {
        setConfirmPasswordError("");
        // const searchParams = new URLSearchParams(window.location.search);
        // Now show the loader
        setIsLoading(true);
        // const setToken = searchParams.get("token");
        let token = ResetToken;

        fetch(
          `${process.env.REACT_APP_API_AUTH_END_POINT}api/auth/forceChangePassword`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: token,
              password: confirmpassword,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("data", data);
            if (data.success) {
              // Now show the loader
              setIsLoading(false);
              navigate("/password-changed");
            } else {
              // Now show the loader
              setIsLoading(false);
              let msg = data.message;
              if (msg) setConfirmPasswordError(msg);
            }
          });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Password validation
    if (!password) {
      setPasswordError("Please enter new password");
      return;
    }
    if (password.length <= 7) {
      setPasswordError("Password must be at least 8 characters");
      return;
    }
    setPasswordError("");

    if (!confirmpassword) {
      setConfirmPasswordError("Please enter confirm password");
      return;
    }
    if (confirmpassword.length <= 7) {
      setConfirmPasswordError("Password must be at least 8 characters");
      return;
    }
    setConfirmPasswordError("");
    validateConfirmPassword();
    //  else {
    //   setConfirmPasswordError("");
    //   validateConfirmPassword();
    //   // reDirect();
    // }

    // const fetchData = () => {
    //     return fetch("https://jsonplaceholder.typicode.com/users")
    //         .then((response) => response.json()).then((data) => setUser(data));
    // }
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      <Helmet>
        <title>Reset password | {title}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xxl" sx={{ padding: '0px !important' }}>
          <div className='main'>
            <div className="parent-container-main">
              <div className="logo-text">
                <div className="zoom-room-logo">
                  <img
                    src={"/static/images/logo/zrlogo.png"}
                    style={{ maxWidth: "100%" }}
                    className="logo-image"
                    alt="zoom-room-logo"
                  />
                </div>
                <div className="hero-text">
                  <Typography className="hero-message">
                    WE DON'T TRAIN DOGS.
                  </Typography>
                  <Typography className="hero-message">
                    WE TRAIN THE PEOPLE WHO LOVE THEM.
                  </Typography>
                </div>
              </div>

              <div className="form-section">
                <div className="zoom-room-line">
                  <div className="horizontal-line" />
                </div>
                <div className="form1">
                  <Card className="form-area-password-reset">
                    <Container component="main" maxWidth="xs" className="login-page">
                      <Box
                        sx={{
                          marginTop: 0,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="title-text">RESET PASSWORD</Typography>
                        <Typography className="title-text-sub">
                          Please type something you'll remember
                        </Typography>
                        <Box
                          component="form"
                          onSubmit={handleSubmit}
                          className="reset-password-form"
                        >
                          <TextField
                            margin="normal"
                            fullWidth
                            name="new-password"
                            placeholder="Must be 8 character"
                            type={givePass ? "text" : "password"}
                            id="Password"
                            value={password}
                            error={Boolean(passwordError)}
                            helperText={passwordError}
                            onChange={(e) => validateNewpassword(e.target.value)}
                            onKeyUp={(e) => checkPassword(e.target.value)}
                            autoComplete="new-password"
                            label="NEW PASSWORD"
                            className="Pass-field field-label-css"
                            autoFocus
                            // InputProps={{ style:{borderRadius:'12px'}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={passShow}>
                                    {givePass ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              // style: { borderRadius: '12px', padding: '5px', fontSize: '16px', color: '#525252' }
                              style: {
                                borderRadius: "12px",
                                padding: "20px 17px",
                                fontSize: "16px",
                                color: "#053288",
                                height: "76px",
                              },
                            }}
                          />

                          <TextField
                            margin="dense"
                            fullWidth
                            name="confirm-password"
                            placeholder="Repeat Password"
                            type={confirmPass ? "text" : "password"}
                            id="password"
                            value={confirmpassword}
                            error={Boolean(ConfirmpasswordError)}
                            helperText={ConfirmpasswordError}
                            onChange={(e) => setConfimrPassword(e.target.value)}
                            onKeyUp={(e) => checkCnfPassword(e.target.value)}
                            autoComplete="confirm-password"
                            label="CONFIRM PASSWORD"
                            className="Cnfpass-field field-label-css"
                            // InputProps={{ style:{borderRadius:'12px'}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={confirmPassShow}>
                                    {confirmPass ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              // style: { borderRadius: '12px', padding: '5px', fontSize: '16px', color: '#525252' }
                              style: {
                                borderRadius: "12px",
                                padding: "20px 17px",
                                fontSize: "16px",
                                color: "#053288",
                                height: "76px",
                              },
                            }}
                          />

                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className="button-style"
                            onClick={forceChange}
                          >
                            <Typography className="button-text">
                              RESET NOW
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                    </Container>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MainContent>
    </>
  );
}

export default ResetPassword;
