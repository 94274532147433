/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Avatar,
    Card,
    CardHeader,
    IconButton,
    useTheme,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableContainer,
    Checkbox,
    Grid,
    Menu,
    MenuItem
} from '@mui/material';
import Loader from '../../../components/Loader';

export const TemplateTable
    = (props) => {
        const {
            searchQuery,
            isLoading,
            setIsLoading,
            setShowScreen,
            selectedRows,
            setSelectedRows,
            selectedStatus,
            setSelectedStatus,
            handleBulkAdd,
            btnLoading,
            rowData,
            setRowData
        } = props;
        const theme = useTheme();
console.log(rowData, 'rowDatares');
        const [searchText, setSearchText] = useState('');
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const [anchor, setAnchor] = useState(null);
       

        // Apply search filter
        const openDetail = (row)=>{
setSelectedRows(row);
setShowScreen(1)
        }
        

        useEffect(() => {
            const searchText = searchQuery.toLowerCase();
            setSearchText(searchText);
            setPage(0);
        }, [searchQuery]);

        const filteredData = rowData.filter((item) => {
            return Object.values(item).some((value) =>
                value?.toString()?.toLowerCase()?.includes(searchText)
            );
        });

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0); // Reset page when rows per page changes
        };

        const paginatedData = filteredData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        const openMenu = (staffItem, event) => {
            // setStaffId(staffItem.staffId);
            console.log(staffItem, 'hgfhfghf');
            event.preventDefault();
            event.stopPropagation();
            setAnchor(event.currentTarget);
          
          };
          const closeMenu = () => {
            setAnchor(null);
          };

     // method to capitalize first letter
  function capitalizeStatus(status) {
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }
        console.log(paginatedData, 'paginatedDatapaginatedData');
        return (
            <><Loader IsLoading={isLoading} /><Box>
                <Card
                    sx={{
                        padding: '15px',
                        borderRadius: '19px 19px 0px 0px'
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        ml={2}
                    >
                        <Grid item>
                            {/* <CardHeader title="All Services" className="table-header" /> */}
                            {/* <h2 className="table-heading">All Services</h2> */}
                            <Typography className="table-heade header-blue-20">
                                {' '}
                                All Template
                            </Typography>
                        </Grid>
                    </Grid>
                    <TableContainer className="location-table-container">
                        <Table>
                            <TableHead>
                                <TableRow className="table-header">
                                    <TableCell>Template Name</TableCell>
                                    <TableCell>Channel</TableCell>
                                    <TableCell>Created By</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {paginatedData.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            key={row.sortKey}
                                            role="checkbox"
                                            tabIndex={-1}
                                            className="table-row cursor-pointer"
                                            onClick={(e) => openDetail(row, 3, e)}
                                        >
                                            <TableCell className="table-td">
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    sx={{ marginTop: 1 }}
                                                    gutterBottom
                                                    noWrap
                                                >
                                                    {row.TemplateName}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    gutterBottom
                                                    noWrap
                                                >
                                                    {row.TemplateType==='SMS'? 'Text' : capitalizeStatus(row.TemplateType)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    color="text.primary"
                                                    gutterBottom
                                                    noWrap
                                                >
                                                    {row.createdBy}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box p={2}>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    </Box>
                </Card>
            </Box></>
        );
    };
