import { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  Card,
  TextField,
  styled
} from '@mui/material';
// import Environment from '../../../../environment';
import { useNavigate, Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import '../../style.css';
import { Helmet } from 'react-helmet-async';
import Loader from '../../../../components/Loader';
import GlobalData from '../../../../global';
// import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
// import LoadingButton from '@mui/lab/LoadingButton';

// const GridWrapper = styled(Grid)(
//   ({ theme }) => `
//     background: ${theme.colors.gradients.blue5};
// `
// );

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

// const TypographyPrimary = styled(Typography)(
//   ({ theme }) => `
//       color: ${theme.colors.alpha.trueWhite[100]};
// `
// );

// const TypographySecondary = styled(Typography)(
//   ({ theme }) => `
//       color: ${theme.colors.alpha.trueWhite[70]};
// `
// );

function ForgotPassword() {
  // const domain = Environment[0].apiAuthEndPoint;
  // const [pending, setPending] = useState(false);
  // function handleClick() {
  //   setPending(true);
  // }

  // Get the title from global data
  const title = GlobalData[0].title;

  const navigate = useNavigate();
  // const theme = createTheme()
  // const [password, setPassword] = useState("");
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //     return () => {
  //       // && history.location.pathname === "any specific path")
  //       console.log("hhh", history);
  //       if (history.action === "POP") {
  //         history.replace(history.location.pathname,);
  //       }
  //     };
  //   }, [history])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value.trim();
    // const emailRex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const emailRex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!email) {
      setEmailError('Please enter your email');
      return;
    }
    if (!emailRex.test(email)) {
      setEmailError('Please enter a valid email');
      return;
    }
    // else {
    setEmailError('');

    // Now send the reset password link to the user
    sendResetPasswordLink(email);
    // reDirect();
    // fetch(
    //     "https://anyn6vf0sl.execute-api.us-east-1.amazonaws.com/dev/api/auth/sendPasswordResetLink",
    //     {
    //         method: "post",
    //         headers: {
    //             Accept: "application/json",
    //             "Access-Control-Allow-Origin": "*",
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ "email": email }),
    //     },
    // ).then((response) => response.json())
    //     .then((data) => {
    //         console.log("v", data);
    //         if (data && data.success)
    //             navigate("/request-sent");
    //         else {
    //             let msg = data.message;
    //             setEmailError(msg);
    //         }
    //     });

    // };
  };

  // Method to send the reset password link to the user
  const sendResetPasswordLink = async (email) => {
    // show loader
    setIsLoading(true);
    const body = { email: email.toLowerCase() };
    const header = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_AUTH_END_POINT}api/auth/sendPasswordResetLink`,
        body,
        header
      );
      if (response.status === 200) {
        if (response.data.success) {
          // show loader
          setIsLoading(false);
          navigate('/request-sent');
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // show loader
        setIsLoading(false);
        if (error.response.status === 401 || error.response.status === 500) {
          setEmailError('The user does not exist with this email account.');
        } else if (error.response.status === 400) {
          setEmailError(
            'You have already requested a password reset link, please try again later!'
          );
        }
      }
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Helmet>
        <title>Forgot Password | {title}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xxl" sx={{ padding: '0px !important' }}>
          <div className="main">
            <div className="parent-container-main">
              <div className="logo-text">
                <div className="zoom-room-logo">
                  <img
                    src={'/static/images/logo/zrlogo.png'}
                    style={{ maxWidth: '100%' }}
                    className="logo-image"
                    alt="zoom-room-logo"
                  />
                </div>
                <div className="hero-text">
                  <Typography className="hero-message">
                    WE DON'T TRAIN DOGS.
                  </Typography>
                  <Typography className="hero-message">
                    WE TRAIN THE PEOPLE WHO LOVE THEM.
                  </Typography>
                </div>
              </div>

              <div className="form-section">
                <div className="zoom-room-line">
                  <div className="horizontal-line" />
                </div>
                <div className="form1">
                  <Card className="form-area-forgot-password">
                    <Container
                      component="main"
                      maxWidth="xs"
                      className="forgot-password-page"
                    >
                      <Box
                        sx={{
                          marginTop: 0,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <Typography className="title-text">
                          FORGOT PASSWORD?
                        </Typography>
                        <Typography className="title-text-sub-forgot-password">
                          <p className="para-top">
                            Don't worry, it happens! Enter your ZR email
                          </p>
                          <p className="para-bottom">
                            address and we will send you a link to reset it.
                          </p>
                          {/* <br></br> */}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit}>
                          <TextField
                            margin="dense"
                            fullWidth
                            id="email"
                            placeholder="Email"
                            name="email"
                            label="EMAIL"
                            value={email}
                            error={Boolean(emailError)}
                            helperText={emailError}
                            autoComplete="email"
                            onChange={(e) => setEmail(e.target.value)}
                            className="Email-field field-label-css"
                            InputProps={{
                              style: {
                                borderRadius: '12px',
                                padding: '19px 17px',
                                fontSize: '16px',
                                height: '76px',
                                color: '#053288',
                                wordBreak: 'break-all'
                              }
                              // style: { borderRadius: '10px', padding: '5px', fontSize: '16px', color: '#525252' }
                            }}
                          />

                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className="button-style"
                          >
                            <Typography className="button-text">
                              REQUEST
                            </Typography>
                          </Button>
                          {/* //marginTop: '20px',  */}
                          <div className="backbtn">
                            <Grid
                              container
                              justifyContent="center"
                              style={{ color: '#003087' }}
                            >
                              <Link
                                to="/"
                                variant="body2"
                                className="link-style back-arrow"
                              >
                                <ArrowBackIcon
                                  className="back-arrow-style"
                                  style={{ margin: '-3px 2px' }}
                                />
                                <Grid item>BACK TO LOGIN</Grid>
                              </Link>
                            </Grid>
                          </div>
                        </Box>
                      </Box>
                    </Container>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MainContent>
    </>
  );
}

export default ForgotPassword;
