// import { } from 'react';
import { React, useEffect, useState} from 'react';
import Header from '../../../components/Header';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';
import {Box } from '@mui/material';
import DrawBundlesTable from './drawBundlesTable'; 
import { getLocalData } from 'src/utils';
import { useNavigate } from 'react-router-dom';
// import { HeaderButtonContext } from 'src/contexts/HeaderButtonContext';

const Bundles = () => {
  const navigate = useNavigate();
    const [showScreen, setShowScreen] = useState(0);
    const [openAddBundleModal, setAddBundleModal] = useState(false);
    const [openAddBundle, setOpenAddBundle] = useState(false);
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Bundles Management');
  // Get the title from global data
  // const {togglePopup,setTogglePopup,showScreen,setShowScreen,setIsToggleComponent}  = useContext(HeaderButtonContext)
  const title = GlobalData[0].title;
  // const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
console.log(showScreen, 'showScreenshowScreenshowScreen');
  useEffect(() => {
    // if (id) setSearchParams({ Id: id });

    // check if the user is logged in or not
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [Bundles, navigate]);
  const handleButtonClick = () => {
    setOpenAddBundle(true);
    setAddBundleModal(true);
  };

  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam:true
    },
    
  ];
// useEffect( ()=>{
//   if(showScreen===0){

//     setHeaderText('Bundles Management');
//   }
// },[showScreen])
// useEffect(()=>{
//   setIsToggleComponent(true)
// },[])
  return (
    <>
      <Box>
        <Helmet>
          <title>Bundle | {title}</title>
        </Helmet>
        <Box  sx={{paddingX:"30px"}} >  
        {showScreen===0 &&<Header title="Bundles Management" buttons={buttons} />}
        </Box>
      </Box>
      <DrawBundlesTable
      setAddBundleModal= {setAddBundleModal} 
      openAddBundle= {openAddBundle}
      openAddBundleModal={openAddBundleModal}
      setOpenAddBundle = {setOpenAddBundle}
      showScreen={showScreen}
      setShowScreen ={setShowScreen}
      />

    </>
  )
}
export default Bundles;