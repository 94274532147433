import React, { useState, useEffect } from 'react';
import { DynamicButton } from '../../../../components/Checkout';
import {
  Box,
  Grid,
  Typography,
  Stack,
  FormHelperText,
  TextField
} from '@mui/material';
import { DynamicTextField } from '../../../../components/GlobalComponents';
import { getLocalData } from '../../../../utils';
import Loader from 'src/components/Loader';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import {
  applyGiftCardDiscount,
  checkPromoCodeExists,
  getCardNumberDetail,
  getDiscountFromPromoCode,
  getMemberShipDetails
} from '../Functions/DiscountFunctions';

export const Discount = ({
  setSelected,
  selected,
  handleNext,
  handlePrevious,
  main
}) => {
  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');
  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const [loading, setLoading] = useState(false);
  const [queueId, setQueueId] = useState(selected?.queueId ?? '');
  const [isAvailable, setIsAvailable] = useState(false);
  const [codeError, setCodeError] = useState('');
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeId, setPromoCodeId] = useState('');
  const [isApplied, setIsApplied] = useState({
    codeApplied: false,
    message: ''
  });
  const [cardNumber, setCardNumber] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [isGiftCardAvailable, setIsGiftCardAvailable] = useState(false);
  const [memberships, setMemberships] = useState([]);
  const [giftCardId, setGiftCardId] = useState('');
  const [isGiftApplied, setIsGiftApplied] = useState(false);
  const payload = {
    locationId: locationId,
    clientName: selected?.client?.firstName || '',
    items: selected?.items?.map(({ TotalPrice, sortKey, ...rest }) => rest),
    createdBy: userId || ''
  };
  if (!selected?.guestClient) {
    payload.clientId = selected?.client?.sortKey || '';
  } else {
    payload.guestClient = true;
  }
  // TO FILTER MEMBERSHIP DETAILS IF SAME APPLIED ON MORE THAN 1 ITEM start ----
  useEffect(() => {
    let uniqueItems = [];
    const newArr = selected?.items?.map?.((item) => {
      if (item?.discountDetails?.memberShip?.memberShipName) {
        return item?.discountDetails;
      }
      return null;
    });
    const uniqueMemberShipIds = new Set();

    newArr?.forEach((item) => {
      if (!uniqueMemberShipIds?.has(item?.memberShipId)) {
        uniqueMemberShipIds?.add(item?.memberShipId);
        uniqueItems?.push(item);
      }
    });
    setMemberships(uniqueItems);
  }, [selected?.items]);
  // TO FILTER MEMBERSHIP DETAILS IF SAME APPLIED ON MORE THAN 1 ITEM end ----

  // HANDLE CHANGE FOR PROMOCODE TEXTFIELD start ----
  const handleChange = (type, value) => {
    setIsAvailable(false);
    setIsApplied({
      codeApplied: false,
      message: ''
    });
    let code = '';
    if (value) {
      code = value?.toUpperCase();
    } else {
      setCodeError('');
    }
    setPromoCode(code);
    if (code) {
      checkPromoCodeExists(
        code,
        locationId,
        BASE_URL,
        setIsAvailable,
        setCodeError,
        promoCode,
        setPromoCodeId
      );
    }
  };
  // HANDLE CHANGE FOR PROMOCODE TEXTFIELD end ----

  // HANDLE CHANGE GIFT CARD FIELD start -------
  const handleCardNumberChange = (e) => {
    setIsGiftApplied(false);
    const { name, value } = e.target;
    if (name === 'Card Number' && value?.length < 13) {
      const formattedValue = value?.toUpperCase()?.replace(/[^0-9A-Z]/g, '');
      setCardNumberError('');
      if (formattedValue?.length > 5) {
        debouncedIsExist(formattedValue.replace(/-/g, ''));
      }
      if (formattedValue?.length < 6) {
        setCardNumberError('Card number should be at least 6 characters long.');
        setIsGiftCardAvailable(true);
      } else {
        setIsGiftCardAvailable(false);
      }
      if (formattedValue?.length <= 12) {
        setCardNumber(formattedValue);
        setIsGiftCardAvailable(false);
      }
    }
  };
  // HANDLE CHANGE GIFT CARD FIELD end -------

  useEffect(() => {
    if (isApplied?.codeApplied) {
      setCodeError('');
    }
  }, [isApplied?.codeApplied]);

  useEffect(() => {
    if (isGiftApplied) {
      setCardNumberError('');
    }
  }, [isGiftApplied]);

  useEffect(() => {
    // Update the active step value in local storage
    localStorage.setItem('activeStep', '2');
  }, []);

  useEffect(() => {
    getMemberShipDetails(
      setLoading,
      payload,
      BASE_URL,
      setQueueId,
      selected,
      setSelected
    );
  }, []);

  useEffect(() => {
    localStorage.setItem('prevStep', 2);
  }, []);

  const debouncedIsExist = useCallback(
    debounce(
      (value) =>
        getCardNumberDetail(
          value,
          setGiftCardId,
          locationId,
          setCardNumber,
          setIsGiftCardAvailable,
          setCardNumberError
        ),
      1000
    ),
    []
  );

  // MAIN UI

  return (
    <Grid
      container
      spacing={2}
      className="giftCardWrap"
      sx={{
        marginTop: '16px',
        marginLeft: '0px',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <Loader IsLoading={loading} />

      <Box sx={{ width: '420px', textAlign: 'left' }}>
          <Box marginTop="10px">
            <Typography
              variant="h4"
              align="left"
              color="primary"
              style={{ marginBottom: '10px' }}
            >
              Promo Code
            </Typography>
            <Box className="clientItem">
              <div
                style={{
                  margin: '0 auto 10px 0',
                  width: '380px'
                }}
              >
                <DynamicTextField
                  className={`input-field-styling required-field ${
                    codeError ? 'failed' : 'success'
                  }`}
                  type="text"
                  name="code"
                  label="Enter Code"
                  required={false}
                  fullWidth
                  error={codeError}
                  value={promoCode}
                  onChange={handleChange}
                />
                {promoCode && !isAvailable && (
                  <FormHelperText className="failed">
                    {`Promo code - "${promoCode}" is not available`}
                  </FormHelperText>
                )}
                {promoCode && isAvailable && !isApplied?.codeApplied && (
                  <FormHelperText className="failed">
                    {isApplied?.message}
                  </FormHelperText>
                )}
                {promoCode && isApplied?.codeApplied && (
                  <FormHelperText sx={{ color: 'green' }}>
                    {`Your promo code - "${promoCode}" has been successfully applied.`}
                  </FormHelperText>
                )}
              </div>
            </Box>
            <Box>
              <DynamicButton
                disabled={!isAvailable}
                title={'Apply Promo Code'}
                variant="contained"
                onClick={() =>
                  getDiscountFromPromoCode(
                    setLoading,
                    locationId,
                    promoCode,
                    queueId,
                    BASE_URL,
                    isApplied,
                    setIsApplied,
                    selected,
                    setSelected,
                    promoCodeId
                  )
                }
                style={{
                  width: '380px',
                  borderRadius: '10px',
                  fontWeight: '600',
                  fontSize: '16px',
                  border: '2px solid #003087'
                }}
              />
            </Box>
          </Box>
        <Box marginTop="10px">
          <Typography
            variant="h4"
            align="left"
            color="primary"
            style={{ marginBottom: '10px' }}
          >
            Gift Card
          </Typography>
          <Box className="clientItem">
            <div
              style={{
                margin: '0 auto 10px 0',
                width: '380px'
              }}
            >
              <TextField
                sx={{ marginBottom: '10px', width: '380px' }}
                name="Card Number"
                fullWidth
                id="outlined-required"
                label="Card Number"
                className="location-field input-field-styling required-field"
                onChange={handleCardNumberChange}
                error={!!cardNumberError}
                helperText={cardNumber && cardNumberError}
                value={cardNumber}
                InputLabelProps={{
                  className: 'label-style-create-franchisee'
                }}
              />
              {isGiftApplied && (
                <FormHelperText sx={{ color: 'green' }}>
                  {`Your gift card - "${cardNumber}" has been successfully applied.`}
                </FormHelperText>
              )}
            </div>
          </Box>
          <Box>
            <DynamicButton
              disabled={!isGiftCardAvailable}
              title={'Apply Gift Card'}
              variant="contained"
              onClick={() =>
                applyGiftCardDiscount(
                  giftCardId,
                  setLoading,
                  locationId,
                  queueId,
                  BASE_URL,
                  setCardNumberError,
                  setIsGiftApplied
                )
              }
              style={{
                width: '380px',
                borderRadius: '10px',
                fontWeight: '600',
                fontSize: '16px',
                border: '2px solid #003087'
              }}
            />
          </Box>
        </Box>
        <Box marginTop="10px">
          {selected?.items?.filter(
            (item) => item?.discountDetails?.memberShip?.memberShipName
          )?.length > 0 && (
            <Typography
              variant="h4"
              align="left"
              color="primary"
              style={{ marginBottom: '10px' }}
            >
              Memberships
            </Typography>
          )}
          {selected?.items?.filter(
            (item) => item?.discountDetails?.memberShip?.memberShipName
          )?.length > 0 &&
            memberships?.map?.((item) => {
              return (
                <Box
                  className="memBox"
                  style={{ textAlign: 'left', color: '#706e6e' }}
                >
                  <Box style={{ width: '380px', margin: '0 0 0 auto' }}>
                    <Stack justifyContent="space-between" flexDirection="row">
                      <Typography>
                        {item?.memberShip?.memberShipName}
                      </Typography>
                      <Typography fontStyle="italic">
                        {/* Flat */}
                        {item?.memberShip?.discountApplied}% off
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
      {main && (
        <Box
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: '0'
          }}
        >
          <DynamicButton
            title={'Go Back'}
            variant="contained"
            onClick={handlePrevious}
            style={{
              padding: '10px 23px',
              borderRadius: '10px',
              textTransform: 'capitalize',
              maxWidth: 'max-content',
              fontWeight: '600',
              fontSize: '16px',
              marginRight: '10px',
              background: '#fff',
              color: '#003087',
              border: '2px solid #003087'
            }}
          />
          <DynamicButton
            title={'Order Summary'}
            variant="contained"
            onClick={handleNext}
            style={{
              padding: '10px 23px',
              borderRadius: '10px',
              textTransform: 'capitalize',
              maxWidth: 'max-content',
              fontWeight: '600',
              fontSize: '16px',
              border: '2px solid #003087'
            }}
          />
        </Box>
      )}
    </Grid>
  );
};
