/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  useTheme,
  IconButton
} from '@mui/material';

export const PurchaseTable = (props) => {
  const { tableRows, searchQuery, openSummary, handleEditOrder } = props;
  const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSearchTextChange = (searchText) => {   
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchText)
    );
  });


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedRows = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  console.log(paginatedRows)
 
  function getDateFormat(value) {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    });

    return formattedDate;
  }

  useEffect(() => { 
    let searchTxt = searchQuery ? searchQuery.toLowerCase() : '';
    handleSearchTextChange(searchTxt);
  }, [searchQuery]);

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      ><CardHeader
      // action={
      // }
      title="All Purchase Order"
      className="table-header-name"
      sx={{ fontSize: '30px', color: '#003087' }}
    />
       
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>PO #</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Order Date</TableCell>                
                <TableCell>Shipping Cost</TableCell>
                <TableCell>Sub Total</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.PRDSort}
                    className="table-row cursor-pointer"
                    onClick={(e) => openSummary(row.PRDSort, e)}
                  >
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row.orderNumber}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {row.supplierName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {getDateFormat(row.createdAt)}
                      </Typography>
                    </TableCell>             
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.subTotal &&
                          Number(row.shippingCost).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                          })}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.subTotal &&
                          Number(row.subTotal).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                          })}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.subTotal &&
                          Number(row.total).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                          })}
                      </Typography>
                    </TableCell> 
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {row.orderStatus}
                      </Typography>
                    </TableCell>                                       
                    <TableCell className="table-td">
                      {row.orderStatus === 'draft' && (
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          className="edit-btn"
                          onClick={(event) => handleEditOrder(row.PRDSort, 'create', event,)}

                        >
                          Edit
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}> 
          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
