
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';
import ErrorMessage from '../../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from '../../../../components/Loader';
// import { getLocalData } from 'src/utils';
import { AlertFilters } from './AlertFilters';
import { AlertTable } from './AlertTable';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Alert = (props) => {
  const { clientId, invokeRefresh, setInvokeRefresh } = props;
  // const locationId = getLocalData('locationId');
  // const userId = getLocalData('userId');
  const [loading, setLoading] = useState(true);
  const [showScreen, setShowScreen] = useState(1);
  const [isInitialState, setIsInitialState] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [open, setOpen] = React.useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  const [alertTypeList, SetAlertTypeList] = useState([]);

  const [filters, setFilters] = useState({
    status: null,
   alertFor: null,
   alertType: null,
   createdAt: null,
   startDate: null,
   endDate: null
  });
  
  useEffect(() => {
    if (showScreen === 1 && isInitialState) {
      setSearchQuery('');
      setIsInitialState(false);
    }
  }, [showScreen, isInitialState]);

  const handleOpenScreen = (value) => {
    setShowScreen(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleResetStates = () => {
    setIsInitialState(true);
    setShowScreen(1);
    setSelectedRows([]);
    setSelectedStatus('');
    getAllAlert();
  };

  const openDetail = (id, screen, e) => {
    e.preventDefault();
    e.stopPropagation();

    handleOpenScreen(screen);
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============

  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined ||
        (Array.isArray(filtersParams[key]) && filtersParams[key].length === 0)
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  const getAllAlert = async () => {
    let cid = localStorage.getItem('clientId');
    let encodedClientId = encodeURIComponent(clientId) || encodeURIComponent(cid);
    setLoading(true);
    setTableLoading(true);
    let queryString = filtersQuery();
    try {
      const [
        trophyResponse      
      ] = await Promise.all([
        axios.get(
          // `${process.env.REACT_APP_API_END_POINT}alerts?${queryString}`
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllAlerts?clientId=${encodedClientId}&${queryString}`
          // `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getAllAlerts?${queryString}`
        )
      ]);

      if (!trophyResponse?.data?.data?.error) {
        const modifiedData = trophyResponse?.data?.data?.Items.map(
          (item) => ({
            ...item,
            id: item.sortKey
          })
        );
        setServicesData(modifiedData);
        setLoading(false);
        setTableLoading(false);
      } else {
        setLoading(false);
       // setShowErrorMessage(true);
        setErrorMessage(
          trophyResponse.message || 'Something went wrong with Services'
        );
      }
      setLoading(false);
      setTableLoading(false);
    } catch (error) {
      console.warn('API Error', error);
      setLoading(false);
      setTableLoading(false);
      // setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };
  
  // get all alert type
  const getAlertType = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}alerts`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          // let Item = data.data.Items;
          let Item = data.data;
          SetAlertTypeList(Item);
        }
      });
  };

  useEffect(() => {
    getAllAlert();
  }, [JSON.stringify(filters), clientId]);
  
  // State change for invoke refresh
  useEffect(() => {
    if(invokeRefresh) {
      getAllAlert();
      setInvokeRefresh(false);
    }
  }, [invokeRefresh]);
  
  useEffect(() => {
    getAlertType();
  }, []);

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        handleResetStates();
        setIsUpdated(false);
      }
    }
  };
  
  return (
    <>
      <Loader IsLoading={loading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          getDetails={getAllAlert}
        />
      )}

      <Container maxWidth="100%" className="custom-container p-0">
        {(() => {
          switch (showScreen) {
            default:
              return (
                <Box justifyContent="center" direction="column">
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Grid item width={'100%'}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item marginY="20px"
                          xs={12} sm={6} >
                            <CustomButton
                              onClick={() => setIsFilters(!isFilters)}
                              variant={isFilters ? 'contained' : 'outlined'}
                            >
                              Filter (All)
                              <KeyboardArrowDownRoundedIcon
                                className={clsx(
                                  !isFilters && classes.closeY,
                                  isFilters && classes.openY
                                )}
                              />
                            </CustomButton>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            // marginY={{ sm: '20px' }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <SearchInput
                              value={searchQuery}
                              setSearchQuery={setSearchQuery}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {/* {loading ? (
                      <CircularProgress sx={{ marginTop: '80px' }} />
                    ) : ( */}
                    <>
                      {isFilters ? (
                        <Grid item width={isFilters ? 300 : 0}>
                          <AlertFilters
                            filters={filters}
                            setFilters={setFilters}
                            tableRows={servicesData}
                            alertTypeList={alertTypeList}
                          />
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                      >
                        <AlertTable
                          tableRows={servicesData}
                          searchQuery={searchQuery}
                          openDetail={openDetail}
                          loading={tableLoading}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                        />
                      </Grid>
                    </>
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Save Changes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, Do you want to
                  <Typography className="title-text-sub">save them?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Alert's status has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'No'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Alert;
