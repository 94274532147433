import React, { useEffect, useState } from "react";
import { Box, Card, Divider, FormControl, Grid, InputLabel, Paper, Select, Typography } from "@mui/material";
import { getLocalData } from '../../utils';

import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import { ApiService } from "../../services/ApiService";

export const CampaignFilters = ({ setFilters, filters }) => {
  const franchiseeId = getLocalData('franchiseeId');

  const useStyles = makeStyles(() => ({
    openY: {
      transform: "rotate(90deg)",
      color: "#003087"
    },
    closeY: {
      transform: "rotate(0deg)",
      color: "#003087"
    }
  }));
  //   const locationId = setFullLocationId(getLocalData('locationId'));
  const classes = useStyles();
  const statusOption = [
    { label: "Completed", value: "COMPLETED" },
    // { label: "Paused", value: "PAUSED" },
   // { label: "Schedule", value: "SCHEDULED" }
    { label: "Schedule", value: "SCHEDULED" }
  ];
  const ChannelOptions = [
    { label: "Email", value: "Email" },
    // { label: "SMS", value: "Sms" }
   // { label: "SMS", value: "SMS" }
  ];
  const ScheduleOptions = [
    { label: "Immediate", value: "immediately" },
    { label: "Once", value: "once" },
    // { label: "Daily", value: "daily" },
    // { label: "Hourly", value: "hourly" },
    // { label: "Weekly", value: "weekly" },
    // { label: "Monthly", value: "monthly" }
  ];

  const [openFilter, setOpenFilter] = useState({
    channel: false,
    cohort: false,
    schedule: false
  });
  const [cohortsList, setCohortsList] = useState([]);

  // const getCohortsList = async () => {

  //   let response = await ApiService.get(
  //     `https://vtqf4ke0yj.execute-api.us-east-1.amazonaws.com/dev/pinpoint/getAllSegments`
  //   );
  //   let Items =
  //     response && response.SegmentsResponse && response.SegmentsResponse.Item
  //       ? response.SegmentsResponse.Item
  //       : [];

  //   setCohortsList(Items);

  // };
  const getCohortsList = async () => {
    try{
    let encodedFranchiseeId = encodeURIComponent(franchiseeId)
    console.log(encodedFranchiseeId,'encodedFranchiseeId')
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

      const response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllFranchiseeCohorts?${queryString}&franchiseeId=${encodedFranchiseeId}`
      )
     
      let Items =
        response && response?.data && response?.data?.Items
          ? response?.data?.Items
          : [];

       setCohortsList(Items);          
    }catch(err){
      console.log(err)
    }
  };
  
  useEffect(() => {
    getCohortsList();
  }, []);
  const changeType = (value) => {
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const ChangeValue = (value, type) => {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      channel: field === "channel" ? !prevState.channel : false,
      cohort:
        field === "cohort"
          ? !prevState.cohort
          : false,
      schedule:
        field === "schedule"
          ? !prevState.schedule
          : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      SegmentId: null,
      Channel: null,
      ScheduleType: null
    });

    setOpenFilter({
      cohort: false,
      channel: false,
      schedule: false
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: "100%",
            marginBottom: "10px"
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status ?? ""}
            onChange={(event) => changeType(event.target.value)}
            label="Status"
            className="status-field field-label-css"
          >
            <MenuItem key={1} id={1} value='All'>
              All
            </MenuItem>
            {statusOption.map((item, index) => (
              <MenuItem key={index} id={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: "600",
                  color: "#003087",
                  fontSize: "16px"
                }}
              >
                Filters
              </Typography>
            </Grid>
            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  cursor: "pointer"
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Category filter */}

        {/* //price  */}
        <Grid item xs={12} onClick={() => handleOpenFilter("cohort")} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.cohort && classes.closeY,
                    openFilter.cohort && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Cohort</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.cohort && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined" >
              <InputLabel>Cohort</InputLabel>
              <Select
                onChange={(event) => ChangeValue(event.target.value, "SegmentId")}
                value={filters.SegmentId ?? ""}
                sx={{
                  width: "100%"
                }}
                MenuProps={{
                  PaperProps: {
                      style: {
                          maxHeight: 224,
                         maxWidth:100
                      },
                  },
                }}
                label="Cohort"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} id={0} value="">
                  None
                </MenuItem> */}
                {cohortsList.map((item, index) => (
                  <MenuItem key={index} id={index} value={item.sortKey}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter("schedule")}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.schedule && classes.closeY,
                    openFilter.schedule && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Schedule</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.schedule && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Schedule</InputLabel>
              <Select
                onChange={(event) =>
                  ChangeValue(event.target.value, "ScheduleType")
                }
                value={filters.ScheduleType ?? ""}
                sx={{
                  width: "100%"
                }}
                label="Schedule"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} id={0} value="">
                  None
                </MenuItem> */}
                {ScheduleOptions.map((item, index) => (
                  <MenuItem key={index} id={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {/* expired */}
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter("channel")}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.channel && classes.closeY,
                    openFilter.channel && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Channel</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.channel && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Channel</InputLabel>
              <Select
                onChange={(event) =>
                  ChangeValue(event.target.value, "Channel")
                }
                value={filters.Channel ?? ""}
                sx={{
                  width: "100%"
                }}
                label="Channel"
                className="status-field field-label-css"
              >
                {/* <MenuItem key={0} id={0} value="">
                  None
                </MenuItem> */}
                {ChannelOptions.map((item, index) => (
                  <MenuItem key={index} id={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};