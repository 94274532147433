import {
  Box,
  Grid,
  Card,
  CardHeader,
  Divider
} from '@mui/material';
import { useEffect } from 'react';

function ProfileDetails({ profileDetails }) {
  console.log("profileDetails", profileDetails);
  useEffect(() => {
    // console.log("profileDetails", profileDetails);
  }, [profileDetails]);

  return (
    <Card sx={{ border: '1px solid #A5A5A5' }}>
      <CardHeader title="PERSONAL INFORMATION" className='text-primary' />
      <Divider />
      <Box px={2} py={2} display="flex" alignItems="flex-start">
        {profileDetails ? (
          <Box className="user-profile-container w-100 common-details-cont">
            <Grid container mb={3} className="location-details-row">
              <Grid item xs={12} sm={6} className="location-details-col">
                <Box className="field-label text-black weight-700 mb-10">NAME</Box>
                <Box className="field-value text-value-grey weight-700">{profileDetails.firstName} {profileDetails.lastName}</Box>
              </Grid>
              <Grid item xs={12} sm={6} className="location-details-col">
                <Box className="field-label text-black weight-700 mb-10">EMAIL</Box>
                <Box className="field-value text-value-grey weight-700">{profileDetails.email}</Box>
              </Grid>
            </Grid>
            <Grid container mb={3} className="location-details-row">
              <Grid item xs={12} sm={6} className="location-details-col">
                <Box className="field-label text-black weight-700 mb-10">PHONE</Box>
                <Box className="field-value text-value-grey weight-700">{profileDetails?.phoneNumber ? profileDetails?.phoneNumber : profileDetails?.phone}</Box>
              </Grid>
              <Grid item xs={12} sm={6} className="location-details-col">
                <Box className="field-label text-black weight-700 mb-10">EMERGENCY PHONE</Box>
                <Box className="field-value text-value-grey weight-700">{profileDetails.emergencyPhone}</Box>
              </Grid>
            </Grid>
            <Grid container mb={3} className="location-details-row">
              <Grid item xs={12} sm={6} className="location-details-col">
                <Box className="field-label text-black weight-700 mb-10">ROLE</Box>



                {profileDetails?.roleName ?
                  <Box className="field-value text-value-grey weight-700">{profileDetails?.roleName} </Box>
                  :
                  profileDetails?.roles?.map((data) => {
                    return <Box className="field-value text-value-grey weight-700">{data.role} </Box>
                  })}


                {/* <Box>{(profileDetails.roles.length > 0 && profileDetails.roles[0].role) ? profileDetails.roles[0].role : null}</Box> */}


                <Box className="field-value">


                  {/* {(profileDetails && profileDetails.roles && profileDetails.roles.length > 0 && profileDetails.roles.map((Item, index) => {
                    return (
                      <span className="field-value text-value-grey weight-700">
                      {Item.role}{index < profileDetails.roles.length - 1 ? ', ' : null}
                    </span>
                    )
                  })
                  )} */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} className="location-details-col">
                <Box className="field-label text-black weight-700 mb-10">ADDRESS</Box>
                <Box className="field-value text-value-grey weight-700">{profileDetails.addressLine1}</Box>
              </Grid>
            </Grid>
          </Box>
        ) : <Box className="user-profile-container w-100 common-details-cont">
          <Grid container mb={3} className="location-details-row">
            No Data Found
          </Grid>
        </Box>
        }

      </Box>
    </Card>
  );
}

export default ProfileDetails;
