import React from 'react';
import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  TextField,
  Slide
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CreateCategoryModal = ({
  openAddCategory,
  handleCloseVariant,
  setcategoryName,
  categoryName,
  categoryDescription,
  setcategoryDescription,
  handleCreateCategory,
  createCategoryErrors,
  setCreateCategoryErrors
}) => {
  const handleClose = () => {
    setCreateCategoryErrors({
      categoryName: '',
      description: ''
    });
    handleCloseVariant();
  };
  
  const handleSubmit = () => {
    const error = !categoryName || !categoryDescription;
  
    setCreateCategoryErrors({
      categoryName: !categoryName ? "Category name is required." : "",
      description: !categoryDescription ? "Description is required." : ""
    });
  
    return error ? null : handleCreateCategory();
  };
  

  return (
    <Dialog
      open={openAddCategory}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()}
      aria-describedby="alert-dialog-slide-description"
      // sx={{ width: '350px' }}
    >
      <DialogTitle className="alert-title align-center">
        {'Add Category'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
        >
          <Box
            display="flex"
            justifyContent="center"
            direction="column"
            width={300}
            mt={1}
            mb={2}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="CATEGORY NAME"
                  name="categoryName"
                  className="location-field input-field-styling "
                  onChange={
                    (e) => {
                      setcategoryName(e.target.value);
                      setCreateCategoryErrors((prevErrors) => ({
                        ...prevErrors,
                        categoryName: ""
                      }));
                    }
                  }
                  required
                  value={categoryName}
                  variant="outlined"
                  helperText={createCategoryErrors?.categoryName}
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  sx={{ background: 'white' }}
                  fullWidth
                  multiline
                  id="categoryDescription"
                  name="CatDescription"
                  autocomplete="Category Description"
                  label="CATEGORY DESCRIPTION"
                  placeholder="Description . . . "
                  required
                  value={categoryDescription}
                  variant="outlined"
                  className="location-description field-top-margin-edit-location"
                  helperText={createCategoryErrors?.description}
                  // error={Boolean(productDescriptionError)}
                  // helperText={productDescriptionError}
                  onChange={
                    (e) =>  { 
                      setcategoryDescription(e.target.value)
                      setCreateCategoryErrors((prevErrors) => ({
                        ...prevErrors,
                        description: ""
                      }));
                    }
                  }
                  InputLabelProps={{
                    className: 'label-style-create-franchisee'
                  }}
                  InputProps={{ rows: 4 }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 text-black no-hover"
          onClick={() => handleClose()}
        >
          {'Close'}
        </Button>
        <Divider className="vertical-divider" />
        <Button
          className="font-17 no-hover"
          onClick={() => handleSubmit()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCategoryModal;
