import React, { useState } from 'react';
import {
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Paper,
  Box
} from '@mui/material';
// import "../../content/applications/LocationManagement/Location.css";

import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

export const Filter = ({
  setFilters,
  filters,
  filterOptions,
  clearFilters
}) => {
  // console.log(filterOptions, 'filterOptions');
  const handleFilterChange = (type, value) => {
    setFilters(() => ({ [type]: value }));
  };
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    type: false,
    billingCycle: false,
    category: false,
    sellOnline: false
  });

  // const changeType = (value) => {
  //   setFilters((prevState) => ({ ...prevState, status: value }));
  // };

  const ChangeValue = (value, type) => {

    setFilters({ ...filters, [type]: value });
  };

  const handleOpenFilter = (field) => {
    // console.log('field-->',field);
    setOpenFilter((prevState) => ({
      // type: field === 'type' ? !prevState.type : false,
      typeOfService: field === 'typeOfService' ? !prevState.typeOfService : false,
      billingCycle: field === 'billingCycle' ? !prevState.billingCycle : false,
      categoryName: field === 'categoryName' ? !prevState.categoryName : false,
      sellOnline: field === 'sellOnline' ? !prevState.sellOnline : false
    }));
  };

  return (
    <>
      <Card className="filter-content">
        {filterOptions &&
          filterOptions?.map((item, index) => (
            <>
              <Typography className="filter-label" pt={2} pb={3}>
                {item.label}
              </Typography>
              <FormControl
                pt={2}
                fullWidth
                disabled={!item?.enable}
                variant="outlined"
                sx={{
                  width: '100%',
                  marginBottom: '10px'
                }}
                key={index}
              >
                <InputLabel> {item.label}</InputLabel>
                <Select
                  value={filters[item.value] ?? ''}
                  name={item.value}
                  disabled={!item.enable}
                  onChange={(event) =>
                    handleFilterChange(event.target.name, event.target.value)
                  }
                  label="Active"
                  className="status-field field-label-css"
                >
                  {item.options.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Divider />
            </>
          ))}

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {filterOptions &&
          filterOptions[0]?.subFilters?.map((item) => (
            <>
              <Grid
                item
                xs={12}
                onClick={() => handleOpenFilter(item.value)}
                pt={0.5}
              >
                <Paper>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    className="pointer"
                  >
                    <Grid item>
                      <KeyboardArrowRightRoundedIcon
                        className={clsx(
                          !openFilter[item.value] && classes.closeY,
                          openFilter[item.value] && item.enable && classes.openY
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>{item.label}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {openFilter[item.value] && item.enable && (
                <Box marginTop={1}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{item.label}</InputLabel>
                    {item.label !== 'Category' ? (
                      <Select
                        onChange={(event) =>
                          ChangeValue(event.target.value, item.value)
                        }
                        value={filters[item.value] ?? ''}
                        sx={{
                          width: '100%'
                        }}
                        label={item.label}
                        className="status-field field-label-css"
                      >
                        {/* <MenuItem key={0} id={0} value="">
                          None
                        </MenuItem> */}
                        {item.options.map((item, index) => (
                          <MenuItem key={index} id={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Select
                       // multiple
                        onChange={(event) =>
                          ChangeValue(event.target.value, item.value)
                        }
                        value={filters[item.value] ?? ''}
                        sx={{
                          width: '100%'
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto',
                            },
                          },
                        }}
                        label={item.label}
                        className="status-field field-label-css"
                      >
                        {/* <MenuItem key={0} id={0} value="">
                          None
                        </MenuItem> */}
                        {item.options.map((item, index) => (
                          <MenuItem key={index} id={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>  
                    )}
                  </FormControl>
                </Box>
              )}
            </>
          ))}
      </Card>
    </>
  );
};
