import { Autocomplete, Avatar, TextField } from '@mui/material';
import React, {useEffect} from 'react';
import { useState } from 'react';
import { ApiService } from 'src/services/ApiService';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { Box } from '@mui/system';
import { getLocalData } from 'src/utils';

const UserSearch = ({ handleSelectUser }) => {
  let placeHolderImg = '/static/images/logo/cardboardicon.png';
  const BASE_URL = process.env.REACT_APP_CHAT_API_END_POINT;

  const locationId = getLocalData('locationId');
  const [searchValue, setSearchValue] = useState('');
  const [clientLoading, setClientLoading] = useState(false);
  const [existingClientList, setExistingClientList] = useState([]);

  // states for error handling
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
      }
    },
    clearIndicator: {
      color: '#003087',
      backgroundColor: '#F8FBFF !important',
      '&:hover': {
        backgroundColor: '#F8FBFF !important'
      }
    }
  });
  const autoCompleteClasses = useAutocompleteStyles();

  /* Function to get the existing client */
  const getClientList = async (searchText = '') => {
    let encodedLocationId = encodeURIComponent(locationId);
    setClientLoading(true);
    try {
      const response = await ApiService.get(
        `${BASE_URL}getClientsBySearch?searchText=${searchText}&locationId=${encodedLocationId}`
      );
      if (response?.statusCode === 200) {
        setExistingClientList(response?.data?.Items ?? []);
        setClientLoading(false);
      } else {
        setClientLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console?.error(error);
      setClientLoading(false);
      setShowErrorMessage(true);
      setErrorMessage(error?.message || 'Something went wrong');
    }
  };

  // Memoized and debounced function
  const debouncedGetClientList = useCallback(debounce(getClientList, 1000), []);

  useEffect(() => {
    // Fetch all clients initially
    getClientList();
  }, []);

  const handleSearchClient = (event) => {
    setSearchValue(event.target.value);
    debouncedGetClientList(event.target.value);
  };
  // method to change the existing client
  const changeExistingClient = (selectedclient) => {
    if (selectedclient) {
      handleSelectUser(selectedclient);
    }
  };

  return (
    <Box className="input-list-item">
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      <Autocomplete
        disablePortal
        classes={autoCompleteClasses}
        onChange={(event, selectedOptions) => {
          changeExistingClient(selectedOptions);
        }}
        className="status-field field-label-css custom-field chat_searchbox"
        options={existingClientList}
        noOptionsText={clientLoading ? 'Loading...' : 'No client found'}
        getOptionLabel={(option) => {
          return (
            option &&
            option?.firstName &&
            option?.lastName &&
            option.firstName + ' ' + option.lastName
          );
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.sortKey}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                <Avatar
                  alt={option?.firstName || placeHolderImg}
                  src={option?.profileImage}
                  sx={{ width: '33px', height: '33px' }}
                />
                <h4>
                  {option?.firstName} {option?.lastName}
                </h4>
              </div>
            </li>
          );
        }}
        sx={{
          width: '100%',
          borderRadius: '15px !important',
          padding: '3px !important',
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#EBEAEA',
            // Ensure the border is consistent
            '& fieldset': {
              border: '1px solid #EBEAEA !important',
              borderRadius: '43px !important'
            },
            '&:hover fieldset': {
              border: '1px solid #EBEAEA !important', // Same border on hover
              borderRadius: '43px !important'
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #EBEAEA !important', // Same border on focus
              borderRadius: '43px !important'
            },
            '& .MuiOutlinedInput-input::placeholder': {
              color: 'black', // Style for placeholder
              opacity: 1
            }
          }
          // ... other styles
        }}
        renderInput={(params) => (
          <TextField
            // label={params?.label || 'SEARCH CLIENT'}
            {...params}
            placeholder="Search"
            InputProps={{
              ...params.InputProps,
              endAdornment: <SearchIcon />
            }}
            value={searchValue}
            autoComplete="off"
            onChange={handleSearchClient}
            InputLabelProps={{ className: 'home_mt-4' }}
          />
        )}
      />
    </Box>
  );
};

export default UserSearch;
