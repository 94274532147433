import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddCampaign() {
  const [campaignName, setCampaignName] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailHtmlBody, setEmailHtmlBody] = useState('');
  const [openDialog, setopenDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const options = ['Send', 'Schedule'];
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [data, setData] = useState([]);
  const [segment, setSegment] = useState('');

  const Editor = {};
  Editor.modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  Editor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'HTML'
  ]

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // const recipientEmails = ['vijendra.verma@accelance.io','sourabh.bhaisare@accelance.io']
      const params = {
        WriteCampaignRequest: {
          Name: campaignName,
          SegmentId: segment,
          MessageConfiguration: {
            EmailMessage: {
              FromAddress: `${process.env.REACT_APP_NOTIFYFROMEMAIL}`,
              HtmlBody: emailHtmlBody,
              Title: emailSubject
            }
          },
          Schedule: {
            StartTime: new Date().toISOString(),
            Frequency: 'ONCE'
          }
        }
      };

      addCampaign(params);
      // alert('Campaign created successfully!');
      setCampaignName('');
      setEmailSubject('');
      setEmailHtmlBody('');
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  };

  const getSegments = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllSegments`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        let Item = data.SegmentsResponse.Item;
        setData(Item);
      });
  };

  const getEmailTemplate = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}pinpoint/getEmailTemplate`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setEmailHtmlBody(data.HtmlPart);
        setEmailSubject(data.Subject);
      });
  };

  const addCampaign = async (params) => {
    fetch(`${process.env.REACT_APP_API_END_POINT}pinpoint/addCampaign`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setopenDialog(true);
        getEmailTemplate();
      });
  };

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    console.log('dadadda', index);
    setSelectedIndex(index);
    setOpen(false);
    if (index === 0) handleSubmit(event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setopenDialog(false);
  };

  const handleSegmentChange = (e) => {
    // console.log(segment,e.target.value);
    if (e && e.target && e.target.value) {
      setSegment(e.target.value);
    }
  };

  useEffect(() => {
    // Fetch data from Pipoint API
    getEmailTemplate();
    getSegments();
  }, []);

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => handleClose("Cancel")}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle className="alert-title align-center">{'Congratulations!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className="alert-decription align-center">
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">Campaign has been sent successfully!</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Container maxWidth="100%" className='main-container'>
        <Box className="Main-container common-form-container">
          {/* FOrm Starts */}
          <form onSubmit={handleSubmit}>
            <Grid mt={1}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1 className="title mb-0 mt-10 primary-color">Add Campaign</h1>
                  </Box>
                </Grid>
                <Grid item>
                  {/* 
                                           <Button variant="contained" type="submit" sx={{
                                                marginTop: '20px'
                                            }}>
                                                <Typography className="next-button-text">Save</Typography>
                                            </Button>                                                                                       
                                           */}
                  <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">

                    <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                    <Button
                      size="small"
                      aria-controls={open ? 'split-button-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    sx={{
                      zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {options.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  disabled={index === 2}
                                  selected={index === selectedIndex}
                                  onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} mt={1}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="locationName"
                  name="locationName"
                  label="CAMPAIGN NAME"
                  type="text"
                  className='input-field-styling'
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  InputLabelProps={{
                    className: 'form-field-label '
                  }}
                  InputProps={{}}
                />
              </Grid>
              <Grid item xs={6}>
              <FormControl                  
                  fullWidth
                  className="select-drop-down-label-styling required-field"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="input-field-dropdown-styling"
                  >
                    SEGMENTS
                  </InputLabel>
                  <Select
                  fullWidth
                  xs={{ height: 70 }}
                  labelId="demo-simple-select-helper-label"
                  className="input-field-styling"
                  id="designationId"
                  label="Segments"
                  name="Segment"
                  value={segment}
                  onChange={handleSegmentChange}
                >
                  {data.map((r, i) => (
                    <MenuItem
                      key={i}
                      value={r?.Id}
                    >
                      {r.Name}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>           
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} mt={3} >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="unit"
                  name="unit"
                  label="EMAIL SUBJECT"
                  type="text"
                  className="input-field-styling"
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                  InputLabelProps={{
                    className: 'form-field-label '
                  }}
                  InputProps={{}}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} mt={3} pl={2}>
              <ReactQuill
                modules={Editor.modules}
                formats={Editor.formats}
                value={emailHtmlBody}
                onChange={setEmailHtmlBody}
              />
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
}

export default AddCampaign;
