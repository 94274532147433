import { Box, Grid } from '@mui/material';

const LocationDetailRow = ({ label, value }) => (
  <>
    <Grid item xs={12} sm={2} className="location-details-col">
      <Box className="location-details-label">{label}</Box>
    </Grid>
    <Grid item xs={12} sm={4} className="location-details-col">
      <Box className="location-details-value">{value}</Box>
    </Grid>
  </>
);

export default LocationDetailRow;
