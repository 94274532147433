import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  Card,
  Grid,
  Paper,
  Autocomplete,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';

// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const Filter = ({
  setFilters,
  filters,
  // getFranchiseeList,
  // getLocationList,
  getStates
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const classes = useStyles();
  const autoCompleteClasses = useAutocompleteStyles();

  const [stateOpen, setStateOpen] = useState(false);
  // const [franchiseeOpen, setFranchiseeOpen] = useState(false);
  // const [locationOpen, setLocationOpen] = useState(false);
  // const [locationName, setLocationName] = useState('');
  // const [franchiseeName, setFranchiseeName] = useState('');

  // console.log(getFranchiseeList);
  const handleActiveChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, isActive: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };
  
  const changeState = (Selectedstate) => {
    setFilters((prevState) => ({ ...prevState, state: Selectedstate }));
  };

  // const handlFranchiseeChange = (event) => {
  //   // setFranchiseeName(event.target.name);
  //   // filters.franchiseeName = event.target.name;
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     franchiseeId: event.target.value
  //   }));
  // };

  // const handleLocationChange = (event) => {
  //   // setLocationName(event.target.name);
  //   // filters.locationName = event.target.name;
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     locationId: event.target.value
  //   }));
  // };

  
  const handleStateClick = () => {
    setStateOpen(!stateOpen);
    // setFranchiseeOpen(false);
    // setLocationOpen(false);
  };

  // const handleFranchiseeClick = () => {
  //   setFranchiseeOpen(!franchiseeOpen);
  //   setLocationOpen(false);
  //   setStateOpen(false);
  // };

  // const handleLocationClick = () => {
  //   setLocationOpen(!locationOpen);
  //   // setFranchiseeOpen(false);
  //   setStateOpen(false);
  // };

  const handleClearFilter = () => {
    // setFranchiseeName('');
    // setLocationName('');
    setFilters({
      // isActive: filters.isActive,
      // status: filters.status,
      status: 1,
      state: null,
      // franchiseeId: null,
      // locationId: null
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Status</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={-1}>All</MenuItem>
            {/* <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text cursor-pointer"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        
        <Grid item onClick={handleStateClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !stateOpen && classes.closeY,
                    stateOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>State</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {stateOpen && (
          <Box marginTop={1}>
            <Autocomplete
              disablePortal
              value={filters.state}
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                changeState(newValue);
              }}
              className="status-field field-label-css"
              options={getStates.map((option) => option.state)}
              sx={{
                width: '100%'
              }}
              renderInput={(params) => (
                <TextField {...params} label={params?.label || 'States'} />
              )}
            />
          </Box>
        )}

         {/* <Grid item onClick={handleFranchiseeClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !franchiseeOpen && classes.closeY,
                    franchiseeOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Franchisee</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {franchiseeOpen && (
          <Box marginTop={1}>
             <Autocomplete
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                if (newValue && newValue.franchiseeId) {
                  handlFranchiseeChange({
                    target: {
                      name: 'franchiseeId',
                      value: newValue.franchiseeId ? newValue.franchiseeId : ''
                    }
                  });
                } else {
                  handlFranchiseeChange({
                    target: { name: 'franchiseeId', value: '' }
                  });
                }
              }}
              className="status-field field-label-css status-field-lbl"
              options={getFranchiseeList}
              getOptionLabel={(option) => option.franchiseeName}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search Franchisee" />
              )}
            /> 
              <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                FRANCHISEE
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="locationId"
                onChange={handlFranchiseeChange}
                value={filters.franchiseeId}
                label="LOCATION"
                className="input-field-styling"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                {getFranchiseeList.map((r, i) => (
                  <MenuItem key={i} value={r.franchiseeId}>
                    {r.franchiseeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )} 

        <Grid xs={12} onClick={handleLocationClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !locationOpen && classes.closeY,
                    locationOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Location</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {locationOpen && (
          <Box marginTop={1}>
            <Autocomplete
              classes={autoCompleteClasses}
              value={getLocationName()}
              onChange={(event, newValue) => {
                if (newValue && newValue.locationId) {
                  handleLocationChange({
                    target: {
                      name: 'locationId',
                      value: newValue.locationId ? newValue.locationId : ''
                    }
                  });
                } else {
                  handleLocationChange({
                    target: { name: 'locationId', value: '' }
                  });
                }
              }}
           
              className="status-field field-label-css status-field-lbl"

              options={getLocationList}
              getOptionLabel={(option) => option.locationName}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search Location" />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                LOCATION
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="locationId"
                onChange={handleLocationChange}
                value={filters.locationId}
                label="LOCATION"
                className="input-field-styling"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                {getLocationList.map((r, i) => (
                  <MenuItem key={i} value={r.locationId}>
                    {r.locationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )} */}
      </Card>
    </>
  );
};

export default Filter;
