/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Box,
  Autocomplete,
  TextField
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { dateFormat, enterNumber, addTwoDecimalPlaces } from 'src/utils/index';
import { CustomDatePicker } from 'src/components/CustomDatePicker';

export const AdjustmentFilters = ({ setFilters, filters, data, filterData }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
   
  // Filter the data based on operatorName key
  const key = 'operatorName';
  const operatorData = filterData?.length > 0 ? [...new Map(filterData?.map(item =>
    [item[key], item])).values()] : [];

  const autoCompleteClasses = useAutocompleteStyles();
  const classes = useStyles();
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [priceValue, setPriceValue] = useState('');

  const [openFilter, setOpenFilter] = useState({
    numberOfItems: false,
    totalCost: false,
    adjustmentId: false,
    updatedBy: false,
    updatedAt: false
  });

  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      numberOfItems: field === 'numberOfItems' ? !prevState.numberOfItems : false,
      // totalCost: field === 'totalCost',
      adjustmentId: field === 'adjustmentId' ? !prevState.adjustmentId : false,
      updatedBy: field === 'updatedBy' ? !prevState.updatedBy : false,
      updatedAt: field === 'updatedAt' ? !prevState.updatedAt : false
    }));
  };

  const handleChangeFilter = (value, type) => {
    if (type === 'numberOfItems') {
      value = enterNumber(value);
    }

    // if(type === 'totalCost') {
    //   setPriceValue(enterNumber(value));
    //   // Method to pass value in two decimall always
    //   value  = addTwoDecimalPlaces(value);

    // } 
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const handleStartDateChange = (date) => {
    if (date === "01-01-1970") {
      return;
    }
    setstartDate(date);
    // let formattedStartDate = formatDate(date);
    let formattedStartDate = dateFormat(new Date(date));
    setFilters((prevState) => ({
      ...prevState,
      updateStartDate: formattedStartDate
    }));
  };
  const handleEndDateChange = (date) => {
    if (date === "01-01-1970") {
      return;
    }
    setEndDate(date);
    // let formattedEndDate = formatDate(date);
    let formattedEndDate = dateFormat(new Date(date));
    setFilters((prevState) => ({
      ...prevState,
      updateEndDate: formattedEndDate
    }));
  };

  useEffect(() => {
    console.log('filters', filters);
  }, [filters]);

  const clearFilters = () => {
    setFilters({
      numberOfItems: '',
      // totalCost: '',
      adjustmentId: '',
      // updatedBy: '',
      operatorName: '',
      updatedAt: '',
      updateEndDate: '',
      updateStartDate: ''
    });
    setstartDate('');
    setEndDate('');
    // setPriceValue('');

    setOpenFilter({
      numberOfItems: false,
      // totalCost: false,
      adjustmentId: false,
      updatedBy: false,
      updatedAt: false
    });
  };

  return (
    <>
      <Card className="filter-content custom-filters-form ">
        {/* <Typography className="filter-label" pt={2} pb={3}>
          Service Type
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Type</InputLabel>
          <Select
            value={filters.isActive ?? ''}
            onChange={(event) =>
              handleChangeFilter(event.target.value, 'isActive')
            }
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={null}>None</MenuItem>
            <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider /> */}

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('updatedBy')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.updatedBy && classes.closeY,
                    openFilter.updatedBy && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>User/ Employee</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.updatedBy && (
          <Box marginTop={1}>
            <Autocomplete
              classes={autoCompleteClasses}
              disablePortal
              id="combo-box-demo"
              className="status-field field-label-css"
              // options={[
              //   ...new Set(filterData.map((option) => option.updatedBy))
              // ]?.map((updatedBy) => ({ label: updatedBy, value: updatedBy }))}
              // options={[
              //   ...new Set(filterData && filterData.length > 0 && filterData.map((option) => option.operatorName))
              // ]?.map((operatorName) => ({ label: operatorName, value: operatorName }))}
              options={operatorData && operatorData}
              getOptionLabel={(option) => (option.operatorName)}
              sx={{ width: '100%', paddingBottom: '16px' }}
              renderInput={(params) => (
                <TextField {...params} label="Select Operator" />
              )}
              onChange={(event, newValue) =>
                // handleChangeFilter(target?.value, 'updatedBy')
                // console.log('newValue', newValue)
                handleChangeFilter(newValue?.operatorName, 'operatorName')
              }
            />
          </Box>
        )}

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('numberOfItems')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.numberOfItems && classes.closeY,
                    openFilter.numberOfItems && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Number of Products</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.numberOfItems && (
          <Box marginTop={1}>
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              className="status-field field-label-css"
              sx={{ width: '100%', paddingBottom: '16px' }}
              options={[
                ...new Set(data.map((option) => option.numberOfItems))
              ]?.map((numberOfItems) => ({
                label: numberOfItems,
                value: numberOfItems
              }))}
              renderInput={(params) => (
                <TextField {...params} label="No of Products" />
              )}
              onChange={(event, target) =>
                handleChangeFilter(target?.value, 'numberOfItems')
              }
            /> */}
            <TextField
              className="w-100"
              value={filters.numberOfItems ?? ''}
              onChange={(event, target) =>
                handleChangeFilter(event.target?.value, 'numberOfItems')
              }
              placeholder="Number of Products"
            />
          </Box>
        )}

        {/* <Grid
          item
          onClick={() => handleOpenFilter('totalCost')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.totalCost && classes.closeY,
                    openFilter.totalCost && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Price</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.totalCost && (
          <Box marginTop={1}>
        

            <TextField
              className="w-100"
              value={priceValue ?? ''}
              onChange={(event, target) =>
                handleChangeFilter(event.target?.value, 'totalCost')
              }
              placeholder="Enter price"
            />
          </Box>
        )} */}

        <Grid
          item
          onClick={() => handleOpenFilter('adjustmentId')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.adjustmentId && classes.closeY,
                    openFilter.adjustmentId && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Adjustment ID</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.adjustmentId && (
          <Box marginTop={1}>
            <Autocomplete
              classes={autoCompleteClasses}
              disablePortal
              id="combo-box-demo"
              className="status-field field-label-css"
              sx={{ width: '100%', paddingBottom: '16px' }}
              options={[
                ...new Set(filterData.map((option) => option.adjustmentId))
              ]?.map((adjustmentId) => ({
                label: adjustmentId,
                value: adjustmentId
              }))}
              renderInput={(params) => (
                <TextField {...params} label="Adjustment ID" />
              )}
              onChange={(event, target) =>
                handleChangeFilter(target?.value, 'adjustmentId')
              }
            />
          </Box>
        )}

        <Grid
          item
          onClick={() => handleOpenFilter('updatedAt')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.updatedAt && classes.closeY,
                    openFilter.updatedAt && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Date Range</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.updatedAt && (

          <>
            {/* <Box marginTop={1}>
              <TextField
                fullWidth
                name="start-date"
                autocomplete="start-date"
                label="Start Date"
                placeholder="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => handleStartDateChange(e.target.value)}
                className="input-field-styling required-fiel"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-labe',
                  shrink: 'true'
                }}
              />
            </Box>
            <Box marginTop={1}>
              <TextField
                fullWidth
                name="end-date"
                autocomplete="end-date"
                label="End Date"
                placeholder="End Date"
                type="date"
                value={endDate}
                // onChange={changeEndDate}
                onChange={(e) => handleEndDateChange(e.target.value)}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee',
                  shrink: 'true'
                }}
                InputProps={{ min: startDate }}
              />
            </Box> */}
            <Box marginTop={1}>
              <CustomDatePicker label="Start Date" onChange={handleStartDateChange}
                fieldValue={startDate}
              />
            </Box>
            <Box marginTop={1}>
              <CustomDatePicker
                label="End Date"
                onChange={handleEndDateChange}
                fieldValue={endDate}
                minDate={new Date(startDate)}
                disabled={Boolean(!startDate)}
              />
            </Box>
          </>
        )}
      </Card>
    </>
  );
};
