/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { getLocalData } from '../../../utils';
import { Box, Container, Grid, Slide } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { CustomButton, SearchInput } from 'src/components/Global';
// import {tableData} from './cohorts'
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import { ApiService } from '../../../services/ApiService';
import Loader from '../../../components/Loader';
import GlobalData from 'src/global';
import Header from 'src/components/Header';
import { CohortsDetail } from './CohortsDetail';
import CohortsFilter from './CohortsFilter';
import { CohortsTable } from './CohortsTable';
import { EditCohorts } from './EditCohorts';
import CreateCohorts from './CreateCohorts';

const Campaign = () => {
  const franchiseeId = getLocalData('franchiseeId');
  const title = GlobalData[0].title;
  const [showScreen, setShowScreen] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [isFilters, setIsFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rowData, setRowData] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  // check updated or saved
  const [getFranchisee, setFranchisee] = useState('');

  const [filters, setFilters] = useState({
    // isActive: null,
    status: null,
    createdBy: null,
    createdDate: null
  });
  // setServicesData(tableData)
  useEffect(() => {
    if (showScreen === 1) {
      setSearchQuery('');
    }
  }, [showScreen]);
  const getFranchiseeName = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}franchisees/getFranchiseeDetail?franchiseeId=${encodedFranchiseeId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      setFranchisee(Item.franchiseeName);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFranchiseeName();
  }, []);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  //   ===============call api here=============
  const getCohorts = async () => {
    setIsLoading(true);
    try {
      let encodedFranchiseeId = encodeURIComponent(franchiseeId);
      let filtersParams = JSON.parse(JSON.stringify(filters));
      Object.keys(filtersParams).forEach((key) => {
        if (
          filtersParams[key] === null ||
          filtersParams[key] === '' ||
          filtersParams[key] === 'undefined' ||
          filtersParams[key] === undefined
        ) {
          delete filtersParams[key];
        }
      });

      let queryString = Object.keys(filtersParams)
        .map((key) => key + '=' + filtersParams[key])
        .join('&');

      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAllFranchiseeCohorts?${queryString}&franchiseeId=${encodedFranchiseeId}`
      );

      if (response.statusCode && !response.error) {
        // Set the list of location
        setIsLoading(false);
        setRowData(response.data.Items);
      } else {
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, try later.');
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, try later.');
    }
  };

  // setFranchiseeId(locationList.partitionKey);
  // gets call the method to get the data
  useEffect(() => {
    // Not call the get location method
    getCohorts();
  }, [JSON.stringify(filters)]);

  const handleButtonClick = () => {
    setShowScreen(2);
    setIsDuplicate(false);
  };
  const buttons = [
    {
      label: 'Create Cohort',
      onClick: handleButtonClick,
      theam: true
    }
  ];
  return (
    <>
      <Helmet>
        <title>Cohorts | {title}</title>
      </Helmet>
      {/* <Loader IsLoading={loading} /> */}
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 1:
              return (
                <Box justifyContent="center" direction="column">
                  {/* AddCampaign component */}
                  <CohortsDetail
                    setShowScreen={setShowScreen}
                    selectedRow={selectedRow}
                    rowData={rowData}
                    setIsDuplicate={setIsDuplicate}
                    getCohorts={getCohorts}
                  />
                </Box>
              );
            case 2:
              return (
                <Box justifyContent="center" direction="column">
                  {/* AddCampaign component */}
                  <CreateCohorts
                    setShowScreen={setShowScreen}
                    selectedRow={selectedRow}
                    getFranchisee={getFranchisee}
                    getCohorts={getCohorts}
                    isDuplicate={isDuplicate}
                  />
                </Box>
              );
            case 3:
              return (
                <Box justifyContent="center" direction="column">
                  {/* AddCampaign component */}
                  <EditCohorts
                    setShowScreen={setShowScreen}
                    selectedRow={selectedRow}
                    getFranchisee={getFranchisee}
                    getCohorts={getCohorts}
                  />
                </Box>
              );
            default:
              return (
                <>
                  <Loader IsLoading={isLoading} />
                  <Box justifyContent="center" direction="column">
                    <Header title="Cohorts" buttons={buttons} />
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                      spacing={3}
                    >
                      <Grid item width={'100%'}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-end"
                          >
                            <Grid item marginY="20px">
                              <CustomButton
                                onClick={() => setIsFilters(!isFilters)}
                                variant={isFilters ? 'contained' : 'outlined'}
                              >
                                Filters
                                <KeyboardArrowDownRoundedIcon
                                  className={clsx(
                                    !isFilters && classes.closeY,
                                    isFilters && classes.openY
                                  )}
                                />
                              </CustomButton>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              marginY={{ sm: '20px' }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                              }}
                            >
                              <SearchInput
                                value={searchQuery}
                                setSearchQuery={setSearchQuery}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      {/* {loading ? (
      <CircularProgress sx={{ marginTop: '80px' }} />
    ) : ( */}
                      <>
                        {isFilters ? (
                          <Grid item width={isFilters ? 300 : 0}>
                            <CohortsFilter
                              filters={filters}
                              setFilters={setFilters}
                              tableData={rowData}
                              getFranchisee={getFranchisee}
                            />
                          </Grid>
                        ) : null}

                        <Grid
                          item
                          width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                        >
                          <CohortsTable
                            setIsLoading={setIsLoading}
                            setRowData={setRowData}
                            filters={filters}
                            setIsDuplicate={setIsDuplicate}
                            isLoading={isLoading}
                            rowData={rowData}
                            setSelectedRow={setSelectedRow}
                            selectedRow={selectedRow}
                            tableRows={rowData}
                            searchQuery={searchQuery}
                            setShowScreen={setShowScreen}
                            loading={tableLoading}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            selectedStatus={selectedStatus}
                            setSelectedStatus={setSelectedStatus}
                            btnLoading={btnLoading}
                          />
                        </Grid>
                      </>
                      {/* )} */}
                    </Grid>
                  </Box>
                </>
              );
          }
        })()}
      </Container>
    </>
  );
};

export default Campaign;
