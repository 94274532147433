/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const MembershipFilters = ({ data, setFilters, filters }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    // name: false,
    // activeMembers: false,
    AutoPay: false,
    billingCycle: false
  });

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      //  name: field === 'name' ? !prevState.name : false,
      //  activeMembers: field === 'activeMembers' ? !prevState.activeMembers : false,
      billingCycle: field === 'billingCycle' ? !prevState.billingCycle : false,
      AutoPay: field === 'AutoPay' ? !prevState.AutoPay : false
    }));
  };

  const handleChangeFilter = (value, type) => {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      status: null,
      //  name: null,
      //  activeMembers: null,
      AutoPay: null,
      billingCycle: null
    });

    setOpenFilter({
      // name: false,
      //  activeMembers: false,
      AutoPay: false,
      billingCycle: false
    });
  };

  return (
    <>
      <Card className="filter-content custom-filter-form ">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Type</InputLabel>
          <Select
            // value={filters.isActive ?? ''}
            // value={filters.status ?? ''}
            value={filters.status ?? 1} // Set the default value to 1 for "Active"
            onChange={(event) =>
              handleChangeFilter(event.target.value, 'status')
            }
            label="Type"
            className="status-field field-label-css"
          >
            <MenuItem key={1} id={1} value={-1}>
              All
            </MenuItem>
            {/* <MenuItem key={2} id={2} value={Boolean(true)}>Active</MenuItem>
            <MenuItem key={3} id={3} value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem key={2} id={2} value={1}>
              Active
            </MenuItem>
            <MenuItem key={3} id={3} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid
          xs={12}
          onClick={() => handleOpenFilter('name')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.name && classes.closeY,
                    openFilter.name && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Name</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.name && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Name</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'name')
                }
                value={filters.name}
                sx={{
                  width: '100%'
                }}
                label="Select Name"
                className="status-field field-label-css"
              >
                <MenuItem value={-1}>All</MenuItem>
                {data?.length > 0 &&
                  [...new Set(data.map((option) => option.name))]?.map(
                    (name) => (
                      <MenuItem id={name} value={name}>
                        {name}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Box>
        )} */}

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('billingCycle')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.billingCycle && classes.closeY,
                    openFilter.billingCycle && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Billing Cycle</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.billingCycle && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Billing Cycle</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'billingCycle')
                }
                value={filters.billingCycle}
                sx={{
                  width: '100%'
                }}
                label="Select Billing Cycle"
                className="status-field field-label-css"
              >
                <MenuItem value={-1}>All</MenuItem>
                <MenuItem value="every month">Per Month</MenuItem>
                <MenuItem value="every year">Per Year</MenuItem>
                {/* {data?.length > 0 &&
                  [...new Set(data.map((option) => option.billingCycle))]?.map(
                    (billingCycle) => (
                      <MenuItem id={billingCycle} value={billingCycle}>
                      {billingCycle === 1 ? '1 Year' :  (billingCycle === 0 ? '1 Year' : (billingCycle > 1 ? `${billingCycle} Months` : `${billingCycle} Month`))}
           
                      </MenuItem>
                    )
                  )} */}
              </Select>
            </FormControl>
          </Box>
        )}
        <Grid
          item
          onClick={() => handleOpenFilter('AutoPay')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.AutoPay && classes.closeY,
                    openFilter.AutoPay && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Auto Pay</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.AutoPay && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Auto Pay</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'autopay')
                }
                value={filters.AutoPay}
                sx={{
                  width: '100%'
                }}
                label="Select Auto Pay"
                className="status-field field-label-css"
              >
                <MenuItem value={-1}>All</MenuItem>
                <MenuItem value="true">Auto Pay</MenuItem>
                <MenuItem value="false">Not Auto Pay</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        {/* <Grid
          item
          onClick={() => handleOpenFilter('activeMembers')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.activeMembers && classes.closeY,
                    openFilter.activeMembers && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Active Members</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.activeMembers && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Active Members</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'activeMembers')
                }
                value={filters.activeMembers}
                sx={{
                  width: '100%'
                }}
                label="Select Active Members"
                className="status-field field-label-css"
              >
                <MenuItem value={-1}>All</MenuItem>
                {data?.length > 0 &&
                  [...new Set(data.map((option) => option.activeMembers))]?.map(
                    (activeMembers) => (
                      <MenuItem id={activeMembers} value={activeMembers}>
                        {activeMembers}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Box>
        )} */}
      </Card>
    </>
  );
};
