import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  // TextField,
  Card,
  Grid,
  Paper,
  // Autocomplete,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';

// Import Datepicker from MUI
// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
//  import LocalizationProvider from '@mui/lab/LocalizationProvider';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import { CustomDatePicker } from 'src/components/CustomDatePicker';
// import './Franchisee.css';

const Filter = ({ setFilters, filters, statesList }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors

  // const useAutocompleteStyles = makeStyles({
  //   inputRoot: {
  //     // borderRadius: 10, // or any other value you prefer
  //   },
  //   option: {
  //     '&:hover': {
  //       color: 'white'
  //       // or any other color you prefer
  //     }
  //   },
  //   clearIndicator: {
  //     color: '#003087', // or any other color you prefer
  //     backgroundColor: '#F8FBFF',
  //     '&:hover': {
  //       backgroundColor: '#F8FBFF'
  //     }
  //   }
  // });

  const classes = useStyles();
  // const autoCompleteClasses = useAutocompleteStyles();

  // const [teamOpen, setTeamOpen] = useState(false);
  // const [regionOpen, setRegionOpen] = useState(false);
  // const [showDatePicker, setShowDatePicker] = useState(false);
  // const [isCityOpen, setCity] = useState(false);
  // const [getCity, setgetCity] = React.useState('');
  // const [startDate, setstartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [openFilter, setOpenFilter] = useState({
    datefilter: false,
    city: false,
    state: false,
    mud: false
  });

  const handleActiveChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, isActive: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  // const handleCityChange = (value) => {
  //   setFilters((prevState) => ({ ...prevState, city: value }));
  // };

  const handleStateChange = (value) => {
    setFilters((prevState) => ({ ...prevState, state: value }));
  };
  const handleMudChange = (value) => {
    setFilters((prevState) => ({ ...prevState, mud: value }));
  };

  // const handlTeamChange = (value) => {
  //   setFilters((prevState) => ({ ...prevState, team: value }));
  // };

  // const handlRegionChange = (value) => {
  //   setFilters((prevState) => ({ ...prevState, region: value }));
  // };

  // const handleStartDateChange = (date) => {
  //   if (date === '01-01-1970') {
  //     date = null;
  //     return;
  //   }
  //   setstartDate(date);
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     startDate: date
  //   }));
  // };
  // const handleEndDateChange = (date) => {
  //   if (date === '01-01-1970') {
  //     date = null;
  //     return;
  //   }
  //   setEndDate(date);
  //   setFilters((prevState) => ({ ...prevState, endDate: date }));
  // };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      datefilter: field === 'datefilter' ? !prevState.datefilter : false,
      city: field === 'city' ? !prevState.city : false,
      state: field === 'state' ? !prevState.state : false,
      mud: field === 'mud' ? !prevState.mud : false
    }));
  };

  const handleClearFilter = () => {
    // setstartDate('');
    // setEndDate('');
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      startDate: null,
      city: '',
      endDate: null,
      state: [],
      mud: null
    });
    setOpenFilter({
      datefilter: false,
      city: false,
      state: false,
      mud: false
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel className="status-field-lbl"> Type</InputLabel>
          <Select
            // value={filters.isActive ?? ''}
            value={filters.status ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={-1}>All</MenuItem>
            {/* <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid onClick={() => handleOpenFilter('city')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.city && classes.closeY,
                    openFilter.city && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>City</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
        {/* {openFilter.city === true && getCities && (
          <Box marginTop={1}>
            <Autocomplete
              disablePortal
              classes={autoCompleteClasses}
              value={filters.city}
              onChange={(event, newValue) => {
                // setgetCity(newValue);
                handleCityChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                handleCityChange(newInputValue);
              }}
              className="status-field field-label-css"
              options={getCities.map((option) => option.city)}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search City"
                />
              )}
            />

            <TextField
              fullWidth
              name="city"
              label="CITY"
              placeholder="CITY"
              value={filters.city}
              onChange={(e) => handleCityChange(e.target.value)}
              className="status-field field-label-css"
              InputLabelProps={{
                className: 'label-style-create-franchisee',
                shrink: true
              }}
              InputProps={{}}
            />
          </Box>
        )} */}
        {/* <Grid item onClick={handleTeamClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !teamOpen && classes.closeY,
                    teamOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Team</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {teamOpen && (
          <Box marginTop={1}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                width: '100%'
              }}
            >
              <InputLabel className="status-field-lbl">Team</InputLabel>
              <Select
                onChange={(event) => handlTeamChange(event.target.value)}
                value={filters.team ?? ''}
                label="Team"
                className="status-field field-label-css"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Team1">Team1</MenuItem>
                <MenuItem value="Team2">Team2</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <Grid xs={12} onClick={handleRegionClick} pt={0.5}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer">
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !regionOpen && classes.closeY,
                    regionOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Region</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {regionOpen && (
          <Box marginTop={1}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                width: '100%'
              }}
            >
              <InputLabel className="status-field-lbl">Region</InputLabel>
              <Select
                onChange={(event) => handlRegionChange(event.target.value)}
                value={filters.region ?? ''}
                label="Region"
                className="status-field field-label-css"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="Northeast">Northeast</MenuItem>
                <MenuItem value="Southwest">Southwest</MenuItem>
                <MenuItem value="West">West</MenuItem>
                <MenuItem value="Midwest">Midwest</MenuItem>
                <MenuItem value="Southeast">Southeast</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )} */}

        {/* <Grid xs={12} onClick={() => handleOpenFilter('datefilter')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.datefilter && classes.closeY,
                    openFilter.datefilter && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Opening Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}

        {/* {openFilter.datefilter && ( */}
        {/* <> */}
        {/* <Box marginTop={1}>
              <TextField
                fullWidth
                name="start-date"
                autocomplete="start-date"
                label="Start Date"
                placeholder="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => handleStartDateChange(e.target.value)}
                className="input-field-styling required-fiel"
                InputLabelProps={{
                  className: 'label-style-create-franchisee required-labe',
                  shrink: 'true'
                }}
              />
            </Box>
            <Box marginTop={1}>
              
              <TextField
                fullWidth
                name="end-date"
                autocomplete="end-date"
                label="End Date"
                placeholder="End Date"
                type="date"
                value={endDate}
                onChange={(e) => handleEndDateChange(e.target.value)}
                className="input-field-styling"
                InputLabelProps={{
                  className: 'label-style-create-franchisee',
                  shrink: 'true'
                }}
                InputProps={{ min: startDate }}
              />
            </Box> */}
        {/* <Box marginTop={1}>
              <CustomDatePicker
                label="Start Date"
                onChange={handleStartDateChange}
                fieldValue={startDate}
              />
            </Box> */}
        {/* <Box marginTop={1}>
              <CustomDatePicker
                label="End Date"
                onChange={handleEndDateChange}
                minDate={new Date(startDate)}
                fieldValue={endDate}
                disabled={Boolean(!startDate)}
              />
            </Box> */}
        {/* </> */}
        {/* )} */}

        <Grid
          item
          onClick={() => handleOpenFilter('state')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.state && classes.closeY,
                    openFilter.state && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>State</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.state && (
          <Box marginTop={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                State
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="state"
                onChange={(event) => handleStateChange(event.target.value)}
                value={filters.state}
                label="STATE"
                className="input-field-styling"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                {statesList.map((r, i) => (
                  <MenuItem key={i} value={r.state}>
                    {r.state}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Grid
          item
          onClick={() => handleOpenFilter('mud')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.mud && classes.closeY,
                    openFilter.mud && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>MUD</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.mud && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select MUD</InputLabel>
              <Select
                onChange={(event) => handleMudChange(event.target.value)}
                value={filters.cost}
                sx={{
                  width: '100%'
                }}
                label="Select Level"
                className="status-field field-label-css"
              >
                <MenuItem value={-1}>Show All</MenuItem>
                <MenuItem value="true">MUD's Only</MenuItem>
                <MenuItem value="false">Non-MUD</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};

export default Filter;
