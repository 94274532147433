/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';

export const DynamicTextField = ({
  id,
  type,
  value,
  defaultValue,
  onChange,
  name,
  label,
  placeholder,
  helperText,
  error,
  variant,
  required,
  disabled,
  readOnly,
  multiline,
  rows,
  shrink,
  maxRows,
  min,
  max,
  maxLength,
  minLength,
  fullWidth,
  autoComplete,
  className,
  InputLabelClass,
  InputProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleInput = (event) => {
    let value = event.target.value;
    if (onChange) {
      if (maxLength && value?.length <= maxLength) {
        onChange(name, value);
      } else if (max && value?.length <= max) {
        onChange(name, value);
      } else if (!maxLength && !max) {
        onChange(name, value);
      }
    }
  };

  return (
    <>
      <TextField
        id={id}
        name={name}
        fullWidth={fullWidth}
        type={type}
        label={label}
        autoComplete={autoComplete}
        placeholder={placeholder}
        variant={variant}
        defaultValue={defaultValue}
        value={value}
        onChange={handleInput}
        required={required}
        disabled={disabled}
        helperText={helperText}
        error={error}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        className={`${error && 'helper-error'} ${className}`}
        InputLabelProps={{
          shrink: shrink,
          className: `${InputLabelClass} label-style-create-franchisee`
        }}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
          max: max,
          min: min,
          readOnly: readOnly,
          rows: rows
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...InputProps,
          style: { color: 'black' } // This sets the text color to black
        }}
      />
    </>
  );
};
