
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Typography,
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Button,
  Slide,
  Select,
  MenuItem
} from '@mui/material';
import { CustomSelect } from './CustomSelect';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function CalenderDialogs(props) {
  const {
    dialog,
    setDialog,
    onClose,
    notify,
    setNotify,
    deleteType,
    setDeleteType,
    editType,
    setEditType,
    newSchedule,
    setNewSchedule,
    alignment

  } = props;

  const handleDialog = (type) => {
    onClose(type);
  };
  return (
    <Dialog
      open={dialog.state}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleDialog('cancel')}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ m: 0, p: 1 }} />

      <DialogContent
        sx={{
          minWidth: dialog.outTime ? '315px' : '400px',
          paddingTop: !dialog.outTime && '16px !important',
          paddingX: !dialog.outTime && '30px !important'
        }}
      >
        <Grid container direction="column" alignItems="center">
          {!dialog.outTime && (
            <Grid
              item
              sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: '17px',
                  fontWeight: '600',
                  color: '#000',
                  letterSpacing: '0.05px'
                }}
              >
                {dialog.type && ' What do you want to schedule?'}
                {dialog.delete && 'Delete Recurring Schedule?'}
                {dialog.edit && 'Edit Recurring Schedule?'}
                {dialog.notify && 'Notify?'}
              </Typography>
            </Grid>
          )}

          <Grid
            item
            sx={{
              paddingTop: dialog.type ? '25px !important' : '4px !important',
              paddingBottom: !dialog.outTime && '8px !important',
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {dialog.type && (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 32,
                      color: '#003087'
                    },
                    '& .MuiFormControlLabel-label': {
                      color: '#000000',
                      letterSpacing: '0.3px',
                      fontWeight: 600
                    },
                    '& .MuiFormControlLabel-root': {
                      marginRight: '40px'
                    }
                  }}
                >
                  <FormControlLabel
                    value="appointment"
                    control={<Radio />}
                    label="Appointment"
                    checked={newSchedule.type == 'appointment'}
                    onChange={() =>
                      setNewSchedule({ ...newSchedule, type: 'appointment' })
                    }
                  />
                  <FormControlLabel
                    value="enrollment"
                    control={<Radio />}
                    label="Enrollment"
                    checked={newSchedule.type == 'enrollment'}
                    onChange={() =>
                      setNewSchedule({ ...newSchedule, type: 'enrollment' })
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}

            {dialog.delete && (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 30,
                      color: '#003087'
                    },
                    '& .MuiFormControlLabel-label': {
                      color: '#000000',
                      letterSpacing: '0.3px',
                      fontWeight: 500
                    },
                    '& .MuiFormControlLabel-root': {
                      marginRight: '40px'
                    }
                  }}
                >
                  <FormControlLabel
                    value="occurrence"
                    control={<Radio />}
                    label="This Occurrence"
                    checked={deleteType == 'occurrence'}
                    onChange={() => setDeleteType('occurrence')}
                  />
                  <FormControlLabel
                    value="series"
                    control={<Radio />}
                    label="All Occurrences"
                    checked={deleteType == 'series'}
                    onChange={() => setDeleteType('series')}
                  />
                  {/* <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                    checked={deleteType == 'custom'}
                    onChange={() => setDeleteType('custom')}
                  /> */}
                </RadioGroup>
              </FormControl>
            )}

            {dialog.edit && (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 30,
                      color: '#003087'
                    },
                    '& .MuiFormControlLabel-label': {
                      color: '#000000',
                      letterSpacing: '0.3px',
                      fontWeight: 500
                    },
                    '& .MuiFormControlLabel-root': {
                      marginRight: '40px'
                    }
                  }}
                >
                  <FormControlLabel
                    value="occurrence"
                    control={<Radio />}
                    label="This Occurrence"
                    checked={editType == 'occurrence'}
                    onChange={() => setEditType('occurrence')}
                  />
                  <FormControlLabel
                    value="series"
                    control={<Radio />}
                    label="All Occurrences"
                    checked={editType == 'series'}
                    onChange={() => setEditType('series')}
                  />
                  {/* <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                    checked={editType == 'custom'}
                    onChange={() => setEditType('custom')}
                  /> */}
                </RadioGroup>
              </FormControl>
            )}
            {dialog.outTime && alignment === 'week' && (
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  direction="column"
                  sx={{ maxWidth: '290px' }}
                >
                  <Typography className="title-text-sub">
                  You are attempting to book a time slot outside of our operating hours.
                   Are you sure you want to proceed with this action?
                  </Typography>
                </Box>
              </DialogContentText>
            )}
            {dialog.outTime && alignment === 'day' && (
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  direction="column"
                  sx={{ maxWidth: '290px' }}
                >
                  <Typography className="title-text-sub">
                    You are trying to book a time outside of staff's
                    availability. Are you sure you want to proceed with this
                    action?
                  </Typography>
                </Box>
              </DialogContentText>
            )}

            {dialog.notify && (
              <Box sx={{ marginTop: '16px', width: '100%' }}>
                <CustomSelect
                  fullWidth
                  name="value"
                  value={notify.value}
                  defaultValue={'client'}
                  onChange={(event, selected) =>
                    setNotify({ ...notify, value: selected })
                  }
                  options={[
                    { label: "NOTIFY TRAINER ONLY", value: "trainer" },
                    { label: "NOTIFY ALL SCHEDULED CLIENT", value: "client" },
                    { label: "NOTIFY ALL", value: "all" },
                  ]}
                />
              </Box>
            )}

            {/* for notify dialog */}
            {/* <Select
          value={10}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          className=" scheduleFilter"
        >
          <MenuItem value={10}>NOTIFY ALL SCHEDULED CLIENT</MenuItem>
         
        </Select> */}

          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      {dialog.type && (
        <DialogActions className="alert-button-control">
          <Button
            className="font-15 weight-400 cancel-btn no-hover"
            onClick={() => handleDialog('cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-15 weight-400 no-hover"
            onClick={() => handleDialog('type')}
          >
            Continue
          </Button>
        </DialogActions>
      )}

      {dialog.delete && (
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 weight-400 no-hover cancel-btn"
            onClick={() => handleDialog('cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 weight-400 no-hover"
            onClick={() =>
              deleteType == 'custom'
                ? handleDialog('customDelete')
                : setDialog({
                  ...dialog,
                  delete: false,
                  isDelete: true,
                  notify: true
                })
            }
          >
            Continue
          </Button>
        </DialogActions>
      )}

      {dialog.edit && (
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 weight-400 no-hover cancel-btn"
            onClick={() => handleDialog('cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 weight-400 no-hover"
            onClick={() =>
              editType == 'custom'
                ? handleDialog('customEdit')
                : setDialog({ ...dialog, edit: false, notify: true, notifyUpdate: true })
            }
          >
            Continue
          </Button>
        </DialogActions>
      )}

      {dialog.outTime && (
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 weight-400 cancel-btn no-hover"
            onClick={() => handleDialog('cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 weight-400 no-hover"
            onClick={() => handleDialog('outTime')}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      )}

      {dialog.notify && (
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 weight-400 no-hover cancel-btn"
            onClick={() => handleDialog('skip')}
          >
            Skip
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 weight-400 no-hover"
            onClick={() => {
              if (dialog.edit) {
                setDialog({ ...dialog, notifyUpdate: true })
                handleDialog('notify');
              } else {
                handleDialog('notify')
              }
            }}
          >
            Notify
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}