import { Helmet } from 'react-helmet-async';
import React, { useEffect, useRef, useState } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import Footer from 'src/components/Footer';
import { Grid } from '@mui/material';
import StaffListAPI from './StaffListAPI';
// import { HeaderButtonContext } from 'src/contexts/HeaderButtonContext';
import {
  // Container,
  // Grid,
  // Card,
  // CardHeader,
  // CardContent,
  Slide,
  Box
  // Dialog,
  // DialogContent,
  // DialogContentText,
  // DialogTitle
  // Divider
} from '@mui/material';
import GlobalData from '../../../global';
import AddStaff from './AddStaff';
import Header from '../../../components/Header';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function StaffManagement() {
  // Get the title from global data
  const title = GlobalData[0].title;
  // const [open, setOpen] = React.useState(false);
  const [showScreen, setShowScreen] = useState(0);
  /* Set the header text */
  // const { setHeaderText } = useContext(HeaderContext);
  // const { showScreen, setShowScreen, setIsToggleComponent} = useContext(HeaderButtonContext);
  // setHeaderText('Staff Management');
  // useEffect(() => {
  //   setIsToggleComponent(false)
  // }, [])
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Staff Management';
  const childRef = useRef(null);

  useEffect(() => {
    //  getFranchise();
    // let headerelm = document.getElementsByClassName('add-btn-container')[0];
    // if (headerelm)
    //   headerelm.setAttribute('style', 'visibility: unset !important');
  }, []);

  // Method to open the delete dialog
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // Method to close the delete dialog
  // const handleClose = () => {
  //   // Now close the dialog
  //   setOpen(false);
  // };
  const handleButtonClick = () => {
    setShowScreen(1);
  };
  const buttons = [
    {
      label: 'Add new',
      onClick: handleButtonClick,
      theam: true
    }
  ];
  const closeAddClient = () => {
    childRef?.current?.refreshStaffData();
    // handleClose();
  };

  // Method to referesh the data after addign staff
  // const refreshData = () => {
  //   // Call refesh data method of child component
  //   //  setRefreshChildData(true);

  // };

  return (
    <>
      <Helmet>
        <title>Staff Management | {title}</title>
      </Helmet>
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <Box justifyContent="center" direction="column">
                <AddStaff
                  showScreen={showScreen}
                  setShowScreen={setShowScreen}
                  refreshData={closeAddClient}
                  Transition={Transition}
                />
              </Box>
            );
          default:
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item width={'100%'}>
                  <Box sx={{ paddingX: '30px' }}>
                    {showScreen === 0 && (
                      <Header title="Staff Management" buttons={buttons} />
                    )}
                  </Box>
                  {/* Now draw the Product View */}
                  {/* <ProductListAPI /> */}
                  <StaffListAPI
                    showScreen={showScreen}
                    setShowScreen={setShowScreen}
                    ref={childRef}
                  />
                </Grid>
              </Grid>
            );
        }
      })()}

      {/* <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="details-dialog"
          className="dialog-box dialog-box-right"
          maxWidth="lg"
        >
          <DialogTitle className="alert-title align-right dialog-header">
            <Box>

              <Button
                className="details-remove-icon p-0 no-hover staff-close"
                onClick={handleClose}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="white" />
                  <path
                    d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                    fill="#003087"
                  />
                </svg>
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription"
            >
             
            </DialogContentText>
          </DialogContent>

        </Dialog> */}

      <Footer />
    </>
  );
}

export default StaffManagement;
