import {
    Box,
    Typography,
    Container,
    styled
  } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { getLocalData } from 'src/utils';
import Loader from 'src/components/Loader';
import Header from 'src/components/Header';
  
  const MainContent = styled(Box)(
    () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
  );
  
  function Unauthorized() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const locationId = getLocalData('locationId') 
    // const locationStatus = getLocalData('locationStatus') 
    useEffect(()=> {
      setTimeout(()=> {
        setLoading(false)
      },1000)
    },[])

    useEffect(()=> {
      if(locationId && locationId !== 'undefined'){
        navigate('/')
      }
    },[locationId])

    return (
      <>
        <Loader IsLoading={loading} />
        <Container maxWidth="100%" className="main-container">
          <Header />
        </Container>
        {!loading && 
          <>
            <Helmet>
              <title>Unauthorized</title>
            </Helmet>
            <MainContent>
              <Container maxWidth="md">
                <Box textAlign="center" sx={{ height:"90vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <Typography variant="h2" sx={{ my: 2 }}>
                  No location assigned to your franchise.
                  {/* {!locationId 
                    ? "No location assigned to your franchise." 
                    : locationStatus === "0"
                      ? "The franchise location is Inactive"
                      : "No location assigned to your franchise."
                    }  */}
                  </Typography>
                  
                </Box>
              </Container>
            </MainContent>
          </>
        }
      </>
    );
  }
  
  export default Unauthorized;
  