import Login from '../content/pages/Authentications/Login';
import ForgotPassword from '../content/pages/Authentications/ForgotPassword';
import ResetPassword from '../content/pages/Authentications/ResetPassword';
import ForgotRequestSent from '../content/pages/Authentications/ForgotRequestSent';
import ResetLinkExpired from '../content/pages/Authentications/ResetLinkExpired';
import PasswordChanged from '../content/pages/Authentications/PasswordChanged';
import UpdatePassword from '../content/pages/Authentications/UpdatePassword';
import Authorization from '../content/applications/Authorization/AuthorizationMessage'

export const publicRoutes = [{
    path: '/',
    element: <Login />
},
{
    path: 'login',
    element: <Login />
},
{
    path: 'forgot-password',
    element: <ForgotPassword />
},
{
    path: 'reset-password',
    element: <ResetPassword />
},
{
    path: 'request-sent',
    element: <ForgotRequestSent />
},
{
    path: 'link-expired',
    element: <ResetLinkExpired />
},
{
    path: 'password-changed',
    element: <PasswordChanged />
},
{
    path: 'update-password',
    element: <UpdatePassword />
},
{
    path: 'authorization/*',
    element: <Authorization />
}
]; 