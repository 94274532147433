import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import { HeaderProvider } from 'src/contexts/HeaderContext';
import * as serviceWorker from 'src/serviceWorker';
import { HeaderButtonProvider } from './contexts/HeaderButtonContext';
import { CheckoutProvider } from './contexts/CheckoutContext';
import { registerLicense } from '@syncfusion/ej2-base';

// syncfusion license
registerLicense(process.env.REACT_APP_SYNCFUSIONKEY);

ReactDOM.render(
  <HelmetProvider>
    <CheckoutProvider>
      <SidebarProvider>
        <HeaderProvider>
          <HeaderButtonProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </HeaderButtonProvider>
        </HeaderProvider>
      </SidebarProvider>
    </CheckoutProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
// serviceWorker.register();
