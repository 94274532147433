import React from 'react';
import {Avatar, Card, CardContent, CardHeader, Grid, IconButton, Typography} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";


export const ContentCard = ({content}) => {


    return (
        <>
            <Grid container xs={12} xl={12} sm={12} md={12} lg={12} mt={2} pl={2} className='card-grid-container'>
                <Grid item sx={12} md={4} sm={4} lg={4} xl={4} pr={2}>
                    <Grid className='card-content-blk' sx={{width: '100%', padding: '0px'}}>
                        {/* sx={{ maxWidth: 345 }} */}
                        <Card sx={{borderRadius: '19px'}}>
                            <CardHeader
                                className='card-header'
                                avatar={
                                    <Avatar
                                        alt="board"
                                        src="/static/images/logo/cardboardicon.png"
                                        sx={{marginTop: 3}}
                                        className='card-avatar-img'
                                    />
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon/>
                                    </IconButton>
                                }
                                title={content?.length}
                                subheader="Active Contracts"
                            />
                            <CardContent sx={{paddingTop: 0, paddingLeft: 8, wordBreak: 'break-all'}}>
                                <Typography variant="body2" color="text.secondary">
                                   Total number of active contracts
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item sx={12} md={4} sm={4} lg={4} xl={4} pr={2}>
                    <Grid className='card-content-blk' sx={{width: '100%', padding: '0px'}}>
                        <Card sx={{borderRadius: '19px'}}>
                            <CardHeader
                                className='card-header'
                                avatar={
                                    <Avatar
                                        alt="board"
                                        src="/static/images/logo/cardboardicon.png"
                                        sx={{marginTop: 3}}
                                        className='card-avatar-img'
                                    />
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon/>
                                    </IconButton>
                                }
                                title="$232,456"
                                subheader="Revenue Generated"
                            />
                            <CardContent sx={{paddingTop: 0, paddingLeft: 8, wordBreak: 'break-all'}}>
                                <Typography variant="body2" color="text.secondary">
                                    Total average revenue generated
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item sx={12} md={4} sm={4} lg={4} xl={4}>
                    <Grid className='card-content-blk' sx={{width: '100%', padding: '0px'}}>
                        <Card sx={{borderRadius: '19px'}}>
                            <CardHeader
                                className='card-header'
                                avatar={
                                    <Avatar
                                        alt="board"
                                        src="/static/images/logo/cardboardicon.png"
                                        sx={{marginTop: 3}}
                                        className='card-avatar-img'
                                    />
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon/>
                                    </IconButton>
                                }
                                title={content?.length}
                                subheader="Total Contracts"
                            />
                            <CardContent sx={{paddingTop: 0, paddingLeft: 8, wordBreak: 'break-all'}}>
                                <Typography variant="body2" color="text.secondary">
                                   Total number of active contracts
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}