import { React, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  styled,
  Button,
  // OutlinedInput,
  // Divider,
  // IconButton,
  // Tooltip,
  // FormControl,
  // InputAdornment,
  // FormHelperText
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// import Logo from 'src/components/LogoSign';
import '../../style.css';
// import { styled } from '@mui/material/styles';
import GlobalData from '../../../../global';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(75)};
`
);

const TypographyH3 = styled(Typography)(
  ({ theme }) => `
  color: ${theme.colors.alpha.black[50]};
`
);

// const OutlinedInputWrapper = styled(OutlinedInput)(
//   ({ theme }) => `
//     background-color: ${theme.colors.alpha.white[100]};
// `
// );

// const ButtonNotify = styled(Button)(
//   ({ theme }) => `
//     margin-right: -${theme.spacing(1)};
// `
// );

function PasswordChanged() {
  const navigate = useNavigate();
  
  // Get the title from global data
  const title = GlobalData[0].title;

  const calculateTimeLeft = () => {
    const difference = +new Date(`2023`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const loginPageRedirect = () => {
    navigate('/')
}

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box textAlign="center" px={3}>
        <TypographyH1 variant="h1">{timeLeft[interval]}</TypographyH1>
        <TypographyH3 variant="h3">{interval}</TypographyH3>
      </Box>
    );
  });

  return (
    <>
      <Helmet>
        <title>Password changed | {title}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xxl" sx={{ padding: '0px !important' }}>
          <div className='main'>
            <div className='parent-container-main'>
              <div className='logo-text'>
                <div className="zoom-room-logo">
                  <img src={'/static/images/logo/zrlogo.png'} style={{ maxWidth: '100%' }} className="logo-image" alt='zoom-room-logo' />
                </div>
                <div className="hero-text">
                  <Typography className='hero-message'>WE DON'T TRAIN DOGS.</Typography>
                  <Typography className='hero-message'>WE TRAIN THE PEOPLE WHO LOVE THEM.</Typography>
                </div>
              </div>
              <div className='form-section'>
                <div className='zoom-room-line'>
                  <div className='horizontal-line' />
                </div>
                <div className='form1'>
                  <Card className="form-area-password-changed">
                    <Container component="main" maxWidth="xs" className="login-page">
                      <Box display="flex" justifyContent="center" direction="column" className="tick-container">
                        <img src={'/static/images/logo/Tick.png'} style={{ maxWidth: '100%' }} className="tick-image" alt='zoom-room-logo' />
                      </Box>
                      <Box>
                        <Box display="flex" justifyContent="center" direction="column">

                          <Typography className="title-text-password-changed">
                            PASSWORD CHANGED
                          </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" direction="column">
                          <Typography className="title-text-sub">
                            Your password has been changed 
                            <Typography style={{ textAlign: 'center', marginTop: '1px', fontFamily: 'Myriad Pro' }}>successfully</Typography>
                          </Typography>
                        </Box>

                        <Button type="button" fullWidth variant="contained" className='button-style-password-changed' onClick={loginPageRedirect}>
                          <Typography className='button-text'>
                            BACK TO LOGIN
                          </Typography>
                        </Button>
                      </Box>
                    </Container>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MainContent>
    </>
  );
}

export default PasswordChanged;
