import React, { useCallback } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Typography } from '@mui/material';
import { useChat } from './context/ChatContext';

function ChatHeader({ handleClose }) {
  const {
    activeScreen,
    handleClearStates,
    selectedSearchClient,
    locationDetails
  } = useChat();

  const formatNumber = (number) => {
    if (number?.includes('-')) {
      return number?.replace(/-/g, '');
    }
    return number;
  };

  const getChatName = useCallback(() => {
    const cleanedPhoneNumber = formatNumber(locationDetails?.twilioNumber);

    const { clientName, firstName, lastName } = selectedSearchClient;
    return (
      clientName ||
      `${firstName || ''} ${lastName || ''}`.trim() ||
      `Chat${cleanedPhoneNumber ? ` : ${cleanedPhoneNumber}` : ''}`
    );
  }, [selectedSearchClient, locationDetails]);

  return (
    <div className="list-view-header">
      {activeScreen !== 0 && (
        <ArrowBackIcon className="icon-style" onClick={handleClearStates} />
      )}
      <Typography className="chat-header-name">{getChatName()}</Typography>
      <CancelOutlinedIcon className="cancel-icon" onClick={handleClose} />
    </div>
  );
}

export default ChatHeader;
