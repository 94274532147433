import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  Card,
  Grid,
  Paper,
  Autocomplete,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
// import './Location.css';
import { CustomDatePicker } from 'src/components/CustomDatePicker';

const Filter = ({ setFilters, filters, getStates }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const classes = useStyles();
  const autoCompleteClasses = useAutocompleteStyles();
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [managerList, SetManagerList] = useState([]);
  console.log(endDate);
  const [openFilter, setOpenFilter] = useState({
    manager: false,
    state: false,
    datefilter: false,
    city: false,
    openingStatus: false
  });

  useEffect(() => {
    getManagerList();
  }, []);

  const changeType = (value) => {
    // setFilters((prevState) => ({ ...prevState, isActive: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const changeState = (Selectedstate) => {
    setFilters((prevState) => ({ ...prevState, state: Selectedstate }));
  };

  const ChangeManager = (value) => {
    setFilters((prevState) => ({ ...prevState, managerName: value }));
  };

  const ChangeLocationStatus = (value) => {
    setFilters((prevState) => ({ ...prevState, openingStatus: value }));
  };

  const changeStartDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setstartDate(date);
    setFilters((prevState) => ({
      ...prevState,
      openingStartDate: date
    }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      manager: field === 'manager' ? !prevState.manager : false,
      state: field === 'state' ? !prevState.state : false,
      datefilter: field === 'datefilter' ? !prevState.datefilter : false,
      city: field === 'city' ? !prevState.city : false,
      openingStatus:
        field === 'openingStatus' ? !prevState.openingStatus : false
    }));
  };

  const changeEndDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setEndDate(date);
    setFilters((prevState) => ({
      ...prevState,
      openingEndDate: date
    }));
  };

  const clearFilters = () => {
    setEndDate('');
    setstartDate('');
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      city: '',
      state: null,
      managerName: null,
      openingDate: null,
      openingEndDate: null,
      openingStatus: null
    });

    setOpenFilter({
      manager: false,
      state: false,
      datefilter: false,
      city: false,
      openingStatus: null
    });
  };

  const getManagerList = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}users/getManagerList`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          SetManagerList(Item);
        }
      });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Type</InputLabel>
          <Select
            // value={filters.isActive ?? ''}
            value={filters.status ?? ''}
            onChange={(event) => changeType(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={-1}>All</MenuItem>
            {/* <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} onClick={() => handleOpenFilter('manager')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.manager && classes.closeY,
                    openFilter.manager && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography> Assigned Specialist</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.manager && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Manager</InputLabel>
              <Select
                onChange={(event) => ChangeManager(event.target.value)}
                value={filters.managerName}
                sx={{
                  width: '100%'
                }}
                label="Assign Manager"
                className="status-field field-label-css"
              >
                {/* <MenuItem value="">None</MenuItem> */}
                {managerList.length > 0 &&
                  managerList.map((item) => (
                    <MenuItem
                      id={item.firstName + ' ' + item.lastName}
                      value={item.firstName + ' ' + item.lastName}
                    >
                      {item.firstName + ' ' + item.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Grid item onClick={() => handleOpenFilter('state')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.state && classes.closeY,
                    openFilter.state && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>State</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.state && (
          <Box marginTop={1}>
            <Autocomplete
              disablePortal
              value={filters.state}
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                changeState(newValue);
              }}
              className="status-field field-label-css"
              options={getStates.map((option) => option.state)}
              sx={{
                width: '100%'
              }}
              renderInput={(params) => (
                <TextField {...params} label={params?.label || 'States'} />
              )}
            />
          </Box>
        )}

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('openingStatus')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.openingStatus && classes.closeY,
                    openFilter.openingStatus && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography> Status</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.openingStatus && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Status</InputLabel>
              <Select
                onChange={(event) => ChangeLocationStatus(event.target.value)}
                value={filters.cost}
                sx={{
                  width: '100%'
                }}
                label="Select Level"
                className="status-field field-label-css"
              >
                <MenuItem value={'Location Search'}>Location Search</MenuItem>
                <MenuItem value={'Location Negotiation'}>
                  Location Negotiation
                </MenuItem>
                <MenuItem value={'LOI Signed'}>LOI Signed</MenuItem>
                <MenuItem value={'Lease Signed'}>Lease Signed</MenuItem>
                <MenuItem value={'Under Construction'}>
                  Under construction
                </MenuItem>
                <MenuItem value={'Open'}>Open</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        {/*  
        <Grid onClick={() => handleOpenFilter('city')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.city && classes.closeY,
                    openFilter.city && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>City</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.city === true && (
          <Box marginTop={1}>
            <TextField
              fullWidth
              name="city"
              label="CITY"
              placeholder="CITY"
              value={filters.city}
              onChange={(e) => changeCity(e.target.value)}
              className="status-field field-label-css"
              InputLabelProps={{
                className: 'label-style-create-franchisee',
                shrink: true
              }}
              InputProps={{}}
            />
          </Box>
            )}
*/}
        <Grid xs={12} onClick={() => handleOpenFilter('datefilter')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.datefilter && classes.closeY,
                    openFilter.datefilter && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Opening Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.datefilter && (
          <>
            <Box marginTop={1}>
              <CustomDatePicker label="Start Date" onChange={changeStartDate} />
            </Box>
            <Box marginTop={1}>
              <CustomDatePicker
                label="End Date"
                onChange={changeEndDate}
                minDate={new Date(startDate)}
                disabled={Boolean(!startDate)}
              />
            </Box>
          </>
        )}
      </Card>
    </>
  );
};

export default Filter;
