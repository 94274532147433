import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    Typography,
    Card,
    Grid,
    Paper,
    Box,
    Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../services/ApiService';
import { getLocalData } from '../../utils';

export const GiftCardPricingFilter = ({ setFilters, filters }) => {
    const useStyles = makeStyles(() => ({
        openY: {
            transform: 'rotate(90deg)',
            color: '#003087'
        },
        closeY: {
            transform: 'rotate(0deg)',
            color: '#003087'
        }
    }));
    const locationId = getLocalData('locationId');
    const classes = useStyles();
    // const [expired, SetExpired] = useState(true);
    // const [service, SetService] = useState([]);

    const [pricingDataPrice, setPricingDataPrice] = useState([]); // getting dynamic list
    const [pricingDataValue, setPricingDataValue] = useState([]); // getting dynamic list

    const [openFilter, setOpenFilter] = useState({
        value: false,
        price: false,
    });

    const getGiftCardPricing = async () => {
        try {
            let encodedLocationId = encodeURIComponent(locationId);
            const response = await ApiService.get(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/getAllGiftCardPrices?locationId=${encodedLocationId}`);
            // setPricingData(response.data.Items);
            let Item = response && response.data && response.data.Items ? response.data.Items : [];
            const giftcardprice = Array.from(new Set(Item.map(obj => obj.price)));
            const giftcardvalue = Array.from(new Set(Item.map(obj => obj.giftCardPriceValue)));
            setPricingDataPrice(giftcardprice);
            setPricingDataValue(giftcardvalue);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // get all categories
        getGiftCardPricing();
    }, []);

    const changeType = (value) => {
        setFilters((prevState) => ({ ...prevState, status: value }));
    };

    const ChangeValue = (value) => {
        setFilters((prevState) => ({ ...prevState, giftCardPriceValue: value }));
    };

    const ChangePrice = (value) => {
        setFilters((prevState) => ({ ...prevState, price: value }));
    };

    const handleOpenFilter = (field) => {
        setOpenFilter((prevState) => ({
            // expired: field === 'expired' ? !prevState.expired : false,
            // category: field === 'category' ? !prevState.category : false,
            // service: field === 'service' ? !prevState.service : false,
            giftCardPriceValue: field === 'giftCardPriceValue' ? !prevState.giftCardPriceValue : false,
            price: field === 'price' ? !prevState.price : false
        }));
    };

    const clearFilters = () => {
        setFilters({
            status: filters.status,
            giftCardPriceValue: '',
            price: ''
        });

        setOpenFilter({
            giftCardPriceValue: false,
            price: false
        });
    };

    return (
        <>
            <Card className="filter-content">
                <Typography className="filter-label" pt={2} pb={3}>
                    Type
                </Typography>
                <FormControl
                    pt={2}
                    fullWidth
                    variant="outlined"
                    sx={{
                        width: '100%',
                        marginBottom: '10px'
                    }}
                >
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={filters.status ?? ''}
                        onChange={(event) => changeType(event.target.value)}
                        label="Type"
                        className="status-field field-label-css"
                    >
                        <MenuItem key={1} id={1} value={-1}>All</MenuItem>
                        {/* <MenuItem key={2} id={2} value={'active'}>Active</MenuItem>
                        <MenuItem key={3} id={3} value={'inactive'}>Inactive</MenuItem>
                        <MenuItem key={3} id={3} value={'expired'}>Expired</MenuItem> */}
                        <MenuItem key={2} id={2} value={1}>Active</MenuItem>
                        <MenuItem key={3} id={3} value={0}>Inactive</MenuItem>
                        <MenuItem key={3} id={3} value={3}>Expired</MenuItem>
                    </Select>
                </FormControl>
                <Divider />
                <Grid mt={2}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <Typography
                                className="filter-text"
                                sx={{
                                    fontWeight: '600',
                                    color: '#003087',
                                    fontSize: '16px'
                                }}
                            >
                                Filters
                            </Typography>
                        </Grid>
                        <Grid item onClick={clearFilters}>
                            <Typography
                                className="filter-text"
                                sx={{
                                    fontWeight: '600',
                                    fontSize: '16px',
                                    cursor: 'pointer'
                                }}
                            >
                                Clear
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Category filter */}
                <Grid item xs={12} onClick={() => handleOpenFilter('giftCardPriceValue')} pt={0.5}>
                    <Paper>
                        <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            className="pointer"
                        >
                            <Grid item>
                                <KeyboardArrowRightRoundedIcon
                                    className={clsx(
                                        !openFilter.giftCardPriceValue && classes.closeY,
                                        openFilter.giftCardPriceValue && classes.openY
                                    )}
                                />
                            </Grid>
                            <Grid item >
                                <Typography>Gift Card Value</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {openFilter.giftCardPriceValue && (
                    <Box marginTop={1}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Gift Card Value</InputLabel>
                            <Select
                                onChange={(event) => ChangeValue(event.target.value)}
                                value={filters.giftCardPriceValue ?? ''}
                                sx={{
                                    width: '100%'
                                }}
                                label="Gift Card Value"
                                className="status-field field-label-css"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224,
                                            overflowY: 'auto',
                                        },
                                    },
                                  }}
                            >
                                {/* <MenuItem key={0} id={0} value="">None</MenuItem> */}
                                {pricingDataValue.length > 0 && (
                                    pricingDataValue.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                {/* //price  */}
                <Grid item xs={12} onClick={() => handleOpenFilter('price')} pt={0.5}>
                    <Paper>
                        <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            className="pointer"
                        >
                            <Grid item>
                                <KeyboardArrowRightRoundedIcon
                                    className={clsx(
                                        !openFilter.price && classes.closeY,
                                        openFilter.price && classes.openY
                                    )}
                                />
                            </Grid>
                            <Grid item >
                                <Typography>Price</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {openFilter.price && (
                    <Box marginTop={1}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Price</InputLabel>
                            <Select
                                onChange={(event) => ChangePrice(event.target.value)}
                                value={filters.price ?? ''}
                                sx={{
                                    width: '100%'
                                }}
                                label="Price"
                                className="status-field field-label-css"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224,
                                            overflowY: 'auto',
                                        },
                                    },
                                  }}
                            >
                                {/* <MenuItem key={0} id={0} value="">None</MenuItem> */}
                                {pricingDataPrice.length > 0 && (
                                    pricingDataPrice.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                )}

            </Card>
        </>
    );
};


