import { Helmet } from 'react-helmet-async';
import React, { useEffect, useRef, useState } from 'react';
import Footer from 'src/components/Footer';
import Header from '../../../components/Header';
import { Slide } from '@mui/material';
import { Grid, Box } from '@mui/material';
import { getLocalData } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import ClientListAPI from './clientListAPI';
import AddClient from './addClient';
import { ApiService } from '../../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function clientList() {
  const navigate = useNavigate();
  const [allClientList, setallClientList] = useState([]);
  const [showScreen, setShowScreen] = useState(0);
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const [allLocationList, setAllLocationLists] = useState([]);
  // Get the title from global data
  const title = GlobalData[0].title;
  const childRef = useRef(null);
  // const [openPopup, setOpenPopup] = React.useState(false);

  useEffect(() => {
    let isLogin = getLocalData('Login_Sucess');
    console.log(isLogin, 'isssssssssssssss');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, []);

  const handleButtonClick = () => {
    setShowScreen(1);
  };

  // call method when get all location in case of franchisor
  useEffect(() => {
    if (isFranchisor === 'true') {
      getAllLocationList();
    }
  }, []);

  // Get all location lists
  const getAllLocationList = async () => {
    // Now show the loader
    // setIsLoading(true);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations`
      );
      let data = response;

      if (data.statusCode && !data.error) {
        // Set the list of franchisee
        setAllLocationLists(data.data.Items);
        let item = data?.data?.Items[0];
        if (item?.franchiseeId)
          localStorage.setItem('SelectedFranchiseeId', item?.franchiseeId);
        if (item?.locationId)
          localStorage.setItem('SelectedLocationId', item?.locationId);

        // Now hide the loader
        // setIsLoading(false);
      } else {
        // setIsLoading(false);
      }
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };

  const buttons = [
    {
      label: 'Add Client',
      onClick: handleButtonClick,
      theam: true
    }
  ];

  const closeFranchiseeDialog = () => {
    // When popup is open then not call the loader
    // setIsPopup(true);

    // trigger the parent method to refresh the data
    childRef?.current?.refreshClientData();
  };

  // const handleClosePopup = () => {
  //   setOpenPopup(false);
  // };
  return (
    <>
      <Helmet>
        <title>Client Management | {title}</title>
      </Helmet>
      <Box sx={{ paddingX: '22px' }}>
        {showScreen === 0 && (
          <Header title="Client Management" buttons={buttons} />
        )}

        {/* {(isFranchisor === 'true' && showScreen === 0) && (
          <Header title="Client Management" />
        )} isFranchisor === 'false' && */}
      </Box>

      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <AddClient
                Transition={Transition}
                FranchiseeAdded={closeFranchiseeDialog}
                setShowScreen={setShowScreen}
                showScreen={showScreen}
                allClientList={allClientList}
              />
            );
          // case 2: // Assuming 2 represents the EditClient screen
          // return (
          //   <DrawClientTable
          //     Transition={Transition}
          //     FranchiseeAdded={closeFranchiseeDialog}
          //     setShowScreen={setShowScreen}
          //     showScreen={showScreen}
          //     allClientList={allClientList} // Pass allClientList as a prop
          //   />
          // );
          default:
            return (
              <Grid item width={'100%'}>
                {/* Now draw the Client table */}
                <ClientListAPI
                  ref={childRef}
                  setShowScreen={setShowScreen}
                  showScreen={showScreen}
                  setallClientList={setallClientList}
                  allClientList={allClientList}
                  allLocationList={allLocationList}
                />
              </Grid>
            );
        }
      })()}
      {/* container */}
      {/* <Dialog
        open={openPopup}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Congratulations!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                The client and its child has been created successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            Close
          </Button>
        
        </DialogActions>
      </Dialog> */}
      <Footer />
    </>
  );
}

export default clientList;
