import React, { useState, useEffect } from 'react';
import {
  Typography,
  Dialog,
  Button,
  TextField,
  Grid,
  Box
} from '@mui/material';
import { handleSubmit, getTaxRate } from './taxModalFunctions';
import Loader from 'src/components/Loader';
import { limitDecimalDigits } from 'src/utils';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { getLocalData } from 'src/utils';

const AddTaxModal = ({ taxModal, setTaxModal }) => {
  const locationId = getLocalData('locationId');
  const UserName = getLocalData('UserName');
  const franchiseeId = getLocalData('franchiseeId');
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSucessMessage, setShowSucessMessage] = useState(false);
  const [taxValue, setTaxValue] = useState({
    object: {},
    value: ''
  });
  const [formError, setFormError] = useState({ taxValue: '' });

  const handleCloseModal = () => {
    setTaxModal(false);
  };

  const handleTaxChange = (e) => {
    setFormError({
      ...formError,
      taxValue: ''
    });
    const { value } = e.target;
    const formattedValue = value?.replace(/(\..*)\./g, '$1');
    const limitedValue = limitDecimalDigits(formattedValue, 2);
    setTaxValue({ ...taxValue, value: limitedValue });
  };

  useEffect(() => {
    if (taxModal) {
      getTaxRate(
        setTaxValue,
        setIsLoading,
        setErrorMessage,
        setShowErrorMessage,
        locationId
      );
    }
  }, [taxModal]);

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={successMessage}
          setShowMessage={setShowSucessMessage}
          closePopup={handleCloseModal}
        />
      )}

      <Dialog open={taxModal} onClose={handleCloseModal}>
        <Grid
          container
          item
          sx={{
            width: '400px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            gap: 3
          }}
        >
          <Typography variant="h3" color="primary">
            {' '}
            Add Tax
          </Typography>

          <TextField
            fullWidth
            className="input-field-dropdown-to-from"
            label="Tax"
            name="Tax"
            value={taxValue?.value}
            onChange={handleTaxChange}
            error={formError?.taxValue}
            helperText={formError?.taxValue}
            InputProps={{
              endAdornment: '%'
            }}
          />
        </Grid>
        <Box sx={{ display: 'flex', gap: 1, padding: 2 }}>
          <Grid container item md={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleCloseModal}
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
          <Grid container item md={6}>
            <Button
              fullWidth
              variant="contained"
              onClick={() =>
                handleSubmit(
                  taxValue,
                  setTaxModal,
                  setIsLoading,
                  setErrorMessage,
                  setShowErrorMessage,
                  setSuccessMessage,
                  setShowSucessMessage,
                  setFormError,
                  locationId,
                  UserName,
                  franchiseeId
                )
              }
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default AddTaxModal;
