/* eslint-disable no-lonely-if */
/* eslint-disable react/jsx-fragments */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */

import { useEffect, useState, forwardRef, Fragment } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  Divider,
  CircularProgress,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { EnrollFilter, EnrollTable } from './index';
import { CustomSelect, CustomButton, SearchInput, CustomTextField } from 'src/components/Global';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { getLocalData, setFullLocationId } from 'src/utils';
import ErrorMessage from '../PopupMessage/ErrorMessage';
import Loader from '../Loader';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const EnrollMember = (props) => {
  const {
    handleResetStates,
    loading,
    clientsData,
    allClientsData,
    allMembershipCategories,
    filters,
    setFilters,
    setLoading
  } = props;

  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const steps = ['Add Member', 'Payment Method'];
  const [activeStep, setActiveStep] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFilters, setIsFilters] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const locationId = setFullLocationId(getLocalData("locationId"));
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // const encodedLocationId = encodeURIComponent(locationId);
  const [dialog, setDialog] = useState({
    state: false,
    isEnrollComplete: false
  });

  const [isError, setIsError] = useState({
    category: false
  });

  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const data = clientsData?.filter((item) =>
      selectedRows?.includes?.(item.id)

    );
    console.log(allMembershipCategories)
    setSelectedRowsData(data);

  }, [selectedRows]);
  console.log(selectedRows, 'selectedRows')
  const handleEnrollInfo = (type, value) => {
    setSelectedCategory(value);
    setIsError({ ...isError, [type]: false });
  };

  const handleCloseScreen = () => {
    if (selectedRows?.length) {
      setDialog({ ...dialog, state: true });
    } else {
      setDialog({ ...dialog, state: true });
    //  handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        state: false
      });
    } else if (value === 'Discard') {
      handleResetStates();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
  };

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }

  const handleProceed = () => {
    if (activeStep == 0) {
      if (!selectedRows?.length) {
        setShowMessage(true);
        setErrorMessage('Select atleast one member to proceed further')
        // toast.error('Select atleast one member to proceed further');
        return;
      }

      if (!selectedCategory) {
        setIsError({ ...isError, category: true });
        return;
      }

      setActiveStep(1);
    } else {
      if (!selectedPayment) {
        setShowMessage(true);
        setErrorMessage('Please select payment method')
        // toast.error('Please select payment method');
      } else {
        if (getSelectedCategory()) {
          handleEnrollMember();
        } else {
          setShowMessage(true);
          setErrorMessage('Selected category have missing sortkey or name attribute')
          // toast.error(
          //   'Selected category have missing sortkey or name attribute'
          // );
        }
      }
    }
  };

  const getSelectedCategory = () => {
    const matchedObject = allMembershipCategories.find(
      (obj) => obj.sortKey == selectedCategory
    );
    return matchedObject || null;
  };

  function getClintId() {
    let array = [];
    selectedRowsData?.map((item) => array.push(item.id));

    return array;
  }
  // function getClintId() {
  //   let array = [];
  //   selectedRows?.map((item) => array.push(item.id));

  //   return array;
  // }

  const handleEnrollMember = async () => {
    setLoading(true)
    setBtnLoading(true);
    const data = {
      // locationId: 'LOC#12121',
      locationId: locationId,
      updatedBy: 'joy',
      paymentMethod: selectedPayment,
      membershipId: selectedCategory,
      membershipName: getSelectedCategory().name || '',
      price: parseFloat(getSelectedCategory().price) || 0,
      // emailIds: getEmailList(),
      clientIds: getClintId()
    };

    try {
      const response = await axios.post(
        `${BASE_URL}memberships/enrollMember`,
        data
      );

      if (!response.data?.error) {
       // setShowMessage(true);
        setErrorMessage(response.data?.message)
        // toast.success(response.data?.message);

        setDialog({ ...dialog, isEnrollComplete: true });
      } else {
        setShowMessage(true);
        setErrorMessage(response.data?.message ||
          'Something went wrong with enroll Membership')
        // toast.error(
        //   response.data?.message ||
        //     'Something went wrong with enroll Membership'
        // );
      }
      setLoading(false)
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, Try again!')
      // toast.error('Something went wrong, Try again!');
    }
  };

  return (
    <div className="service-container order-container">
      <Loader IsLoading={loading} />
      {showMessage &&
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      }
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        backgroundColor="#d1dced"
        height="50px"
      >
        <div className="container summary-close-btn">
          <Button
            className="details-remove-icon p-0 no-hover"
            onClick={() => handleCloseScreen()}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="13" cy="13" r="13" fill="white" />
              <path
                d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                fill="#003087"
              />
            </svg>
          </Button>
        </div>
      </Box> */}

      {dialog.isEnrollComplete ? (
        <Box
          sx={{ width: '100%' }}
          marginTop="70px"
          display="flex"
          justifyContent="center"
          marginX="auto"
          className=" common-form-container"
        >
          <Grid
            container
            maxWidth="400px"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Typography
                variant="variant1"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  textAlign: 'center',
                  fontSize: "30px",
                  color: '#003087',
                }}
              >
                Enrollment Successful
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="variant6"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  textAlign: 'center',
                  color: '#000'
                }}
              >
                Congratulations! Your payment has been successfully processed.
                Thank you for your purchase.
              </Typography>
            </Grid>

            <Grid item display="flex" gap="20px">
              <CustomButton variant="contained" onClick={() => window.print()}>Print Receipt</CustomButton>

              <CustomButton variant="outlined">Send Notification</CustomButton>
            </Grid>

            <Grid item className="underline-btn">
              <Button className="no-hover-btn" onClick={handleResetStates}>
                Back to Dashboard
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form">
            <Grid mt={1} marginBottom="20px">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginTop={2}
              >


                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box onClick={() => handleCloseScreen()} className="back-arrow-close">
                      <ArrowLeft />
                    </Box>
                    <Typography className="header-blue-32">Enroll Member</Typography>
                    {/* <h1 className="title primary-color">Enroll Member</h1> */}
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <CustomButton
                      variant="contained"
                     // loading={btnLoading}
                      onClick={handleProceed}
                    >
                      Proceed
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  width: '100%',
                  background: 'transparent',
                  paddingY: '30px'
                }}
                className="custom-stepper enroll-stepper"
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-labeling">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            {(() => {
              switch (activeStep) {
                case 1:
                  return (
                    <Fragment>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        maxWidth="sm"
                        marginX="auto"
                        marginTop={1}
                      >
                        <Grid item width="70%">
                          <Typography
                            variant="variant2"
                            sx={{
                              marginBottom: '16px',
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                              textAlign: 'center',
                              color: '#003087',
                              fontSize: "16px",
                              fontWeight: "600"
                            }}
                          >
                            Select Payment Method
                          </Typography>
                          <CustomSelect
                            fullWidth
                            name="paymentType"
                            value={selectedPayment}
                            onChange={(type, value) =>
                              setSelectedPayment(value)
                            }
                            label="Payment Method"
                            options={[
                              {
                                label: 'Cash',
                                value: 'Cash'
                              }
                            ]}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        maxWidth="sm"
                        marginX="auto"
                        marginTop={1}
                      >
                        <Grid item width="80%">
                          <CustomTextField
                            type="text"
                            name="name"
                            label="TOTAL AMOUNT TO PAY"
                            fullWidth
                            className="primary-color"
                            value={`$${getSelectedCategory().price ?? 0}`}
                          />
                        </Grid>
                      </Grid>
                    </Fragment>
                  );

                default:
                  return (
                    <Fragment>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item width={'100%'}>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              className="enroll-filters-container"
                            >
                              <Grid item marginY="20px">
                                <CustomButton
                                  onClick={() => setIsFilters(!isFilters)}
                                  variant={
                                    isAnyFilterApplied()
                                      ? 'contained'
                                      : isFilters
                                        ? 'contained'
                                        : 'outlined'
                                  }
                                >
                                  Filter (All)
                                  <KeyboardArrowDownRoundedIcon
                                    className={clsx(
                                      !isFilters && classes.closeY,
                                      isFilters && classes.openY
                                    )}
                                  />
                                </CustomButton>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={6}
                                marginY={{ sm: '20px' }}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  gap: '12px'
                                }}
                                className="enroll-search"
                              >
                                <CustomSelect
                                  fullWidth
                                  name="category"
                                  value={selectedCategory}
                                  onChange={handleEnrollInfo}
                                  label="Membership Category"
                                  error={isError.category}
                                  helperText={
                                    isError.category && 'Category is required'
                                  }
                                  options={allMembershipCategories?.map(
                                    (item) => ({
                                      label: item.name,
                                      value: item.sortKey
                                    })
                                  )}
                                />

                                <SearchInput
                                  value={searchQuery}
                                  setSearchQuery={setSearchQuery}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        {loading ? (
                          <CircularProgress sx={{ marginTop: '80px' }} />
                        ) : (
                          <Fragment>
                            {isFilters ? (
                              <Grid item width={isFilters ? 300 : 0}>
                                <EnrollFilter
                                  filters={filters}
                                  setFilters={setFilters}
                                  data={allClientsData}
                                />
                              </Grid>
                            ) : null}

                            <Grid
                              item
                              width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                            >
                              <EnrollTable
                                tableRows={clientsData}
                                searchQuery={searchQuery}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                              />
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    </Fragment>
                  );
              }
            })()}
          </form>
        </Box >
      )}

      <Dialog
        open={dialog.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes, Do you want to
                <Typography className="title-text-sub"> save them?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleDialog('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleDialog('Continue')}
          >
            Go back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};
