import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
// import { HiOutlineDocumentText } from 'react-icons/hi';

export function TransferAccordian(props) {
  const { notesData, selectedReason } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        style={{
          padding: '0',
          minHeight: '55px !important',
          alignItems: 'flex-start !important'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            display="flex"
            alignItems="center"
            gap={4}
            sx={{ marginBottom: '0px !important' }}
          >
            {/* <HiOutlineDocumentText className="orange-icons icon-adjustment-summary" /> */}
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6274 3.75977H5.62744C5.09701 3.75977 4.5883 3.97048 4.21323 4.34555C3.83816 4.72062 3.62744 5.22933 3.62744 5.75977V19.7598C3.62744 20.2902 3.83816 20.7989 4.21323 21.174C4.5883 21.5491 5.09701 21.7598 5.62744 21.7598H19.6274C20.1579 21.7598 20.6666 21.5491 21.0417 21.174C21.4167 20.7989 21.6274 20.2902 21.6274 19.7598V9.75977L15.6274 3.75977ZM19.6274 19.7598H5.62744V5.75977H14.6274V10.7598H19.6274M17.6274 14.7598H7.62744V12.7598H17.6274M14.6274 17.7598H7.62744V15.7598H14.6274"
                fill="#E35205"
              />
            </svg>
            <h3 className="accordian-header">Notes</h3>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0px 20px 20px 65px',
            color: ' #000000 !important',
            fontSize: '16px !important',
            fontWeight: '400 !important',
            lineheight: '19px',
            fontFamily: 'Myriad Pro, sans-serif !important',
            fontStyle: 'normal !important'
          }}
        >
          <Typography sx={{ textAlign: 'left' }}>{selectedReason}</Typography>
          {notesData ? (
            <>
              {/* <Typography sx={{ marginTop: '16px', fontWeight: '600', textAlign: 'left' }}>
                Notes:{' '}
              </Typography> */}
              <Typography>{notesData}</Typography>
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
