import {Autocomplete, Stack, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
    autocomplete: {
        // Add your custom styles for the Autocomplete component here
        width: 300,
        borderColor: '2px solid red'
    },

    inputRoot: {
        // Add your custom styles for the input field here

        borderRadius: 12
    },
    listItem: {
        // Add your custom styles for the list items here
    }
}));

const options = ['Every 30 days', 'Every 60 days', 'Every 120 days'];

export const DaysDropDown = () => {
    const classes = useStyles();

    return (
        <div className='contDropDownSec'>
            <Stack direction="column" spacing={3} marginTop={1} className='sort-dropdown'>
                {/* <label htmlFor="">
                                <Typography variant="variant2">Choose Products</Typography>
                        </label> */}
                <Autocomplete
                    style={{
                        // border: "2px solid rgba(0, 48, 135, 0.5",
                        outline: 'none',
                        borderRadius: '12px'
                    }}
                    disablePortal
                    classes={{
                        root: classes.autocomplete,
                        inputRoot: classes.inputRoot,
                        listbox: classes.listbox,
                        option: classes.listItem
                    }}
                    id="combo-box-demo"
                    options={options}
                    // sx={{ width: 300, border: "2px solod #003087" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{
                                className: 'text-area-label'
                            }}
                            label="SELECT DAYS"
                        />
                    )}
                />
            </Stack>
        </div>
    );
};
