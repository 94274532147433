import React, { useState } from 'react';
import { Typography, Card, Grid, Paper, Divider, Box } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDatePicker } from 'src/components/CustomDatePicker';

const CashBoxFilter = ({ setstartDate, setEndDate, startDate, endDate }) => {
  const useStyles = makeStyles(() => ({
    openY: { transform: 'rotate(90deg)', color: '#003087' },
    closeY: { transform: 'rotate(0deg)', color: '#003087' }
  }));
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState({ datefilter: false, });
  const [errorMessage, setErrorMessage] = useState('');
  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({ datefilter: field === 'datefilter' ? !prevState.datefilter : false }));
  };

  const changeStartDate = (date) => {
    const selectedDate = new Date(date);
    const endSelectDate = new Date(endDate);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
      setstartDate(null);
      setErrorMessage('From date cannot be greater than todays date.')
      return;
      // eslint-disable-next-line
    } else if (endDate !== null && selectedDate > endSelectDate) {
      setstartDate(null);
      setErrorMessage('From date should be less than To date .')
      return;
    }
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setErrorMessage('')
    setstartDate(date);
  };

  const changeEndDate = (date) => {
    const selectedDate = new Date(startDate);
    const endSelectDate = new Date(date);
    const currentDate = new Date();
    if (endSelectDate > currentDate) {
      setEndDate(date)
      setErrorMessage('To date cannot be greater than todays date.')
      return;
      // eslint-disable-next-line
    } else if (selectedDate > endSelectDate || selectedDate > currentDate) {
      setEndDate(date)
      setErrorMessage('From date should be less than To date.')
    }
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setErrorMessage('')
    setEndDate(date)
  };
  const clearFilters = () => {
    setErrorMessage('')
    setEndDate(null)
    setstartDate(null)
    setOpenFilter({ datefilter: false });
  };
  return (
    <>
      <Card className="filter-content custom-filter-fields">
        <Grid mt={2} mb={2}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <Typography className="filter-text filter-text-title" sx={{ fontWeight: '600', fontSize: '16px' }}>Filters</Typography>
            </Grid>
            <Grid item onClick={clearFilters}>
              <Typography className="filter-text" sx={{ fontWeight: '600', fontSize: '16px', cursor: 'pointer' }}> Clear </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid xs={12} onClick={() => handleOpenFilter('datefilter')} pt={0.5} sx={{ cursor: 'pointer' }}>
          <Paper>
            <Grid container alignItems="center" justify="space-between" className="pointer"    >
              <Grid item><KeyboardArrowRightRoundedIcon className={clsx(!openFilter.datefilter && classes.closeY, openFilter.datefilter && classes.openY)} /></Grid>
              <Grid item><Typography>Date</Typography></Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.datefilter && (
          <>
            <Box marginTop={2}>
              <CustomDatePicker
                label="FROM DATE"
                onChange={changeStartDate} />
            </Box>
            <Box marginTop={3}>
              <CustomDatePicker
                label="TO DATE"
                onChange={changeEndDate}
                minDate={new Date(startDate)}
                disabled={Boolean(!startDate)} />
              <p style={{ color: 'red', marginLeft: '5px' }}>{errorMessage}</p>
            </Box>
          </>
        )}
      </Card>
    </>
  );
};
export default CashBoxFilter;