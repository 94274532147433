// UserContext.js
import { createContext, useState } from 'react';
import { getLocalData } from '../utils';

export const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const userRole = getLocalData('userRole');
  const [isCheckInOutApi, setIsCheckInOutApi] = useState(true)
  const [isUserUpdate, setIsUserUpdate] = useState(true)
  const [userData, setUserData] = useState({
    UserDetails: {},
    franchiseeId: '',
    isFranchisor: false,
    isPrimaryOwner: false,
    locationId: '',
    locationName: '',
    userRole: userRole ?? 'guest',
    refreshToken: '',
    accessToken: ''
  }); // Initialize the user role state
  const [isNotification, setNotification] = useState(true)

  return (
    // <UserContext.Provider value={{ userData, setUserData ,isCheckInOutApi,setIsCheckInOutApi }} >
    <UserContext.Provider value={{ userData, setUserData, isCheckInOutApi, setIsCheckInOutApi, isNotification, setNotification, isUserUpdate, setIsUserUpdate }} >
      {children}
    </UserContext.Provider>
  );
};
