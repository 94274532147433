/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Autocomplete,
  Slide,
  CardMedia,
  InputAdornment
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TextField, Grid } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { getLocalData } from 'src/utils';
import Loader from '../../../components/Loader';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { HeaderContext } from 'src/contexts/HeaderContext';
import { ImageSlider } from 'src/components/Global';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import heic2any from 'heic2any';
import CreateVariantModal from 'src/content/applications/FranchiseProduct/AddProduct/CreateVariantModal';
import CreateCategoryModal from 'src/content/applications/FranchiseProduct/AddProduct/CreateCategoryModal';
import CreateVariantTypeModal from 'src/content/applications/FranchiseProduct/AddProduct/CreateVariantTypeModal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddProduct({
  setShowScreen,
  refreshData,
  autoGeneratedSkuCode,
  getAutoGeneratedSkuCode
}) {
  let userId = getLocalData('userId');
  const franchiseeId = getLocalData('franchiseeId');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const [imageUrl, setImageUrl] = useState('');
  // check field updated or not
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);

  // const [imgObj, setImageObj] = useState('');
  //  const [productData, setProductData] = useState([]);

  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  /* States for getting values from input fields of Add product form as follows */

  const [manufacturerProductName, setmanufacturerProductName] = useState('');
  // const [barCode, setbarCode] = useState(0);
  const [manufacturer, setmanufacturer] = useState('');
  const [manufacturerUrl, setmanufacturerUrl] = useState('');
  const [zoomRoomProductName, setzoomRoomProductName] = useState('');
  const [productCategory, setproductCategory] = useState([]);
  const [varientType, setvarientType] = useState([]);
  const [varientProduct, setvarientProduct] = useState({
    0: [],
    1: []
  });
  console.log(varientProduct, 'varientProduct');
  const [getSkuFromApi, setgetSkuFromApi] = useState('');
  const [price, setprice] = useState('');
  const [supplierList, setsupplierList] = useState([]);
  const [sellingPoint, setsellingPoint] = useState('');
  const [productDescription, setproductDescription] = useState('');
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [isRequired, setisRequired] = useState(false);
  const [isBestSeller, setisBestSeller] = useState(false);
  const [istaxExempt, setistaxExempt] = useState(false);
  const [isDiscontinued, setisDiscontinued] = useState(false);
  const [productCategoryId, setproductCategoryId] = useState('');
  const [variantId, setvariantId] = useState('');
  const [variant, setVariant] = useState([
    {
      valueId: '',
      value: ''
    },
    {
      valueId: '',
      value: ''
    }
  ]);
  const [typesVar1, setTypesVar1] = useState('');
  const [typesVar2, setTypesVar2] = useState('');
  const [supplierId, setsupplierId] = useState('');
  const [valueId, setvalueId] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [variantDisabled, setvariantDisabled] = useState(true);
  const [expiryDate, setexpiryDate] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [multipleImage, setMultipleImage] = useState([]);
  const [skuProduct, setSkuProduct] = useState(null);
  const [imageError, setImageError] = useState('');
  const [skuCode, setSkuCode] = useState(null);
  const [skuCodeError, setSkuCodeError] = useState('');
  const [skuCodeValidateApi, setskuCodeValidateApi] = useState(false);
  /* States for getting and setting add product errors  */
  const [manufacturerProductNameError, setmanufacturerProductNameError] =
    useState('');
  // const { setHeaderText } = useContext(HeaderContext);
  //  setHeaderText('Add Product');
  // const [barCodeError, setbarCodeError] = useState('');
  const [manufacturerError, setmanufacturerError] = useState('');
  const [manufacturerUrlError, setmanufacturerUrlError] = useState('');
  const [zoomRoomProductNameError, setzoomRoomProductNameError] = useState('');
  const [productCategoryError, setproductCategoryError] = useState(false);
  const [varientTypeError, setvarientTypeError] = useState(false);
  const [varientError, setvarientError] = useState(false);
  // const [priceError, setpriceError] = useState('');
  const [supplierError, setsupplierError] = useState('');
  const [sellingPointError, setsellingPointError] = useState('');
  const [productDescriptionError, setproductDescriptionError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [expiryDateError, setexpiryDateError] = useState('');
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check manufacturere url is valid or not
  const [isManufacturerUrlValid, setIsManufacturerUrlValid] = useState(false);

  /* States for opening of dialog */
  // const [openAddproduct, setopenAddproduct] = useState(openProductPopup);
  const [openAddVariant, setopenAddVariant] = useState(false);
  const [openAddCategory, setopenAddCategory] = useState(false);
  const [openAddVarientType, setopenAddVarientType] = useState(false);
  // const [openAddSupplier, setopenAddSupplier] = useState(false);
  const [variantName, setvariantName] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const [categoryDescription, setcategoryDescription] = useState('');
  const [varientTypeName, setvarientTypeName] = useState('');
  const [variantTypeId, setvariantTypeId] = useState('');
  const [productImage, setProductImage] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [variantField, setVariantField] = useState(0)
  const [createCategoryErrors, setCreateCategoryErrors] = useState({
    categoryName: '',
    description: ''
  });
  const [createVariantTypeErrors, setCreateVariantTypeErrors] = useState({
    varientTypeName: ''
  });
  const [createVariantErrors, setCreateVariantErrors] = useState({
    varientName: '',
    variantType: ''
  });
  /*  const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState(['Apple', 'Banana', 'Orange']);
    const [selectedCategory, setSelectedCategory] = useState('');
  
    const handleSearchTermChange = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const handleCategorySelect = (category) => {
      setSelectedCategory(category);
      setSearchTerm('');
    };
  
    const handleAddNewCategory = () => {
      if (searchTerm && !categories.includes(searchTerm)) {
        setCategories([...categories, searchTerm]);
      }
      setSelectedCategory(searchTerm);
      setSearchTerm('');
    };
  
    const filteredCategories = categories.filter(category =>
      category.toLowerCase().includes(searchTerm.toLowerCase())
    );
  */
  useEffect(() => {
    // getProductCategory();
    clear();
    getCategory();
    getVariantType();
    getSupplier();
    setSkuCode(autoGeneratedSkuCode);
    // getSku();
  }, []);

  useEffect(() => {
    if (multipleImage) {
      let images = multipleImage.map((item) => item.previewURL);
      console.log(images, 'images');
      // let imagesArr = images.map((item) => ({
      //   image: item
      // }));
      // setMultipleImages(images);
      setProductImage(images);
    }
  }, [multipleImage]);
  /* Function to clear form fields when dialog form is closed */
  const clear = () => {
    setmanufacturer('');
    setmanufacturerUrl('');
    setzoomRoomProductName('');
    setmanufacturerProductName('');
    setvarientType('');
    setvarientProduct({
      0: [],
      1: []
    });
    setTypesVar1('');
    setTypesVar2('');
    setVariant([
      {
        valueId: '',
        value: ''
      },
      {
        valueId: '',
        value: ''
      }
    ]);
    setprice('');
    setsupplierList([]);
    setsellingPoint('');
    setproductDescription('');
    setproductCategoryList([]);
    setisRequired(false);
    setisBestSeller(false);
    setistaxExempt(false);
    setisDiscontinued(false);
    setvariantDisabled(true);
    setimgObj([]);
    setexpiryDate('');
    setvariantId('');
    setsupplierId('');
    setMultipleImage([]);
    setIsFieldUpdated(false);
  };
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });
  const autoCompleteClasses = useAutocompleteStyles();

  // open variant dialog
  // const handleOpenVariant = () => {
  //   setopenAddVariant(true);
  // };

  // close variant dialog
  const handleCloseVariant = () => {
    setCreateCategoryErrors({
      categoryName: '',
      description: ''
    });
    setCreateVariantTypeErrors({
      varientTypeName: ''
    });
    setCreateVariantErrors({
      varientName: '',
      variantType: ''
    });
    setopenAddVariant(false);
    setopenAddCategory(false);
    setopenAddVarientType(false);
  };
  // create new variant
  const handleCreateVariantType = () => {
    const error = !varientTypeName;
    setCreateVariantTypeErrors({
      varientTypeName: !varientTypeName ? 'Varient Type name is required.' : ''
    });
    if (error) return;

    if (varientTypeName) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        variantType: varientTypeName,
        createdBy: userId
        // isActive: true
      };
      createVariantType(data);
    }
  };

  const addNewVariant = (number) => {
    setvarientTypeName('');
    setopenAddVarientType(true);
    setVariantField(number)
  };

  // create variant type
  const createVariantType = (data) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCT_API_END_POINT}variants`, data)
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvarientTypeName('');
          getVariantType(res?.data?.data?.variantId);
          setvariantId(res?.data?.data?.variantId);
          setopenAddVarientType(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        } else {
          setIsLoading(false);
          setCreateVariantTypeErrors({...createVariantTypeErrors, varientTypeName: res?.data?.message})
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const addVariant = (number) => {  
    if(number === 0){
      setvariantTypeId(typesVar1?.variantId ?? '');
    }
    if(number === 1){
      setvariantTypeId(typesVar2?.variantId ?? '');
    }
    setopenAddVariant(true);
    setvariantName('');
  };

  // Method to set variant type
  const handleVariantTypeChange = (e) => {
    setvariantTypeId(e.target.value);
  };

  // Method to create new variant
  const handleCreateVariant = () => {
    const error = !variantName || !variantTypeId;

    setCreateVariantErrors({
      varientName: !variantName ? 'Varient Name is required.' : '',
      variantType: !variantTypeId ? 'Varient Type is required.' : ''
    });
    if (error) return;
    if (variantName && variantTypeId) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        value: variantName,
        variantId: variantTypeId,
        createdBy: userId
        // isActive: true
      };
      createVariant(data);
    }
  };

  // create variant
  const createVariant = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}variant-values`,
        data
      )
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvariantName('');
          setvariantTypeId('');

          if(typesVar1?.variantId === variantTypeId){
            getVariants(variantTypeId, 0 , null, res.data.data.valueId);
          }
          if(typesVar2?.variantId === variantTypeId){
            getVariants(variantTypeId, 1, null, res.data.data.valueId);
          }
          // set variant value id
          setvalueId(res.data.data.valueId);
          setopenAddVariant(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        } else {
          setIsLoading(false);
          setCreateVariantErrors({...createVariantErrors, varientName: res?.data?.message})
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  /* Method for Add product category modal */
  const addNewCategory = () => {
    setcategoryName('');
    setcategoryDescription('');
    setopenAddCategory(true);
  };

  // Now create category
  const handleCreateCategory = () => {
    const error = !categoryName || !categoryDescription;

    setCreateCategoryErrors({
      categoryName: !categoryName ? 'Category name is required.' : '',
      description: !categoryDescription ? 'Description is required.' : ''
    });

    if (error) return;
    if (categoryName && categoryDescription) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        categoryName: categoryName,
        description: categoryDescription,
        createdBy: userId
        // isActive: true
      };
      createCategory(data);
    }
  };
  // create category
  const createCategory = (data) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCT_API_END_POINT}category`, data)
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setcategoryName('');
          setcategoryDescription('');
          getCategory();
          setproductCategoryId(res.data.data.categoryId);
          setopenAddCategory(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
          setproductCategoryError(false);
        } else {
          setIsLoading(false);
          console.log(res, "res is consoaefds")
          setCreateCategoryErrors({...createCategoryErrors, categoryName: res?.data?.message})
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // Method to open the update dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      // setopenAddproduct(true);
    }
    if (val === 'Discard') {
      setShowScreen(0);
      clear();
      setOpen(false);
      refreshData();
      // setopenAddproduct(false);
      // setopenProductPopup(false);
    }
  };

  /* Method for closing of Add product modal */

  const handleCloseAddProduct = () => {
    if (isFieldUpdated) {
      setOpen(true);
      // setopenAddproduct(true);
    } else {
      setShowScreen(0);
      clear();
      // setopenAddproduct(false);
      // setopenProductPopup(false);
    }
  };

  /* Function to get uploaded image */
  const handleFileInput = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg') ||
        fileName.endsWith('.heic')
      ) {
        if (fileName.endsWith('.heic')) {
          try {
            const jpegBlob = await heic2any({ blob: file });
            if (!jpegBlob) {
              throw new Error('Conversion failed.');
            }

            const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
              type: 'image/jpeg'
            });

            const uploadedImage = {
              previewURL: URL.createObjectURL(jpegFile),
              id: Math.random().toString(36).substring(7),
              file: jpegFile
            };

            setMultipleImage([...multipleImage, uploadedImage]);
            setImageError('');
            setIsFieldUpdated(true);
          } catch (error) {
            console.error('Error handling HEIC file:', error);
            setImageError('Error handling HEIC file.');
          }
        } else if (file.type === 'image/gif') {
          setImageError(
            'Please upload a supported image format (excluding GIF and HEIC).'
          );
        } else {
          const uploadedImages = Array.from(event.target.files).map((file) => ({
            previewURL: URL.createObjectURL(file),
            id: Math.random().toString(36).substring(7),
            file: file
          }));

          setMultipleImage([...multipleImage, ...uploadedImages]);
          setImageError('');
          setIsFieldUpdated(true);
        }
      } else {
        setImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
    event.target.value = ""
  };

  /* Function to get category of products */

  const getCategory = async () => {
    await fetch(`${process.env.REACT_APP_PRODUCT_API_END_POINT}category`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setproductCategoryList(data.data.Items);
      });
  };

  /* Function to get variant types */

  const getVariantType = async (variantTypeId) => {
    try {
      await fetch(`${process.env.REACT_APP_PRODUCT_API_END_POINT}variants`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.data.Items);
          setvarientType(data.data.Items);
          if (data?.data?.Items?.length > 0 && variantTypeId) {
            const foundItem = data?.data?.Items?.find(
              (item) => item?.variantId === variantTypeId
            );
            if(variantField === 0){
              setTypesVar1({
                variantId: foundItem?.variantId,
                variantType: foundItem?.variantType
              });
            }
            if(variantField === 1){
              setTypesVar2({
                variantId: foundItem?.variantId,
                variantType: foundItem?.variantType
              });
            }
            
            const selectedVariants = [...variant];
            selectedVariants[variantField] = {
              valueId: '',
              value: ''
            };
            setvarientProduct({ ...varientProduct, [variantField]: [] });
            setVariant(selectedVariants);
          }
        })
        .catch((error) => {
          console.error('Error fetching variant types:', error);
          // Handle the error as needed, such as displaying an error message or logging it.
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error('Error in getVariantType:', error);
      // Handle the error as needed.
      setIsLoading(false);
    }
  };
  

  /* Function to get the suppliers list */

  const getSupplier = async () => {
    await fetch(`${process.env.REACT_APP_PRODUCT_API_END_POINT}suppliers`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setsupplierList(data.data.Items);
      });
  };

  /* Function to get variant on basis of variant id */
  const getVariants = async (id, number, setDefault, variantValudId) => {
    console.log(id, 'id');
    let variantId = encodeURIComponent(id);
    await fetch(
      `${process.env.REACT_APP_PRODUCT_API_END_POINT}variant-values/getVariantValues?variantId=${variantId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "dafa asd fasd fas df")
        setvarientProduct({ ...varientProduct, [number]: data?.data?.Items });
        let defaultArr = [...variant];
        if (setDefault === 'setDefault') {
          defaultArr[number] = {
            valueId: data?.data?.Items[0]?.sortKey,
            value: data?.data?.Items[0]?.value
          };
          setVariant(defaultArr);
        }
        const typesVarArray = [typesVar1, typesVar2]; // Add more if needed

        if (typesVarArray[number]?.variantId === id && variantValudId) {
          const newData = data?.data?.Items?.find((item) => variantValudId === item?.sortKey);
        console.log(newData, "newDatanewData")
          if (newData) {
            defaultArr[number] = {
              valueId: newData.sortKey,
              value: newData.value
            };
            setVariant([...defaultArr]); // Use spread operator to create a new array and trigger re-render
          }
        }
      });
  };

  /* Functions to validate Add products fields validation and setting value */

  /* Function to set manufacturer product name setting value */

  const manufacturerProductNameValidate = (manufacturerProductName) => {
    if (!manufacturerProductName) {
      setIsFieldUpdated(true);
      setmanufacturerProductName('');
      // setmanufacturerProductNameError('Manufacturer product name is required');
    } else {
      setIsFieldUpdated(true);
      setmanufacturerProductNameError('');

      setmanufacturerProductName(manufacturerProductName);
    }
  };

  /* Function for validation of Manufacturer  */

  const manufacturervalidate = (manufacturer) => {
    let manufacturerPattern = /[a-zA-Z ]/;
    // set field updated to true

    if (!manufacturer) {
      setIsFieldUpdated(true);
      // setmanufacturerError('Manufacturer is required');
      setmanufacturer('');
    } else if (!manufacturerPattern.test(manufacturer)) {
      setIsFieldUpdated(true);
      setmanufacturerError('Manufacturer should be text');
      setmanufacturer(manufacturer);
    } else {
      setIsFieldUpdated(true);
      setmanufacturerError('');
      setmanufacturer(manufacturer);
    }
  };

  /* Function for setting value of manufacturer url */
  /* const isValidHttpUrl = (str)  => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  } */

  const manufacturerUrlvalidate = (manufacturerUrl) => {
    // if (!manufacturerUrl) {
    //   setIsFieldUpdated(true);
    //   setmanufacturerUrlError('Manufacturer url is required');
    // } else
    const URL_REGEX = /^(http|https):\/\/([a-z]*\.)?[a-z]*\.[a-z]{2,}(\/)?$/;

    if (manufacturerUrl && !URL_REGEX.test(manufacturerUrl)) {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('Manufacturer url is not valid');
      setIsManufacturerUrlValid(true);
      setmanufacturerUrl(manufacturerUrl);
    } else {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('');
      setmanufacturerUrl(manufacturerUrl);
      setIsManufacturerUrlValid(false);
    }
  };
  /* Function for validation of Zoom room product name  */

  const zoomRoomProductNamevalidate = (zoomRoomProductName) => {
    if (!zoomRoomProductName) {
      setzoomRoomProductName(
        zoomRoomProductName !== '' ? '' : zoomRoomProductName
      );
      setIsFieldUpdated(true);

      // setzoomRoomProductNameError('Zoom room product name is requried');
    } else {
      setIsFieldUpdated(true);
      setzoomRoomProductNameError('');
      setzoomRoomProductName(zoomRoomProductName);
    }
  };

  /* Function for setting value of product category validate */

  const productCategoryvalidate = (productCategory) => {
    if (!productCategory) {
      setIsFieldUpdated(true);
      setproductCategoryError(false);
      setproductCategoryId('');
    } else {
      setIsFieldUpdated(true);
      setproductCategoryError(false);
      setproductCategoryId(productCategory);
    }
  };

  /* Function for state drop-down of varient type */

  const varientTypeValidate = (varient, number) => {
    const variantObj = [...variant];
    if (!varient?.variantId) {
      setIsFieldUpdated(true);
      setvarientTypeError(false);
    } else {
      variantObj[number] = {
        valueId: '',
        value: ''
      };
      setIsFieldUpdated(true);
      // setvarientTypeError('');
      setvarientTypeError(false);
      setvariantDisabled(false);
      getVariants(varient?.variantId, number, 'setDefault');
      const typesVarArray = [setTypesVar1, setTypesVar2]; // Add more if needed

      if (typesVarArray[number]) {
        typesVarArray[number]({
          variantId: varient?.variantId,
          variantType: varient?.variantType
        });
      }
      setVariant(variantObj);
      // setvarientType(varientType);
      setvariantId(varientType);
    }
  };

  /* Function for setting varient */

  const varientSet = (varientValue, number) => {
    let variantObj = [...variant];

    if (!varientValue?.sortKey) {
      setIsFieldUpdated(true);
      // setvarientError('Varient is requried');
      // setvarientError(true);
    } else {
      variantObj[number] = {
        valueId: varientValue?.sortKey,
        value: varientValue?.value
      };
      setVariant(variantObj);
      setIsFieldUpdated(false);
      setvalueId(varientValue);
      setvarientError(false);
    }
  };

  /* Function to set value of price  */

  const priceSet = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, '');
    // Allow only two digits after the decimal point
    const decimalIndex = input.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setprice(input);
  };

  /* Function to set value of supplier */

  const supplierValidate = (supplier) => {
    if (!supplier) {
      setIsFieldUpdated(true);
      // setsupplierError('Supplier is required');
      setsupplierError(true);
    } else {
      setIsFieldUpdated(true);
      // setsupplierError('');
      setsupplierError(false);
      // setsupplierList(supplier);
      setsupplierId(supplier);
      // console.log(supplier);
    }
  };

  /* Function to set value of selling point */

  const sellingpointSet = (sellingPoint) => {
    // if (!sellingPoint) {
    //   setIsFieldUpdated(true);
    //   setsellingPointError('Selling point is error');
    //   setsellingPoint("");
    // } else {
    setIsFieldUpdated(true);
    setsellingPointError('');
    setsellingPoint(sellingPoint);
    // }
  };

  /* Function to set value of product description */

  const productDescriptionSet = (productDescription) => {
    // if (!productDescription) {
    //   setIsFieldUpdated(true);
    //   setproductDescriptionError('Product description is required');
    // } else {
    setIsFieldUpdated(true);
    setproductDescriptionError('');
    setproductDescription(productDescription);
    // }
  };

  /* Function to Sku of product */

  // const SkuProductSet = (event) => {

  //   const keyCode = event.keyCode || event.which;
  //   const isValidKey = keyCode >= 48 && keyCode <= 57; // Check if the key code is for a number (0-9)

  //   if (!isValidKey) {
  //     event.preventDefault(); // Prevent the default behavior for non-numeric keys
  //   }
  //   else {
  //     const valueEntered = isValidKey ? event.target.value + String.fromCharCode(keyCode) : '';
  //     if (valueEntered.length < 7) {
  //       setSkuProduct(valueEntered)
  //     }
  //     else {
  //       event.preventDefault();
  //     }

  //   }

  //   if (keyCode === 8) {
  //     // Backspace key pressed
  //     const updatedSkuProduct = skuProduct.slice(0, -1);
  //     setSkuProduct(updatedSkuProduct);
  //   }
  // }

  /* Function for validation of SKU Code  */

  const skuCodeValidate = (skuCode) => {
    // Remove all non-digit characters
    // const skuCodePattern = skuCode.replace(/\D/g, '');

    // if (!skuCode) {
    //   setIsFieldUpdated(true);
    //   // setSkuCodeError('SKU Code is required');
    //   setSkuCode('');
    // } else if (skuCode !== skuCodePattern) {
    //   setIsFieldUpdated(true);
    //   setSkuCodeError('SKU code should be a number');
    //   setSkuCode(skuCodePattern); // Set the SKU code to the filtered version with only numbers
    // } else {
    //   setIsFieldUpdated(true);
    //   setSkuCodeError('');
    //   setSkuCode(skuCodePattern); // Set the SKU code to the filtered version with only numbers
    // }
    setIsFieldUpdated(true);
    setSkuCodeError('');
    // checkSkuExist(skuCode);

    setSkuCode(skuCode);
  };

  /* Function to set the expiry date */

  const expiryDateSet = (expiryDate) => {
    if (!expiryDate) {
      setIsFieldUpdated(true);
      // setexpiryDateError('Expiry date is required');
      setexpiryDate('');
    } else {
      // const formattedDate = expiryDate.toLocaleDateString('en-US', {
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit'
      // });

      // let newDate = formattedDate.replace(/\//g, '-');
      // setIsFieldUpdated(true);
      // setexpiryDateError('');
      // setexpiryDate(newDate);
      let newDate;
      setexpiryDateError('');
      setexpiryDate(expiryDate);
      // let objectDate = new Date(expiryDate);
      // // setStartdateObj(objectDate);

      // let date = objectDate.getDate();
      // if (date < 10) {
      //   newDate = '0' + date;
      // } else {
      //   newDate = date;
      // }
      // let month = objectDate.getMonth() + 1;
      // // let newMonth = "0" + month;
      // let newMonth;
      // newMonth = month < 10 ? '0' + month : month;
      // let year = objectDate.getFullYear();
      // let finalDate = newMonth + '-' + newDate + '-' + year;
      // setexpiryDate(finalDate);
      setIsFieldUpdated(true);
    }
  };

  /* Json for add product  */
  const addProducts = async () => {
    const variantsArr = variant?.map((item, i) => ({
      ...item,
      variantId: i === 0 ? typesVar1?.variantId : typesVar2?.variantId ?? '',
      variantType:
        i === 0 ? typesVar1?.variantType : typesVar2?.variantType ?? ''
    }));
    // Now show the loader
    setIsLoading(true);
    console.log(expiryDate, 'expiryDate-----');
    const dataAddProduct = JSON.stringify({
      // franchiseeId: franchiseeId,
      manufacturer: manufacturer,
      mfgName: manufacturerProductName,
      // mfgUrl: manufacturerUrl,
      fName: zoomRoomProductName,
      categoryId: productCategoryId,
      // variantId: variantId,
      // supplierId: supplierId,
      // valueId: valueId,
      productDescription: productDescription,
      productImage: '',
      productVideo: '',
      price: price?.toString(),
      sellingPoints: sellingPoint,
      isRequired: isRequired,
      isBestSeller: isBestSeller,
      taxExempt: istaxExempt,
      isDiscontinued: isDiscontinued,
      sku: skuCode,
      variants: variantsArr,
      // isActive: true,
      // expiryDate: expiryDate || '06-15-2024',
      createdBy: userId
    });

    try {
      await fetch(`${process.env.REACT_APP_PRODUCT_API_END_POINT}products`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },

        body: dataAddProduct
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.data.productId);
          if (data?.statusCode && data?.data?.productId) {
            getAutoGeneratedSkuCode();
            // if (imgObj) formData.append('media', imgObj);
            if (multipleImage && multipleImage.length > 0) {
              for (const image of multipleImage) {
                console.log(image, 'formData');

                const formData = new FormData();
                formData.append('productId', data.data.productId);
                formData.append('media', image.file);

                addProductMedia(formData);
              }
            } else {
              setIsLoading(false);
              setIsUpdated(true);
              setOpen(true);
              // setopenAddproduct(false);
              // setopenProductPopup(false);
              // refreshData();
              clear();
            }
            // Method to add product media
          }
        });
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong ');
    }
  };
  console.log(multipleImage, 'multipleImagemultipleImagemultipleImage');
  // Add product media
  const addProductMedia = async (formData) => {
    setIsLoading(true);
    await fetch(
      `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/addProductMedia`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          // 'content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        },
        body: formData
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsUpdated(true);
        setOpen(true);
        setIsLoading(false);
        clear();
      })
      .catch((error) => {
        console.error('Error during fetch operation:', error);
        setIsLoading(false);
      });
  };
  

  /* Function call when user going to  submit Add product  form */

  // eslint-disable-next-line consistent-return
  const addProduct = (e) => {
    // eslint-disable-next-line no-debugger
    // handleOpenVariant();

    e.preventDefault();
    e.stopPropagation();

    if (!zoomRoomProductName) {
      setzoomRoomProductNameError('Zoom Room product name is required');
    }

    if (!manufacturerProductName) {
      setmanufacturerProductNameError('Manufacturer product name is required');
    }
    if (!productCategoryId) {
      setproductCategoryError(true);
    }

    if (!skuCode) {
      setSkuCodeError('SKU Code is required');
    }

    if (!manufacturer) {
      setmanufacturerError('Manufacturer is required');
    }
    // if (!variantId) {
    //   // setvariantError('Variant type is required');
    //   setvarientTypeError(true);
    // }
    // if (!valueId) {
    //   // setvalueError('Variant is required');
    //   setvarientError(true);
    // }
    // if (!supplierId) {
    //   // setsupplierError('Supplier is required');
    //   setsupplierError(true);
    // }
    // if (!price) {
    //   setpriceError('Price is required');
    // }

    // if (!expiryDate) {
    //   setexpiryDateError('Expiry date is required');
    // }

    if (
      manufacturerProductName &&
      zoomRoomProductName &&
      productCategoryId &&
      skuCode &&
      manufacturer
      // variantId &&
      // valueId &&
      // supplierId &&
      // price &&
      // !isManufacturerUrlValid
      // expiryDate
    ) {
      // return 0;
      addProducts();
    } // else {
    // addProducts();
    // }
    // Now open the dialog box to update if any of the field is updated
    // if (isFieldUpdated) handleClickOpen();
  };

  const removeImage = (idToRemove) => {
    const updatedImages = multipleImage.filter(
      (image) => image.id !== idToRemove
    );
    setMultipleImage(updatedImages);
  };

  // /* Function to get variant on basis of variant id */
  // const getSku = async (id) => {
  //   console.log(id, 'id');
  //   let variantId = encodeURIComponent(id);
  //   await fetch(
  //     `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/maxSku`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*'
  //       }
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setSkuCode(data.data.sku);
  //     });
  // };

  // Method to check the email already exist or not
  // const checkSkuExist = async (sku) => {
  //   const data = {
  //     sku: sku
  //   };
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/checkSkuExists`,
  //       data
  //     );
  //     console.log(response, 'responseresponseresponse');
  //     // Process the response here
  //     if (response.data.data.exists) {
  //       let msg = 'SKU code already exists';
  //       // Now email is already exist
  //       setSkuCodeError(msg);
  //       setskuCodeValidateApi(true);
  //     } else {
  //       // Now email is not exist

  //       setSkuCodeError('');
  //       setskuCodeValidateApi(false);
  //     }
  //   } catch (error) {
  //     console.error('Error checking sku existence:', error);
  //   }
  // };

  return (
    <>
      {/* , paddingRight: '10px', paddingLeft: '58px' */}
      {/* show loaded element */}
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Grid sx={{ padding: '0 2rem', mt: 1 }}>
        <Box className="Main-container">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  onClick={() => handleCloseAddProduct()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>

                <Typography className="header-blue-32">Add Product</Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {' '}
                  <Button
                    sx={{ mt: 1 }}
                    type="button"
                    variant="contained"
                    className="common-button"
                    onClick={addProduct}
                  >
                    <Typography className="next-button-text">Save</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className="container-text-field">
            <Card
              sx={{
                backgroundColor: '#FFFFFF'
              }}
            >
              <CardContent className="pr-0 pl-0">
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    {/* <Card className="Main-card product-details-img d-flex align-items-center justify-content-center">
                      <CardContent className="card-image-content">
                        <CardMedia
                          component="img"
                          alt="product-icon"
                          image={
                            multipleImage.length > 0
                              ? multipleImage[multipleImage.length - 1]
                                  .previewURL
                              : '/static/images/products/ProductImage.png'
                          }
                        />
                      </CardContent>
                    </Card> */}

                    <Box className="product-details-img align-center relative Main-card ">
                      {multipleImage && multipleImage.length > 0 ? (
                        <div>
                          {/* <Slider {...settings}>
                                  {multipleImages.map((media, index) => (
                                    <div key={index} style={{}}>
                                      <img
                                        className=" product-img"
                                        src={media.media}
                                        alt="product-icon"
                                      />
                                      <Box
                                        class="delete-icon"
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-end'
                                        }}
                                      >
                                        <DeleteIcon onClick={deleteMedia} />
                                      </Box>
                                    </div>
                                  ))}
                                </Slider> */}
                          <ImageSlider images={productImage} />
                        </div>
                      ) : (
                        <img
                          src="/static/images/products/ProductImage.png"
                          alt="product-icon"
                          className="product-img"
                        />
                      )}
                    </Box>
                    <Box mt={2}>
                      <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                        Upload Product Image
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        id="select-product-add-file"
                        style={{ display: 'none' }}
                        onChange={(event) => handleFileInput(event)}
                      />
                      <label htmlFor="select-product-add-file">
                        <Button
                          type="button"
                          variant="contained"
                          component="span"
                          className="upload-image image-with-txt common-button"
                        >
                          <Typography className="upload-text">
                            Upload
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                    <Box className="card-image-carousel">
                      {/* Render the uploaded images */}
                      {multipleImage.map((image, index) => (
                        <div className="single-uploaded-images" key={image.id}>
                          <img src={image.previewURL} alt="Uploaded" />
                          <div
                            className="remove-image text-blue"
                            onClick={() => {
                              removeImage(image.id);
                              setImageError('');
                            }}
                          >
                            x
                          </div>
                        </div>
                      ))}
                    </Box>
                    <Box sx={{ float: 'left' }}>
                      {imageError && (
                        <FormHelperText className="error-message">
                          {imageError}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={2} component="form" id="form-add">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="ZOOM ROOM PRODUCT NAME"
                          autocomplete="ZOOM ROOM PRODUCT NAME"
                          label="ZOOM ROOM PRODUCT NAME"
                          placeholder="ZOOM ROOM PRODUCT NAME"
                          className="location-field input-field-styling required-field"
                          error={Boolean(zoomRoomProductNameError)}
                          helperText={zoomRoomProductNameError}
                          onChange={(e) =>
                            zoomRoomProductNamevalidate(e.target.value)
                          }
                          value={zoomRoomProductName}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="MANUFACTURER PRODUCT NAME"
                          autocomplete="MANUFACTURER PRODUCT NAME"
                          label="MANUFACTURER PRODUCT NAME"
                          placeholder="MANUFACTURER PRODUCT NAME"
                          id="locationId"
                          value={manufacturerProductName}
                          className="location-field input-field-styling required-field "
                          error={Boolean(manufacturerProductNameError)}
                          helperText={manufacturerProductNameError}
                          onChange={(e) =>
                            manufacturerProductNameValidate(e.target.value)
                          }
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="SKU CODE"
                          autocomplete="SKU CODE"
                          label="SKU CODE"
                          className="location-field required-field input-field-styling"
                          error={Boolean(skuCodeError)}
                          helperText={skuCodeError}
                          value={skuCode}
                          placeholder="SKU CODE"
                          onChange={(e) => skuCodeValidate(e.target.value)}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                          disabled
                          // onKeyUp={(e) => checkSkuExist(e.target)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="MANUFACTURER"
                          autocomplete="MANUFACTURER"
                          label="MANUFACTURER"
                          className="location-field required-field input-field-styling"
                          error={Boolean(manufacturerError)}
                          helperText={manufacturerError}
                          value={manufacturer}
                          placeholder="MANUFACTURER"
                          onChange={(e) => manufacturervalidate(e.target.value)}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="MANUFACTURER URL"
                          autocomplete="MANUFACTURER URL"
                          label="MANUFACTURER URL"
                          className="field-top-margin-edit-location location-field input-field-styling"
                          value={manufacturerUrl}
                          error={Boolean(manufacturerUrlError)}
                          helperText={manufacturerUrlError}
                          onChange={(e) =>
                            manufacturerUrlvalidate(e.target.value)
                          }
                          placeholder="MANUFACTURER URL"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid> */}

                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling required-field"
                          fullWidth
                          error={productCategoryError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling required-label"
                          >
                            PRODUCT CATEGORY
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="PRODUCT CATEGORY"
                            className="input-field-styling"
                            value={productCategoryId}
                            onChange={(e) =>
                              productCategoryvalidate(e.target.value)
                            }
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                            //   options={product.map((option) => option.categoryName)}
                          >
                            {productCategoryList.length > 0 &&
                              productCategoryList.map((option) => (
                                <MenuItem
                                  key={option.categoryId}
                                  value={option.categoryId}
                                >
                                  {option.categoryName}
                                </MenuItem>
                              ))}
                          </Select>
                          {productCategoryError && (
                            <FormHelperText>
                              Please select product category
                            </FormHelperText>
                          )}
                        </FormControl>
                        {/* <FormControl
                            className="field-top-margin-edit-location location-field required-field"
                            fullWidth
                            error={productCategoryError}
                          >
                            <Autocomplete
                              classes={autoCompleteClasses}
                              // value={getCity || ''}
                              // value={filters.categoryName ?? ''}
                              onChange={(event, newValue) => {
                                productCategoryvalidate(newValue);
                              }}
                              // onInputChange={(event, newInputValue) => {
                              //   handleCategoryChange(newInputValue);
                              // }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              className="status-field field-label-css"
                              options={productCategoryList.map((option) => option.categoryName)}
                              sx={{
                                width: '100%',
                                borderRadius: '19px !important'
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="product_category input-field-dropdown-styling required-label"
                                  label="PRODUCT CATEGORY"
                                  sx={{
                                    fontSize: '16px !important',
                                    fontWeight: '600 !important',
                                  }}
                                />
                              )}
                            />
                            {productCategoryError && (
                              <FormHelperText>
                                Please select product category
                              </FormHelperText>
                            )}
                          </FormControl> */}
                        {isFranchisor === 'true' && (
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px'
                              // display: 'none'
                            }}
                            className="add-new-category align-right"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                addNewCategory();
                              }}
                            >
                              +Add Category
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} />

                      {/* DIVIDER LINE ABOVE VARIANTS */}
                      <Grid item xs={12} sm={12}>
                        <Divider style={{ backgroundColor: '#003087' }} />
                      </Grid>

                      {/* FIRST VARIANT ROW */}
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling "
                          fullWidth
                          error={varientError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling"
                          >
                            VARIANT TYPE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIANT TYPE"
                            className="input-field-styling"
                            onChange={(e) =>
                              varientTypeValidate(e.target.value, 0)
                            }
                            renderValue={(selected) => selected?.variantType}
                            value={typesVar1}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientType?.length > 0 &&
                              varientType
                                ?.filter(
                                  (option) =>
                                    !typesVar2?.variantId ||
                                    option?.variantId !== typesVar2?.variantId
                                )
                                ?.map((option) => {
                                  return (
                                    <MenuItem value={option}>
                                      {option?.variantType}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                          {varientTypeError && (
                            <FormHelperText>
                              Please select variant type
                            </FormHelperText>
                          )}
                        </FormControl>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px'
                              // display: 'none'
                            }}
                            className="add-new-variant align-right"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                addNewVariant(0);
                              }}
                            >
                              +Add Variant Type
                            </span>
                          </Typography>

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling  "
                          fullWidth
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling "
                          >
                            VARIANT
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIENT"
                            className="input-field-styling"
                            onChange={(e) => varientSet(e.target.value, 0)}
                            value={variant[0]}
                            renderValue={(selected) => selected?.value}
                            disabled={varientProduct[0]?.length === 0}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientProduct[0]?.length > 0 &&
                              varientProduct[0]?.map((option) => (
                                <MenuItem value={option}>
                                  {option?.value}
                                </MenuItem>
                              ))}
                          </Select>
                          {varientError && (
                            <FormHelperText>
                              Please select variant
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px'
                              // display: 'none'
                            }}
                            className="add-new-variant align-right"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                addVariant(0);
                              }}
                            >
                              +Add Variant
                            </span>
                          </Typography>
                        
                      </Grid>
                      {/* SECOND VARIANT ROW */}
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling "
                          fullWidth
                          error={varientError}
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling"
                          >
                            VARIANT TYPE
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIANT TYPE"
                            className="input-field-styling"
                            onChange={(e) =>
                              varientTypeValidate(e.target.value, 1)
                            }
                            value={typesVar2}
                            renderValue={(selected) => selected?.variantType}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientType?.length > 0 &&
                              varientType
                                ?.filter(
                                  (option) =>
                                    !typesVar1?.variantId ||
                                    option?.variantId !== typesVar1?.variantId
                                )
                                ?.map((option) => {
                                  return (
                                    <MenuItem value={option}>
                                      {option?.variantType}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                          {varientTypeError && (
                            <FormHelperText>
                              Please select variant type
                            </FormHelperText>
                          )}
                        </FormControl>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px'
                              // display: 'none'
                            }}
                            className="add-new-variant align-right"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                addNewVariant(1);
                              }}
                            >
                              +Add Variant Type
                            </span>
                          </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling  "
                          fullWidth
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling "
                          >
                            VARIANT
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="VARIENT"
                            className="input-field-styling"
                            onChange={(e) => varientSet(e.target.value, 1)}
                            value={variant[1]}
                            renderValue={(selected) => selected?.value}
                            disabled={varientProduct[1]?.length === 0}
                            MenuProps={{
                              PaperProps: { style: { maxHeight: 200 } }
                            }}
                          >
                            {varientProduct[1]?.length > 0 &&
                              varientProduct[1]?.map((option) => (
                                <MenuItem value={option}>
                                  {option.value}
                                </MenuItem>
                              ))}
                          </Select>
                          {varientError && (
                            <FormHelperText>
                              Please select variant
                            </FormHelperText>
                          )}
                        </FormControl>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: '#003087',
                              textDecoration: 'underline',
                              marginRight: '5px'
                              // display: 'none'
                            }}
                            className="add-new-variant align-right"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                addVariant(1);
                              }}
                            >
                              +Add Variant
                            </span>
                          </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Divider style={{ backgroundColor: '#003087' }} />
                      </Grid>

                      {/* <Grid item xs={12} sm={6}>
                        <FormControl
                          className="select-drop-down-label-styling "
                          fullWidth
                        >
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className="input-field-dropdown-styling "
                          >
                            SUPPLIER
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="SUPPLIER"
                            className="input-field-styling"
                            onChange={(e) => supplierValidate(e.target.value)}
                          >
                            {supplierList &&
                              supplierList.map((option) => (
                                <MenuItem value={option.supplierId}>
                                  {option.supplierName}
                                </MenuItem>
                              ))}
                          </Select>
                          {supplierError && (
                            <FormHelperText>
                              Please select supplier
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}
                      {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        className="form-datepicker-container"
                      > */}
                      {/* <TextField
                        fullWidth
                        name="Expiry Date"
                        label="EXPIRY DATE"
                        className="field-top-margin-edit-location location-field"
                        placeholder="Expiry Date"
                        value={expiryDate}
                        type="date"
           
                        onChange={(e) => expiryDateSet(e.target.value)}
                        InputLabelProps={{
                          className: 'label-style-create-franchisee',
                          shrink: true
                        }}
                        InputProps={{}}
                      /> */}
                      {/* <CustomDatePicker
                          label="EXPIRY DATE"
                          onChange={expiryDateSet}
                        /> */}
                      {/* {openingDateError && (
                        <FormHelperText>
                          Opening Date is required
                        </FormHelperText>
                      )} */}
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="EXPIRY DATE"
                              value={expiryDate}
                              onChange={expiryDateSet}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // error={Boolean(expiryDateError)}
                                  // helperText={expiryDateError}
                                  fullWidth
                                  className="field-top-margin-edit-location location-field required-fiel"
                                  InputLabelProps={{
                                    className: 'label-style-create-franchisee required-labe'
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider> */}
                      {/* </Grid> */}

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="MSRP"
                          autocomplete="MSRP"
                          label="MSRP"
                          className="field-top-margin-edit-locatio location-field input-field-styling"
                          value={price}
                          // error={Boolean(priceError)}
                          // helperText={priceError}
                          onChange={priceSet}
                          placeholder="MSRP"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px'
                                  }}
                                >
                                  $
                                </span>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} />

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          multiline
                          id="my-textarea"
                          name="PRODUCT Description"
                          autocomplete="PRODUCT Description"
                          label="PRODUCT DESCRIPTION"
                          placeholder="Notes here . . . "
                          variant="outlined"
                          value={productDescription}
                          className="location-description field-top-margin-edit-location"
                          error={Boolean(productDescriptionError)}
                          helperText={productDescriptionError}
                          onChange={(e) =>
                            productDescriptionSet(e.target.value)
                          }
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{
                            style: { color: 'black' }, // Set the text color to black here
                            rows: 6
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <TextField
                            fullWidth
                            name="Sku"
                            autocomplete="Sku"
                            label="SKU"
                            type="text"

                            className="field-top-margin-edit-location location-field"
                            // error={Boolean(priceError)}
                            // helperText={priceError}
                            // onChange={(e) =>  SkuProductSet(e.target.value)}

                            onKeyDown={SkuProductSet}
                            value={skuProduct}
                            placeholder="SKU"
                            InputLabelProps={{
                              className:
                                'label-style-create-franchisee edit-address-label'
                            }}
                            inputProps={{
                              maxLength: 6
                            }}
                          /> */}
                        <TextField
                          fullWidth
                          multiline
                          id="my-textarea"
                          name="Selling point"
                          autocomplete="Selling point"
                          label="SELLING POINT"
                          placeholder="Notes here . . . "
                          variant="outlined"
                          value={sellingPoint}
                          className="location-description field-top-margin-edit-location "
                          // error={Boolean(productDescriptionError)}
                          // helperText={productDescriptionError}
                          onChange={(e) => sellingpointSet(e.target.value)}
                          InputLabelProps={{
                            className: 'label-style-create-franchisee'
                          }}
                          InputProps={{
                            style: { color: 'black' }, // Set the text color to black here
                            rows: 6
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                          
                        </Grid> */}

                      {/* <Grid container sx={{ marginTop: '8px' }} xs={12} > */}
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isRequired}
                              className="check-box-span"
                              color="default"
                              onChange={(e) => setisRequired(e.target.checked)}
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Required
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isBestSeller}
                              className="check-box-span"
                              color="default"
                              onChange={(e) =>
                                setisBestSeller(e.target.checked)
                              }
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Bestseller
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={istaxExempt}
                              className="check-box-span"
                              color="default"
                              onChange={(e) => setistaxExempt(e.target.checked)}
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Tax Exempted
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isDiscontinued}
                              className="check-box-span"
                              color="default"
                              onChange={(e) =>
                                setisDiscontinued(e.target.checked)
                              }
                              sx={{
                                color: '#003087',
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Discontinued
                            </Typography>
                          }
                        />
                      </Grid>
                      {/* </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {showError && (
                      <Box className="error-message">{errorMessage}</Box>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>

      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Product has been added successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : ' Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Create Variant dialog */}
      {/* {openAddVariant && (<CustomizedDialogs />)} */}
      <CreateVariantTypeModal
        openAddVarientType={openAddVarientType}
        handleCloseVariant={handleCloseVariant}
        setvarientTypeName={setvarientTypeName}
        varientTypeName={varientTypeName}
        handleCreateVariantType={handleCreateVariantType}
        createVariantTypeErrors= {createVariantTypeErrors}
        setCreateVariantTypeErrors = {setCreateVariantTypeErrors}
      />

      {/* Create Category dialog */}
      <CreateCategoryModal
        openAddCategory={openAddCategory}
        handleCloseVariant={handleCloseVariant}
        setcategoryName={setcategoryName}
        categoryName={categoryName}
        categoryDescription={categoryDescription}
        setcategoryDescription={setcategoryDescription}
        handleCreateCategory={handleCreateCategory}
        createCategoryErrors={createCategoryErrors}
        setCreateCategoryErrors = {setCreateCategoryErrors}
      />

      {/* Create Variant dialog */}
      <CreateVariantModal
        openAddVariant={openAddVariant}
        handleCloseVariant={handleCloseVariant}
        handleCreateVariant={handleCreateVariant}
        varientType={varientType}
        variantName={variantName}
        setvariantName={setvariantName}
        handleVariantTypeChange={handleVariantTypeChange}
        variantTypeId={variantTypeId}
        createVariantErrors={createVariantErrors}
        setCreateVariantErrors={setCreateVariantErrors}
      />
    </>
  );
}

export default AddProduct;
