/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,

} from '@mui/material';
import styled from 'styled-components';

export const DynamicDropdown = ({
                                id,
                                onChange,
                                value,
                                defaultValue,
                                label,
                                options,
                                placeholder,
                                fullWidth,
                                required,
                                name,
                                error,
                                helperText,
                                multiple
                              }) => {
  const handleChange = (event) => {
    let value = event.target.value;
    console.log('value', value);
    if (onChange) {
      if (multiple) {
        onChange(name, typeof value === 'string' ? value.split(',') : value);
      } else {
        onChange(name, value);
      }
    }
  };

  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {label && (
        <InputLabel id={id} className="input-field-dropdown-styling">
          {label}
        </InputLabel>
      )}
      <StyledSelect
        id={id}
        fullWidth={fullWidth}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        displayEmpty
        label={label}
        required={required}
        multiple={multiple}
        inputProps={{
          'aria-label': label || '',
          placeholder: placeholder || ''
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const StyledSelect = styled(Select)(() => ({
  //   background: theme.palette.background.default,
  borderRadius: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '30px',
  color: '#525252',
  '& .MuiSelect-select': {
    paddingLeft: '10px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSelect-icon': {
    color: '#003087'
  }
}));
