// import { } from 'react';
import { React } from 'react';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';
import PricingOptionsList from './PricingOptionList';
import Header from '../../../components/Header';
import { useState } from 'react';
import { Box } from '@mui/material';

const FeaturedItem = () => {
  const [openAddPricingModal, setAddPricingModal] = useState(false);
  const [openAddPricing, setOpenAddPricing] = useState(false);
  const [showScreen, setShowScreen] = useState(0);
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Pricing Options');
  const handleButtonClick = () => {
    //  setOpenAddPricing(true);
    setOpenAddPricing(true);
    setAddPricingModal(true);
  };

  const buttons = [
    {
      label: 'New Pricing Options',
      onClick: handleButtonClick,
      theam: true
    }
  ];
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = 'Pricing Options';
  const title = GlobalData[0].title;
  // const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
  return (
    <>
      <Helmet>
        <title>Pricing Options | {title}</title>
      </Helmet>
      <Box sx={{ paddingX: '30px' }}>
        {showScreen === 0 && (
          <Header title="Pricing Options" buttons={buttons} />
        )}
      </Box>
      <PricingOptionsList
        openAddPricingModal={openAddPricingModal}
        openAddPricing={openAddPricing}
        setOpenAddPricing={setOpenAddPricing}
        showScreen={showScreen}
        setShowScreen={setShowScreen}
        setAddPricingModal={setAddPricingModal}
      />
    </>
  );
};
export default FeaturedItem;
