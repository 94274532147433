import React from 'react';
import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  TextField,
  Slide
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CreateVariantTypeModal = ({
  openAddVarientType,
  handleCloseVariant,
  setvarientTypeName,
  varientTypeName,
  handleCreateVariantType,
  createVariantTypeErrors,
  setCreateVariantTypeErrors
}) => {


  const handleClose = () => {
    setCreateVariantTypeErrors({
      varientTypeName: '',
    });
    handleCloseVariant();
  };

  const handleSubmit = () => {
    const error = !varientTypeName ;
  
    setCreateVariantTypeErrors({
      varientTypeName: !varientTypeName ? "Varient Type name is required." : "",
    });
  
    return error ? null : handleCreateVariantType();
  };
  return (
    <Dialog
      open={openAddVarientType}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="alert-title align-center">
        {'Add Variant Type'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
        >
          <Box
            display="flex"
            justifyContent="center"
            direction="column"
            width={300}
            mt={1}
            mb={2}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="VARIANT TYPE"
                  name="variantTypeName"
                  className="location-field input-field-styling "
                  onChange={
                    (e) => {
                      setvarientTypeName(e.target.value)
                      setCreateVariantTypeErrors({
                        varientTypeName: "",
                      });
                    }
                  }
                  helperText={createVariantTypeErrors?.varientTypeName}
                  required
                  value={varientTypeName}
                  variant="outlined"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 text-black no-hover"
          onClick={() => handleClose()}
        >
          {'Close'}
        </Button>
        <Divider className="vertical-divider" />
        <Button
          className="font-17 no-hover"
          onClick={() => handleSubmit()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateVariantTypeModal;
