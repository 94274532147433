import React, { useState, useEffect } from 'react';
import {
  DynamicButton,
  DynamicDropdown,
  DynamicModal
} from '../../../../components/Checkout';
import { getLocalData } from '../../../../utils';
import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Box
} from '@mui/material';
import { DynamicTextField } from '../../../../components/GlobalComponents';
import { ArrowLeft } from '../../../../assets/SVG/SvgIcons';
import { ApiService } from 'src/services/ApiService';
import Loader from 'src/components/Loader';

export const PaymentMethod = ({
  selected,
  setSelected,
  handleNext,
  setSuccess,
  setActiveStep
}) => {
  const handleCardChange = (type, value) => {
    setSelected({ ...selected, [type]: value });
  };
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State to control the dialog
  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const handleGoBack = () => {
    setIsDialogOpen(true); // Open the dialog when Go Back is clicked
  };
  const handleCloseDialog = (value) => {
    setIsDialogOpen(false);
    if (value === 'discard') {
      setActiveStep(0);
      setSelected({
        items: [],
        values: []
      });
    } else if (value === 'goBack') {
      setActiveStep(3);
    }
  };

  const [successOrder, setSuccessOrder] = useState(false);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');

  // DATE FORMATTING DO NOT REMOVE
  // const currentDate = new Date();
  // const formattedDate = currentDate
  //   .toLocaleDateString('en-US', {
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit'
  //   })
  //   .replace(/\//g, '-');

  const payload = {
    locationId: locationId,
    clientId: selected?.client?.sortKey || '',
    clientName: selected?.client?.firstName || '',
    guestClient: selected?.guestClient || false,
    createdBy: userId || '',
    queueId: selected?.queueId,
    petId: selected?.pet?.sortKey || '',
    petName: selected?.pet?.firstName || '',
    squareLocationId: 'LMJ551VXH2G09',
    sellerCustomerId: 'HDDBXF4Q77SAJC6HHVMY2YE4MG'
  };

  useEffect(() => {
    if (successOrder) {
      setSuccess(successOrder);
    }
    if (error) {
      setOpen(true);
    }
  }, [successOrder, error]);

  const handleConfirm = async () => {
    setLoading(true);
    let newItemsArr = payload?.items?.map?.((item) => {
      delete item.promoCode;
      return item;
    });
    let payloadData = { ...payload, items: newItemsArr };
    if (payload.clientId === '') {
      delete payloadData.clientId;
      delete payloadData.clientName;
    }
    if (!selected?.pet) {
      delete payloadData.petId;
      delete payloadData.petName;
    }
    // callApi('orderSummary', 'checkout/placeOrder', 'POST', payloadData);

    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/placeOrder`,
        payloadData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.statusCode === 200) {
        const orderId = response.data.ZROrderId;
        const sqaureOrderId = response.data.sqaureOrderId;
        const sellerCustomerId = response.data.sellerCustomerId;
        const squareCustomerId = response.data.squareCustomerId;
        const squareLocationId = response.data.squareLocationId;
        const sqaureOrderTotalMoney = response.data.sqaureOrderTotalMoney;

        const createPayload = {
          source: 'CASH',
          amount: Number(sqaureOrderTotalMoney),
          squareSellerCustomerId: sellerCustomerId,
          squareDeveloperCustomerId: squareCustomerId,
          squareLocationId: squareLocationId,
          squareOrderId: sqaureOrderId,
          locationId: locationId,
          orderId: orderId,
          paymentMode: 'CASH',
          cashDetails: {
            buyerSuppliedMoney: {
              amount: Number(sqaureOrderTotalMoney),
              currency: 'USD'
            },
            changeBackMoney: {
              amount: 0,
              currency: 'USD'
            }
          }
        };

        createPayment(createPayload);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccessOrder(false);
      setError(error?.data?.message || 'Order not placed something went wrong');
    }
  };

  const createPayment = async (createPayload) => {
    setLoading(true);
    try {
      let response = await ApiService.post(
        `${BASE_URL}checkout/createPayment`,
        createPayload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.statusCode === 200) {
        setSuccessOrder(true);
        setSelected({
          ...selected,
          sqaureOrderId: response.data.payment.orderId,
          squareLocationId: response.data.payment.locationId
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccessOrder(false);
      setError(error?.data?.message || 'Order not placed something went wrong');
    }
  };

  return (
    <>
      <Loader IsLoading={loading} />
      <Box
        className="back-arrow-close"
        onClick={handleGoBack}
        style={{
          padding: '10px 23px',
          borderRadius: '10px',
          textTransform: 'capitalize',
          fontWeight: '600',
          fontSize: '16px',
          background: '#fff',
          color: '#003087',
          top: '10px',
          left: '10px',
          position: 'absolute'
        }}
      >
        <ArrowLeft />
      </Box>
      <Stack
        className="paymentHead"
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="30%"
        margin="0 auto"
      >
        <Typography
          variant="h4"
          align="center"
          color="primary"
          style={{ marginBottom: '15px', fontSize: '30px' }}
        >
          Select Payment Method
        </Typography>
        <DynamicDropdown
          fullWidth
          required
          name="paymentMethod"
          value={selected?.paymentMethod || ''}
          onChange={handleCardChange}
          label={'Payment Method'}
          options={[
            { label: 'Cash', value: 'Cash' },
            { label: 'Card', value: 'Card' }
          ]}
          className="input-field-styling required-field"
        />
      </Stack>
      <Stack
        className="paymentHead"
        direction="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="35%"
        margin="0 auto"
        marginTop="20px"
      >
        <DynamicTextField
          type="number"
          name="contact"
          label="TOTAL AMOUNT TO PAY"
          required={false}
          disabled
          fullWidth
          value={selected?.totalPrice || 0}
          className="input-field-styling required-field"
          // onChange={handleChange}
        />
      </Stack>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        bottom="0"
        right="0"
        left={'0'}
      >
        <DynamicButton
          variant={'contained'}
          title={'Proceed'}
          onClick={handleConfirm}
          disabled={!selected.paymentMethod}
          style={{
            padding: '11px 23px',
            borderRadius: '10px',
            textTransform: 'capitalize',
            width: '10%',
            fontWeight: '600',
            fontSize: '16px',
            margin: '0 auto 24px'
          }}
        />
      </Stack>
      <DynamicModal
        className={'checkoutModal transactionModal'}
        open={isOpen}
        handleDialog={() => setIsOpen(false)}
        type
        selected={selected}
      />
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle className="alert-title align-center">
          {'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes, what do you want
                <Typography className="title-text-sub">to do?</Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            onClick={() => handleCloseDialog('discard')}
            className="font-17 text-black no-hover"
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            onClick={() => handleCloseDialog('goBack')}
            className="font-17 no-hover"
          >
            Go back to Editing
          </Button>
        </DialogActions>
      </Dialog>
      <DynamicModal
        className={'checkoutModal transactionModal'}
        open={open}
        handleDialog={() => {
          setOpen(false);
          handleNext();
        }}
        type
        selected={selected}
      />
    </>
  );
};
