import { Avatar, Grid, Typography } from '@mui/material';
import React from 'react';
import { useChat } from '../context/ChatContext';

const Sendertemplate = ({ data }) => {
  const { selectedSearchClient } = useChat();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end" // This spreads out the items
      spacing={1}
      wrap="nowrap"
    >
      <Grid item>
        <Typography
          sx={{
            padding: '8.188px 10.918px',
            borderRadius: '16.377px 16.377px 0px 16.377px',
            background: '#003087',
            color: '#FFF',
            wordBreak: 'break-word',
            display: 'inline-flex', // Keeps the background size to the content
            alignItems: 'center',
            marginLeft: 'auto' // Aligns the text to the right
          }}
        >
          {data && (data?.message || data?.body)}
        </Typography>
      </Grid>
      <Grid item>
        <Avatar
          alt="userImg"
          src={selectedSearchClient && selectedSearchClient?.profileImage}
          sx={{
            width: '33px',
            height: '33px',
            border: '0.5px solid #D0D0D0'
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Sendertemplate;
