import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Card,
  styled
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import '../../style.css';
import GlobalData from '../../../../global';

// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(75)};
`
);

const TypographyH3 = styled(Typography)(
  ({ theme }) => `
  color: ${theme.colors.alpha.black[50]};
`
);

// const OutlinedInputWrapper = styled(OutlinedInput)(
//   ({ theme }) => `
//     background-color: ${theme.colors.alpha.white[100]};
// `
// );

// const ButtonNotify = styled(Button)(
//   ({ theme }) => `
//     margin-right: -${theme.spacing(1)};
// `
// );

function ResetLinkExpired() {
  
  // Get the title from global data
  const title = GlobalData[0].title;
  
  const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault();
        navigate('/forgot-password');
    };

  const calculateTimeLeft = () => {
    const difference = +new Date(`2023`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box textAlign="center" px={3}>
        <TypographyH1 variant="h1">{timeLeft[interval]}</TypographyH1>
        <TypographyH3 variant="h3">{interval}</TypographyH3>
      </Box>
    );
  });

  return (
    <>
      <Helmet>
        <title>Reset Link Expired | {title}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xxl" sx={{ padding: '0px !important' }}>
          <div className='main'>
            <div className='parent-container-main'>
              <div className='logo-text'>
                <div className="zoom-room-logo">
                  <img src={'/static/images/logo/zrlogo.png'} style={{ maxWidth: '100%' }} className="logo-image" alt='zoom-room-logo' />
                </div>
                <Typography className='hero-message'>WE DON'T TRAIN DOGS.</Typography>
                <Typography className='hero-message'>WE TRAIN THE PEOPLE WHO LOVE THEM.</Typography>
              </div>

              <div className='form-section'>
                <div className='zoom-room-line'>
                  <div className='horizontal-line' />
                </div>
                <div className='form1'>
                  <Card className="form-area-password-expired">
                    <Container component="main" className="login-page password-expired" >
                      <Box
                        sx={{
                          marginTop: 0,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'

                        }}
                      >
                        <Typography className="title-text-password-expired">                          
                          OOPS! PASSWORD RESET LINK HAS BEEN EXPIRED
                        </Typography>
                        <Typography className="title-text-sub-password-expired">
                        <p className='link-expired'><p>The password link expired after a short while for security reasons. You</p>
                            <p>can ask for new password reset email if you still need to reset your</p>
                            <span className='password-expire-text'> password.</span>
                          </p>

                        </Typography>
                        <Box component="form" onSubmit={handleSubmit}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className='button-style-password-expired'
                          >
                            <Typography className='button-text'>
                              REQUEST A NEW PASSWORD RESET LINK
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Container>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MainContent>
    </>
  );
}

export default ResetLinkExpired;
