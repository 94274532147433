/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import {
 
  Grid,
  Slide,
  Box
  // Divider
} from '@mui/material';
import { getLocalData } from 'src/utils';
import TemplateListAPI from './TemplateList';
import Footer from 'src/components/Footer';
import React, { useEffect, useState, useRef } from "react";
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import GlobalData from '../../../global';
import { ApiService } from 'src/services/ApiService';
import Loader from 'src/components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
// import AddLocation from './AddLocation';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function LocationList() {
  const navigate = useNavigate();
  const title = GlobalData[0].title;
  const childRef = useRef(null);

  const [showScreen, setShowScreen] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  useEffect(() => {

    let isLogin = getLocalData("Login_Sucess");
    if (isLogin !== 'true') {
      navigate("/login");
    }

  }, []);


// Method to close the location form
  const handleButtonClick = async () => {
    setIsLoading(true);
    
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/syncTemplates?developer=${'backend'}`
      );
      if(response){
        setIsLoading(false)
        setShowSuccessMessage(true);
        setSuccessMessage(response?.data?.message);
      }
    } catch (e) {
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
      setIsLoading(false)
    }
  }
  const buttons = [
    {
      label: 'Sync Template',
      onClick: handleButtonClick,
      theme: true // Corrected typo 'theam' to 'theme'
    },
  ];
  const closePopup = () => {
    setShowSuccessMessage(false);
    setShowScreen(0);
  };
  return (
    <>
         <Loader IsLoading={isLoading} />
         {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Helmet>
        <title>Template | {title}</title>
      </Helmet>
      {/* maxWidth="lg" */}
      <Box  sx={{pl:"45px", pr: '22px', mr:'20px'}} >
      {showScreen===0 &&<Header title="Template" sx={{marginX:"10px"}} buttons={buttons}/>}
              </Box>
          <Grid item width={'100%'}>
            {/* Now draw the location table */}
            <TemplateListAPI ref={childRef} setShowScreen={setShowScreen} showScreen={showScreen} />
          </Grid>
      </>
            );
        }
      <Footer />


export default LocationList;
