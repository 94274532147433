import { Box, alpha, lighten, useTheme } from '@mui/material'; //  IconButton, Tooltip,
import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Sidebar from './Sidebar';
// import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
// import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
//  import Header from '../../components/Header';
import RightSidebar from './RightSidebar';

const SidebarLayout = () => { 
  const theme = useTheme();
  const { closeSidebar, sidebarToggleHalf } = useContext(SidebarContext); // , sidebarToggle, toggleSidebar
  console.log('sidebarToggleHalf', sidebarToggleHalf, closeSidebar);

  // check if the window width is less than 1280px then set the sidebar to half
  let half;
  // window width
  if (window.innerWidth < 1280) {
    half = '';
  } else {
    half = sidebarToggleHalf ? '' : 'content-layout-half';
  }

  return (
    <>
      <Box
        className="content-layout-main"
        sx={{
          flex: 1,
          height: '100%',
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`
          }
        }}
      >
        {/* <Header /> */}
        {/*  Chnaged position in navigation 
        <Box
        component="span"
        sx={{
          // ml: 2,
          mr: 3,
          display: { lg: 'none', xs: 'inline-block' },
          position: { lg: 'absolute' }
        }}
        className="hamburger-cont"
      >
        <Tooltip arrow title="Menu">
        <IconButton
          color="primary"
          onClick={toggleSidebar}
          className="hamburger-icon-btn"
          title="hamburger-menu"
        >
          {!sidebarToggle ? (
            <MenuTwoToneIcon fontSize="small" />
          ) : (
            <CloseTwoToneIcon fontSize="small" />
          )}
        </IconButton>
        </Tooltip>
      </Box>
    */}
        <Sidebar />
        <Box
          sx={{
            // position: 'relative',
            zIndex: 4,
            display: 'block',
            flex: 1,
            // pt: `${theme.header.height}`,
            // mt: '60px',
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            }
            // ml: `${theme.sidebar.width}`
          }}
          className={`content-layout-wrapper ${sidebarToggleHalf ? '' : half}`} // based on the sidebarToggleHalf state, add the class to the content layout wrapper
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>

      {/* Right isdebar */}
      <RightSidebar />
    </>
  );
};

export default SidebarLayout;
