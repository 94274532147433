/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Slide,
  CardMedia,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  InputAdornment
} from '@mui/material';
import { HeaderContext } from 'src/contexts/HeaderContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
// import './EditProduct.css';
import DeleteIcon from '@mui/icons-material/Delete';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Loader from '../../../components/Loader';
import axios from 'axios';
import { getLocalData, setFullLocationId } from 'src/utils';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { ImageSlider } from 'src/components/Global';
import heic2any from 'heic2any';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import {
  varientTypeValidate,
  varientSet
} from './AddProduct/productvalidationHelpers';
import CreateVariantModal from 'src/content/applications/FranchiseProduct/AddProduct/CreateVariantModal';
import CreateCategoryModal from 'src/content/applications/FranchiseProduct/AddProduct/CreateCategoryModal';
import CreateVariantTypeModal from 'src/content/applications/FranchiseProduct/AddProduct/CreateVariantTypeModal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function EditProduct({
  data,
  openProductEditPopup,
  setopenProductEditPopup,
  refreshProductData,
  setShowScreen,
  fromDetailPage
}) {
  console.log(data);

  const [getProductList, setgetProductList] = useState([]);
  let userId = getLocalData('userId');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const locationId = getLocalData('locationId');
  const locationName = getLocalData('locationName');
  const franchiseeId = getLocalData('franchiseeId');
  const franchiseeName = getLocalData('franchiseeName');

  /* States for getting values from input fields of Add product form as follows */

  const [manufacturerProductName, setmanufacturerProductName] = useState('');
  // const [barCode, setbarCode] = useState(0);
  const [manufacturer, setmanufacturer] = useState('');
  const [manufacturerUrl, setmanufacturerUrl] = useState('');
  const [zoomRoomProductName, setzoomRoomProductName] = useState('');
  const [productCategory, setproductCategory] = useState([]);
  const [varientType, setvarientType] = useState([]);
  const [varientProduct, setvarientProduct] = useState([]);
  const [price, setprice] = useState('');
  const [supplierList, setsupplierList] = useState([]);
  const [sellingPoint, setsellingPoint] = useState('');
  const [productDescription, setproductDescription] = useState('');
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [isRequired, setisRequired] = useState(false);
  const [isBestSeller, setisBestSeller] = useState(false);
  const [istaxExempt, setistaxExempt] = useState(false);
  const [isDiscontinued, setisDiscontinued] = useState(false);
  const [productCategoryId, setproductCategoryId] = useState('');

  const [supplierId, setsupplierId] = useState('');
  const [supplierName, setsupplierName] = useState('');

  const [valueId, setvalueId] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [variantDisabled, setvariantDisabled] = useState(true);
  const [expiryDate, setexpiryDate] = useState('');
  const [productId, setproductId] = useState('');
  const [mediaId, setmediaId] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [productCategoryName, setproductCategoryName] = useState('');
  const [skuCode, setSkuCode] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  /* States for getting and setting add product errors  */
  const [manufacturerProductNameError, setmanufacturerProductNameError] =
    useState('');
  // const [barCodeError, setbarCodeError] = useState('');
  const [skuCodeError, setSkuCodeError] = useState('');

  const [manufacturerError, setmanufacturerError] = useState('');
  const [manufacturerUrlError, setmanufacturerUrlError] = useState('');
  const [zoomRoomProductNameError, setzoomRoomProductNameError] = useState('');
  const [productCategoryError, setproductCategoryError] = useState(false);
  const [varientTypeError, setvarientTypeError] = useState(false);
  const [varientError, setvarientError] = useState(false);
  const [priceError, setpriceError] = useState('');
  const [supplierError, setsupplierError] = useState(false);
  const [sellingPointError, setsellingPointError] = useState('');
  const [productDescriptionError, setproductDescriptionError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [expiryDateError, setexpiryDateError] = useState('');
  const [openConfirmationPop, setOpenConfirmationPopUp] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);
  /* States for opening of dialog */
  const [openEditproduct, setopenEditproduct] = useState(openProductEditPopup);
  // check manufacturere url is valid or not
  const [isManufacturerUrlValid, setIsManufacturerUrlValid] = useState(false);
  const [openAddVariant, setopenAddVariant] = useState(false);
  const [openAddCategory, setopenAddCategory] = useState(false);
  const [openAddVarientType, setopenAddVarientType] = useState(false);
  // const [openAddSupplier, setopenAddSupplier] = useState(false);
  const [variantName, setvariantName] = useState(data?.variant?.value);
  const [variantId, setvariantId] = useState(data?.variant?.sortKey);
  const [categoryName, setcategoryName] = useState('');
  const [categoryDescription, setcategoryDescription] = useState('');
  const [varientTypeName, setvarientTypeName] = useState(
    data?.variant?.variantType
  );
  const [variantTypeId, setvariantTypeId] = useState('');
  const [imageError, setImageError] = useState('');
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Edit Product');
  /* State for setting image url */
  const [imageUrl, setimageUrl] = useState('');
  const [newImages, setNewImages] = useState([]);
  const [openDeleteMedia, setopenDeleteMedia] = useState(false);

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [productImage, setProductImage] = useState([]);
  const [wholeSalePrice, setWholeSalePrice] = useState('');
  const [uploadImagesToApi, setUploadImagesToApi] = useState([]);

  const [supplier, setSupplier] = useState({});
  const [imageObj, setimageObj] = useState({});

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [variantField, setVariantField] = useState(0);
  const [typesVar1, setTypesVar1] = useState('');
  const [typesVar2, setTypesVar2] = useState('');
  const [variant, setVariant] = useState([
    {
      valueId: '',
      value: ''
    },
    {
      valueId: '',
      value: ''
    }
  ]);

  const [createVariantTypeErrors, setCreateVariantTypeErrors] = useState({
    varientTypeName: ''
  });

  const [createVariantErrors, setCreateVariantErrors] = useState({
    varientName: '',
    variantType: ''
  });

  const [createCategoryErrors, setCreateCategoryErrors] = useState({
    categoryName: '',
    description: ''
  });

  let productDetails;

  useEffect(() => {
    productDetails = data;
    // console.log(productDetails, "productDetails");
    getProducts();
    getCategory();
    getSupplier();
    getVariantType();
  }, [data]);

  useEffect(() => {
    if (productDetails?.variants?.length > 0) {
      const setVariantData = (index) => ({
        variantId: productDetails?.variants[index]?.variantId ?? '',
        variantType: productDetails?.variants[index]?.variantType ?? ''
      });

      const setVariantValue = (index) => ({
        valueId: productDetails?.variants[index]?.valueId ?? '',
        value: productDetails?.variants[index]?.value ?? ''
      });

      setTypesVar1(setVariantData(0));
      setTypesVar2(setVariantData(1));

      setVariant([setVariantValue(0), setVariantValue(1)]);

      Promise.all(
        productDetails?.variants?.map((item, index) =>
          getVariants(item?.variantId, index)
        )
      );
    }
  }, [productDetails]);

  useEffect(() => {
    if (typesVar1?.variantId) {
      getVariants(typesVar1?.variantId, 0);
    }
    if (typesVar2?.variantId) {
      getVariants(typesVar2?.variantId, 1);
    }
  }, [typesVar1, typesVar2]);

  /* Function to get uploaded image */
  const handleFileInput = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg') ||
        fileName.endsWith('.heic')
      ) {
        if (fileName.endsWith('.heic')) {
          try {
            const jpegBlob = await heic2any({ blob: file });
            if (!jpegBlob) {
              throw new Error('Conversion failed.');
            }

            const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
              type: 'image/jpeg'
            });

            const uploadedImage = {
              previewURL: URL.createObjectURL(jpegFile),
              id: Math.random().toString(36).substring(7),
              file: jpegFile
            };

            setNewImages([...newImages, uploadedImage]);
            setUploadImagesToApi([...uploadImagesToApi, uploadedImage]); // Keep this line
            setImageError('');
            setIsFieldUpdated(true);
          } catch (error) {
            console.error('Error handling HEIC file:', error);
            setImageError('Error handling HEIC file.');
          }
        } else if (file.type === 'image/gif') {
          setImageError(
            'Please upload a supported image format (excluding GIF and HEIC).'
          );
        } else {
          const uploadedImages = Array.from(event.target.files).map((file) => ({
            previewURL: URL.createObjectURL(file),
            id: Math.random().toString(36).substring(7),
            file: file
          }));

          setNewImages([...newImages, ...uploadedImages]);
          setUploadImagesToApi([...uploadImagesToApi, ...uploadedImages]); // Keep this line
          setImageError('');
          setIsFieldUpdated(true);
        }
      } else {
        setImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
    event.target.value = ""
  };

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      setopenEditproduct(true);
    }
    if (val === 'Discard') {
      setOpen(false);
      setopenEditproduct(false);
      setopenProductEditPopup(false);
      setproductId('');
      setShowScreen(0);
    }
    if (val === 'Cancel') {
      setopenDeleteMedia(false);
    }
  };

  /* Function to close the Edit product dialog box */

  const handleCloseEditProduct = () => {
    if (isFieldUpdated) {
      setopenEditproduct(true);
      setOpen(true);
    } else {
      setopenEditproduct(false);
      setopenProductEditPopup(false);
      setOpen(false);
      setproductId('');
      if (fromDetailPage) {
        setShowScreen(3);
      } else {
        setShowScreen(0);
      }
    }
  };

  // close variant dialog
  const handleCloseVariant = () => {
    setCreateCategoryErrors({
      categoryName: '',
      description: ''
    });
    setCreateVariantTypeErrors({
      varientTypeName: ''
    });
    setCreateVariantErrors({
      varientName: '',
      variantType: ''
    });
    setopenAddVariant(false);
    setopenAddCategory(false);
    setopenAddVarientType(false);
  };

  const addNewVariant = (number) => {
    setvarientTypeName('');
    setopenAddVarientType(true);
    setVariantField(number);
  };

  const addVariant = (number) => {
    if (number === 0) {
      setvariantTypeId(typesVar1?.variantId ?? '');
    }
    if (number === 1) {
      setvariantTypeId(typesVar2?.variantId ?? '');
    }
    setvariantName('');
    setopenAddVariant(true);
  };

  // create new variant
  const handleCreateVariantType = () => {
    const error = !varientTypeName;
    setCreateVariantTypeErrors({
      varientTypeName: !varientTypeName ? 'Varient Type name is required.' : ''
    });
    if (error) return;

    if (varientTypeName) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        variantType: varientTypeName,
        createdBy: userId
        // isActive: true
      };
      createVariantType(data);
    }
  };

  // create variant type
  const createVariantType = (data) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCT_API_END_POINT}variants`, data)
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvarientTypeName('');
          getVariantType(res.data.data.variantId);
          setvariantId(res.data.data.variantId);
          setopenAddVarientType(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // Now create category
  const handleCreateCategory = () => {
    const error = !categoryName || !categoryDescription;

    setCreateCategoryErrors({
      categoryName: !categoryName ? 'Category name is required.' : '',
      description: !categoryDescription ? 'Description is required.' : ''
    });

    if (error) return;
    if (categoryName && categoryDescription) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        categoryName: categoryName,
        description: categoryDescription,
        createdBy: userId
        // isActive: true
      };
      createCategory(data);
    }
  };

  // create category
  const createCategory = (data) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCT_API_END_POINT}category`, data)
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setcategoryName('');
          setcategoryDescription('');
          getCategory();
          setproductCategoryId(res.data.data.categoryId);
          setopenAddCategory(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // Method to create new variant
  const handleCreateVariant = () => {
    const error = !variantName || !variantTypeId;

    setCreateVariantErrors({
      varientName: !variantName ? 'Varient Name is required.' : '',
      variantType: !variantTypeId ? 'Varient Type is required.' : ''
    });
    if (error) return;
    if (variantName && variantTypeId) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        value: variantName,
        variantId: variantTypeId,
        createdBy: userId
        // isActive: true
      };
      createVariant(data);
    }
  };

  // create variant
  const createVariant = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}variant-values`,
        data
      )
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvariantName('');
          setvariantTypeId('');
          if (typesVar1?.variantId === variantTypeId) {
            getVariants(variantTypeId, 0, null, res.data.data.valueId);
          }
          if (typesVar2?.variantId === variantTypeId) {
            getVariants(variantTypeId, 1, null, res.data.data.valueId);
          }
          // set variant value id
          setvalueId(res.data.data.valueId);
          setopenAddVariant(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Method to set variant type
  const handleVariantTypeChange = (e) => {
    setvariantTypeId(e.target.value);
  };

  /* Function to get category of products */
  const getCategory = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    await fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}category?locationId=${encodedLocationId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data.Items);
        setproductCategoryList(data.data.Items);
        // console.log(productCategoryList);
      });
  };

  /* Function to get variant types of products */
  const getVariantType = async (variantTypeId) => {
    const location = encodeURIComponent(locationId);
    console.log(variantTypeId, 'variantTypeId');
    await ApiService.get(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}variants?locationId=${location}`
    ).then((data) => {
      setvarientType(data?.data?.Items);
      if (data?.data?.Items?.length > 0 && variantTypeId) {
        const foundItem = data?.data?.Items?.find(
          (item) => item?.variantId === variantTypeId
        );
        if (variantField === 0) {
          setTypesVar1({
            variantId: foundItem?.variantId,
            variantType: foundItem?.variantType
          });
        }
        if (variantField === 1) {
          setTypesVar2({
            variantId: foundItem?.variantId,
            variantType: foundItem?.variantType
          });
        }

        const selectedVariants = [...variant];
        selectedVariants[variantField] = {
          valueId: '',
          value: ''
        };
        setvarientProduct({ ...varientProduct, [variantField]: [] });
        setVariant(selectedVariants);
      }
    });
    setIsLoading(false);
  };

  /* Function to get variant on basis of variant id */
  const getVariants = async (id, number, setDefault, variantValudId) => {
    console.log(id, 'id');
    let variantId = encodeURIComponent(id);
    const location = encodeURIComponent(locationId);

    await fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}variant-values/getVariantValues?variantId=${variantId}&locationId=${location}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setvarientProduct((prevProducts) => {
          return { ...prevProducts, [number]: data?.data?.Items ?? [] };
        });
        let defaultArr = [...variant];
        if (setDefault === 'setDefault') {
          defaultArr[number] = {
            valueId: data?.data?.Items[0]?.sortKey,
            value: data?.data?.Items[0]?.value
          };
          setVariant(defaultArr);
        }
        const typesVarArray = [typesVar1, typesVar2]; // Add more if needed

        if (typesVarArray[number]?.variantId === id && variantValudId) {
          const newData = data?.data?.Items?.find(
            (item) => variantValudId === item?.sortKey
          );

          if (newData) {
            defaultArr[number] = {
              valueId: newData.sortKey,
              value: newData.value
            };
            setVariant([...defaultArr]); // Use spread operator to create a new array and trigger re-render
          }
        }
      });
  };
  console.log(varientProduct, 'setvarientProduct');

  /* Function to get the suppliers list */

  const getSupplier = async () => {
    const encodedLocationId = encodeURIComponent(locationId);

    await fetch(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?businessEntityType=Supplier&locationId=${encodedLocationId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setsupplierList(data.data.Items);
      });
  };

  /* Function to get product details on basis of id */

  const getProducts = async () => {
    setIsLoading(true);
    const encodedLocationId = encodeURIComponent(locationId);

    // setIsLoading(true);
    let getProductId;
    if (productId) {
      getProductId = productId;
    } else {
      getProductId = productDetails.sortKey;
    }
    let encodedId = encodeURIComponent(getProductId);
    await fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}products/getProductDetailsById?locationId=${encodedLocationId}&id=${encodedId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        let setValue =
          data.data.Items[0] &&
          data.data.Items[0].supplierId + '|' + data.data.Items[0].supplierName;
        setIsUpdated(false);

        setproductId(data.data.Items[0].sortKey);
        setgetProductList(data.data.Items[0]);
        // setmanufacturerProductName(data.data.Items[0].mfgName);
        setmanufacturerProductName(data.data.Items[0].mfgName);
        setmanufacturer(data.data.Items[0].manufacturer);
        setmanufacturerUrl(data.data.Items[0].mfgUrl);
        // setzoomRoomProductName(data.data.Items[0].mfgName);
        setzoomRoomProductName(data.data.Items[0].fName);
        setprice(data.data.Items[0].price);
        setWholeSalePrice(data.data.Items[0].cost);
        setsellingPoint(data.data.Items[0].sellingPoints);
        setproductDescription(data.data.Items[0].productDescription);
        setexpiryDate(data.data.Items[0].expiryDate);
        setIsManufacturerUrlValid(false);

        // If product has media then set the image url else set it to empty
        if (data.data.Items[0].media.length > 0) {
          // setimageUrl(data.data.Items[0].media[0].media);
          setMultipleImages(data.data.Items[0].media);
          setNewImages(data.data.Items[0].media);
        } else {
          // setimageUrl('');
          setMultipleImages([]);
          setNewImages([]);
        }
        setIsLoading(false);

        // If product has media then set the media id else set it to empty
        if (data.data.Items[0].media.length > 0)
          setmediaId(data.data.Items[0].media[0].mediaId);
        else setmediaId('');
        // setproductCategoryName(data.data.Items[0].category.categoryName);
        setproductCategoryId(data.data.Items[0].categoryId);
        setproductCategoryName(data.data.Items[0].categoryName);

        setsupplierId(data.data.Items[0].supplierId);
        setsupplierName(data.data.Items[0].supplierName);
        setSupplier(setValue);

        // setvariantId(data.data.Items[0].variantId);
        // setvariantName(data.data.Items[0].variantId);
        // setvalueId(data.data.Items[0].valueId);
        setVariant(data.data.Items[0].variants);

        setistaxExempt(data.data.Items[0].taxExempt);

        setSelectedStatus(data.data.Items[0].status);
        setSkuCode(data.data.Items[0].sku);

        if (data.data.Items[0].isRequired === '') {
          setisRequired(false);
        } else {
          setisRequired(data.data.Items[0].isRequired);
        }

        if (data.data.Items[0].isBestSeller === '') {
          setisBestSeller(false);
        } else {
          setisBestSeller(data.data.Items[0].isBestSeller);
        }

        if (data.data.Items[0].taxExempt === '') {
          setistaxExempt(false);
        } else {
          setistaxExempt(data.data.Items[0].taxExempt);
        }

        if (data.data.Items[0].isDiscontinued === '') {
          setisDiscontinued(false);
        } else {
          setisDiscontinued(data.data.Items[0].isDiscontinued);
        }
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (newImages) {
      let images = newImages?.map((item) => item.media || item?.previewURL);
      console.log(images, 'images');
      // let imagesArr = images.map((item) => ({
      //   image: item
      // }));
      // setMultipleImages(images);
      setProductImage(images);
    }
  }, [newImages]);
  // useEffect(() => {
  //   console.log(getProductList, 'getProductList');
  //   if (getProductList) {
  //     let images = getProductList.productMedia.map((item) => item.media);
  //     console.log(images, 'images');
  //     setMultipleImages(images);
  //   }

  // }, [getProductList]);

  /* Function to delete the current profile picture */

  const deleteMedia = async (id) => {
    setIsFieldUpdated(true);
    // const formData=new FormData();
    // formData.append("productId",productId);
    // formData.append("mediaIds",mediaId)
    let mediaArr = [];
    mediaArr.push(id);
    let body = JSON.stringify({
      locationId: locationId,
      mediaIds: mediaArr
    });

    await fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}products/deleteProductMedia`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },

        body: body
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setopenDeleteMedia(false);
        const updatedImages = newImages.filter((image) => image.sortKey !== id);
        setNewImages(updatedImages);
        setImageError('');
        // getProducts();
        // setimageUrl('');
      });
  };

  console.log(typesVar2, 'types var isadfascads');

  /* Function to validate and check all field are filled */
  const SubmitProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!manufacturer) {
      setmanufacturerError('Manufacturer is required');
    }
    if (!zoomRoomProductName) {
      setzoomRoomProductNameError('Zoom Room product name is required');
    }
    if (!productCategoryId) {
      setproductCategoryError(true);
    }

    if (!supplierId) {
      setsupplierError(true);
    }
    if (!skuCode) {
      // setsupplierError('Supplier is required');
      setSkuCodeError('SKU code is required');
    }

    if (!manufacturerProductName) {
      // setsupplierError('Supplier is required');
      setmanufacturerProductNameError('Manufacturer product name is required');
    }
    if (!price) {
      setpriceError('Price is required');
    }

    if (
      zoomRoomProductName &&
      manufacturerProductName &&
      skuCode &&
      manufacturer &&
      productCategoryId &&
      supplierId &&
      price &&
      // price &&
      !isManufacturerUrlValid
      // expiryDate
    ) {
      setOpenConfirmationPopUp(true);
      //  EditProduct();
      if (isFieldUpdated) {
        setOpenPopup(true);
      }
    }
  };

  const handleConfirmClose = (val) => {
    if (val === 'Yes') {
      // EditBundlesData()
      EditProduct();
      setOpenConfirmationPopUp(false);
    }
    if (val === 'No') {
      setOpenConfirmationPopUp(false);
    }
  };

  /* Function to edit product details */
  const EditProduct = async () => {
    const variantsArr = variant?.map((item, i) => ({
      ...item,
      variantId: i === 0 ? typesVar1?.variantId : typesVar2?.variantId ?? '',
      variantType:
        i === 0 ? typesVar1?.variantType : typesVar2?.variantType ?? ''
    }));
    try {
      const encodedLocationId = encodeURIComponent(locationId);

      setIsLoading(true);

      /* Json for add product  */
      const dataEditProduct = {
        locationName: locationName,
        isFranchisor: data?.isFranchisor,
        franchiseeId: franchiseeId,
        franchiseeName: franchiseeName,
        manufacturer: manufacturer,
        mfgName: manufacturerProductName,
        fName: zoomRoomProductName,
        mfgUrl: manufacturerUrl,
        categoryId: productCategoryId,
        categoryName: productCategoryName,
        // variantId: variantId,
        supplierId: supplierId,
        sku: skuCode,
        supplierName: supplierName,
        // valueId: valueId,
        variants: variantsArr,
        productDescription: productDescription,
        productImage: '',
        productVideo: '',
        price: price?.toString(),
        // cost: wholeSalePrice?.toString(),
        sellingPoints: sellingPoint,
        taxExempt: istaxExempt,
        status: selectedStatus,
        updatedBy: userId
      };

      console.log(productId);
      let encodedproductId = encodeURIComponent(productId);
      const response = await ApiService.put(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}products/updateProduct?locationId=${encodedLocationId}&id=${encodedproductId}`,
        dataEditProduct
      );

      if (response.statusCode === 200) {
        if (uploadImagesToApi.length > 0) {
          for (const image of uploadImagesToApi) {
            const formData = new FormData();
            formData.append('productId', productId);
            formData.append('locationId', locationId);
            formData.append('media', image.file);
            addProductMedia(formData);
          }
        } else {
          setOpen(true);
          setIsUpdated(true);
          setIsLoading(false);
          refreshProductData();
          setIsFieldUpdated(false);
        }
      } else {
        // Handle the case when the response status is not 200
        // You may want to throw an error or handle it according to your needs

        setShowMessage(true);
        setIsLoading(false);
        setErrorMessage('Failed to update the product');
      }
    } catch (error) {
      // Handle any errors that occurred during the execution
      console.error('An error occurred:', error);
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
      // You can also add more specific error handling here
    }
  };

  const removeImage = (idToRemove) => {
    const updatedImages = newImages.filter(
      (image) => image.sortKey !== idToRemove
    );
    const updatedUploadImages = uploadImagesToApi.filter(
      (image) => image.sortKey !== idToRemove
    );

    setUploadImagesToApi(updatedUploadImages);

    setNewImages(updatedImages);
    setopenDeleteMedia(false);
    setImageError('');
  };

  // Method to change the status
  const handleStatusChange = (val) => {
    setSelectedStatus(val);
    setIsFieldUpdated(true);
  };

  // Add product media
  const addProductMedia = async (formData) => {
    await fetch(
      `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/addFranchiseeProductMedia`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*'
        },
        body: formData
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // if (newImages[newImages.length - 1]) {
        //   getProducts();
        setIsLoading(false);
        refreshProductData();
        setIsUpdated(true);
        setOpen(true);
        setIsFieldUpdated(false);
        // }
      })
      .catch((error) => {
        console.error('Error during fetch operation:', error);
        // Handle the error as needed, e.g., show an error message to the user
        setIsLoading(false);
      });
  };

  /* Function to set manufacturer product name setting value */

  const manufacturerProductNameValidate = (manufacturerProductName) => {
    if (!manufacturerProductName) {
      setmanufacturerProductName(
        manufacturerProductName !== '' ? '' : manufacturerProductName
      );
      setIsFieldUpdated(true);
      // setmanufacturerProductNameError('Manufacturer product name is required');
    } else {
      setIsFieldUpdated(true);
      setmanufacturerProductNameError('');
      setmanufacturerProductName(manufacturerProductName);
    }
  };

  /* Function for validation of Manufacturer  */

  const manufacturervalidate = (manufacturer) => {
    setIsFieldUpdated(true);
    setmanufacturerError('');
    setmanufacturer(manufacturer);
  };

  /* Function for validation of Zoom room product name  */
  const zoomRoomProductNamevalidate = (zoomRoomProductName) => {
    if (!zoomRoomProductName) {
      setzoomRoomProductName(
        zoomRoomProductName !== '' ? '' : zoomRoomProductName
      );
      setIsFieldUpdated(true);

      // setzoomRoomProductNameError('Zoom room product name is requried');
    } else {
      setIsFieldUpdated(true);
      setzoomRoomProductNameError('');
      setzoomRoomProductName(zoomRoomProductName);
    }
  };

  /* Function for setting value of product category validate */

  const productCategoryvalidate = (productCategory) => {
    if (!productCategory) {
      setIsFieldUpdated(true);
      setproductCategoryError(true);
    } else {
      setIsFieldUpdated(true);
      setproductCategoryError(false);
      setproductCategoryId(productCategory);
    }
  };

  /* Function to set value of price  */
  const priceSet = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, '');
    // Allow only two digits after the decimal point
    const decimalIndex = input.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setprice(input);
    setIsFieldUpdated(true);
    setpriceError('');
  };

  /* Function to set value of price  */

  const wholeSalePriceSet = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, '');
    // Allow only two digits after the decimal point
    const decimalIndex = input.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setWholeSalePrice(input);
  };

  /* Function to set value of supplier */

  const supplierValidate = (supplier) => {
    console.log(supplier, 'supplier');
    if (!supplier) {
      setIsFieldUpdated(true);
      // setsupplierError('Supplier is required');
    } else {
      const [Id, Name] = supplier.split('|');
      setSupplier(supplier);
      setsupplierId(Id);
      setsupplierName(Name);
      setIsFieldUpdated(true);
      setsupplierError('');
      // setsupplierList(supplier);

      // console.log(supplier);
    }
  };
  console.log(supplier, 'rolessss');

  /* Function to set value of selling point */

  const sellingpointSet = (sellingPoint) => {
    if (!sellingPoint) {
      setsellingPoint(sellingPoint !== '' ? sellingPoint : '');
      setIsFieldUpdated(true);
      setsellingPointError('Selling point is error');
    } else {
      setIsFieldUpdated(true);
      setsellingPointError('');
      setsellingPoint(sellingPoint);
    }
  };

  /* Function to set value of product description */

  const productDescriptionSet = (productDescription) => {
    if (!productDescription) {
      setproductDescription(
        productDescription !== '' ? productDescription : ''
      );
      setIsFieldUpdated(true);
      // setproductDescriptionError('Product description is required');
    } else {
      setIsFieldUpdated(true);
      setproductDescriptionError('');
      setproductDescription(productDescription);
    }
  };

  /* Function to set the expiry date */

  const expiryDateSet = (expiryDate) => {
    if (!expiryDate) {
      setIsFieldUpdated(true);
      // setexpiryDateError('Expiry date is required');
      setexpiryDate('');
    } else {
      /* const formattedDate = expiryDate.toLocaleDateString('en-US', {
         year: 'numeric',
         month: '2-digit',
         day: '2-digit'
       });
 
       let newDate = formattedDate.replace(/\//g, '-');
       setIsFieldUpdated(true);
       setexpiryDateError('');
       setexpiryDate(newDate); */
      let newDate;
      setexpiryDateError('');
      let objectDate = new Date(expiryDate);
      // setStartdateObj(objectDate);

      let date = objectDate.getDate();
      if (date < 10) {
        newDate = '0' + date;
      } else {
        newDate = date;
      }
      let month = objectDate.getMonth() + 1;
      // let newMonth = "0" + month;
      let newMonth;
      newMonth = month < 10 ? '0' + month : month;
      let year = objectDate.getFullYear();
      let finalDate = newMonth + '-' + newDate + '-' + year;
      setexpiryDate(finalDate);
      setIsFieldUpdated(true);
    }
  };

  /* Function for validation of SKU Code  */

  const skuCodeValidate = (skuCode) => {
    setIsFieldUpdated(true);
    setSkuCodeError('');
    setSkuCode(skuCode);
  };

  const handleMediaDelete = (image, index) => {
    // if (index) productImage.splice(index, 1);
    setimageObj(image);
    setopenDeleteMedia(true);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={1}
          mb={4}
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseEditProduct()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">Edit Product</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="button"
                variant="contained"
                className="common-button"
                onClick={SubmitProduct}
              >
                <Typography className="next-button-text">Save</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box className="location-details-container" display={'flex'}>
          <Grid container xs={12}>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={12} className="location-details-col">
                <Box className="container-text-field">
                  <Card
                    sx={{
                      backgroundColor: '#FFFFFF'
                    }}
                  >
                    <CardContent className="pr-0 pl-0">
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                          <Box className="product-details-img align-center relative Main-card ">
                            {newImages && newImages.length > 0 ? (
                              <div>
                                <ImageSlider images={productImage} />
                              </div>
                            ) : (
                              <img
                                src="/static/images/products/ProductImage.png"
                                alt="product-icon"
                                className="product-img"
                              />
                            )}
                          </Box>

                          <Box mt={2}>
                            <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                              Upload Product Image
                            </Typography>
                            <input
                              accept="image/*"
                              multiple
                              type="file"
                              id="select-product-edit-file"
                              style={{ display: 'none' }}
                              onChange={(event) => handleFileInput(event)}
                            />
                            <label htmlFor="select-product-edit-file">
                              <Button
                                type="button"
                                variant="contained"
                                component="span"
                                className="upload-image image-with-txt common-button"
                                sx={{ marginTop: '24px' }}
                              >
                                <Typography className="upload-text">
                                  Upload
                                </Typography>
                              </Button>
                            </label>
                          </Box>
                          <Box className="card-image-carousel">
                            {/* Render the uploaded images */}
                            {newImages.map((image, index) => (
                              <div
                                className="single-uploaded-images"
                                key={image.id}
                              >
                                <img
                                  src={image?.media || image?.previewURL}
                                  alt="Uploaded"
                                />
                                <div
                                  className="remove-image text-blue"
                                  onClick={() =>
                                    handleMediaDelete(image, index)
                                  }
                                >
                                  x
                                </div>
                              </div>
                            ))}
                          </Box>
                          <Box sx={{ float: 'left' }}>
                            {imageError && (
                              <FormHelperText className="error-message">
                                {imageError}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Grid
                            container
                            spacing={2}
                            component="form"
                            id="form-add"
                          >
                            <Grid item xs={12} sm={6}>
                              <TextField
                                disabled={data?.isFranchisor}
                                fullWidth
                                name="ZOOM ROOM PRODUCT NAME"
                                autocomplete="ZOOM ROOM PRODUCT NAME"
                                label="ZOOM ROOM PRODUCT NAME"
                                placeholder="ZOOM ROOM PRODUCT NAME"
                                value={zoomRoomProductName}
                                className="location-field input-field-styling required-field"
                                error={Boolean(zoomRoomProductNameError)}
                                helperText={zoomRoomProductNameError}
                                onChange={(e) =>
                                  zoomRoomProductNamevalidate(e.target.value)
                                }
                                // value={zoomRoomProductName}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label',
                                  shrink: true
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="MANUFACTURER PRODUCT NAME"
                                autocomplete="MANUFACTURER PRODUCT NAME"
                                label="MANUFACTURER PRODUCT NAME"
                                placeholder="Name"
                                id="locationId"
                                value={manufacturerProductName}
                                className="location-field input-field-styling required-field"
                                error={Boolean(manufacturerProductNameError)}
                                helperText={manufacturerProductNameError}
                                onChange={(e) =>
                                  manufacturerProductNameValidate(
                                    e.target.value
                                  )
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label',
                                  shrink: true
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="SKU CODE"
                                autocomplete="SKU CODE"
                                label="SKU CODE"
                                className="location-field required-field input-field-styling"
                                value={skuCode}
                                error={Boolean(skuCodeError)}
                                helperText={skuCodeError}
                                placeholder="SKU CODE"
                                onChange={(e) =>
                                  skuCodeValidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label',
                                  shrink: true
                                }}
                                InputProps={{}}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="MANUFACTURER"
                                autocomplete="MANUFACTURER"
                                label="MANUFACTURER"
                                className="location-field required-field input-field-styling"
                                value={manufacturer}
                                error={Boolean(manufacturerError)}
                                helperText={manufacturerError}
                                placeholder="MANUFACTURER"
                                onChange={(e) =>
                                  manufacturervalidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label',
                                  shrink: true
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="field-top-margin-edit-location location-field required-field select-drop-down-label-styling "
                                fullWidth
                                // error={productCategoryError}
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling required-label"
                                >
                                  PRODUCT CATEGORY
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="PRODUCT CATEGORY"
                                  className="input-field-styling"
                                  value={productCategoryId}
                                  onChange={(e) =>
                                    productCategoryvalidate(e.target.value)
                                  }
                                  disabled={data?.isFranchisor}
                                >
                                  {productCategoryList.map((option) => (
                                    <MenuItem
                                      key={option.categoryId}
                                      value={option.categoryId}
                                    >
                                      {option.categoryName}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {productCategoryError && (
                                  <FormHelperText>
                                    Please select product category
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} />

                            {/* DIVIDER LINE ABOVE VARIANTS */}
                            <Grid item xs={12} sm={12}>
                              <Divider style={{ backgroundColor: '#003087' }} />
                            </Grid>

                            {/* VARIANT TYPE 1 */}
                            <Grid item xs={12} sm={6} mt={2}>
                              <FormControl
                                className="select-drop-down-label-styling"
                                fullWidth
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling"
                                >
                                  VARIANT TYPE
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="VARIANT TYPE"
                                  className="input-field-styling"
                                  value={typesVar1}
                                  renderValue={(selected) =>
                                    selected?.variantType ??
                                    typesVar1?.variantType
                                  }
                                  // disabled={variantDisabled}
                                  disabled={data?.isFranchisor}
                                  onChange={(e) =>
                                    // varientTypeValidate(e.target.value, 0)
                                    varientTypeValidate(
                                      e.target.value,
                                      0,
                                      getVariants,
                                      setvariantId,
                                      setTypesVar1,
                                      setTypesVar2,
                                      variant,
                                      setVariant,
                                      setIsFieldUpdated
                                    )
                                  }
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {varientType?.length > 0 &&
                                    varientType
                                      ?.filter(
                                        (option) =>
                                          !typesVar2?.variantId ||
                                          option?.variantId !==
                                            typesVar2?.variantId
                                      )
                                      ?.map((option) => {
                                        return (
                                          <MenuItem
                                            key={option?.variantId}
                                            value={option}
                                          >
                                            {option?.variantType}
                                          </MenuItem>
                                        );
                                      })}
                                </Select>
                              </FormControl>
                              {!data?.isFranchisor && (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: '#003087',
                                    textDecoration: 'underline',
                                    marginRight: '5px'
                                    // display: 'none'
                                  }}
                                  className="add-new-variant align-right"
                                >
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      addNewVariant(0);
                                    }}
                                  >
                                    +Add Variant Type
                                  </span>
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="field-top-margin-edit-location location-field  select-drop-down-label-styling "
                                fullWidth
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling "
                                >
                                  VARIANT
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="VARIANT"
                                  className="input-field-styling"
                                  value={variant?.length > 0 && variant[0]}
                                  renderValue={(selected) => selected?.value}
                                  // disabled={variantDisabled}
                                  disabled={data?.isFranchisor}
                                  onChange={(e) =>
                                    varientSet(
                                      e.target.value,
                                      0,
                                      variant,
                                      setVariant,
                                      setIsFieldUpdated,
                                      setvalueId
                                    )
                                  }
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {varientProduct[0]?.map((option) => (
                                    <MenuItem value={option}>
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {!data?.isFranchisor && (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: '#003087',
                                    textDecoration: 'underline',
                                    marginRight: '5px'
                                    // display: 'none'
                                  }}
                                  className="add-new-variant align-right"
                                >
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      addVariant(0);
                                    }}
                                  >
                                    +Add Variant
                                  </span>
                                </Typography>
                              )}
                            </Grid>

                            {/* VARIANT TYPE 2 */}
                            <Grid item xs={12} sm={6} mt={2}>
                              <FormControl
                                className="select-drop-down-label-styling"
                                fullWidth
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling"
                                >
                                  VARIANT TYPE
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="VARIANT TYPE"
                                  className="input-field-styling"
                                  value={typesVar2}
                                  renderValue={(selected) =>
                                    selected?.variantType
                                  }
                                  // disabled={variantDisabled}
                                  disabled={data?.isFranchisor}
                                  onChange={(e) =>
                                    // varientTypeValidate(e.target.value, 0)
                                    varientTypeValidate(
                                      e.target.value,
                                      1,
                                      getVariants,
                                      setvariantId,
                                      setTypesVar1,
                                      setTypesVar2,
                                      variant,
                                      setVariant,
                                      setIsFieldUpdated
                                    )
                                  }
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {varientType?.length > 0 &&
                                    varientType
                                      ?.filter(
                                        (option) =>
                                          !typesVar1?.variantId ||
                                          option?.variantId !==
                                            typesVar1?.variantId
                                      )
                                      ?.map((option) => {
                                        return (
                                          <MenuItem value={option}>
                                            {option?.variantType}
                                          </MenuItem>
                                        );
                                      })}
                                </Select>
                              </FormControl>
                              {!data?.isFranchisor && (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: '#003087',
                                    textDecoration: 'underline',
                                    marginRight: '5px'
                                    // display: 'none'
                                  }}
                                  className="add-new-variant align-right"
                                >
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      addNewVariant(1);
                                    }}
                                  >
                                    +Add Variant Type
                                  </span>
                                </Typography>
                              )}
                              {/* SPACE FOR ADD VARIANT TYPE BUTTON */}

                              {/* SPACE FOR ADD VARIANT TYPE BUTTON */}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="field-top-margin-edit-location location-field  select-drop-down-label-styling "
                                fullWidth
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling "
                                >
                                  VARIANT
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="VARIANT"
                                  className="input-field-styling"
                                  value={variant?.length > 0 && variant[1]}
                                  renderValue={(selected) => selected?.value}
                                  // disabled={variantDisabled}
                                  disabled={data?.isFranchisor}
                                  onChange={(e) =>
                                    varientSet(
                                      e.target.value,
                                      1,
                                      variant,
                                      setVariant,
                                      setIsFieldUpdated,
                                      setvalueId
                                    )
                                  }
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {varientProduct[1]?.map((option) => (
                                    <MenuItem value={option}>
                                      {option.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {!data?.isFranchisor && (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: '#003087',
                                    textDecoration: 'underline',
                                    marginRight: '5px'
                                    // display: 'none'
                                  }}
                                  className="add-new-variant align-right"
                                >
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      addVariant(1);
                                    }}
                                  >
                                    +Add Variant
                                  </span>
                                </Typography>
                              )}
                              {/* SPACE FOR ADD VARIANT BUTTON */}

                              {/* SPACE FOR ADD VARIANT BUTTON */}
                            </Grid>

                            {/* DIVIDER LINE BELOW VARIANTS */}
                            <Grid item xs={12} sm={12}>
                              <Divider style={{ backgroundColor: '#003087' }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="field-top-margin-edit-location location-field required-field select-drop-down-label-styling "
                                fullWidth
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling required-label"
                                >
                                  SUPPLIER
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="SUPPLIER"
                                  className="input-field-styling"
                                  // value={getProductList.supplierId}
                                  value={supplier}
                                  onChange={(e) =>
                                    supplierValidate(e.target.value)
                                  }
                                >
                                  {supplierList &&
                                    supplierList.map((option) => (
                                      <MenuItem
                                        value={`${option.sortKey}|${option.businessName}`}
                                      >
                                        {option.businessName}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {supplierError && (
                                  <FormHelperText>
                                    Please select supplier
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                disabled
                                name="PURCHASE COST"
                                autocomplete="PURCHASE COST"
                                label="PURCHASE COST"
                                className="field-top-margin-edit-locatio location-field input-field-styling"
                                value={wholeSalePrice}
                                // error={Boolean(priceError)}
                                // helperText={priceError}
                                onChange={wholeSalePriceSet}
                                placeholder="PURCHASE COST"
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: '18px'
                                        }}
                                      >
                                        $
                                      </span>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="PRICE"
                                autoComplete="PRICE"
                                label="PRICE"
                                className="field-top-margin-edit-location location-field input-field-styling required-field"
                                error={Boolean(priceError)}
                                helperText={priceError}
                                onChange={priceSet}
                                value={price}
                                placeholder="PRICE"
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label required-label',
                                  shrink: true
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          fontSize: '18px'
                                        }}
                                      >
                                        $
                                      </span>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} />

                            {/* <Grid
                              item
                              xs={12}
                              sm={6}
                              className="form-datepicker-container field-top-margin-edit-location location-field"
                            > */}
                            {/* <TextField
                                fullWidth
                                name="Expiry Date"
                                label="EXPIRY DATE"
                                className="field-top-margin-edit-location location-field"
                                placeholder="Expiry Date"
                                type="date"
                                // value={expiryDate}
                                // error={Boolean(expiryDateError)}location-owner input-field-styling
                                // helperText={expiryDateError}label-style-datePicker-create-franchisee
                                onChange={(e) =>
                                  expiryDateSet(e.target.value)
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee',
                                  shrink: true
                                }}
                                InputProps={{}}
                              /> */}
                            {/* <CustomDatePicker
                                label="EXPIRY DATE"
                                onChange={expiryDateSet}
                                fieldValue={expiryDate}
                                disabled
                              /> */}
                            {/* <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      label="EXPIRY DATE"
                                      value={expiryDate}
                                      onChange={expiryDateSet}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          // error={Boolean(expiryDateError)}
                                          // helperText={expiryDateError}
                                          fullWidth
                                          className="field-top-margin-edit-location location-field required-fiel"
                                          InputLabelProps={{
                                            className:
                                              'label-style-create-franchisee required-labe',
                                          }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider> */}
                            {/* </Grid> */}

                            <Grid item xs={12} sm={6} md={6}>
                              <TextField
                                fullWidth
                                multiline
                                id="my-textarea"
                                name="PRODUCT Description"
                                autocomplete="PRODUCT Description"
                                label="PRODUCT DESCRIPTION"
                                placeholder="Notes here . . . "
                                variant="outlined"
                                className="location-description field-top-margin-edit-location"
                                value={productDescription}
                                error={Boolean(productDescriptionError)}
                                helperText={productDescriptionError}
                                onChange={(e) =>
                                  productDescriptionSet(e.target.value)
                                }
                                InputLabelProps={{
                                  className: 'label-style-create-franchisee',
                                  shrink: true
                                }}
                                InputProps={{
                                  style: { color: 'black' }, // Set the text color to black here
                                  rows: 6
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <TextField
                                fullWidth
                                multiline
                                id="my-textarea"
                                name="Selling point"
                                autocomplete="Selling point"
                                label="SELLING POINT"
                                placeholder="Notes here . . . "
                                variant="outlined"
                                className="location-description field-top-margin-edit-location"
                                value={sellingPoint}
                                error={Boolean(productDescriptionError)}
                                helperText={productDescriptionError}
                                onChange={(e) =>
                                  sellingpointSet(e.target.value)
                                }
                                InputLabelProps={{
                                  className: 'label-style-create-franchisee',
                                  shrink: true
                                }}
                                InputProps={{
                                  style: { color: 'black' }, // Set the text color to black here
                                  rows: 6
                                }}
                              />
                            </Grid>

                            {/* <Grid item xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                className="select-drop-down-label-styling"
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling"
                                >
                                  STATUS
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="STATUS"
                                  className="input-field-styling"
                                  value={selectedStatus}
                                  onChange={(e) =>
                                    handleStatusChange(e.target.value)
                                  }
                                  disabled
                                >
                                  <MenuItem value={1}>Active</MenuItem>
                                  <MenuItem value={0}>Inactive</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={6} /> */}
                            {/* <Grid item xs={12} sm={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isRequired}
                                    className="check-box-span"
                                    color="default"
                                    onChange={(e) => {
                                      setisRequired(e.target.checked);
                                      setIsFieldUpdated(true);
                                    }}
                                    sx={{
                                      color: '#003087',
                                      '&.Mui-checked': {
                                        color: '#003087'
                                      }
                                    }}
                                    style={{
                                      cursor: isRequired
                                        ? 'pointer'
                                        : 'not-allowed'
                                    }}
                                    disabled = {data?.isFranchisor}
                                  />
                                }
                                label={
                                  <Typography className="check-box-text">
                                    Required
                                  </Typography>
                                }
                              />
                            </Grid> */}
                            {/* <Grid item xs={12} sm={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isBestSeller}
                                    className="check-box-span"
                                    color="default"
                                    onChange={(e) => {
                                      setisBestSeller(e.target.checked);
                                      setIsFieldUpdated(true);
                                    }}
                                    sx={{
                                      color: '#003087',
                                      '&.Mui-checked': {
                                        color: '#003087'
                                      }
                                    }}
                                    disabled = {data?.isFranchisor}
                                  />
                                }
                                label={
                                  <Typography className="check-box-text">
                                    Bestseller
                                  </Typography>
                                }
                              />
                            </Grid> */}
                            <Grid item xs={12} sm={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className="check-box-span"
                                    color="default"
                                    checked={istaxExempt}
                                    onChange={(e) => {
                                      setistaxExempt(e.target.checked);
                                      setIsFieldUpdated(true);
                                    }}
                                    sx={{
                                      color: '#003087',
                                      '&.Mui-checked': {
                                        color: '#003087'
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <Typography className="check-box-text">
                                    Tax Exempted
                                  </Typography>
                                }
                              />
                            </Grid>
                            {/* <Grid item xs={12} sm={3}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isDiscontinued}
                                    className="check-box-span"
                                    color="default"
                                    onChange={(e) => {
                                      setisDiscontinued(e.target.checked);
                                      setIsFieldUpdated(true);
                                    }}
                                    sx={{
                                      color: '#003087',
                                      '&.Mui-checked': {
                                        color: '#003087'
                                      }
                                    }}
                                    disabled = {data?.isFranchisor}
                                  />
                                }
                                label={
                                  <Typography className="check-box-text">
                                    Discontinued
                                  </Typography>
                                }
                              />
                            </Grid> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Delete confirmation dialog */}
      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Product details has been updated successfully.
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmationPop}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Save Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('No')}
          >
            Go Back to Editing
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmClose('Yes')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog
        open={openDeleteMedia}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Delete'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to delete this image?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() =>
              imageObj.file
                ? removeImage(imageObj.sortKey)
                : deleteMedia(imageObj.sortKey)
            }
          >
            Delete
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Variant dialog */}
      {/* {openAddVariant && (<CustomizedDialogs />)} */}
      <CreateVariantTypeModal
        openAddVarientType={openAddVarientType}
        handleCloseVariant={handleCloseVariant}
        setvarientTypeName={setvarientTypeName}
        varientTypeName={varientTypeName}
        handleCreateVariantType={handleCreateVariantType}
        createVariantTypeErrors={createVariantTypeErrors}
        setCreateVariantTypeErrors={setCreateVariantTypeErrors}
      />

      {/* Create Category dialog */}
      <CreateCategoryModal
        openAddCategory={openAddCategory}
        handleCloseVariant={handleCloseVariant}
        setcategoryName={setcategoryName}
        categoryName={categoryName}
        categoryDescription={categoryDescription}
        setcategoryDescription={setcategoryDescription}
        handleCreateCategory={handleCreateCategory}
        createCategoryErrors={createCategoryErrors}
        setCreateCategoryErrors={setCreateCategoryErrors}
      />

      {/* Create Variant dialog */}
      <CreateVariantModal
        openAddVariant={openAddVariant}
        handleCloseVariant={handleCloseVariant}
        handleCreateVariant={handleCreateVariant}
        varientType={varientType}
        variantName={variantName}
        setvariantName={setvariantName}
        handleVariantTypeChange={handleVariantTypeChange}
        variantTypeId={variantTypeId}
        createVariantErrors={createVariantErrors}
        setCreateVariantErrors={setCreateVariantErrors}
      />
    </>
  );
}

export default EditProduct;