import * as React from 'react';
import './Chat.css';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Card } from '@material-ui/core';
import AllUsersList from './AllUsersList';
import AllChats from './AllChats';
import { useChat } from './context/ChatContext';
import { styled } from '@mui/material/styles';
import { Badge, Tooltip, alpha } from '@mui/material';
import ChatHeader from './ChatHeader';

const NotificationsBadge = styled(Badge)(
  () => `    
    .MuiBadge-badge {
        background-color:#57CA22;
        color:#57CA22;
        min-width: 16px; 
        height: 16px;
        padding: 0;
        border-radius: 999px;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha('#57CA22', 0.3)};
            content: "";
        }
    }
`
);

export default function Chat() {
  const {
    activeScreen,
    handleClearStates,
    anchorEl,
    setAnchorEl,
    notification,
    setNotification
  } = useChat();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setNotification(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      handleClearStates();
    }, 500);
  };

  return (
    <div>
      <Tooltip arrow title="Chat">
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          sx={{
            width: '45px',
            height: '45px',
            borderRadius: '100%',
            minWidth: 0
          }}
        >
          <NotificationsBadge
            variant={notification ? 'dot' : null}
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            className="notification-badge"
          >
            <ChatOutlinedIcon
              style={{
                width: '25px',
                height: '25px'
              }}
            />
          </NotificationsBadge>
        </Button>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        style={{ borderRadius: '22px !important' }}
      >
        <Card style={{ width: 400, height: 600 }}>
          <ChatHeader handleClose={handleClose} />
          {(() => {
            switch (activeScreen) {
              case 1:
                return <AllChats handleClose={handleClose} />;
              default:
                return <AllUsersList handleClose={handleClose} />;
            }
          })()}
        </Card>
      </Popover>
    </div>
  );
}
