/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { FormHelperText } from '@mui/material';

export function AutoComplete(props) {
  const {
    id,
    label,
    disabled,
    timePicker,
    error,
    fullWidth,
    className,
    freeSolo,
    helperText,
    disableClearable,
    compareForDisable,
    options,
    name,
    value,
    defaultValue,
    onChange,
    multiple
  } = props;

  const [autoValue, setAutoValue] = useState({
    label: '',
    value: ''
  });

  const handleChange = (event, newValue, type) => {
    // console.log('newValue', newValue, 'event', event && event.target.value);
    if (type === 'input') {
      if (event) {
        onChange(name, event.target.value);
      } else {
        onChange(name, value);
        setAutoValue({
          label: value,
          value: value
        });
      }
    } else if (type === 'select') {
      if (newValue && newValue.label && newValue.value) {
        onChange(name, newValue.value);
      } else {
        onChange(name, '');
      }
    }
  };

  useEffect(() => {
    setAutoValue(findObjectByValue(value));
  }, [value]);

  function findObjectByValue(value) {
    if (value && options?.length) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          return options[i];
        }
      }
    } else if (value) {
      return {
        label: value,
        value: value
      };
    }

    return {
      label: '',
      value: ''
    };
  }

  return (
    <span
      className={`custom-text-field ${timePicker && 'timePicker'} ${
        error && 'helper-error'
      } ${disabled && 'disabeld-textfield'}`}
      style={{ width: '100%' }}
    >
      <Autocomplete
        id={id}
        fullWidth={fullWidth}
        freeSolo={freeSolo}
        disabled={disabled}
        disableClearable={disableClearable}
        className={`${className}  custom-text-field`}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionDisabled={(option) =>
          compareForDisable && option.value <= compareForDisable
        }
        sx={{ fontSize: '16px !important' }}
        multiple={multiple}
        defaultValue={defaultValue}
        value={autoValue}
        onChange={(event, newValue) => handleChange(event, newValue, 'select')}
        onInputChange={(event, newValue) =>
          handleChange(event, newValue, 'input')
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputLabelProps={{
              className: `label-style-create-franchisee custom-text-field-label input-field-dropdown-styling custom-select-label`
            }}
          />
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </span>
  );
}

const options = [
  { label: 'The Shawshank Redemption', value: 1994 },
  { label: 'The Godfather', value: 1972 },
  { label: 'The Godfather: Part II', value: 1974 },
  { label: 'The Dark Knight', value: 2008 },
  { label: '12 Angry Men', value: 1957 },
  { label: "Schindler's List", value: 1993 },
  { label: 'Pulp Fiction', value: 1994 }
];
