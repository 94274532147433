import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Typography,
  Container,
  Card,
  Grid,
  
  // Divider,
  // OutlinedInput,
  styled,
  // IconButton,
  // Tooltip,
  // FormControl,
  // InputAdornment,
  // Button,
  // FormHelperText
} from '@mui/material';
import '../../style.css';
// import Logo from 'src/components/LogoSign';
import GlobalData from '../../../../global';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(75)};
`
);

const TypographyH3 = styled(Typography)(
  ({ theme }) => `
  color: ${theme.colors.alpha.black[50]};
`
);

// const OutlinedInputWrapper = styled(OutlinedInput)(
//   ({ theme }) => `
//     background-color: ${theme.colors.alpha.white[100]};
// `
// );

// const ButtonNotify = styled(Button)(
//   ({ theme }) => `
//     margin-right: -${theme.spacing(1)};
// `
// );

function ForgotRequestSent() {

  // Get the title from global data
  const title = GlobalData[0].title;

  const calculateTimeLeft = () => {
    const difference = +new Date(`2023`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box textAlign="center" px={3}>
        <TypographyH1 variant="h1">{timeLeft[interval]}</TypographyH1>
        <TypographyH3 variant="h3">{interval}</TypographyH3>
      </Box>
    );
  });

  return (
    <>
      <Helmet>
        <title>Reset Request Sent | {title}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xxl" sx={{ padding: '0px !important' }}>
          <div className='main'>
            <div className='parent-container-main'>
              <div className='logo-text'>
                <div className="zoom-room-logo">

                  <img src={'/static/images/logo/zrlogo.png'} style={{ maxWidth: '100%' }} className="logo-image" alt='zoom-room-logo' />
                </div>
                <div className="hero-text">
                  <Typography className='hero-message'>WE DON'T TRAIN DOGS.</Typography>
                  <Typography className='hero-message'>WE TRAIN THE PEOPLE WHO LOVE THEM.</Typography>
                </div>

              </div>

              <div className='form-section'>
                <div className='zoom-room-line'>
                  <div className='horizontal-line' />
                </div>
                <div className='form1'>
                  <Card className="form-area-reset-password-request">
                    <Container component="main" maxWidth="xs" className="login-page">

                      <Box display="flex"
                        justifyContent="center"
                        direction="column"
                        marginTop="0"
                        className="tick-container"
                      >
                        <img src={'/static/images/logo/Tick.png'} style={{ maxWidth: '100%' }} className="tick-image" alt='zoom-room-logo' />
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

                        <Typography className="title-text">
                          PASSWORD RESET
                        </Typography>

                        <Typography className="title-text">
                          REQUEST SENT
                        </Typography>

                        <Typography className="title-text-sub">

                          {/* You will recieve a reset link into your email */}
                          A link to reset your password will be sent to you

                        </Typography>
                        <Typography className="title-text-sub">
                          through email. Please check your email.
                        </Typography>

                      </Box>

                      {/* <Box
                                    sx={{ marginTop: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

                                    <Button type="button" fullWidth variant="contained" className='button-style-password-reset' onClick={passwordChangedRedirect}>

                                        <Typography className='button-text' >
                                            PROCEED
                                        </Typography>
                                    </Button>
                                </Box> */}

                      <div className="backbtn">
                        <Grid
                          container
                          justifyContent="center"
                          style={{ color: '#003087' }}
                        >
                          <Link
                            to="/"
                            variant="body2"
                            className="link-style back-arrow"
                          >
                            <ArrowBackIcon
                              className="back-arrow-style"
                              // style={{ margin: '-3px 2px' }}
                              style={{marginTop:'-0.8px',marginRight:'5px'}}
                            />
                            <Grid item>BACK TO LOGIN</Grid>
                          </Link>
                        </Grid>
                      </div>
                    </Container>

                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </MainContent>
    </>
  );
}

export default ForgotRequestSent;
