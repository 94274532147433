import { ApiService } from 'src/services/ApiService';

// API TO GET THE MEMBERSHIP DISCOUNT DETAILS start ----
const getMemberShipDetails = async (
  setLoading,
  payload,
  BASE_URL,
  setQueueId,
  selected,
  setSelected
) => {
  setLoading(true);
  let newItemsArr = payload?.items?.map?.((item) => {
    delete item.items;
    delete item.taxRate;
    delete item.taxAmount;
    delete item.discountDetails;

    return item;
  });
  let payloadData = { ...payload, items: newItemsArr };

  try {
    let response = await ApiService.post(
      `${BASE_URL}checkout/applyMemberShipDiscounts`,
      payloadData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.statusCode === 200) {
      let data = response.data;
      setQueueId(data?.queueId);
      setSelected({
        ...selected,
        items: data.items,
        subTotal: data.total,
        total: data.subTotal,
        totalDiscount: data.totalDiscount,
        totalPrice: data.totalPrice,
        queueId: data.queueId
      });
    }
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};
// API TO GET THE MEMBERSHIP DISCOUNT DETAILS end ----

// API FOR CHECKING PROMOCODE AVAILABILITY start ----
const checkPromoCodeExists = async (
  promoValue,
  locationId,
  BASE_URL,
  setIsAvailable,
  setCodeError,
  promoCode,
  setPromoCodeId
) => {
  let encodedLocationId = encodeURIComponent(locationId);

  try {
    let response = await ApiService.get(
      `${BASE_URL}promotions/checkPromoCodeExists?locationId=${encodedLocationId}&promoCode=${promoValue}&appType=web`
    );

    if (response.statusCode === 200) {
      setIsAvailable(response?.isExists);
      if (!response?.isExists) {
        setCodeError(`Promo code - ${promoCode} is not available`);
      } else {
        setPromoCodeId(response?.id)
        setCodeError('');
      }
    } else {
      setIsAvailable(false);
      setCodeError(`Promo code - ${promoValue} is not available`);
    }
  } catch (error) {
    setCodeError(`Promo code - ${promoValue} is not available`);
    console.error(error);
    setIsAvailable(false);
  }
};
// API FOR CHECKING PROMOCODE AVAILABILITY end ----

// API FOR PROMO CODE DISCOUNT DETAILS start ----
const getDiscountFromPromoCode = async (
  setLoading,
  locationId,
  promoCode,
  queueId,
  BASE_URL,
  isApplied,
  setIsApplied,
  selected,
  setSelected,
  promoCodeId
) => {
  setLoading(true);
  let promoPayload = {
    locationId: locationId,
    promoCode: promoCode,
    queueId: queueId,
    appType: 'web',
    promoCodeId: promoCodeId,
    clientId: selected?.client?.clientId,
  };
    if(selected?.guestClient){
      promoPayload.guestClient = true
    } else {
      promoPayload.guestClient = false
      promoPayload.petId = selected?.pet?.sortKey
    }
  try {
    let response = await ApiService.post(
      `${BASE_URL}checkout/getPromoCodeDetails`,
      promoPayload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.statusCode === 200) {
      setIsApplied({
          ...isApplied,
          codeApplied: true,
      });

      if (response.data) {
        let data = response.data;
        setSelected({
          ...selected,
          items: data.items,
          subTotal: data.subTotal,
          total: data.subTotal,
          totalDiscount: data.totalDiscount,
          totalPrice: data.totalPrice,
          queueId: data.queueId
        });
      }
    } else {
      setIsApplied({
        ...isApplied,
        codeApplied: false
      });
    }
    setLoading(false);
  } catch (error) {
    if(error?.statusCode === 400){
      setIsApplied({
        ...isApplied,
        codeApplied: false,
        message: error?.message || 'Something went wrong'
      });
    }else{
      setIsApplied({
        ...isApplied,
        codeApplied: false,
        message: error?.message || 'Something went wrong'
      });
    }
    setLoading(false);
  }
};
// API FOR PROMO CODE DISCOUNT DETAILS end ----

// API FOR CHECKING GIFT CARD AVAILABILITY start ----
const getCardNumberDetail = async (
  value,
  setGiftCardId,
  locationId,
  setCardNumber,
  setIsGiftCardAvailable,
  setCardNumberError
) => {
  try {
    let encodedLocationId = encodeURIComponent(locationId);
    const response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/isCardExists?locationId=${encodedLocationId}&giftCardNumber=${value}`
    );

    if (response.statusCode === 200) {
      if (response?.data?.result?.Items?.length) {
        setGiftCardId(response?.data?.result?.Items[0]?.sortKey);
        setCardNumber(response?.data?.result?.Items[0]?.giftCardNumber);
        setCardNumber(value);
        if (response?.data?.result?.Items?.length > 0) {
          setIsGiftCardAvailable(true);
          setCardNumberError('');
        }
        // eslint-disable-next-line array-callback-return
        response?.data?.result?.Items?.map((item) => {
          item.giftCardPriceValue = item.giftCardValue;
        });
      } else {
        setCardNumberError(response?.message);
      }
    } else {
      setCardNumberError(response?.message);
      setIsGiftCardAvailable(false);
    }
  } catch (error) {
    console.error(error);
    setCardNumberError(error?.message);
    setIsGiftCardAvailable(false);
  }
};
// API FOR CHECKING GIFT CARD AVAILABILITY end ----

// API FOR GIFT CARD DISCOUNT DETAILS start ----
const applyGiftCardDiscount = async (
  giftCardId,
  setLoading,
  locationId,
  queueId,
  BASE_URL,
  setCardNumberError,
  setIsGiftApplied
) => {
  setLoading(true);
  let promoPayload = {
    locationId: locationId,
    giftCardId: giftCardId,
    queueId: queueId
  };
  try {
    let response = await ApiService.post(
      `${BASE_URL}checkout/applyGiftCardDiscount`,
      promoPayload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.statusCode === 200) {
      if (response?.data?.giftCardValue === 0) {
        setCardNumberError(response?.message);
        setIsGiftApplied(false);
      } else {
        setIsGiftApplied(true);
      }
    } else {
      setIsGiftApplied(false);
    }
    setLoading(false);
  } catch (error) {
    setIsGiftApplied(false);
    setLoading(false);
  }
};
// API FOR GIFT CARD DISCOUNT DETAILS end ----

export {
  getMemberShipDetails,
  checkPromoCodeExists,
  getDiscountFromPromoCode,
  getCardNumberDetail,
  applyGiftCardDiscount
};
