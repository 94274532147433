import React, { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { getLocalData } from 'src/utils';
import { getLocationList } from 'src/services/getUserLocations';

const ProtectedRoute = ({ allowedRoles, element: Element }) => {
  const { userData } = useContext(UserContext); // Get the userRole from the context
  const { id } = useParams()
  console.log(
    userData.isFranchisor,
    'userData.isFranchisoruserData.isFranchisor'
  );
  let userRole =
    userData &&
    userData.isFranchisor &&
    userData.isFranchisor === 'true' &&
    userData.userRole === 'Manager'
      ? `Super ${userData.userRole}`
      : userData.userRole;
  console.log('Role --->' + userData.userRole + '<--', userRole);
  const locationId = getLocalData('locationId');
  const isFranchisor = getLocalData('isFranchisor')
  

  if(!locationId || locationId === 'undefined'){
    let data = {
        userId: getLocalData('userId'),
        franchiseeId: getLocalData('franchiseeId'),
        role: getLocalData('userRole'),
        isFranchisor: getLocalData('isFranchisor') || false
    };
    getLocationList(data)  
  }

  if (userRole === 'guest') {
    if(id) {
      sessionStorage.setItem('paramKey', id)
    }
    // Redirect to login page if the user is not authenticated
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(userRole)) {
    // Render unauthorized page if the user role is not allowed
    return <Navigate to="/unauthorized" />;
  }

  if ((!locationId || locationId === 'undefined') && (!isFranchisor || isFranchisor === 'false')) {
    // Render unauthorized page if the location is not available
      return <Navigate to="/unauthorized" />;
  }

  // Render the component for authorized users
  return Element;
};

export default ProtectedRoute;