/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';

const Receipt = ({ selected, paymentSourceType }) => {
  const clientWidth = '100%';
  const date = new Date();
  const formattedDate = date?.toLocaleDateString('en-US', {
    month: 'long', // Changed from 'numeric' to 'long'
    day: 'numeric',
    year: 'numeric'
  });

  const receiptLeft = {
    fontWeight: 'bold'
  };

  return (
    <div
      className="receipt"
      style={{
        border: '1px solid #000',
        fontFamily: 'Arial, Helvetica, sans-serif',
        padding: '10px',
        textAlign: 'center'
      }}
    >
      <h2 style={{ textAlign: 'left', width: clientWidth }}>
        {paymentSourceType === 'COMP' ? 'Comp Receipt' : 'Sales Receipt'}
      </h2>
      <div
        className="receipt-info"
        style={{
          marginBottom: '10px',
          textAlign: 'left',
          width: clientWidth
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '5px',
            marginBottom: '5px'
          }}
        >
          <p style={receiptLeft}>Client Name:</p>
          <p>
            {`${selected?.client?.firstName} ${selected?.client?.lastName}` ||
              ''}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '5px',
            marginBottom: '5px'
          }}
        >
          <p style={receiptLeft}>Date:</p>
          <p>{formattedDate}</p>
        </div>

        <div
          style={{
            display: 'flex',
            gap: '5px',
            marginBottom: '5px'
          }}
        >
          <p style={receiptLeft}>Order ID:</p>
          <p>{selected?.ZROrderId || ''}</p>
        </div>
      </div>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '50%'
              }}
            >
              Item
            </th>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '25%'
              }}
            >
              Quantity
            </th>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '25%'
              }}
            >
              Price
            </th>
            <th
              style={{
                border: '1px solid #000',
                padding: '8px',
                width: '25%'
              }}
            >
              Total Price
            </th>
          </tr>
        </thead>
        <tbody>
          {selected?.items?.map((product, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product.itemName}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                {product?.quantity || 1}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                $
                {parseFloat(
                  product?.itemType === 'Bundle' &&
                    (product?.actualPrice || product?.actualPrice === 0)
                    ? product?.actualPrice
                    : product?.price
                )?.toFixed(2)}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px' }}>
                $
                {parseFloat(
                  (product?.itemType === 'Bundle'
                    ? product.actualPrice * product.quantity
                    : product.price * product.quantity) || 0
                )?.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="totals"
        style={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'end',
          width: clientWidth
        }}
      >
        <div
          style={{
            marginTop: '10px',
            display: 'flex',

            flexDirection: 'column',
            width: '35%'
          }}
        >
          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px'
            }}
          >
            <span style={receiptLeft}>Subtotal:</span>
            <span>${parseFloat(selected?.subTotal)?.toFixed(2)}</span>
          </p>
          {parseFloat(selected?.giftCardDiscount) > 0 && (
            <p
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '5px'
              }}
            >
              <span style={receiptLeft}>Gift Card Redemption:</span>
              <span>${parseFloat(selected?.giftCardDiscount)?.toFixed(2)}</span>
            </p>
          )}
          {parseFloat(selected?.serviceFee) > 0 && (
            <p
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '5px'
              }}
            >
              <span style={receiptLeft}>Service Fee:</span>
              <span>${parseFloat(selected?.serviceFee)?.toFixed(2)}</span>
            </p>
          )}
          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px'
            }}
          >
            <span style={receiptLeft}>Total Discount:</span>
            <span>
              $
              {parseFloat(selected?.giftCardDiscount) > 0 &&
              parseFloat(selected?.discount) > 0
                ? (
                    parseFloat(selected.discount) -
                    parseFloat(selected.giftCardDiscount)
                  )?.toFixed(2)
                : parseFloat(selected?.discount ?? 0)?.toFixed(2)}
            </span>
          </p>

          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px',
              borderBottom: '1px solid black',
              paddingBottom: '10px'
            }}
          >
            <span style={receiptLeft}>Tax:</span>
            <span>${parseFloat(selected?.tax)?.toFixed(2)}</span>
          </p>

          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '5px'
            }}
          >
            <span style={receiptLeft}>Total:</span>
            <span>
              $
              {parseFloat(selected?.totalPrice + selected?.serviceFee)?.toFixed(
                2
              )}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
