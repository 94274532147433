/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { forwardRef, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Slide,
  Typography,
  Button,
  Box,
  Grid,
  FormControl,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Avatar,
  FormHelperText,
  Card,
  Select,
  MenuItem
} from '@mui/material';
import { CustomTextField, CustomButton, Toggle } from 'src/components/Global';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { PictureSnapshot } from 'src/assets/SVG/SvgIcons';
import { ApiService } from '../../../services/ApiService';
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { closest } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { getComponent } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import heic2any from 'heic2any';
import { getLocalData } from 'src/utils';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const AddTrophy = (props) => {
  const { isServiceEdit, setIsServiceEdit, trophyDetails, handleResetStates } =
    props;
  const userId = getLocalData('userId');
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [segment, setSegment] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [preValues, setPreValues] = useState({});
  const locationId = getLocalData('locationId');
  const [columns, setColumns] = useState([]);
  const [creteriaError, setCreteriaErrors] = useState('');
  const [importRules, setImportRules] = useState({});
  const [initialValue, setInitialValue] = useState({
    title: '',
    description: '',
    juniorTrophy: false,
    staffAssigned: false,
    displayOnShelf: true,
    status: '',
    sortKey: ''
  });
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  console.log(isServiceEdit, 'isServiceEditisServiceEdit');

  const [isError, setIsError] = useState({
    title: false,
    trophyImage: false
  });
  const [attributeData, setAttributeData] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
  const [imgObjClient, setimgObjClient] = useState([]);
  const [imageSelected, setImageSelected] = useState(false);
  const [imgUrl, setimgUrl] = useState('');
  const [imageError, setImageError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [imageErrorMsg, setImageErrorMsg] = useState('');

  const qryBldrObj = useRef(null);
  let dropDownObj = useRef(null);
  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });
  const [segmentErrors, setSegmentErrors] = useState([]);

  const onInputClick = (event) => {
    event.target.value = '';
  };
  useEffect(() => {
    setImportRules({
      condition: 'and',
      rules: [
        {
          label: '',
          field: '',
          type: '',
          operator: '',
          value: ''
        }
      ]
    });
    getAllAttribute()
  }, []);
  useEffect(() => {
    if (isServiceEdit) {
      if (trophyDetails && trophyDetails?.Segments) {
        setSegment(trophyDetails?.Segments);
      } else {
        setSegment([
          {
            DimensionsAttributes: [
              {
                operator: '',
                attribute: '',
                values: []
              }
            ]
          }
        ]);
      }
    } else {
      setSegment([
        {
          DimensionsAttributes: [
            {
              operator: '',
              attribute: '',
              values: []
            }
          ]
        }
      ]);
    }
  }, [isServiceEdit]);

  const dataValueToAppend = (data) => {
    return {
      create: () => {
        const elem = document.createElement('input');
        elem.setAttribute('type', 'text');
        return elem;
      },
      destroy: (args) => {
        dropDownObj.current = getComponent(
          document.getElementById(args.elementId),
          'dropdownlist'
        );
        if (dropDownObj.current) {
          dropDownObj.current.destroy();
        }
      },
      write: (args) => {
        const ds = data;
        dropDownObj.current = new DropDownList({
          dataSource: ds,
          value: args.values ? args.values : ds[0],
          change: (e) => {
            qryBldrObj.current.notifyChange(e.itemData.value, e.element);
          }
        });
        dropDownObj.current.appendTo('#' + args.elements.id);
      }
    };
  };
  useEffect(() => {
    if (columnData.length > 0) {
      const updatedColumnData = columnData.map((field) => {
        if (field?.dataValue) {
          // Append the dataValue field to the object
          return {
            ...field,
            template: dataValueToAppend(field.dataValue)
          };
        }
        return field; // Return unchanged objects
      }); // Set updateColumnData to false to prevent further updates
      setColumns(updatedColumnData);
    }
  }, [columnData]);

  useEffect(() => {
    if (isServiceEdit && trophyDetails) {
      // setServiceType(trophyDetails?.typeOfService && trophyDetails?.typeOfService.toLowerCase());
      let data = {
        sortKey: trophyDetails?.sortKey,
        title: trophyDetails?.title,
        description: trophyDetails?.description,
        juniorTrophy: trophyDetails?.juniorTrophy,
        staffAssigned: trophyDetails?.staffAssigned,
        displayOnShelf: trophyDetails?.displayOnShelf,
        trophyImage: trophyDetails?.trophyImage,
        status: trophyDetails?.status
      };

      // if trophy image available then set the imageError to be false else true
      if (trophyDetails?.trophyImage) {
        setImageError(false);
        setImageSelected(true);
        setimgUrl(trophyDetails?.trophyImage);
      } else {
        setImageError(true);
        setImageErrorMsg('Image is required');
      }
      // setIsFranchisor(trophyDetails?.isFranchisor);

      // TODO: To enable the toggle button if value is true
      // if staff assigned is truen then set the staff assigned to be true
      if (trophyDetails?.staffAssigned) {
        let elm = document.querySelector('.staff-assigned');
        if (elm) elm.click();
      }
      // if juniour trophy is truen then set the staff assigned to be true
      if (trophyDetails?.juniorTrophy) {
        let elm = document.querySelector('.junior-trophy');
        if (elm) elm.click();
      }
      // if display on self is truen then set the staff assigned to be true
      if (!trophyDetails?.displayOnShelf) {
        let elm = document.querySelector('.display-on-shelf');
        if (elm) elm.click();
      }
      setInitialValue(data);
      setPreValues(data);
    }
  }, []);

  const handleServiceInfo = (type, value) => {
    setImageError(false);
    setSegmentErrors([]);
    setFieldUpdated(true);
    if (type === 'Badges') type = 'juniorTrophy';
    if (type === 'title' || type === 'description') {
      // Update the title with the new value
      setInitialValue((prevInitialValue) => ({
        ...prevInitialValue,
        [type]: value
      }));
    } else if (type === 'duration' && value < 0) {
      // Check for negative values for the 'duration' field
      return; // Exit the function if the value is negative
    } else {
      // For other fields, toggle the value as you were doing before
      setInitialValue((prevInitialValue) => ({
        ...prevInitialValue,
        [type]: !prevInitialValue[type]
      }));
    }

    // Reset the error for the current field
    setIsError({ ...isError, [type]: false });
  };

  const valuesAreDifferent = () => {
    if (!isServiceEdit) {
      return Object.entries(initialValue).some(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0; // Consider non-empty arrays as different
        } else {
          return value !== ''; // Consider non-empty strings as different
        }
      });
    } else {
      const keys = Object.keys(initialValue);
      return keys.some((key) => initialValue[key] !== preValues[key]);
    }
  };

  const handleCloseScreen = () => {
    if (valuesAreDifferent()) {
      setDialog({ ...dialog, state: true });
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        state: false,
        isCreated: false
      });
      // handleResetStates();
    } else if (value === 'Discard') {
      handleResetStates();
      setDialog({
        state: false,
        isCreated: false
      });
    } else if (value === 'Cancel') {
      handleCloseScreen();
    } else if (value === 'Close') {
      handleResetStates();
    }
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};

    // Always check for empty values
    const alwaysCheck = ['title', 'trophyImage'];
    for (const key of alwaysCheck) {
      const value = initialValue[key];
      console.log(initialValue, 'keeeeeeeeyyyyy');
      const isEmpty =
        value === '' || (Array.isArray(value) && value.length === 0);
      updatedErrors[key] = isEmpty;

      if (isEmpty) {
        hasError = true;
      }
      if (!imageSelected) {
        // Handle the error or display a message
        hasError = true;
        setImageError(true);
        setImageErrorMsg('Image is required');
      }
    }

    setIsError(updatedErrors);
    return hasError;
  }

  const validateSegment = () => {
    const newSegmentErrors = [...segmentErrors];
    let errors = false;

    segment.forEach((currentSegment, segmentIndex) => {
      const segmentErrors = [];
      currentSegment.DimensionsAttributes.forEach((attribute) => {
        const attributeErrors = {};

        if (!attribute.attribute) {
          attributeErrors.attribute = 'Attribute cannot be empty.';
          errors = true;
        } else {
          attributeErrors.attribute = '';
        }
        if (!attribute.operator) {
          attributeErrors.operator = 'Operator cannot be empty.';
          errors = true;
        } else {
          attributeErrors.operator = '';
        }
        if (attribute.values.length) {
          if (attribute.values.some((value) => !value)) {
            attributeErrors.values = 'Values cannot be empty.';
            errors = true;
          } else {
            attributeErrors.values = '';
          }
        } else {
          attributeErrors.values = 'Values cannot be empty.';
          errors = true;
        }

        segmentErrors.push(attributeErrors);
      });

      newSegmentErrors[segmentIndex] = segmentErrors;
    });

    setSegmentErrors(newSegmentErrors);
    return errors;
  };
  const handleFormSubmission = async (e) => {
    e.preventDefault();
    let hasSegmentErrors;
    let hasEmptyValues;
    if (initialValue.staffAssigned) {
      hasEmptyValues = checkForEmptyValues();
    } else {
      hasSegmentErrors = validateSegment();
      hasEmptyValues = checkForEmptyValues();
    }

    if (hasSegmentErrors || hasEmptyValues) {
      return;
    }
    try {
      if (isServiceEdit) {
        await handleUpdateTrophy();
      } else {
        await CreateTrophy();
      }
    } catch (error) {
      console.error(
        'An error occurred while checking cohort name existence:',
        error
      );
    }
  };

  const handleEditDialog = (value) => {
    if (value === 'Continue') {
      handleUpdateTrophy();
      setConfirmEdit(false);
    } else if (value === 'Discard') {
      setBtnLoading(false);
      setConfirmEdit(false);
    }
  };

  const CreateTrophy = async () => {
    setIsLoading(true);
    const data = {
      title: initialValue.title,
      juniorTrophy: initialValue.juniorTrophy,
      description: initialValue.description,
      staffAssigned: initialValue.staffAssigned,
      displayOnShelf: initialValue.displayOnShelf,
      createdBy: userId
    };

    if (!initialValue.staffAssigned) {
      data.Segments = segment;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}trophy/cohort/createCohortTrophy`,
        data
      );

      if (!response.data?.error && response.data.data.sortKey) {
        setIsLoading(false);

        if (imgObjClient) {
          const formData = new FormData();
          formData.append('franchisorId', response.data.data.partitionKey);
          formData.append('trophyId', response.data.data.sortKey);
          formData.append('trophyImage', imgObjClient);

          await Promise.all([
            addTrophyImage(formData)
            // Add the second API call here if needed
          ]);
        }
        // setShowSuccessMessage(true);
        // setSuccessMessage('Trophy created successfully');
      } else {
        setIsLoading(false);
        setShowMessage(true);
        setErrorMessage(
          response.data?.message || 'Something went wrong, try later.'
        );
      }
      setBtnLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
      setBtnLoading(false);
      console.error(error);
      // console.log('Something went wrong, Try again!');
    }
  };

  const addTrophyImage = async (formData) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}trophy/cohort/addTrophyImage`,
        formData
      );
      if (!response.data?.error) {
        setIsLoading(false);

        setIsServiceEdit(false);
        setFieldUpdated(false);
        setIsImageUpload(false);
        setConfirmEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
      } else {
        setIsLoading(false);

        // toast.error(response.data?.message || 'Something went wrong with add Service');
        // console.log(
        //   response.data?.message || 'Something went wrong with add Service'
        // );
      }

      setBtnLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      console.error(error);
      setBtnLoading(false);
      // toast.error('Something went wrong, Try again!');
      console.log('Something went wrong, Try again!');
    }
  };
  const handleUpdateTrophy = async () => {
    // const encodedLocationId = encodeURIComponent(locationId);=
    setIsLoading(true);
    const data = {
      title: initialValue.title,
      juniorTrophy: initialValue.juniorTrophy,
      franchisorId: trophyDetails.partitionKey,
      description: initialValue.description,
      staffAssigned: initialValue.staffAssigned,
      displayOnShelf: initialValue.displayOnShelf,
      updatedBy: 'name',
      trophyId: initialValue.sortKey,
      Id: trophyDetails.Id
    };
    if (!initialValue.staffAssigned) {
      data.logicalOperator = 'AND';
      data.Segments = segment;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}trophy/cohort/updateCohortTrophy`,
        data
      );
      if (!response.data?.error) {
        setIsLoading(false);
        if (isImageUpload && imgObjClient) {
          const formData = new FormData();
          formData.append(
            'franchisorId',
            response.data.data.Attributes.partitionKey
          );
          formData.append('trophyId', response.data.data.Attributes.sortKey);
          formData.append('trophyImage', imgObjClient);

          await Promise.all([
            addTrophyImage(formData)
            // Add the second API call here if needed
          ]);
        }

        //handleResetStates();
        setIsServiceEdit(true);
        setFieldUpdated(false);
        setConfirmEdit(false);
        setDialog({
          isCreated: true,
          state: true
        });
        // handleCloseScreen();
      } else {
        // toast.error( response.data?.message || 'Something went wrong with update Service');
      }

      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // toast.error('Something went wrong, Try again!');
      console.log('Something went wrong, Try again!');
    }
  };

  const handleUploadTrophyImage = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();
      setIsImageUpload(true);

      console.log(file, 'filefile');

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setimgUrl(URL.createObjectURL(file));
        setImageSelected(true);
        setimgObjClient(file);
        setImageError(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setimgUrl(URL.createObjectURL(jpegFile));
          setImageSelected(true);
          setimgObjClient(jpegFile);
          setImageError(false);
        } catch (error) {
          setImageSelected(false);
          console.error('Error handling HEIC file:', error);
          setImageError(true);
          setimgUrl('');
          setimgObjClient([]);
          setImageErrorMsg('Error handling HEIC file.');
        }
      } else {
        setImageSelected(false);
        setImageError(true);
        setimgUrl('');
        setimgObjClient([]);
        setImageErrorMsg(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  // Cross icon handler
  const removeImage = () => {
    setImageSelected(false);
    setimgUrl('');
    setimgObjClient([]);
    setImageError(true);
    setImageErrorMsg('Image is required');
  };
  const getAllAttribute = async () => {
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAttributeFilter?locationId=${encodedLocationId}`
      );

      if (!response.error) {
        setColumnData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log('ImageError', imageError);
  }, [imageError, initialValue.title]);

  const handleQueryBuilderChange = () => {
    setCreteriaErrors('');
  };
  const headerTemplate = (props) => {
    let items;
    let fields;

    items = [
      { key: 'AND', value: 'and' },
      { key: 'OR', value: 'or' }
    ];
    fields = { text: 'key', value: 'value' };
    let state = { ...props };
    const conditionChange = (args) => {
      qryBldrObj.current.notifyChange(args.value, args.element, 'condition');
    };
    const addGroupClick = (args) => {
      let a = qryBldrObj.current.getRules();
      if (a.rules[0].field) {
        let addbtn = args.currentTarget.offsetParent.id;
        let ddb = addbtn.split('_');
        qryBldrObj.current.addGroups(
          [{ condition: 'and', rules: [{}] }],
          ddb[1]
        );
      }
    };
    const addRuleClick = (args) => {
      let addbtn = args.currentTarget.offsetParent.id;
      let ddb = addbtn.split('_');
      qryBldrObj.current.addRules([{}], ddb[1]);
    };
    const onClick = (args) => {
      qryBldrObj.current.deleteGroup(
        closest(args.target.offsetParent, '.e-group-container')
      );
    };
    const args = state;
    return (
      <div className="query-template-control">
        <div className="e-groupheader">
          <DropDownListComponent
            id={args.ruleID + '_cndtn'}
            cssClass="e-custom-group-btn"
            width="100px"
            dataSource={items}
            fields={fields}
            value={args.condition}
            change={conditionChange}
          />
          <div className="e-header">
            {(() => {
              if (
                args.ruleID === 'querybuilder_group0' ||
                args.groupID === null
              ) {
                return (
                  <div className="e-qb-hdr-content">
                    <ButtonComponent
                      className="e-grp-btn btn-style-cohort"
                      cssClass="e-primary"
                      onClick={addGroupClick}
                    >
                      Add Group
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}

            <div className="e-qb-hdr-content">
              <ButtonComponent
                className="e-cond-btn btn-style-cohort"
                cssClass="e-primary"
                onClick={addRuleClick}
              >
                Add Condition
              </ButtonComponent>
            </div>

            {(() => {
              if (
                args.ruleID !== 'querybuilder_group0' ||
                args.groupID !== null
              ) {
                return (
                  <div className="e-qb-hdr-content ">
                    <ButtonComponent
                      id={args.ruleID + '_dltbtn'}
                      cssClass="e-danger"
                      onClick={onClick}
                      className="btn-style-cohort"
                    >
                      Remove
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <div className="service-container">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={1}
          mb={4}
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleCloseScreen()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {isServiceEdit ? 'Edit Trophy' : 'Create a Trophy'}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <CustomButton
                variant="contained"
                type="submit"
                onClick={handleFormSubmission}
                className="common-button"
                loading={btnLoading}
              >
                <Typography>{isServiceEdit ? 'Update' : 'Save'}</Typography>
              </CustomButton>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form" onSubmit={handleFormSubmission}>
            <div>
              <Grid container spacing={2} columns={12} mt={5}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    type="text"
                    name="title"
                    label="TITLE"
                    fullWidth
                    // error={isError.name}
                    required={Boolean(true)}
                    helperText={isError.title && 'Title is required'}
                    value={initialValue.title}
                    onChange={handleServiceInfo}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={4} paddingRight="50px">
                  <Toggle
                    fullWidth
                    spaceBetween
                    name="Badges"
                    label="Badges"
                    labelPlacement="start"
                    value={Boolean(initialValue.juniorTrophy)}
                    onChange={handleServiceInfo}
                    className="junior-trophy"
                  />
                </Grid>
                <Grid item xs={12} sm={4} paddingRight="50px">
                  <Toggle
                    fullWidth
                    spaceBetween
                    name="staffAssigned"
                    label="Staff Assigned"
                    labelPlacement="start"
                    value={initialValue.staffAssigned}
                    onChange={handleServiceInfo}
                    className="staff-assigned"
                  />
                </Grid>
                <Grid item xs={12} sm={4} paddingRight="50px">
                  <Toggle
                    fullWidth
                    spaceBetween
                    name="displayOnShelf"
                    label="Display On The Shelf"
                    labelPlacement="start"
                    value={initialValue.displayOnShelf}
                    onChange={handleServiceInfo}
                    className="display-on-shelf"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="DESCRIPTION"
                    multiline
                    rows="6"
                    type="text"
                    name="description"
                    value={initialValue.description}
                    onChange={handleServiceInfo}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={10} md={10}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    className={`${imageSelected ? null : 'mt-10'}`}
                  >
                    {imageSelected ? (
                      <Box className="relative">
                        <Avatar
                          className="image-avatar"
                          src={imgUrl !== '' ? imgUrl : null}
                          sx={{
                            marginTop: '40px',
                            height: '165px',
                            width: '165px',
                            borderRadius: 'none',
                            marginRight: '30px'
                          }}
                        />
                        <div
                          className="cross-imageicon text-blue"
                          role="button"
                          onClick={() => removeImage()}
                          onKeyDown={() => removeImage()}
                          tabIndex={0}
                        >
                          x
                        </div>
                      </Box>
                    ) : (
                      <PictureSnapshot />
                    )}

                    <Box
                      className={`required-field ${
                        imageSelected ? 'mt-40' : null
                      } select-image`}
                      sx={{
                        marginLeft: imageSelected ? '0px' : '50px',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                        {isServiceEdit ? 'Edit' : 'Upload'} Trophy Image
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        id="select-file"
                        name="profileImage"
                        style={{ display: 'none' }}
                        onClick={onInputClick}
                        onChange={(event) => handleUploadTrophyImage(event)}
                      />
                      <label htmlFor="select-file">
                        <Button
                          type="button"
                          variant="contained"
                          component="span"
                          className="upload-image image-with-txt common-button"
                          sx={{ marginTop: '24px' }}
                        >
                          <Typography className="upload-text">
                            Upload
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                  </Box>
                  {imageError && (
                    <FormHelperText>{imageErrorMsg}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </div>
            <Card
              sx={{
                padding: '15px',
                backgroundColor:'rgba(248, 251, 255, 1)',
                borderRadius: '19px 19px 0px 0px',
                mt: 5,
                opacity: initialValue.staffAssigned ? '0.5' : '1',
                pointerEvents: initialValue.staffAssigned ? 'none' : 'auto'
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                ml={2}
              >
                <Grid item>
                  <Typography className="table-heade header-blue-20">
                    {' '}
                    Criteria
                  </Typography>
                </Grid>
              </Grid>
          
                {columns.length > 0 && (
                  <div className="control-pane" >
                    <div className="control-section">
                      <div className="col-lg-12 control-section">
                        <QueryBuilderComponent
                        
                          id="querybuilder"
                          columns={columns}
                          change={handleQueryBuilderChange}
                          rule={importRules}
                          headerTemplate={headerTemplate}
                          ref={qryBldrObj}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <FormHelperText>{creteriaError}</FormHelperText>
             
            </Card>
          </form>

          <Dialog
            open={dialog.state}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              {dialog.isCreated ? 'Congratulations!' : 'Discard Changes?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  {!dialog.isCreated ? (
                    <Typography className="title-text-sub">
                      You have unsaved changes.
                      <Typography className="title-text-sub">
                        What do you want to do?
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography className="title-text-sub">
                      {`Trophy has been ${
                        isServiceEdit ? 'updated' : 'created'
                      } successfully!`}
                    </Typography>
                  )}
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleDialog(dialog.isCreated ? 'Close' : 'Discard')
                }
              >
                {dialog.isCreated ? 'Close' : 'Discard Changes'}
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleDialog('Continue')}
                >
                  Go Back to Editing
                </Button>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            open={confirmEdit}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleEditDialog('Cancel')}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="alert-title align-center">
              Save Changes
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                className="alert-decription align-center"
              >
                <Box display="flex" justifyContent="center" direction="column">
                  <Typography className="title-text-sub">
                    Please confirm that you want to save your changes?
                  </Typography>
                </Box>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions className="alert-button-control">
              <Button
                className="font-17 text-black no-hover"
                onClick={() =>
                  handleEditDialog(dialog.isCreated ? 'Discard' : 'Discard')
                }
              >
                Go Back to Editing
              </Button>
              {!dialog.isCreated && <Divider className="vertical-divider" />}
              {!dialog.isCreated && (
                <Button
                  className="font-17 no-hover"
                  onClick={() => handleEditDialog('Continue')}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      </div>
    </>
  );
};
