import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, Stack, Divider, IconButton } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
// import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderMenu from '../layouts/SidebarLayout/Header/Menu';
import { useContext } from 'react';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

const Header = ({ title, buttons, sx, backArrowIcon }) => {
  const { toggleSidebar } = useContext(SidebarContext);
  // let half;
  // // window width
  // if (window.innerWidth < 1280) {
  //   half = '';
  // } else {
  //   half = sidebarToggleHalf ? '' : 'header-wrapper-half';
  // }
  // console.log(half);
  return (
    <AppBar
      position="static"
      sx={{ background: 'transparent', boxShadow: 'none' }}
      className='header-class'
    >
      <Toolbar>
        {/* <Box
        component="span"
        sx={{
          // ml: 2,
          mr: 3,
          display: { lg: 'none', xs: 'inline-block' },
          position: { lg: 'absolute' }
        }}
        className="hamburger-cont"
      > */}
        {/* <Tooltip arrow title="Menu"> */}
        {/* <IconButton
          color="primary"
          onClick={toggleSidebar}
          className="hamburger-icon-btn"
          title="hamburger-menu"
        >
          {!sidebarToggle ? (
            <MenuTwoToneIcon fontSize="small" />
          ) : (
            <CloseTwoToneIcon fontSize="small" />
          )}
        </IconButton> */}
        {/* </Tooltip> */}
        {/* </Box> */}
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          alignItems="center"
          mr={0}
          // width= "calc(100% - 100px)"

          spacing={2}
        >
          <HeaderMenu />
        </Stack>
        <Typography
          className="header-blue-40 hamburger-cont"
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: { xs: 'flex' } }} // Added display flex for small view to add arrow icon
        >
          {/* Below code for Header Icon */}
          <IconButton
            color="primary"
            className="hamburger-icon-btn"
            title="hamburger-menu"
            onClick={toggleSidebar}
            sx={{
              display: { lg: 'none', xs: 'inline-flex' }, mt: { xs: '14px' }, mr: { xs: '25px' } // Added mt and mr for small view with arrow adjust
            }}
          >
            <MenuTwoToneIcon fontSize="large" />
          </IconButton>{' '}

          {/* Added support to add arrow icon with title */}
          {backArrowIcon?.show ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start'
              }}
            >
              <Box
                onClick={backArrowIcon.onClick}
                className="custom-back-arrow-close"
              >
                {/* left arrow icon */}
                <ArrowLeft />
              </Box>
              <Typography className="header-blue-40">
                {title}
              </Typography>
            </Box>
          ) : (
            title
          )}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
            mt: 1
          }}
        >
          {buttons?.map((button, index) => (
            <Button
              key={index}
              color="primary"
              variant="contained"
              onClick={button.onClick}
              sx={{ sx }}
            >
              {button.label}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
