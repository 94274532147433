/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Autocomplete,
  FormHelperText
} from '@mui/material';
import Loader from '../../../components/Loader';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SignaturePad from 'react-signature-canvas';
import './addClient.css';
import { getFileFromUrl, formatPhoneNumber } from '../../../utils/index';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { getLocalData } from '../../../utils';
// import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { makeStyles } from '@material-ui/core/styles';
import { ApiService } from '../../../services/ApiService';
import { CustomDatePickerForAllBrowser } from 'src/components/CustomDatePickerForAllBrowser';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import heic2any from 'heic2any';
import SearchIcon from '@mui/icons-material/Search';

const AddClient = ({ FranchiseeAdded, Transition, setShowScreen }) => {
  const signatureCanvasRef = useRef();
  const [imageURL, setImageURL] = React.useState(null);
  const [signImageFile, setSignImageFile] = React.useState(null);

  // create a custom theme with updated hover and close icon colors
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();

  let userId = getLocalData('userId');
  let isValid = true;
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationName = getLocalData('locationName');
  const franchiseeName = getLocalData('franchiseeName');

  const locationId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedLocationId')
      : getLocalData('locationId');
  const franchiseeId =
    isFranchisor === 'true'
      ? localStorage.getItem('SelectedFranchiseeId')
      : getLocalData('franchiseeId');
  // const userRole = getLocalData('userRole');
  /* State for add client */

  const currentDate = new Date(); // Today
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 20);
  const minDateValue = new Date(minDate); // 20 years ago
  const [openPopup, setOpenPopup] = React.useState(false);
  // const [name, setname] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [address1, setAddress1] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [referralResource, setReferralResource] = useState('');
  const [location, setLocation] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setemail] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [selectedFile, setSelectedFile] = useState(null);
  const [zipcode, setZipcode] = useState('');
  // const [clientDob, setClientDob] = useState('');
  // const [cities, setCities] = useState([]);
  const [setstate, setIsState] = useState([]);
  // const [homePhone, setHomePhone] = useState('');
  // const [workPhone, setWorkPhone] = useState('');
  // const [emailEmergancy, setemailEmergancy] = useState('');
  // const [phone, setPhone] = useState('');
  // const [relationship, setrelationship] = useState('');
  const [locationDetails, setlocationDetails] = useState([]);
  const [liabilityWaiver, setliabilityWaiver] = useState('');
  const [liabilityWaiverId, setliabilityWaiverId] = useState('');
  // const [isStepOneValid, setStepOneValid] = useState(false);
  const [imgObjClient, setimgObjClient] = useState([]);
  const [imgUrlClient, setimgUrlClient] = useState('');

  /* States for error messages in client add */
  const [locationError, setlocationError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [stateError, setStateError] = useState('');
  const [cityError, setCityError] = useState('');
  // const [dobError, setDobError] = useState('');
  const [referralResourceError, setReferralResourceError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [emailError, setemailError] = useState('');
  // const [emailEmergancyError, setemailEmergancyError] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  // const [homePhoneError, setHomePhoneError] = useState('');
  // const [workPhoneError, setWorkPhoneError] = useState('');
  // const [emergencyPhoneError, setEmergencyPhoneError] = useState('');

  /* States for setting details of pet information */

  const [petFirstName, setpetFirstName] = useState('');
  const [petLastName, setpetLastName] = useState('');
  const [birthDate, setbirthDate] = useState('');
  const [gender, setgender] = useState('');
  const [acquisitionSource, setacquisitionSource] = useState('');
  const [breed, setbreed] = useState('');
  // const [veterinarian, setveterinarian] = useState(false);
  const [veterinarian, setveterinarian] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [imgUrl, setimgUrl] = useState('');
  const [allergies, setallergies] = useState([]);
  const [bark, setbark] = useState('');
  const [strangers, setstrangers] = useState('');
  const [biting, setbiting] = useState('');
  const [possessive, setpossessive] = useState('');
  const [advanced, setadvanced] = useState('');
  const [social, setsocial] = useState('');
  const [orientation, setorientation] = useState(false);
  const [evaluation, setevaluation] = useState(false);
  const [agility, setagility] = useState(false);
  const [tricks, settricks] = useState(false);
  const [rabiesExpiration, setrabiesExpiration] = useState('');
  const [bordetellaExpiration, setbordetellaExpiration] = useState('');
  const [dhppExpiration, setdhppExpiration] = useState('');
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);

  /* State Error message for pet information validation */
  const [petFirstNameError, setpetFirstNameError] = useState('');
  const [birthDateError, setbirthDateError] = useState('');
  const [genderError, setgenderError] = useState('');
  const [acquisitionSourceError, setacquisitionSourceError] = useState('');
  const [breedError, setbreedError] = useState('');
  // const [veterinarianError, setveterinarianError] = useState("");
  // const [allergiesError, setallergiesError] = useState('');
  // const [orientationError, setorientationError] = useState('');
  // const [evaluationError, setevaluationError] = useState('');
  const [petNotes, setPetNotes] = useState('');
  const [breeds, setbreeds] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  // const [openAddClient, setopenAddClient] = useState(false);
  // show error message
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [openAddClientPopUp, setOpenClientPopUp] = useState(false);

  const [existingClient, setExistingClient] = useState(null);
  const [businessEntity, setBusinessEntity] = useState(null);
  const [openAddReferralSource, setOpenAddReferralSource] = useState(false);
  const [openLiabilitySigned, setOpenLiabilitySigned] = useState(false);
  const [referalSourceList, setReferalSourceList] = useState([]);
  const [existingClientList, setExistingClientList] = useState([]);
  const [isExistingClient, setIsExistingClient] = useState('');
  const [businessEntityList, setBusinessEntityList] = useState([]);
  const [sourceName, setSourceName] = useState('');
  const [sourceNameError, setSourceNameError] = useState('');
  // const [sourceEmail, setSourceEmail] = useState('');
  // const [sourceEmailError, setSourceEmailError] = useState('');
  const [veterinarianList, setVeterinarianList] = useState([]);
  const [isLiabilityWaiverSigned, setIsLiabilityWaiverSigned] = useState(false);
  const [acquisitionList, setAcquisitionList] = useState([]);
  const [allergiesList, setAllergiesList] = useState([]);
  const [multipleFile, setMultipleFile] = useState([]);
  const [productImage, setProductImage] = useState([]);
  const [imageError, setImageError] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [profileImageError, setProfileImageError] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [clientLoading, setClientLoading] = useState(false);

  // Method to save the signature
  const saveSignature = async () => {
    let fileSign;
    if (
      signatureCanvasRef &&
      signatureCanvasRef.current &&
      signatureCanvasRef.current.isEmpty() === false &&
      signatureCanvasRef.current.getTrimmedCanvas()
    ) {
      setImageURL(
        signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
      );
      // convert base64 to file object if signature Image await
      fileSign = await getFileFromUrl(
        signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
      );

      setSignImageFile(fileSign);
      setIsLiabilityWaiverSigned(true);
    } else {
      setImageURL('');
      setSignImageFile('');
      setIsLiabilityWaiverSigned(false);
    }
    return fileSign;
  };

  useEffect(() => {}, [signImageFile, imageURL]);

  const steps = ['Add Client Info', 'Add Dog Info'];

  useEffect(() => {
    getReferalSource();
    getBreed();
    getAllAcquisition();
    getAllAllergies();
    // getCities();
    getState();
    getBusinessEntity();
    getClientList();
    // setopenAddClient(true);
    getLocation();
    getLiabilityWaiver();
    getVeterinarian();
  }, []);

  useEffect(() => {
    // Set the logged in location
    if (isFranchisor === 'false') {
      setLocation(locationId);
    }
    // if (locationId) setLocation(locationId);
  }, [locationId]);

  useEffect(() => {
    // Set the logged in location
    if (isFranchisor === 'false') {
      let locId = getLocalData('locationId');
      if (locId) {
        const interval = setInterval(() => {
          setLocation(locationId);
        }, 100);
        return () => clearInterval(interval);
      }
      setLocation(locId);
    }
    // if (locationId) setLocation(locationId);
  }, []);

  // Check if unsaved changes are there and loading/closing browser tab
  useEffect(() => {
    if (isFieldUpdated) window.addEventListener('beforeunload', alertUnsaved);
    // return () => {
    //   window.removeEventListener("beforeunload", alertUnsaved);
    // };
  }, [isFieldUpdated]);

  // Unsaved changes alert
  const alertUnsaved = (e) => {
    e.preventDefault();
    e.returnValue = 'You have unsaved changes, what do you want to do?';
  };

  const getLiabilityWaiver = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}liability-waiver/recent`
      );
      let data = response;
      if (data.statusCode && !data.error) {
        // setliabilityWaiver(data.data.content);
        // let a = data.data.content;
        setliabilityWaiver(data.data.content);
        setliabilityWaiverId(data.data.sortKey);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to Get cities in drop down */

  // const getCities = async () => {
  //   fetch(`${process.env.REACT_APP_API_END_POINT}locations/getAllCities`, {
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*'
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.statusCode && !data.error) {
  //         let Item = data.data.Items.slice(0, 50);

  //         setCities(Item);
  //       }
  //     });
  // };

  /* Function to get the referal source */

  const getReferalSource = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getReferralSource?franchiseeId=${encodedFranchiseeId}`
      );
      if (response.statusCode === 200) {
        setReferalSourceList(response.data);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get the existing client */

  const getClientList = async (searchText) => {
    setClientLoading(true);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/getClientsBySearch?searchText=${searchText}`
      );
      if (response.statusCode === 200) {
        setExistingClientList(response.data.Items);
        setClientLoading(false);
      } else {
        setClientLoading(false);
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get the existing client */

  const getBusinessEntity = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getBusinessEntityBySearch?locationId=${encodedLocationId}`
      );
      if (response.statusCode === 200) {
        setBusinessEntityList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get state in drop down */
  const getState = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`
      );
      if (response.statusCode === 200) {
        // let Item = response?.data?.Items.slice(0, 50);
        let Item = response?.data?.Items || [];
        setIsState(Item);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get location details */
  const getLocation = async () => {
    let url;
    // let encodedLocationId = encodeURIComponent(locationId);
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && franchiseeId) {
      url = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      url = `${process.env.REACT_APP_API_END_POINT}locations/getFranchiseeWiseLocationList?id=${encodedFranchiseeId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500`;
    }

    try {
      const response = await ApiService.get(`${url}`);
      if (response.statusCode === 200) {
        let Item = response?.data?.Items.slice(0, 50);
        setlocationDetails(Item);
        if (isFranchisor === 'true') {
          let locId = localStorage.getItem('SelectedLocationId');
          if (locId) {
            setLocation(locId);
          } else {
            setLocation(Item && Item?.length > 0 && Item[0]?.locationId);
          }
        }
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to clear the signature */
  const handleSignature = () => {
    if (imageURL) setImageURL(null);
    if (signatureCanvasRef && signatureCanvasRef.current)
      signatureCanvasRef.current.clear();

    setIsLiabilityWaiverSigned(false);
  };

  const handleClosePopup = (val) => {
    if (val === 'Save') {
      setOpenPopup(false);
      setOpenClientPopUp(false);
    } else if (val === 'Discard') {
      setOpenPopup(false);
      setShowScreen(0);
      setOpenClientPopUp(false);
      setShowSuccessPopup(false);
    }
  };

  // Function to close modal popUp

  const handleClose = () => {
    if (isFieldUpdated) {
      setOpenPopup(true);
    } else {
      setOpenPopup(false);
      setShowScreen(0);
    }
  };
  // Function to close modal popUp

  const handleConfClose = (val) => {
    if (val === 'Cancel') {
      setOpenLiabilitySigned(false);
    } else if (val === 'Close') {
      setOpenLiabilitySigned(false);
      setActiveStep(activeStep + 1);
    }
  };

  const getStateFromSortKey = (sortKey) => {
    const foundObject = locationDetails?.find(item => item?.sortKey === sortKey);
    return foundObject ? foundObject?.state : null;
  };

  /* Function to create client */

  // eslint-disable-next-line no-unused-vars
  const createClientDetails = async () => {
    setShowError(false);
    setErrorMessage('');
    setIsLoading(true);
    const formData = new FormData();
    formData.append('locationId', location);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('mobilePhone', mobileNumber);
    // formData.append('workPhone', workPhone);
    formData.append('franchiseeId', franchiseeId);
    // formData.append('homePhone', homePhone);
    // formData.append('birthDate', ''); // clientDob
    formData.append('addressLine1', address);
    formData.append('addressLine2', address1);
    formData.append('state', selectedState);
    formData.append('city', selectedCity);
    formData.append('referralSource', referralResource);
    // formData.append('emergencyContactName', '');
    // formData.append('emergencyContactPhone', '');
    // formData.append('emergencyContactRelationShip', '');
    // formData.append('emergencyContactEmail', '');
    formData.append('isLiabilityWaiverSigned', isLiabilityWaiverSigned);
    formData.append('postalCode', zipcode);
    formData.append('createdBy', userId);
    formData.append('liabilityWaiverId', liabilityWaiverId);
    formData.append('liabilityWaiverContent', liabilityWaiver);
    formData.append('locationState', getStateFromSortKey(location))
    if (imgObj) formData.append('profileImage', imgObjClient);
    if (signImageFile) formData.append('signatureImage', signImageFile);

    // let referralData = {
    //   referralSourceId: businessEntity?.sortKey || existingClient?.sortKey,
    //   referralSourceName:
    //     businessEntity?.businessName || existingClient?.firstName,
    //   referralSourceEmail: businessEntity?.contactEmail || existingClient?.email
    // };
    let referralData = {};
    if (isExistingClient === 'client') {
      referralData.referralSourceId = existingClient?.sortKey;
      let fname = existingClient?.firstName;
      let lname = existingClient?.lastName;
      let fullname;
      if (fname) {
        if (lname) {
          fullname = fname + ' ' + lname;
        } else {
          fullname = fname;
        }
      }
      referralData.firstName = fname;
      referralData.lastName = lname;
      referralData.referralSourceName = fullname;
      // existingClient.firstName + ' ' + existingClient.lastName;
      referralData.referralSourceEmail = existingClient?.email;
    } else if (isExistingClient === 'businessEntity') {
      referralData.referralSourceId = businessEntity?.sortKey;
      referralData.referralSourceName = businessEntity?.businessName;
      referralData.referralSourceEmail = businessEntity?.contactEmail;
    }
    formData.append('referralDetails', JSON.stringify(referralData));

    // let liabilityWaiverObj = {
    //   liabilityWaiverId: liabilityWaiverId,
    //   content: liabilityWaiver
    // };
    // // if isLiabilityWaiverSigned is true then only add the liability waiver
    // if (isLiabilityWaiverSigned) {
    //   formData.append('liabilityWaiver', JSON.stringify(liabilityWaiverObj));
    // }
    // console.log("formData", liabilityWaiverObj)

    try {
      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}client`,
        formData
      );
      const data = response;
      if (data.statusCode === 200) {
        setShowSuccessPopup(true);
        let id = data.data.clientId;

        // Now add the pet
        addPet(id);
        setOpenClientPopUp(true);
      } else {
        setShowError(true);
        setErrorMessage(data?.message ?? data?.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setShowError(true);
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  // Method to add the pet
  const addPet = async (clientId) => {
    setShowError(false);
    setErrorMessage('');
    let clId = clientId;

    if (clientId) {
      // Now show the loader
      setIsLoading(true);

      // let imagePath = (imgObj) ? String(imgObj) : '';
      // Use form data to send the body
      let formData = new FormData();
      formData.append('clientId', clientId);
      formData.append('firstName', petFirstName);
      formData.append('lastName', petLastName);
      formData.append('profileImage', imgObj);
      formData.append('birthDate', birthDate || '');
      formData.append('gender', gender);
      formData.append('acquisitionSource', acquisitionSource);
      formData.append('breed', breed);
      formData.append('yourDog', petNotes);
      formData.append('veterinarian', veterinarian);
      formData.append('allergies', allergies);
      formData.append('barking', bark);
      formData.append('strangers', strangers);
      formData.append('biting', biting);
      formData.append('possessive', possessive);
      formData.append('advanced', advanced);
      formData.append('social', social);
      formData.append('completed[orientation]', orientation ? 'Yes' : 'No');
      formData.append('completed[evaluation]', evaluation ? 'Yes' : 'No');
      formData.append('completed[agility]', agility ? 'Yes' : 'No');
      formData.append('completed[tricks]', tricks ? 'Yes' : 'No');
      formData.append('rabiesExpiration', rabiesExpiration || '');
      formData.append('bordetellaExpiration', bordetellaExpiration || '');
      formData.append('dhppExpiration', dhppExpiration || '');
      formData.append('createdBy', userId);
      formData.append('franchiseeId', franchiseeId);
      formData.append('franchiseeName', franchiseeName);
      formData.append('locationId', locationId);
      formData.append('locationName', locationName);
      try {
        const response = await ApiService.post(
          `${process.env.REACT_APP_API_END_POINT}client/addChild`,
          formData
        );
        const data = response;

        if (data.statusCode === 200) {
          let childId = data.data.childId;
          let clientId = clId;

          // Now check the file length and add the pet document
          if (multipleFile && multipleFile.length > 0) {
            sessionStorage.setItem('cId', clientId);
            sessionStorage.setItem('petId', childId);
            for (let i = 0; i < multipleFile.length; i++) {
              let clientId = clId || sessionStorage.getItem('cId');
              let petId = childId || sessionStorage.getItem('petId');

              // Now call the method to add the pet document
              addPetDocument(clientId, petId, multipleFile[i]);
            }
          }

          // Now hide the loader
          setIsLoading(false);
          FranchiseeAdded();
        } else {
          // Now hide the loader
          setIsLoading(false);
          // setOpen(false);
          setShowError(true);
          setErrorMessage(data.message);
        }
      } catch (error) {
        console.error(error);
        setShowError(true);
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    }
  };

  /* Function to Submit details of client  */
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let mobileNumberValid = true;
    let isemailValid = true;
    let isMobileValid = true;

    if (!imageURL) saveSignature();

    // if (!location) {
    //   isValid = false;
    //   setlocationError('Location is required');
    // } else {
    //   setlocationError('');
    //   isValid = true;
    // }

    if (!firstName) {
      isValid = false;
      setFirstNameError('First name is required');
    } else {
      setFirstNameError('');
      isValid = true;
    }

    if (!lastName) {
      isValid = false;
      setLastNameError('Last name is required');
    } else {
      setLastNameError('');
      isValid = true;
    }
    if (emailError) {
      isemailValid = false;
    } else if (!email) {
      setemailError('Email is required');
      isValid = false;
    } else {
      setemailError('');
      isValid = true;
    }

    // if (!address) {
    //   setAddressError('Address is required');
    //   isValid = false;
    // } else {
    //   setAddressError('');
    //   isValid = true;
    // }

    // if (!selectedState) {
    //   setStateError('State is required');
    //   isValid = false;
    // } else {
    //   setStateError('');
    // }

    // if (!selectedCity) {
    //   setCityError('City is required');
    //   isValid = false;
    // } else {
    //   setCityError('');
    //   isValid = true;
    // }

    // if (!zipcode) {
    //   setZipcodeError('Zip Code is required');
    //   isValid = false;
    // }

    // else {
    //   setZipcodeError('');
    //   isValid = true;
    // }

    // if (!referralResource) {
    //   setReferralResourceError('Referral Resource is required');
    //   isValid = false;
    // } else {
    //   setReferralResourceError('');
    //   isValid = true;
    //   // Handle form submission
    // }

    if (mobileNumberError) {
      isMobileValid = false;
    } else if (mobileNumber) {
      if (mobileNumber && !/^\d{3}-\d{3}-\d{4}$/.test(mobileNumber)) {
        // setWorkPhoneNumberError(true);
        mobileNumberValid = false;
        setMobileNumberError(
          'Please enter a valid mobile number in format: xxx-xxx-xxxx'
        );
      } else {
        setMobileNumberError('');
      }
    } else {
      setMobileNumberError('Mobile number is required');
      isValid = false;
    }

    // eslint-disable-next-line no-lone-blocks
    if (!petLastName) setpetLastName(lastName);

    if (
      !zipcodeError?.length > 0 &&
      isValid &&
      mobileNumberValid &&
      // selectedCity &&
      isemailValid &&
      isMobileValid
    ) {
      handleNext();
    }
  };

  /* Function to set location name */

  const SetLocationName = (val) => {
    if (!val) {
      setlocationError('');
    } else {
      setlocationError('');
      setIsFieldUpdated(true);
      setLocation(val);
    }
  };

  /* Function to set first name */

  const SetFirstName = (val) => {
    if (!val) {
      setFirstName('');
      setFirstNameError('First name is required');
    } else {
      setFirstNameError('');
      setIsFieldUpdated(true);
      setFirstName(val);
    }
  };

  const descriptionSet = (val) => {
    setPetNotes(val);
  };

  /* Function to set last name */

  const SetLastName = (val) => {
    if (!val) {
      setLastNameError('Last name is required');
      setLastName('');
    } else {
      setLastNameError('');
      setIsFieldUpdated(true);
      setLastName(val);
    }
  };

  /* Function to set Email */

  const SetEmail = (e) => {
    const emailValue = e.target.value;
    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let manufacturerPattern = /\S+@\S+\.\S+/;
    // set field updated to true

    if (!emailValue) {
      setIsFieldUpdated(true);
      setemailError('Email is required');
      setemail('');
    } else if (!manufacturerPattern.test(emailValue)) {
      setIsFieldUpdated(true);
      setemailError('Please enter valid email address');
      setemail(emailValue);
    } else if (emailValue.length >= 6) {
      setemail(emailValue);
      debouncedCheckEmailExist(emailValue);
      setIsFieldUpdated(true);
    } else {
      setIsFieldUpdated(true);
      setemailError('');
      setemail(emailValue);
    }
  };

  /* Function to set mobile number */

  const SetMobileNumber = (e) => {
    setIsFieldUpdated(true);
    const mobileNumber = e.target.value;
    const validation = /^\d{3}-\d{3}-\d{4}$/;

    if (mobileNumber) {
      let formatWorkPhoneNumber = formatPhoneNumber(mobileNumber);
      setMobileNumber(formatWorkPhoneNumber);
      setIsFieldUpdated(true);

      if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
        setMobileNumberError(
          'Please enter a valid mobile number in format: xxx-xxx-xxxx'
        );
      } else if (formatWorkPhoneNumber.length >= 11) {
        setMobileNumberError('');
        debouncedCheckMobileNumberExist(formatWorkPhoneNumber);
      } else {
        setMobileNumberError('');
      }
    } else {
      setMobileNumberError('Mobile Number is required');
      setMobileNumber('');
    }
  };

  /* Function to set date of birth */

  /* const SetDateOfBirth = (val) => {
 
     if (val === '01-01-1970') {
       return;
     }
     if (!val) {
       // setDobError('Date of birth is required');
     } else {
       // setDobError('');
       setIsFieldUpdated(true);
       setClientDob(val);
     }
   }; */

  /* Function to set  Referal source */

  const SetReferalSource = (val) => {
    if (val === 'Existing Client') {
      setIsExistingClient('client');
    } else if (val === 'Business Entity') {
      setIsExistingClient('businessEntity');
    } else {
      setIsExistingClient('');
    }

    setReferralResourceError('');
    setReferralResource(val);
    setIsFieldUpdated(true);
  };

  /* Function to set Address  */

  const SetAddress = (val) => {
    setAddressError('');
    setAddress(val);
    setIsFieldUpdated(true);
  };
  /* Function to set Address1  */

  const SetAddress1 = (val) => {
    setAddress1(val);
  };

  /* Function to set state */

  const SetState = (val) => {
    setStateError('');
    setSelectedState(val);
    setIsFieldUpdated(true);
  };

  /* Function to set city */

  const SetCity = (val) => {
    setCityError('');
    setSelectedCity(val);
    setIsFieldUpdated(true);
  };

  /* Function to set zip code */

  const SetZipCode = (val) => {
    const zipCodePattern = val.replace(/\D/g, ''); // Remove non-digit characters

    if (!zipCodePattern) {
      setIsFieldUpdated(true);
      setZipcodeError('');
      setZipcode('');
    } else if (zipCodePattern.length !== 5) {
      setIsFieldUpdated(true);
      setZipcodeError('ZIP code should have exactly 5 digits');
      setZipcode(zipCodePattern.substring(0, 5)); // Truncate to 5 digits
    } else {
      setIsFieldUpdated(true);
      setZipcodeError('');
      setZipcode(zipCodePattern);
    }
  };

  /* Function to set name */
  /*
    const SetName = (val) => {
      setname(val);
    }; */

  /* Function to set Email */
  /*
    const SetEmailEmergancy = (val) => {
      let manufacturerPattern = /\S+@\S+\.\S+/;
      // set field updated to true
  
      if (!manufacturerPattern.test(val)) {
        setIsFieldUpdated(true);
        setemailEmergancyError('Please enter valid email address');
        setemailEmergancy(val);
      } else {
        setIsFieldUpdated(true);
        setemailEmergancyError('');
        setemailEmergancy(val);
      }
    };
  */
  /* Function to set phone */
  /*
    const SetPhone = (val) => {
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      if (val) {
        setIsFieldUpdated(true);
        let formatWorkPhoneNumber = formatPhoneNumber(val);
        setPhone(formatWorkPhoneNumber);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setEmergencyPhoneError(
            'Please enter a valid phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setEmergencyPhoneError('');
        }
      } else {
        setEmergencyPhoneError('');
        setPhone('');
      }
    }; */

  /* Function to set relationship */
  /*
    const SetRelationship = (val) => {
      setrelationship(val);
      setIsFieldUpdated(true);
    }; */

  /* Function to Liability waiver */

  // const SetLiabilityWaiver = (val) => {
  //   setliabilityWaiver(val);
  //   setIsFieldUpdated(true);
  // };

  // Function to move to create user form page
  const handleNext = () => {
    // if(!imageURL) saveSignature();
    if (emailError) {
      return;
    }
    if (
      firstName &&
      lastName &&
      // location &&
      email
      //  &&
      // address &&
      // zipcode
      // selectedState &&
      // selectedCity &&
      // referralResource
    ) {
      // if (imageURL || signatureCanvasRef?.current?.isEmpty() === false) {
      //   // if (signImageFile) setActiveStep(activeStep + 1);
      //   setActiveStep(activeStep + 1);
      // } else {
      //   setOpenLiabilitySigned(true);
      // }
      setActiveStep(activeStep + 1);
    }
  };

  // Function to move back to add client

  const handleBack = () => {
    // setClientDob('');
    setActiveStep(activeStep - 1);
  };

  /* Function to upload Image for pet */

  const handleImageUpload = (e) => {
    const maxAllowedSize = 5 * 1024 * 1024;
    let filesLength = e.target.files[0].size;
    if (filesLength <= maxAllowedSize) {
      setProfileImageError('');
      setimgObj(e.target.files[0]);
      setimgUrl(URL.createObjectURL(e.target.files[0]));
      setIsFieldUpdated(true);
    } else {
      setProfileImageError('Image size is more then 5mb.');
    }
  };

  /* Function to upload image of client */

  const handleImageUploadClient = async (e) => {
    setProfileImageError('');
    const maxAllowedSize = 5 * 1024 * 1024;
    let filesLength = e.target.files[0].size;
    const imageFile = e.target.files[0];

    if (imageFile && filesLength <= maxAllowedSize) {
      if (imageFile.name.toLowerCase().endsWith('.heic')) {
        try {
          // Convert HEIC to JPEG
          const jpegBlob = await heic2any({
            blob: imageFile,
            toType: 'image/jpeg',
            quality: 0.7 // Adjust quality as needed
          });

          const jpegFile = new File([jpegBlob], `${imageFile.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setimgObjClient(jpegFile);
          setimgUrlClient(URL.createObjectURL(jpegFile));
          setIsFieldUpdated(true);
        } catch (error) {
          console.error('Error converting HEIC to JPEG:', error);
          // Handle error as needed
          setProfileImageError(
            'Error converting HEIC to JPEG. Please try again.'
          );
        }
      } else if (/\.(png|jpe?g)$/i.test(imageFile.name)) {
        // If it's a supported format (png, jpeg, jpg), handle it as usual
        setimgObjClient(imageFile);
        setimgUrlClient(URL.createObjectURL(imageFile));
        setIsFieldUpdated(true);
      } else {
        // If it's not a supported format, display an error message
        console.error('Unsupported image format:', imageFile.name);
        // Handle error as needed
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    } else {
      setProfileImageError('Image size is more then 5mb.');
    }
  };

  const createClient = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!petFirstName) {
      setpetFirstNameError('First name is required');
    }

    if (!birthDate) {
      setbirthDateError('Birth date is required');
    }

    // if (!gender) {
    //   setgenderError('Gender is required');
    // }
    // if (!acquisitionSource) {
    //   setacquisitionSourceError('Acquisition Source is required');
    // }
    // if (!breed) {
    //   setbreedError(' Breed is required');
    // }

    // check if all field are valid or not
    if (
      petFirstName &&
      birthDate
      // gender &&
      // acquisitionSource &&
      // breed
      // allergies &&
      // location &&
      // firstName &&
      // selectedState &&
      // selectedCity &&
      // zipcode
    ) {
      createClientDetails();
    }
  };

  /* Functions to set values of various fields */
  const SetPetfirstName = (val) => {
    if (!val) {
      setpetFirstNameError('First name is required');
      setpetFirstName('');
    } else {
      setpetFirstNameError('');
      setpetFirstName(val);
      setIsFieldUpdated(true);
    }
  };

  const SetPetLastName = (val) => {
    setpetLastName(val);
    setIsFieldUpdated(true);
  };

  const SetBirthDate = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    if (!val) {
      setbirthDateError('Birth date is required');
    } else {
      setbirthDateError('');
      setIsFieldUpdated(true);
      setbirthDate(val);
    }
  };

  const SetGender = (val) => {
    // if (!val) {
    //   setgenderError('Gender is required');
    // } else {
    setgenderError('');
    setIsFieldUpdated(true);
    setgender(val);
    // }
  };

  const SetacquisitionSource = (val) => {
    // if (!val) {
    //   setacquisitionSourceError('AcquisitionSource is required');
    // } else {
    setacquisitionSourceError('');
    setIsFieldUpdated(true);
    setacquisitionSource(val);
    // }
  };

  const SetBreed = (val) => {
    // if (!val) {
    //   setbreedError('Breed is required');
    // } else {
    setbreed(val);
    setbreedError('');
    setIsFieldUpdated(true);
    // }
  };

  const SetVeterinarian = (val) => {
    if (val) {
      setveterinarian(val);
      setIsFieldUpdated(true);
    } else {
      setveterinarian(false);
    }
  };

  const SetAllergies = (val) => {
    if (!val) {
      // setallergiesError('Allergies is required');
      setallergies('');
    } else {
      setallergies(val);
      // setallergiesError('');
    }
  };

  const SetBark = (val) => {
    setbark(val);
  };

  const SetStrangers = (val) => {
    setstrangers(val);
    setIsFieldUpdated(true);
  };

  const SetBiting = (val) => {
    setbiting(val);
    setIsFieldUpdated(true);
  };

  const SetPossessive = (val) => {
    setpossessive(val);
    setIsFieldUpdated(true);
  };

  const SetAdvanced = (val) => {
    setadvanced(val);
    setIsFieldUpdated(true);
  };

  const SetSocial = (val) => {
    setsocial(val);
    setIsFieldUpdated(true);
  };

  const SetOrientation = (val) => {
    setorientation(val);
  };

  const SetEvaluation = (val) => {
    setevaluation(val);
  };

  const SetAgility = (val) => {
    setagility(val);
  };

  const SetTricks = (val) => {
    settricks(val);
  };

  const SetRabiesExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    setrabiesExpiration(val);
    setIsFieldUpdated(true);
  };

  const SetBordetellaExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    setbordetellaExpiration(val);
    setIsFieldUpdated(true);
  };

  const SetDhppExpiration = (val) => {
    if (val === '01-01-1970') {
      return;
    }
    setdhppExpiration(val);
    setIsFieldUpdated(true);
  };

  // Method to check the email already exist or not
  const checkEmailExist = async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}client/checkEmailExists?email=${email}`
      );
      // Process the response here
      if (response.data.isExists) {
        let msg = response.data.message;
        // Now email is already exist
        setemailError(msg);
      } else {
        // Now email is not exist
        setemailError('');
      }
    } catch (error) {
      console.error('Error checking email existence:', error);
    }
  };

  // Method to check the email already exist or not
  const checkMobileNumberExist = async (mobileNumber) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_END_POINT}client/checkMobilePhoneExists?mobilePhone=${mobileNumber}`
      );
      // Process the response here
      if (response.data.isExists) {
        let msg = response.data.message;
        // Now email is already exist
        setMobileNumberError(msg);
      } else {
        // Now email is not exist
        setMobileNumberError('');
      }
    } catch (error) {
      console.error('Error checking email existence:', error);
    }
  };

  // Debounced function to call API
  const debouncedCheckEmailExist = debounce(checkEmailExist, 500); // Adjust the debounce delay as per your requirements
  const debouncedCheckMobileNumberExist = debounce(checkMobileNumberExist, 500); // Adjust the debounce delay as per your requirements

  /* Function to get breeds */

  const getBreed = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list`
      );
      if (response.statusCode === 200) {
        setbreeds(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get getAllAcquisition */

  const getAllAcquisition = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list/getAllAcquisition`
      );
      if (response.statusCode === 200) {
        setAcquisitionList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get getAllAllergies */

  const getAllAllergies = async () => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}breed-list/getAllAllergies`
      );
      if (response.statusCode === 200) {
        setAllergiesList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* Function to get Veterinarian */

  const getVeterinarian = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntities?locationId=${encodedLocationId}&businessEntityType=Veterinarian`
      );
      if (response.statusCode === 200) {
        setVeterinarianList(response.data.Items);
      } else {
        // setShowErrorMessage(true);
        // setErrorMessage('Something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // methdo to change the existing client
  const changeExistingClient = (selectedclient) => {
    if (selectedclient) {
      setExistingClient(selectedclient);
    } else {
      setExistingClient(null);
    }
  };

  const handleSearchClient = (e) => {
    let value = '';
    value = e.target.value;
    setSearchValue(value);
    if (value.length) {
      getClientList(value);
    } else setExistingClientList([]);
  };

  // methdo to change the business entity
  const changeBusinessEntity = (selectedEntity) => {
    if (selectedEntity) {
      setBusinessEntity(selectedEntity);
    } else {
      setBusinessEntity(null);
    }
  };

  /* Method for Add new referral source modal */
  const addNewReferralSource = () => {
    setSourceName('');
    setSourceNameError('');
    // setSourceEmail('');
    setOpenAddReferralSource(true);
  };

  // close referal source dialog
  const handleCloseVariant = () => {
    setOpenAddReferralSource(false);
  };

  useEffect(() => {
    if (sourceName) {
      setSourceNameError('');
    } else {
      setSourceNameError('Source name is required');
    }
  }, [sourceName]);

  // Now create refereral source
  const handleCreateReferralSource = () => {
    //  && sourceEmail && !sourceEmailError
    if (sourceName) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        franchiseeId: franchiseeId,
        locationId: locationId,
        referralSourceName: sourceName,
        // referralSourceEmail: sourceEmail,
        createdBy: userId
      };
      createReferralSource(data);
    } else {
      setSourceNameError('Source name is required');
    }
  };
  // create referal source
  const createReferralSource = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}client/createReferralSource`,
        data
      )
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setSourceName('');
          // setSourceEmail('');
          getReferalSource();
          // setproductCategoryId(res.data.data.categoryId);
          setOpenAddReferralSource(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
        // Now hide the loader
        setIsLoading(false);
      });
  };

  /* Function to set SourceEmail */

  // const SetSourceEmail = (val) => {
  //   const emailValue = val;
  //   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   // let emailPattern = /\S+@\S+\.\S+/;

  //   if (!emailValue) {
  //     setSourceEmailError('Email is required');
  //     setSourceEmail('');
  //   } else if (!emailPattern.test(emailValue)) {
  //     setSourceEmailError('Please enter valid email address');
  //     setSourceEmail(emailValue);
  //   }
  //   else {
  //     setSourceEmailError('');
  //     setSourceEmail(emailValue);
  //   }

  // };

  const removeFile = (idToRemove) => {
    const updatedFiles = multipleFile.filter((file) => file.id !== idToRemove);
    setMultipleFile(updatedFiles);

    // check if multiple file lenght is less than or equal to 5
    if (multipleFile.length <= 5) {
      setImageError('');
    }
  };

  useEffect(() => {
    if (multipleFile) {
      let images = multipleFile.map((item) => item.name);
      setProductImage(images);

      // check if multiple file lenght is less than or equal to 5
      if (multipleFile.length <= 5) {
        setImageError('');
      }
    }
  }, [multipleFile]);

  /* Function to get uploaded image */
  const handleFileInput = (event) => {
    /* Maximum allowed size in bytes
      5MB Example
      Change first operand(multiplier) for your needs */
    const maxAllowedSize = 5 * 1024 * 1024;
    let filesLength = event.target.files.length;
    let total = multipleFile.length + filesLength || 0;

    if (
      event.target.files.length !== 0 &&
      filesLength <= 5 &&
      multipleFile.length <= 5 &&
      total <= 5
    ) {
      const fileList = event.target.files;
      const uploadedImages = Array.from(fileList)
        .map((file) => {
          if (file.type === 'image/gif') {
            // alert("Sorry! GIF format is not supported.");
            setImageError('Please upload a supported file size and format.');
            return null;
          }
          if (file.size > maxAllowedSize) {
            setImageError(
              'File size exceeds the maximum allowed limit of 5 MB'
            );
            return null;
          }
          return {
            name: file.name,
            id: Math.random().toString(36).substring(7),
            file: file
          };
        })
        .filter(Boolean); // Remove null values

      if (uploadedImages.length > 0) {
        setMultipleFile([...multipleFile, ...uploadedImages]);
        setImageError('');
        setIsFieldUpdated(true);
      }
    } else if (filesLength > 5 || multipleFile.length > 5 || total > 5) {
      setImageError(
        'Number of 5 files allowed only. Please select/remove extra files.'
      );
    }
  };

  // Method to add the pet
  const addPetDocument = async (clId, petId, docObj) => {
    // Now check if the uploadData is not empty

    let clientId = clId || sessionStorage.getItem('cId');
    let childId = petId || sessionStorage.getItem('petId');
    let uploadData = docObj;

    if (clientId && childId) {
      // Now show the loader
      // setIsLoading(true);
      let formData = new FormData();
      formData.append('clientId', clientId);
      formData.append('childId', childId);
      formData.append('documentImage', uploadData?.file);
      formData.append('documentName', uploadData?.name);

      const response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}client/addDocumentProfile`,
        formData
      );
      if (response.statusCode === 200) {
        // localStorage.removeItem('clientId');
        // setIsLoading(false);
        // setOpen(true);
        // getEventToRefresh();
      } else {
        setShowError(true);
        setErrorMessage(response.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {errorMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setErrorMessage} />
      )}
      <>
        <Grid sx={{ padding: '0 2rem' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Box onClick={() => handleClose()} className="back-arrow-close">
                  <ArrowLeft />
                </Box>
                <Typography
                  className="header-blue-32"
                  // style={{ marginTop: '-5px', marginLeft: '5px' }}
                >
                  Add Client
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <Button
                  sx={{ mt: { xs: 0, md: 0 }, mr: 2 }}
                  variant="contained"
                  type="submit"
                  className="btn-primary m-0"
                >
                  Save
                </Button> */}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ width: '40%', background: 'none' }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel className="step-labeling">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0 && (
            <>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ margin: 'auto', width: '98%' }}
              >
                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="heading-text">
                      Client Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      error={locationError}
                      fullWidth
                      className="select-drop-down-label-styling required-field"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling"
                      >
                        LOCATION
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="LOCATION"
                        className="input-field-styling"
                        value={location}
                        disabled
                        onChange={(e) => SetLocationName(e.target.value)}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 200 } }
                        }}
                      >
                        {locationDetails.map((location) => (
                          <MenuItem
                            key={location.locationName}
                            value={location.sortKey}
                          >
                            {location.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                      {locationError && (
                        <FormHelperText error>{locationError}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="First name"
                      autocomplete="First name"
                      label="FIRST NAME"
                      placeholder="FIRST NAME"
                      value={firstName}
                      className="input-field-styling required-field"
                      onChange={(e) => SetFirstName(e.target.value)}
                      error={Boolean(firstNameError)}
                      helperText={firstNameError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="Last name"
                      autocomplete="Last name"
                      label="LAST NAME"
                      placeholder="LAST NAME"
                      className="input-field-styling required-field"
                      value={lastName}
                      onChange={(e) => SetLastName(e.target.value)}
                      error={Boolean(lastNameError)}
                      helperText={lastNameError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="Email"
                      autocomplete="Email"
                      label="EMAIL"
                      placeholder="EMAIL"
                      className="input-field-styling required-field"
                      defaultValue={email}
                      onChange={SetEmail}
                      error={Boolean(emailError)}
                      helperText={emailError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      id="Mobile number"
                      name="Mobile number"
                      label="MOBILE NUMBER"
                      className="input-field-styling required-field"
                      type="text"
                      placeholder="777-777-7777"
                      // onChange={(e) => SetMobileNumber(e.target.value)}
                      onChange={SetMobileNumber}
                      value={mobileNumber}
                      error={Boolean(mobileNumberError)}
                      helperText={mobileNumberError}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                      inputProps={{ maxLength: 12 }}
                    />
                  </Grid>
                  {/* <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  className="form-datepicker-container"
                >
                  <CustomDatePicker
                    label="DATE OF BIRTH"
                    onChange={SetDateOfBirth}
                    // minDate={minDateValue}
                    fieldValue={clientDob}
                  // maxDate={currentDate}
                  // className="input-field-styling"
                  />
                </Grid> */}
                  <Grid
                    item
                    xs={12}
                    sm={1.5}
                    md={1.5}
                    sx={
                      {
                        // marginLeft: '10px'
                      }
                    }
                  >
                    <Avatar
                      src={imgUrlClient !== '' ? imgUrlClient : null}
                      sx={{ height: '60px', width: '60px' }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2.5}
                    md={2.5}
                    sx={
                      {
                        // marginLeft: '20px'
                      }
                    }
                  >
                    <Box>
                      <Typography className="check-box-text">
                        Upload Profile Image
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        id="select-file"
                        name="profileImage"
                        style={{ display: 'none' }}
                        onChange={(event) => handleImageUploadClient(event)}
                      />
                      <label htmlFor="select-file">
                        <Button
                          variant="contained"
                          component="span"
                          className="upload-image"
                          sx={{ marginTop: '6px !important' }}
                        >
                          <Typography className="upload-text">
                            Upload
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                    {profileImageError && (
                      <FormHelperText>{profileImageError}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      error={referralResourceError}
                      fullWidth
                      className="select-drop-down-label-styling required-field"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling"
                      >
                        REFERRAL SOURCES
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="REFERRAL SOURCES"
                        className="input-field-styling"
                        value={referralResource}
                        onChange={(e) => SetReferalSource(e.target.value)}
                        error={Boolean(referralResourceError)}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 200 } }
                        }}
                      >
                        {referalSourceList &&
                          referalSourceList.map((item) => (
                            // <MenuItem key={item.sortKey} value={item.sortKey}>
                            //   {item.firstName + item.lastName}
                            // </MenuItem>
                            <MenuItem
                              key={item.sortKey}
                              value={item.referralSourceName}
                            >
                              {item.referralSourceName}
                            </MenuItem>
                          ))}
                      </Select>
                      {referralResourceError && (
                        <FormHelperText error>
                          {referralResourceError}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 700,
                        color: '#003087',
                        textDecoration: 'underline',
                        marginRight: '5px'
                        // display: 'none'
                      }}
                      className="add-new-category align-right"
                    >
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          addNewReferralSource();
                        }}
                        onKeyDown={() => {
                          addNewReferralSource();
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        +Add New Referral Source
                      </span>
                    </Typography>
                  </Grid>
                  {isExistingClient === 'client' && (
                    <Grid item xs={12} sm={4} md={4}>
                      {/* <Box marginTop={1}> */}
                      <Autocomplete
                        disablePortal
                        classes={autoCompleteClasses}
                        onChange={(event, selectedOptions) => {
                          changeExistingClient(selectedOptions);
                        }}
                        className="status-field field-label-css search-field-autocss"
                        options={existingClientList}
                        noOptionsText={
                          clientLoading ? 'Loading...' : 'No client found'
                        }
                        getOptionLabel={(option) => {
                          return (
                            option &&
                            option?.firstName &&
                            option?.lastName &&
                            option.firstName + ' ' + option.lastName
                          );
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.sortKey}>
                              {option.firstName} {option.lastName}
                            </li>
                          );
                        }}
                        sx={{
                          width: '100%'
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: <SearchIcon />
                            }}
                            label={params?.label || 'SEARCH CLIENT'}
                            className="input-field-styling"
                            InputLabelProps={{
                              className: 'input-field-dropdown-styling'
                            }}
                            value={searchValue}
                            autoComplete="off"
                            onChange={handleSearchClient}
                          />
                        )}
                      />
                      {/* </Box> */}
                    </Grid>
                  )}
                  {isExistingClient === 'businessEntity' && (
                    <Grid item xs={12} sm={4} md={4}>
                      {/* <Box marginTop={1}> */}
                      <Autocomplete
                        disablePortal
                        // value={businessEntity}
                        // value={businessEntity?.businessName}
                        classes={autoCompleteClasses}
                        className="status-field field-label-css search-field-autocss"
                        options={businessEntityList}
                        getOptionLabel={(option) => option.businessName}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.sortKey}>
                              {option.businessName}
                            </li>
                          );
                        }}
                        onChange={(event, selectedOptions) => {
                          changeBusinessEntity(selectedOptions);
                        }}
                        // options={businessEntityList && businessEntityList.map((option) => option.businessName)}
                        sx={{
                          width: '100%'
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={params?.label || 'SEARCH BUSINESS ENTITY'}
                            className="input-field-styling"
                            InputLabelProps={{
                              className: 'input-field-dropdown-styling'
                            }}
                          />
                        )}
                      />
                      {/* </Box> */}
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="heading-text">
                      Client Address Detail
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="Address"
                      autocomplete="Address"
                      label="ADDRESS 1"
                      placeholder="Address 1"
                      value={address}
                      onChange={(e) => SetAddress(e.target.value)}
                      error={Boolean(addressError)}
                      helperText={addressError}
                      className="input-field-styling"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                        // shrink: 'true'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="Address"
                      autocomplete="Address"
                      label="ADDRESS 2"
                      placeholder="Address 2"
                      defaultValue={address1}
                      onChange={(e) => SetAddress1(e.target.value)}
                      className="input-field-styling"
                      InputLabelProps={{
                        className: 'label-style-create-franchisee'
                        // shrink: 'true'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      error={stateError}
                      fullWidth
                      className="select-drop-down-label-styling"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        STATE
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="STATE"
                        className="input-field-styling"
                        value={selectedState}
                        onChange={(e) => SetState(e.target.value)}
                        error={Boolean(stateError)}
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 200 } }
                        }}
                      >
                        {setstate.map((state) => (
                          <MenuItem key={state.state} value={state.state}>
                            {state.state}
                          </MenuItem>
                        ))}
                      </Select>
                      {stateError && (
                        <FormHelperText error>{stateError}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    {/* <FormControl
                        error={cityError}
                        fullWidth
                        className="select-drop-down-label-styling required-field"
                      >
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className="input-field-dropdown-styling required-label"
                        >
                          CITY
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="CITY"
                          value={selectedCity}
                          onChange={(e) => SetCity(e.target.value)}
                          error={Boolean(cityError)}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } }
                          }}
                        >
                          {cities.map((city) => (
                            <MenuItem key={city.city} value={city.city}>
                              {city.city}
                            </MenuItem>
                          ))}
                        </Select>
                        {cityError && (
                          <FormHelperText error>{cityError}</FormHelperText>
                        )}
                      </FormControl> */}
                    <TextField
                      fullWidth
                      label="CITY"
                      placeholder="CITY"
                      name="city"
                      onChange={(e) => SetCity(e.target.value)}
                      value={selectedCity}
                      error={Boolean(cityError)}
                      helperText={cityError}
                      className="input-field-styling"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                        // shrink: 'true'
                      }}
                      inputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="Zipcode"
                      autocomplete="Zipcode"
                      label="ZIP CODE"
                      placeholder="Zip code"
                      value={zipcode}
                      onChange={(e) => SetZipCode(e.target.value)}
                      error={Boolean(zipcodeError)}
                      helperText={zipcodeError}
                      className="input-field-styling "
                      InputLabelProps={{
                        className: 'label-style-create-franchisee'
                        // shrink: 'true'
                      }}
                      InputProps={{
                        inputProps: {
                          maxLength: 5
                        }
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12}>
                  <Typography className="heading-text">
                    Emergency Contact Person Details
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    name="name"
                    autocomplete="Name"
                    label="NAME"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => SetName(e.target.value)}
                    className="input-field-styling"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    name="Email"
                    autocomplete="Email"
                    label="EMAIL"
                    placeholder="Email"
                    value={emailEmergancy}
                    onChange={(e) => SetEmailEmergancy(e.target.value)}
                    error={Boolean(emailEmergancyError)}
                    helperText={emailEmergancyError}
                    className="input-field-styling"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                    InputProps={{}}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    id="Phone"
                    name="Phone"
                    label="PHONE"
                    className="input-field-styling"
                    type="text"
                    placeholder="777-777-7777"
                    onChange={(e) => SetPhone(e.target.value)}
                    value={phone}
                    helperText={emergencyPhoneError}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    name="Relationship"
                    autocomplete="Relationship"
                    label="RELATIONSHIP"
                    placeholder="Relationship"
                    value={relationship}
                    onChange={(e) => SetRelationship(e.target.value)}
                    // error={Boolean(franchiseeNameError)}
                    // helperText={franchiseeNameError}
                    className="input-field-styling"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                    InputProps={{}}
                  />
                </Grid> */}
                  {/* <Grid item xs={12} sm={4} md={4}>
                      <Box
                        className="Profile-parent"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <label
                          htmlFor="select-parent-file"
                          className="label-select"
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          ATTACH PARENT PROFILE
                        </label>
                        <input
                          type="file"
                          accept=".pdf, .jpg, .heif, .hevc"
                          hidden
                          id="select-parent-file"
                          onChange={handleFileUpload}
                        />
                      </Box>
                    </Grid> */}
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="heading-text">
                      Liability Waiver
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {/* <TextField
                    sx={{ borderRadius: '10px', padding: '5px' }}
                    fullWidth
                    multiline
                    id="my-textarea"
                    name="Description"
                    value={{__html: liabilityWaiver}}
                    // dangerouslySetInnerHTML={{ __html: liabilityWaiver }}
                    // innerHTML={liabilityWaiver}
                    // onChange={(e) => SetLiabilityWaiver(e.target.value)}
                    placeholder="Notes here . . . "
                    variant="outlined"
                    readonly
                    className="field-top-margin-edit-locatio"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                    InputProps={{ rows: 4 }}
                  />
                </Grid> */}
                    <Box
                      sx={{ borderRadius: '10px', padding: '5px' }}
                      fullWidth
                      multiline
                      id="my-textarea"
                      name="Description"
                      dangerouslySetInnerHTML={{ __html: liabilityWaiver }}
                      className="liability-section-container"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="signature-text">
                      By signing here you agree to Zoom Room's liability waiver
                      and terms and conditions.
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      marginTop: '10px',
                      marginLeft: '12px',
                      width: '100%'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        {imageURL ? (
                          <img
                            src={imageURL}
                            alt="my signature"
                            style={{
                              height: '103px',
                              width: '450px',
                              border: '2px solid rgba(0, 48, 135, 0.5)',
                              borderRadius: '10px',
                              padding: '15px',
                              marginLeft: '10px',
                              marginRight: '20px',
                              float: 'left'
                            }}
                          />
                        ) : (
                          <SignaturePad
                            canvasProps={{
                              className: 'signatureCanvas',
                              alt: 'Sign Here',
                              style: {
                                height: '103px',
                                width: '450px',
                                border: '2px solid rgba(0, 48, 135, 0.5)',
                                borderRadius: '10px',
                                padding: '15px',
                                marginLeft: '10px',
                                marginRight: '20px',
                                float: 'left'
                              }
                            }}
                            penColor={'#003087'}
                            backgroundColor={'#fff'}
                            ref={signatureCanvasRef}
                          />
                        )}

                        <Button
                          sx={{
                            border: '2px solid #003087 !important',
                            color: '#003087 !important',
                            display: 'inline-block',
                            marginTop: '57px'
                          }}
                          variant="outlined"
                          className="btn-primary"
                          onClick={handleSignature}
                        >
                          {' '}
                          Clear{' '}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{ mt: { xs: 0, md: 0 } }}
                    variant="contained"
                    type="submit"
                    className="btn-primary"
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {activeStep === 1 && (
            <>
              <Typography className="heading-text text-primary">
                Dog information
              </Typography>
              <Box
                component="form"
                onSubmit={createClient}
                sx={{ margin: 'auto', width: '98%' }}
              >
                <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="First name"
                      autocomplete="First name"
                      label="FIRST NAME"
                      placeholder="FIRST NAME"
                      value={petFirstName}
                      onChange={(e) => SetPetfirstName(e.target.value)}
                      error={Boolean(petFirstNameError)}
                      helperText={petFirstNameError}
                      className="input-field-styling required-field"
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                        // shrink: 'true'
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      fullWidth
                      name="Last name"
                      autocomplete="Last name"
                      label="LAST NAME"
                      value={petLastName}
                      placeholder="LAST NAME"
                      // defaultValue={franchiseeName}
                      onChange={(e) => SetPetLastName(e.target.value)}
                      // error={Boolean(franchiseeNameError)}
                      // helperText={franchiseeNameError}
                      className="input-field-styling "
                      InputLabelProps={{
                        className: 'label-style-create-franchisee'
                      }}
                      InputProps={{}}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      error={genderError}
                      fullWidth
                      className="select-drop-down-label-styling"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        GENDER
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="GENDER"
                        value={gender}
                        // defaultValue={state}
                        className="input-field-styling"
                        onChange={(e) => SetGender(e.target.value)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        {/* <MenuItem value="Other">Other</MenuItem> */}
                      </Select>
                      {genderError && (
                        <FormHelperText>Gender is required</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      error={acquisitionSourceError}
                      fullWidth
                      className="select-drop-down-label-styling"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        ACQUISITION SOURCE
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="ACQUISITION SOURCE"
                        value={acquisitionSource}
                        // defaultValue={state}
                        className="input-field-styling"
                        onChange={(e) => SetacquisitionSource(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {acquisitionList &&
                          acquisitionList.map((data) => (
                            <MenuItem
                              key={data.sortKey}
                              value={data.acquisitionSource}
                            >
                              {data.acquisitionSource}
                            </MenuItem>
                          ))}
                        {/* <MenuItem value="Shelter/Rescue">Shelter/Rescue</MenuItem>
                      <MenuItem value="Breeder">Breeder</MenuItem>
                      <MenuItem value="Friend/Family">Friend/Family</MenuItem>
                      <MenuItem value="Pet Store">Pet Store</MenuItem>
                      <MenuItem value="Others">Others</MenuItem> */}
                      </Select>
                      {acquisitionSourceError && (
                        <FormHelperText>
                          AcquisitionSource is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      error={breedError}
                      fullWidth
                      className="select-drop-down-label-styling"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling required-label"
                      >
                        BREED
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="input-field-styling"
                        label="BREED"
                        value={breed}
                        onChange={(e) => SetBreed(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {breeds.map((data) => (
                          <MenuItem value={data.breedName}>
                            {data.breedName}
                          </MenuItem>
                        ))}
                      </Select>
                      {breedError && (
                        <FormHelperText>Breed is required</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className="form-datepicker-container"
                  >
                    {/* <TextField
                  fullWidth
                  name="Birthday"
                  autoComplete="off"
                  label="BIRTHDAY"
                  placeholder="BIRTHDAY"
                  value={birthDate}
                  type="date"
                  onChange={(e) => SetBirthDate(e.target.value)}
                  helperText={birthDateError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className:
                      'label-style-create-franchisee required-label',
                    shrink: 'true'
                  }}
                  InputProps={{ min: minDateValue, max: currentDate }}

                /> */}
                    {/* <CustomDatePicker
                      label="BIRTHDAY"
                      onChange={SetBirthDate}
                      required={Boolean(true)}
                      minDate={minDateValue}
                      maxDate={currentDate}
                    /> */}
                    <CustomDatePickerForAllBrowser
                      label={'BIRTHDAY'}
                      onChange={SetBirthDate}
                      required={Boolean(true)}
                      minDate={minDateValue}
                      maxDate={currentDate}
                    />
                    {birthDateError && (
                      <FormHelperText>Birth date is required</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      fullWidth
                      className="select-drop-down-label-styling"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling"
                      >
                        VETERINARIAN
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="input-field-styling"
                        label="VETERINARIAN"
                        value={veterinarian}
                        onChange={(e) => SetVeterinarian(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {veterinarianList &&
                          veterinarianList.map((data) => (
                            <MenuItem
                              key={data.sortKey}
                              value={data.businessName}
                            >
                              {data.businessName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={4} md={4}>
                  <TextField
                    fullWidth
                    name="Allergies"
                    autocomplete="Allergies"
                    label="ALLERGIES"
                    placeholder="Allergies"
                    value={allergies}
                    onChange={(e) => SetAllergies(e.target.value)}
                    // error={Boolean(allergiesError)}
                    // helperText={allergiesError}
                    className="input-field-styling"
                    InputLabelProps={{
                      className: 'label-style-create-franchisee',
                      // shrink: 'true'
                    }}
                    InputProps={{}}
                  />
                </Grid> */}
                  <Grid item xs={12} sm={4} md={4}>
                    <FormControl
                      fullWidth
                      className="select-drop-down-label-styling"
                    >
                      <InputLabel
                        id="demo-simple-select-helper-label"
                        className="input-field-dropdown-styling"
                      >
                        ALLERGIES
                      </InputLabel>
                      <Select
                        multiple
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        className="input-field-styling"
                        label="ALLERGIES"
                        value={allergies}
                        onChange={(e) => SetAllergies(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              overflowY: 'auto'
                            }
                          }
                        }}
                      >
                        {allergiesList &&
                          allergiesList.map((data) => (
                            <MenuItem
                              key={data.sortKey}
                              value={data.allergyName}
                            >
                              {data.allergyName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={1}
                    md={1}
                    sx={
                      {
                        // marginRight: '10px'
                      }
                    }
                  >
                    <Avatar
                      src={imgUrl !== '' ? imgUrl : null}
                      sx={{ height: '60px', width: '60px' }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    sx={
                      {
                        // marginLeft: '20px'
                      }
                    }
                  >
                    <Box>
                      <Typography className="check-box-text">
                        Upload Profile Image
                      </Typography>
                      <input
                        accept="image/*"
                        multiple
                        type="file"
                        id="select-child-file"
                        name="profileImage"
                        style={{ display: 'none' }}
                        onChange={(event) => handleImageUpload(event)}
                      />
                      <label htmlFor="select-child-file">
                        <Button
                          variant="contained"
                          component="span"
                          className="upload-image"
                          sx={{ marginTop: '6px !important' }}
                        >
                          <Typography className="upload-text">
                            Upload
                          </Typography>
                        </Button>
                      </label>
                    </Box>
                    {profileImageError && (
                      <FormHelperText>{profileImageError}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="heading-text">
                      Tell us about your dog
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6}>
                    <Box className="radio-button-heading">
                      Does your dog bark at other dogs when out on a walk or at
                      you for attention?
                    </Box>
                    <FormControl className=" radio-button-text">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={bark}
                        name="radio-buttons-group"
                        onChange={(e) => SetBark(e.target.value)}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <FormControlLabel
                            // className="radio-button-style"
                            value="Yes"
                            control={<Radio className="radio-button-style" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio className="radio-button-style" />}
                            label="No"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Box className="radio-button-heading">
                      Is your dog afraid of stranger or certain groups of
                      people?
                    </Box>
                    <FormControl className=" radio-button-text">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={strangers}
                        name="radio-buttons-group"
                        onChange={(e) => SetStrangers(e.target.value)}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <FormControlLabel
                            value="Yes"
                            control={<Radio className="radio-button-style" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio className="radio-button-style" />}
                            label="No"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6}>
                    <Box
                      className="radio-button-heading"
                      sx={{ marginTop: '15px' }}
                    >
                      Has your dog ever bitten a person or another dog,
                      regardless or injury(not including nipping or play
                      biting)?
                    </Box>
                    <FormControl className=" radio-button-text">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={biting}
                        name="radio-buttons-group"
                        onChange={(e) => SetBiting(e.target.value)}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <FormControlLabel
                            // className="radio-button-style"
                            value="Yes"
                            control={<Radio className="radio-button-style" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio className="radio-button-style" />}
                            label="No"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Box className="radio-button-heading">
                      Is your dog very possessive about his/her food or toys?
                    </Box>
                    <FormControl className=" radio-button-text">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={possessive}
                        name="radio-buttons-group"
                        onChange={(e) => SetPossessive(e.target.value)}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <FormControlLabel
                            value="Yes"
                            control={<Radio className="radio-button-style" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio className="radio-button-style" />}
                            label="No"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Box
                      className="radio-button-heading"
                      sx={{ marginTop: '15px' }}
                    >
                      Do you believe your dog is able to skip basic obedience
                      and take advanced classes or agility?
                    </Box>
                    <FormControl className=" radio-button-text">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={advanced}
                        name="radio-buttons-group"
                        onChange={(e) => SetAdvanced(e.target.value)}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <FormControlLabel
                            // className="radio-button-style"
                            value="Yes"
                            control={<Radio className="radio-button-style" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio className="radio-button-style" />}
                            label="No"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Box className="radio-button-heading">
                      Is your dog social?
                    </Box>
                    <FormControl className=" radio-button-text">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={social}
                        name="radio-buttons-group"
                        onChange={(e) => SetSocial(e.target.value)}
                      >
                        <Box sx={{ display: 'flex' }}>
                          <FormControlLabel
                            value="Yes"
                            control={<Radio className="radio-button-style" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio className="radio-button-style" />}
                            label="No"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      fullWidth
                      multiline
                      id="my-textarea"
                      name="Notes"
                      autocomplete="Notes"
                      label="NOTES"
                      placeholder="Notes here . . . "
                      variant="outlined"
                      value={petNotes}
                      className="text-area-style"
                      onChange={(e) => descriptionSet(e.target.value)}
                      InputLabelProps={{
                        className: 'label-style-create-franchisee'
                      }}
                      InputProps={{ rows: 6 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className="-d-none">
                    <Typography className="text-blue font-16 weight-600">
                      COMPLETED
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={orientation}
                              className="check-box-span radio-button-style"
                              color="default"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                              onChange={(e) => SetOrientation(e.target.checked)}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Orientation
                            </Typography>
                          }
                        />
                        {/* {orientationError && (
                            <FormHelperText>
                              Orientation is required
                            </FormHelperText>
                          )} */}
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={evaluation}
                              className="check-box-span radio-button-style"
                              color="default"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                              onChange={(e) => SetEvaluation(e.target.checked)}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Evaluation
                            </Typography>
                          }
                        />
                        {/* {evaluationError && (
                            <FormHelperText>
                              Evaluation is required
                            </FormHelperText>
                          )} */}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '5px' }}>
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={agility}
                              className="check-box-span radio-button-style"
                              color="default"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                              onChange={(e) => SetAgility(e.target.checked)}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Agility
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tricks}
                              className="check-box-span radio-button-style"
                              color="default"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#003087'
                                }
                              }}
                              onChange={(e) => SetTricks(e.target.checked)}
                            />
                          }
                          label={
                            <Typography className="check-box-text">
                              Tricks
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography
                  className="heading-text"
                  sx={{ marginTop: '15px', marginBottom: '15px' }}
                >
                  Vaccination Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className="form-datepicker-container"
                  >
                    {/* <CustomDatePicker
                      label="RABIES EXPIRATION"
                      onChange={SetRabiesExpiration}
                      minDate={currentDate}
                    /> */}
                    <CustomDatePickerForAllBrowser
                      label={'RABIES EXPIRATION'}
                      onChange={SetRabiesExpiration}
                      minDate={currentDate}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className="form-datepicker-container"
                  >
                    {/* <CustomDatePicker
                      label="BORDETELLA EXPIRATION"
                      onChange={SetBordetellaExpiration}
                      minDate={currentDate}
                    /> */}
                    <CustomDatePickerForAllBrowser
                      label={'BORDETELLA EXPIRATION'}
                      onChange={SetBordetellaExpiration}
                      minDate={currentDate}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className="form-datepicker-container"
                  >
                    {/* <CustomDatePicker
                      label="COMBO SHOT EXP DATE"
                      onChange={SetDhppExpiration}
                      minDate={currentDate}
                    /> */}
                    <CustomDatePickerForAllBrowser
                      label={'COMBO SHOT EXP DATE'}
                      onChange={SetDhppExpiration}
                      minDate={currentDate}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={4} md={4}>
                    <Box
                      className="Profile-parent"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <label
                        htmlFor="select-child-profile"
                        className="label-select"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        ATTACH DOCUMENT
                      </label>
                      <input
                        type="file"
                        accept=".pdf, .doc, .heif, .hevc, .jpeg, .png, .heic, .mp4, .webp, .jpg"
                        multiple
                        hidden
                        id="select-child-profile"
                        // onChange={handleChildFileUpload}
                        onChange={(event) => handleFileInput(event)}
                      />
                    </Box>
                    <i className="text-blue headerTopCloseIcon p-5">
                      {multipleFile.length} of 5 files uploaded
                    </i>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="card-image-carousel m-0">
                      {/* Render the uploaded images */}
                      {multipleFile.map((file, index) => (
                        <div
                          className="single-uploaded-image single-uploaded-files"
                          key={file.id}
                        >
                          <Box className="word-break" id={index}>
                            {file.name}
                          </Box>
                          <Box
                            className="remove-imag remove-file text-blue"
                            onClick={() => removeFile(file.id)}
                          >
                            x
                          </Box>
                        </div>
                      ))}
                    </Box>
                    <Box sx={{ float: 'left', clear: 'both' }}>
                      {/* <i>{multipleFile.length} of 5 files uploaded</i> */}
                      {imageError && (
                        <FormHelperText className="error-message">
                          {imageError}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Button
                    sx={{
                      mt: { xs: 0, md: 0 },
                      border: '2px solid #003087 !important',
                      color: '#003087 !important',
                      padding: '6px 20px !important'
                    }}
                    variant="outlined"
                    className="btn-primary"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    sx={{ mt: { xs: 0, md: 0 } }}
                    variant="contained"
                    type="submit"
                    className="btn-primary"
                  >
                    Save
                  </Button>
                </Box>
                <Grid item xs={12} sm={6}>
                  {showError && (
                    <Box className="error-message">{errorMessage}</Box>
                  )}
                </Grid>
              </Box>
            </>
          )}
        </Grid>
      </>

      {/* client created successfully dialog */}

      <Dialog
        open={showSuccessPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Congratulations!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                The client and dog profiles have been successfully created.
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Category dialog */}
      <Dialog
        open={openAddReferralSource}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Referral Source'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="SOURCE NAME"
                    name="sourceName"
                    className="location-field input-field-styling "
                    onChange={(e) => setSourceName(e.target.value)}
                    // required
                    value={sourceName}
                    variant="outlined"
                    error={Boolean(sourceNameError)}
                    helperText={sourceNameError}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="SOURCE EMAIL"
                      name="sourceEmail"
                      className="location-field input-field-styling "
                      onChange={(e) => SetSourceEmail(e.target.value)}
                      required
                      value={sourceEmail}
                      error={Boolean(sourceEmailError)}
                      helperText={sourceEmailError}
                      variant="outlined"
                      InputLabelProps={{
                        className: 'label-style-create-franchisee required-label'
                      }}
                    />
                  </Grid> */}
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateReferralSource()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleClosePopup('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={openAddClientPopUp}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        // className={!openAddClientPopUp ? 'closeEditClient' : ''}
        className={''}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Congratulations!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                The client and its child has been created successfully!
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* confirmation dialog */}
      <Dialog
        open={openLiabilitySigned}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfClose('Close')}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle className="alert-title align-center">
          {'Delete'}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-su font-13">
                The client cannot buy any services unless
                <Typography className="title-text-su font-13">
                  they have signed the liability waiver. Do you
                </Typography>
                <Typography className="title-text-su font-13">
                  want to add the client without signing the
                </Typography>
                <Typography className="title-text-su font-13">
                  liability waiver?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleConfClose('Close')}
          >
            Yes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleConfClose('Cancel')}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddClient;
