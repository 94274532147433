/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  TextField
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const JourneyFilters = ({
  setFilters,
  filters,
  allCategories,
  allLevels
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();
  const classes = useStyles();

  const activityStatusList = [
    // {name : 'All'},
    { name: 'Published' },
    { name: 'Draft' }
  ];

  const [openFilter, setOpenFilter] = useState({
    juniorTrophy: false,
    assignmentOnly: false,
    activityStatus: false,
    datefilter: false
  });
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      // juniorTrophy: field === 'juniorTrophy' ? !prevState.juniorTrophy : false,
      activityStatus: field === 'activityStatus' ? !prevState.activityStatus : false,
      datefilter: field === 'datefilter' ? !prevState.datefilter : false,
    }));
  };

  const handleChangeFilter = (value, type) => {
    console.log('value', value);
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  // const handleJuniorTrophyChange = (event) => {
  //   let { checked } = event.target;
  //   if (!checked) {
  //     checked = null;
  //   }
  //   setFilters((prevState) => ({ ...prevState, juniorTrophy: checked }));
  // };

  const changeActivityStatus = (selectedOpt) => {
    setFilters((prevState) => ({ ...prevState, activityStatus: selectedOpt }));
  };

  // const handleAssignmentOnlyChange = (event) => {
  //   let { checked } = event.target;
  //   if (!checked) {
  //     checked = null;
  //   }
  //   setFilters((prevState) => ({ ...prevState, assignmentOnly: checked }));
  // };

  const changeStartDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setstartDate(date);
    setFilters((prevState) => ({
      ...prevState,
      startDate: date
    }));
  };

  const changeEndDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setEndDate(date);
    setFilters((prevState) => ({
      ...prevState,
      endDate: date
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: filters.status,
      activityStatus: null,
      startDate: null,
      endDate: null
    });

    setOpenFilter({
      // juniorTrophy: false,
      // assignmentOnly: false,
      activityStatus: null,
      datefilter: false
    });
  };

  return (
    <>
      <Card className="filter-content custom-filter-fields">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) =>
              // handleChangeFilter(event.target.value, 'isActive')
              handleChangeFilter(event.target.value, 'status')
            }
            label="Status"
            className="status-field field-label-css"
          >
            <MenuItem value={null}>All</MenuItem>
            <MenuItem value={'Shared'}>Shared</MenuItem>
            <MenuItem value={'Private'}>Private</MenuItem>
          </Select>
        </FormControl>

        <Divider />

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text filter-text-title"
                sx={{
                  fontWeight: '600',

                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item onClick={() => handleOpenFilter('activityStatus')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.activityStatus && classes.closeY,
                    openFilter.activityStatus && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Activity Status</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.activityStatus && (
          <Box marginTop={1}>
            <Autocomplete
              // disablePortal
              value={filters.activityStatus}
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                changeActivityStatus(newValue);
              }}
              className="status-field field-label-css"
              options={activityStatusList.map((option) => option && option?.name)}
              // options={activityStatusList}
              // getOptionLabel={(option) => (option?.name)}
              sx={{
                width: '100%'
              }}
              renderInput={(params) => (
                <TextField {...params} label={params?.label || 'Activity Status'} />
              )}
            />
          </Box>
        )}

        {/* <Grid
          xs={12}
          onClick={() => handleOpenFilter('juniorTrophy')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.typeOfService && classes.closeY,
                    openFilter.typeOfService && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Activity Status</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.juniorTrophy && (
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.juniorTrophy ?? ''}
                  className="check-box-span"
                  onChange={handleJuniorTrophyChange}
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Junior Trophy</Typography>
              }
            />
             <FormControl fullWidth variant="outlined">
              <InputLabel>Service Type</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'typeOfService')
                }
                value={filters.typeOfService}
                sx={{
                  width: '100%'
                }}
                label="Select Type"
                className="status-field field-label-css"
              >
                <MenuItem value="Enrollment">Enrollment</MenuItem>
                <MenuItem value="Appointment">Appointment</MenuItem>
              </Select>
            </FormControl> 
          </Box>
        )}

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('assignmentOnly')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.typeOfService && classes.closeY,
                    openFilter.typeOfService && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Assignment-based</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.assignmentOnly && (
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.assignmentOnly ?? ''}
                  className="check-box-span"
                  onChange={handleAssignmentOnlyChange}
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Assignment-based</Typography>
              }
            />
          </Box>
        )} */}

        <Grid xs={12} onClick={() => handleOpenFilter('datefilter')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.datefilter && classes.closeY,
                    openFilter.datefilter && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Create Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.datefilter && (
          <>
            <Box marginTop={1}>
              <CustomDatePicker label="Start Date" onChange={changeStartDate} />
            </Box>
            <Box marginTop={1}>
              <CustomDatePicker
                label="End Date"
                onChange={changeEndDate}
                minDate={new Date(startDate)}
                disabled={Boolean(!startDate)}
              />
            </Box>
          </>
        )}
      </Card>
    </>
  );
};