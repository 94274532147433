/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Checkbox
} from '@mui/material';
import { Stack, Avatar } from '@mui/material';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { useEffect } from 'react';

export const InventoryTable = (props) => {
  const {
    tableRows,
    searchQuery,
    quantity,
    setQuantity,
    selectedRows,
    setSelectedRows,
    setIsFieldTouched
  } = props;

  // const [tablePagination, setTablePagination] = useState({
  //   page: 0,
  //   limit: 5
  // });
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // To change the page into the table
  // const handlePageChange = (event, newPage) => {
  //   setTablePagination({ ...tablePagination, page: newPage });
  // };

  // // To change the limit
  // const handleLimitChange = (event) => {
  //   setTablePagination({
  //     ...tablePagination,
  //     limit: parseInt(event.target.value)
  //   });
  // };

  // Apply paination on table
  // const applyPagination = (tableRows) => {
  //   const page = tablePagination.page;
  //   const limit = tablePagination.limit;
  //   return tableRows.slice(page * limit, page * limit + limit);
  // };

  // Apply search filter
  const applyFilters = (tableRows) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = tableRows.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = tableRows;
    }
    return arrayOfMatchedObjects;
  };

  // Apply filter from object and below is the pagination order
  // const filteredRows = applyFilters(tableRows);
  // const paginatedRows = applyPagination(filteredRows);

  const handleQuantity = (e, id, max) => {
    let value = Number(e.target.value);

    // if (value <= max && value >= 0) {
    if (value >= 0) {
      // check if current quantity is equal to 0 and row is already selected then set selected the row
      if (value > 0 && !isSelected(id)) {
        handleClick(e, id);
      }

      // if quantity is 0 then remove the selected row
      // if (value === 0) {
      //   setSelectedRows((prevSelectedRows) => {
      //     let newSelected = [];
      //     newSelected = newSelected.concat(
      //       prevSelectedRows.filter((row) => row !== id)
      //     );
      //     return newSelected;
      //   });
      // }

      setQuantity((prevQuantities) => ({
        ...prevQuantities,
        [id]: value
      }));
    } else {
      setQuantity((prevQuantities) => ({
        ...prevQuantities,
        [id]: 0 // 1
      }));
    }
    setIsFieldTouched(true);
  };

  const handleQuantityBtn = (event, type, id, max) => {
    event.stopPropagation();
    setQuantity((prevQuantities) => {
      const currentQuantity = prevQuantities[id];
      // console.log(quantity[id], currentQuantity, max);
      // if (max !== undefined  && max !== 0 && !quantity[id] && quantity[id] !== 0 && type === 'increment') {
      if (!quantity[id] && quantity[id] !== 0 && type === 'increment') {
        // check if current quantity is equal to 0 and row is already selected then set selected the row
        if (quantity[id] !== 0 && !isSelected(id)) {
          handleClick(event, id);
        }
        return {
          ...prevQuantities,
          [id]: 1
        };
      }
      // if (max !== 0 && type === 'increment') {
      //   return {
      //     ...prevQuantities,
      //     [id]: 1
      //   };
      // }
      // if (type === 'increment' && quantity[id] < max) {
      // if (type === 'increment' && currentQuantity < max) {

      if (type === 'increment') {
        return {
          ...prevQuantities,
          [id]: currentQuantity + 1
        };
      } else if (type === 'decrement' && currentQuantity > 0) {
        // if quantity is 0 then remove the selected row
        // if (currentQuantity === 1) {
        //   setSelectedRows((prevSelectedRows) => {
        //     let newSelected = [];
        //     newSelected = newSelected.concat(
        //       prevSelectedRows.filter((row) => row !== id)
        //     );
        //     return newSelected;
        //   });
        // }
        return {
          ...prevQuantities,
          [id]: currentQuantity - 1
        };
      }
      return prevQuantities;
    });
    setIsFieldTouched(true);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableRows.map((n) => n.sortKey); // n.id
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
    setIsFieldTouched(true);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
    setIsFieldTouched(true);
  };
  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  // const handleSearchTextChange = (event) => {
  //   const searchText = event.target.value.toLowerCase();
  //   setSearchText(searchText);
  //   setPage(0); // Reset page when search text changes
  // };

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  useEffect(() => {
    setPage(0);
  }, [tableRows]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      <Card
        sx={{
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length < filteredData?.length
                    }
                    checked={
                      filteredData?.length > 0 &&
                      selectedRows?.length === filteredData?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all'
                    }}
                  />
                </TableCell>

                <TableCell>Title</TableCell>
                <TableCell>Categories</TableCell>
                <TableCell>Variants</TableCell>
                <TableCell>SKU Code</TableCell>
                <TableCell>Expiry Date</TableCell>
                <TableCell>Quantity on Hand</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData?.length > 0 &&
                paginatedData?.map((row, index) => {
                  const isItemSelected = isSelected(row?.sortKey);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?.sortKey}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      className="table-row cursor-pointer"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row?.sortKey)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>

                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          sx={{ marginTop: 1 }}
                          gutterBottom
                          noWrap
                        >
                          {row?.fName}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {row?.categoryName}
                        </Typography>
                      </TableCell>
                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {row?.variants
                            ?.filter((variant) => variant?.value)
                            ?.map?.((subItem, index, array) =>
                              index === array.length - 1
                                ? subItem?.value
                                : `${subItem?.value}, `
                            )
                            .join('') || '-'}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {row?.sku}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {row?.expiryDate}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {row?.quantity}
                        </Typography>
                      </TableCell>

                      <TableCell className="table-td">
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          <AiFillMinusCircle
                            style={{
                              color: '#003087',
                              fontSize: '1.2rem',
                              cursor: 'pointer'
                            }}
                            onClick={(event) =>
                              handleQuantityBtn(
                                event,
                                'decrement',
                                row?.sortKey,
                                row?.quantity
                              )
                            }
                          />
                          <input
                            type="number"
                            min="0"
                            style={{
                              textAlign: 'center',
                              width: '50px',
                              borderRadius: '45px',
                              border: '1px solid #D0D0D0',
                              outline: 'none',
                              padding: '1px 0',
                              margin: '0 5px'
                            }}
                            value={quantity[row?.sortKey] || 0}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) =>
                              handleQuantity(e, row?.sortKey, row?.quantity)
                            }
                          />
                          <AiFillPlusCircle
                            style={{
                              color: '#003087',
                              fontSize: '1.2rem',
                              cursor: 'pointer'
                            }}
                            onClick={(event) =>
                              handleQuantityBtn(
                                event,
                                'increment',
                                row?.sortKey,
                                row?.quantity
                              )
                            }
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          {/* <TablePagination
            className="pagination-data"
            component="div"
            count={filteredRows?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={tablePagination.page}
            rowsPerPage={tablePagination.limit}
            rowsPerPageOptions={[3, 5]}
          /> */}

          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
