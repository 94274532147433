import { useState, createContext } from 'react';

export const HeaderButtonContext = createContext({});

export const HeaderButtonProvider = ({ children }) => {
  const [showScreen, setShowScreen] = useState(0);
  const [togglePopup, setTogglePopup] = useState(false);
  const [isToggleComponent,setIsToggleComponent] = useState(false)
  const [buttonText,setButtonText] = useState("Add New")
  
  
  const toggleHeaderButton = () => {
   setShowScreen(1);
  };
  const togglePopupButton = () => {
    setTogglePopup(true)
  };
  const togglePopupComponent = () => {
    setIsToggleComponent(true)
  };



  return (
    <HeaderButtonContext.Provider
      value={{
        showScreen,
        togglePopup,
        toggleHeaderButton,
        setShowScreen,
        setTogglePopup,
        togglePopupButton,
        isToggleComponent,
        setIsToggleComponent,
        togglePopupComponent,
        buttonText,
        setButtonText

      }}
    >
      {children}
    </HeaderButtonContext.Provider>
  );
};
