import React from 'react';
import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Slide
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DiscardDialog = ({
  handleClose,
  open,
  handleCloseConfirm,
  title,
  text,
  firstButton,
  secondButton,
  readOnly = false
}) => {
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        {!readOnly && (
          <DialogTitle className="alert-title align-center">
            {title}
          </DialogTitle>
        )}

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">{text}</Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />

        <DialogActions className="alert-button-control">
          {firstButton && (
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleCloseConfirm(firstButton)}
            >
              {firstButton}
            </Button>
          )}

          {secondButton && <Divider className="vertical-divider" />}
          {secondButton && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm(secondButton)}
            >
              {secondButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DiscardDialog;
