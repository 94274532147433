/* eslint-disable no-unused-vars */
import React from 'react';
import { IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { CustomDatePicker } from './index';

export function CalenderMenu({ handleDateChange,handleNextClick,handlePreviousClick,currentDate,view }) {
  return (
    <>
      <IconButton className="filterMenu" style={{marginRight:'20px'}} onClick={handlePreviousClick}>
        <KeyboardArrowLeft />
      </IconButton>

      <CustomDatePicker
        // label="Select your birthday"
        filterType={view}
        currentDate={currentDate.replaceAll('-','/')}
        onChange={handleDateChange}
        // minDate={new Date('2023/05/15')}
        // maxDate={new Date('2024/05/15')}
      />

      <IconButton className="filterMenu" style={{marginLeft:'20px'}} onClick={handleNextClick}>
        <KeyboardArrowRight />
      </IconButton>
    </>
  );
}