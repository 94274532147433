// import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid
} from '@mui/material';
// Apply franchisee filter
const applyFilters = (AllTasks, searchText) => {
  // Commented the above code and added the below code for search functionality
  let arrayOfMatchedObjects = AllTasks.filter((object) => {
    return JSON.stringify(object).toString().toLowerCase().includes(searchText);
  });
  return arrayOfMatchedObjects;
};

// Apply paination on table
const applyPagination = (AllTasks, page, limit) => {
  return AllTasks.slice(page * limit, page * limit + limit);
};

function AllActiveTasks({
  AllTasks,
  userName,
  handleCompleteStatus,
  setShowScreen
}) {
  let allTask = [];
  // get the logged in user id
  const [selectedTaskLists] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchText] = useState(''); // New state for search functionality

  // To change the page into the table
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // To change the list
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  // Apply filter from object and below is the pagination order
  const filteredTaskLists = applyFilters(AllTasks, searchText);
  const paginatedTaskLists = applyPagination(filteredTaskLists, page, limit);

  useEffect(() => {
    allTask = AllTasks;
    console.log('allTask333', allTask, allTask.length);
  }, [AllTasks]);

  /* Function to open the add task popup */

  const handleAllActiveTaskClose = () => {
    setShowScreen(0);
  };

  return (
    <>
      <Grid>
        <Grid container spacing={2} mt={1}>
          <Grid width={1} item>
            {/* Add new task condition block */}
            <Card className="">
              {/* <CardHeader
                                action={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            letterSpacing: '0.135em',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: 700,
                                                color: '#003087',
                                                textDecoration: 'underline',
                                                marginRight: '10px'
                                            }}
                                            onClick={handleOpenAddTask}
                                        >
                                            +Add New Task
                                        </Typography>
                                    </Box>
                                }
                                title="All Active Tasks"
                                className="table-header-name text-primary font-20"
                            /> */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ paddingLeft: '2rem' }}
              >
                <Grid item>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box
                      onClick={() => handleAllActiveTaskClose()}
                      className="back-arrow-close"
                    >
                      <svg
                        width="27"
                        height="19"
                        viewBox="0 0 27 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                          fill="#003087"
                        />
                      </svg>
                    </Box>
                    <Typography className="header-blue-32">
                      All Active Tasks
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* <Divider /> */}
              <Box
                sx={{ maxHeight: '76vh', overflowY: 'auto' }}
                className="table-content "
              >
                <TableContainer
                  className="franchise-table-container word-break"
                  sx={{ padding: '2rem' }}
                >
                  <Table>
                    <TableBody>
                      {(paginatedTaskLists.length > 0 &&
                        paginatedTaskLists.map((taskItem) => {
                          const isTaskItemSelected = selectedTaskLists.includes(
                            taskItem.taskId
                          );
                          return (
                            <TableRow
                              hover
                              key={taskItem.taskId}
                              selected={isTaskItemSelected}
                              className="table-row cursor-pointer"
                            >
                              <TableCell className="table-td d-flex no-border">
                                <Grid
                                  xs={12}
                                  container
                                  sx={{
                                    p: 2,
                                    border: ' 0.4px solid #E7EFF9',
                                    borderRadius: '5px',
                                    backgroundColor: '#FFFFFF'
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={1}
                                    container
                                    sx={{
                                      flex: 1,
                                      justifyContent: 'center',
                                      alignItems: 'flex-start',
                                      marginTop: '3px'
                                    }}
                                  >
                                    <div>
                                      <img
                                        src="/static/images/logo/list.png"
                                        alt="TaskIcon"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        pb: 1
                                      }}
                                    >
                                      <span className="task-name mr-10">
                                        {taskItem.title}{' '}
                                      </span>
                                      {/* <span className='clock-container'><AccessTimeIcon className='clock-icon'
                      sx={{ fontSize: 15, color: '#003087', mr: 1 }}
                    /></span> */}
                                      <span className="mr-10 text-blue">
                                        {taskItem.dueDate}
                                      </span>
                                      {/* <span className="text-blue">{taskItem.dueTime} AM</span> */}
                                      <span>{taskItem.dueTime}</span>
                                    </Typography>

                                    <Typography
                                      sx={{ color: '#A8A4A4' }}
                                      className="word-break pr-10 align-justify"
                                    >
                                      {taskItem.description}
                                    </Typography>
                                    <FormControlLabel
                                      sx={{ ml: '-10px' }}
                                      onChange={() =>
                                        handleCompleteStatus(taskItem.sortKey)
                                      }
                                      control={
                                        <Checkbox
                                          size="small"
                                          disabled={taskItem.isCompleted}
                                          checked={taskItem.isCompleted}
                                        />
                                      }
                                      label={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontWeight: 'bold',
                                            color: 'black'
                                          }}
                                        >
                                          Completed
                                        </Typography>
                                      }
                                      labelPlacement="end"
                                    />
                                  </Grid>

                                  {/* <Divider /> */}
                                  <Grid
                                    item
                                    xs={2}
                                    container
                                    sx={{
                                      flex: 1,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      paddingLeft: '10px',
                                      borderLeft: {
                                        xs: 0,
                                        md: '1px solid rgba(0, 48, 135, 0.1)'
                                      }
                                    }}
                                  >
                                    <Typography>{userName}</Typography>
                                    <PersonIcon
                                      sx={{
                                        fontSize: '15px !important',
                                        ml: 1
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          );
                        })) || <Box ml={1}>No Task Found</Box>}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box p={2}>
                  <TablePagination
                    component="div"
                    count={filteredTaskLists.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 30]}
                  />
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AllActiveTasks;
