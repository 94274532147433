import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import moment from 'moment';

const TrainerAvailabilityTable = (props) => {
  const convertToCustomFormat = (date )=>{
    let utc;
    utc = date ? moment?.utc(date).format('MM/DD/YYYY') : '';
    return utc;
  }
  const { data, TableHeading } = props;
  return (
    <>
      {data && data.length > 0 && (
        <TableContainer
          className="location-table-container servies-details-table"
          sx={{
            borderRadius: '19px 19px 0px 0px',
            maxHeight: 300,
            overflowX: 'hidden'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {TableHeading.map((heading) => {
                  return <TableCell>{heading}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            {data?.map((data) => {
              return (
                <TableBody>
                  <TableCell>
                    {convertToCustomFormat(data.startDate, true)}
                  </TableCell>
                  <TableCell>
                    {convertToCustomFormat(data.endDate, true)}
                  </TableCell>
                  <TableCell>{data.startTime}</TableCell>
                  <TableCell>{data.endTime}</TableCell>
                  <TableCell>{data.availabilityType}</TableCell>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default TrainerAvailabilityTable;
