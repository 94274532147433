import { Helmet } from 'react-helmet-async';
import '../../pages/style.css';
import { Container } from '@mui/material';
import RoomDetail from './RoomDetail';
import AddRoom from './AddRoom';
import Footer from 'src/components/Footer';
import React, { useEffect, useState } from 'react';
import GlobalData from '../../../global';
import { getLocalData } from 'src/utils'; // filtersQuery
// import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import { CustomRoomTable } from './CustomRoomTable';
// import { ContractFilter } from 'src/components/Filters/ContractFilter';

import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Header from 'src/components/Header';
import Loader from 'src/components/Loader';

function FrachiseeRooms() {
  // const [selectedRow, setSelectedRow] = useState('');
  const locationId = getLocalData('locationId');

  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [first, setfirst] = useState(false);

  const title = GlobalData[0].title;
  const [showAddContract, setShowAddRoom] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [contracts, setRoom] = useState([]);
  const [roomsDetail, setRoomDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const fetchData = async () => {
    setIsLoading(true);

    let params = {
      locationId: locationId
    };

    try {
      let roomsData = await ApiService.post(
        `${API_URL}rooms/getAllRooms`,
        params
      );

      if (roomsData.statusCode && !roomsData.error) {
        let Item = roomsData && roomsData.data ? roomsData.data : [];
        const result = Item?.Items?.map((obj) => {
          const {
            partitionKey,
            sortKey,
            name,
            description,
            forAllServices,
            services
          } = obj;

          return {
            partitionKey,
            sortKey,
            Room: name,
            Description: description,
            Services:
              forAllServices === true
                ? 'For All Services'
                : services?.length
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  services
                    ?.map((i) => i?.serviceName)
                    ?.slice(0, 2)
                    ?.join(' , ') + (services?.length > 2 ? ' ...' : '')
                : '-'
          };
        });
        setRoom(result);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          roomsData.message || 'Something went wrong with inventory'
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
      // toast.error('Internal Server Error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleResetStates = () => {
    setRoomDetails('');
    setShowAddRoom(false);
  };

  const handleOnClick = (record) => {
    // let arr = [record[1]];
    // let filterRecord = contracts?.filter((i) => arr.includes(i.sortKey));
    setRoomDetails(record);
    if (record[2] === 'Edit') {
      setShowAddRoom(true);
      setfirst(true);
    } else if (record[2] === 'Detail') {
      // console.log('Detail');
    }
  };

  const handleButtonClick = () => {
    setShowAddRoom(true);
  };

  const buttons = [
    {
      label: 'Add New Room',
      onClick: handleButtonClick,
      theam: true
    }
  ];

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <div className="myContainerRight">
        {showErrorMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowErrorMessage}
          />
        )}

        {showSucessMessage && (
          <SuccessMessage
            message={sucessMessage}
            setShowMessage={setShowSuccessMessage}
            closePopup={closePopup}
          />
        )}
        <Helmet>
          <title>Rooms | {title}</title>
        </Helmet>

        {showAddContract ? (
          <AddRoom
            handleOpenScreen={() => setShowAddRoom(true)}
            handleClose={handleResetStates}
            roomsDetail={roomsDetail}
            type={1}
            fetchData={fetchData}
            setfirst={setfirst}
            first={first}
            handleResetStates={handleResetStates}
            setroomDetails={setRoomDetails}
            setShowSuccessMessage={setShowSuccessMessage}
            setSuccessMessage={setSuccessMessage}
            showSucessMessage={showSucessMessage}
            sucessMessage={sucessMessage}
          />
        ) : roomsDetail !== '' ? (
          <RoomDetail
            data={roomsDetail}
            handleClosePage={handleResetStates}
            handleOpenScreen={setShowAddRoom}
            fetchData={fetchData}
          />
        ) : (
          <Container maxWidth="100%" className="main-container">
            {!showAddContract && roomsDetail === '' && (
              <Header title="Rooms" buttons={buttons} />
            )}
            {/* <DynamicTabsTwo list={tabList} /> */}
            <CustomRoomTable
              data={contracts}
              handleOnClick={handleOnClick}
              label="All Rooms"
            />
          </Container>
        )}
        <Footer />
      </div>
    </>
  );
}

export default FrachiseeRooms;
