import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    Typography,
    Card,
    Grid,
    Paper,
    Box,
    Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { ApiService } from '../../services/ApiService';
import { getLocalData, setFullLocationId } from '../../utils';

export const PromotionsFilter = ({ setFilters, filters }) => {
    const useStyles = makeStyles(() => ({
        openY: {
            transform: 'rotate(90deg)',
            color: '#003087'
        },
        closeY: {
            transform: 'rotate(0deg)',
            color: '#003087'
        }
    }));
    const locationId = (setFullLocationId(getLocalData('locationId')));
    const classes = useStyles();
    // const [expired, SetExpired] = useState(true);
    // const [service, SetService] = useState([]);

    const [promotionData, setPromotionData] = useState([]); // getting dynamic list

    const [openFilter, setOpenFilter] = useState({
        discount: false,
    });

    const getPromotions = async () => {
        try {
            let encodedLocationId = encodeURIComponent(locationId);
            const response = await ApiService.get(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}promotions?locationId=${encodedLocationId}`);

            // let Item = response && response.data && response.data.result && response.data.result.Items ? response.data.result.Items : [];
            let Item = response && response?.data && response?.data?.Items ? response?.data?.Items : [];
            const discount = Array.from(new Set(Item.map(obj => obj.discount)));
            setPromotionData(discount);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // get all categories
        getPromotions();
    }, []);

    const changeType = (value) => {
        setFilters((prevState) => ({ ...prevState, status: value }));
    };
    const ChangeDiscount = (value) => {
        setFilters((prevState) => ({ ...prevState, discount: value }));
    };

    const handleOpenFilter = (field) => {
        setOpenFilter((prevState) => ({
            discount: field === 'discount' ? !prevState.discount : false
        }));
    };

    const clearFilters = () => {
        setFilters({
            // status: filters.status,
            status: filters.status,
            discount: ''
        });

        setOpenFilter({
            discount: false
        });
    };

    return (
        <>
            <Card className="filter-content">
                <Typography className="filter-label" pt={2} pb={3}>
                    Type
                </Typography>
                <FormControl
                    pt={2}
                    fullWidth
                    variant="outlined"
                    sx={{
                        width: '100%',
                        marginBottom: '10px'
                    }}
                >
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={filters.status ?? 1}
                        onChange={(event) => changeType(event.target.value)}
                        label="Type"
                        className="status-field field-label-css"
                    >
                        <MenuItem key={1} id={1} value={-1}>All</MenuItem>
                        <MenuItem key={2} id={2} value={1}>Active</MenuItem>
                        <MenuItem key={3} id={3} value={0}>Inactive</MenuItem>
                        <MenuItem key={3} id={3} value={3}>Expired</MenuItem>
                    </Select>
                </FormControl>
                <Divider />
                <Grid mt={2}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <Typography
                                className="filter-text"
                                sx={{
                                    fontWeight: '600',
                                    color: '#003087',
                                    fontSize: '16px'
                                }}
                            >
                                Filters
                            </Typography>
                        </Grid>
                        <Grid item onClick={clearFilters}>
                            <Typography
                                className="filter-text"
                                sx={{
                                    fontWeight: '600',
                                    fontSize: '16px',
                                    cursor: 'pointer'
                                }}
                            >
                                Clear
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* //discount  */}
                <Grid item xs={12} onClick={() => handleOpenFilter('discount')} pt={0.5}>
                    <Paper>
                        <Grid
                            container
                            alignItems="center"
                            justify="space-between"
                            className="pointer"
                        >
                            <Grid item>
                                <KeyboardArrowRightRoundedIcon
                                    className={clsx(
                                        !openFilter.discount && classes.closeY,
                                        openFilter.discount && classes.openY
                                    )}
                                />
                            </Grid>
                            <Grid item >
                                <Typography>Discount</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {openFilter.discount && (
                    <Box marginTop={1}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Discount</InputLabel>
                            <Select
                                onChange={(event) => ChangeDiscount(event.target.value)}
                                value={filters.discount ?? ''}
                                sx={{
                                    width: '100%'
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 224,
                                            overflowY: 'auto',
                                        },
                                    },
                                }}
                                label="Discount"
                                className="status-field field-label-css"
                            >
                                {/* <MenuItem key={0} id={0} value="">None</MenuItem> */}
                                {promotionData.length > 0 && (
                                    promotionData.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                )}

            </Card>
        </>
    );
};


