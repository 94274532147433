import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
 
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const FeaturedItemsFilter = ({ setFilters, filters }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState({
    itemType: false
  });

  useEffect(() => {
  }, []);

  const changeType = (value) => {
    // setFilters((prevState) => ({ ...prevState, isActive: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const ChangeItem = (value) => {
    setFilters((prevState) => ({ ...prevState, itemType: value }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      itemType: field === 'itemType' ? !prevState.itemType : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      itemType: ''
    });

    setOpenFilter({
      itemType: false,
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Type</InputLabel>
          <Select
            // value={filters.isActive ?? ''}
            value={filters.status ?? ''}
            onChange={(event) => changeType(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem key={1} id={1} value={-1}>All</MenuItem>
            {/* <MenuItem key={2} id={2} value={Boolean(true)}>Active</MenuItem>
            <MenuItem key={3} id={3} value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem key={2} id={2} value={1}>Active</MenuItem>
            <MenuItem key={3} id={3} value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>
        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} onClick={() => handleOpenFilter('itemType')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.itemType && classes.closeY,
                    openFilter.itemType && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography> Items </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.itemType && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Item</InputLabel>
              <Select
                onChange={(event) => ChangeItem(event.target.value)}
                value={filters.itemType ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Select Item"
                className="status-field field-label-css"
              >                
                <MenuItem key={1} id={1} value={-1}>All</MenuItem>
                <MenuItem key={2} id={2} value={`Bundles`}>Bundles</MenuItem>
                <MenuItem key={3} id={3} value={`Memberships`}>Memberships</MenuItem>
                <MenuItem key={4} id={4} value={`Contracts`}>Contracts</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};


