import React, { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField, Box, Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
 
export const CustomDatePicker = ({
  onChange,
  label,
  required,
  disabled,
  currentDate,
  filterType
}) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [formatDate, setFormatDate] = useState(currentDate);
  const [open, setOpen] = React.useState(false);
  const requiredField = required || false;
 
  const handleDateChange = (date) => {
    if (date) {
      let formatDate = new Date(date);
      let month = formatDate.getMonth() + 1;
      let day = formatDate.getDate();
      let year = formatDate.getFullYear();
      // Add leading zeros to month and day if needed
      let formattedMonth = month.toString().padStart(2, '0');
      let formattedDay = day.toString().padStart(2, '0');
      let dateMDY = `${formattedMonth}/${formattedDay}/${year}`;
      /* Date converted to MM-DD-YYYY format */
      setSelectedDate(date);
      onChange(dateMDY); // Call the onChange callback with the selected date
    }
  };
 
  useEffect(() => {
    try {
      let formatDateStr = '';
      let formatDate = new Date(currentDate);
      let monthFirstDay = getFirstDateOfMonth(formatDate);
      let fDate = getFirstDateOfCurrentWeek(formatDate);
      let sDate = getLastDateOfCurrentWeek(formatDate);
      if (filterType === 'Month') {
        formatDateStr = `${
          months[monthFirstDay.getMonth()]
        } , ${monthFirstDay.getFullYear()}`;
      } else {
        const monthNamef = fDate.toLocaleString('en-us', { month: 'short' });
        const monthNames = sDate.toLocaleString('en-us', { month: 'short' });
        formatDateStr = `${fDate.getDate()} ${monthNamef} - ${sDate.getDate()} ${monthNames}`;
      }
      setFormatDate(formatDateStr);
    } catch (e) {
      console.log(e);
    }
  }, [currentDate, filterType]);
 
  const handleInputChange = (event) => {
    event.preventDefault();
    setOpen(true);
  };
 
  const openCalander = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
 
  const getFirstDateOfCurrentWeek = (currentDate) => {
    const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ... , 6 = Saturday)
    const daysUntilMonday = (dayOfWeek + 6) % 7; // Calculate how many days to subtract to reach Monday
 
    const firstDate = new Date(currentDate);
    firstDate.setDate(currentDate.getDate() - daysUntilMonday);
 
    return firstDate;
  };
 
  const getLastDateOfCurrentWeek = (currentDate) => {
    const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ... , 6 = Saturday)
 
    let daysToAdd;
    if (dayOfWeek === 0) {
      // If the current day is Sunday
      daysToAdd = 0; // No days to add as it's already Sunday
    } else {
      daysToAdd = 7 - dayOfWeek; // Calculate how many days to add to reach Sunday
    }
 
    const lastDate = new Date(currentDate);
    lastDate.setDate(currentDate.getDate() + daysToAdd);
 
    return lastDate;
  };
 
  // const getFirstDateOfCurrentWeek = (currentDate) => {
  //   const dayOfWeek = currentDate.getDay();
  //   const firstDate = new Date(currentDate);
  //   firstDate.setDate(currentDate.getDate() - dayOfWeek);
  //   return firstDate;
  // };
 
  // const getLastDateOfCurrentWeek = (currentDate) => {
  //   const dayOfWeek = currentDate.getDay();
  //   const lastDate = new Date(currentDate);
  //   lastDate.setDate(currentDate.getDate() + (6 - dayOfWeek));
  //   return lastDate;
  // };
 
  const getFirstDateOfMonth = (currentDate) => {
    const firstDate = new Date(currentDate);
    firstDate.setDate(1); // Set the day of the month to 1, the first day of the month
    return firstDate;
  };
 
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="staffTimeOffDatePick">
        <Box className={'staffLabelDate'}>
          <Grid container sx={{ font: 'text.primary' }} onClick={openCalander}>
            <Grid item xs={10}>
              <Typography variant="h6" className="selected-month">
                {formatDate}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <KeyboardArrowDownIcon />
            </Grid>
          </Grid>
        </Box>
        <DesktopDatePicker
          label={label}
          value={currentDate || selectedDate}
          onChange={handleDateChange}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              sx={{ width: '100%', visibility: 'hidden' }}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true
              }}
              InputLabelProps={{
                className: requiredField ? 'required-label' : ''
              }}
              className={`custom-datepicker ${
                requiredField ? 'required-field' : ''
              }`}
              onClick={handleInputChange}
            />
          )}
          inputProps={{ readOnly: true }} // Make the input field read-only
          PopperProps={{
            disablePortal: true // Prevent the Popper component from rendering in a portal
          }}
          open={open}
          onClose={() => setOpen(false)}
          inputFormat="MMMM dd, yyyy"
        />
      </Box>
    </LocalizationProvider>
  );
};
 