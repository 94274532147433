// import { } from 'react';
import { React, useState } from 'react';
import GlobalData from 'src/global';
import { Helmet } from 'react-helmet-async';
import Header from '../../../components/Header';
// import { HeaderButtonContext } from 'src/contexts/HeaderButtonContext';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import DrawFeaturedItemTable from './drawFeaturedItemTable';

import './styles.css'
import { Box } from '@mui/material';

const FeaturedItem = () => {
  // Now update the header title through context
  // const { setHeaderText } = useContext(HeaderContext);
  // const { showScreen, setShowScreen, setIsToggleComponent } = useContext(HeaderButtonContext);
  // setHeaderText('Featured Items');
  // let elm = document.getElementsByClassName('page-header-text')[0];
  // if (elm) elm.innerHTML = '';
  const [showScreen, setShowScreen] = useState(0);
  const title = GlobalData[0].title;
  // useEffect(() => {
  //   setIsToggleComponent(false)
  // }, [])
  const handleButtonClick = () => {
    setShowScreen(1)
  };
  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam:true
    },
    
  ];
  // const BASE_URL = `${process.env.REACT_APP_INVENTRY_API_END_POINT}`;
  return (
    <>

      <Helmet>
        <title>Featured Items | {title}</title>
      </Helmet>
      <Box  sx={{paddingX:"30px"}} >

      {showScreen===0 &&<Header title="Featured Items" buttons={buttons} />}
      </Box>

      <DrawFeaturedItemTable
        setShowScreen={setShowScreen}
        showScreen={showScreen}
      />

    </>
  )
}
export default FeaturedItem;