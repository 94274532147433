import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Grid, Stack, Autocomplete, TextField } from '@mui/material';
import { DynamicButton } from '../../../../components/Checkout';
import cancelRed from '../../../../assets/Images/cancelRed.svg';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { getLocalData } from 'src/utils/index';
import {
  getClientList,
  getPetList,
  guestSignUp
} from '../Functions/SelectClientFunctions';
import debounce from 'lodash/debounce';
import GuestForm from '../Components/GuestForm';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';

const useStyles = makeStyles({
  option: {
    '&:hover': {
      backgroundColor: '#00308733 !important',
      color: '#fff'
    }
  }
});
export const SelectClient = ({ handleNext, setSelected, selected }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const API_BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;

  const [clientList, setClientList] = useState([]);
  const [petList, setPetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // REFRESH WARNING IF UNSAVED DATA
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (selected.client) {
        event.preventDefault();
        event.returnValue =
          'You have unsaved changes. Are you sure you want to leave?';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selected.client]);

  useEffect(() => {
    // Define the event handler function
    const handleBeforeUnload = (event) => {
      if (selected?.guestClient) {
        event.preventDefault();
        event.returnValue = 'Changes you made may not be saved';
      }
    };
    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selected?.guestClient]);

  const handleSelectedClient = (client) => {
    setSelected({ ...selected, client: client });
  };
  const removeClient = () => {
    setSelected({ ...selected, client: null, pet: null });
    setSearchValue('');
  };

  const handleInputChange = (event, newValue) => {
    handleSelectedClient(newValue);
    getPetList(
      newValue.clientId,
      newValue.locationId,
      API_BASE_URL,
      setPetList,
      setLoading
    );
    setOpen(false);
    setSearchValue('');
  };

  const handlePetChange = (event, newValue) => {
    handleSelectedPet(newValue);
  };

  const handleSelectedPet = (pet) => {
    setSelected({ ...selected, pet: pet });
  };

  const handleSearchClient = (e) => {
    let value = '';
    value = e.target.value;
    setSearchValue(value);
    if (value.length) {
      debouncedIsExist(value);
    } else setClientList([]);
  };

  const handleGuest = (event, guestCheckout) => {
    guestSignUp(
      event,
      BASE_URL,
      handleNext,
      setLoading,
      locationId,
      franchiseeId,
      guestCheckout,
      selected,
      setSelected,
      setShowMessage,
      setErrorMessage
    );
  };

  // Delayed client list api
  const debouncedIsExist = useCallback(
    debounce(
      (value) =>
        getClientList(
          value,
          BASE_URL,
          locationId,
          setClientLoading,
          setClientList,
          setLoading
        ),
      300
    ),
    []
  );

  useEffect(() => {
    if (searchValue === '') {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [searchValue]);

  useEffect(() => {
    // Update the active step value in local storage
    localStorage.setItem('activeStep', '0');
  }, []);

  const clientInputRef = useRef(null);

  const petInputRef = useRef(null);

  useEffect(() => {
    if (clientInputRef?.current) {
      clientInputRef?.current?.focus();
    }
  }, []);

  // Component UI
  return (
    <>
      <Loader IsLoading={loading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: '16px',
          marginLeft: '0px',
          width: '100%',
          justifyContent: 'center'
        }}
      >
        {selected?.guestClient ? (
          <GuestForm
            handleGuest={handleGuest}
            selected={selected}
            setSelected={setSelected}
          />
        ) : (
          <>
            <Box className="clientSearch searchBar">
              <Autocomplete
                style={{
                  width: '400px'
                }}
                classes={{
                  option: styles.option
                }}
                open={open}
                // clearOnBlur={false}
                disabled={selected?.client || selected?.guestClient}
                value={selected?.client}
                onChange={handleInputChange}
                className="status-field field-label-css"
                noOptionsText={clientLoading ? 'Loading...' : 'No client found'}
                options={clientList ?? []}
                getOptionLabel={(option) =>
                  option && `${option?.firstName} ${option?.lastName}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <SearchIcon />
                    }}
                    label={'Search Client *'}
                    variant="outlined"
                    autoComplete="off"
                    value={searchValue}
                    onChange={handleSearchClient}
                    inputRef={clientInputRef}
                  />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: '200px' // Set the desired height for the options card
                  }
                }}
                renderOption={(props, item) => {
                  return (
                    <li {...props} key={item.clientId}>
                      {`${item?.firstName} ${item?.lastName}`}
                    </li>
                  );
                }}
              />
            </Box>
            <Box className="clientSearch searchBar clientItem">
              <Autocomplete
                style={{
                  width: '400px'
                }}
                classes={{
                  option: styles.option
                }}
                disabled={
                  !selected?.client || selected?.pet || selected?.guestClient
                }
                value={selected.pet}
                onChange={handlePetChange}
                noOptionsText="No dogs found"
                className="status-field field-label-css"
                options={petList ?? []}
                getOptionLabel={(option) => option?.firstName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps
                    }}
                    label={'Select Dog *'}
                    variant="outlined"
                    inputRef={petInputRef}
                  />
                )}
                ListboxProps={{
                  style: {
                    maxHeight: '200px' // Set the desired height for the options card
                  }
                }}
              />
            </Box>
            {selected.client !== undefined && selected.client !== null && (
              <Box
                className="clientItem remove-client-button"
                sx={{ marginBottom: '10px' }}
              >
                <Box style={{ width: '400px', borderRadius: '10px' }}>
                  {selected?.client?.firstName} {selected?.client?.lastName}
                  {selected?.pet?.firstName
                    ? ` - (${selected?.pet?.firstName})`
                    : ''}
                </Box>
                <DynamicButton
                  onClick={() => removeClient(selected.client)}
                  variant={'contained'}
                  src={cancelRed}
                  type={'cancel'}
                />
              </Box>
            )}
          </>
        )}
        {selected?.client !== null && selected?.client !== undefined ? (
          <Box
            style={{
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <DynamicButton
              title={'Go Back'}
              variant="contained"
              onClick={() => {
                window.history.back();
              }}
              style={{
                padding: '10px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px',
                marginRight: '10px',
                background: '#fff',
                color: '#003087',
                border: '2px solid #003087'
              }}
            />

            <DynamicButton
              onClick={handleNext}
              variant={'contained'}
              disabled={
                !selected?.pet ||
                selected?.pet === null ||
                selected?.pet === undefined
              }
              title={'Add Items'}
              style={{
                padding: '11px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px'
              }}
            />
          </Box>
        ) : !selected?.guestClient ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            bottom="80px"
            right="0"
            left={'0'}
          >
            <DynamicButton
              onClick={() => navigate('/client-management')}
              variant={'contained'}
              disabled={selected.client}
              title={'Add New Client'}
              style={{
                padding: '11px 23px',
                borderRadius: '10px',
                textTransform: 'capitalize',
                maxWidth: 'max-content',
                fontWeight: '600',
                fontSize: '16px',
                margin: '0 auto 24px',
                backgroundColor: '#003087',
                color: '#ffffff'
              }}
            />
            <DynamicButton
              onClick={() => setSelected({ ...selected, guestClient: true })}
              variant={'text'}
              title={'Check Out As Guest'}
              style={{
                padding: '0',
                maxWidth: 'max-content',
                background: 'transparent',
                textDecoration: 'underline',
                fontWeight: '600',
                fontSize: '15px'
              }}
            />
          </Stack>
        ) : null}
      </Grid>
    </>
  );
};
