/**
 * Sample for smart axis labels
 */
import * as React from 'react';
import moment from 'moment';

import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
  DataLabel,
  Category,
  ColumnSeries,
  Inject
} from '@syncfusion/ej2-react-charts';

const SAMPLE_CSS = `
     .control-fluid {
         padding: 0px !important;
     }`;

function ColumnDashboardWidget({ chartId, xAxisTitle, yAxisTitle, graphData }) {
  const dateFormate = (date) => {
    let utc;
    utc = date ? moment?.utc(date)?.format('MMM DD, YYYY') : '';
    return utc;
  };

  graphData?.forEach((item) => {
    let fromDate = dateFormate(item.x.slice(0, 10));
    let toDate = dateFormate(item.x.slice(13, 26));
    item.x = `${fromDate}  -  ${toDate}`;
  });

  return (
    <div className="control-pane">
      <style>{SAMPLE_CSS}</style>
      <div className="control-section row">
        <div className="col-md-8">
          <ej-chart id="charts" background="skyblue">
            <ChartComponent
              id={chartId}
              style={{ textAlign: 'center' }}
              primaryXAxis={{
                title: `${xAxisTitle}`,
                valueType: 'Category',
                interval: 1,
                majorGridLines: { width: 0 },
                labelIntersectAction: 'Hide',
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 }
              }}
              // Y-axis configuration
              primaryYAxis={{
                labelStyle: { size: '12px' }, // Adjust the font size as needed
                title: `${yAxisTitle}`, // Add a title to the Y-axis
                majorTickLines: { width: 1 },
                majorGridLines: { width: 1 },
                lineStyle: { width: 1 }
              }}
              chartArea={{ border: { width: 0 } }}
              load={load}
              loaded={onChartLoad}
              legendSettings={{ visible: false }}
              tooltip={{
                enable: true,
                header: ''
              }}
            >
              <Inject
                services={[
                  Category,
                  Category,
                  ColumnSeries,
                  Tooltip,
                  DataLabel
                ]}
              />
              <SeriesCollectionDirective>
                <SeriesDirective
                  dataSource={graphData}
                  xName="x"
                  yName="y"
                  name="Users"
                  type="Column"
                  fill="#E35205"
                  marker={{
                    dataLabel: {
                      visible: true,
                      angle: -90,
                      position: 'Top',
                      format: '{value}',
                      font: {
                        fontWeight: '600',
                        color: '#ffffff'
                      }
                    }
                  }}
                />
              </SeriesCollectionDirective>
            </ChartComponent>
          </ej-chart>
        </div>
      </div>
    </div>
  );
  function onChartLoad() {
    let chart = document.getElementById('charts');
    chart.setAttribute('title', '');
  }
  function load(args) {
    let selectedTheme = window.location.hash.split('/')[1];
    selectedTheme = selectedTheme || 'Material';
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, 'Dark')
      .replace(/contrast/i, 'Contrast');
  }
}
export default ColumnDashboardWidget;
