/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Day,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective
} from '@syncfusion/ej2-react-schedule';
import './style.css';
import { Stack } from '@mui/system';
import {
  Avatar,
  Typography,
  Box,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  DialogActions
} from '@mui/material';
import { ApiService } from 'src/services/ApiService';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const StaffTimeOffCalender = ({
  currentDate,
  trainerData,
  handleOpenModal,
  startHour,
  endHour,
  setendTime,
  setfromTime,
  setendDate,
  setFromDate,
  setselectedTrainerData,
  getAllTrainers,
  setIsLoading
}) => {
  let scheduleObj = useRef(null);
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState(null);

  const splitData = [];
  const navigate = useNavigate();
  const onRenderCell = (args) => {
    if (
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.length === 1) ||
      (args.element.classList.contains('e-work-cells') &&
        args.element.classList.contains('e-alternate-cells') &&
        args.element.classList.length === 2)
    ) {
      args.element.style.backgroundColor = '#ffff';
    }

    if (
      args.element.classList.contains('e-header-cells') ||
      args.element.classList.contains('e-all-day-cells')
    ) {
      args.element.style.display = 'none';
    }
  };

  trainerData.forEach((trainer) => {
    if (
      trainer.unavailableSchedules &&
      trainer.unavailableSchedules.length > 0
    ) {
      trainer.unavailableSchedules.forEach((item) => {
        const startDate = item?.startDate?.split('-');
        const endDate = item?.endDate?.split('-');
        let sDate = `${startDate[1]}-${startDate[2]}-${startDate[0]}`;
        let eDate = `${endDate[1]}-${endDate[2]}-${endDate[0]}`;
        splitData.push({
          ...item,
          startDate: sDate, // Format date as needed
          endDate: eDate // Format date as needed
        });
      });
    }
  });

  const combinedData = [];
  splitData.forEach((trainer) => {
    let FromDate = `${trainer.startDate}, ${trainer.startTime}`;
    let ToDate = `${trainer.endDate}, ${trainer.endTime}`;
    combinedData.push({
      Id: trainer.sortKey,
      Subject: `Unavailable`,
      StartTime: FromDate,
      EndTime: ToDate,
      IsAllDay: false,
      isBlock: true,
      TrainerId: trainer.partitionKey,
      EventType: 'unavailability'
    });
  });

  const resourceHeaderTemplate = (props) => {
    return (
      <Stack flexDirection="row">
        <Avatar
          alt={props?.resourceData?.trainerName}
          src={props?.resourceData?.trainerProfileImage}
        />
        <Stack flexDirection="column" marginLeft="10px">
          <Typography variant="h4">
            {props?.resourceData?.trainerName}
          </Typography>
          <Typography variant="body1">
            {props?.resourceData?.roles[0]?.role}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const onPopupOpen = (args) => {
    const detail = args?.data ? args?.data : args;
    const startDateTimeString = detail?.StartTime;
    const originalStartDate = new Date(startDateTimeString);
    const formattedStartTime = originalStartDate
      .toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .toUpperCase();
    const formattedStartDate = `${
      originalStartDate.getMonth() + 1
    }/${originalStartDate.getDate()}/${originalStartDate.getFullYear()}`;
    const endDateTimeString = detail?.EndTime;
    const originalEndDate = new Date(endDateTimeString);
    const formattedEndTime = originalEndDate
      .toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      })
      .toUpperCase();
    const formattedEndDate = `${
      originalEndDate.getMonth() + 1
    }/${originalEndDate.getDate()}/${originalEndDate.getFullYear()}`;

    setfromTime(formattedStartTime);
    setFromDate(formattedStartDate);
    setendTime(formattedEndTime);
    setendDate(formattedEndDate);
    setselectedTrainerData(detail?.TrainerId);

    if (detail.EventType === 'unavailability') {
      args.cancel = false;
    } else {
      handleOpenModal();
    }
    if (
      args.type === 'Editor' ||
      args.target.classList.contains('e-selected-cell') ||
      (args.target && args.target.classList.contains('e-work-cells'))
    ) {
      args.cancel = true;
    }
  };

  // navigate to staff managment
  const navigateToAddStaff = () => {
    navigate('/staff-management');
  };

  const contentTemplate = (props) => {
    setPayload({
      trainerId: props.TrainerId,
      slotId: props.Id
    });

    return (
      <Box className="enrollPopup">
        <Button onClick={() => setOpen(true)}>Remove Schedule</Button>
      </Box>
    );
  };

  // Remove Shedule from calender
  async function handleRemoveTrainer() {
    setIsLoading(true);
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/removeStaffTimeOff`,
        payload
      );

      if (response.statusCode === 200) {
        getAllTrainers();
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  return (
    <div className="schedule-control-section">
      <div className="col-lg-12 control-section">
        {trainerData?.length > 0 ? (
          <div className="control-wrapper">
            <ScheduleComponent
              ref={(schedule) => (scheduleObj = schedule)}
              allowSwiping={false}
              showTimeIndicator
              cssClass={'schedule-cell-dimension'}
              width="100%"
              height="800px"
              currentView="Day"
              resourceHeaderTemplate={resourceHeaderTemplate}
              eventSettings={{
                dataSource: combinedData
              }}
              quickInfoTemplates={{
                content: contentTemplate,
                header: () => {
                  return null;
                }
              }}
              selectedDate={currentDate}
              popupOpen={onPopupOpen}
              renderCell={onRenderCell}
              startHour={startHour}
              endHour={endHour}
              // startHour='00:00'
              // endHour='24:00'
              showHeaderBar={false}
            >
              <ResourcesDirective>
                <ResourceDirective
                  field="TrainerId"
                  title="Trainer"
                  name="Trainer"
                  dataSource={trainerData}
                  idField="trainerId"
                  allowMultiple
                />
              </ResourcesDirective>

              <ViewsDirective>
                <ViewDirective
                  option="Day"
                  group={{ resources: ['Trainer'], enableCompactView: false }}
                />
              </ViewsDirective>
              <Inject services={[Day]} />
            </ScheduleComponent>
          </div>
        ) : (
          <Box mt={5} align={'center'}>
            <Typography variant="h1" className="schedule-header" mb={5}>
              You first need to add a staff to set staff timeoff.
            </Typography>
            <Button
              variant="contained"
              className="top-button-style"
              sx={{ padding: '15px' }}
              onClick={navigateToAddStaff}
              mt={5}
            >
              {' '}
              Add Staff{' '}
            </Button>
          </Box>
        )}
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to remove this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleRemoveTrainer()}
          >
            Remove
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default StaffTimeOffCalender;
