import { useState, useEffect } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  DialogActions
} from '@mui/material';
import './AddRoom.css';
import Loader from '../Loader';
import { getLocalData } from '../../utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddRoom({ setRefreshRooms, openAddRoom, setOpenAddRoom, setIsAddRoomOpen, getAllRooms }) {
  
  /* States to set the data */
  const [roomName, setRoomName] = useState('');
  const [description, setdescription] = useState('');
  /* States to set error messages */
  const [roomNameError, setRoomNameError] = useState('');
  const [roomDescError, setRoomDescError] = useState('');
  
  /* State to open confirmation dialog box */
  const [open, setOpen] = useState(false);
  /* State to check whether the value is added/updated  */
  const [isUpdated, setIsUpdated] = useState(false);
  /* State for checking the state of field update */
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const locationId = getLocalData('locationId');

  useEffect(() => {
    setOpen(false);
    setOpenAddRoom(true);
    setIsUpdated(false);
  }, []);

  /* Variable getting user id from local storage */
  let userId = localStorage.getItem('userId');

  /* Function to set team name */
  const roomNameSet = (data) => {
    if (!data) {
      setRoomNameError('Room name is required');
    } else {
      setRoomNameError('');
      setRoomName(data);
      setIsFieldUpdated(true);
    }
  };

  /* Function to set description */

  const descriptionSet = (description) => {
    if (!description) {
      setRoomDescError('Description is required');
    } else {
      setdescription(description);
      setRoomDescError('');
      setIsFieldUpdated(true);
    }
  };

  /* Function to add team  */

  const AddRoom = () => {
    if (!roomName) {
      setRoomNameError('Room name is required');
    }

    if (!description) {
      setRoomDescError('Description is required');
    }
    
    if (roomName && description) {
      AddRoomData();
    }
  };

  /* Function to call add team api */

  const AddRoomData = () => {
    // Shwo loader
    setIsLoading(true);

    // let encodedLocationId = encodeURIComponent(locationId);

    let roomData = JSON.stringify({
      name: roomName,
      description: description,
      createdBy: userId,
      locationId: locationId
    });
    fetch(`${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: roomData
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode) {
          // Now call method to refresh the team list
          // getAllRooms();
          setOpenAddRoom(false);
          setIsUpdated(true);
          // Shwo loader
          setIsLoading(false);
          setOpen(true);
          setRefreshRooms(true);
        }
      });
  };

  /* Function to close confirm dialog */

  const handleClose = (val) => {
    
    if (val === 'Save') {
      setOpen(false);
      setOpenAddRoom(true);
    } else if (val === 'Discard') {
      setIsAddRoomOpen(false);
      setOpenAddRoom(false);
      // setOpenTeams(true);
      setOpen(false);
      setOpenAddRoom(false);
      setIsFieldUpdated(false);
      getAllRooms();
      clearAll();
    }
  };

  /* Function to clear all fields and error */

  const clearAll = () => {
    setRoomName('');
    setdescription('');
    setRoomNameError('');
  };

  /* Function to close the add item dialog */

  const handleAddRoomClose = () => {
    if (isFieldUpdated) {
      
      setOpenAddRoom(true);
      setOpen(true);
    } else {
      setIsAddRoomOpen(false);
      setOpenAddRoom(false);
      // setOpenTeams(true);
      setOpenAddRoom(false);
      setOpen(false);
      clearAll();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        open={openAddRoom}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleAddRoomClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right not "
        maxWidth="sm"
        sx={{ maxHeight: '550px', mt:15, ml: 2}}
      >
      <Box>
          <DialogTitle 
        className='dialog-box-right-addTask -team-container-white-box'
         > 
        {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
          <Box
            className="dialog-text "
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography className="text-primary">ADD ROOM</Typography>
            {/* cross icon */}
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => handleAddRoomClose('Close')}
            >
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
      </Box>
        <DialogContent className='-team-container-white-box'>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription"
          >
            <TextField
              fullWidth
              name="Room name"
              autocomplete="Room name"
              label="ROOM NAME"
              placeholder="Room name"
              defaultValue={roomName}
              onChange={(e) => roomNameSet(e.target.value)}
              className="input-field-styling field-top-margin-edit-location location-field required-field"
              //   error={Boolean(teamNameError)}
              helperText={roomNameError}
              InputLabelProps={{
                className: 'label-style-create-franchisee required-label'
              }}
              InputProps={{}}
            />

            <TextField
              fullWidth
              multiline
              id="my-textarea"
              name="Description"
              autocomplete="Description"
              label="DESCRIPTION"
              placeholder="Notes here . . . "
              variant="outlined"
              defaultValue={description}
              className="field-top-margin-edit-location required-field"
              onChange={(e) => descriptionSet(e.target.value)}
              InputLabelProps={{
                className: 'label-style-create-franchisee required-label'
              }}
              helperText={roomDescError}
              InputProps={{ rows: 6 }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="button-style-add-team field-top-margin-edit-location"
              onClick={AddRoom}
            >
              <Typography className="next-button-text">Save</Typography>
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {!isUpdated ? 'Save Changes' : 'Congratulations'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes, Do you want to
                  <Typography className="title-text-sub"> save them?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  The room has been added successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Continue editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddRoom;
