import React, { useState, useEffect } from 'react';
import {
  Container,
  styled,
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';

import { setFullSupplierId } from '../../../utils';
import Loader from '../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SuppliersDetails = ({
  setShowScreen,
  supplierDetails,
  locationId,
  supplierId,
  refreshSupplierData
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // SupplierDetails page style wrapper
  const SupplierDetailsWrapper = styled(Container)(
    ({ theme }) => `margin-top: ${theme.spacing(2)};`
  );

  useEffect(() => {
    console.log(supplierDetails);
  }, []);

  /* Function to close the supplier details page */
  const handleClose = (val) => {
    if (val === 'Cancel') {
      setOpenDeleteDialog(false);
    } else if (val === 'Delete') {
      deleteRecord(locationId, supplierId);
      setShowScreen(1);
    }
  };

  /* Function to open edit supplier page */
  const handleClickEdit = () => {
    setShowScreen(3);
  };

  /* Function to open the delete dialog */
  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseSupplierDetails = () => {
    setShowScreen(1);
  };

  const deleteRecord = (locationId, supplierId) => {
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
    let encodedSupplierId = encodeURIComponent(setFullSupplierId(supplierId));

    // console.log(encodedSupplierId)

    fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}/suppliers/deleteSupplier?supplierId=${encodedSupplierId}&locationId=${encodedLocationId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          refreshSupplierData();
          setIsLoading(false);
        }
      });
  };
  return (
    <>
      <SupplierDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        <Loader IsLoading={isLoading} />
        <Grid
          className="location-header-container"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleCloseSupplierDetails()}
                className="back-arrow-close"
              >
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Typography className="header-blue-32">
                {supplierDetails.supplierName}
              </Typography>
            </Box>
          </Grid>
          {/* Delete icon and edit button */}
          <Grid xs={4} sm={4} md={4} lg={4} className="align-right">
            {/* remove icon */}

            {/* Edit Button */}
            <Button
              className="details-remove-icon no-hover"
              onClick={handleClickOpenDeleteDialog}
            >
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                  fill="#8098C3"
                />
              </svg>
            </Button>
            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              onClick={handleClickEdit}
              className="btn-primary edit-button common-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Box className="location-details-container">
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">Supplier Contact</Box>
              <Box className="location-details-value">
                {supplierDetails.phone}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">EMAIL</Box>
              <Box className="location-details-value">
                {supplierDetails.email}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">Supplier Phone</Box>
              <Box className="location-details-value">
                {supplierDetails.phone}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">Supplier Company URL</Box>
              <Box className="location-details-value">
                {supplierDetails.websiteURL}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">Account Number</Box>
              <Box className="location-details-value">
                {supplierDetails.accountNumber}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">ADDRESS LINE 1</Box>
              <Box className="location-details-value">
                {supplierDetails.addressLine1}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">Notes</Box>
              <Box className="location-details-value">
                {supplierDetails.notes}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label">Status</Box>
              <Box className="location-details-value">
                {supplierDetails.status === 1 ? 'Active' : 'Inactive'}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </SupplierDetailsWrapper>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Delete'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to delete this record?
                <Typography className="title-text-sub">
                  It will send the record to inactive state.
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Delete')}
          >
            Delete
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleClose('Cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SuppliersDetails;
