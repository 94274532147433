import { Box } from '@mui/material';
import AlertNotifications from './AlertNotifications';

const RightSidebar = () => {
  
  return (
    <>
      {/* Right isdebar */}
      <Box display="block" className='fixed sidebar-right align-center'>
        <AlertNotifications />
      </Box>
    </>
  );
};

export default RightSidebar;
