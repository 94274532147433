import React,{forwardRef}  from 'react';
import {
    Box,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider, Slide,
    Typography
} from "@mui/material";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const DynamicPopup=({handleDialog,open,created})=>{


    return(
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleDialog('Cancel')}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="alert-title align-center">
                    {created? 'Congratulations!' : 'Save Changes'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className="alert-decription align-center"
                    >
                        <Box display="flex" justifyContent="center" direction="column">
                            {!created ? (
                                <Typography className="title-text-sub">
                                    You have unsaved changes, Do you want to
                                    <Typography> save them?</Typography>
                                </Typography>
                            ) : (
                                <Typography className="title-text-sub">
                                    Service has been created successfully!
                                </Typography>
                            )}
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions className="alert-button-control">
                    <Button
                        className="font-17 text-black no-hover"
                        onClick={() => handleDialog('Discard')}
                    >
                        {created? 'Close' : 'Discard'}
                    </Button>
                    {!created && <Divider className="vertical-divider"/>}
                    {!created && (
                        <Button
                            className="font-17 no-hover"
                            onClick={() => handleDialog('Continue')}
                        >
                            Continue
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}