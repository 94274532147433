

import { publicRoutes } from './publicRoutes';
import { privateRoutes } from './privateRoutes';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import Status404 from '../content/pages/Status/Status404';
 

const routes = [
  {
    path: '',
    element: <BaseLayout />,
    children: [...publicRoutes]
  },
  {
    path: '/',
    element: <SidebarLayout />,
    children: [...privateRoutes]
  },
  {
    path: '*',
    element: <Status404 />,
  }
];

export default routes;
