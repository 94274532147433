import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const ImageSlider = ({ images, hideNavigator }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    setCurrentIndex(0);
  }, [images]);

  return (
    <div
      className="image-slider"
      style={{ height: '12rem', overflow: 'hidden' }}
    >
      <img
        src={images[currentIndex]}
        alt="Slide"
        className="slider-image"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
      />
      {!hideNavigator ?
        <><Box
          onClick={goToPrevSlide}
          sx={{
            position: 'absolute',
            top: '100px',
            left: '10px',
            cursor: 'pointer'
          }}
        >
          <img
            src="/static/images/logo/prev-icon.png"
            alt="Previous"
            width="35px"
          />
        </Box>
          <Box
            onClick={goToNextSlide}
            sx={{
              position: 'absolute',
              top: '100px',
              right: '10px',
              cursor: 'pointer'
            }}
          >
            <img src="/static/images/logo/next-icon.png" alt="Next" width="35px" />
          </Box>
        </> : null}
    </div>
  );
};

// export default ImageSlider;
