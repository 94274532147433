import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
  FormHelperText,
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  Slide
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import { makeStyles } from '@material-ui/core/styles';
import { formatPhoneNumber } from 'src/utils/index';
import { ApiService } from '../../../services/ApiService';
import heic2any from 'heic2any';
import { getLocalData } from 'src/utils';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CreateUser = ({ setShowScreen }) => {
  // create a custom theme with updated hover and close icon colors

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();
  const [isFieldUpdated, setisFieldUpdated] = useState(false);
  const [open, setOpen] = React.useState(false);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [workPhoneNumber, setWorkPhoneNumber] = useState('');
  const [role, setRole] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roles, setRoles] = useState('');
  const [gender, setgender] = useState('');
  const [teamId, setTeamId] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setpostalCode] = useState('');
  const [profileimageError, setProfileImage] = useState(false);
  const [profileImageError, setProfileImageError] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imgObj, setImageObj] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  // Profile image error text

  const [statesList, SetStatesList] = useState([]);
  // const [cityList, SetCityList] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  // const[roleName,setroleName]=useState('');

  // const [firstNameError, setFirstNameError] = useState(false);
  // const [lastNameError, setLastNameError] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  // const [phoneNumberError, setPhoneNumberError] = useState(false);
  // const [workPhoneNumberError, setWorkPhoneNumberError] = useState(false);
  // const [roleError, setRoleIdError] = useState(false);
  // const [teamError, setTeamError] = useState(false);
  // const [locationError, setLocationError] = useState(false);
  // const [addressLine1Error, setAddressLine1Error] = useState(false);
  // // const [addressLine2Error, setAddressLine2Error] = useState(false);
  // const [stateError, setStateError] = useState(false);
  // const [cityError, setCityError] = useState(false);
  // const [postalCodeError, setpostalCodeError] = useState(false);

  const [firstNameHelperText, setFirstNameHelperText] = useState('');
  const [lastNameHelperText, setLastNameHelperText] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState(false);
  const [workPhoneNumberHelperText, setWorkPhoneNumberHelperText] =
    useState(false);
  const [roleHelperText, setRoleIdHelperText] = useState(false);
  const [genderHelperText, setgenderHelperText] = useState(false);
  const [teamHelperText, setTeamHelperText] = useState(false);
  // const [locationHelperText, setLocationHelperText] = useState(false);
  const [addressLine1HelperText, setAddressLine1HelperText] = useState(false);
  // const [addressLine2HelperText, setAddressLine2HelperText] = useState(false);
  const [stateHelperText, setStateHelperText] = useState(false);
  const [cityHelperText, setCityHelperText] = useState(false);
  const [postalCodeHelperText, setpostalCodeHelperText] = useState(false);
  const [userTeams, setUserTeams] = useState([]);
  //  const [locationList, setLocationList] = useState([]);
  // Set the final error message
  const [finalApiErrMsg, setFinalApiErrMsg] = useState('');
  const [finalApiErr, setFinalApiErr] = useState(false);
  console.log(teamHelperText);
  useEffect(() => {
    //  getLocationsLists();
    getStates();
    getUserRoles();
    getTeams();
  }, []);

  console.log('workPhoneNumber', workPhoneNumber, addressLine2);

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    workPhoneNumber: '',
    role: '',
    team: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    postalCode: '',
    imgObj: ''
  };

  let userId = getLocalData('userId');
  const [formValues, setFormValues] = useState(initialValues);

  /* Function binded on close button of create-franchisee/user form dialog */
  const handleClose = (value) => {
    // setOpen(true);
    if (value === 'Discard') {
      // setopenDialog(false);
      setOpen(false);
      setisFieldUpdated(false);
      setShowScreen(0);
      clearFields();
      // showForm(false);
      // clear();
    } else if (value === 'Close') {
      setOpen(false);
      setShowScreen(0);
    } else if (value === 'Continue') {
      // setopenDialog(false);
      setOpen(false);

      // showForm(true);
    }
    // closeAddUser();
  };

  // Method to open the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  const clearFields = () => {
    // eslint-disable-next-line no-debugger

    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setWorkPhoneNumber('');
    setRoleId('');
    setgender('');
    setAddressLine1('');
    setAddressLine2('');
    setpostalCode('');
    setImageUrl('');
    setTeamId('');
    setState('');
    setCity('');
    setFirstNameHelperText('');
    setLastNameHelperText('');
    setEmailHelperText('');
    setPhoneNumberHelperText('');
    setWorkPhoneNumberHelperText('');
    setRoleIdHelperText('');
    setgenderHelperText('');
    setTeamHelperText('');
    setAddressLine1HelperText('');
    setpostalCodeHelperText('');
    setCityHelperText('');
    setStateHelperText('');
    setisFieldUpdated(false);
    // setFinalApiErr(false);
  };

  const checkEmailExist = (email) => {
    // check the email is valid or not
    // const validateEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let emailid = email.value.toLowerCase();
    // Now check the email is already exist or not
    ApiService.get(`${process.env.REACT_APP_API_END_POINT}users/checkEmailExists?email=${emailid}`)
      .then((res) => res)
      .then((res) => {
        if (res.statusCode === 200) {
          // Now check the response is true or false
          console.log(res);
          let emailExist = res.isExists;
          let msg = res.message;
          if (res && emailExist) {
            if (msg === "Email already exists in Cognito") {
              setEmailHelperText("Email already exist");
            } else {
              setEmailHelperText(msg);
            }
            // Now email is already exist

          } else {
            // Now email is not exist
          }
        } else {
          // Now email is not exist
        }
      })
      .catch((err) => {
        console.log(err);
        // Now email is not exist
      });
  };


  const handleInputChange = (e) => {
    // eslint-disable-next-line no-debugger

    const { name, value } = e.target;
    if (name === 'firstName') {
      setFirstName(e.target.value);
      // setFirstNameError(false);
      setFirstNameHelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'lastName') {
      setLastName(e.target.value);
      // setLastNameError(false);
      setLastNameHelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'email') {
      setEmail(e.target.value);
      // setEmailError(false);
      setEmailHelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'phoneNumber') {
      // setPhoneNumber(e.target.value);
      // // setPhoneNumberError(false);
      // setPhoneNumberHelperText('');
      // setisFieldUpdated(true);
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      setisFieldUpdated(true);
      if (e && e.target.value) {
        let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
        setPhoneNumber(formatWorkPhoneNumber);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setPhoneNumberHelperText(
            'Please enter a valid phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setPhoneNumberHelperText('');
        }
      } else {
        setPhoneNumberHelperText('Phone number is required');
        setPhoneNumber('');
      }
    }

    if (name === 'workPhoneNumber') {
      // setWorkPhoneNumber(e.target.value);
      // // setWorkPhoneNumberError(false);
      // setWorkPhoneNumberHelperText('');
      // setisFieldUpdated(true);
      const validation = /^\d{3}-\d{3}-\d{4}$/;
      setisFieldUpdated(true);
      if (e && e.target.value) {
        let formatWorkPhoneNumber = formatPhoneNumber(e.target.value);
        setWorkPhoneNumber(formatWorkPhoneNumber);
        if (formatWorkPhoneNumber && !validation.test(formatWorkPhoneNumber)) {
          setWorkPhoneNumberHelperText(
            'Please enter a valid phone number in format: xxx-xxx-xxxx'
          );
        } else {
          setWorkPhoneNumberHelperText('');
        }
      } else {
        setWorkPhoneNumberHelperText('');
        setWorkPhoneNumber('');
      }
    }
    if (name === 'roleId') {
      if (value) {
        console.log(value, 'rolessss');
        const [Id, Name] = value.split('|');
        setRole(value);
        setRoles(Name, Id);
        setRoleId(Id);
        setRoleName(Name);
        setisFieldUpdated(true);
        setRoleIdHelperText('');
      }
    }
    if (name === 'teamId') {
      setTeamId(e.target.value);
      // setTeamError(false);
      setTeamHelperText('');
      setisFieldUpdated(true);
    }
    if (name === 'gender') {
      console.log(e.target.value);
      setgender(e.target.value);
      // setLocationError(false);
      setgenderHelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'addressLine1') {
      setAddressLine1(e.target.value);
      // setAddressLine1Error(false);
      setAddressLine1HelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'addressLine2') {
      setAddressLine2(e.target.value);
      // setAddressLine2Error(false);
      // setAddressLine2HelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'state') {
      // getCityList(e.target.value);
      setState(e.target.value);
      // setStateError(false);
      setStateHelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'city') {
      setCity(e.target.value);
      // setCityError(false);
      setCityHelperText('');
      setisFieldUpdated(true);
    }

    if (name === 'postalCode') {
      const validateZip = /^[0-9]{5}(?:-[0-9]{4})?$/;
      // setpostalCode(e.target.value);
      // setpostalCodeError(false);
      //  setpostalCodeHelperText('');
      //  setisFieldUpdated(true);
      if (!validateZip.test(e.target.value)) {
        setpostalCodeHelperText('Invalid zip code');
        setpostalCode(e.target.value);
        setisFieldUpdated(true);
      } else {
        setpostalCodeHelperText('');
        setpostalCode(e.target.value);
        setisFieldUpdated(true);
      }

    }
    if (name === 'roleId') {
      setFormValues({
        ...formValues,
        roleName: value ? value.role : '',
        roleId: value ? value.userRoleId : ''
      });
      setisFieldUpdated(true);
    } else {
      setFormValues({
        ...formValues,
        [name]: value
      });
    }

  };
  const handleFileInput = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();
      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setisFieldUpdated(true);
        const { name, value } = event.target;
        setImageUrl(URL.createObjectURL(file));
        setImageObj(file);
        setProfileImage(false);
        setFormValues({
          ...formValues,
          [name]: value
        });
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });
          setisFieldUpdated(true);
          const { name, value } = event.target;
          setImageUrl(URL.createObjectURL(jpegFile));
          setImageObj(jpegFile);
          setProfileImage(false);
          setFormValues({
            ...formValues,
            [name]: value
          });
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setProfileImage(true);
          setImageUrl('');
          setImageObj([]);
          setProfileImageError('Error handling HEIC file.');
        }
      } else {
        setProfileImage(true);
        setImageUrl('');
        setImageObj([]);
        setProfileImageError(
          'Please upload only png, jpeg, jpg, or heic image formats'
        );
      }
    }
  };
  console.log('imageUrl:', imageUrl);
  console.log('imgObj:', imgObj);

  console.log(roleName);
  const handleSubmit = (event) => {
    console.log(event);
    event.preventDefault();
    let emailValid = true;
    let phoneValid = true;
    let zipCodeValid = true;
    if (!firstName) {
      // setFirstNameError(true);
      setFirstNameHelperText('First name is required');
    }

    if (!lastName) {
      // setLastNameError(true);
      setLastNameHelperText('Last name is required');
    }

    if (!email) {
      // setEmailError(true);
      setEmailHelperText('Email is required');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      // setEmailError(true);
      emailValid = false;
      setEmailHelperText('Please enter a valid email address');
      setisFieldUpdated(true);
    }

    if (!phoneNumber) {
      // setPhoneNumberError(true);
      setPhoneNumberHelperText('Phone number is required');
    } else if (!/^\d{3}-\d{3}-\d{4}$/.test(phoneNumber)) {
      // setPhoneNumberError(true);
      phoneValid = false;
      setPhoneNumberHelperText(
        'Please enter a valid phone number in format: xxx-xxx-xxxx'
      );
      setisFieldUpdated(true);
    }

    if (workPhoneNumber && !/^\d{3}-\d{3}-\d{4}$/.test(workPhoneNumber)) {
      // setWorkPhoneNumberError(true);
      phoneValid = false;
      setWorkPhoneNumberHelperText(
        'Please enter a valid phone number in format: xxx-xxx-xxxx'
      );
      setisFieldUpdated(true);
    }

    if (!roles) {
      // setRoleIdError(true);
      setRoleIdHelperText('Role is required');
    }
    if (!gender) {
      setgenderHelperText('Gender is required');
    }

    if (!addressLine1) {
      // setAddressLine1Error(true);
      setAddressLine1HelperText('Address Line 1 is required');
    }

    if (!state) {
      // setStateError(true);
      setStateHelperText('State is required');
    }

    if (!city) {
      // setCityError(true);
      setCityHelperText('City is required');
    }

    if (!postalCode) {
      // setpostalCodeError(true);
      setpostalCodeHelperText('Zip Code is required');
    }
    //  else if (postalCode && !/^(0|[1-9]\d*)$/.test(postalCode)) {
    //   // setpostalCodeError(true);
    //   zipCodeValid = false;
    //   setpostalCodeHelperText('Please enter a valid zipCode');
    //   setisFieldUpdated(true);
    // }
    setProfileImageError('');

    // if (imgObj.length === 0) {
    //   setProfileImage(true);
    // }
    if (
      phoneValid &&
      emailValid &&
      zipCodeValid &&
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      phoneNumber !== '' &&
      roleId !== '' &&
      gender !== '' &&
      addressLine1 !== '' &&
      city !== '' &&
      state !== '' &&
      postalCode !== ''
    ) {
      createUser();
    }

    // Do something with form data
  };

  const createUser = async () => {
    setFinalApiErr(false);
    setFinalApiErrMsg('');
    // const body = JSON.stringify(formValues);
    // Now show the loader
    setIsLoading(true);

    let formData = new FormData();
    formData.append('firstName', formValues.firstName);
    formData.append('lastName', formValues.lastName);
    formData.append('email', formValues.email.toLowerCase());
    formData.append('phoneNumber', formValues.phoneNumber);
    // if (formValues.workPhoneNumber)
    formData.append('workPhoneNumber', formValues.workPhoneNumber);
    formData.append('roleId', roleId);
    formData.append('roleName', roleName);
    formData.append('gender', gender);
    formData.append('teamId', formValues.teamId);
    formData.append('addressLine1', formValues.addressLine1);
    formData.append('addressLine2', formValues.addressLine2);
    formData.append('city', formValues.city);
    formData.append('state', formValues.state);
    formData.append('postalCode', formValues.postalCode.toString());
    formData.append('country', 'USA');
    if (imgObj.name) formData.append('profileImage', imgObj);
    formData.append('createdBy', userId);
    // formData.append('isActive', true);
    console.log(formData);

    ApiService.post(`${process.env.REACT_APP_API_END_POINT}users/createUser`, formData)
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Now close the parent modal to trigger the cross icon
          let crossElm = document.getElementsByClassName('user-close')[0];
          console.log(crossElm);

          if (crossElm) crossElm.click();
          // Now show the loader
          setIsLoading(false);
          setIsCreated(true);
          // Show the success dialog
          handleClickOpen();
          // UserAdded();
          // let Item = data.data.Items;
          // SetStatesList(Item);
        } else {
          let errmsg = data.response.message;
          setFinalApiErr(true);
          setFinalApiErrMsg(errmsg);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        let errmsg = error.message;
        setFinalApiErr(true);
        setFinalApiErrMsg(errmsg);
        setIsLoading(false);
      });
  };



  const getStates = async () => {
    ApiService.get(`${process.env.REACT_APP_API_END_POINT}locations/getAllStates`)
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          SetStatesList(Item);
        }
      });
  };

  const getUserRoles = async () => {
    ApiService.get(`${process.env.REACT_APP_API_END_POINT}users/getFranchisorUserRoles`)
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          setUserRoles(Item);
        }
      });
  };


  // Method to get the teams
  const getTeams = async () => {
    // Now show the loader
    // setIsLoading(true);

    ApiService.get(`${process.env.REACT_APP_API_END_POINT}teams`)
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of location
          setUserTeams(data.data.Items);
          // Now hide the loader
          // setIsLoading(false);
        }
      });
  };

  const handleAddUserClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
    }
  };

  return (
    <>
       <Loader IsLoading={isLoading} />
      <Box className="Main-container create-user-form common-form-container">
        {/* FOrm Starts */}
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  onClick={() => handleAddUserClose()}
                  className="back-arrow-close"
                >
                  <svg
                    width="27"
                    height="19"
                    viewBox="0 0 27 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                      fill="#003087"
                    />
                  </svg>
                </Box>

                <Typography className="header-blue-32">Add User</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="FIRST NAME"
                type="text"
                className="input-field-styling required-field"
                value={firstName}
                onChange={handleInputChange}
                // error={firstNameError}
                helperText={firstNameHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="LAST NAME"
                type="text"
                className="input-field-styling required-field"
                value={lastName}
                onChange={handleInputChange}
                // error={lastNameError}
                helperText={lastNameHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="EMAIL"
                placeholder="test@gmail.com"
                className="input-field-styling required-field"
                // error={emailError}
                helperText={emailHelperText}
                value={email}
                onChange={handleInputChange}
                onKeyUp={(e) => checkEmailExist(e.target)}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                label="PHONE NUMBER"
                type="text"
                placeholder="777-777-7777"
                className="input-field-styling required-field"
                onChange={handleInputChange}
                value={phoneNumber}
                // error={phoneNumberError}
                helperText={phoneNumberHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="workPhoneNumber"
                name="workPhoneNumber"
                label="WORK PHONE NUMBER"
                className="input-field-styling"
                type="text"
                placeholder="777-777-7777"
                onChange={handleInputChange}
                value={workPhoneNumber}
                // error={workPhoneNumberError}
                helperText={workPhoneNumberHelperText}
                InputLabelProps={{
                  className: 'form-field-label '
                }}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  GENDER
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={handleInputChange}
                  value={gender}
                  name="gender"
                  label="gender"
                  className="input-field-styling"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                <FormHelperText className="field-error-text">
                  {genderHelperText}
                </FormHelperText>
              </FormControl>
            </Grid>

          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <Autocomplete
                classes={autoCompleteClasses}
                // defaultValue={teamId}
                disabled
                onChange={(event, newValue) => {
                  if (newValue && newValue.teamId) {
                    handleInputChange({
                      target: {
                        name: 'teamId',
                        value: newValue.teamId ? newValue.teamId : ''
                      }
                    });
                  } else {
                    handleInputChange({
                      target: { name: 'teamId', value: '' }
                    });
                  }
                }}
                // error={teamHelperText}
                className="dropdown-field field-label-css status-field-lbl "
                options={userTeams || []}
                getOptionLabel={(option) => option.teamName}
                sx={{
                  width: '100%'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params?.label || 'TEAM'}
                    // error={teamHelperText}
                    className="input-field-styling"
                    // helperText={teamHelperText}
                    variant="outlined"
                    value={teamId}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                      // shrink: 'true'
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  ROLE
                </InputLabel>
                {/* <Select
                  onChange={handleInputChange}
                  id="roleId"
                  name="roleId"
                  label="Role"
                  className="dropdown-field field-label-css"
                  value={roleId}
                  //  error={roleHelperText}
                  // helperText={roleHelperText}
                > */}
                <Select
                  labelId="demo-simple-select-helper-label"
                  className="input-field-styling required-field"
                  id="roleId"
                  name="roleId"
                  label="Role"
                  value={role}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">Select Role</MenuItem>
                  {userRoles?.map((statusOption) => (
                    <MenuItem
                      key={statusOption.role}
                      value={statusOption.userRoleId + '|' + statusOption.role}
                    >
                      {statusOption?.role}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className="error-message">
                  {roleHelperText}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid container xs={4}>
              <Grid
                item
                sx={{
                  marginLeft: '10px'
                }}
              >
                <Avatar sx={{ height: '90px', width: '90px' }} src={imageUrl} />
              </Grid>
              <Grid
                item
                sx={{
                  marginLeft: '10px'
                }}
              >
                <span className="add-profile-text">Upload Profile Image</span>
                <Box>
                  <input
                    multiple
                    type="file"
                    id="select-file"
                    name="profileImage"
                    accept="image/png, image/jpeg, image/jpg"
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileInput(event)}
                  />
                  <label htmlFor="select-file">
                    <Button
                      variant="contained"
                      component="span"
                      className="upload-image"
                    >
                      <Typography className="upload-text">Upload</Typography>
                    </Button>
                  </label>
                </Box>
              </Grid>
              {profileimageError && (
                <FormHelperText className="error-message">
                  {profileImageError}
                </FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="addressLine1"
                name="addressLine1"
                label="ADDRESS LINE 1"
                type="text"
                className="input-field-styling required-field"
                onChange={handleInputChange}
                value={addressLine1}
                // error={addressLine1Error}
                helperText={addressLine1HelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                id="addressLine2"
                name="addressLine2"
                label="ADDRESS LINE 2"
                type="text"
                className="input-field-styling"
                onChange={handleInputChange}
                value={addressLine2}
                InputLabelProps={{
                  className: 'form-field-label'
                }}
                InputProps={{}}
              />
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                value={formValues.state}
                classes={autoCompleteClasses}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: { name: 'state', value: newValue }
                  });
                }}
                className="dropdown-field field-label-css status-field-lbl required-field"
                options={statesList.map((option) => option.state || [])}
                sx={{
                  width: '100%'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params?.label || 'STATE'}
                    // error={stateError}
                    helperText={stateHelperText}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                      // shrink: 'true'
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} columns={12} mt={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="CITY"
                type="text"
                className="input-field-styling required-field"
                onChange={handleInputChange}
                value={formValues.city}
                //  onChange={(event, newValue) => {
                //   handleInputChange({
                //     target: { name: 'city', value: newValue }
                //   });
                // }}
                helperText={cityHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                InputProps={{}}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="postalCode"
                name="postalCode"
                label="ZIP CODE"
                type="text"
                className="input-field-styling required-field"
                onChange={handleInputChange}
                value={postalCode}
                // error={postalCodeError}
                helperText={postalCodeHelperText}
                InputLabelProps={{
                  className: 'form-field-label required-label'
                }}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px'
            }}
          >
            <Button type="submit" className="next-button pr-0 no-hover">
              <Typography className="next-button-text">Save</Typography>
            </Button>
          </Box>
        </form>
        {finalApiErr && (
          <FormHelperText className="Mui-error final-error">
            {finalApiErrMsg}
          </FormHelperText>
        )}
        {/* FOrm End */}
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub"> What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  User has been added successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUser;
