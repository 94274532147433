import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Typography,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  Slide,
  DialogActions,
  Divider,
  FormHelperText
  // FormLabel,
  // Card,
  // CardContent,
  //  FormHelperText,
  // DialogActions,
  //  Divider,
} from '@mui/material';

import './AddTask.css';
import { getLocalData } from '../../../../utils';
import Loader from '../../../../components/Loader';
import { CustomDatePicker } from 'src/components/CustomDatePicker';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddTask({
  showForm,
  openAddTaskDialog,
  setopenAddTaskDialog,
  setOpenAddTask,
  refreshTaskData,
  setTaskApiUpdate,
  taskApiUpdate
}) {
  // const [openAddTaskDialog, setopenAddTaskDialog] = useState(false);
  const [radioButtonValue, setradioButtonValue] = useState('Self');
  const [taskDetails, setTaskDetails] = useState('');
  const [task, setTask] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [time, setTime] = useState('');
  /* States  to set the selected staff memebers value */
  const [selectStaff, setStaffMembers] = useState([]);
  /* States to show the staff members name in select drop down */
  const [staffDetails, setStaffDetails] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [staffError, setStaffError] = useState('');
  /* State to show select staff drop down */
  const [selectStaffdisable, setselectStaffdisable] = useState(false);

  /* State to open/close confirmation dialog box */
  const [openConfirm, setOpen] = useState(false);

  /* State for checking the state of field update */
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);

  /* State to check whether the task  is added  */
  const [isUpdated, setIsUpdated] = useState(false);

  /* States for error messages */

  const [taskDetailError, setTaskDetailError] = useState('');
  const [taskError, setTaskError] = useState('');
  const [dueDateError, setDueDateError] = useState('');
  const [timeError, setTimeError] = useState('');

  useEffect(() => {
    // setopenAddTaskDialog(true);
    setOpen(false);
    getStaff();
    setIsUpdated(false);
  }, []);

  /* Variable getting user id from local storage */
  let userId = getLocalData('userId');
  let userName = getLocalData('UserName')
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  let encodedLocationId = encodeURIComponent(locationId);


  let getStaffUrl = `${process.env.REACT_APP_API_END_POINT}staff?limit=1000&locationId=${encodedLocationId}`;

  if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
    getStaffUrl = `${process.env.REACT_APP_API_END_POINT}staff?limit=1000&locationId=${encodedLocationId}`;
    console.log(encodedLocationId, 'callocationid');

  } else {
    getStaffUrl = `${process.env.REACT_APP_API_END_POINT}staff?limit=1000&locationId=${encodedLocationId}`;
  }

  /* Function to get staff members */

  const getStaff = async () => {
    fetch(getStaffUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.statusCode && !data.error) {
          setStaffDetails(data.data.Items);
        }
      });
  };

  /* Function to add task  */

  const taskAddition = () => {
    setLoading(true);
    const taskData = JSON.stringify({
      assignedBy: userId,
      assignType: radioButtonValue,
      assignedTo:
        radioButtonValue === 'Self'
          ? [
              { id: userId,
                name: userName
              }
            ]
          : radioButtonValue === 'All'
            ? []
            : selectStaff,
      description: taskDetails,
      dueDate: dueDate,
      dueTime: time,
      locationId: locationId,
      createdBy: userId,
      title: task,
      isCompleted: false
    });
    fetch(`${process.env.REACT_APP_API_END_POINT}staff/addTask`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: taskData
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          setIsUpdated(true);
          setOpen(true);
          setLoading(false);
          setopenAddTaskDialog(false);
          refreshTaskData();
        } else {
          setIsUpdated(false);
          setOpen(false);
          setLoading(false);
        }
      });
  };

  /* Function to close the main dialog box */
  const handleClose = () => {
    if (isFieldUpdated) {
      setopenAddTaskDialog(true);
      setOpen(true);
      showForm(true);
      setTaskApiUpdate(!taskApiUpdate)
      clearFields();
    } else {
      setOpen(false);
      setopenAddTaskDialog(false);
      setOpenAddTask(false);
      showForm(false);
    }
    // setopenAddTask(false);
  };

  /* Function to close the confirmation dialog box */

  const handleConfirmClose = (e) => {
    if (e === 'Discard') {
      setOpen(false);
      setopenAddTaskDialog(false);
      setIsFieldUpdated(false);
      setOpenAddTask(false);
      clearFields();
    } else if (e === 'Save') {
      setOpenAddTask(true);
      setOpen(false);
      setopenAddTaskDialog(true);
    }
    // setOpen(false)
  };

  /* Function to clear all fields */

  const clearFields = () => {
    setradioButtonValue('Self');
    setTaskDetails('');
    setTask('');
    setDueDate('');
    setTime('');
    selectStaff.length = 0;
    setTaskDetailError('');
    setTaskError('');
  };

  /* Function to set staff members selected */

  const StaffMembers = (val) => {
    console.log(val, "seasdfasfdsf")
    setIsFieldUpdated(true);
    setStaffMembers(val);
  };

  console.log(selectStaff, "select staff is consoled here")

  /* Function to set task details  */
  const Tasks = (val) => {
    if (!val) {
      setTask('');
      setTaskError('Task is required');
    } else {
      setIsFieldUpdated(true);
      setTask(val);
      setTaskError('');
    }
  };

  /* Function to set task details  */
  const TaskDetails = (val) => {
    if (!val) {
      setTaskDetails('');
      setTaskDetailError('Task details is required');
    } else {
      setIsFieldUpdated(true);
      setTaskDetailError('');
      setTaskDetails(val);
    }
  };

  /* Function to set due date */
  const DueDate = (date) => {
    if (date === '01-01-1970') {
      return;
    }
    // let newDate;
    // let objectDate = new Date(val);
    // // setStartdateObj(objectDate);
    // let date = objectDate.getDate();
    // if (date < 10) {
    //   newDate = '0' + date;
    // } else {
    //   newDate = date;
    // }
    // let month = objectDate.getMonth() + 1;
    // // let newMonth = "0" + month;
    // let newMonth;
    // newMonth = month < 10 ? '0' + month : month;
    // let year = objectDate.getFullYear();
    // let finalDate = newMonth + '-' + newDate + '-' + year;
    setDueDateError('');
    setIsFieldUpdated(true);
    setDueDate(date);
  };

  /* Function set time */

  const SetTime = (e) => {
    let hourValue;
    let newTime;
    const timeValue = e;
    const timeArray = timeValue.split(':');
    if (timeArray[0] > 12) {
      hourValue = timeArray[0] - 12;
      if (hourValue < 10) {
        newTime = '0' + hourValue + ':' + timeArray[1] + ' PM';
      } else {
        newTime = hourValue + ':' + timeArray[1] + ' PM';
      }

      console.log(newTime);
      setTime(newTime);
    } else {
      newTime = timeArray[0] + ':' + timeArray[1] + ' AM';
      console.log(newTime);
      setTime(newTime);
    }
    setTimeError('');
    setIsFieldUpdated(true);
  };

  /* Function to set the assign to */

  const setAssignTo = (e) => {
    if (e === 'Some Individuals') {
      setselectStaffdisable(true);
      setradioButtonValue(e);
      setIsFieldUpdated(true);
    } else {
      setselectStaffdisable(false);
      setradioButtonValue(e);
      setIsFieldUpdated(true);
      setStaffError('');
    }
  };

  /* Function to add task */
  const AddTask = () => {
    if (!task) {
      setTaskError('Task  is required');
    }
    if (radioButtonValue === 'Some Individuals' && selectStaff.length === 0) {
      setStaffError('Please select atleast one staff');
    }
    if (!taskDetails) {
      setTaskDetailError('Task details is required');
    }
    if (!dueDate) {
      setDueDateError('Due date is required');
    }
    if (!time) {
      setTimeError('Time is required');
    } else {
      setTaskApiUpdate(!taskApiUpdate)
      taskAddition();
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Dialog
        open={openAddTaskDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box-right-addTask"
        maxWidth="sm"
      >
        <DialogTitle
          className="alert-title align-right"
          sx={{ backgroundColor: '#FFFFFF ! important' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className="header-black-20">ASSIGN TASK</Typography>
            {/* remove icon */}
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => handleClose('Close')}
            >
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#FFFFFF ! important' }}>
          <Typography sx={{ marginTop: '5px' }}>Assign to</Typography>

          <FormControl className=" radio-button-text">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={radioButtonValue}
              name="radio-buttons-group"
              onChange={(e) => setAssignTo(e.target.value)}
            >
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel
                  className="radio-button-style"
                  value="All"
                  control={<Radio />}
                  label="All"
                />
                <FormControlLabel
                  className="radio-button-style"
                  value="Self"
                  control={<Radio />}
                  label="Self"
                />
                <FormControlLabel
                  className="radio-button-style"
                  value="Some Individuals"
                  control={<Radio />}
                  label="Some Individuals"
                />
              </Box>
            </RadioGroup>
          </FormControl>

          <Box sx={{ marginTop: '18px' }}>
            <Grid container spacing={2} className="grid-container">
              <Grid item xs={12} sm={12}>
                {selectStaffdisable ? (
                  <>
                    <Autocomplete
                      multiple
                      className="select-staff required-field"
                      options={staffDetails}
                      getOptionLabel={(option) =>( option.firstName + " " + option.lastName)}
                      onChange={(event, selectedOptions) => {
                        const selectedStaff = selectedOptions.map((option) => {
                          return {
                            id: option.sortKey,
                            name: `${option?.firstName} ${option?.lastName}`
                          };
                        });
                        StaffMembers(selectedStaff);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={params?.label || 'SELECT STAFF'}
                          className="field-top-margin-edit-location select-field "
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee select-staff required-label '
                          }}
                        />
                      )}
                    />
                    <FormHelperText>{staffError}</FormHelperText>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  multiline
                  id="my-text"
                  name="TITLE"
                  label="TASK"
                  value={task}
                  onChange={(e) => Tasks(e.target.value)}
                  error={Boolean(taskError)}
                  helperText={taskError}
                  placeholder="Type here . . . "
                  variant="outlined"
                  className="field-top-margin-edit-location required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label '
                  }}
                  InputProps={{ rows: 2 }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  multiline
                  id="my-textarea"
                  name="WRITE TASK DETAIL"
                  label="WRITE TASK DETAIL"
                  value={taskDetails}
                  onChange={(e) => TaskDetails(e.target.value)}
                  error={Boolean(taskDetailError)}
                  helperText={taskDetailError}
                  placeholder="Type here . . . "
                  variant="outlined"
                  className="field-top-margin-edit-location required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label '
                  }}
                  InputProps={{ rows: 6 }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="form-datepicker-container">
                {/* <TextField
                  fullWidth
                  name="Due date"
                  autocomplete="Due date"
                  label="DUE DATE"
                  type="date"
                  sx={{ marginTop: '10px' }}
                  defaultValue={dueDate}
                  onChange={(e) => DueDate(e.target.value)}
                  error={Boolean(dueDateError)}
                  helperText={dueDateError}
                  className="input-field-styling"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee',
                    shrink: true
                  }}
                /> */}
                <CustomDatePicker
                  label="DUE DATE"
                  fieldValue={dueDate}
                  onChange={DueDate}
                  error={Boolean(dueDateError)}
                  required={Boolean(true)}
                />
                {dueDateError && (
                  <FormHelperText>Due Date is required</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="Time"
                  autocomplete="Time"
                  label="TIME"
                  type="time"
                  defaultValue={time}
                  onChange={(e) => SetTime(e.target.value)}
                  error={Boolean(timeError)}
                  helperText={timeError}
                  className="input-field-styling required-field"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee required-label',
                    shrink: true
                  }}
                />
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="button-style-add-task field-top-margin-edit-location"
                onClick={AddTask}
                sx={{ marginTop: '20px', marginLeft: '18px' }}
              >
                Assign Task
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Confirmation dialog */}
      <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmClose('Discard')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {!isUpdated ? 'Discard Changes?' : 'Congratulations'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Task has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleConfirmClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddTask;
