/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const BusinessEntitiesFilter = ({
  setFilters,
  filters,
  allCategories,
  allLevels
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    category: false,
    level: false,
    type: false,
    free: false,
    cost: false
  });

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      category: field === 'category' ? !prevState.category : false,
      level: field === 'level' ? !prevState.level : false,
      type: field === 'type' ? !prevState.type : false,
      free: field === 'free' ? !prevState.free : false,
      cost: field === 'cost' ? !prevState.cost : false
    }));
  };

  const handleChangeFilter = (value, type) => {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      businessEntityType: [],
      typeOfService: null,
      difficultyLevel: [],
      isFree: null,
      cost: null
    });

    setOpenFilter({
      category: false,
      level: false,
      type: false,
      free: false,
      cost: false
    });
  };

  return (
    <>
      <Card className="filter-content">
        {/* custom-filter-fields */}
        {/* <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Type</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) =>
              // handleChangeFilter(event.target.value, 'isActive')
              handleChangeFilter(event.target.value, 'status')
            }
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={null}>None</MenuItem> */}
        {/* <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem> */}
        {/* <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl> */}

        {/* <Divider /> */}

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text filter-text-title"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text cursor-pointer"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* isFree Filter */}
        {/* <Grid
          xs={12}
          onClick={() => handleOpenFilter('free')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.isFree && classes.closeY,
                    openFilter.isFree && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Is Free</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}

        <Grid
          xs={12}
          onClick={() => handleOpenFilter('category')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.category && classes.closeY,
                    openFilter.category && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Business Entity Type</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.category && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Business Entity Type</InputLabel>
              <Select
                // multiple
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'businessEntityType')
                }
                value={filters.businessEntityType}
                sx={{
                  width: '100%'
                  // fontSize : '17px'
                }}
                label="Business Entity Type"
                className="status-field field-label-css"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                <MenuItem value={-1}>All</MenuItem>
                {allCategories.length > 0 &&
                  allCategories.map((item) => (
                    <MenuItem id={item.sortKey} value={item.businessEntityType}>
                      {item.businessEntityType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};
