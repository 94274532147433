import { ApiService } from 'src/services/ApiService';
 


export const franchisorApiRequest = async (
    url,
    setFranchisorWidgetdata
  
  ) => {
    try {
      const response = await ApiService.post(url);
   
      if (response.statusCode && !response.error) {
        setFranchisorWidgetdata(response?.data)
      }
  
  
    } catch (error) {
     console.log(error);
    }
  };