import React, { useState, useEffect, useImperativeHandle } from 'react';
import {
  Box,
  Grid,
  // Button,
  TextField,
  Card,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  IconButton,
  useTheme,
  Container,
  CircularProgress
} from '@mui/material';
// import { Helmet } from 'react-helmet-async';
// import { CustomButton } from 'src/components/Global';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import SearchIcon from '@mui/icons-material/Search';
import AddSupplier from './AddSupplier';
import EditSupplier from './EditSupplier';
import SupplierDetails from './SupplierDetails';
import { getLocalData, setFullLocationId, setFullSupplierId } from '../../../utils';
import Loader from '../../../components/Loader';
import Filter from './Filter';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../../components/Header';
import clsx from 'clsx';
// import GlobalData from 'src/global';
import { CustomButton } from 'src/components/Global';
// import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
// import { useNavigate } from 'react-router-dom';

const ManageSuppliers = ({ ref }) => {
  const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showScreen, setShowScreen] = useState(1);
  const locationId = setFullLocationId(getLocalData('locationId'));
  // const supplierId=setFullSupplierId(getLocalData('supplierId'));
  const [supplierList, setSupplierList] = useState([]);

  const [supplierDetails, setSupplierDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [editSupplierDetails, setEditSupplierDetails] = useState([]);
  const [isFilters, setIsFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [supplierId, setSupplierId] = useState('')

  const [filters, setFilters] = useState({
    productCount: null,
    locationId: null,
    status: null
  });

  useEffect(() => {
    getSupplier();
  }, [JSON.stringify(filters)]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const refreshSupplierData = () => {
    getSupplier();
  };

  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshTaskData: () => {
      // Not call the get franchisee method again
      getSupplier();
    }
  }));

  // const handleClickOpen = () => {
  //   setShowScreen(2);
  // };

  function isAnyFilterApplied() {
    return Object.values(filters).some((value) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    });
  }
  const handleEditSupplier = (e, supplierData) => {
    e.preventDefault();
    e.stopPropagation();
    setEditSupplierDetails(supplierData);
    setShowScreen(3);
  };
  const handleViewSupplier = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    getSupplierDetailsById(data.supplierId, data.locationId);
    setSupplierId(data.supplierId)
    setShowScreen(4);
  };



  /* Function to retrieve the supplier details by supplier,location ID */
  const getSupplierDetailsById = (supplierId, locationID) => {
    // setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationID);
    let encodedSupplierId = encodeURIComponent(setFullSupplierId(supplierId));
    fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT
      }suppliers/getSupplierById?supplierId=${encodedSupplierId}&locationId=${encodedLocationId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          //   setIsLoading(false);
          setLoading(false);
          setSupplierDetails(data.data.Item);
          setEditSupplierDetails(data.data.Item);
        }
      });
  };

  const showFilter = () => {
    // if (franchiseeList.length === 0) getFranchisee();
    if (locationList.length === 0) getLocations();
    setIsFilters(!isFilters);
  };

  // Get locations method to get the list of location to display in the filter
  const getLocations = async () => {
    // Now show the loader

    let url;
    let encodedLocationId = encodeURIComponent(locationId);

    // if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
    //   url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500&locationId=${locationId}`;
    // } else if (
    //   isFranchisor === 'false' &&
    //   isPrimaryOwner === 'false' &&
    //   locationId
    // ) {
    //   url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500&locationId=${locationId}`;
    // } else {
    //   url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500`;
    // }
    url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500&locationId=${encodedLocationId}`;
    // fetch(`${process.env.REACT_APP_API_END_POINT}locations?limit=500`, {
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of location
          setLocationList(data.data.Items);
          // Now hide the loader
        }
      });
  };

  /* Pagination functions  */
  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = supplierList.filter((item) => {
    return Object.values(item).some((value) =>
      value ? value.toString().toLowerCase().includes(searchText) : ''
    );
  });


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedSupplierLists = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  /* Pagination functions end */
 
  function filtersQuery() {
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    return queryString;
  }

  /* Function to get list of suppliers on the basis of location ID */
  const getSupplier = () => {
    setIsLoading(true);
    let queryString = filtersQuery();
    let encodedLocationId = encodeURIComponent(locationId);
    fetch(
      `${process.env.REACT_APP_INVENTRY_API_END_POINT}suppliers/getAllSupplier?locationId=${encodedLocationId}&${queryString}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.statusCode && !data.error) {
          setIsLoading(false);
          if (data.data.Items === undefined) {
            setSupplierList([]);
          } else {
            setSupplierList(data.data.Items);
          }
        }
      });
  };
  const handleButtonClick = () => {
    setShowScreen(2)
  };

  const buttons = [
    {
      label: 'Add New Supplier',
      onClick: handleButtonClick,
      theam: true
    },

  ];
  return (
    <>
      <Container maxWidth="100%" className="custom-container">
        <Loader IsLoading={isLoading} />
        {(() => {
          switch (showScreen) {
            case 2:
              return (
                <AddSupplier
                  locationId={locationId}
                  setShowScreen={setShowScreen}
                  refreshSupplierData={refreshSupplierData}
                />
              );
            case 3:
              return (
                <EditSupplier
                  locationId={locationId}
                  setShowScreen={setShowScreen}
                  editSupplierDetails={editSupplierDetails}
                  refreshSupplierData={refreshSupplierData}
                  supplierDetails={supplierDetails}
                />
              );
            case 4:
              return (
                <SupplierDetails
                  setShowScreen={setShowScreen}
                  supplierDetails={supplierDetails}
                  locationId={locationId}
                  supplierId={supplierId}
                  refreshSupplierData={refreshSupplierData}
                />
              );
            default:
              return (
                <Box justifyContent="center" direction="column">
                  {/* <h1 className="title mb-0 mt-10 primary-color">
                                        Manage Supplier
                                    </h1> */}
                  {showScreen === 1 && <Header title="Manage Supplier" buttons={buttons} />}
                  {/* <Grid container justifyContent="end" alignItems="center"> */}
                  {/* <Button
                      onClick={() => handleClickOpen()}
                      variant="contained"
                      className="common-button"
                    >
                      <Typography className="button-text">
                        Add New Supplier
                      </Typography>
                    </Button>
                  </Grid> */}
                  <Grid>
                    <Grid xs={12}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-end"
                      >
                        <Grid
                          item
                          sx={{
                            // marginTop: '10px',
                            marginBottom: '20px'
                          }}
                        >
                          <CustomButton
                            onClick={showFilter}
                            variant={
                              isAnyFilterApplied()
                                ? 'contained'
                                : isFilters
                                  ? 'contained'
                                  : 'outlined'
                            }
                          >
                            Filter (All)
                            <KeyboardArrowDownRoundedIcon
                              className={clsx(
                                !isFilters && classes.closeY,
                                isFilters && classes.openY
                              )}
                            />
                          </CustomButton>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            marginTop: '20px',
                            marginBottom: '20px'
                          }}
                        >
                          <Box
                            width={280}
                            sx={
                              {
                                // marginRight: '20px'
                              }
                            }
                          >
                            <TextField
                              type="search"
                              label="Search"
                              variant="outlined"
                              className="search-field"
                              onChange={(e) => handleSearchTextChange(e)}
                              //  onClick={handleSearch}
                              fullWidth
                              InputProps={{
                                style: {},
                                endAdornment: (
                                  <SearchIcon className="search-icon" />
                                )
                              }}
                              InputLabelProps={{}} // style: { paddingLeft: '10px' }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      {loading ? (
                        <CircularProgress sx={{ marginTop: '80px' }} />
                      ) : (
                        <>
                          {isFilters ? (
                            <Grid item width={isFilters ? 300 : 0}>
                              <Filter
                                filters={filters}
                                setFilters={setFilters}
                                locationList={locationList}
                              //   data={historyData}
                              />
                            </Grid>
                          ) : null}
                          <Grid
                            item
                            // sx={{ width: '100%' }}
                            width={`calc(100% - ${isFilters ? 300 : 0}px)})`}
                          // sx={{
                          //     width: `calc(100% - ${getFilterSize()}px)})`
                          // }}
                          >
                            <Card
                              sx={{
                                padding: '15px',
                                borderRadius: '19px 19px 0px 0px'
                              }}
                            >
                              <CardHeader
                                // action={
                                // }
                                title="All Suppliers"
                                className="table-header-name"
                                sx={{ fontSize: '20px', color: '#003087' }}
                              />
                              <TableContainer className="manage-supplier-table-container table-container ">
                                <Table>
                                  <TableHead>
                                    <TableRow className="table-header">
                                      <TableCell>Supplier Name</TableCell>
                                      <TableCell>Contact Info</TableCell>
                                      <TableCell>Payment Term</TableCell>
                                      <TableCell>Product Provided</TableCell>
                                      <TableCell>Delivery Terms</TableCell>
                                      <TableCell />
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {paginatedSupplierLists.map(
                                      (supplierItem) => {
                                        return (
                                          <TableRow
                                            hover
                                            className="table-row cursor-pointer"
                                            onClick={(e) =>
                                              handleViewSupplier(
                                                e,
                                                supplierItem
                                              )
                                            }
                                          >
                                            <TableCell className="table-td">
                                              <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                              >
                                                {supplierItem.supplierName}
                                              </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                              <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                              >
                                                {supplierItem.phone}
                                              </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                              <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                              >
                                                Payment Method
                                              </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                              <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                              >
                                                {supplierItem.productCount}
                                              </Typography>
                                            </TableCell>
                                            <TableCell className="table-td">
                                              <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                              >
                                                $778.35
                                              </Typography>
                                            </TableCell>

                                            <TableCell className="table-td">
                                              {/* <Tooltip title="Edit Client" arrow> */}
                                              <IconButton
                                                sx={{
                                                  '&:hover': {
                                                    background:
                                                      theme.colors.primary
                                                        .lighter
                                                  },
                                                  color:
                                                    theme.palette.primary.main
                                                }}
                                                color="inherit"
                                                size="small"
                                                className="edit-btn"
                                                // id={'edit_btn_' + clientItem.clientId}
                                                // onClick={(e) => handleEditClient(clientItem, e)}
                                                onClick={(e) =>
                                                  handleEditSupplier(
                                                    e,
                                                    supplierItem
                                                  )
                                                }
                                              >
                                                Edit
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <Box p={2}>
                                {/* Pagination */}
                                <TablePagination
                                  rowsPerPageOptions={[5, 10, 20]}
                                  component="div"
                                  count={filteredData.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                /> 
                              </Box>
                            </Card>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
          }
        })()}
      </Container>
      {/* {openAddSupplier && (<AddSupplier open={open} setOpen={setOpen} setOpenAddSupplier={setOpenAddSupplier} />)} */}
    </>
  );
};

export default ManageSuppliers;
