/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Container,
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Card,
  FormControl,
  Select,
  MenuItem,
  TextField,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Avatar
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DeleteIcon } from 'src/assets/SVG/SvgIcons'; // ArrowLeft
import { ArrowLeft, PictureSnapshot } from 'src/assets/SVG/SvgIcons';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ApiService } from '../../../services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { getLocalData } from 'src/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// Trophy page style wrapper
const TrophyDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

export const TrophyDetails = (props) => {
  const { trophyDetails, handleResetStates, handleOpenScreen } = props;

  // const trophyId = trophyDetails?.sortKey && encodeURIComponent(trophyDetails.sortKey);
  const trophyId = trophyDetails?.sortKey && trophyDetails.sortKey;
  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [criteriaCount, setCriteriaCount] = useState();
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputValues, setInputValues] = useState([
    { attribute: "", operator: "", values: [] },
  ])
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [attributeData, setAttributeData] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
console.log(trophyDetails,'trophyDetailsk');
useEffect(() => {
  if (trophyDetails) {
    if (trophyDetails?.Segments) {
      const criteriaData = trophyDetails?.Segments[0]?.DimensionsAttributes;
      setCriteriaCount( trophyDetails?.Segments[0]?.DimensionsAttributes.length)
      if (criteriaData && criteriaData.length > 0) {
        // Initialize inputValues as an array of objects with the correct structure
        const initialInputValues = criteriaData.map(criteria => ({
          attribute: criteria.attribute, // Populate attribute
          operator: criteria.operator,     // Populate operator
          values: criteria.values,
        }));

        // Set inputValues with the correct structure
        setInputValues(initialInputValues);

        // Extract and set selectedOperators
        const extractedOperators = initialInputValues.map(criteria => criteria.operator);
        setSelectedOperators(extractedOperators);
      }
    }
  }
}, []);
console.log(criteriaCount,'jjjjjjj');
  // Get the loggedIn user Id
  const userId = getLocalData('userId');
  const handleDeleteDialog = (type) => {
    if (type === 'cancel') {
      setDeleteDialog(false);
    } else {
      setDeleteDialog(false);
      deleteTrophy();
    }
  };

  // Delete trophy
  const deleteTrophy = async () => {
    let body = {
      trophyId: trophyId,
      userId: userId
    };
    setBtnLoading(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_END_POINT}/trophy/deleteTrophy`,
        { data: body }
      );
      console.log('delete response', response.data);
      if (!response.data?.error) {
        
        setShowSuccessMessage(true);
        setSuccessMessage('Selected trophy has been deactivated successfully!');
        // toast.success(response.data?.message);
       
      } else {
        setDeleteDialog(false);
        setShowSuccessMessage(false);
        setShowMessage(true);
        setErrorMessage(
         'Something went wrong, try later'
        );

        // toast.error(
        //   response.data?.message || 'Something went wrong with delete Service'
        // );
      }

      setBtnLoading(false);
    } catch (error) {
      setDeleteDialog(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later');

      console.error(error);
      setBtnLoading(false);
      toast.error('Something went wrong, Try again!');
    }
  };
  const getAllAttribute = async () => {
    try {
      let response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllCriteria`);

      if (response.statusCode && !response.error) {
        setAttributeData(response?.data?.Items)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getAllOperator = async () => {
    try {
      let response = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}pinpoint/getAllOperators`);

      if (response.statusCode && !response.error) {
        setOperatorData(response?.data?.Items)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const closePopup = () => {
    setShowSuccessMessage(false);
    handleResetStates();
  };
  useEffect(() => {
    getAllOperator()
    getAllAttribute()
  }, [])
  console.log(inputValues,'inputValuesk');
  return (
    <>
     {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
    {showSucessMessage &&(
      <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
           )}
    <div className="custom-summary">
      <TrophyDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleResetStates()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {trophyDetails?.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              disabled={btnLoading}
              className="details-remove-icon no-hover"
              onClick={() => setDeleteDialog(true)}
            >
              <DeleteIcon />
            </Button>

            <Button
              sx={{ mt: { xs: 0, md: 0 } }}
              variant="contained"
              onClick={() => handleOpenScreen(2)}
              className="btn-primary edit-button"
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Box className="location-details-container">
          <Grid container mt={3} >
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">Title</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {trophyDetails?.title}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">Badges</Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value capitalize">
                {trophyDetails?.juniorTrophy ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Assignment Based
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.staffAssigned ? 'Yes' : 'No'}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Description
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.description}
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                Trophy IMAGE
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.trophyImage}
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {trophyDetails.trophyImage ? (
                  <Avatar
                    src={trophyDetails?.trophyImage}
                  />
                ) : (
                  // <PictureSnapshot />
                  <Avatar
                    src={''}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-label summary-heading font-16">
                STATUS
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} className="location-details-col">
              <Box className="location-details-value summary-value">
                {trophyDetails?.status === 1 && 'Active'}
                {trophyDetails?.status === 0 && 'Inactive'}
                {trophyDetails?.status === 2 && 'Deleted'}
              </Box>
            </Grid>
          </Grid>
       
        </Box>
        {!trophyDetails?.staffAssigned&&(
          <Card 
          sx={{
            padding: '15px',
            borderRadius: '19px 19px 0px 0px',
            mt: 5,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            ml={2}

          >
            <Grid item>
              <Typography className="table-heade header-blue-20">
                {' '}
                Criteria
              </Typography>
            </Grid>
          </Grid>
          {Array.from({ length: criteriaCount }, (_, index) => {
            const criteria = inputValues[index];
            console.log(criteria,'trophyDetails')
            return (
              <>
               
                <Grid key={index} container mt={3} ml={2} gap={3}>
                    <Grid item xs={3.8} sm={3.8}>
                      <Typography className="cohorts-detail-header">
                        Attribute
                      </Typography>
                      <FormControl
                        className="top-margin-cohorts"
                        fullWidth
                      >
                        <Select
                          name='attribute'
                          disabled
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="input-field-styling cohort-input-styling"
                          value={criteria.attribute}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } }
                          }}
                        >
                          {attributeData?.map((option) => (
                            <MenuItem value={option.name} key={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>

                      </FormControl>
                    </Grid>

                    <Grid item xs={3.8} sm={3.8}>
                      <Typography className="cohorts-detail-header">
                        Operator
                      </Typography>
                      <FormControl
                        className="top-margin-cohorts"
                        fullWidth
                      >
                        <Select
                        disabled
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          className="input-field-styling cohort-input-styling"
                          name='operator'
                          value={criteria.operator}
                        >
                          {operatorData?.map((option) => (
                            <MenuItem value={option.name} key={option.sortKey}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3.8} sm={3.8}>
                      <Typography className="cohorts-detail-header"> {selectedOperators[index] === 'BETWEEN'? 'Values' : 'Value'} </Typography> 

                      {selectedOperators[index] === 'IS' ||
                        selectedOperators[index] === 'IS NOT' ||
                        selectedOperators[index] === 'CONTAINS' ? (
                        <TextField
                          fullWidth
                          autoComplete="SENDER NAME"
                          label=""
                          name="values"
                          disabled
                          className="location-field input-field-styling top-margin-cohorts"
                          value={criteria?.values}
                          InputLabelProps={{
                            className: 'label-style-create-franchisee edit-address-label'
                          }} />

                      ) : selectedOperators[index] === 'BETWEEN' ? (
                        <Grid container mt={0.2} spacing={2}>
                          <Grid item >
                            <CustomDatePicker
                              name="values"
                              disabled
                              fieldValue={criteria.values[0] ? moment(criteria.values[0]).format("MM-DD-YYYY") : ''} />
                          </Grid>
                          <Grid item >
                            <CustomDatePicker
                              name="values"
                               disabled
                              fieldValue={criteria.values[1] ? moment(criteria.values[1]).format("MM-DD-YYYY") : ''} />
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                        <Grid mt={2}>
                            <CustomDatePicker
                              name="values"
                              disabled
                              // value={formatDate(criteria.values[0])}
                              fieldValue={criteria.values[0] ? moment(criteria.values[0]).format("MM-DD-YYYY") : ''} />
                          </Grid></>
                        // Render a normal text field for other operators
                      )}

                    </Grid>
                  </Grid></>
        
            );
          })}
        </Card>
        )}
      </TrophyDetailsWrapper >

      <Dialog
        open={deleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDeleteDialog('cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDeleteDialog('delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleDeleteDialog('cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div >
    </>
  );
};
