import { Avatar, Grid, Typography } from '@mui/material';
import React from 'react';

const Receivertemplate = ({ data, staffProfileData }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start" // This spreads out the items
      spacing={1}
      wrap="nowrap"
    >
      <Grid item>
        <Avatar
          alt="userImg"
          src={staffProfileData?.profileImage}
          sx={{ width: '33px', height: '33px' }}
        />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            padding: '8.188px 10.918px',
            borderRadius: '16.377px 16.377px 16.377px 0px',
            background: '#EBEAEA',
            color: '#000',
            wordBreak: 'break-word',
            display: 'inline-flex', // Keeps the background size to the content
            alignItems: 'center',
            marginLeft: 'auto' // Aligns the text to the right
          }}
        >
          {data && (data?.message || data?.body)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Receivertemplate;
