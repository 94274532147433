import { Box, Container, styled, Typography } from '@mui/material';

const LogoWithTextWrapper = styled(Container)(
    ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

function LogoWithText() {
    return (
        <LogoWithTextWrapper className="logo-text-wrapper">
            <Box className="logo-image-wrapper logo-text">
                <div className="logo-text">
                    <div className="zoom-room-logo">
                        <img
                            src={"/static/images/logo/zrlogo.png"}
                            style={{ maxWidth: "100%" }}
                            className="logo-image"
                            alt="zoom-room-logo"
                        />
                    </div>
                    <div className="hero-text">
                        <Typography className="hero-message">
                        WE DON'T TRAIN DOGS.
                        </Typography>
                        <Typography className="hero-message">
                            WE TRAIN THE PEOPLE WHO LOVE THEM.
                        </Typography>
                    </div>
                </div>
            </Box>
        </LogoWithTextWrapper>
    );
}

export default LogoWithText;
