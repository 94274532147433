import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import { CustomInput, Filter } from './index';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import ErrorMessage from '../PopupMessage/ErrorMessage';

export const FilterAndSearchComponent = ({
  sideBarFilters,
  setSidebarFilters,
  title,
  dataList,
  columns,
  loading,
  isCheckBox,
  setSelected,
  selected,
  filterOptions,
  handleChangeFilter,
  message,
  tempSelectedItems
  // setTempSelectedItems
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  // console.log(filterOptions, 'filterOptions', isCheckBox, selected);
  const [displayFilterForm, setDisplayFilterForm] = useState(false);
  // const [selectionStart, setSelectionStart] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setisLoading] = useState(true);

  const handleChange = (value) => {
    setSearchQuery(value);
    // let list = [...tableData];
    let list = [...dataList];

    let result = columns?.map((attribute) =>
      list.filter((item) =>
        item[attribute?.field]
          ?.toString()
          ?.toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    const convertedData = [];
    let uniqueData = [];
    result.forEach((item) => {
      if (item.length > 0) {
        convertedData.push(...item);
      }
    });
    convertedData.forEach((item) => {
      if (!uniqueData.includes(item)) {
        uniqueData.push(item);
      }
    });
    if (value !== '') {
      setTableData(uniqueData);
    } else {
      setTableData(dataList);
    }
  };

  useEffect(() => {
    setTableData(dataList);
  }, [dataList]);

  useEffect(() => {
    setisLoading(true);

    setTimeout(() => {
      setisLoading(false);
    }, 100);
  }, [dataList]);

  useEffect(() => {
    setTableData(dataList);
    // setDisplayFilterForm(false);
    // console.log('-----1=-=-=->',selected);
    setSelected({
      ...selected,
      items: [...selected.items],
      values: [...selected.values]
    });
    setSelectedRows(selected?.values);
  }, [dataList]);

  useEffect(() => {
    // console.log('-----=-2=-=->',selected);
    setSelected({
      ...selected,
      items: [...selected.items],
      values: [...selected.values]
    });
    setSelectedRows(selected?.values);
  }, [tableData]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const showFilter = () => {
    setDisplayFilterForm(!displayFilterForm);
    handleChangeFilter();
  };
  // const getRowId = (row) => row?.sortKey;
  const getRowId = (row) => row?.sortKey || row?.itemId;

  // Working code
  // useEffect(() => {
  //   if (selected?.values?.length > 0 && selectedRows?.length === 0) {
  //     setSelectedRows(selected?.values);
  //   }

  // }, [selected?.values, tempSelectedItems?.tempValues]);

  // useEffect(() => {
  //   if (selectedRows?.length > 0) setSelectedRows(selectedRows);
  //   if (selectionStart) {
  //     let myArrayFiltered = filterArray(selected?.items, selectedRows);
  //     setTempSelectedItems({
  //       ...tempSelectedItems,
  //       tempItems: myArrayFiltered,
  //       tempValues: selectedRows,
  //     });
  //   }
  // }, [selectedRows]);

  useEffect(() => {
    if (selected?.values?.length > 0 && selectedRows?.length === 0) {
      setSelectedRows(selected?.values);
    }
  }, [selected?.values, tempSelectedItems?.tempValues]);

  useEffect(() => {
    setSelectedRows(selected?.values);
  }, [selected?.values]);

  // Working code
  /*  useEffect(() => {
     if (selectedRows?.length > 0) setSelectedRows(selectedRows);
     if (selected?.values?.length > 0 && selectedRows?.length === 0) {
       setSelectedRows(selected?.values);
     }
 
     let myArrayFiltered = filterArray(selected?.items, selectedRows);
     let filterPOData = myArrayFiltered.map((item) => {
       return {
         itemId: item?.sortKey, // itemId
         sortKey:item?.sortKey,
         itemName: item?.itemName,
         price: item?.price,
         numberOfSessions: item?.numberOfSessions,
         itemCategoryId: item?.itemCategoryId,
         itemCategoryName: item?.itemCategoryName,
         services: item?.services,
         sellOnline: item?.sellOnline,
         free: item?.free,
         sharable: item?.sharable,
         restricted: item?.restricted,
         validThroughStartDate: item?.validThroughStartDate,
         validThroughEndDate: item?.validThroughEndDate,
         schedulingLimitation: item?.schedulingLimitation,
         setTimeRange: item?.setTimeRange,
         startTime: item?.startTime,
         endTime: item?.endTime,
         creditsValidityStarts: item?.creditsValidityStarts,
         creditsValidityExpires: item?.creditsValidityExpires,
       }
     });
    // console.log('myArrayFiltered',myArrayFiltered);
     console.log('-----=-3=-=->',selected);
     setSelected({
       ...selected,
       items: filterPOData,
       values: selectedRows
     });
     }
   }, [selected?.values, selectedRows]);
 
   function filterArray(array, filter) {
     let myArrayFiltered = [];
     for (let i = 0; i < array.length; i++) {
       for (let j = 0; j < filter.length; j++) {
         if (array[i].sortKey === filter[j]) {
           myArrayFiltered.push(array[i]);
         }
       }
     }
     return myArrayFiltered;
   } */

  const handleCheckBoxChange = (newRowSelectionModel) => {
    if (isLoading) return;

    // setSelectionStart(true);
    const filteredResults = dataList?.filter((item) =>
      newRowSelectionModel?.includes(item.sortKey)
    );
    let updatedArray = filteredResults;
    let itemType = '';
    if (title === 'All Pricing Options') {
      updatedArray = filteredResults.map((item) => {
        return {
          itemType: 'PricingOption',
          sortKey: item.sortKey,
          itemId: item.sortKey,
          itemName: item.name,
          quantity: 1,
          itemCategoryId: item?.categoryId ?? '',
          itemCategoryName: item?.categoryName ?? '',
          discount: item?.discount ?? 0,
          price: item?.price ?? 0,
          creditsValidityStarts: item?.creditsValidityStarts ?? '',
          numberOfSessions: item?.numberOfSessions ?? 0,
          sellOnline: item?.sellOnline ?? false,
          free: item?.free ?? false,
          sharable: item?.sharable ?? false,
          restricted: item?.restricted ?? false,
          validThroughStartDate: item?.validThroughStartDate ?? '',
          validThroughEndDate: item?.validThroughEndDate ?? '',
          services: item?.services ?? [],
          schedulingLimitation: item?.schedulingLimitation ?? {},
          setTimeRange: item?.setTimeRange ?? false,
          startTime: item?.startTime ?? '',
          endTime: item?.endTime ?? '',
          creditsValidityExpires: item?.creditsValidityExpires ?? {}
        };
      });
      itemType = 'PricingOption';
    } else if (title === 'All Contracts') {
      updatedArray = filteredResults.map((item) => {
        return {
          sortKey: item.sortKey,
          itemId: item.sortKey,
          itemName: item.contractName,
          itemType: 'Contract',
          quantity: 1,
          session: 1,
          discount: 0,
          price: item?.price ?? 0
        };
      });
      itemType = 'Contract';
    } else if (title === 'All Memberships') {
      updatedArray = filteredResults.map((item) => {
        return {
          sortKey: item.sortKey,
          itemId: item.sortKey,
          itemName: item.name,
          itemType: 'Membership',
          price: item?.price ?? 0,
          quantity: 1,
          session: 0,
          discount: 0
        };
      });
      itemType = 'Membership';
    } else if (title === 'All Products') {
      updatedArray = filteredResults.map((item) => {
        return {
          itemType: 'Product',
          sortKey: item.sortKey,
          itemId: item.sortKey,
          itemName: item.fName,
          itemCategoryId: item?.categoryId ?? '',
          itemCategoryName: item?.categoryName ?? '',
          quantity: 1,
          session: 0,
          discount: 0,
          price: item?.price ?? 0
        };
      });
      itemType = 'Product';
    } else if (title === 'All Bundles') {
      updatedArray = filteredResults.map((item) => {
        return {
          sortKey: item.sortKey,
          itemId: item.sortKey,
          itemName: item.bundleName,
          itemCategoryId: item?.categoryId ?? '',
          itemCategoryName: item?.categoryName ?? '',
          itemType: 'Bundle',
          price: item?.bundlePrice ?? 0,
          quantity: 1,
          session: 0,
          discount: Number(item.discount || 0)
        };
      });
      itemType = 'Bundle';
    }

    let nonExist = [...selected.items]?.filter((item) => {
      return (
        item.itemType === itemType &&
        !tableData?.some((dataItem) => dataItem?.sortKey === item?.sortKey)
      );
    });

    let filterItems = [...selected.items]?.filter((item) => {
      return item.itemType !== itemType;
    });

    let itemsArr = [...filterItems, ...updatedArray, ...nonExist]?.filter(
      (item, index, self) => {
        return (
          self?.findIndex((obj) => obj?.sortKey === item?.sortKey) === index
        );
      }
    );

    let valArr = itemsArr?.map((item) => item?.sortKey || item?.itemId);

    // first remove selected items
    /* let filterItems = [...selected.items].filter(
       (item) => {
         return (
           // self.findIndex((obj) => obj.sortKey === item.sortKey) === index
           item.itemType !== itemType
         );
       });
 
     let filterValues = [...selected.values].filter(
       (value, index, self) => {
         return self.indexOf(value) === index;
       }
     );
 
     // added updated items
     setSelected({
       ...selected,
       items: [...filterItems, ...updatedArray].filter(
         (item, index, self) => {
           return (
             self.findIndex((obj) => obj.sortKey === item.sortKey) === index
           );
         }
       ),
       values: [...filterValues, ...newRowSelectionModel].filter(
         (value, index, self) => {
           return self.indexOf(value) === index;
         }
       )
     });
     setSelectedRows(newRowSelectionModel); */

    // Check if only total 800 items allowed to select for the add/update promotion
    if (valArr?.length <= 800) {
      // added updated items
      setSelected({
        ...selected,
        items: itemsArr,
        values: valArr
      });

      setSelectedRows(valArr);
    } else {
      setShowMessage(true);
      setErrorMessage(
        'You have exceeded the maximum allowable limit for item selection, which is total 800 items at a time.'
      );
    }
  };

  const clearFilters = () => {
    setSidebarFilters({
      typeOfService: null,
      sellOnline: null,
      contractType: null,
      billingCycle: null,
      status: 1,
      categoryName: null,
      discount: ''
    });
  };

  return (
    <>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Grid>
        {sideBarFilters && (
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid
              item
              sx={{
                marginTop: '50px',
                marginBottom: '20px'
              }}
            >
              <Button
                className="filterBtn"
                onClick={showFilter}
                variant={displayFilterForm ? 'contained' : 'outlined'}
              >
                Filter (All)
                <KeyboardArrowDownRoundedIcon
                  className={clsx(
                    !displayFilterForm && classes.closeY,
                    displayFilterForm && classes.openY
                  )}
                />
              </Button>
            </Grid>

            <Grid
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginBottom: '20px'
              }}
              className="searchBar"
            >
              <CustomInput
                label={'Search'}
                value={searchQuery}
                setSearchQuery={handleChange}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          className="cartFilter cartTable promotion-cart-table no-width"
          container
          spacing={2}
        >
          {displayFilterForm === true ? (
            <Grid item width={getFilterSize()}>
              {/* <AddToCartFilter
                filters={sideBarFilters}
                setFilters={setSidebarFilters}
                filterOption={filterOptions}
              /> */}
              <Filter
                filters={sideBarFilters}
                filterOptions={filterOptions}
                setFilters={setSidebarFilters}
                clearFilters={clearFilters}
              />
            </Grid>
          ) : null}

          <Grid
            item
            sx={{
              width: `calc(100% - ${getFilterSize()}px)})`
            }}
          >
            {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
            ) : (
              <Grid
                item
                sx={{
                  width: '100%'
                }}
              >
                <Card
                  sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}
                  className="table-container bg-white"
                >
                  <CardHeader
                    title={title}
                    className="table-header-name"
                    sx={{ fontSize: '20px', color: '#003087' }}
                  />
                  <DataGrid
                    className="custom-table col-4 editable "
                    rows={tableData}
                    columns={columns}
                    loading={!tableData}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 }
                      }
                    }}
                    disableColumnMenu
                    getRowId={getRowId}
                    disableRowSelectionOnClick
                    columnBuffer={0}
                    checkboxSelection={isCheckBox}
                    pageSizeOptions={[5, 10, 50]}
                    onRowSelectionModelChange={handleCheckBoxChange}
                    // rowSelectionModel={selected?.values || selectedRows}
                    localeText={{ noRowsLabel: message }}
                    rowSelectionModel={selectedRows}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
