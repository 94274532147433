import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Container, Dialog, DialogContent, DialogContentText, DialogActions, Divider, Slide } from "@mui/material";
// import { HeaderContext } from 'src/contexts/HeaderContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ArrowLeft } from '../../../assets/SVG/SvgIcons';
import Header from '../../../components/Header';
import Loader from '../../../components/Loader';
import { ApiService } from '../../../services/ApiService';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import { getLocalData } from 'src/utils';
// import { getLocalData } from '../../../utils';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const Liability = () => {

    let userId = getLocalData('userId');
    // const { setHeaderText } = useContext(HeaderContext);
    let liabilityTextValue;
    // setHeaderText('Liability Waiver');
    // let userId = getLocalData('userId');
    // const { setHeaderText } = useContext(HeaderContext);
    // setHeaderText('Create Liability Waiver');
    // const [showError, setShowError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');
    const [liability, setLiability] = useState('');
    const [openConfirmPopUp, setOpenConfirmPopup] = useState(false);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [isFieldUpdated, setIsFieldUpdated] = useState(false);
    const [liabilityText, setLiabilityText] = useState('')
    const [showScreen, setShowScreen] = useState(0);


    const Editor = {};
    Editor.modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    Editor.formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video', 'HTML'
    ]


    /* Function to get  liability details on basis of id(Sort key) */
    const getLiabilityDetails = async () => {
        setIsLoading(true)
        let data = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}liability-waiver/${encodeURIComponent('#LW#2RjuaVQWo83BI3EvG1SIaCmam5R')}`);
        // let data = await ApiService.get(`${process.env.REACT_APP_API_END_POINT}liability-waiver`);
        setLiability(data.data.Item.content);
        liabilityTextValue = data.data.Item.content;
        // let formattedText = extractTextFromHTML(liabilityTextValue);
        setLiabilityText(liabilityTextValue);
        setIsLoading(false);
        console.log(data);
    }

    /* Function to edit liability on the basis of id(Sort key)  */
    const editLiabilityWaiver = async () => {

        setIsLoading(true);
        const updatedBy = userId;
        // const extractedText = extractTextFromHTML(liability);

        console.log(liability);


        let body = {
            content: liability,
            updatedBy: toString(updatedBy),
            isActive: true
        }
        let data = await ApiService.put(`${process.env.REACT_APP_API_END_POINT}liability-waiver/updateLiabilityWaiver?liabilityWaiverId=${encodeURIComponent('#LW#2RjuaVQWo83BI3EvG1SIaCmam5R')}`, body);
        if (data.statusCode === 200) {
            setIsLoading(false);
            setIsCreated(true);
            setOpen(true);

        }
        else {
            setIsLoading(false);
        }

        console.log(data);
    }

    // Function to clear the field after liability is added sucessfully

    const clear = () => {
        setLiability('');
    }

    /* Function to open dialog box */
    const openPopUp = () => {
        setIsCreated(false);

        setOpen(true);
        setIsFieldUpdated(false);

    }

    /* Function to edit the liability waiver */
    const liablityConfirm = (e) => {

        e.preventDefault();
        if (liability) {
            setOpenConfirmPopup(true);
        }
        // if (!liability) {
        //     setShowError(true);
        //     setErrorMessage('Liability waiver is required');
        // }
    }

    const handleCloseConfirm = (val) => {
        if (val === 'Discard') {
            setOpen(false);
            clear();
        }
        if (val === 'Save') {
            setOpenConfirmPopup(false);
            console.log(isFieldUpdated);
            // commented this if condtion for save textEditor event if its not edited
            // if (isFieldUpdated) {
            editLiabilityWaiver();
            // }

        }
        if (val === 'Continue') {
            setOpenConfirmPopup(false);
        }
    }

    // const extractTextFromHTML = (html) => {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(html, 'text/html');
    //     return doc.body.textContent;
    // };



    // const createLiability = () => {
    //     setIsLoading(true)
    //     let body = JSON.stringify({
    //         content: liability,
    //         createdBy: userId,
    //         isActive: true,
    //     })

    //     if (liability) {
    //         // setShowError(false);
    //         // setErrorMessage('');
    //         fetch(`${process.env.REACT_APP_API_END_POINT}liability-waiver`, {
    //             method: 'POST',
    //             headers: {
    //                 Accept: 'application/json',
    //                 'content-Type': 'application/json',
    //                 'Access-Control-Allow-Origin': '*'
    //             }, body: body
    //         }).then((response) => response.json()).then((data) => {
    //             if (data.statusCode && !data.error) {
    //                 setIsCreated(true);
    //                 setIsLoading(false);
    //                 setOpen(true);
    //             }
    //         }
    //         )
    //     } else {
    //         console.log("Message here");
    //     }
    //     console.log(liability);
    // }

    // const formValChange = (e) => {

    //     e.preventDefault();
    //     const { name, value } = e.target;
    //     switch (name) {
    //         case "liabilitywaiver":
    //             if (value.length > 0) {
    //                 // setShowError(false);
    //                 setErrorMessage('');
    //                 setLiability(value);
    //                 setIsFieldUpdated(true);
    //                 console.log(liability);
    //             } else {
    //                 // setShowError(true);
    //                 setErrorMessage('Liability waiver is required');
    //                 setLiability('');
    //             }
    //             break;
    //         default:

    //             break;
    //     }

    // };

    useEffect(() => {

        // setShowError(false);
        setLiability('');
        const fetchData = async () => {
            try {
                await getLiabilityDetails();

                //   let formattedText = extractTextFromHTML(liabilityTextValue);
                //   setLiabilityText(formattedText);
            } catch (error) {
                // Handle error if necessary
            }
        };

        fetchData();


    }, []);

    const handleClose = (val) => {

        if (val === 'Discard') {
            setOpen(false);
            getLiabilityDetails();
            setIsFieldUpdated(false);
            setShowScreen(0);
        }
        if (val === 'Continue') {
            setOpen(false);
            setIsFieldUpdated(true);

        }
    }



    const detailsLiability = (e) => {

        if (e) {
            setIsFieldUpdated(true)
        }
    }
    const handleButtonClick = () => {
        setShowScreen(1)
    };
    // const handlepaymentClick = () => {
        /*
        let tenderTypes = "com.squareup.pos.TENDER_CASH";
        let callbackUrl = "https://devzr.accelance.io/liability";
        // Your application ID
        let applicationId = "sq0idp-HBGKbdNEb5YXTqpnJA-N3w";
        
        // The total and currency code should come from your transaction flow.
        // For now, we are hardcoding them.
        let transactionTotal = "100";
        let currencyCode = "USD";
        // var timeout = "10000";
        // The version of the Point of Sale SDK that you are using.
        let sdkVersion = "v2.0";

        let posUrl =
    "intent:#Intent;" +
    "action=com.squareup.pos.action.CHARGE;" +
    "package=com.squareup;" + 
    "S.com.squareup.pos.WEB_CALLBACK_URI=" + callbackUrl + ";" +
    "S.com.squareup.pos.CLIENT_ID=" + applicationId + ";" +
    "S.com.squareup.pos.API_VERSION=" + sdkVersion + ";" +
    "i.com.squareup.pos.TOTAL_AMOUNT=" + transactionTotal + ";" +
    "S.com.squareup.pos.CURRENCY_CODE=" + currencyCode + ";" +
    "S.com.squareup.pos.LOCATION_ID=L2S1J1FF48BA0;" +
    "S.com.squareup.pos.TENDER_TYPES=" + tenderTypes + ";" +
    "l.com.squareup.pos.AUTO_RETURN_TIMEOUT_MS=4000;" +
    "end";

    window.location.assign(posUrl);
    */

    // let dataParameter = {
    //     amount_money: {
    //       amount: "100",
    //       currency_code: "USD"
    //     },
    //     // Replace this value with your application's callback URL
    //     callback_url: "https://ifcxqbb98k.execute-api.us-east-1.amazonaws.com/dev/checkout/paymentWebHook",
    //     // Replace this value with your application's ID
    //     client_id: "sq0idp-OUjitN-skZtQulJgdDNGRA",
    //     location_id:"LENFJDBBSEYGB",
    //     customer_id:"KXPC7R8YJ5C4RHFX8Q0P6R4KB0",
    //     auto_return: true,
    //     version: "1.3",
    //     notes: "{{id:397837}}",
    //     options: {
    //       supported_tender_types: ["CREDIT_CARD","CASH","OTHER","SQUARE_GIFT_CARD","CARD_ON_FILE"]
    //     }
    //   };
  
    //   window.location =
    //     "square-commerce-v1://payment/create?data=" +
    //     encodeURIComponent(JSON.stringify(dataParameter));
    // }
    const buttons = [
        {
            label: 'Edit',
            onClick: handleButtonClick,
            theme: true,


        },
        // {
        //     label: 'Pay',
        //     onClick: handlepaymentClick,
        //     theme: true,


        // },

    ];
    // const openEditPage = () => {
    //     setShowScreen(1);
    // }

    return (
        <>
            <Loader IsLoading={isLoading} />
            {(() => {
                switch (showScreen) {
                    case (1):
                        return (
                            <Container maxWidth={false} component="form" onSubmit={liablityConfirm}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Box onClick={() => openPopUp()} sx={{ mt: 0.8 }}>
                                            <ArrowLeft />
                                        </Box>

                                        <h1 className="primary-color -title-style ml">Liability Waiver</h1>
                                    </Box>

                                    <Button variant="contained" type="submit" className='button-style-manage-supplier'  >
                                        <Typography className="next-button-text">Save</Typography>
                                    </Button>
                                </Box>
                                <ReactQuill
                                    className="mt-20 react-quill-height"
                                    modules={Editor.modules}
                                    formats={Editor.formats}
                                    value={liability}
                                    onChange={setLiability}
                                    onKeyDown={detailsLiability}
                                />
                                {/* <span>{showError && <Box className="error-message">{errorMessage}</Box>}</span> */}
                                {/* <TextField
                    fullWidth
                    multiline
                    id="my-textarea"
                    name="liabilitywaiver"
                    label="WRITE YOUR LIABILITY WAIVER. . ."
                    placeholder="WRITE YOUR LIABILITY WAIVER. . ."
                    variant="outlined"
                    error={Boolean(errorMessage)}
                    helperText={errorMessage}
                    value={liability}
                    // defaultValue={description}
                    className="mt-20"
                    onChange={(e) => formValChange(e)}
                    InputLabelProps={{ className: 'label-style-create-franchisee' }}
                    InputProps={{ rows: 20 }} /> */}
                                {/* <Grid mt={1} component="form" onSubmit={liablityConfirm}> */}
                                {/* <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        <Grid item p={3}>
                            <Button variant="contained" type="submit" className='button-style-manage-supplier'  >
                                <Typography className="next-button-text">Save</Typography>
                            </Button>
                        </Grid>
                    </Grid> */}
                                {/* <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    > */}
                                {/* <Grid item p={3} xs={12} sm={12}>
                            <TextField
                                fullWidth
                                multiline
                                id="my-textarea"
                                name="liabilitywaiver"
                                label="WRITE YOUR LIABILITY WAIVER. . ."
                                placeholder="WRITE YOUR LIABILITY WAIVER. . ."
                                variant="outlined"
                                value={liability}
                                // defaultValue={description}
                                className=""
                                onChange={(e) => formValChange(e)}
                                InputLabelProps={{ className: 'label-style-create-franchisee' }}
                                InputProps={{ rows: 20 }} />
                            <Grid item xs={12} sm={6}>
                                {showError && <Box className="error-message">{errorMessage}</Box>}
                            </Grid>
                        </Grid>
                    </Grid> */}
                                {/* </Grid> */}
                            </Container>
                        )
                    default:
                        return (
                            <Container maxWidth='100%'>

                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                                    {showScreen === 0 && <Header title="Liability Waiver" buttons={buttons} />}
                                    {/* <Button variant="contained" type="submit" className='button-style-manage-supplier' onClick={() => openEditPage()}>
                                        <Typography className="next-button-text" >Edit</Typography>
                                    </Button> */}

                                </Box>
                                <div className="header-black-20" dangerouslySetInnerHTML={{ __html: liabilityText }} />
                            </Container>
                        )
                }

            })()}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose('Cancel')}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className="alert-decription align-center"
                    >
                        <Box display="flex" justifyContent="center" direction="column">

                            {!isCreated ? (
                                <Typography className="title-text-sub">
                                    Your unsaved changes will be discarded if you navigate away from this page.
                                    <Typography className="title-text-sub">What do you want to do?</Typography>
                                </Typography>
                            ) : (
                                <Typography className="title-text-sub">
                                    Liability text Edited successfully!
                                </Typography>
                            )}
                            {!isCreated && <Divider className="vertical-divider" />}
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="alert-button-control">
                    <Button
                        className="font-17 text-black no-hover"
                        onClick={() => handleClose('Discard')}
                    >
                        {isCreated ? 'Close' : 'Discard Changes'}

                    </Button>
                    {!isCreated && <Divider className="vertical-divider" />}
                    {!isCreated && (
                        <Button
                            className="font-17 no-hover"
                            onClick={() => handleClose('Continue')}
                        >
                            Back to editing
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmPopUp}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleCloseConfirm('Cancel')}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className="alert-decription align-center"
                    >
                        <Box display="flex" justifyContent="center" direction="column">

                            <Typography className="title-text-sub">
                                Do you want to save the changes you’ve made to the
                                <Typography className="title-text-sub">liability waiver text?</Typography>
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="alert-button-control">
                    <Button
                        className="font-17 no-hover"
                        onClick={() => handleCloseConfirm('Continue')}>
                        Back to Editing
                    </Button>
                    <Divider className="vertical-divider" />
                    <Button
                        className="font-17 text-black no-hover"
                        onClick={() => handleCloseConfirm('Save')}>
                        Save
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )
};

export default Liability;