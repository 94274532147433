import React, { forwardRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  Typography
} from "@mui/material";


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const DiscardPopup = ({ handleDialog, open, success }) => {


  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialog}
        // BackdropClick="ignore" // or
        disableEscapeKeyDown
        BackdropClick={false}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {success ? success.title : "Discard Changes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">

              <Typography className="title-text-sub">
                {success ? success.content 
                :
                <Typography className="title-text-sub">
                You have unsaved changes. 
                <Typography className="title-text-sub">What do you want to do? </Typography>
              </Typography>
              }

              </Typography>

            </Box>
          
          </DialogContentText>
        </DialogContent>
        <Divider />
        {success ? <DialogActions className="alert-button-control">


          <Button
            className="font-17 no-hover"
            onClick={() => handleDialog("close")}
          >
            Close
          </Button>

        </DialogActions> : <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleDialog("discard")}
          >
            Discard changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleDialog("save")}
          >
          Go Back to Editing
          </Button>
          
        </DialogActions>}

      </Dialog>
    </>
  );
};