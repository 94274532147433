import { Grid } from '@mui/material';
import DrawRecieveInventoryTable from './DrawRecieveInventoryTable';
import React, { useEffect, useState } from "react";
import Loader from '../../../components/Loader';
import { getLocalData, setFullLocationId } from 'src/utils';


function RecieveInventoryListAPI() {
  const [transferHistoryList, setTransferHistory] = useState([]);
  const [dataCount, setdataCount] = useState(0);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const locationId = setFullLocationId(getLocalData('locationId'));
  let [filters, setSidebarFilters] = useState({
    transactionStatus: 'received',
    fromLocationId: null,
    toLocationId: null
  });

  // gets call the method to get the data
  useEffect(() => {
    // Not call the get franchisee method again
    getTransferHistory();
  }, [JSON.stringify(filters)]);
  
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  console.log(isFranchisor, locationId, isPrimaryOwner);

  // Get Transfer method to get the list of franchisee
  const getTransferHistory = async () => {
    // Now show the loader
    setIsLoading(true);
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    let url;
    let encodedLocationId = encodeURIComponent(locationId);
    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
      url = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/transactions?locationId=${encodedLocationId}&${queryString}`;
    } else if (isFranchisor === 'false' && isPrimaryOwner === 'false' && locationId) {
      url = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/transactions?locationId=${encodedLocationId}&${queryString}`;
    } else {
      url = `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/transactions?${queryString}`;
    }

    fetch(
      // `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/transactions?limit=1000&locationId=LOC%237bb65894-4e56-7p98-1q44-w456i50b47j0&${queryString}`,
      `${url}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {

          // Set the list of franchisee
          setTransferHistory(data.data.Items);
          // Set the list of franchisee count
          setdataCount(data.data.Count);
          // Now hide the loader
          setIsLoading(false);
        }
      });
  };

  // Handle the event from child to parent. It is being used for refresh the data after edit or delete
  const refreshData = () => {
    // When popup is open then not call the loader
    // setIsPopup(true);
    // Not call the get transfer method again
    getTransferHistory();
  }

  return (

    <Grid className='card-table-main' xs={12}>
      <Loader IsLoading={isLoading} />
      {/* If data count is greater the 0 then pass the value into the parameter */}
      {/* {dataCount > 0 ? */}
      <DrawRecieveInventoryTable
        transferHistoryLists={transferHistoryList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        refreshData={refreshData}
        dataCount={dataCount}
      />
    </Grid>
  );
}

export default RecieveInventoryListAPI;
