import { useState, createContext } from 'react';

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  // half close side bar
  const [sidebarToggleHalf, setSidebarToggleHalf] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const toggleSidebarHalf = () => {
    setSidebarToggleHalf(!sidebarToggleHalf);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar, sidebarToggleHalf, toggleSidebarHalf }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
