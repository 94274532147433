import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import Loader from '../../../components/Loader';
// import { toast } from 'react-toastify';
import axios from 'axios';
import { getLocalData, setFullLocationId } from 'src/utils';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
// import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import DrawFranchiseeTable from './DrawFranchiseeTable';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function FranchiseeListAPI({
  ref,
  showScreen,
  setShowScreen,
  setFranchiseeItem,
  rolesList,
  franchiseeItem
}) {
  const [franchiseeList, setFranchisee] = useState([]);
  const [dataCount, setdataCount] = useState(0);
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  let [filters, setSidebarFilters] = useState({
    // isActive: null,
    status: 1,
    // team: null,
    // region: null,
    startDate: null,
    city: '',
    endDate: null,
    state: [],
    mud: null
  });
  const userId = getLocalData('userId');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [selectedFranchiseeLists, setSelectedFranchiseeLists] = useState([]);
  const [open, setOpen] = React.useState(false);
 // const [showSucessMessage, setShowSuccessMessage] = useState(false);
 // const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);

  // gets call the method to get the data
  useEffect(() => {
    // Not call the get franchisee method again
    getFranchisee();
  }, [JSON.stringify(filters)]);

  // Getting hooks from parent to child
  useImperativeHandle(ref, () => ({
    refreshFranchiseeData: () => {
      // Not call the get franchisee method again
      getFranchisee();
    }
  }));

  // methos to ferech the data

  // Get Franchise method to get the list of franchisee
  const getFranchisee = async () => {
    // Now show the loader
    setIsLoading(true);
    let filtersParams = JSON.parse(JSON.stringify(filters));
    Object.keys(filtersParams).forEach((key) => {
      if (
        filtersParams[key] === null ||
        filtersParams[key] === -1 ||
        filtersParams[key] === '' ||
        filtersParams[key] === 'undefined' ||
        filtersParams[key] === undefined ||
        (Array.isArray(filtersParams[key]) && filtersParams[key].length === 0)
      ) {
        delete filtersParams[key];
      }
    });

    let queryString = Object.keys(filtersParams)
      .map((key) => key + '=' + filtersParams[key])
      .join('&');

    fetch(
      `${process.env.REACT_APP_API_END_POINT}franchisees/getAllFranchise?${queryString}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of franchisee
          setFranchisee(data.data.Items);
          // Set the list of franchisee count
          setdataCount(data.data.Count);
          // Now hide the loader
          setIsLoading(false);
        }
      });
  };

  // Handle the event from child to parent. It is being used for refresh the data after edit or delete
  const refreshFranchiseeData = () => {
    // When popup is open then not call the loader
    // setIsPopup(true);
    // Not call the get franchisee method again
    getFranchisee();
  };

  const handleBulkAdd = async () => {
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    // show loader
    setIsLoading(true);
    const data = {
      // isActive: selectedStatus === 'Active',
      status: selectedStatus,
      updatedBy: userId,
      franchiseeIds: selectedFranchiseeLists
    };

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      console.log('encodedLocationId', encodedLocationId);
      // ?locationId=${encodedLocationId}
      const response = await axios.post(
        // `${BASE_URL}/memberships/updateBulkStatus?locationId=LOC%23227a3546-10a3-446b-ba5f-0971212d47bf`,
        `${process.env.REACT_APP_API_END_POINT}franchisees/updateBulk`,
        data
      );

      if (!response.data?.error) {
        // setShowSuccessMessage(true);
        // setSuccessMessage(
        //   response.data?.message || 'Franchisee status updated'
        // );
        // toast.success(response.data?.message || 'Franchisee status updated');
        setSelectedStatus('');
        setSelectedFranchiseeLists([]);
        setIsUpdated(true);
        setOpen(true);
        // getLocations();
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
            'Something went wrong with update franchisee status'
        );
        // toast.error(
        //   response.data?.message ||
        //   'Something went wrong with update franchisee status'
        // );
      }
      // show loader
      setIsLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // show loader
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
      // toast.error('Something went wrong, Try again!');
    }
  };

  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getFranchisee();
        setIsUpdated(false);
      }
    }
  };

  return (
    <Grid className="card-table-main" xs={12}>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {/* {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={getFranchisee}
        />
      )} */}
      <Loader IsLoading={isLoading} />
      {/* If data count is greater the 0 then pass the value into the parameter  refreshFranchiseeData={ refreshFranchiseeData } */}
      {/* {dataCount > 0 ? */}
      <DrawFranchiseeTable
        franchiseeLists={franchiseeList}
        sideBarfilters={filters}
        setSidebarFilters={setSidebarFilters}
        // getCities={franchiseecity}
        refreshFranchiseeData={refreshFranchiseeData}
        dataCount={dataCount}
        showScreen={showScreen}
        setShowScreen={setShowScreen}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleBulkAdd={handleBulkAdd}
        btnLoading={btnLoading}
        selectedFranchiseeLists={selectedFranchiseeLists}
        setSelectedFranchiseeLists={setSelectedFranchiseeLists}
        open={open}
        setOpen={setOpen}
        setFranchiseeItem={setFranchiseeItem}
        rolesList={rolesList}
        franchiseeItem={franchiseeItem}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                Are you sure you want to mark Activate/Deactivate,
                <Typography className="title-text-sub">
                all the selected items?
              </Typography>
              </Typography>
              ) : (
                <Typography className="title-text-sub">
                  All the selected franchises have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default React.forwardRef(FranchiseeListAPI);
