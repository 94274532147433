import {
  Box,
  Typography,
  Container,
  Button,
  IconButton,
  Card,
  InputAdornment,
  TextField,
  styled
} from '@mui/material';
import { Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import '../../style.css';
import { useNavigate } from "react-router-dom";
import LogoWithText from "src/components/LogoWithText.js";
import { Helmet } from 'react-helmet-async';
import Loader from '../../../../components/Loader';
import GlobalData from '../../../../global';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function UpdatePassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfimrPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [ConfirmpasswordError, setConfirmPasswordError] = useState("");
  const [email, setEmail] = useState("");
  // show loader when login button is clicked
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  // Get the title from global data
  const title = GlobalData[0].title;

  // check email from local storage
  useEffect(() => {
    let email = localStorage.getItem("email");
    setEmail(email);
  }, []);

  // Method to open the update dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the update dialog
  const handleClose = () => {
    setOpen(false);
    // Now navigate to login page
    navigate("/login");
  };

  // Now validate confirm password on change
  const validateConfirmPassword = () => {
    // let val = passwordValue;
    if (confirmpassword === password) {
      setConfirmPasswordError("");
    } else {
      setConfirmPasswordError("New password and confirm password does not match");
    }
  };

  // Now validate new password on change
  const validateNewpassword = (passwordValue) => {
    let passval = passwordValue;
    const passRex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

    setPassword(passval);

    if (!passval) {
      setPasswordError("Please enter new password");
      return;
    }
    if (!passRex.test(passval)) {
      setPasswordError("Password must contains a combination of uppercase, lowercase letter, number, and special character");
      return;
    }
    if (passval.length <= 7) {
      setPasswordError("Password must be at least 8 characters");
      return;
    }
    setPasswordError("");
  };

  // Now validate new password on keyup
  const checkPassword = (val) => {
    // setPassword(passwordValue);

    if (!val) {
      setPasswordError("Please enter new password");
      // return;
    }
  };
  const checkCnfPassword = (val) => {
    if (!val) {
      setConfirmPasswordError("Please enter confirm password");
      // return;
    }
    else if (val !== password) {
      setConfirmPasswordError("Password and confirm password must match");
    }
    else {
      setConfirmPasswordError('');
    }
  };

  // Now show password on click eye icon
  const passShow = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };
  // Now show confirm password on click eye icon
  const confirmPassShow = () => {
    if (showConfirmPassword) {
      setShowConfirmPassword(false);
    } else {
      setShowConfirmPassword(true);
    }
  };

  // Now update password method
  const updateFranchiseePassword = () => {
    if (confirmpassword && password) {
      if (password.length >= 7 && confirmpassword.length >= 7 && confirmpassword === password) {

        // reset error
        setConfirmPasswordError("");
        setPasswordError("");
        // Now show the loader
        setIsLoading(true);

        // body data
        let body = JSON.stringify({
          password: password,
          email: email.toLowerCase()
        });

        fetch(
          `${process.env.REACT_APP_API_AUTH_END_POINT}api/auth/updateFranchiseePassword`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body
        }
        ).then((response) => response.json())
          .then((data) => {
            // Now show the loader
            setIsLoading(false);
            // console.log("data", data);
            if (data.success) {
              // Now remove email from local storage
              localStorage.removeItem("email");

              // Now open the confirmation dialog
              handleClickOpen();

              // Now navigate to login page
              // navigate("/login");
            } else {
              let msg = data.message;
              if (msg) setConfirmPasswordError(msg);
            }
          }).catch((error) => {
            console.error('Error:', error);
            let errmsg = error.message;
            // set error message
            if (errmsg) setConfirmPasswordError(errmsg);
            // Now hide the loader
            setIsLoading(false);
          });
      }
    }
  };

  // handle submit method
  const handleSubmit = (e) => {
    e.preventDefault();

    // Password validation
    if (!password) {
      setPasswordError("Please enter new password");
      // return;
    }
    else if (password.length <= 7) {
      setPasswordError("Password must be at least 8 characters");
      // return;
    }
    // setPasswordError("");

    if (!confirmpassword) {
      setConfirmPasswordError("Please enter confirm password");
      // return;
    }
    else if (confirmpassword.length <= 7) {
      setConfirmPasswordError("Password must be at least 8 characters");

    }
    else {
      setPasswordError("");
      // Now set error to empty
      setConfirmPasswordError("");
      // Now call validate confirm password method
      validateConfirmPassword();
    }




  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Helmet>
        <title>Update password | {title}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="xxl" sx={{ padding: '0px !important' }}>
          <div className='main'>
            <div className="parent-container-main">
              {/* LogoWithText component */}
              <LogoWithText />
              <div className="form-section">
                {/* horizontal line */}
                <div className="zoom-room-line">
                  <div className="horizontal-line" />
                </div>
                {/* form area */}
                <div className="form1">
                  <Card className="form-area">
                    <Container component="main" maxWidth="xs" className="main-form-container">
                      <Box
                        sx={{
                          marginTop: 0,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="title-text">UPDATE PASSWORD</Typography>
                        <Typography className="sub-text" m={2}>
                          Please type something you'll remember
                        </Typography>
                        <Box
                          component="form"
                          onSubmit={handleSubmit}
                          className="update-password-form"
                        >
                          <TextField
                            margin="normal"
                            fullWidth
                            name="new-password"
                            placeholder="Must be 8 character"
                            type={showPassword ? "text" : "password"}
                            id="Password"
                            value={password}
                            error={Boolean(passwordError)}
                            helperText={passwordError}
                            onChange={(e) => validateNewpassword(e.target.value)}
                            onKeyUp={(e) => checkPassword(e.target.value)}
                            autoComplete="new-password"
                            label="NEW PASSWORD"
                            className="Pass-field field-label-css"
                            // InputProps={{ style:{borderRadius:'12px'}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={passShow}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              style: {
                                borderRadius: "12px",
                                padding: "20px 17px",
                                fontSize: "16px",
                                color: "#053288",
                                height: "76px",
                              },
                            }}
                          />

                          <TextField
                            margin="dense"
                            fullWidth
                            name="confirm-password"
                            placeholder="Repeat Password"
                            type={showConfirmPassword ? "text" : "password"}
                            id="password"
                            value={confirmpassword}
                            error={Boolean(ConfirmpasswordError)}
                            helperText={ConfirmpasswordError}
                            onChange={(e) => setConfimrPassword(e.target.value)}
                            onKeyUp={(e) => checkCnfPassword(e.target.value)}
                            autoComplete="confirm-password"
                            label="CONFIRM PASSWORD"
                            className="Cnfpass-field field-label-css"
                            // InputProps={{ style:{borderRadius:'12px'}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={confirmPassShow}>
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              style: {
                                borderRadius: "12px",
                                padding: "20px 17px",
                                fontSize: "16px",
                                color: "#053288",
                                height: "76px",
                              },
                            }}
                          />

                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className="button-style"
                            onClick={updateFranchiseePassword}
                          >
                            <Typography className="button-text">
                              UPDATE PASSWORD
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Container>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose("Cancel")}
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle className="alert-title align-center">{'Congratulations!'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description" className="alert-decription align-center">
              <Box display="flex" justifyContent="center" direction="column">
                <Typography className="title-text-sub">
                  Password has been updated successfully!
                  <Typography className="title-text-sub">Please login with new password!</Typography>
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button className="font-17 text-black no-hover" onClick={() => handleClose("close")}>LOGIN</Button>
          </DialogActions>
        </Dialog>
      </MainContent>
    </>
  );
}

export default UpdatePassword;
