/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Avatar,
  Card,
  CardHeader,
  IconButton,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Checkbox,
  Grid
} from '@mui/material';

export const BusinessEntitiesTable = (props) => {
  const {
    tableRows,
    searchQuery,
    loading,
    openDetail,
    selectedRows,
    setSelectedRows,
    selectedStatus,
    setSelectedStatus,
    handleBulkAdd,
    btnLoading
  } = props;
  const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const [tablePagination, setTablePagination] = useState({
  //   page: 0,
  //   limit: 10
  // });

  // To change the page into the table
  // const handlePageChange = (event, newPage) => {
  //   setTablePagination({ ...tablePagination, page: newPage });
  // };

  // To change the limit
  // const handleLimitChange = (event) => {
  //   setTablePagination({
  //     ...tablePagination,
  //     limit: parseInt(event.target.value)
  //   });
  // };

  // Apply paination on table
  // const applyPagination = (tableRows) => {
  //   const page = tablePagination.page;
  //   const limit = tablePagination.limit;
  //   return tableRows.slice(page * limit, page * limit + limit);
  // };

  // Apply search filter
  const applyFilters = (tableRows) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = tableRows.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = tableRows;
    }
    return arrayOfMatchedObjects;
  };

  // Apply filter from object and below is the pagination order
  // const filteredRows = applyFilters(tableRows);
  // const paginatedRows = applyPagination(filteredRows);

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {/* <CardHeader title="All Services" className="table-header" /> */}
            {/* <h2 className="table-heading">All Services</h2> */}
            <Typography className="table-heade header-blue-20">
              {' '}
              All Business Entities
            </Typography>
          </Grid>
        </Grid>
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Name</TableCell>
                <TableCell>Business Entity Type</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    key={row.sortKey}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    className="table-row cursor-pointer"
                    onClick={(e) => openDetail(row.sortKey, 3, e)}
                  >
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row.businessName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.businessEntityType}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.businessEmail}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.phone}
                      </Typography>
                    </TableCell>

                    <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.id}
                        onClick={(e) => openDetail(row.sortKey, 4, e)}
                      >
                        Edit
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          {/* <TablePagination
            component="div"
            count={filteredRows?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={tablePagination.page}
            rowsPerPage={tablePagination.limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          /> */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </Box>
  );
};
