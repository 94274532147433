import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  // CircularProgress,
  Grid,
  TextField
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import clsx from 'clsx';
import { Filter } from './index';
// import { AddToCartFilter } from '../Filters/AddToCartFilter';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';

export const FilterAndSearchComponent = ({
  sideBarFilters,
  setSidebarFilters,
  title,
  dataList,
  columns,
  isCheckBox,
  setSelected,
  selected,
  filterOptions,
  handleChangeFilter,
  tempSelectedItems,
  displayFilterForm,
  setDisplayFilterForm
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  // const [temp, setTemp] = useState([])

  useEffect(() => {
    if (title === 'All Products' && searchQuery) {
      setProductList(tableData);
    }
  }, [tableData]);

  // useEffect(()=> {
  //   let items = selected?.items ?? []
  //   if(temp.length){
  //     items = [...items, temp]
  //     setSelected({
  //       ...selected,
  //       items: items
  //     })
  //   }
  // },[temp])

  const handleChange = (value) => {
    setSearchQuery(value);
    let list = [...dataList];

    let result = columns?.map((attribute) =>
      list.filter((item) =>
        item[attribute?.field]
          ?.toString()
          ?.toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    const convertedData = [];
    let uniqueData = [];
    result.forEach((item) => {
      if (item.length > 0) {
        convertedData.push(...item);
      }
    });
    convertedData.forEach((item) => {
      if (!uniqueData.includes(item)) {
        uniqueData.push(item);
      }
    });
    if (value !== '') {
      setTableData(uniqueData);
    } else {
      setTableData(dataList);
    }
  };

  useEffect(() => {
    if (title === 'All Products' && searchQuery) {
      const filteredData = dataList?.filter((dataItem) =>
        productList?.some(
          (productItem) => productItem?.sortKey === dataItem?.sortKey
        )
      );
      filteredData?.sort((a, b) => {
        const sortKeyA = productList?.findIndex(
          (item) => item?.sortKey === a?.sortKey
        );
        const sortKeyB = productList?.findIndex(
          (item) => item?.sortKey === b?.sortKey
        );

        // If either sortKey is not found in the productList, leave it in its current position
        if (sortKeyA === -1 && sortKeyB === -1) {
          return 0;
        }
        if (sortKeyA === -1) {
          return 1;
        }
        if (sortKeyB === -1) {
          return -1;
        }

        return sortKeyA - sortKeyB;
      });
      setTableData(filteredData);
    } else {
      setTableData(dataList);
      // setDisplayFilterForm(false);
      setisLoading(true);
    }
  }, [dataList]);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  const showFilter = () => {
    setDisplayFilterForm(!displayFilterForm);
    handleChangeFilter();
  };
  const getRowId = (row) => row?.sortKey || row?.itemId;
  // Working code

  useEffect(() => {
    if (selected?.values?.length > 0 && selectedRows?.length === 0) {
      setSelectedRows(selected?.values);
    }
  }, [tempSelectedItems?.tempValues]);

  useEffect(() => {
    setSelectedRows(selected?.values);
  }, [selected?.values]);

  console.log(isLoading, 'isloading is consoled jere');

  const handleCheckBoxChange = (newRowSelectionModel) => {
    if (isLoading) return;
    const filteredResults = dataList?.filter((item) =>
      newRowSelectionModel?.includes(item.sortKey)
    );

    let updatedArray = filteredResults;
    let itemType = '';
    if (title === 'All Pricing Options') {
      updatedArray = filteredResults.map((item) => {
        return {
          itemType: 'PricingOption',
          sortKey: item.sortKey,
          itemId: item.sortKey,
          itemName: item.name,
          quantity: 1,
          itemCategoryId: item?.categoryId ?? '',
          itemCategoryName: item?.categoryName ?? '',
          discount: item?.discount ?? 0,
          price: item?.price ?? 0,
          creditsValidityStarts: item?.creditsValidityStarts ?? '',
          numberOfSessions: item?.numberOfSessions ?? 0,
          sellOnline: item?.sellOnline ?? false,
          free: item?.free ?? false,
          sharable: item?.sharable ?? false,
          restricted: item?.restricted ?? false,
          validThroughStartDate: item?.validThroughStartDate ?? '',
          validThroughEndDate: item?.validThroughEndDate ?? '',
          services: item?.services ?? [],
          schedulingLimitation: item?.schedulingLimitation ?? {},
          setTimeRange: item?.setTimeRange ?? false,
          startTime: item?.startTime ?? '',
          endTime: item?.endTime ?? '',
          creditsValidityExpires: item?.creditsValidityExpires ?? {},
          TotalPrice: item?.price || 0.0
        };
      });
      itemType = 'PricingOption';
    } else if (title === 'All Contracts') {
      updatedArray = filteredResults.map((item) => {
        return {
          sortKey: item?.sortKey,
          itemId: item?.sortKey,
          itemName: item?.contractName,
          itemType: 'Contract',
          quantity: 1,
          price: item?.price || 0.0,
          TotalPrice: item?.price || 0.0,
          contractType: item?.contractType || '',
          autopay: item?.autopay || false,
          billingCycle: item?.billingCycle || '',
          uponConsumption: item?.uponConsumption,
          terms: item?.terms

          // billingCycle: 0
        };
      });
      itemType = 'Contract';
    } else if (title === 'All Memberships') {
      updatedArray = filteredResults.map((item) => {
        return {
          sortKey: item?.sortKey,
          itemId: item?.sortKey,
          itemName: item?.name,
          itemType: 'Membership',
          quantity: 1,
          price: item?.price || 0.0,
          TotalPrice: item?.price || 0.0,
          billingCycle: item?.billingCycle?.toString() || '',
          autopay: item?.autopay || false
          // applyToAllPricingOption: item?.applyToAllPricingOption || false,
          // applyToAllProduct: item?.applyToAllProduct || false
        };
      });
      itemType = 'Membership';
    } else if (title === 'All Products') {
      updatedArray = filteredResults.map((item) => {
        return {
          sortKey: item?.sortKey,
          itemId: item?.sortKey,
          itemName: item?.fName,
          itemType: 'Product',
          quantity: item?.selectedQuantity,
          price: item?.price ?? 0.0,
          TotalPrice:
            Number(item?.price) * Number(item?.selectedQuantity) ?? 0.0,
          itemCategoryName: item?.categoryName ?? '',
          // itemVariantId: item?.variantId ?? "",
          // itemVariantType: item?.variant?.variantType ?? "",
          // itemValueId: item?.valueId ?? "",
          // itemVariantValue: item?.variant?.value ?? "",
          variants: item?.variants ?? []
        };
      });
      itemType = 'Product';
    } else if (title === 'All Bundles') {
      updatedArray = filteredResults.map((item) => {
        return {
          sortKey: item?.sortKey,
          itemId: item?.sortKey,
          itemName: item?.bundleName,
          itemType: 'Bundle',
          quantity: 1,
          price: item?.bundlePrice ?? 0.0,
          TotalPrice: Number(item?.bundlePrice) ?? 0.0,
          discount: item?.discount ?? 0,
          actualPrice: item?.actualPrice ?? 0,
          sellOnline: item?.sellOnline ?? false,
          startDate: item?.startDate ?? '',
          endDate: item?.endDate ?? ''
        };
      });
      itemType = 'Bundle';
    }
    // first remove selected items

    // let prevItems = [...selected.items]?.filter((item) => {
    //   return item.itemType === itemType;
    // });

    let nonExist = [...selected.items]?.filter((item) => {
      return (
        item.itemType === itemType &&
        !tableData?.some((dataItem) => dataItem?.sortKey === item?.sortKey)
      );
    });

    // let filteredItems = prevItems?.filter((prevItem) => {
    //   return (
    //     !tableData?.some((tableItem) => tableItem?.sortKey === prevItem?.sortKey) &&
    //     dataList?.some((dataItem) => dataItem?.sortKey === prevItem?.sortKey)
    //   );
    // });

    let filterItems = [...selected.items]?.filter((item) => {
      return item.itemType !== itemType;
    });

    let itemsArr = [...filterItems, ...updatedArray, ...nonExist]?.filter(
      (item, index, self) => {
        return (
          self?.findIndex((obj) => obj?.sortKey === item?.sortKey) === index
        );
      }
    );

    // if(title === 'All Contracts'){
    //   let allContracts = [...selected.items]?.filter((item) =>  item.itemType === 'Contract')
    //   itemsArr = itemsArr?.filter((item) => item.itemType !== 'Contract')
    //   allContracts = allContracts?.length > 0 ?  : [];
    //   console.log(allContracts.map((item)=> item.itemName), "allContractsallContracts")
    //   // itemsArr = [...itemsArr, ...allContracts]
    // }

    let valArr = itemsArr?.map((item) => item?.sortKey || item?.itemId);

    // added updated items
    setSelected({
      ...selected,
      items: itemsArr,
      values: valArr
    });

    localStorage.setItem(
      'checkoutData',
      JSON.stringify({
        ...selected,
        items: itemsArr,
        values: valArr
      })
    );

    setSelectedRows(valArr);
  };

  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 100);
  }, [dataList]);

  const clearFilters = () => {
    setSidebarFilters({
      typeOfService: null,
      contractType: null,
      billingCycle: null,
      status: null,
      category: null
    });
    // let list = sideBarFilters;
    // Object.entries(list).forEach(([key]) => {
    //   list[key] = null;
    // });
    // setSidebarFilters(list);
  };
  const searchLabel = title?.replace('All ', '');
  return (
    <>
      <Grid>
        {sideBarFilters && (
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid
              item
              sx={{
                marginTop: '50px',
                marginBottom: '20px'
              }}
            >
              <Button
                className="filterBtn"
                onClick={showFilter}
                variant={displayFilterForm ? 'contained' : 'outlined'}
              >
                Filters
                <KeyboardArrowDownRoundedIcon
                  className={clsx(
                    !displayFilterForm && classes.closeY,
                    displayFilterForm && classes.openY
                  )}
                />
              </Button>
            </Grid>

            <Grid
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginBottom: '20px'
              }}
              className="searchBar"
            >
              <TextField
                type="search"
                className="Search-field"
                label={`Search ${searchLabel}`}
                sx={{ width: '215px' }}
                InputProps={{
                  style: {},
                  endAdornment: !searchQuery ? <SearchIcon /> : null
                }}
                onChange={(e) => handleChange(e.target.value)}
                value={searchQuery}
              />
            </Grid>
          </Grid>
        )}
        <Grid className={`cartFilter cartTable-checkout ${title === 'All Contracts' ? 'contractsCartTable' :""}`} container spacing={2}>
          {displayFilterForm === true ? (
            <Grid item width={getFilterSize()}>
              {/* <AddToCartFilter
                filters={sideBarFilters}
                setFilters={setSidebarFilters}
                filterOption={filterOptions}
              /> */}
              <Filter
                filters={sideBarFilters}
                filterOptions={filterOptions}
                setFilters={setSidebarFilters}
                clearFilters={clearFilters}
              />
            </Grid>
          ) : null}

          <Grid
            item
            sx={{
              width: `calc(100% - ${getFilterSize()}px)})`
            }}
          >
            {/* {loading ? (
              <div className="loader">
                <CircularProgress />
              </div>
             ) : ( */}
            <Grid
              item
              sx={{
                width: '100%!important'
              }}
            >
              <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px', width: "100%!important" }}>
                <CardHeader
                  title={title}
                  className="table-header-name"
                  sx={{ fontSize: '20px', color: '#003087' }}
                />
                <DataGrid
                  className="custom-table-checkout col-4 "
                  rows={tableData}
                  columns={columns}
                  loading={!tableData}
                  autoHeight 
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 }
                    }
                  }}
                  disableColumnMenu
                  getRowId={getRowId}
                  disableRowSelectionOnClick
                  columnBuffer={0}
                  checkboxSelection={isCheckBox}
                  pageSizeOptions={[5, 10, 50]}
                  // onRowSelectionModelChange={handleCheckBoxChange}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    handleCheckBoxChange(newRowSelectionModel);
                  }}
                  rowSelectionModel={selectedRows}
                  // isRowSelectable={(params) => {
                  //   // Check the quantity of the item and allow selection only if quantity is greater than or equal to 0
                  //   if (title === 'All Products') {
                  //     return params?.row?.quantity > 0;
                  //   }
                  //   return true;
                  // }}
                />
              </Card>
            </Grid>
            {/* )} */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
