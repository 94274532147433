import {
    Container,
    Box,
    styled,
    Grid,
    Typography,
    Avatar,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
 import Loader from '../Loader';
  import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
 import TrophyEarned from 'src/content/applications/ClientManagement/TrophyEarned';
  import { ApiService} from 'src/services/ApiService';
  

  
  // PetDetails page style wrapper
  const PetDetailsWrapper = styled(Container)(
    ({ theme }) => `
          margin-top: ${theme.spacing(2)};
  `
  );
  
  // PetDetails page main method
  const PetDetails = ({ petId, setShowScreen,  clientId }) => {
    // show loader when login button is clicked
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // set the pet details
    const [petDetails, setPetDetails] = useState({});
  
    useEffect(() => {
      window.scroll(0, 0);
      // call the get client details method
      if (petId) getChildDetails(petId);
    }, [petId]);
  

 
  
    // Method to get the client details
  
    const getChildDetails = async (petId) => {
      let encodedChildId = encodeURIComponent(petId);
      let encodedCLientId = encodeURIComponent(clientId);
  
      try {
        const response = await ApiService.get(
          `${process.env.REACT_APP_API_END_POINT}client/getChildDetails?childId=${encodedChildId}&clientId=${encodedCLientId}`
        );
        // call the api to get the location details
        setIsLoading(true);
  
        let data = response.data;
        if (data) {
          setIsLoading(false);
          // set the location details
          let items = data.Items;
          if (items) setPetDetails(items[0]);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        // set error message
        setShowError(true);
        setErrorMessage(error.message);
      }
    };
  
 

  
    // Method to delete the pet
  
    const handleCloseDetails = () => {
      setShowScreen(3);
    };
  
    return (
      <>
        <Loader IsLoading={isLoading} />
  
        <PetDetailsWrapper maxWidth={'100%'} className="location-details-wrapper">
          {petDetails && (
            <>
              <Grid
                className="location-header-container"
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{ paddingBottom: '15px' }}
              >
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      onClick={() => handleCloseDetails()}
                      className="back-arrow-close"
                    >
                      <ArrowLeft />
                    </Box>
                    <Typography className="header-blue-32">
                      {petDetails.firstName} {petDetails.lastName}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className="location-details-container">
                {/* <Grid container spacing={2} > */}
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">NAME</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.firstName} {petDetails.lastName}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">GENDER</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.gender}
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">
                      Acquisition Source
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.acquisitionSource}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">BREED</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.breed}
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">ALLERGIES</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.allergies}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">Veterinarian</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.veterinarian}
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">DATE OF BIRTH</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    {/* <Box className="location-details-value">Your dog is {petAge} old</Box> */}
                    <Box className="location-details-value">
                      {petDetails.birthDate}
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={1}
                  className="location-details-row"
                >
                  <Grid
                    item
                    xs={12}
                    sm={2.5}
                    className="location-details-col d-flex align-items-center"
                  >
                    <Box className="location-details-label">PROFILE IMAGE</Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3.5}
                    className="location-details-col pl-0"
                  >
                    <Box className="location-details-value">
                      <Box className="location-details-value location-image">
                        <Avatar
                          alt="icon"
                          src={
                            petDetails.profileImage ||
                            '/static/images/logo/cardboardicon.png'
                          }
                          sx={{ marginLeft: 2 }}
                          className="card-avatar-img"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
  
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label text-blac">NOTES</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.notes}
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label text-blac">COMPLETED</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value txt-Transform">
                      {completedDoses}
                    </Box>
                  </Grid> */}
                </Grid>
  
                {/* Vaccination Details Details */}
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="page-header title-adjust mt-10"
                >
                  <span className="location-name text-wrap font-20 mt-10">
                    Vaccination Details
                  </span>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label ">RABIES EXP DATE</Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.rabiesExpiration}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label ">
                      BORDETELLA EXP DATE
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.bordetellaExpiration}
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  className="location-details-row"
                >
                  <Grid item xs={12} sm={2.5} className="location-details-col">
                    <Box className="location-details-label">
                      COMBO SHOT EXP DATE
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3.5} className="location-details-col">
                    <Box className="location-details-value">
                      {petDetails.dhppExpiration}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} className="location-details-col">
                    <Box className="location-details-label">
                      ATTACHED DOCUMENT
                    </Box>
                    <Box className="location-details-value">
                      {petDetails?.documentList?.length > 0 ? (
                        petDetails?.documentList?.map((item, index) => {
                          return (
                            <span key={index}>
                              <a
                                href={item.documentUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="text-grey"
                              >
                            
                                {item.documentName || 'View Document'}
                              </a>
                              {index !== petDetails.documentList.length - 1
                                ? ', '
                                : ''}
                            </span>
                          );
                        })
                      ) : (
                        <span>No document attached</span>
                      )}
                    </Box>
                  </Grid>
                </Grid>
  
                {/* Trophy Earned */}
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="page-header title-adjust mt-40"
                >
                  <span className="location-name text-wrap font-20 mt-10">
                    Trophy Earned
                  </span>
                </Grid>
                <Grid container mt={2} className="location-details-row">
                  <TrophyEarned clientId={clientId} petId={petId} />
                </Grid>
  
                {/* </Grid> */}
                {showError && (
                  <Grid container mt={3} className="location-details-row">
                    <Box className="error-message">{errorMessage}</Box>
                  </Grid>
                )}
              </Box>
            </>
          )}
        </PetDetailsWrapper>
  
      </>
    );
  };
  
  export default PetDetails;
  