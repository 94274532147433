import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Divider,
  MenuItem,
  Avatar,
  InputLabel,
  FormControl,
  Select,
  Slide
} from '@mui/material';
// import image from './images/image-plus-outline.png' // setFullLocationId
import { getLocalData, getFileFromUrl } from '../../../utils';
// import AddchartIcon from '@mui/icons-material/Addchart';
import { PictureSnapshot } from 'src/assets/SVG/SvgIcons';
import Loader from '../../../components/Loader';
import BundleTable from './BundlesTable';
import MembershipTable from './MembershipTable';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import ContractTable from './ContractTable';
import { ApiService } from '../../../services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import heic2any from 'heic2any';
// import { ArrowLeft } from 'src/assets/SVG/SvgIcons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const FeaturedItemEdit = ({ setShowScreen, selectedData }) => {
  let detailsData = selectedData[0];
  // let itemNewValue = {};
  // let featuredData = selectedData[0].services;
  console.log('SELECTED DATA ---->', detailsData);

  const [open, setOpen] = React.useState(false);
  const [itemValue, setItemValue] = React.useState({});
  const [showData, setShowData] = useState('');
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectItem, setSelectItem] = useState('');
  const [bundlesList, setbundlesList] = useState('');
  const [locationData, setLocationData] = useState([]);
  // const [locationName, setLocationName] = useState([]);
  // const [imgObjClient, setimgObjClient] = useState([]);
  // const [imgUrlClient, setimgUrlClient] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [descriptionError, setDescriptionError] = useState('');
  const [locationIdError, setlocationIdError] = useState('');
  //  const [itemType, setItemType] = useState('');
  const [membershipList, setmembershipList] = useState('');
  const [contractList, setcontractList] = useState([]);
  // const [name, setName] = useState('');
  // const [price, setPrice] = useState('');
  const [itemType, setItemType] = useState('');
  const [isLocationid, setLocationId] = useState('');
  const [imageSelected, setImageSelected] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [status, setStatus] = useState('');
  const [itemNewValue, setItemNewValue] = React.useState({});
  const [imageError, setImageError] = useState(false);
  const [imageErrorMsg, setImageErrorMsg] = useState('');
  const [filtersParams, setFiltersParams] = useState(''); // filtersParams
  const [filtersMemberParams, setFiltersMemberParams] = useState(''); // filtersParams
  const [filtersContractParams, setFiltersContractParams] = useState(''); // filtersParams
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [selectItemError, setSelectItemError] = useState('');
  // const [selectedLocation, setSelectedLocation] = useState('');
  //   console.log(selectedBundle);
  // const { setHeaderText } = useContext(HeaderContext);
  //     setHeaderText('Edit Featured Item');

  const setData = () => {
    if (detailsData?.featuredImage) {
      setImageSelected(true);
      let file = getFileFromUrl(detailsData?.featuredImage);
      setimgObj(file);
    }
    console.log(detailsData?.itemValue);
    // setItemType(detailsData?.itemType);
    setItemValue(detailsData?.itemValue);
  };

  const getData = () => {
    let itemSet = detailsData?.itemType;
    console.log(itemSet, 'item value');
    setTitle(detailsData?.title);
    setDescription(detailsData?.description);
    setItemType(itemSet);
    // setSelectItem(itemSet);
    // setItemType(detailsData?.itemType)
    setLocationId(detailsData?.locationId);
    setImgUrl(detailsData?.featuredImage);
    setStatus(detailsData?.status);
    setShowData(detailsData?.itemType);
    setSelectItem(detailsData?.itemData[0]);
  };
  console.log(imgUrl);

  const handleEditClientClose = () => {
    setIsUpdated(false);
    // if (isFieldUpdated) {
    setOpen(true);
    // setopenAddproduct(true);
    // } else {
    //   setShowScreen(0)
    //   clear();
    //   // setopenAddproduct(false);
    //   // setopenProductPopup(false);
    // }
  };
  let userId = getLocalData('userId');

  const handleLocationChange = (e) => {
    setLocationId(e);
  };
  const handleClosePopup = (val) => {
    if (val === 'Save') {
      setOpen(false);
      // setopenAddClient(true);
      // openModal(true);
    } else if (val === 'Discard') {
      setOpen(false);
      // setopenAddClient(false);
      // openModal(false);
      setShowScreen(0);
    }
  };

  // const locationId = setFullLocationId(getLocalData('locationId'));
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  console.log(franchiseeId, 'franchiseeid');
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'title') {
      if (value === '') {
        setTitle('');
        setTitleError('Title is required');
      } else {
        setTitleError('');
        setTitle(value);
        setIsFieldUpdated(true);
      }
    }
    if (name === 'description') {
      if (value === '') {
        setDescription('');
        setDescriptionError('Description is required');
      } else {
        setDescriptionError('');
        setDescription(value);
        setIsFieldUpdated(true);
      }
    }
    // if (name === 'itemType') {
    //   setItemValue(value); // Update itemType state
    //   // Reset selectItem to previous value when itemType changes
    //   setSelectItem({ ...selectItem, itemType: value });
    // }
    if (name === 'itemType') {
      if (value === '') {
        setItemType('');
        setSelectItemError('Select item is required');
      } else {
        itemNewValue.id = '';
        setSelectItemError('');
        setItemType(value);
        setIsFieldUpdated(true);
      }
      // Update itemType state
      // Reset selectItem to previous value when itemType changes
      // setSelectItem({ ...selectItem, itemType: value });
    }

    if (name === 'locationId') {
      if (value === '') {
        setLocationId('');
        setlocationIdError('Location is required');
      } else {
        setlocationIdError('');
        setLocationId(value);
        setIsFieldUpdated(true);
      }
    }
  };
  //     const handleLocationChange = (e)=>{
  //    setLocationId(e)
  //     }
  const getBundle = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllBundles?locationId=${encodedLocationId}${filtersParams}`
      );
      setbundlesList(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };
  const getMembership = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllMemberships?locationId=${encodedLocationId}${filtersMemberParams}`
      );
      setmembershipList(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };
  const getContract = async () => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllContracts?locationId=${encodedLocationId}${filtersContractParams}`
      );
      setcontractList(response.data.Items || response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getLocation = async () => {
    let encodedFranchiseeId = encodeURIComponent(franchiseeId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}featured-items/getAllLocations?franchiseeId=${encodedFranchiseeId}`
      );
      setLocationData(response.data.Items);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(isFieldUpdated);
  // const clear = () => {
  //   setTitle('')
  //   setDescription('')
  //   setLocationId('')
  //   setSelectItem('')
  // }
  useEffect(() => {
    // clear();
    setData();
    getBundle();
    getContract();
    getMembership();
    getLocation();
    getData();
  }, []); // selectItem

  useEffect(() => {
    getBundle();
  }, [filtersParams]);

  useEffect(() => {
    getMembership();
  }, [filtersMemberParams]);

  useEffect(() => {
    getContract();
  }, [filtersContractParams]);

  const SelectBundle = () => {
    itemNewValue.id = '';
    setSelectItem('');
    setShowData('Bundles');
  };
  const SelectMembership = () => {
    itemNewValue.id = '';
    setSelectItem('');
    setShowData('Memberships');
  };
  const SelectContract = () => {
    itemNewValue.id = '';
    setSelectItem('');
    setShowData('Contracts');
  };
  const confirmAddFeaturedItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let itemNewValue = {};

    if (showData === 'Bundles') {
      itemNewValue.id = selectItem?.sortKey;
      setItemNewValue(itemNewValue);
    } else if (showData === 'Memberships') {
      //  itemNewValue.name = selectItem.name;
      //  itemNewValue.price = selectItem.price;
      itemNewValue.id = selectItem?.sortKey;
      setItemNewValue(itemNewValue);
    } else if (showData === 'Contracts') {
      //  itemNewValue.name = selectItem.contractName;
      //  itemNewValue.price = selectItem.price;
      itemNewValue.id = selectItem?.sortKey;
      setItemNewValue(itemNewValue);
    }
    if (!title) {
      setTitleError('Title is required');
    }
    if (!description) {
      setDescriptionError('Description is required');
    }
    if (!isLocationid) {
      setlocationIdError('Location is required');
    }
    if (!itemType) {
      setSelectItemError('Select item is required');
    } else if (!itemNewValue.id && itemType) {
      setShowMessage(true);
      console.log('tetststststtsts');
      setErrorMessage(`Select at least one item from ${itemType}`);
      // setSelectFeaturedItemError(`Select at least one item from ${selectItem ? selectItem.toLowerCase() : ''}`);
    }
    // let data = imgObj?.name ? Object.keys(imgObj.name).length : 0;
    if (imgObj.length === 0) {
      setImageError(true);
      setImageErrorMsg('Image is required');
    }
    // console.log(data, "data", imgObj, "imgObj", && data > 0)

    // if (title && description && isLocationid && itemNewValue.id && showData) {
    if (
      title &&
      description &&
      isLocationid &&
      itemNewValue.id &&
      showData &&
      imgObj.length !== 0
    ) {
      setOpenConfirmation(true);
      // editFeaturedItem([itemNewValue]);
    }
  };

  const editFeaturedItem = async (itemNewValue) => {
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
    let sortKey = encodeURIComponent(detailsData.sortKey);

    const formData = new FormData();
    formData.append('featuredImage', imgObj);
    formData.append('title', title);
    formData.append('description', description);

    // Make sure `showData` has a valid value before appending it
    // if (showData) {
    //   formData.append('itemType', showData);
    // }else{
    //   formData.append('itemType', itemType);
    // }

    if (showData) {
      console.log(showData);
      formData.append('itemType', showData);
      formData.append('itemValue', JSON.stringify(itemNewValue));
    } else {
      formData.append('itemType', itemType);
      formData.append('itemValue', JSON.stringify(itemValue));
    }
    // formData.append('itemType', itemType)
    // formData.append('itemValue', JSON.stringify(itemValue));
    // formData.append('locationId', isLocationid);
    formData.append('status', status);
    formData.append('updatedBy', userId);

    // setIsLoading(true);

    try {
      let data = await ApiService.put(
        `${process.env.REACT_APP_FEATURED_API_END_POINT}featured-items/updateFeaturedItem?locationId=${encodedLocationId}&id=${sortKey}`,
        formData
      );

      if (data.statusCode === 200) {
        // Success logic
        setOpen(true);
        setIsUpdated(true);
        setIsLoading(false);
      } else {
        // Error logic
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleImageUploadfeatured = (e) => {
  //   // Create an object URL
  //   setImgUrl(URL.createObjectURL(e.target.files[0])); // Update the imgUrl state with the object URL
  //   setImageSelected(true);
  //   setimgObj(e.target.files[0]);
  //   setIsFieldUpdated(true);
  //   setImageError(false);
  //   setImageErrorMsg('');
  // };

  const handleImageUploadfeatured = async (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      const fileName = file.name.toLowerCase();
      setImageSelected(true);

      console.log(file, 'filefile');

      if (
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.jpg')
      ) {
        setImgUrl(URL.createObjectURL(file));
        setImageSelected(true);
        setimgObj(file);
        setImageError(false);
      } else if (fileName.endsWith('.heic')) {
        try {
          const jpegBlob = await heic2any({ blob: file });
          if (!jpegBlob) {
            throw new Error('Conversion failed.');
          }

          const jpegFile = new File([jpegBlob], `${file.name}.jpeg`, {
            type: 'image/jpeg'
          });

          setImgUrl(URL.createObjectURL(jpegFile));
          setImageSelected(true);
          setimgObj(jpegFile);
          setImageError(false);
        } catch (error) {
          console.error('Error handling HEIC file:', error);
          setImageError(true);
          setImgUrl('');
          setimgObj([]);
          setImageErrorMsg('Error handling HEIC file.');
        }
      } else {
        setImageError(true);
        setImgUrl('');
        setimgObj([]);
        setImageErrorMsg(
          'Please upload only png, jpeg, jpg, or heic format images.'
        );
      }
    }
  };

  console.log(imgObj);
  // const handleImageUploadfeatured = (e) => {
  //   setimgObjClient(e.target.files[0]);
  //   // setimgUrlClient(URL.createObjectURL(e.target.files[0]));
  //   setIsFieldUpdated(true);
  // };

  const handleConfirmPopup = (val) => {
    if (val === 'Save') {
      setOpenConfirmation(false);
      editFeaturedItem([itemNewValue]);
    } else {
      setOpenConfirmation(false);
    }
  };

  // const handleCloseDetails = () =>{
  //   if(isFieldUpdated){
  //     setOpen(true);
  //   }
  //   else{
  //     setShowScreen(0);
  //   }

  // }

  // Cross icon handler
  const removeImage = () => {
    // const updatedImages = newImages.filter((image) => image.id !== idToRemove);
    // setNewImages(updatedImages);
    setImageSelected(false);
    setImgUrl('');
    setimgObj([]);
    setImageError(true);
    setImageErrorMsg('Image is required');
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Box
        component="form"
        className="featured-item-main-container common-form-container"
        sx={{ margin: 'auto', width: '100%', padding: '0 2rem', mt: 2 }}
        onSubmit={(e) => confirmAddFeaturedItem(e)}
      >
        {/* <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start">

          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

              <Box
                onClick={() => handleCloseDetails()}
                className="back-arrow-close"> */}
        {/* left arrow icon */}
        {/* <ArrowLeft sx={{mt:3}} />
              </Box>
            </Box>
          </Grid> */}
        {/* <Grid item>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}> */}
        {/* Edit Button */}
        {/* <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                className="btn-primary featured-btn"
                type='submit'
              >
                Save
              </Button>

            </Box>

          </Grid>

        </Grid> */}

        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleEditClientClose()}
                className="back-arrow-close featured-item-back-arrow"
              >
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Typography className="header-blue-32">
                Edit Featured Item
              </Typography>
              {/* <Typography
                className="featured-add-header"
              // style={{ marginTop: '-5px', marginLeft: '5px' }}
              >
                Edit Featured Item
              </Typography> */}
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                type="submit"
                className="featured-btn"
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid className="">
          <Grid container spacing={4} columns={12}>
            <Grid item xs={12} sm={6} mt={2}>
              <TextField
                fullWidth
                label="TITLE"
                name="title"
                onChange={handleInputChange}
                className="featured-grid-item required-field input-field-styling"
                helperText={titleError}
                value={title}
                InputLabelProps={{
                  className: 'form-field-label required-label form-field-label'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={2}>
              <TextField
                fullWidth
                label="DESCRIPTION"
                multiline
                onChange={handleInputChange}
                helperText={descriptionError}
                value={description}
                className="featured-grid-item required-field"
                rows="7"
                type="text"
                name="description"
                InputLabelProps={{
                  className: 'form-field-label required-label form-field-label'
                }}
              />
            </Grid>
          </Grid>

          <Grid
            className="feature-add-adjust"
            container
            spacing={4}
            columns={12}
            mt={-10}
          >
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                className="select-drop-down-label-styling required-field"
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  SELECT ITEM TO INCLUDE IN FEATURED
                </InputLabel>
                <Select
                  value={itemType} // Bind the value to the item type state
                  name="itemType"
                  onChange={handleInputChange}
                  label="SELECT ITEM TO INCLUDE IN FEATURED"
                  className="input-field-styling"
                >
                  <MenuItem onClick={SelectBundle} value="Bundles">
                    Bundle
                  </MenuItem>
                  <MenuItem onClick={SelectMembership} value="Memberships">
                    Membership
                  </MenuItem>
                  <MenuItem onClick={SelectContract} value="Contracts">
                    Contract
                  </MenuItem>
                </Select>
                {selectItemError && (
                  <FormHelperText>{selectItemError}</FormHelperText>
                )}
                {/* {selectItemError && (
                          <FormHelperText>
                            Please select item
                          </FormHelperText>
                        )} */}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            className="feature-add-adjust"
            container
            spacing={4}
            columns={12}
            mt={1}
          >
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                name="location"
                className="select-drop-down-label-styling required-field"
                disabled
              >
                <InputLabel
                  id="demo-simple-select-helper-label"
                  className="input-field-dropdown-styling required-label"
                >
                  LOCATION
                </InputLabel>
                <Select
                  // labelId="demo-simple-select-helper-label"
                  // id="demo-simple-select-helper"
                  value={isLocationid}
                  label="LOCATION"
                  name="locationId"
                  className="input-field-styling"
                  onChange={(e) => handleLocationChange(e.target.value)}
                >
                  {locationData.map((i) => (
                    <MenuItem key={i.locationId} value={i.locationId}>
                      {i.locationName}
                    </MenuItem>
                  ))}
                </Select>
                {locationIdError && (
                  <FormHelperText>Please select location</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={10} md={10}>
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                className={`${imageSelected ? null : 'mt-40'}`}
              >
                {imageSelected ? (
                  <Box className="relative">
                    <Avatar
                      className="image-avatar"
                      src={imgUrl}
                      sx={{
                        marginTop: '40px',
                        height: '165px',
                        width: '165px',
                        borderRadius: 'none',
                        marginRight: '30px'
                      }}
                    />
                    <div
                      className="cross-imageicon text-blue"
                      role="button"
                      onClick={() => removeImage()}
                      onKeyDown={() => removeImage()}
                      tabIndex={0}
                    >
                      x
                    </div>
                  </Box>
                ) : (
                  // <div className="ellipes d-flex justify-content-center align-items-center">
                  //   <Grid className="image-tag">
                  //     <AddchartIcon className='font=35' />
                  //   </Grid>
                  // </div>
                  <PictureSnapshot />
                )}
                <Box
                  // className={`${imageSelected ? 'mt-40' : null} select-image`}
                  className={`required-field ${
                    imageSelected ? 'mt-40' : null
                  } select-image`}
                  sx={{
                    marginLeft: '50px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {/* <Typography className="add-picture-text weight-700 text-black font-16"> */}
                  <Typography className="add-picture-text weight-700 text-black font-16 required-label">
                    Upload Featured Image
                  </Typography>
                  <input
                    accept="image/*"
                    multiple
                    type="file"
                    id="select-file"
                    name="profileImage"
                    style={{ display: 'none' }}
                    onChange={(event) => handleImageUploadfeatured(event)}
                  />
                  <label
                    htmlFor="select-file"
                    style={{ width: '85px', marginTop: '24px' }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      component="span"
                      className="upload-image image-with-txt common-button"
                      sx={{ marginTop: '0px !important' }}
                    >
                      <Typography className="upload-text">Upload</Typography>
                    </Button>
                  </label>
                </Box>
              </Box>
              {imageError && <FormHelperText>{imageErrorMsg}</FormHelperText>}
            </Grid>
          </Grid>
          {showMessage && (
            <ErrorMessage
              message={errorMessage}
              setShowMessage={setShowMessage}
            />
          )}
          {showData === 'Bundles' && (
            <BundleTable
              setSelectItem={setSelectItem}
              bundlesList={bundlesList}
              getBundle={getBundle}
              setFiltersParams={setFiltersParams}
              selectItem={selectItem}
            />
          )}
          {showData === 'Memberships' && (
            <MembershipTable
              setSelectItem={setSelectItem}
              membershipList={membershipList}
              getMembership={getMembership}
              setFiltersMemberParams={setFiltersMemberParams}
              selectItem={selectItem}
            />
          )}
          {showData === 'Contracts' && (
            <ContractTable
              setSelectItem={setSelectItem}
              contractList={contractList}
              getContract={getContract}
              setFiltersContractParams={setFiltersContractParams}
              selectItem={selectItem}
            />
          )}
        </Grid>
      </Box>
      {/* {showData === 'Bundle'  && <BundleTable  />} */}
      {/* <Dialog
                open={open}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClosePopup('Cancel')}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="alert-title align-center">
                    Discard Changes?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-slide-description"
                        className="alert-decription align-center"
                    >
                        <Box display="flex" justifyContent="center" direction="column">
                            <Typography className="title-text-sub">
                                You have unsaved changes, what do you want
                                <Typography className="title-text-sub">to do?</Typography>
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="alert-button-control">
                    <Button
                        className="font-17 text-black no-hover"
                        onClick={() => handleClosePopup('Discard')}
                    >
                        Discard Changes
                    </Button>
                    <Divider className="vertical-divider" />

                    <Button
                        className="font-17 no-hover"
                        onClick={() => handleClosePopup('Save')}
                    >
                        Go back to Editing
                    </Button>
                </DialogActions>
            </Dialog>
        </> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes. 
                  <Typography className="title-text-sub">What do you want to do?</Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Featured Item has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClosePopup('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmPopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
               Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmPopup('Discard')}
          >
            Go Back to Editing
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmPopup('Save')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeaturedItemEdit;
