import React  from 'react';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';

export const CustomDate = (props) => {
    const {
        label,
        value,
        name,
        onChange,
        required,
        disabled,
        disablePast,
        disableFuture,
        inputFormat,
        fullWidth,
        minDate,
        maxDate,
        error,
        helperText
    } = props;
  const handleDateChange = (value) => {
      onChange(name, value);
  };
    return (
        <div className={`${fullWidth && 'fullWidth-date'}`}>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker

                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputFormat={inputFormat}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    disabled={disabled}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            required={required}
                            fullWidth={fullWidth}
                            error={error}
                            helperText={helperText}
                            className={`${error && 'helper-error'}`}
                        />
                    )}
                />
            </LocalizationProvider>
        </div>
    );
};