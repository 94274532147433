import React, { forwardRef, useState, useEffect } from 'react';
import {
  Slide,
  DialogActions,
  Typography,
  Button,
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import {
  CustomSelect,
  CustomTextField,
  CustomButton,
  Toggle
} from 'src/components/Global';
import { getLocalData, setFullLocationId } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const AddBenefits = (props) => {
  const {
    isEdit,
    dialog,
    setDialog,
    benefit,
    setBenefit,
    benefitsList,
    setBenefitsList,
    setFieldUpdated,
    selectedIndex,
    setIsEditBenefitsList,
    setbenefitError
  } = props;

  const BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;

  const [allCategories, setAllCategories] = useState([]);
  const [allPricingOptions, setAllPricingOptions] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allPricing, setAllPricing] = useState([]);
  const locationId = setFullLocationId(getLocalData('locationId'));
  const encodedLocationId = encodeURIComponent(locationId);
  // below variables is used on the Benifits Popup for select All pricing options and prodcuts
  const [applyToAllPricingOption, setApplyToAllPricingOption] = useState(false);
  const [applyToAllProduct, setApplyToAllProduct] = useState(false);
  const [isItemExists, setisItemExists] = useState('');

  const [isError, setIsError] = useState({
    itemId: false,
    pricingOptionId: false,
    itemCategoryId: false,
    discount: false
  });

  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const clearValues = () => {
    setIsError({
      itemId: false,
      pricingOptionId: false,
      itemCategoryId: false,
      discount: false
    });
  };

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [discountError, setDiscountError] = useState('');

  const handleDialogClose = () => {
   setisItemExists('');
   setDiscountError('');
    setApplyToAllPricingOption(false);
      setApplyToAllProduct(false);
    setShowConfirmationDialog(true);
  };

  const getOptionLabel = (option) => {
    const { fName, variants } = option;
  
    if (variants && variants?.filter((variant) => variant?.value)?.length === 0) {
      return `${fName}`;
    }
    if (variants && variants?.filter((variant) => variant?.value)?.length > 0) {
      const variantLabels = variants?.filter((variant) => variant?.value)?.map(v => v?.value);
      const variantLabel = variantLabels?.length === 1
        ? `${fName} (${variantLabels[0]})`
        : `${fName} (${variantLabels?.join(', ')})`;
  
      return variantLabel;
    }
    return `${fName}`
  }

  const handleCancelConfirmation = () => {
    // Close the confirmation dialog
    clearValues();
    setBenefit({
      itemType: "PricingOption",
      discount: 0,
      itemId: "",
      itemName: "",
      itemCategoryId: "",
      itemCategoryName: "",
    });

    // Close the confirmation dialog
    setShowConfirmationDialog(false);
    setDialog({ ...dialog, benefit: false });
    if (setIsEditBenefitsList) {
      setIsEditBenefitsList(false);
    }
  };

  const handleConfirmClose = () => {
    // Close both the main dialog and the confirmation dialog
    setShowConfirmationDialog(false);
  };

  // function getValueFromId(id, array, matchValue, returnValue) {
  //   const matchedObject = array.find((obj) => obj[matchValue] === id);
  //   return matchedObject ? matchedObject[returnValue] : '';
  // }

  const handleBenefit = (type, value) => {
    console.log(type, value, 'valuevaluevalue')
    setisItemExists('');
    if (type === 'itemType') {
      clearValues();
      benefit.discount = 0;
      setApplyToAllPricingOption(false);
      setApplyToAllProduct(false);
      setBenefit({
        ...benefit,
        itemType: value,
      });
    } else {
      setIsError({ ...isError, [type]: false });
      if (type === 'itemId') {
        setBenefit({
          ...benefit,
          itemId: value.split('|')[1],
          itemName: value.split('|')[0]
          // discount: filterSingleData?.discount,       
        });
      } else if (type === 'itemCategoryId') {
        console.log(type, 'typetypetypetype')
        benefit.itemId = '';
        benefit.itemName = ''; 
        setBenefit({
          ...benefit,
          itemType: 'Product',
          itemCategoryId: value.split('|')[1],
          itemCategoryName: value.split('|')[0],
          //  product: ''
        });
      }
      // else if (type === 'pricingOptionId') {
      //   const filterSingleData = allPricing?.find((item) => {
      //     return item.sortKey === value.split('|')[1];
      //   });
      //   setBenefit({
      //     ...benefit,
      //     pricingOptionId: value.split('|')[1],
      //     pricingOptionName: value.split('|')[0],
      //     pricingOption: filterSingleData
      //   });
      // }
      else if (type === 'product') {
        setBenefit({
          ...benefit,
          // product: productObject
          itemId: value.split('|')[1],
          itemName: value.split('|')[0]
        });
      } else if (type === 'discount') {
        const numericValue = parseFloat(value);
        
        if (numericValue < 0) {
          setDiscountError('Discount cannot be negative.');
        } else if (numericValue > 100) {
          setDiscountError('Discount cannot be greater than 100.');
        } else {
          setDiscountError('');
          setBenefit({
            ...benefit,
            discount: parseInt(numericValue)
          });
        }
      } else {
        setBenefit({ ...benefit, [type]: value });
      }
    }
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};
    // Check additional values based on conditions
    const { itemType, itemId, pricingOptionId, itemCategoryId, discount } = benefit;
    console.log(itemId, 'datatatatat')
    if (itemType === 'PricingOption') {
      if (!applyToAllPricingOption) {
        const pricingOptionIsEmpty = itemId === '';
        const pricingIsEmpty = pricingOptionId === '';
        updatedErrors['itemId'] = pricingOptionIsEmpty;
        updatedErrors['pricingOptionId'] = pricingIsEmpty;
        if (pricingOptionIsEmpty || pricingIsEmpty) {
          hasError = true;
        }
      }
      // const discountIsEmpty = discount === 0 || '';
      const discountIsEmpty = discount === 0 || Number.isNaN(discount);
      updatedErrors['discount'] = discountIsEmpty;
      if (discountIsEmpty) {
        hasError = true;
      }
    } else if (itemType === 'Product') {
      if (!applyToAllProduct) {
        const categoryIsEmpty = itemCategoryId === '';
        updatedErrors['itemCategoryId'] = categoryIsEmpty;
        if (categoryIsEmpty) {
          hasError = true;
        }
      }   
      // const discountIsEmpty = discount === 0 || ''; 
      const discountIsEmpty = discount === 0 || Number.isNaN(discount);     
      updatedErrors['discount'] = discountIsEmpty;
      if (discountIsEmpty) {
        hasError = true;
      }
    }

    setIsError(updatedErrors);
    return hasError;
  }

  const handleCloseDialog = () => {
    setDiscountError('');
    const { itemType, itemId, itemCategoryId } = benefit; // itemCategoryId, discount
    let isItemIdExists;
    let message;
    if (itemType === 'PricingOption' && !isEdit) {
      if (applyToAllPricingOption) {
        isItemIdExists = benefitsList.some(benefit => benefit.itemType === 'PricingOption');
        message = 'You have already included the selected pricing options in the benefits. To proceed, please remove the existing one before adding it again.';
      } else {
        isItemIdExists = benefitsList.some(benefit => benefit.itemId === "applyToAllPricingOption");
        message = 'You have already included the selected pricing options in the benefits. To proceed, please remove the existing one before adding it again.';
        if (!isItemIdExists) {
          isItemIdExists = benefitsList.some(benefit => benefit.itemId === itemId);
          message = 'You have already included the selected pricing options in the benefits. To proceed, please remove the existing one before adding it again.';
        }
      }

    }
    if (itemType === 'Product' && !isEdit) {
      if (applyToAllProduct) {
        isItemIdExists = benefitsList.some(benefit => benefit.itemType === 'Product');
        message = 'You have already included the selected product category or product in benefits. To proceed, please remove the existing one before adding it again.';
      } else {
        let allprodCheck = benefitsList.some(benefit => benefit.itemId === "applyToAllProduct");
        if(benefitsList.some(benefit => benefit.itemId === "applyToAllProduct")){
          let catCheck = benefitsList.some(benefit => benefit.itemCategoryId === 'applyToAllProduct');
          if(catCheck) message = 'You have already included the selected product category or product in benefits. To proceed, please remove the existing one before adding it again.';    
             if(catCheck) isItemIdExists = true;  
        }else {
          if(allprodCheck) message = 'You have already included the selected product category or product in benefits. To proceed, please remove the existing one before adding it again.';
          if(itemId === '' || benefitsList.some(benefit => benefit.itemId === '')) {
             let catCheck = benefitsList.some(benefit => benefit.itemCategoryId === itemCategoryId);
             if(catCheck) message = 'You have already included the selected product category or product in benefits. To proceed, please remove the existing one before adding it again.';    
             if(catCheck) isItemIdExists = true;        
           } else {
             let catCheck = benefitsList.some(benefit => benefit.itemCategoryId === itemCategoryId);
             if(catCheck) message = 'You have already included the selected product category or product in benefits. To proceed, please remove the existing one before adding it again.';
             let itemCheck = benefitsList.some(benefit => benefit.itemId === itemId);
             if(itemCheck) message = 'You have already included the selected product category or product in benefits. To proceed, please remove the existing one before adding it again.';
             if(catCheck && itemCheck) isItemIdExists = true;
           }          
         }
        }
        
    }
    if (!checkForEmptyValues()) {
      setisItemExists('');
      if (isItemIdExists) {
        setisItemExists(message);
      } else {
       setisItemExists('');
       setOpenConfirmation(true);
      }
    }
  };

  const handleConfirmPopup = (val) => {
    if (val === 'Save') {
      setApplyToAllProduct(false);
      setApplyToAllPricingOption(false);
      setOpenConfirmation(false);
      setbenefitError('');
      handleAddBenefit();
    } else {
      setisItemExists('');
    //  setApplyToAllProduct(false);
    //  setApplyToAllPricingOption(false);
      setOpenConfirmation(false);
    }
  };

  const handleAddBenefit = async () => {
    // if (!checkForEmptyValues()) {
    const newBenefit = { ...benefit };
    if (newBenefit.itemType === 'Product' && !applyToAllProduct) {
      console.log('newBenefit-->', newBenefit);
      console.log('applyToAllProduct------>', applyToAllProduct);
      // newBenefit.product = [
      //   {
      //     id: `${benefit.product[0]?.id || ''}`,
      //     name: await getValueFromId(
      //       benefit.product[0]?.id || '',
      //       allProducts,
      //       'sortKey',
      //       'fName'
      //     )
      //   }
      // ];        
    } else if (newBenefit?.itemType?.trim() === "PricingOption" && !applyToAllPricingOption) {
      console.log('applyToAllPricingOption------>', applyToAllPricingOption);
      const filterSingleData = await allPricing?.find((item) => {
        return item?.pricingOptionId === newBenefit?.itemId?.trim();
      });
      newBenefit.itemCategoryId = filterSingleData?.categoryId ?? '';
      newBenefit.itemCategoryName = filterSingleData?.categoryName ?? '';
      newBenefit.price = filterSingleData?.price ?? 0;
      newBenefit.creditsValidityStarts = filterSingleData?.creditsValidityStarts ?? '';
      newBenefit.numberOfSessions = filterSingleData?.numberOfSessions ?? 0;
      newBenefit.sellOnline = filterSingleData?.sellOnline ?? false;
      newBenefit.free = filterSingleData?.free ?? false;
      newBenefit.sharable = filterSingleData?.sharable ?? false;
      newBenefit.restricted = filterSingleData?.restricted ?? false;
      newBenefit.validThroughStartDate = filterSingleData?.validThroughStartDate ?? '';
      newBenefit.validThroughEndDate = filterSingleData?.validThroughEndDate ?? '';
      newBenefit.services = filterSingleData?.services ?? [];
      newBenefit.schedulingLimitation = filterSingleData?.schedulingLimitation ?? {};
      newBenefit.setTimeRange = filterSingleData?.setTimeRange ?? false;
      newBenefit.startTime = filterSingleData?.startTime ?? '';
      newBenefit.endTime = filterSingleData?.endTime ?? '';
      newBenefit.creditsValidityExpires = filterSingleData?.creditsValidityExpires ?? {}
    } else if (newBenefit?.itemType?.trim() === "PricingOption" && applyToAllPricingOption) {
      newBenefit.itemCategoryId = 'applyToAllPricingOption';
      newBenefit.itemCategoryName = 'applyToAllPricingOption';
      newBenefit.itemId = 'applyToAllPricingOption';
      newBenefit.itemName = 'all pricing options';
      newBenefit.creditsValidityStarts = 'applyToAllPricingOption';
      newBenefit.numberOfSessions = 1;
    } else if (newBenefit.itemType === 'Product' && applyToAllProduct) {
      newBenefit.itemCategoryId = 'applyToAllProduct';
      newBenefit.itemCategoryName = 'applyToAllProduct';
      newBenefit.itemId = 'applyToAllProduct';
      newBenefit.itemName = 'all products';
    }
    if (isEdit) {
      setBenefitsList((prevList) => [
        ...prevList.slice(0, selectedIndex),
        newBenefit,
        ...prevList.slice(selectedIndex + 1)
      ]);
      // setIsEditBenefitsList(false);
    } else {
      setBenefitsList((prevList) => [...prevList, newBenefit]);
    }

    setFieldUpdated(true);
    // Close the confirmation dialog
    setShowConfirmationDialog(false);
    setDialog({ ...dialog, benefit: false });
    clearValues();
    setBenefit({
      itemType: "PricingOption",
      discount: 0,
      itemId: "",
      itemName: "",
      itemCategoryId: "",
      itemCategoryName: "",
    });
    // }
  };

  // ===================Fetch Api=================================

  const fetchCategories = async () => {
    try {
      const categoriesResponse = await ApiService.get(
        `${BASE_URL}memberships/getProductCategory?locationId=${encodedLocationId}&status=1`
      );
      if (!categoriesResponse?.data?.error) {
        setAllCategories(categoriesResponse?.data?.Items);
      } else {
        setShowMessage(true);
        setErrorMessage(
          categoriesResponse.message ||
          'Something went wrong with membership categories'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const getPricingOptions = async () => {
    try {
      const PricingOptionResponse = await ApiService.get(
        `${BASE_URL}memberships/getAllPricingOptions?locationId=${encodedLocationId}&status=1`
      );
      if (!PricingOptionResponse?.data?.error) {
        setAllPricingOptions(PricingOptionResponse?.data?.Items || []);
      } else {
        setShowMessage(true);
        setErrorMessage(
          PricingOptionResponse.message ||
          'Something went wrong with memberships Pricing options'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const fetchProducts = async (categoryName) => {
    try {
      const productsResponse = await ApiService.get(
        `${BASE_URL}memberships/getProductByCategory?locationId=${encodedLocationId}&categoryName=${categoryName}&status=1&isDiscontinued=false`
      );

      if (!productsResponse?.data?.error) {
        setAllProducts(productsResponse?.data?.Items);
      } else {
        setShowMessage(true);
        setErrorMessage(
          productsResponse.message || 'Something went wrong with products'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const pricingOptions = async () => {
    try {
      const pricingResponse = await ApiService.get(
        `${BASE_URL}memberships/getAllPricingOptions?locationId=${encodedLocationId}&status=1`
      );

      if (!pricingResponse?.data?.error) {
        setAllPricing(pricingResponse?.data?.Items);
      } else {
        setShowMessage(true);
        setErrorMessage(
          pricingResponse.message || 'Something went wrong with Pricing options'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  useEffect(() => {
    fetchCategories();
    getPricingOptions();
  }, []);

  useEffect(() => {
    // fetch products options whenever category option changes
    if (benefit.itemCategoryName !== '') {
      // let id = encodeURIComponent(benefit.categoryId);
      fetchProducts(benefit.itemCategoryName);
    }
  }, [benefit.itemCategoryName]);

  useEffect(() => {
    // fetch pricing options whenever service option changes
    if (benefit.itemId !== '' && benefit.itemType === 'PricingOption') {
      let id = encodeURIComponent(benefit.itemId);
      pricingOptions(id);
    }
  }, [benefit.itemId]);

  useEffect(() => {
    console.log('benefitsList', benefitsList);
  }, [benefitsList]);

  const allPOChange = (type, value) => {
    benefit.itemId = '';
    benefit.itemName = ''; 
    setApplyToAllPricingOption(value);
  }

  const allPRODChange = (type, value) => {
    benefit.itemCategoryName ='';
    benefit.itemName = '';
    setApplyToAllProduct(value);
  }

  useEffect(() => {
    // fetch pricing options whenever service option changes
    if (benefit?.itemCategoryId === 'applyToAllPricingOption') {
      setApplyToAllPricingOption(true);
    }else if(benefit?.itemCategoryId === 'applyToAllProduct'){
      setApplyToAllProduct(true);
    }
    
  }, [benefit.itemCategoryId]);

  return (
    <div>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      <Dialog
        open={dialog.benefit}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right "
        maxWidth="xs"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={handleDialogClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ position: 'relative' }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box>
              <Grid container spacing={2} columns={12} mt={0}>
                <Grid item xs={12} display="flex" justifyContent="flex-start">
                  <Typography
                    variant="variant5"
                    textAlign="left"
                    className="header-blue-24"
                  >
                    {isEdit ? 'Edit Benefits' : 'Add Benefits'}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="flex-start"
                  paddingTop="4px !important"
                  paddingBottom="12px"
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 32,
                          color: '#003087'
                        },
                        '& .MuiFormControlLabel-label': {
                          color: '#000000',
                          fontWeight: '600'
                        },
                        '& .MuiFormControlLabel-root': {
                          marginRight: '40px'
                        }
                      }}
                    >
                      <FormControlLabel
                        value="PricingOption"
                        control={<Radio />}
                        label="Pricing Options"
                        checked={benefit.itemType === 'PricingOption'}
                        onChange={() => handleBenefit('itemType', 'PricingOption')}
                        disabled={isEdit}
                      />
                      <FormControlLabel
                        value="product"
                        control={<Radio />}
                        label="Products"
                        checked={benefit.itemType === 'Product'}
                        onChange={() => handleBenefit('itemType', 'Product')}
                        disabled={isEdit}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <div className="custom-form">
                {benefit.itemType === 'PricingOption' ? (
                  <Grid container spacing={2} columns={12}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className="sell-online-txt"
                          style={{
                            fontWeight: '900',
                            fontSize: '16px',
                            marginLeft: '10px'
                          }}
                        >
                          <b>All Pricing Options</b>
                        </Typography>
                        <Box  >
                          <Toggle
                            name="applyToAllPricingOption"
                            checked={applyToAllPricingOption}
                            value={applyToAllPricingOption}
                            onChange={allPOChange}
                            disabled={isEdit}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomSelect
                        fullWidth
                        name="itemId"
                        className="required-field"
                        value={
                          benefit.itemId
                            ? benefit.itemName + '|' + benefit.itemId
                            : ''
                        }
                        disabled={applyToAllPricingOption || isEdit}
                        onChange={handleBenefit}
                        label="SELECT PRICING OPTIONS"
                        error={isError.itemId}
                        helperText={isError.itemId && 'Pricing Option is required'}
                        options={allPricingOptions?.map((item) => ({
                          label: item.name,
                          value: item.name + '|' + item.sortKey
                        }))}
                        InputLabelProps={{
                          className: 'required-label'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        fullWidth
                        type="number"
                        name="discount"
                        label="DISCOUNT (%)"
                        min={0}
                        required={Boolean(true)}
                        value={benefit.discount}
                        onChange={handleBenefit}
                        error={isError.discount}
                        helperText={isError.discount && 'Discount is required'}
                        
                      />
                    </Grid>
                    {discountError && (
                    <FormHelperText error>{discountError}</FormHelperText>
                  )}
                    {/* Commented below as 
                    <Grid item xs={12}>
                      <CustomSelect
                        fullWidth
                        name="pricingOptionId"
                        value={
                          benefit.pricingOption.sortKey
                            ? benefit.pricingOption.name +
                            '|' +
                            benefit.pricingOption.sortKey
                            : ''
                        }
                        onChange={handleBenefit}
                        label="ASSIGN PRICING OPTION"
                        disabled={benefit.itemId === ''}
                        error={isError.pricingOptionId}
                        helperText={
                          isError.pricingOptionId &&
                          'Pricing option is required'
                        }
                        options={allPricing?.map((item) => ({
                          label: item.name,
                          value: item.name + '|' + item.pricingOptionId
                        }))}
                      />
                    </Grid> */}
                  </Grid>
                ) : (
                  <Grid Grid container spacing={2} columns={12}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className="sell-online-txt"
                          style={{
                            fontWeight: '900',
                            fontSize: '16px',
                            marginLeft: '10px'
                          }}
                        >
                          <b>All Products</b>
                        </Typography>
                        <Box  >
                          <Toggle
                            name="applyToAllProduct"
                            checked={applyToAllProduct}
                            value={applyToAllProduct}
                            onChange={allPRODChange}
                            disabled={isEdit}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomSelect
                        fullWidth
                        name="itemCategoryId"
                        value={
                          benefit.itemCategoryId
                            ? benefit.itemCategoryName +
                            '|' +
                            benefit.itemCategoryId
                            : ''
                        }
                        onChange={handleBenefit}
                        label="SELECT PRODUCT CATEGORY"
                        className="required-field"
                        error={isError.itemCategoryId}
                        helperText={
                          isError.itemCategoryId && 'Category is required'
                        }
                        options={allCategories?.map((item) => ({
                          label: item.categoryName,
                          value: item.categoryName + '|' + item.categoryId
                        }))}
                        disabled={applyToAllProduct || isEdit}
                        InputLabelProps={{
                          className: 'required-label'
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CustomSelect
                        fullWidth
                        name="product"
                        value={
                          benefit.itemId
                            ? benefit.itemName + '|' + benefit.itemId
                            : ''
                        }
                        onChange={handleBenefit}
                        label="SELECT PRODUCT (OPTIONAL)"
                        // disabled={benefit.itemCategoryId === ''}
                        disabled={applyToAllProduct || benefit.itemCategoryId === '' || isEdit}
                        options={allProducts?.map((item) => ({
                          label: getOptionLabel(item),
                          value: item.fName + '|' + item.sortKey
                        }))}
                        
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CustomTextField
                        fullWidth
                        type="number"
                        name="discount"
                        label="DISCOUNT (%)"
                        min={0}
                        required={Boolean(true)}
                        value={benefit.discount}
                        onChange={handleBenefit}
                        error={isError.discount}
                        helperText={isError.discount && 'Discount is required'}
                      />
                    </Grid>
                    {discountError && (
                    <FormHelperText error>{discountError}</FormHelperText>
                  )}
                  </Grid>
                  
                )}
                 {isItemExists && (
                    <FormHelperText error>{isItemExists}</FormHelperText>
                  )}
                <Grid container>
                  <Grid
                    item
                    position="absolute"
                    bottom="20px"
                    width="calc(100% - 48px)"
                    className="fullwidth-btn"
                  >
                    <CustomButton
                      fullWidth
                      variant="contained"
                      onClick={handleCloseDialog}
                    >
                      {isEdit ? 'Update' : 'Add Now'}
                    </CustomButton>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
      </Dialog>

      {/* Discard Pop up for benifits */}
      <Dialog
        open={showConfirmationDialog}
        onClose={handleCancelConfirmation}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Typography className="title-text-sub">
              You have unsaved changes.
              <Typography className="title-text-sub"> What do you want to do?</Typography>
            </Typography>
            
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            onClick={handleCancelConfirmation}
            className="font-17 text-black no-hover"
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button onClick={handleConfirmClose} className="font-17 no-hover">
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        TransitionComponent={Transition}
        // keepMounted
        onClose={() => handleConfirmPopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You are about to save the changes,
                <Typography className="title-text-sub">
                  Do you want to continue?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmPopup('Discard')}
          >
            Discard
          </Button>
         <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmPopup('Save')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
