import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

export default function dynamicDateField({
  setDateValue,
  dateValue,
  label,
  name
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        name={name}
        value={dateValue}
        onChange={(newValue) => setDateValue(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            id="outlined-required"
            className="text-field-styling"
            sx={{ marginTop: '20px' }}
            InputLabelProps={{
              className: 'label-style-text-field'
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
