/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Checkbox,
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const EnrollFilter = ({ setFilters, filters, data }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    store: false
  });

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      store: field === 'store'
    }));
  };

  const handleChangeFilter = (value, type) => {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      isActive: null,
      store: null
    });

    setOpenFilter({
      store: false
    });
  };

  return (
    <>
      <Card className="filter-content custom-form ">
      <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
             <Grid item>
              <Typography className="filter-label" pt={2} pb={1}>
                Type
              </Typography>
            </Grid>
             <Grid item onClick={clearFilters}
             padding = "16px"
             >
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
           
        
       </Grid>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.isActive ?? ''}
            onChange={(event) =>
              handleChangeFilter(event.target.value, 'isActive')
            }
            label="Status"
            className="status-field field-label-css"
          >
            <MenuItem value={null}>All</MenuItem>
            <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem>
          </Select>
        </FormControl>

         <Divider />

       {/* <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

       <Grid
          xs={12}
          onClick={() => handleOpenFilter('store')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.store && classes.closeY,
                    openFilter.store && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Store</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.store && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              {data?.category?.length ? (
                <>
                  <InputLabel>Select Store</InputLabel>
                  <Select
                    onChange={(event) =>
                      handleChangeFilter(event.target.value, 'store')
                    }
                    value={filters.store}
                    sx={{
                      width: '100%'
                    }}
                    label="Select Store"
                    className="status-field field-label-css"
                  >
                    {data?.category?.map((item) => (
                      <MenuItem id={item.categoryId} value={item.store}>
                        {item.store}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <Typography pl={3}>No Store available</Typography>
              )}
            </FormControl>
          </Box>
        )} */}
      </Card>
    </>
  );
};
