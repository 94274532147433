/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CustomDatePicker } from './CustomDatePicker';

export function MonthPicker({ activeFilters, setActiveFilters }) {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');

  // in api dummy data, there is no record for this month 05,2023
  // so i used 04-2023. when site will be live uncomment below code
  // so that  current month will bydefault select

 useEffect(() => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    setSelectedDate(date);
    setSelectedMonth(month);
    setSelectedYear(year);
  }, []);

  const setDate = (sdate) => {
    let newDate = new Date(sdate);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    setSelectedDate(date);
    setSelectedMonth(month);
    setSelectedYear(year);
  }

  const handleNextMonth = () => {
    activeFilters['date'] = activeFilters?.date.replaceAll('-', '/')
    let nextDate = new Date(activeFilters?.date.replace(/-/g, "/"));
    if (activeFilters?.time === "Week") {
      nextDate.setDate(nextDate.getDate() + 1 * 7);
    } else {
      nextDate.setMonth(nextDate.getMonth() + 1);
    }
    setDate(nextDate);
    handleDateChange(nextDate)
  };

  const handlePreviousMonth = () => {
    activeFilters['date'] = activeFilters?.date.replaceAll('-', '/')
    let prevDate = new Date(activeFilters?.date.replace(/-/g, "/"));
    if (activeFilters?.time === "Week") {
      prevDate.setDate(prevDate.getDate() - 1 * 7);
    } else {
      prevDate.setMonth(prevDate.getMonth() - 1);
    }
    setDate(prevDate);
    handleDateChange(prevDate)
  };

  const handleDateChange = (value) => {
    const date  = new Date(value);
    const day   = date.getDate();
    const year  = date.getFullYear();
    const month = date.getMonth() + 1;
    setActiveFilters({ ...activeFilters, date: `${month}-${day}-${year}` });
  };

  useEffect(() => {
    const seldDate = `${selectedMonth}-${selectedDate}-${selectedYear}`;
    setActiveFilters({ ...activeFilters, date: seldDate });
  }, [selectedDate, selectedMonth, selectedYear]);

  const years = Array.from({ length: 50 }, (_, index) => 2001 + index);


  return (
   <Grid container className="filter-grid" sx={{ justifyContent: "center" }}>
     <div
        className={`month-picker`}
      >
                <CustomDatePicker
          // label="Select your birthday"
          currentDate={activeFilters?.date.replaceAll("-", "/")}
          onChange={handleDateChange}
          filterType={activeFilters?.time}
        />
        {/* <Box sx={{ position: "relative", display: "inline-block" }}>
<Typography
  variant="h6"
    className="selected-month"
    onClick={() => setIsMonthSelectorOpen(!isMonthSelectorOpen)}
    >
  {selectedMonth && selectedYear
      ? `${activeFilters?.time === "Week" ? selectedDate : ''} ${months[Number(selectedMonth) - 1]}, ${selectedYear}`
     : "Select Month & Year"}
      {isMonthSelectorOpen ? (
      <KeyboardArrowUpIcon fontSize="small" />
      ) : (
      <KeyboardArrowDownIcon fontSize="small" />
      )}
    </Typography>
  {isMonthSelectorOpen && !isYearSelectorOpen && (
    <Buttons
    variant="outlined"
      onClick={handleYearSelectorClick}
      className="year-btn"
     >
    Select Year
      </Buttons>
    )}
  {isYearSelectorOpen && (
    <Grid
    container
      sx={{
        position: "absolute",
        top: "100%",
        maxHeight: "150px",
        overflow: "auto",
        zIndex: 50,
       width: "250px",
        background: "#ffffff",
       padding: "8px 0 12px 16px",
        marginTop: "8px",
      }}
      >
    {years.map((year) => (
        <Grid item xs={4} key={year}>
        <Typography
          onClick={() => handleYearClick(year)}
            sx={{
              cursor: "pointer",
              textAlign: "center",
              marginBottom: "4px",
              color: selectedYear === year ? "#fff" : "#000",
              backgroundColor:
              selectedYear === year ? "#003087" : "#fff",
              }}
            >
          {year}
            </Typography>
          </Grid>
      ))}
     </Grid>
    )}
  </Box>
{isMonthSelectorOpen && !isYearSelectorOpen && selectedYear && (
  <Grid container className="month-popup">
  {months.map((month, index) => (
      <Grid item xs={4} key={index}>
      <Typography
        onClick={() => handleMonthClick(index + 1)}
          sx={{
            cursor: "pointer",
            textAlign: "center",
            marginBottom: "4px",
            color:
            selectedMonth === (index + 1).toString().padStart(2, "0")
             ? "#fff"
                : "#000",
                backgroundColor:
            selectedMonth === (index + 1).toString().padStart(2, "0")
              ? "#003087"
                : "#fff",
              }}
          >
        {month.slice(0, 3)}
          </Typography>
        </Grid>
      ))}
    </Grid>
)} */}
      </div>
      <ArrowLeftIcon
       color={
          selectedYear === years[0] && selectedMonth === "01" ? "disabled" : ""
        }
       fontSize="large"
        className="filter-arrows arrow-prev"
        onClick={handlePreviousMonth}
     />
     <ArrowRightIcon
       color={
         selectedYear === years[years.length ] && selectedMonth === "12"
          ? "disabled"
            : ""
       }
       fontSize="large"
       className="filter-arrows arrow-next"
       onClick={handleNextMonth}
     />
   </Grid>
  );
}
