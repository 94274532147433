/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Avatar,
  Card,
  CardHeader,
  IconButton,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Checkbox,
  Grid
} from '@mui/material';

import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import { CustomButton, CustomSelect } from 'src/components/Global';

export const ServicesTable = (props) => {
  const {
    tableRows,
    searchQuery,
    loading,
    openDetail,
    selectedRows,
    setSelectedRows,
    selectedStatus,
    setSelectedStatus,
    handleBulkAdd,
    btnLoading
  } = props;
  const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const [tablePagination, setTablePagination] = useState({
  //   page: 0,
  //   limit: 10
  // });

  // To change the page into the table
  // const handlePageChange = (event, newPage) => {
  //   setTablePagination({ ...tablePagination, page: newPage });
  // };

  // To change the limit
  // const handleLimitChange = (event) => {
  //   setTablePagination({
  //     ...tablePagination,
  //     limit: parseInt(event.target.value)
  //   });
  // };

  // Apply paination on table
  // const applyPagination = (tableRows) => {
  //   const page = tablePagination.page;
  //   const limit = tablePagination.limit;
  //   return tableRows.slice(page * limit, page * limit + limit);
  // };

  // Apply search filter
  const applyFilters = (tableRows) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = tableRows.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = tableRows;
    }
    return arrayOfMatchedObjects;
  };

  // Apply filter from object and below is the pagination order
  // const filteredRows = applyFilters(tableRows);
  // const paginatedRows = applyPagination(filteredRows);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredData.map((n) => n.id);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows?.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows?.slice(0, selectedIndex),
        selectedRows?.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows?.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    setPage(0);
  }, [tableRows]);

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {/* <CardHeader title="All Services" className="table-header" /> */}
            {/* <h2 className="table-heading">All Services</h2> */}
            <Typography className="table-heade header-blue-20">
              {' '}
              All Services
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={7}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '12px'
            }}
            className="membership-status-container"
          >
            <CustomSelect
              fullWidth
              name="selectedStatus"
              disabled={!selectedRows?.length}
              value={selectedStatus}
              onChange={(type, value) => setSelectedStatus(value)}
              label="Select Status"
              options={[
                {
                  label: 'Activate',
                  value: 1
                },
                {
                  label: 'Deactivate',
                  value: 0
                }
              ]}
            />
            <CustomButton
              variant="contained"
              disabled={selectedStatus === '' || !selectedRows?.length}
              onClick={handleBulkAdd}
              loading={btnLoading}
            >
              Update
            </CustomButton>
          </Grid>
        </Grid>
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length < filteredData?.length
                    }
                    checked={
                      filteredData?.length > 0 &&
                      selectedRows?.length === filteredData?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all'
                    }}
                    sx={{ color: '#003087' }}
                  />
                </TableCell>
                <TableCell>Service Name</TableCell>
                <TableCell>Service Category</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Duration</TableCell>
                {/* <TableCell>Price</TableCell> */}
                <TableCell className="th-center">Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData.map((row, index) => {
                const isItemSelected = isSelected(row.sortKey);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    key={row.sortKey}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    className="table-row cursor-pointer"
                    onClick={(e) => openDetail(row.sortKey, 3, e)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                        sx={{ color: '#003087' }}
                        onClick={(event) => handleClick(event, row.sortKey)}
                      />
                    </TableCell>
                    <TableCell className="table-td">
                      <Avatar
                        alt="icon"
                        src={'/static/images/logo/cardboardicon.png'}
                        sx={{ marginRight: 2, float: 'left' }}
                        className="card-avatar-img align-self-center"
                      />

                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.categoryName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.typeOfService === 'appointment'
                          ? 'Appointment'
                          : row.typeOfService === 'enrollment'
                          ? 'Enrollment'
                          : row.typeOfService}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.duration} Minutes
                      </Typography>
                    </TableCell>
                    {/* <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.isFree ? '0' : row?.servicePrice}
                      </Typography>
                    </TableCell> */}
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        className="td-center"
                      >
                        {row.status === 1 ? (
                          // Active Status icon
                          <ActiveStatus />
                        ) : (
                          // Inactive Status icon
                          <InactiveStatus />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.sortKey}
                        onClick={(e) => openDetail(row.sortKey, 2, e)}
                      >
                        Edit
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          {/* <TablePagination
            component="div"
            count={filteredRows?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={tablePagination.page}
            rowsPerPage={tablePagination.limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          /> */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </div>
  );
};
