import { ApiService } from 'src/services/ApiService';
import { getFileFromUrl } from 'src/utils';

//   HANDLE CONFIRM FUNCTION AND PLACE ORDER API CALL start ----

const handleConfirm = async (
  buttonValue,
  componentConsts,
  payload,
  serviceFee,
  selected,
  setSelected,
  setDisableButton,
  setSuccessOrder,
  setError,
  setLoading,
  isContract,
  signature,
  isAutopay,
  zipCode,
  setErrorMessage
) => {
  setLoading(true);
  let payloadData = { ...payload };
  payloadData.serviceFee = parseFloat(serviceFee) || 0;
  payloadData.franchiseeName = componentConsts?.franchiseeName;
  payloadData.locationName = componentConsts?.locationName;
  payloadData.createdByName = componentConsts?.UserName;

  if (payload.clientId === '') {
    delete payloadData.clientId;
    delete payloadData.clientName;
  }
  // Remove Petname and PedId if they are not selected
  if (!selected?.pet) {
    delete payloadData.petId;
    delete payloadData.petName;
  }

  if (isContract?.list?.length > 0) {
    payloadData.contractId = isContract?.list?.[0]?.itemId;
    payloadData.signatureImage = signature?.value;

    
  }
  if (isAutopay) {
    payloadData.postalCode = zipCode;
  }

  try {
    let response = await ApiService.post(
      `${componentConsts?.BASE_URL}checkout/placeOrder`,
      payloadData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.statusCode === 200 && response?.data) {
      const {
        sqaureOrderId,
        squareLocationId,
        ZROrderId,
        squareSellerCustomerId,
        squareCustomerId,
        sqaureOrderTotalMoney,
        serviceFee
      } = response.data;

      // Payload for POS App start --
      const createPayload = {
        source: 'CASH',
        amount: Number(sqaureOrderTotalMoney),
        squareSellerCustomerId: squareSellerCustomerId,
        squareDeveloperCustomerId: squareCustomerId,
        squareLocationId: squareLocationId,
        squareOrderId: sqaureOrderId,
        locationId: componentConsts?.locationId,
        orderId: ZROrderId,
        paymentMode: 'CASH',
        cashDetails: {
          buyerSuppliedMoney: {
            amount: Number(sqaureOrderTotalMoney),
            currency: 'USD'
          },
          changeBackMoney: {
            amount: 0,
            currency: 'USD'
          }
        }
      };
      // Payload for POS App end --

      setSelected({
        ...selected,
        sqaureOrderId: sqaureOrderId,
        squareLocationId: squareLocationId,
        ZROrderId: ZROrderId,
        serviceFee: serviceFee
      });
      // Function Call to open POS APP
      if (sqaureOrderTotalMoney === '0' || buttonValue === 'Comp') {
        createPayment(
          createPayload,
          buttonValue,
          setLoading,
          selected,
          setSuccessOrder,
          setError,
          componentConsts
        );
        setDisableButton(true);
      } else {
        openPosApp(createPayload, selected, setSuccessOrder, componentConsts);
      }
    }
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
    setSuccessOrder(false);
    setError(error?.message || 'Order not placed something went wrong');
    setErrorMessage(error?.message || 'Order not placed something went wrong')
  }
};
//   HANDLE CONFIRM FUNCTION AND PLACE ORDER API CALL end ----

//   CREATE PAYMENT API CALL start ----
const createPayment = async (
  newPayload,
  buttonValue,
  setLoading,
  selected,
  setSuccessOrder,
  setError,
  componentConsts
) => {
  setLoading(true);
  // Define the base payload structure
  const basePayload = {
    locationId: newPayload?.locationId,
    franchiseeId: componentConsts?.franchiseeId,
    clientId: selected?.client?.clientId,
    orderId: newPayload?.orderId,
    squareOrderId: newPayload?.squareOrderId,
    amount: newPayload?.amount
  };

  // Extend the base payload based on the buttonValue
  const finalPayload =
    buttonValue === 'Comp'
      ? {
          ...basePayload,

          paymentMode: 'COMP',
          source: 'COMP',
          externalDetails: {
            type: 'EXTERNAL',
            source: 'COMP'
          }
        }
      : {
          ...basePayload,
          source: 'CASH',

          paymentMode: 'CASH',
          cashDetails: {
            buyerSuppliedMoney: {
              amount: 0,
              currency: 'USD'
            },
            changeBackMoney: {
              amount: 0,
              currency: 'USD'
            }
          }
        };

  try {
    let response = await ApiService.post(
      `${componentConsts?.BASE_URL}checkout/createPayment`,
      finalPayload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.statusCode === 200) {
      setSuccessOrder(true);
    } else {
      setSuccessOrder(false);
      setError(response?.data?.message || 'Something went wrong');
    }
  } catch (error) {
    console.error(error);
    setSuccessOrder(false);
    setError(error?.data?.message || 'Something went wrong');
  } finally {
    setLoading(false);
  }
};
//   CREATE PAYMENT API CALL end ----

//   OPEN POS APPLICATION FUNCTION start ----
const openPosApp = (newPayload, selected, setSuccessOrder, componentConsts) => {
  const idempotencyKey = generateIdempotencyKey();
  try {
    const dataParameter = {
      amount_money: {
        amount: `${newPayload?.amount}`,
        currency_code: 'USD'
      },
      location_id: newPayload?.squareLocationId,
      customer_id: '',
      order_id: newPayload?.squareOrderId,
      idempotency_key: idempotencyKey,
      reference_id: componentConsts?.locationId,
      accessToken: componentConsts?.accessToken,
      cash: {
        franchiseeId: componentConsts?.franchiseeId,
        clientId: selected?.client?.clientId,
        source: 'CASH',
        amount: newPayload?.amount,
        squareOrderId: newPayload?.squareOrderId,
        locationId: componentConsts?.locationId,
        orderId: newPayload?.orderId,
        paymentMode: 'CASH',
        cashDetails: {
          buyerSuppliedMoney: {
            amount: Number(newPayload?.amount),
            currency: 'USD'
          },
          changeBackMoney: {
            amount: 0,
            currency: 'USD'
          }
        }
      },
      callbackUrl: `${componentConsts?.DOMAIN_URL}checkout`
    };

    const testSquareURL =
      `${componentConsts?.PAYMENT_APP}://payment?data=` +
      encodeURIComponent(JSON.stringify(dataParameter));

    window.location.href = testSquareURL;

    // Capture the "Cancel" action when the user navigates back from TestSquare
    window.onbeforeunload = function () {
      // This function will execute when the user clicks "Cancel" or navigates back.
      // You can perform your desired action here.
      console.log('User clicked Cancel or navigated back.');
      // Call your cancel function here if needed
    };

    // Optionally, you can capture the "Open" action when the user returns to your page
    window.onfocus = function () {
      setSuccessOrder(true);
      // This function will execute when the user returns to your page after using TestSquare.
      // You can perform your desired action here.
      console.log('User returned to your page after using TestSquare.');
      // Call your "Open" function here if needed
    };
  } catch (error) {
    console.log('Unable to open the app');
  }
};
//   OPEN POS APPLICATION FUNCTION end ----

//   CALCULATE ORDER API CALL start ----
const calculateOrder = async (
  componentConsts,
  payload,
  setLoading,
  selected,
  setSelected,
  setSuccessOrder,
  setError,
  setErrorMessage
) => {
  setLoading(true);
  let newItemsArr = payload?.items?.map?.((item) => {
    delete item.promoCode;
    return item;
  });
  let payloadData = { ...payload, items: newItemsArr };
  payloadData.franchiseeName = componentConsts?.franchiseeName;
  payloadData.locationName = componentConsts?.locationName;
  payloadData.createdByName = componentConsts?.UserName;
  if (payload?.clientId === '') {
    delete payloadData?.clientId;
    // delete payloadData?.clientName;
  }
  if (!selected?.pet) {
    delete payloadData?.petId;
    delete payloadData?.petName;
  }

  try {
    let response = await ApiService.post(
      `${componentConsts?.BASE_URL}checkout/calculateOrder`,
      payloadData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.statusCode === 200) {
      const squareLocationId = response?.data?.squareLocationId;
      const taxAmount = response?.data?.totalSquareTaxAmountInDollar;
      const totalDiscount = response?.data?.totalSquareDiscountAmountInDollar;
      const totalAmount = response?.data?.totalSquareAmountWithTaxInDollar;
      const amountWithoutTax =
        response?.data?.totalSquareAmountWithoutTaxInDollar;
      const subTotal = amountWithoutTax + totalDiscount;

      setSelected({
        ...selected,
        subTotal: subTotal,
        discount: totalDiscount,
        tax: taxAmount,
        squareLocationId: squareLocationId,
        totalPrice: totalAmount,
        giftCardDiscount: findGiftCardDiscount(response?.data)?.deductedAmount,
        giftCardRemaining: findGiftCardDiscount(response?.data)?.remainingAmount
      });
    }
    setLoading(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
    setSuccessOrder(false);
    setErrorMessage(error?.message);
    setError(error?.message || 'Something went wrong');
  }
};
//   CALCULATE ORDER API CALL end ----

const findGiftCardDiscount = (data) => {
  const discountObj = data?.order?.discounts?.find(
    (item) => item?.name === 'Gift Card Discount'
  );
  if (discountObj) {
    const amountInCents = parseFloat(discountObj?.appliedMoney?.amount);
    const deductedAmount = amountInCents / 100;
    const remainingAmount =
      data?.giftCardPrice &&
      parseFloat(data?.giftCardPrice)?.toFixed(2) === deductedAmount
        ? deductedAmount
        : parseFloat(data?.giftCardPrice)?.toFixed(2) > deductedAmount
        ? parseFloat(data.giftCardPrice).toFixed(2) - deductedAmount
        : 0;
    return { deductedAmount, remainingAmount };
  }
  return { deductedAmount: 0, remainingAmount: 0 }; // or any default value you want
};

// FUNCTION TO GENERATE RANDOM IDEMPOTENCYKEY start ----

const generateIdempotencyKey = () => {
  const hexDigits = '0123456789abcdef';
  let uuid = '';
  for (let i = 0; i < 36; i++) {
    if (i === 14) {
      uuid += '4'; // Version 4 UUID
    } else if (i === 19) {
      uuid += hexDigits[Math.floor(Math.random() * 4) + 8]; // Variant (8, 9, A, or B)
    } else {
      uuid += hexDigits[Math.floor(Math.random() * 16)];
    }
  }
  return uuid;
};

// FUNCTION TO GENERATE RANDOM IDEMPOTENCYKEY end ----

const handleCloseSignature = (signature, setSignature) => {
  setSignature({ ...signature, open: false });
};

const handleSave = async (
  signatureCanvasRef,
  setSignature,
  setLoading,
  errors,
  setErrors,
  componentConsts
) => {
  const isEmpty = signatureCanvasRef.current.isEmpty();

  if (isEmpty) {
    setErrors({
      ...errors,
      signatureCanvas: 'Signature is required'
    });
    return;
  }
  setErrors({
    ...errors,
    signatureCanvas: ''
  });

  const signatureBlob = await getFileFromUrl(
    signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
  );

  const formData = new FormData();
  formData.append('signatureImage', signatureBlob);
  uploadImage(formData, setSignature, setLoading, componentConsts);
};

const uploadImage = async (
  formData,
  setSignature,
  setLoading,
  componentConsts
) => {
  setLoading(true);
  try {
    const response = await ApiService.post(
      `${componentConsts?.BASE_URL}checkout/uploadSignature?locationName=${componentConsts?.locationName}`,
      formData
    );
    if (response?.data?.url) {
      setSignature({ open: false, value: response?.data?.url });
    }
    setLoading(false);
  } catch (error) {
    setLoading(false);
    console.error('Error sending image:', error.message);
    throw error;
  }
};

export {
  calculateOrder,
  handleConfirm,
  handleCloseSignature,
  uploadImage,
  handleSave
};
