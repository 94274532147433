/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,

} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ArrowLeft, DeleteIcon } from 'src/assets/SVG/SvgIcons';
import Loader from 'src/components/Loader';
import { ApiService } from '../../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// LocationDetails page style wrapper
const ContractDetailsWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(2)};
`
);

const ContractDetail = (props) => {

  const { data, handleResetStates, handleOpenScreen, fetchData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [contractDetail, setContractDetail] = useState(null);

  const locationId = getLocalData('locationId');
  const encodedLocationId = encodeURIComponent(locationId);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const API_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  // Methdo to handle the delete dialog
  const handleDeleteDialog = (type) => {
    if (type === 'cancel') {
      setDeleteDialog(false);
    } else {
      setDeleteDialog(false);
      //api call here for delete
      deleteContract();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = contractDetail && contractDetail?.items.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  
  // useEffect(() => {
  //   setPage(0);
  // }, [contractDetail, contractDetail?.items]);

  useEffect(() => {
    getContract();
  }, [data]);


  const deleteContract = async () => {
    // To show loader
    setIsLoading(true);
    let id = encodeURIComponent(data[0].sortKey);
    let userId = getLocalData('userId');
    let body = {
      contractId: data[0].sortKey,
      userId: userId,
      locationId: locationId
    };

    try {
      const response = await ApiService.delete(`${API_URL}contracts/deleteContract`, body);

      if (response.statusCode === 200) {
        // To hide loader
        setIsLoading(false);
        setTimeout(() => {
          setShowSuccessMessage(true);
          setSuccessMessage('Selected contract has been deactivated successfully!');
        }, 500);
        fetchData();
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.message);
        // To hide loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      // To hide loader
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong');
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
    handleResetStates();
  };

  const getContract = async () => {
    let id = encodeURIComponent(data[0].sortKey);
    // To show loader
    setIsLoading(true);
    try {
      const contractsData = await ApiService.get(`${API_URL}contracts/getContractById?locationId=${encodedLocationId}&contractId=${id}`);

      if (contractsData.statusCode === 200) {
        setContractDetail(contractsData.data.Items);
        // To hide loader
        setIsLoading(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong');
        // toast.error('Something went wrong');
        setContractDetail([]);
        // To hide loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong');
      // To hide loader
      setIsLoading(false);
    }
  };
  

  // let manager = '';
  return (
    // <div className="custom-summary">
    <div className="contractMain mt-3">
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <ContractDetailsWrapper
        maxWidth={'100%'}
        className="location-details-wrapper p-0"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                onClick={() => handleResetStates()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                {contractDetail?.contractName}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              // disabled={btnLoading}
              className="details-remove-icon no-hover"
              onClick={() => setDeleteDialog(true)}
            >
              <DeleteIcon />
            </Button>
            {contractDetail?.status !== 3 &&
              <Button
                sx={{ mt: { xs: 0, md: 0 } }}
                variant="contained"
                onClick={() => handleOpenScreen(true)}
                className="btn-primary edit-button"
              >
                Edit
              </Button>
            }
          </Grid>
        </Grid>
        <Box className="location-details-container">
          {/* <Grid container spacing={2} > */}
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label summary-heading">
                Contract Duration
              </Box>
              <Box className="location-details-value summary-value">
                {contractDetail?.contractType}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label summary-heading">
                Autopay
              </Box>
              <Box className="location-details-value summary-value">
                {/* {contractDetail?.items?.map((item) => (
                  <>{item.contractItemType}, </>
                ))} */}
                {contractDetail?.autopay ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label summary-heading">
                Payment Cycle
              </Box>
              <Box className="location-details-value summary-value txt-Transform">
                {/* Next auto renewal Date is 26 june 2018 */}
                {contractDetail?.billingCycle}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label summary-heading">
                Price
              </Box>
              <Box className="location-details-value summary-value">
                {/* Next auto renewal Date is 26 june 2018 */}
                ${parseFloat(contractDetail?.price || 0).toFixed(2)}
              </Box>
            </Grid>
          </Grid>
          <Grid container mt={3} className="location-details-row">
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label summary-heading">
                Description
              </Box>
              <Box className="location-details-value summary-value">
                {contractDetail?.description}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className="location-details-col">
              <Box className="location-details-label summary-heading">
              Upon Consumption
              </Box>
              <Box className="location-details-value summary-value">
                {contractDetail?.uponConsumption ? 'Yes' : 'No'}
              </Box>
            </Grid>
          </Grid>

          <div style={{ marginTop: '30px' }}>
            {/* <Divider className="dividerClass" /> */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    borderRadius: '19px 19px 0px 0px'
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '12px'
                      }}
                    >
                      <CardHeader
                        title="All Contract Item"
                        className="table-header-name"
                        sx={{ fontSize: '20px', color: '#003087' }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '12px'
                      }}
                      className="membership-status-container status-field-ddl"
                    />
                  </Grid>
                  <TableContainer className="location-table-container">
                    <Table>
                      <TableHead>
                        <TableRow className="table-header">
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                             checked= "true"
                            // indeterminate={selectedSomeLocationItems}
                            // onChange={handleSelectAllLocationItems}
                            />
                          </TableCell> */}
                          <TableCell>Pricing Option</TableCell>
                          {/* <TableCell>Category</TableCell> */}
                          <TableCell className='text-transform-none'># of Session</TableCell>
                          <TableCell>Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {contractDetail?.items?.map((serviceItem) => { */}
                        {paginatedData?.length > 0 && paginatedData?.map((serviceItem) => {
                          return (
                            <TableRow
                              nohover
                              key={serviceItem.itemId}
                              // selected="true"

                              className="table-row cursor-pointer"
                            >
                              {/* <TableCell padding="checkbox">
                                        <Checkbox
                                          color="primary"
                                          checked= "true"
                                          // onChange={(event) =>
                                          //   handleSelectOneLocationItem(
                                          //     event,
                                          //     locationItem.locationId
                                          //   )
                                          // }
                                         // value={isLocationItemSelected}
                                        />
                                      </TableCell> */}
                              <TableCell
                                className="table-td"
                                sx={{ display: 'flex' }}
                              >
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  sx={{ marginTop: 1 }}
                                  gutterBottom
                                >
                                  {serviceItem.itemName}
                                </Typography>
                              </TableCell>
                              {/* <TableCell className="table-td">
                                <Typography>
                                  {serviceItem.itemCategoryName}
                                </Typography>
                              </TableCell> */}
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {serviceItem.numberOfSessions}
                                </Typography>
                              </TableCell>
                              <TableCell className="table-td">
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  ${parseFloat(serviceItem.price || 0)?.toFixed(2)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box p={2}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={contractDetail?.items?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Box>
      </ContractDetailsWrapper>

      <Dialog
        open={deleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDeleteDialog('cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {'Deactivate'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Are you sure you want to deactivate this record?
               
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleDeleteDialog('delete')}
          >
            Deactivate
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleDeleteDialog('cancel')}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ContractDetail;
