import { Button } from '@mui/material';
import React from 'react';

export const DynamicButton = ({ variant, onClick, disabled, loading, children }) => {
    return (
        <>
            <Button
                className={`${variant === 'contained' ? 'contained-btn' : 'outlined-btn'}`}
                type="button"
                variant="contained"
                disabled={disabled || loading}
                style={{
                    border: '2px solid',
                    padding: '8px 20px',
                    borderRadius: '10px',
                    textTransform: 'initial',
                    letterSpacing: '0.5px',
                    transition: 'background 300ms ease-out',
                    fontWeight: variant === 'contained' ? '500' : '600',
                    borderColor: disabled
                        ? variant === 'contained'
                            ? ''
                            : '#0000001f'
                        : '#003087',
                    color:
                        variant === 'contained'
                            ? 'white'
                            : disabled
                                ? '#d1d1d1'
                                : '#003087',
                    backgroundColor:
                        variant === 'contained'
                            ? disabled
                                ? '#0000001f'
                                : '#003087'
                            : 'inherit'
                }}
                onClick={onClick}
            >
                {/* {loading ? `Loading...` : text} */}
                {children}
            </Button>
        </>
    );
};
