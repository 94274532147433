import { ApiService } from 'src/services/ApiService';

let BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;

const handleSubmit = (
  taxValue,
  setTaxModal,
  setIsLoading,
  setErrorMessage,
  setShowErrorMessage,
  setSuccessMessage,
  setShowSucessMessage,
  setFormError,
  locationId,
  UserName,
  franchiseeId
) => {
  if (!taxValue?.value && taxValue?.value !== 0) {
    setFormError({
      taxValue: 'Tax is required'
    });
    return;
  }
  if (parseFloat(taxValue?.value) > 100) {
    setFormError({
      taxValue: 'Tax can not be greater than 100%.'
    });
    return;
  }
  if (taxValue?.object?.taxSlabId) {
    updateTaxRate(
      taxValue,
      setTaxModal,
      setIsLoading,
      setErrorMessage,
      setShowErrorMessage,
      setSuccessMessage,
      setShowSucessMessage,
      locationId,
      UserName,
      franchiseeId
    );
  } else {
    addTaxRate(
      taxValue,
      setTaxModal,
      setIsLoading,
      setErrorMessage,
      setShowErrorMessage,
      setSuccessMessage,
      setShowSucessMessage,
      locationId,
      UserName,
      franchiseeId
    );
  }
};

const addTaxRate = async (
  taxValue,
  setTaxModal,
  setIsLoading,
  setErrorMessage,
  setShowErrorMessage,
  setSuccessMessage,
  setShowSucessMessage,
  locationId,
  UserName,
  franchiseeId
) => {
  setIsLoading(true);
  const payload = {
    franchiseeId: franchiseeId,
    createdBy: UserName,
    locationId: locationId,
    taxRate: parseFloat(taxValue?.value)
  };
  try {
    const response = await ApiService.post(
      `${BASE_URL}locations/addTaxRate`,
      payload
    );
    if (!response?.error) {
      setTaxModal(false);
      setSuccessMessage(response?.message);
      setShowSucessMessage(true);
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setShowErrorMessage(true);
    setErrorMessage('Something went wrong');
    console.error('Error sending image:', error.message);
  }
};

const getTaxRate = async (
  setTaxValue,
  setIsLoading,
  setErrorMessage,
  setShowErrorMessage,
  locationId
) => {
  setIsLoading(true);

  const location = encodeURIComponent(locationId);
  console.log(locationId, 'get location Id');
  try {
    const response = await ApiService.get(
      `${BASE_URL}locations/getTaxRate?locationId=${location}`
    );
    if (
      response?.data?.Items?.[0]?.taxRate ||
      response?.data?.Items?.[0]?.taxRate === 0
    ) {
      const taxItems =
        response?.data?.Items?.length > 0 ? response?.data?.Items : [];
      setTaxValue({
        object: response?.data?.Items?.length > 0 ? taxItems[0] : {},
        value: response?.data?.Items?.length > 0 ? taxItems[0]?.taxRate : ''
      });
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setShowErrorMessage(true);
    setErrorMessage('Something went wrong');
    console.error('Error sending image:', error.message);
  }
};

const updateTaxRate = async (
  taxValue,
  setTaxModal,
  setIsLoading,
  setErrorMessage,
  setShowErrorMessage,
  setSuccessMessage,
  setShowSucessMessage,
  locationId,
  UserName
) => {
  setIsLoading(true);

  const payload = {
    updatedBy: UserName,
    locationId: locationId,
    taxSlabId: taxValue?.object?.taxSlabId,
    taxRate: parseFloat(taxValue?.value)
  };
  console.log(locationId, 'locationId');

  try {
    const response = await ApiService.put(
      `${BASE_URL}locations/updateTaxRate`,
      payload
    );
    if (!response?.error) {
      setTaxModal(false);
      setSuccessMessage(response?.message);
      setShowSucessMessage(true);
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setShowErrorMessage(true);
    setErrorMessage('Something went wrong');
    console.error('Error sending image:', error.message);
  }
};

export { handleSubmit, getTaxRate };
