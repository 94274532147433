/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { getLocalData } from 'src/utils';
// import { getLocalData, setFullLocationId } from '../../utils';

export const ContractFilter = ({ setFilters, filters, isFeatured }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
  // const locationId = setFullLocationId(getLocalData('locationId'));
  const classes = useStyles();
  // const [expired, SetExpired] = useState(true);
  // const [service, SetService] = useState([]);

  const [pricingDataPrice, setPricingDataPrice] = useState([]); // getting dynamic list
  // const locationId = setFullLocationId(getLocalData('locationId'));
  const locationId = getLocalData('locationId');
  const encodedLocationId = encodeURIComponent(locationId);

  // const contractBillingCycle = [
  //   { label: 'Monthly', value: 'every month' },
  //   { label: 'Quarterly', value: 'every quarter' },
  //   { label: 'Every 6 months', value: 'every 6 months' },
  //   { label: 'Every year', value: 'every year' }
  // ];

  const [openFilter, setOpenFilter] = useState({
    contractName: false,
    soldOnline: false,
    contractType: false,
    typeOfService: false,
    billingCycle: false
  });
  const API_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const getGiftCardPricing = async () => {
    try {
      let contractsResponse = await fetch(
        `${API_URL}contracts?locationId=${encodedLocationId}`
      );

      const contractsData = await contractsResponse.json();
      // console.log(contractsData, 'response');
      // setPricingData(response.data.Items);
      let Item = contractsData && contractsData.data.Items ? contractsData.data.Items : [];
      const giftcardprice = Array.from(
        new Set(Item.map((obj) => obj.contractName))
      );

      setPricingDataPrice(giftcardprice);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // get all categories
    getGiftCardPricing();
  }, []);

  const changeType = (value) => {
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const changeBillingCycle = (value) => {
    setFilters((prevState) => ({ ...prevState, billingCycle: value }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      // expired: field === 'expired' ? !prevState.expired : false,
      // category: field === 'category' ? !prevState.category : false,
      // service: field === 'service' ? !prevState.service : false,
      // set the filter for contract type
      contractType: field === 'contractType' ? !prevState.contractType : false,
      typeOfService: field === 'typeOfService' ? !prevState.typeOfService : false,
      giftCardPriceValue:
        field === 'giftCardPriceValue' ? !prevState.giftCardPriceValue : false,
      contractName: field === 'contractName' ? !prevState.contractName : false,
      billingCycle: field === 'billingCycle' ? !prevState.billingCycle : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: 1,
      contractType: '',
      typeOfService: '',
      contractName: '',
      billingCycle: ''
    });

    setOpenFilter({
      contractName: false,
      contractType: false,
      typeOfService: false,
      billingCycle: false
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Type</InputLabel>
          <Select
            value={filters.status ?? 1}
            onChange={(event) => changeType(event.target.value)}
            label="Type"
            className="status-field field-label-css"
          >
            {!isFeatured && <MenuItem key={1} id={1} value={-1}>All</MenuItem>}
            <MenuItem key={2} id={2} value={1}>Active</MenuItem>
            {!isFeatured && <MenuItem key={3} id={3} value={0}>Inactive</MenuItem>}
            {/* <MenuItem key={3} id={3} value={3}>Expired</MenuItem> */}
          </Select>
        </FormControl>
        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                {/* Contract Type */}
                Filters
              </Typography>
            </Grid>
            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('billingCycle')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.contractName && classes.closeY,
                    openFilter.contractName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Billing Cycle</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.billingCycle && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Billing Cycle</InputLabel>
              <Select
                onChange={(event) => changeBillingCycle(event.target.value)}
                value={filters.billingCycle ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Select Item"
                className="status-field field-label-css"
              >
                <MenuItem key={1} id={1} value='every month'>Monthly</MenuItem>
                <MenuItem key={2} id={2} value="every quarter">Quarterly</MenuItem>
                <MenuItem key={3} id={3} value="every 6 months">Every 6 months</MenuItem>
                <MenuItem key={4} id={4} value="every year">Every year</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        {/* <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('contractName')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.contractName && classes.closeY,
                    openFilter.contractName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Sold online</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          onClick={() => handleOpenFilter('contractName')}
          pt={0.5}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.contractName && classes.closeY,
                    openFilter.contractName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Pause Contract</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openFilter.contractName && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Contract Name</InputLabel>
              <Select
                onChange={(event) => ChangePrice(event.target.value)}
                value={filters.contractName ?? ''}
                sx={{
                  width: '100%'
                }}
                label="Contract Name"
                name="Contract Name"
                className="status-field field-label-css"
                MenuProps={{
                  PaperProps: {
                      style: {
                          maxHeight: 224,
                          overflowY: 'auto',
                      },
                  },
                }}
              >
                <MenuItem key={0} id={0} value={-1}>
                  All
                </MenuItem>
                {pricingDataPrice.length > 0 &&
                  pricingDataPrice.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )} */}
      </Card>
    </>
  );
};
