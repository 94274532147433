/* eslint-disable react/jsx-fragments */
/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {
  Box,
  Stack,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableContainer,
  Button,
  Menu,
  Fade,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  MenuItem,
  Divider,
  DialogActions
} from '@mui/material';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import React, { Fragment, useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ErrorIcon from '@material-ui/icons/Error';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AlertNotesDialog from './AlertNotes';
import { getLocalData } from 'src/utils';
import { convertDateTimeToUserTime } from 'src/utils';
import Loader from '../Loader';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';
import { getUserTimeZone } from 'src/utils';

// import { Card } from '../Calendar/Card';

export const ServiceDetails = (props) => {
  const {
    setGetAllPet,
    getAllPet,
    newSchedule,
    handleResetStates,
    openEditSchedule,
    btnLoading,
    setLoadData,
    dialog,
    setDialog,
    getEnrollmentDetail,
    setShowScreen,
    setSelectedPet,
    setSelectedClient
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [alertNotesChecked, setAlertNotesChecked] = useState(false);
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(true);
  const [selectedAttendee, setSelectedAttendee] = useState('');

  const [attributeData, setAttributeData] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [showAlerts, setShowAlerts] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [itemStatus, setItemStatus] = useState('');
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusItem, setStatusItem] = useState([]);
  const [statusValue, setStatusValue] = useState('');
  const [dateSet, setDateSet] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  let newDataArray ;
  useEffect(()=>{
     newDataArray = getAllPet.map((originalData) => ({
      clientId: originalData.clientParentId,
      petId: originalData.clientChildId
      // serviceId: newSchedule.serviceId
    }));
  },[getAllPet])

  const handleCheckboxClick = (event, attendee) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchor(null);
    setSelectedAttendee(attendee);
    setOpenDialog(!openDialog);
    setAlertNotesChecked(true); // Set the state of the "Alert Notes" checkbox
    setStatusDropdownVisible(false);
    // Toggle the state to open/close the dialog
  };

  const locationId = getLocalData('locationId');
  const userId = getLocalData('userId');
  const handleCloseDialog = () => {
    setOpenDialog(false);
    // Close the dialog
  };
  useEffect(() => {
    let dateTimeStart = convertDateTimeToUserTime(newSchedule.fromDate);
    let dateTimeEnd = convertDateTimeToUserTime(newSchedule.toDate);
    setDateSet(dateTimeStart.formattedDate);
    setStartTime(dateTimeStart.formattedTime);
    setEndTime(dateTimeEnd.formattedTime);
  }, []);
  // const handleClick = (event, item) => {
  //   if(item?.status === 'checkIn'){
  //     setAnchorEl(event.currentTarget);
  //     setSelectedItem(item);
  //   }else if(!item['status']){
  //     setAnchorEl(event.currentTarget);
  //     setSelectedItem(item);
  //   }

  // };
  const getAllAlertList = async () => {
    setIsLoading(true);
    let encodedLocation = encodeURIComponent(locationId);
    let body = {
      serviceId: newSchedule.serviceId,
      data: newDataArray
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/getClassRoster?locationId=${encodedLocation}`,
        body
      );
      if (response.statusCode && !response.error) {
        setIsLoading(false);
        setAlertData(response.data.Items);
      }
    } catch (error) {
      setIsLoading(false);
    }

    // setAlertData(clientAlerts);
  };
  useEffect(() => {
    getEnrollmentDetail();
  }, []);
  const convertToDateTime = (dateTimeString) => {
    let formateDate = moment(dateTimeString).toDate();
    return formateDate;
  };

  useEffect(() => {
    if(newDataArray.length>0){
      getAllAlertList();
    }
  }, [getAllPet]);

  useEffect(() => {
    let todaysDate = new Date();
    let scheduleDate = convertToDateTime(newSchedule.toDate);
    if (scheduleDate < todaysDate) {
      setIsDisabled(true);
    }
  }, [newSchedule]);
  const handleClose = () => {
    setPopoverAnchor(null);
  };

  const handleDialogClose = () => {
    handleResetStates();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCloseAlert = () => {
    setIsOpen(false);
  };

  const handleChangeStatus = async (value, item) => {
    setIsLoading(true);
    if (value === 'earlyCancel') {
      let petId = item[0]?.clientChildId;
      setLoadData(true);
      const updatedAttendiesWithStatus = getAllPet.map((pet) => {
        if (pet.clientChildId === petId) {
          return { ...pet, status: value };
        }
        return pet;
      });

      // Step 2: Filter out the records with 'earlyCancel' status
      const finalUpdatedAttendies = updatedAttendiesWithStatus.filter(
        (pet) => pet.status !== 'earlyCancel'
      );
      setGetAllPet(finalUpdatedAttendies);
    }
    let timezone = getUserTimeZone();
    const data = {
      checkIn: value,
      status: value,
      occurrenceClientId: item[0]?.occurrenceClientId,
      updatedBy: userId,
      appointmentId: newSchedule.appointmentId,
      timeZone : timezone
    };
    try {
      const response = await ApiService.put(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}schedule/changeStatus`,
        data
      );

      if (!response.data?.error) {
        setIsLoading(false);
        setLoadData(true);
        getEnrollmentDetail();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
 
  };
  const showAlertsNotes = (e, a) => {
    let data = a.petData;
    const trueAlerts = data.filter((item) => item.alert === true);
    setAttributeData(trueAlerts);
    setShowAlerts(true);
    setIsOpen(true);
  };

  const calculateAge = (birthday) => {
    let today = new Date();
    let parts = birthday?.split('-');
    if (parts?.length) {
      let reversedBirthday = parts[2] + '-' + parts[0] + '-' + parts[1];
      let dob = new Date(reversedBirthday);
      let diff = today.getTime() - dob.getTime();
      let years = Math.floor(diff / 31556736000);
      let daysDiff = Math.floor((diff % 31556736000) / 86400000);
      let months = Math.floor(daysDiff / 30.4167);
      let days = Math.floor(daysDiff % 30.4167);
      let weeks = Math.floor(daysDiff / 7);
      if (years === 0 && months === 0 && days === 0) {
        return 'less than a day';
      }
      if (years === 0 && months === 0) {
        if (weeks === 1 || weeks < 15) {
          return `${weeks} weeks`;
        }
        return `${days} days`;
      }

      if (years === 0 && days === 0) {
        return `${months} months`;
      }

      if (months === 0 && days === 0) {
        return `${years} years`;
      }

      if (years === 0) {
        if (weeks < 15) {
          return `${weeks} weeks`;
        }
        return `${months} months ${days} days`;
      }

      if (months === 0) {
        return `${years} years ${days} days`;
      }

      if (days === 0) {
        return `${years} years ${months} months`;
      }

      let finalage = `${years} years ${months} months ${days} days`;
      finalage = finalage && finalage.replace(/-/g, ' ');
      return finalage;
    }
    return null;
  };
  function getStatusName(status) {
    switch (status) {
      case 'earlyCancel':
        return 'Early Cancel';
      case 'lateCancel':
        return 'Late Cancel';
      case 'checkIn':
        return 'Check In';
      case 'arrivedLate':
        return 'Arrived Late';
      case 'absent':
        return 'Absent';
      case 'Booked':
        return 'Booked';
      case 'booked':
        return 'Booked';
      case 'refund':
        return 'Refund';
      // Add more cases as needed
      default:
        return '';
    }
  }
  const openMenu = (event, item) => {
    setItemStatus(item);
    event.preventDefault();
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };

  const handleCloseConfirm = (name) => {
    if (name === 'Cancel') {
      setStatusDialog(false);
    } else {
      handleChangeStatus(statusValue, statusItem);
    }
  };
  const handleMultipleStatus = (event) => {
    event.stopPropagation();
    setAnchor(null);
    setPopoverAnchor(event.currentTarget);
    setStatusItem([itemStatus]);
  };
  const handleStatusName = (value) => {
    setStatusValue(value);
    if (
      value === 'refund' ||
      value === 'booked' ||
      value === 'absent' ||
      value === 'lateCancel' ||
      value === 'arrivedLate' ||
      value === 'earlyCancel'
    ) {
      handleChangeStatus(value, statusItem);
      setPopoverAnchor(null);
    }
  };

  const handlePetDetail = (item) => {
    setShowScreen(6);
    setSelectedClient(item.clientParentId);
    setSelectedPet(item.clientChildId);
  };
  return (
    <>
      <>
        <Loader IsLoading={isLoading} />
        <div className="service-container order-container">
          <Box
            sx={{ width: '100%' }}
            paddingBottom="20px"
            marginTop="20px"
            className="Main-container common-form-container"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              className="container"
              alignItems="center"
              marginTop="12px"
            >
              <Typography
                variant="variant2"
                sx={{
                  fontSize: '28px',
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '-5px'
                }}
                className="header-blue-32"
              >
                <Box
                  sx={{ marginBottom: '15px' }}
                  onClick={() => handleDialogClose()}
                  className="back-arrow-close"
                >
                  <ArrowLeft />
                </Box>
                {newSchedule.serviceName}
              </Typography>

              <Box display="flex" gap="16px" className="align-right">
                <Button
                  className="detail-page-icons"
                  disabled={btnLoading}
                  onClick={() => openEditSchedule()}
                >
                  <ModeEditOutlineOutlinedIcon sx={{ color: '#003087' }} />
                </Button>

                <Button
                  className="detail-page-icons"
                  disabled={isDisabled}
                  onClick={() => setDialog({ ...dialog, bookClass: true })}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.15243 2.55566L9.01546 5.11688L7.61819 7.03779L7.15243 6.39748L6.68668 7.03779L5.75516 5.75718L7.15243 3.83627V2.55566ZM14.6046 5.11688L15.0703 5.75718L13.673 7.67809V9.599L14.1388 10.2393V14.0811H13.2073V12.1602L12.2758 10.2393H9.01546V14.0811H8.08395V7.67809L9.48122 5.75718L9.94698 6.39748H13.673L14.6046 5.11688Z"
                      fill="#003087"
                    />
                    <rect
                      x="3.42188"
                      y="6.39941"
                      width="0.931515"
                      height="5.76273"
                      fill="#003087"
                    />
                    <rect
                      x="1.79297"
                      y="9.92285"
                      width="1.28061"
                      height="4.19182"
                      transform="rotate(-90 1.79297 9.92285)"
                      fill="#003087"
                    />
                  </svg>
                </Button>

                <Button
                  className="detail-page-icons"
                  disabled={btnLoading}
                  onClick={() =>
                    setDialog({ ...dialog, state: true, delete: true })
                  }
                >
                  <svg
                    width="24"
                    height="18"
                    viewBox="0 0 24 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.74999 20.0833H10.5833V7.33333H7.74999V20.0833ZM13.4167 20.0833H16.25V7.33333H13.4167V20.0833ZM2.08332 25.75V4.5H0.666656V1.66667H7.74999V0.25H16.25V1.66667H23.3333V4.5H21.9167V25.75H2.08332Z"
                      fill="#003087"
                    />
                  </svg>
                </Button>
              </Box>
            </Stack>

            <Box paddingLeft="40px" paddingRight="15px">
              <Stack
                gap={5}
                direction="row"
                className="container"
                marginTop="24px"
              >
                <Typography
                  variant="variant6"
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    fontWeight: '600',
                    color: 'rgba(0, 0, 0, 0.70)'
                  }}
                >
                  {dateSet}
                </Typography>

                {newSchedule.fromDate == newSchedule.toDate ? (
                  <Typography
                    variant="variant6"
                    sx={{
                      fontSize: '15px',
                      textAlign: 'left',
                      fontWeight: '600',
                      color: 'rgba(0, 0, 0, 0.70)'
                    }}
                  >
                    - {dateSet}
                  </Typography>
                ) : null}

                <Typography
                  variant="variant6"
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    fontWeight: '600',
                    color: 'rgba(0, 0, 0, 0.70)'
                  }}
                >
                  {startTime} - {endTime}
                </Typography>
              </Stack>

              <Stack
                gap={5}
                direction="row"
                className="container"
                marginTop="24px"
              >
                <Typography
                  variant="variant6"
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    fontWeight: '600',
                    color: 'rgba(0, 0, 0, 0.70)'
                  }}
                  className="capitalize"
                >
                  <span style={{ marginRight: '16px' }}>Trainer:</span>{' '}
                  {newSchedule.trainerName}
                </Typography>

                <Typography
                  variant="variant6"
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    fontWeight: '600',
                    color: 'rgba(0, 0, 0, 0.70)'
                  }}
                  className="capitalize"
                >
                  <span style={{ marginRight: '16px' }}>Room:</span>{' '}
                  {newSchedule.room}
                </Typography>
              </Stack>

              <Stack
                gap={0}
                direction="column"
                className="container"
                marginTop="24px"
              >
                <Typography
                  variant="variant6"
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    fontWeight: '600',
                    color: 'rgba(0, 0, 0, 0.70)'
                  }}
                >
                  Description:
                </Typography>

                <Typography
                  variant="variant6"
                  sx={{
                    fontSize: '15px',
                    textAlign: 'left',
                    fontWeight: '400',
                    color: 'rgba(0, 0, 0, 0.70)'
                  }}
                >
                  {newSchedule.description}
                </Typography>
              </Stack>

              <Stack direction="column" className="container" marginTop="40px">
                {/* {attributeData?.map((item, index) => {
return (
<Typography key={index}>
  {item.message}
</Typography>
)
})} */}

                <Typography
                  variant="variant2"
                  sx={{
                    fontSize: '20px',
                    textAlign: 'left',
                    marginBottom: '20px'
                  }}
                  className="detail-title"
                >
                  Roster
                </Typography>

                {newSchedule.type == 'enrollment' ? (
                  <Fragment>
                    {getAllPet?.length ? (
                      <TableContainer
                        className="location-table-container servies-details-table"
                        sx={{
                          borderRadius: '19px 19px 0px 0px',
                          backgroundColor: 'rgba(248, 251, 255, 1)'
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow className="table-header">
                              <TableCell />
                              <TableCell>Pet Name</TableCell>
                              <TableCell>Age</TableCell>
                              <TableCell>Booked By</TableCell>
                              <TableCell>Client Name</TableCell>
                              <TableCell>Payment Status</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Check-In</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {getAllPet?.map((item, index) => {
                              return (
                                <>
                                  <TableRow key={item.id}>
                                    <TableCell >
                                      {alertData.map(
                                        (i) =>
                                          i.petId === item.clientChildId &&
                                          i.petData.some(
                                            (a) => a.alert === true
                                          ) && (
                                            <ErrorIcon
                                              key={
                                                i.petData.find(
                                                  (a) => a.alert === true
                                                ).id
                                              }
                                              style={{ color: '#C00000' }}
                                              onClick={(e) =>
                                                showAlertsNotes(e, i)
                                              }
                                            />
                                          )
                                      )}
                                    </TableCell>
                                    <TableCell
                                      sx={{ minWidth: '150px' }}
                                      onClick={() => handlePetDetail(item)}
                                    >
                                      <Avatar
                                        alt="icon"
                                        src={item.clientChildProfile}
                                        sx={{ marginRight: 2, float: 'left' }}
                                        className="card-avatar-img align-self-center"
                                      />
                                      <Typography
                                        // variant="body1"
                                        // fontWeight="bold"
                                        // color="text.primary"
                                        sx={{ marginTop: 1 }}
                                        fontSize="17px"
                                      >
                                        {item.clientChildName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {calculateAge(item.clientChildBirthDate)}{' '}
                                    </TableCell>
                                    <TableCell>
                                      {item.bookedBy}
                                    </TableCell>
                                    <TableCell>{item.clientParentName}</TableCell>
                                    {/* {item.payLater? <TableCell> <Button>apply credit</Button></TableCell> :<TableCell />} */}
                                    <TableCell
                                      className={`${
                                        item.payLater === true
                                          ? 'paylater-red'
                                          : 'black-status'
                                      }`}
                                    >
                                      {item.payLater ? 'Pay Later' : 'Prepaid'}
                                    </TableCell>

                                    <TableCell
                                      className={`${
                                        item.status === 'checkIn'
                                          ? 'green-status'
                                          : item.status === 'arrivedLate' ||
                                            item.status === 'booked'
                                          ? 'black-status'
                                          : item.status === 'earlyCancel' ||
                                            item.status === 'lateCancel' ||
                                            item.status === 'absent' ||
                                            item.waitingList === true ||
                                            item.status === 'booking'
                                          ? 'red-status'
                                          : 'black-status'
                                      }`}
                                    >
                                      {item.status != 'Booked'
                                        ? getStatusName(item.status)
                                        : item.waitingList
                                        ? 'Waitlist'
                                        : 'Booked'}
                                    </TableCell>
                                    <TableCell>
                                      {item.status === 'checkIn' ||
                                      item.status === 'arrivedLate' ? (
                                        <CheckCircleIcon
                                          style={{ color: '#32B2AC' }}
                                        />
                                      ) : (
                                        <RadioButtonUncheckedIcon
                                          onClick={() =>
                                            handleChangeStatus('checkIn', [
                                              item
                                            ])
                                          }
                                          style={{
                                            color: '#32B2AC',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      )}
                                    </TableCell>

                                    <TableCell className="table-td">
                                      <MoreVertIcon
                                        onClick={(e) => openMenu(e, item)}
                                        color="grey"
                                        variant="contained"
                                        className="three-dots-icon"
                                      />

                                      <Menu
                                        open={Boolean(anchor)}
                                        anchorEl={anchor}
                                        onClose={closeMenu}
                                        keepMounted
                                        PaperProps={{
                                          style: {
                                            maxHeight: 40 * 7,
                                            width: '25ch'
                                          }
                                        }}
                                      >
                                        <MenuItem
                                          onClick={(e) =>
                                            handleCheckboxClick(e, item)
                                          }
                                        >
                                          Add Alerts And Notes
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) =>
                                            handleMultipleStatus(e, item)
                                          }
                                        >
                                          Change Status
                                        </MenuItem>
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}

                            <Popover
                              open={Boolean(popoverAnchor)}
                              anchorEl={popoverAnchor}
                              onClose={handleClose}
                              style={{ top: '-3%' }}
                            >
                              <FormGroup
                                sx={{
                                  backgroundColor: '#F8FBFF',
                                  marginLeft: '5px'
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleStatusName('earlyCancel')
                                      }
                                      name="earlyCancel"
                                    />
                                  }
                                  label="Early Cancel"
                                />

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleStatusName('arrivedLate')
                                      }
                                      name="arrivedLate"
                                    />
                                  }
                                  label="Arrived Late"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleStatusName('lateCancel')
                                      }
                                      name="lateCancel"
                                    />
                                  }
                                  label="Late Cancel"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleStatusName('absent')
                                      }
                                      name="absent"
                                    />
                                  }
                                  label="Absent"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleStatusName('booked')
                                      }
                                      name="booked"
                                    />
                                  }
                                  label="Booked"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={() =>
                                        handleStatusName('refund')
                                      }
                                      name="refund"
                                    />
                                  }
                                  label="Refund Credit"
                                />
                              </FormGroup>
                            </Popover>
                            {/* </Menu> */}
                            {/* </Box> */}
                            {/* )} */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Fragment>
                        <TableContainer
                          className="location-table-container servies-details-table"
                          sx={{
                            borderRadius: '19px 19px 0px 0px',
                            backgroundColor: 'rgba(248, 251, 255, 1)'
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell />
                                <TableCell>Pet Name</TableCell>
                                <TableCell>Age</TableCell>
                                <TableCell>Booked By</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Check-In</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableContainer>
                        <Typography
                          variant="variant4"
                          sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                            color: '#000'
                          }}
                        >
                          No attendees have registered
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <TableContainer
                      className="location-table-container servies-details-table"
                      sx={{
                        borderRadius: '19px 19px 0px 0px',
                        backgroundColor: 'rgba(248, 251, 255, 1)'
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow className="table-header">
                            <TableCell />
                            <TableCell>Pet Name</TableCell>
                            <TableCell>Age</TableCell>
                            <TableCell>Booked By</TableCell>
                            <TableCell>Client Name</TableCell>
                            <TableCell>Payment Status</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Check-In</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {getAllPet?.map((item, index) => {
                            return (
                              <>
                                <TableRow key={item.id}>
                                  <TableCell>
                                    {alertData.map(
                                      (i) =>
                                        i.petId === item.clientChildId &&
                                        i.petData.some(
                                          (a) => a.alert === true
                                        ) && (
                                          <ErrorIcon
                                            key={
                                              i.petData.find(
                                                (a) => a.alert === true
                                              ).id
                                            }
                                            style={{ color: '#C00000' }}
                                            onClick={(e) =>
                                              showAlertsNotes(e, i)
                                            }
                                          />
                                        )
                                    )}
                                  </TableCell>

                                  <TableCell
                                    sx={{ minWidth: '150px' }}
                                    onClick={() => handlePetDetail(item)}
                                  >
                                    <Avatar
                                      alt="icon"
                                      src={item.clientChildProfile}
                                      sx={{ marginRight: 2, float: 'left' }}
                                      className="card-avatar-img align-self-center"
                                    />
                                    <Typography
                                      // variant="body1"
                                      // fontWeight="bold"
                                      // color="text.primary"
                                      sx={{ marginTop: 1 }}
                                      fontSize="17px"
                                    >
                                      {item.clientChildName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {calculateAge(item.clientChildBirthDate)}
                                  </TableCell>
                                  <TableCell>{item.bookedBy}</TableCell>
                                  <TableCell>{item.clientParentName}</TableCell>
                                  <TableCell
                                    className={`${
                                      item.payLater === true
                                        ? 'paylater-red'
                                        : 'black-status'
                                    }`}
                                  >
                                    {item.payLater ? 'Pay Later' : 'Prepaid'}
                                  </TableCell>
                                  {/* <TableCell>{item.payLater ? "Pay later" : "Booked"}</TableCell> */}
                                  <TableCell
                                    className={`${
                                      item.status === 'earlyCancel' ||
                                      item.status === 'lateCancel' ||
                                      item.status === 'absent' ||
                                      item.status === 'waitList' ||
                                      item.status === 'booking'
                                        ? 'red-status'
                                        : item.status === 'checkIn'
                                        ? 'green-status'
                                        : 'black-status'
                                    }`}
                                  >
                                    {getStatusName(item.status)}
                                  </TableCell>
                                  <TableCell>
                                    {item.status === 'checkIn' ||
                                    item.status === 'arrivedLate' ? (
                                      <CheckCircleIcon
                                        style={{ color: '#32B2AC' }}
                                      />
                                    ) : (
                                      <RadioButtonUncheckedIcon
                                        onClick={() =>
                                          handleChangeStatus('checkIn', [item])
                                        }
                                        style={{
                                          color: '#32B2AC',
                                          cursor: 'pointer'
                                        }}
                                      />
                                    )}
                                  </TableCell>

                                  <TableCell className="table-td">
                                    <MoreVertIcon
                                      onClick={(e) => openMenu(e, item)}
                                      color="grey"
                                      variant="contained"
                                      className="three-dots-icon"
                                    />

                                    <Menu
                                      open={Boolean(anchor)}
                                      anchorEl={anchor}
                                      onClose={closeMenu}
                                      keepMounted
                                      PaperProps={{
                                        style: {
                                          maxHeight: 40 * 7,
                                          width: '25ch'
                                        }
                                      }}
                                    >
                                      {/* <MenuItem
                                        disabled={item.status === 'checkIn'}
                                        onClick={(e) =>
                                          onMenuItemClick(
                                            e,
                                            'earlyCancel',
                                            item
                                          )
                                        }
                                      >
                                        Early Cancel
                                      </MenuItem>
                                      <MenuItem
                                        disabled={item.status === 'checkIn'}
                                        onClick={(e) =>
                                          onMenuItemClick(e, 'lateCancel', item)
                                        }
                                      >
                                        Late Cancel
                                      </MenuItem>
                                      <MenuItem
                                        disabled={item.status === 'checkIn'}
                                        onClick={(e) =>
                                          onMenuItemClick(e, 'absent', item)
                                        }
                                      >
                                        Absent
                                      </MenuItem>
                                      <MenuItem
                                        disabled={item.status === 'checkIn'}
                                        onClick={(e) =>
                                          onMenuItemClick(
                                            e,
                                            'arrivedLate',
                                            item
                                          )
                                        }
                                      >
                                        Arrived Late
                                      </MenuItem> */}
                                      <MenuItem
                                        onClick={(e) =>
                                          handleCheckboxClick(e, item)
                                        }
                                      >
                                        Add Alerts And Notes
                                      </MenuItem>
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMultipleStatus(e, item)
                                        }
                                      >
                                        Change Status
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          <Popover
                            open={Boolean(popoverAnchor)}
                            anchorEl={popoverAnchor}
                            onClose={handleClose}
                            style={{ top: '-3%' }}
                          >
                            <FormGroup
                              sx={{
                                backgroundColor: '#F8FBFF',
                                marginLeft: '5px'
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() =>
                                      handleStatusName('earlyCancel')
                                    }
                                    name="earlyCancel"
                                  />
                                }
                                label="Early Cancel"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() =>
                                      handleStatusName('arrivedLate')
                                    }
                                    name="arrivedLate"
                                  />
                                }
                                label="Arrived Late"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() =>
                                      handleStatusName('lateCancel')
                                    }
                                    name="lateCancel"
                                  />
                                }
                                label="Late Cancel"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => handleStatusName('absent')}
                                    name="absent"
                                  />
                                }
                                label="Absent"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => handleStatusName('booked')}
                                    name="booked"
                                  />
                                }
                                label="Booked"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={() => handleStatusName('refund')}
                                    name="refund"
                                  />
                                }
                                label="Refund Credit"
                              />
                            </FormGroup>
                          </Popover>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Fragment>
                )}
              </Stack>
            </Box>
          </Box>
          <AlertNotesDialog
            handleDialogClose={handleDialogClose}
            setStatusDropdownVisible={setStatusDropdownVisible}
            setLoadData={setLoadData}
            selectedAttendee={itemStatus}
            open={openDialog}
            onClose={handleCloseDialog}
            setOpenDialog={setOpenDialog}
            getAllAlertList={getAllAlertList}
          />
          {showAlerts && (
            <Dialog
              open={isOpen}
              //  TransitionComponent={Transition}
              keepMounted
              //  onClose={() => handleUploadDocsClose('cancel')}
              aria-describedby="details-dialog"
              className="dialog-box dialog-box-right not scheduling-dialog"
              sx={{ maxHeight: '400px', mt: 25, ml: 2 }}
            >
              <Box>
                <DialogTitle className="dialog-box-right-addTask -team-container-white-box">
                  {/* this class is commented for the above code className="alert-title align-right dialog-header" */}
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      className="alert-data-scheduling"
                      textAlign="center"
                      sx={{
                        marginTop: '0',
                        marginBottom: '10px',
                        fontWeight: '700',
                        fontSize: '30px'
                      }}
                    >
                      All Alerts
                    </Typography>
                    {/* remove icon */}
                    <Button
                      className="details-remove-icon task-cross p-0 no-hover"
                      onClick={() => handleCloseAlert()}
                    >
                      <svg
                        width="30"
                        height="31"
                        viewBox="0 0 30 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                          fill="#E64646"
                        />
                      </svg>
                    </Button>
                  </Box>
                </DialogTitle>
              </Box>
              <DialogContent className="-team-container-white-box scheduling-alert-dialog">
                <DialogContentText
                  id="alert-dialog-slide-description"
                  className="alert-decription"
                >
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={6}></Grid>
                    <Grid
                      className="alert-padding-dialog"
                      item
                      sx={{
                        width: '100%'
                      }}
                    >
                      <Box
                        className="alert-text-scheduling"
                        sx={{ textAlign: 'left' }}
                      >
                        {attributeData.map((item) => {
                          return (
                            <>
                              <Grid
                                container
                                className="alert-container-scheduling"
                              >
                                <Grid
                                  item
                                  display="flex"
                                  alignItems="left"
                                  marginLeft={2}
                                >
                                  <CircleIcon
                                    style={{
                                      color: '#C00000',
                                      marginRight: '5px',
                                      marginTop: '5px'
                                    }}
                                  />
                                  <Typography>{item.message}</Typography>
                                </Grid>
                              </Grid>
                              <hr className="horizontal-line-scheduling" />
                            </>
                          );
                        })}
                      </Box>
                      {/* <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
           
            <TableBody>

              {attributeData.map((item) => {
                return (
                  <TableRow
                    hover
                    className="table-row cursor-pointer"
                  >
                    <TableCell align='center' className="table-td">
                      <Typography>{item.message}</Typography>
                    </TableCell>
                  </TableRow>
                )


              })}
            
            </TableBody>
          </Table>
        </TableContainer>
      </Card> */}
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </>
      <Dialog
        open={statusDialog}
        // TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'} 
          
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {/* {!isCreated ? ( */}
              <Typography className="title-text-sub">
                Are you sure you want to mark this
                <Typography className="title-text-sub">
                  {statusValue === 'earlyCancel'
                    ? "dog's reservation as an Early Cancel?"
                    : statusValue === 'lateCancel'
                    ? "dog's reservation as a Late Cancel?"
                    : statusValue === 'absent'
                    ? 'dog as Absent for the selected reservation?'
                    : 'Arrived Late for the selected reservation?'}
                </Typography>
              </Typography>
              {/* ) : (
                <Typography className="title-text-sub">
                  Pricing option added successfully!
                </Typography>
              )} */}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Cancel')}
          >
            Cancel
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseConfirm('Confirm')}
          >
            Confirm
          </Button>
          {/* )} */}
        </DialogActions>
      </Dialog>
    </>
  );
};
