import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Grid,
  Card,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  DialogActions,
  Slide,
  TableFooter,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Loader from 'src/components/Loader';
// import './InventoryManagement.css';
import { getLocalData, setFullLocationId } from 'src/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function InventoryExpiry() {
  const [productList, setProductList] = useState([]);
  const [openConfirmDialog, setConfirmDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const locationId = setFullLocationId(getLocalData('locationId'));
  const [expiryTable, setExpiryTable] = useState({
    items: [],
    supplierInfo: []
  });
  const [productSortID, setProductSortID] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  //    let grandTotal = 0;

  useEffect(() => {
    let elm = document.getElementsByClassName('page-header-text')[0];
    if (elm) elm.innerHTML = 'Inventory Adjustments';

    getExpiryStocks();
  }, []);

  /* Function to sort the products */

  const productSort = (data) => {
    setProductList(data);
    console.log(productList);
  };
  /* Function to close modal pop up */
  const handleClose = (val) => {
    if (val === 'Continue') {
      deleteExpiredProducts();
    } else if ('success') {
      setConfirmDialog(false);
      getExpiryStocks();
    } else {
      setConfirmDialog(false);
    }
  };

  /* Function for api call for withdraw expiry stock */

  const getExpiryStocks = async () => {
    // Show Loader
    setIsLoading(true);

    let encodedLocationId = encodeURIComponent(locationId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/expiryProduct?locationId=${encodedLocationId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      );
      const { data } = await response.json();
      const items = Object.values(data).flatMap((item) => item.items || []);
      const supplierInfo = Object.values(data).flatMap(
        (item) => item.supplierInfo || []
      );

      // Get the items PRTSort from the items array
      let productSortId = items.map((item) => encodeURIComponent(item.PRDSort));
      if(productSortId) setProductSortID(productSortId);
      console.log(productSortId);
      // console.log(supplierInfo);
      // console.log(items);
      setExpiryTable({ items: items, supplierInfo: supplierInfo });
      // hide loader
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      // hide loader
      setIsLoading(false);
    }
  };

  /* Function for api call for delete Expired Products */

  const deleteExpiredProducts = async () => {
    // Show Loader
    setIsLoading(true);
    setConfirmDialog(false);

    let encodedLocationId = encodeURIComponent(locationId);
    let prodId = JSON.stringify(productSortID);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}inventory/deleteExpiredProducts?locationId=${encodedLocationId}&productIds=${prodId}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      );
      const { data } = await response.json();
      if (data && !data.error) {
        setIsDeleted(true);
        // hide loader
        setIsLoading(false);
        setConfirmDialog(true);
      } 
    } catch (error) {
      console.log(error);
      setIsDeleted(false);
      // hide loader
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      <Box sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
        {/* <Typography className='title-page' sx={{ paddingLeft: '15px' }}>Inventory Adjustments</Typography> */}
        <Typography className="title-sub fontweight600 text-primary">
          <h2> Withdraw Expired Stock</h2>
        </Typography>
        <Box sx={{ padding: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}
          >
            <Box width={260}>
              <FormControl fullWidth className="select-drop-down-label-styling">
                <InputLabel
                  id="demo-simple-select-helper-label"
                // className="input-field-dropdown-styling"
                >
                  Sort Products By
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="SORT PRODUCT BY"
                  onChange={productSort}
                // className="input-field-styling"
                // defaultValue={state}
                >
                  <MenuItem value="Ascending">Ascending</MenuItem>
                  <MenuItem value="Descending">Descending</MenuItem>
                  <MenuItem value="Name">Name</MenuItem>
                  <MenuItem value="Stock Availability">
                    Stock Availability
                  </MenuItem>
                  <MenuItem value="Price">Price</MenuItem>
                  <MenuItem value="Last Updated">Last Updated</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width={300}>
              <TextField
                fullWidth
                type="search"
                label="Search"
                // sx={{ marginTop: '30px' }}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{ style: {}, endAdornment: <SearchIcon /> }}
              // onChange={handleSearch}
              // onClick={handleSearch}
              // className="input-field-styling"
              />
            </Box>
          </Box>
        </Box>
        <Typography
          className="inventory-summary-title fontweight600 text-primary"
          sx={{ paddingLeft: '10px', fontSize: '20px' }}
        >
          Expired Products
        </Typography>

        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
              <TableContainer className="expiry-stock-container bottom-styling">
                {expiryTable.supplierInfo.map((supplier, index) => (
                  <React.Fragment key={index}>
                    <Typography
                      className="inventory-summary-text fontweight600"
                      sx={{ paddingLeft: '10px', marginTop: '10px', fontSize: '18px' }}
                    >
                      {supplier.supplierName}
                    </Typography>
                    <Table className="main-table" sx={{ marginTop: '15px' }}>
                      <TableHead>
                        <TableRow className="table-header">
                          <TableCell>Product Name</TableCell>
                          <TableCell>SKU Code</TableCell>
                          <TableCell>Expiry Date</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Expired Qty</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Price X Qty</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {searchQuery
                          ? expiryTable.items.map((tableItem, itemIndex) => {
                            if (
                              tableItem.supplierId === supplier.supplierId &&
                              tableItem.productName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ) {
                              return (
                                <TableRow
                                  hover
                                  className="table-row cursor-pointer"
                                  key={itemIndex}
                                >
                                  <TableCell className="table-td">
                                    {tableItem.productName}
                                  </TableCell>
                                  <TableCell className="table-td">
                                    {tableItem.sku}
                                  </TableCell>
                                  <TableCell className="table-td">
                                    {tableItem.expiryDate}
                                  </TableCell>
                                  <TableCell className="table-td">
                                    {tableItem.price}
                                  </TableCell>
                                  <TableCell className="table-td">
                                    {tableItem.productQTY}
                                  </TableCell>
                                  <TableCell className="table-td">
                                    Category1
                                  </TableCell>
                                  <TableCell className="table-td">
                                    {'$' +
                                      tableItem.price +
                                      ' X ' +
                                      tableItem.productQTY}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                            return null;
                          })
                          : expiryTable.items
                            .filter(
                              (tableItem) =>
                                tableItem.supplierId === supplier.supplierId
                            )
                            .map((tableItem, itemIndex) => (
                              <TableRow
                                hover
                                className="table-row cursor-pointer"
                                key={itemIndex}
                              >
                                <TableCell className="table-td">
                                  {tableItem.productName}
                                </TableCell>
                                <TableCell className="table-td">
                                  {tableItem.sku}
                                </TableCell>
                                <TableCell className="table-td">
                                  {tableItem.expiryDate}
                                </TableCell>
                                <TableCell className="table-td">
                                  {tableItem.price}
                                </TableCell>
                                <TableCell className="table-td">
                                  {tableItem.productQTY}
                                </TableCell>
                                <TableCell className="table-td">
                                  Category1
                                </TableCell>
                                <TableCell className="table-td">
                                  {'$' +
                                    tableItem.price +
                                    ' X ' +
                                    tableItem.productQTY}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>

                      <TableFooter>
                        <TableRow
                          hover
                          className="table-row cursor-pointer bottom-row"
                        >
                          <TableCell
                            className={
                              index === expiryTable.items.length - 1
                                ? 'inventory-summary-text'
                                : ' inventory-summary-text bottom-row'
                            }
                          >
                            <Typography className="fontweight600 ">
                              Total Value{' '}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={
                              index === expiryTable.items.length - 1
                                ? ''
                                : 'bottom-row'
                            }
                          />
                          <TableCell
                            className={
                              index === expiryTable.items.length - 1
                                ? ''
                                : 'bottom-row'
                            }
                          />
                          <TableCell
                            className={
                              index === expiryTable.items.length - 1
                                ? ''
                                : 'bottom-row'
                            }
                          />
                          <TableCell
                            className={
                              index === expiryTable.items.length - 1
                                ? ''
                                : 'bottom-row'
                            }
                          />
                          <TableCell
                            className={
                              index === expiryTable.items.length - 1
                                ? ''
                                : 'bottom-row'
                            }
                          />
                          <TableCell
                            className={
                              index === expiryTable.items.length - 1
                                ? 'inventory-summary-text'
                                : ' inventory-summary-text bottom-row'
                            }
                          >
                            <Typography className="fontweight600 ">
                              {'$' +
                                expiryTable.items
                                  .filter(
                                    (item) =>
                                      item.supplierId === supplier.supplierId
                                  )
                                  .reduce(
                                    (total, item) =>
                                      total +
                                      parseFloat(item.price) *
                                      parseFloat(item.productQTY),
                                    0
                                  )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {index === expiryTable.items.length - 1 && (
                          <TableRow
                            hover
                            className="table-row cursor-pointer bottom-row"
                          >
                            <TableCell className="inventory-expiry-footer">
                              <Typography className="text-primary fontweight600">
                                GRAND TOTAL
                              </Typography>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell className="inventory-expiry-footer text-primary">
                              <Typography className="text-primary fontweight600">
                                {'$' +
                                  expiryTable.items.reduce(
                                    (grandTotal, tableItem) =>
                                      grandTotal +
                                      parseFloat(tableItem.price) *
                                      parseFloat(tableItem.productQTY),
                                    0
                                  )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableFooter>
                    </Table>
                  </React.Fragment>
                ))}
              </TableContainer>
            </Card>
          </Grid>

          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '15px',
              paddingBottom: '15px',
              paddingRight: '8px'
            }}
            spacing={2}
          >
            <Grid item xs={12} sm={2.5} md={2.5}>
              <Button
                sx={{ mt: { xs: 0, md: 0 }, mr: 2, width: '100%' }}
                variant="contained"
                className="btn-primary"
              >
                Print Expiry Report
              </Button>
            </Grid>
            <Grid item xs={12} sm={2.5} md={2.5}>
              <Button
                sx={{
                  mt: { xs: 0, md: 0 },
                  border: '2px solid #003087 !important',
                  color: '#003087 !important',
                  padding: '6px 20px !important',
                  width: '100%'
                }}
                variant="outlined"
                className="btn-primary"
                onClick={() => setConfirmDialog(true)}
              >
                Confirm Withdraw
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* <Dialog
        open={openConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Do you want to confirm withdraw
        </DialogTitle>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button className="font-17 text-black no-hover">Yes</Button>
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose()}
          >
            No
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isDeleted ? 'Confirm withdrawal?' : 'Congratulations!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">

              <Typography className="title-text-sub">
                {!isDeleted ? 'Are you sure you want to withdraw these items?' : 'You have successfully withdraw products.'}
              </Typography>

            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          {!isDeleted ? (<>
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleClose('Cancel')}
            >
              No
            </Button>
            <Divider className="vertical-divider" />

            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Continue')}
            >
              Yes
            </Button>
          </>
          ) : (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('success')}
            >
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InventoryExpiry;
