/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Avatar,
  Card,
  CardHeader,
  IconButton,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Checkbox,
  Grid,
  Menu,
  MenuItem
} from '@mui/material';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import { CustomButton, CustomSelect } from 'src/components/Global';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddDescription from './AddDescription/AddDescription';

export const JourneyTable = (props) => {
  const {
    tableRows,
    searchQuery,
    loading,
    openDetail,
    selectedRows,
    setSelectedRows,
    selectedStatus,
    setSelectedStatus,
    handleBulkAdd,
    btnLoading,
    handleResetStates
  } = props;
  const theme = useTheme();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getJourneyItem, setGetJourneyItem] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const [openDescription, setOpenDescription] = useState(false);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);

  // const [tablePagination, setTablePagination] = useState({
  //   page: 0,
  //   limit: 10
  // });

  // To change the page into the table
  // const handlePageChange = (event, newPage) => {
  //   setTablePagination({ ...tablePagination, page: newPage });
  // };

  // To change the limit
  // const handleLimitChange = (event) => {
  //   setTablePagination({
  //     ...tablePagination,
  //     limit: parseInt(event.target.value)
  //   });
  // };

  // Apply paination on table
  // const applyPagination = (tableRows) => {
  //   const page = tablePagination.page;
  //   const limit = tablePagination.limit;
  //   return tableRows.slice(page * limit, page * limit + limit);
  // };

  // Apply search filter
  const applyFilters = (tableRows) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = tableRows.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = tableRows;
    }
    return arrayOfMatchedObjects;
  };

  // Apply filter from object and below is the pagination order
  // const filteredRows = applyFilters(tableRows);
  // const paginatedRows = applyPagination(filteredRows);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredData.map((n) => n.id);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows?.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows?.slice(0, selectedIndex),
        selectedRows?.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  useEffect(() => {
    const searchText = searchQuery.toLowerCase();
    setSearchText(searchText);
    setPage(0);
  }, [searchQuery]);

  const filteredData = tableRows.filter((item) => {
    return Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const dateFormate = (date) => {
    // let utc = moment(date).format('MMMM Do YYYY, h:mm:ss A');
    let utc;
    utc = (date) ? moment(date).format('MMM Do YYYY, h:mm') : '';
    return utc;
  }

  const openMenu = (journey, event) => {
    // setStaffId(staffItem.staffId);
    event.preventDefault();
    event.stopPropagation();
    setGetJourneyItem(journey);

    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (journey, event, value) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(null);

    if (value === 'addDescription') {
      console.log("journey", journey, getJourneyItem);
      setOpenDescription(true);
      setOpenDescriptionDialog(true);
    }
  };

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {/* <CardHeader title="All Services" className="table-header" /> */}
            {/* <h2 className="table-heading">All Services</h2> */}
            <Typography className="table-heade header-blue-20">
              {' '}
              All Journeys
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={7}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '12px'
            }}
            className="membership-status-container"
          >
            <CustomSelect
              fullWidth
              name="selectedStatus"
              disabled={!selectedRows?.length}
              value={selectedStatus}
              onChange={(type, value) => setSelectedStatus(value)}
              label="Select Status"
              options={[
                {
                  label: 'Shared',
                  value: 'Shared'
                },
                {
                  label: 'Private',
                  value: 'Private'
                }
              ]}
            />
            <CustomButton
              variant="contained"
              disabled={selectedStatus === '' || !selectedRows?.length}
              onClick={handleBulkAdd}
              loading={btnLoading}
            >
              Update
            </CustomButton>
          </Grid>
        </Grid>
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows?.length > 0 &&
                      selectedRows?.length < filteredData?.length
                    }
                    checked={
                      filteredData?.length > 0 &&
                      selectedRows?.length === filteredData?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all'
                    }}
                    sx={{ color: '#003087' }}
                  />
                </TableCell>
                <TableCell>Journey Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last modified</TableCell>
                <TableCell>Activity status</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData.map((row, index) => {
                const isItemSelected = isSelected(row.sortKey);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    key={row.sortKey}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    className="table-row cursor-pointer"
                    onClick={(e) => openDetail(row.sortKey, 3, e)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                        sx={{ color: '#003087' }}
                        onClick={(event) => handleClick(event, row.sortKey)}
                      />
                    </TableCell>
                    <TableCell className="table-td">
                      {/* <Avatar
                        alt="icon"
                        src={
                          row.trophyImage ||
                          'https://exquise.anecdote.id/wp-content/uploads/2020/08/Dummy-Product.jpeg'
                          // '../../assets/Images/service-icon.png'
                        }
                        sx={{ marginRight: 2, float: 'left' }}
                        className="card-avatar-img"
                      /> */}
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row?.name}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                      // noWrap
                      >
                        {row?.CreationDate && dateFormate(row.CreationDate)}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.LastModifiedDate && dateFormate(row.LastModifiedDate)}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.activityStatus}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row?.status}
                      </Typography>
                    </TableCell>

                    <TableCell className="table-td">
                      {/* <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.sortKey}
                        onClick={(e) => openDetail(row.sortKey, 2, e)}
                      >
                        Edit
                      </IconButton> */}
                      <MoreVertIcon
                        onClick={(e) => openMenu(row, e)}
                        color="grey"
                        variant="contained"
                        className="three-dots-icon"
                      />

                      <Menu
                        open={Boolean(anchor)}
                        anchorEl={anchor}
                        onClose={closeMenu}
                        keepMounted
                        PaperProps={{
                          style: {
                            maxHeight: 40 * 5,
                            width: '25ch'
                          }
                        }}
                      >
                        <MenuItem
                          onClick={(e) =>
                            onMenuItemClick(
                              row,
                              e,
                              'addDescription'
                            )
                          }
                        >
                          Add a description
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          {/* <TablePagination
            component="div"
            count={filteredRows?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={tablePagination.page}
            rowsPerPage={tablePagination.limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          /> */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Card>

      {/* Add alert and notes */}
      <Box>
        {openDescription && (
          <AddDescription
            openDescriptionDialog={openDescriptionDialog}
            setOpenDescriptionDialog={setOpenDescriptionDialog}
            setOpenDescription={setOpenDescription}
            handleResetStates={handleResetStates}
            getJourneyItem={getJourneyItem}
          // type={'client'}
          />
        )}
      </Box>
    </div>

  );
};
