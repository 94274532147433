/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Slide,
  CardMedia,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@mui/material';
import { HeaderContext } from 'src/contexts/HeaderContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
// import './EditProduct.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiService } from '../../../services/ApiService';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Loader from '../../../components/Loader';
import axios from 'axios';
import { formatPhoneNumber, getLocalData, setFullLocationId } from 'src/utils';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import { ImageSlider } from 'src/components/Global';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function EditBusnessEntities({
  data,
  openProductEditPopup,
  setopenProductEditPopup,
  refreshProductData,
  setShowScreen,
  refreshData
}) {
  const [getProductList, setgetProductList] = useState([]);
  let userId = getLocalData('userId');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const locationId = setFullLocationId(getLocalData('locationId'));

  /* States for getting values from input fields of Add product form as follows */

  const [manufacturerProductName, setmanufacturerProductName] = useState('');
  // const [barCode, setbarCode] = useState(0);
  const [manufacturer, setmanufacturer] = useState('');
  const [manufacturerUrl, setmanufacturerUrl] = useState('');
  const [zoomRoomProductName, setzoomRoomProductName] = useState('');
  const [productCategory, setproductCategory] = useState([]);
  const [varientType, setvarientType] = useState([]);
  const [varientProduct, setvarientProduct] = useState([]);
  const [price, setprice] = useState('');
  const [supplierList, setsupplierList] = useState([]);
  const [sellingPoint, setsellingPoint] = useState('');
  const [productDescription, setproductDescription] = useState('');
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [isRequired, setisRequired] = useState(false);
  const [isBestSeller, setisBestSeller] = useState(false);
  const [istaxExempt, setistaxExempt] = useState(false);
  const [isDiscontinued, setisDiscontinued] = useState(false);
  const [productCategoryId, setproductCategoryId] = useState('');
  const [variantId, setvariantId] = useState('');
  const [supplierId, setsupplierId] = useState('');
  const [valueId, setvalueId] = useState('');
  const [imgObj, setimgObj] = useState([]);
  const [variantDisabled, setvariantDisabled] = useState(true);
  const [expiryDate, setexpiryDate] = useState('');
  const [productId, setproductId] = useState('');
  const [mediaId, setmediaId] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [productCategoryName, setproductCategoryName] = useState('');
  const [skuCode, setSkuCode] = useState(null);

  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');

  const [businessPhone, setBusinessPhone] = useState('');
  const [address2, setaddress2] = useState('');
  const [businessEntity, setBusinessEntity] = useState([]);
  const [businessEntityTypeId, setBusinessEntityTypeId] = useState('');
  const [businessEntityTypeName, setBusinessEntityTypeName] = useState('');

  const [stateType, setStateType] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  /* States for getting and setting add product errors  */

  const [businessNameError, setBusinessNameError] = useState('');
  const [businessEmailerror, setBusinessEmailerror] = useState('');
  const [businessPhoneError, setBusinessPhoneError] = useState('');
  const [contactPhoneError, setContactPhoneError] = useState('');

  const [zipCodeError, setZipCodeError] = useState('');
  const [contactNameError, setContactNameError] = useState('');
  const [contactEmailError, setContactEmailError] = useState('');

  const [address2Error, setaddress2Error] = useState('');
  const [stateTypeError, setStateTypeError] = useState(false);
  const [cityError, setCityError] = useState(false);

  const [manufacturerProductNameError, setmanufacturerProductNameError] =
    useState('');
  // const [barCodeError, setbarCodeError] = useState('');
  const [skuCodeError, setSkuCodeError] = useState('');

  const [manufacturerError, setmanufacturerError] = useState('');
  const [manufacturerUrlError, setmanufacturerUrlError] = useState('');
  const [zoomRoomProductNameError, setzoomRoomProductNameError] = useState('');
  const [businessEntityTypeError, setBusinessEntityTypeError] = useState(false);
  const [productCategoryError, setproductCategoryError] = useState(false);
  const [varientTypeError, setvarientTypeError] = useState(false);
  const [varientError, setvarientError] = useState(false);
  const [priceError, setpriceError] = useState('');
  const [supplierError, setsupplierError] = useState(false);
  const [sellingPointError, setsellingPointError] = useState('');
  const [productDescriptionError, setproductDescriptionError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [expiryDateError, setexpiryDateError] = useState('');

  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);
  /* States for opening of dialog */
  const [openEditproduct, setopenEditproduct] = useState(openProductEditPopup);
  // check manufacturere url is valid or not
  const [isManufacturerUrlValid, setIsManufacturerUrlValid] = useState(false);
  const [openAddVariant, setopenAddVariant] = useState(false);
  const [openAddCategory, setopenAddCategory] = useState(false);
  const [openAddVarientType, setopenAddVarientType] = useState(false);
  // const [openAddSupplier, setopenAddSupplier] = useState(false);
  const [variantName, setvariantName] = useState('');
  const [categoryName, setcategoryName] = useState('');
  const [categoryDescription, setcategoryDescription] = useState('');
  const [varientTypeName, setvarientTypeName] = useState('');
  const [variantTypeId, setvariantTypeId] = useState('');
  const [imageError, setImageError] = useState('');
  // const { setHeaderText } = useContext(HeaderContext);
  // setHeaderText('Edit Product');
  /* State for setting image url */
  const [imageUrl, setimageUrl] = useState('');
  const [newImages, setNewImages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [productImage, setProductImage] = useState([]);
  let productDetails;
  let TempName = '';

  useEffect(() => {
    productDetails = data;
    TempName = productDetails.businessName;
    console.log(productDetails, 'productDetails');
    console.log(productDetails.businessName, 'productDetails');

    getProducts();
    getCategory();
    getVariantType();
    // if(   document.getElementById('select-product-edit-file')){
    //   debugger;
    //   // document.getElementById('select-product-edit-file').value = "";
    //   document.getElementById('select-product-edit-file').srcElement.value = "";
    // }
  }, [data]);

  useEffect(() => {
    getVariants(variantId);
  }, [variantId]);

  /* Function to get uploaded image */
  const handleFileInput = (event) => {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].type === 'image/gif') {
        // alert("Sorry! GIF format is not supported.");
        setImageError('Please upload a supported images format.');
      } else {
        setimgObj(event.target.files[0]);
        setimageUrl(URL.createObjectURL(event.target.files[0]));
        const fileList = event.target.files;
        const uploadedImages = Array.from(fileList).map((file) => {
          // Create an object for each file with a unique ID and the file itself
          return {
            id: Math.random().toString(36).substring(7),
            file: file,
            previewURL: URL.createObjectURL(file)
          };
        });
        setImageError('');
        setNewImages([...newImages, ...uploadedImages]);
        console.log(newImages, 'newImages');

        // set field updated to true
        setImageError('');
        setIsFieldUpdated(true);
      }
    }
  };

  // Method to close the update dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      setopenEditproduct(true);
    }
    if (val === 'Discard') {
      setShowScreen(0);
      refreshData();
      setOpen(false);

      setopenEditproduct(false);
      setopenProductEditPopup(false);
      setproductId('');
    }
  };

  /* Function to close the Edit product dialog box */

  const handleCloseEditProduct = () => {
    if (isFieldUpdated) {
      setopenEditproduct(true);
      setOpen(true);
    } else {
      setShowScreen(0);
      setopenEditproduct(false);
      // setopenProductEditPopup(false);
      setOpen(false);
      setproductId('');
    }
  };

  // close variant dialog
  const handleCloseVariant = () => {
    setopenAddVariant(false);
    setopenAddCategory(false);
    setopenAddVarientType(false);
  };
  // create new variant
  const handleCreateVariantType = () => {
    if (varientTypeName) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        variantType: varientTypeName,
        createdBy: userId
        // isActive: true
      };
      createVariantType(data);
    }
  };

  const addNewVariant = () => {
    setvarientTypeName('');
    setopenAddVarientType(true);
  };

  // create variant type
  const createVariantType = (data) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCT_API_END_POINT}/variants`, data)
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvarientTypeName('');
          getVariantType();
          setvariantId(res.data.data.variantId);
          setopenAddVarientType(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addVariant = () => {
    setvariantName('');
    setvariantTypeId('');
    setopenAddVariant(true);
  };

  // Method to set variant type
  const handleVariantTypeChange = (e) => {
    setvariantTypeId(e.target.value);
  };

  // Method to create new variant
  const handleCreateVariant = () => {
    if (variantName && variantTypeId) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        value: variantName,
        variantId: variantTypeId,
        createdBy: userId
        // isActive: true
      };
      createVariant(data);
    }
  };

  // create variant
  const createVariant = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}/variant-values`,
        data
      )
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setvariantName('');
          setvariantTypeId('');
          if (variantId) getVariants(variantId);
          // set variant value id
          setvalueId(res.data.data.valueId);
          setopenAddVariant(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* Method for Add product category modal */
  const addNewCategory = () => {
    setcategoryName('');
    setcategoryDescription('');
    setopenAddCategory(true);
  };

  // Now create category
  const handleCreateCategory = () => {
    if (categoryName && categoryDescription) {
      // Now show the loader
      setIsLoading(true);
      const data = {
        categoryName: categoryName,
        description: categoryDescription,
        createdBy: userId
        // isActive: true
      };
      createCategory(data);
    }
  };
  // create category
  const createCategory = (data) => {
    axios
      .post(`${process.env.REACT_APP_PRODUCT_API_END_POINT}/category`, data)
      .then((res) => {
        if (res.data.statusCode === 200 && !res.data.error) {
          setcategoryName('');
          setcategoryDescription('');
          getCategory();
          setproductCategoryId(res.data.data.categoryId);
          setopenAddCategory(false);
          // Now hide the loader
          setIsLoading(false);
          setIsFieldUpdated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* Function to get category of products */

  const getCategory = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    try {
      setIsLoading(true);
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntityTypes`
      );
      setproductCategoryList(response.data.Items);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  /* const getCategory = async () => {
    const encodedLocationId = encodeURIComponent(locationId);

    await fetch(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/getAllBusinessEntityTypes`,

      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setproductCategoryList(data.data.Items);
      });
  };
 */

  /* const getSupplier = async () => {
    await fetch(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}products/getAllSupplier`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setsupplierList(data.data.Items);
      });
  }; */

  /* Function to get product details on basis of id */

  //   const getProducts = async () => {
  //     const encodedLocationId = encodeURIComponent(locationId);

  //     // setIsLoading(true);
  //     let getProductId;
  //     if (productId) {
  //       getProductId = productId;
  //     } else {
  //       getProductId = productDetails.sortKey;
  //     }
  //     let encodedId = encodeURIComponent(getProductId);
  //     await fetch(
  //       `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}products/getProductDetailsById?locationId=${encodedLocationId}&id=${encodedId}`,
  //       {
  //         method: 'GET',
  //         headers: {
  //           Accept: 'application/json',
  //           'content-Type': 'application/json',
  //           'Access-Control-Allow-Origin': '*'
  //         }
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  // setIsUpdated(false);

  // setproductId(data.data.Items[0].sortKey);
  // setgetProductList(data.data.Items[0]);
  // // setmanufacturerProductName(data.data.Items[0].mfgName);
  // setmanufacturerProductName(data.data.Items[0].mfgName);
  // setmanufacturer(data.data.Items[0].manufacturer);
  // setmanufacturerUrl(data.data.Items[0].mfgUrl);
  // // setzoomRoomProductName(data.data.Items[0].mfgName);
  // setzoomRoomProductName(data.data.Items[0].fName);
  // setprice(data.data.Items[0].price);
  // setsellingPoint(data.data.Items[0].sellingPoints);
  // setproductDescription(data.data.Items[0].productDescription);
  // setexpiryDate(data.data.Items[0].expiryDate);
  // setIsManufacturerUrlValid(false);
  // setNewImages([]);
  // // If product has media then set the image url else set it to empty
  // if (data.data.Items[0].media.length > 0) {
  //   setimageUrl(data.data.Items[0].media[0].media);
  //   setMultipleImages(data.data.Items[0].media);
  // } else {
  //   setimageUrl('');
  //   setMultipleImages([]);
  // }
  // setIsLoading(false);

  // // If product has media then set the media id else set it to empty
  // if (data.data.Items[0].media.length > 0)
  //   setmediaId(data.data.Items[0].media[0].mediaId);
  // else setmediaId('');
  // // setproductCategoryName(data.data.Items[0].category.categoryName);
  // setproductCategoryId(data.data.Items[0].categoryId);
  // setsupplierId(data.data.Items[0].supplierId);
  // setvariantId(data.data.Items[0].variantId);
  // // check if variant type is not null then call the getVariantValue function
  // // if (variantId !== null) {
  // //   getVariants(variantId);
  // // }
  // setvalueId(data.data.Items[0].valueId);
  // setisBestSeller(data.data.Items[0].isBestSeller);
  // setisRequired(data.data.Items[0].isRequired);
  // setistaxExempt(data.data.Items[0].taxExempt);
  // setisDiscontinued(data.data.Items[0].isDiscontinued);
  // setSelectedStatus(data.data.Items[0].status);
  // setSkuCode(data.data.Items[0].sku);
  //       });
  //   };

  const getProducts = () => {
    let businessEntitySet =
      data && data.businessEntityTypeId + '|' + data.businessEntityType;

    setIsUpdated(false);

    setBusinessName(data?.businessName);
    setBusinessEmail(data?.businessEmail);
    setBusinessPhone(data?.phone);
    setmanufacturerUrl(data?.businessUrl);
    setzoomRoomProductName(data?.addressLine1);
    setaddress2(data?.addressLine2);
    setCity(data?.city);
    setZipCode(data?.postalCode);
    setContactName(data?.contactName);
    setContactEmail(data?.contactEmail);
    setContactPhone(data?.contactPhone);
    setproductDescription(data?.notes);
    setStateType(data?.state);
    setBusinessEntityTypeId(data?.businessEntityTypeId);
    setBusinessEntityTypeName(data?.businessEntityType);
    setBusinessName(data?.businessName);
    setproductId(data?.sortKey);
    setBusinessEntity(businessEntitySet);
  };

  useEffect(() => {
    if (multipleImages) {
      let images = multipleImages.map((item) => item.media);
      console.log(images, 'images');
      // let imagesArr = images.map((item) => ({
      //   image: item
      // }));
      // setMultipleImages(images);
      setProductImage(images);
    }
  }, [multipleImages]);
  // useEffect(() => {
  //   console.log(getProductList, 'getProductList');
  //   if (getProductList) {
  //     let images = getProductList.productMedia.map((item) => item.media);
  //     console.log(images, 'images');
  //     setMultipleImages(images);
  //   }

  // }, [getProductList]);

  /* Function to delete the current profile picture */

  const deleteMedia = async () => {
    // const formData=new FormData();

    // formData.append("productId",productId);
    // formData.append("mediaIds",mediaId)
    let mediaArr = [];
    mediaArr.push(mediaId);
    let body = JSON.stringify({
      productId: productId,
      mediaIds: mediaArr
    });

    await fetch(
      `${process.env.REACT_APP_PRODUCT_API_END_POINT}/products/deleteProductMedia`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },

        body: body
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setimageUrl('');
        getProducts();
      });
  };

  /* Function to get variant on basis of variant id */

  const getVariants = async (id) => {
    if (id) {
      let encodedId = encodeURIComponent(id);
      await fetch(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}variant-values/getVariantValues?variantId=${encodedId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setvarientProduct(data.data.Items);
        });
    }
  };

  /* Function to get  states */

  const getVariantType = async () => {
    await fetch(
      `${process.env.REACT_APP_API_END_POINT}locations/getAllStates`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.data.Items);
        setvarientType(data.data.Items);
      });
  };

  /* Function to validate and check all field are filled */
  const SubmitProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if (!manufacturer) {
    //   setmanufacturerError('Manufacturer is required');
    // }

    // if (!manufacturerUrl) {
    //   setmanufacturerUrlError('Business url is required');
    // }

    if (!zoomRoomProductName) {
      setzoomRoomProductNameError('address is required');
    }
    // if (!address2) {
    //   setaddress2Error('address 2 is required');
    // }
    if (!businessEntityTypeId) {
      setBusinessEntityTypeError(true);
    }
    if (!variantId) {
      // setvariantError('Variant type is required');
      setvarientTypeError(true);
    }
    if (!valueId) {
      // setvalueError('Variant is required');
      setvarientError(true);
    }
    if (!supplierId) {
      // setsupplierError('Supplier is required');
      setsupplierError(true);
    }
    // if (!zipCode) {
    //   // setsupplierError('Supplier is required');
    //   setZipCodeError('ZIP  code is required');
    // }
    if (!businessName) {
      // setsupplierError('Supplier is required');
      setBusinessNameError('Business name is required');
    }
    // if (!businessEmail) {
    //   // setsupplierError('Supplier is required');
    //   setBusinessEmailerror('Business email is required');
    // }
    // if (!businessPhone) {
    //   // setsupplierError('Supplier is required');
    //   setBusinessPhoneError('Business phone is required');
    // }
    // if (!city) {
    //   // setsupplierError('Supplier is required');
    //   setCityError('City is required');
    // }
    // if (!contactName) {
    //   // setsupplierError('Supplier is required');
    //   setContactNameError('Contact Name is required');
    // }
    /* if (!stateType) {
      // setsupplierError('Supplier is required');
      setStateTypeError(true);
    } */
    // if (!contactEmail) {
    //   // setsupplierError('Supplier is required');
    //   setContactEmailError('Contact email is required');
    // }
    // if (!contactPhone) {
    //   // setsupplierError('Supplier is required');
    //   setContactPhoneError('Contact phone is required');
    // }
    /* if (!productDescription) {
      // setsupplierError('Supplier is required');
      setproductDescriptionError('Notes is required');
    } */
    // if (!price) {
    //   setpriceError('Price is required');
    // }
    // if (!expiryDate) {
    //   setexpiryDateError('Expiry date is required');
    // }

    if (
      businessName
      //   zoomRoomProductName &&
      //   productCategoryId &&
      //   variantId &&
      //   valueId &&
      //   supplierId &&
      // price &&
      //   !isManufacturerUrlValid
      // expiryDate
    ) {
      // EditProduct();
      if (isFieldUpdated) {
        setOpenPopup(true);
      }
    }
  };

  const handleClosePopup = (val) => {
    if (val === 'Save') {
      EditProduct();
      setOpenPopup(false);
      // setOpen(false);
      setopenEditproduct(false);
      setopenProductEditPopup(false);
    } else if (val === 'Discard') {
      // setOpen(false);
      // setopenEditproduct(false);
      // setopenProductEditPopup(false);
      // setOpenPopup(false);
      // setproductId('');
      setOpenPopup(false);
      // setOpen(false);
      setopenEditproduct(false);
      setopenProductEditPopup(false);
    }
  };

  console.log(businessEntityTypeName, 'supplierIdsupplierIdsupplierId');
  /* Function to edit product details */
  const EditProduct = async () => {
    const encodedLocationId = encodeURIComponent(locationId);
    let encodedproductId = encodeURIComponent(productId);

    setIsLoading(true);

    /* Json for add product  */
    const dataEditProduct = {
      businessEntityTypeId: businessEntityTypeId,
      businessEntityType: businessEntityTypeName,

      businessName: businessName,
      businessEmail: businessEmail,
      phone: businessPhone,
      businessUrl: manufacturerUrl,
      addressLine1: zoomRoomProductName,
      addressLine2: address2,
      state: stateType,
      city: city,
      postalCode: zipCode,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhone: contactPhone,

      notes: productDescription,

      updatedBy: userId,
      status: 1
    };

    // let productId = productDetails.productId;
    try {
      let response = await ApiService.put(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/updateBusinessEntityById?locationId=${encodedLocationId}&id=${encodedproductId}`,
        dataEditProduct
      );
      if (response.statusCode === 200) {
        if (newImages.length > 0) {
          for (const image of newImages) {
            const formData = new FormData();
            formData.append('productId', productId);
            formData.append('media', image.file);

            addProductMedia(formData);
          }
        } else {
          setOpen(true);
          setIsUpdated(true);
          setIsLoading(false);
          refreshProductData();
          setIsFieldUpdated(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }

    /*       await fetch(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}business-entity/updateBusinessEntityBId?locationId=${encodedLocationId}&id=${encodedproductId}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
  
          body: dataEditProduct
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            if (newImages.length > 0) {
              for (const image of newImages) {
                const formData = new FormData();
                formData.append('productId', productId);
                formData.append('media', image.file);
  
                addProductMedia(formData);
              }
            } else {
              setOpen(true);
              setIsUpdated(true);
              setIsLoading(false);
              refreshProductData();
              setIsFieldUpdated(false);
            }
          }
        }); */
  };

  const removeImage = (idToRemove) => {
    const updatedImages = newImages.filter((image) => image.id !== idToRemove);
    setNewImages(updatedImages);
  };

  // Method to change the status
  const handleStatusChange = (val) => {
    setSelectedStatus(val);
    setIsFieldUpdated(true);
  };

  // Add product media
  const addProductMedia = async (formData) => {
    await fetch(
      `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/addProductMedia`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*'
        },
        body: formData
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // if (newImages[newImages.length - 1]) {
        //   getProducts();
        setIsLoading(false);
        refreshProductData();
        setIsUpdated(true);
        setOpen(true);
        setIsFieldUpdated(false);
        // }
      });
  };

  const businessNameValidate = (businessName) => {
    if (!businessName) {
      setIsFieldUpdated(true);
      setBusinessName('');
      setBusinessNameError('Business  name is required');
    } else {
      setIsFieldUpdated(true);
      setBusinessNameError('');
      setBusinessName(businessName);
    }
  };

  /* Function to set manufacturer contact name setting value */

  const contactNameValidate = (contactName) => {
    if (!contactName) {
      setIsFieldUpdated(true);
      setContactName('');
      // setContactNameError('Contact  name is required');
    } else {
      setIsFieldUpdated(true);
      setContactNameError('');
      setContactName(contactName);
    }
  };

  /* Function for validation of Business email  */

  const businessEmailValidate = (businessEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    // set field updated to true

    if (!businessEmail) {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail('');
    } else if (!manufacturerPattern.test(businessEmail)) {
      setIsFieldUpdated(false);
      setBusinessEmail(businessEmail);
      setBusinessEmailerror('Please enter valid email address');
    } else {
      setIsFieldUpdated(true);
      setBusinessEmailerror('');
      setBusinessEmail(businessEmail);
    }
  };

  /* Function for validation of contact email  */

  const contactEmailValidate = (contactEmail) => {
    let manufacturerPattern = /\S+@\S+\.\S+/;
    // set field updated to true

    if (!contactEmail) {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail('');
    } else if (!manufacturerPattern.test(contactEmail)) {
      setIsFieldUpdated(true);
      setContactEmailError('Please enter valid email address');
      setContactEmail(contactEmail);
    } else {
      setIsFieldUpdated(true);
      setContactEmailError('');
      setContactEmail(contactEmail);
    }
  };

  /* Function for validation of Business phone  */

  const businessPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);

    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setBusinessPhoneError('Business phone should have exactly 10 digits');
      setBusinessPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setBusinessPhoneError('');
      setBusinessPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of contact phone  */

  const contactPhoneValidate = (contactPhone) => {
    let formatWorkPhoneNumber = formatPhoneNumber(contactPhone);

    if (!formatWorkPhoneNumber) {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone('');
    } else if (formatWorkPhoneNumber.length !== 12) {
      setIsFieldUpdated(true);
      setContactPhoneError('Contact phone should have exactly 10 digits');
      setContactPhone(formatWorkPhoneNumber.substring(0, 12)); // Truncate to 10 digits
    } else {
      setIsFieldUpdated(true);
      setContactPhoneError('');
      setContactPhone(formatWorkPhoneNumber);
    }
  };

  /* Function for validation of zipcode  */

  const zipCodeValidate = (zipCode) => {
    let zipCodePattern = zipCode.replace(/\D/g, ''); // Remove non-digit characters

    // If zipCodePattern has more than 5 digits, truncate it
    if (zipCodePattern.length > 5) {
      zipCodePattern = zipCodePattern.substring(0, 5);
    }

    if (!zipCodePattern) {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode('');
    } else if (zipCodePattern.length !== 5) {
      setIsFieldUpdated(true);
      setZipCodeError('ZIP code should have exactly 5 digits');
      setZipCode(zipCodePattern.substring(0, 5)); // Truncate to 5 digits
    } else {
      setIsFieldUpdated(true);
      setZipCodeError('');
      setZipCode(zipCodePattern);
    }
  };

  /* Function to set manufacturer product name setting value */

  const manufacturerProductNameValidate = (manufacturerProductName) => {
    if (!manufacturerProductName) {
      setmanufacturerProductName(
        manufacturerProductName !== '' ? '' : manufacturerProductName
      );
      setIsFieldUpdated(true);
      setmanufacturerProductNameError('Manufacturer product name is required');
      setzoomRoomProductName('');
    } else {
      setIsFieldUpdated(true);
      setmanufacturerProductNameError('');
      setzoomRoomProductNameError('');
      setmanufacturerProductName(manufacturerProductName);
      setzoomRoomProductName(manufacturerProductName);
    }
  };

  const address2validate = (address2) => {
    if (!address2) {
      setaddress2(address2 !== '' ? '' : address2);
      setIsFieldUpdated(true);

      setaddress2Error('address2 is requried');
    } else {
      setIsFieldUpdated(true);
      setaddress2Error('');
      setaddress2(address2);
    }
  };

  /* Function for setting value of product category validate */

  const businessEntityTypeValidate = (businessEntityType) => {
    const [Id, Name] = businessEntityType.split('|');
    if (!Id && !Name) {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(true);
      setBusinessEntityTypeId('');
      setBusinessEntityTypeName('');
    } else {
      setIsFieldUpdated(true);
      setBusinessEntityTypeError(false);
      setBusinessEntity(businessEntityType);
      setBusinessEntityTypeId(Id);
      setBusinessEntityTypeName(Name);
    }
  };

  /* Function for setting value of product category validate */

  const stateTypeValidate = (stateTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setStateTypeError(true);
      setStateType('');
    } else {
      setIsFieldUpdated(true);
      setStateTypeError(false);
      setStateType(stateTypeValidate);
    }
  };

  /* Function for setting value of product category validate */

  const cityValidate = (cityTypeValidate) => {
    if (!stateTypeValidate) {
      setIsFieldUpdated(true);
      setCityError(true);
      setCity('');
    } else {
      setIsFieldUpdated(true);
      setCityError(false);
      setCity(cityTypeValidate);
    }
  };

  /* Function for validation of Manufacturer  */

  const manufacturervalidate = (manufacturer) => {
    let manufacturerPattern = /[a-zA-Z ]/;
    // set field updated to true

    if (!manufacturer) {
      setIsFieldUpdated(true);
      setmanufacturer(manufacturer !== '' ? manufacturer : '');
      setmanufacturerError('Manufacturer is required');
    } else if (!manufacturerPattern.test(manufacturer)) {
      setIsFieldUpdated(true);
      setmanufacturerError('Manufacturer should be text');
    } else {
      setIsFieldUpdated(true);
      setmanufacturerError('');
      setmanufacturer(manufacturer);
    }
  };

  /* Function for setting value of manufacturer url */

  const manufacturerUrlvalidate = (manufacturerUrl) => {
    // if (!manufacturerUrl) {
    //   setIsFieldUpdated(true);
    //   setmanufacturerUrlError('Manufacturer url is required');
    // } else
    // const URL_REGEX = /^(http|https):\/\/([a-z]*\.)?[a-z]*\.[a-z]{2,}(\/)?$/;
    const URL_REGEX =
      /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(\/[^\s]*)?$/;

    if (manufacturerUrl && !URL_REGEX.test(manufacturerUrl)) {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('business url is not valid');
      setIsManufacturerUrlValid(true);
      setmanufacturerUrl(manufacturerUrl);
    } else {
      setIsFieldUpdated(true);
      setmanufacturerUrlError('');
      setmanufacturerUrl(manufacturerUrl);
      setIsManufacturerUrlValid(false);
    }
  };

  /* Function for validation of Zoom room product name  */

  const zoomRoomProductNamevalidate = (zoomRoomProductName) => {
    if (!zoomRoomProductName) {
      setzoomRoomProductName(
        zoomRoomProductName !== '' ? '' : zoomRoomProductName
      );
      setIsFieldUpdated(true);

      setzoomRoomProductNameError('Zoom room product name is requried');
    } else {
      setIsFieldUpdated(true);
      setzoomRoomProductNameError('');
      setzoomRoomProductName(zoomRoomProductName);
    }
  };

  /* Function for setting value of product category validate */

  const productCategoryvalidate = (productCategory) => {
    if (!productCategory) {
      setIsFieldUpdated(true);
      setproductCategoryError(true);
    } else {
      setIsFieldUpdated(true);
      setproductCategoryError(false);
      setproductCategoryId(productCategory);
    }
  };

  /* Function for state drop-down of varient type */

  const varientTypeValidate = (varientType) => {
    if (!varientType) {
      setIsFieldUpdated(true);
      setvarientTypeError('Variant type is required');
    } else {
      setIsFieldUpdated(true);
      setvarientTypeError('');
      setvariantDisabled(false);
      getVariants(varientType);
      // setvarientType(varientType);
      setvariantId(varientType);
    }
  };

  /* Function for setting varient */

  const varientSet = (varient) => {
    if (!varient) {
      setIsFieldUpdated(true);
      setvarientError('Variant is required');
    } else {
      setIsFieldUpdated(true);
      setvarientError('');
      setvalueId(varient);
    }
  };

  /* Function to set value of price  */
  const priceSet = (event) => {
    let input = event.target.value;
    input = input.replace(/[^0-9.]/g, '');
    // Allow only two digits after the decimal point
    const decimalIndex = input.indexOf('.');
    if (decimalIndex !== -1) {
      const parts = input.split('.');
      input = parts[0] + '.' + parts[1].slice(0, 2);
    }
    setprice(input);
    setIsFieldUpdated(true);
  };

  /* Function to set value of supplier */

  const supplierValidate = (supplier) => {
    if (!supplier) {
      setIsFieldUpdated(true);
      setsupplierError('Supplier is required');
    } else {
      setIsFieldUpdated(true);
      setsupplierError('');
      // setsupplierList(supplier);
      setsupplierId(supplier);
      // console.log(supplier);
    }
  };

  /* Function to set value of selling point */

  const sellingpointSet = (sellingPoint) => {
    if (!sellingPoint) {
      setsellingPoint(sellingPoint !== '' ? sellingPoint : '');
      setIsFieldUpdated(true);
      setsellingPointError('Selling point is error');
    } else {
      setIsFieldUpdated(true);
      setsellingPointError('');
      setsellingPoint(sellingPoint);
    }
  };

  /* Function to set value of product description */

  const productDescriptionSet = (productDescription) => {
    // if (!productDescription) {
    //   setproductDescription(
    //     productDescription !== '' ? productDescription : ''
    //   );
    //   setIsFieldUpdated(true);
    //   setproductDescriptionError('Product description is required');
    // } else {
    setIsFieldUpdated(true);
    setproductDescriptionError('');
    setproductDescription(productDescription);
    // }
  };

  /* Function to set the expiry date */

  const expiryDateSet = (expiryDate) => {
    if (!expiryDate) {
      setIsFieldUpdated(true);
      // setexpiryDateError('Expiry date is required');
      setexpiryDate('');
    } else {
      /* const formattedDate = expiryDate.toLocaleDateString('en-US', {
         year: 'numeric',
         month: '2-digit',
         day: '2-digit'
       });
 
       let newDate = formattedDate.replace(/\//g, '-');
       setIsFieldUpdated(true);
       setexpiryDateError('');
       setexpiryDate(newDate); */
      let newDate;
      setexpiryDateError('');
      let objectDate = new Date(expiryDate);
      // setStartdateObj(objectDate);

      let date = objectDate.getDate();
      if (date < 10) {
        newDate = '0' + date;
      } else {
        newDate = date;
      }
      let month = objectDate.getMonth() + 1;
      // let newMonth = "0" + month;
      let newMonth;
      newMonth = month < 10 ? '0' + month : month;
      let year = objectDate.getFullYear();
      let finalDate = newMonth + '-' + newDate + '-' + year;
      setexpiryDate(finalDate);
      setIsFieldUpdated(true);
    }
  };

  /* Function for validation of SKU Code  */

  const skuCodeValidate = (skuCode) => {
    // Remove all non-digit characters
    const skuCodePattern = skuCode.replace(/\D/g, '');

    if (!skuCode) {
      setIsFieldUpdated(true);
      setSkuCodeError('SKU Code is required');
      setSkuCode('');
    } else if (skuCode !== skuCodePattern) {
      setIsFieldUpdated(true);
      setSkuCodeError('SKU code should be a number');
      setSkuCode(skuCodePattern); // Set the SKU code to the filtered version with only numbers
    } else {
      setIsFieldUpdated(true);
      setSkuCodeError('');
      setSkuCode(skuCodePattern); // Set the SKU code to the filtered version with only numbers
    }
  };

  // const settings = {
  //   prevArrow: (
  //     <img
  //       src="/static/images/logo/prev-icon.png"
  //       alt="Previous"
  //       style={{
  //         position: 'absolute',
  //         left: 0,
  //         top: '50%',
  //         transform: 'translateY(-50%)'
  //       }}
  //     />
  //   ),
  //   nextArrow: (
  //     <img
  //       src="/static/images/logo/next-icon.png"
  //       alt="Next"
  //       style={{
  //         position: 'absolute',
  //         left: 0,
  //         top: '50%',
  //         transform: 'translateY(-50%)'
  //       }}
  //     />
  //   )
  // };

  return (
    <>
      <Loader IsLoading={isLoading} />

      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={1}
          mb={4}
        >
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                onClick={() => handleCloseEditProduct()}
                className="back-arrow-close"
              >
                <ArrowLeft />
              </Box>

              <Typography className="header-blue-32">
                Edit Business Entity
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="button"
                variant="contained"
                className="common-button"
                onClick={SubmitProduct}
              >
                <Typography className="next-button-text">Save</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: '20px'
                }}
              >
                 <ArrowLeft
                    className="back-arrow-style"
                    style={{ margin: '14px', fontSize: '30px' }}
                    onClick={() => handleCloseEditProduct()}
                />
                <h1 className="franchisee-name mb-0 mt-10">Edit Product</h1>
              </Box>
              <Button
                type="button"
                className="next-button pr-0 no-hover"
                onClick={SubmitProduct}
              >
                <Typography className="next-button-text">Save</Typography>
              </Button>
            </Box> */}
        <Box className="location-details-container" display={'flex'}>
          <Grid container xs={12}>
            <Grid container mt={3} className="location-details-row">
              <Grid item xs={12} sm={12} className="location-details-col">
                <Box className="container-text-field">
                  <Card
                    sx={{
                      backgroundColor: '#FFFFFF'
                    }}
                  >
                    <CardContent className="pr-0 pl-0">
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                          <Typography
                            className="table-heade header-blue-20"
                            mb={2}
                          >
                            Business Information
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            component="form"
                            id="form-add"
                          >
                            <Grid item xs={12} sm={6}>
                              <FormControl
                                className="select-drop-down-label-styling  required-field"
                                fullWidth
                                error={businessEntityTypeError}
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling required-label"
                                >
                                  BUSINESS ENTITY TYPE
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="BUSINESS ENTITY TYPE"
                                  className="input-field-styling"
                                  value={businessEntity}
                                  onChange={(e) =>
                                    businessEntityTypeValidate(e.target.value)
                                  }
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                  //   options={product.map((option) => option.categoryName)}
                                >
                                  {productCategoryList.length > 0 &&
                                    productCategoryList.map((option) => (
                                      <MenuItem
                                        key={option.sortKey}
                                        value={
                                          option.sortKey +
                                          '|' +
                                          option.businessEntityType
                                        }
                                      >
                                        {option.businessEntityType}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {businessEntityTypeError && (
                                  <FormHelperText>
                                    Please select business entity type
                                  </FormHelperText>
                                )}
                              </FormControl>
                              {/* <FormControl
                            className="field-top-margin-edit-location location-field required-field"
                            fullWidth
                            error={productCategoryError}
                          >
                            <Autocomplete
                              classes={autoCompleteClasses}
                              // value={getCity || ''}
                              // value={filters.categoryName ?? ''}
                              onChange={(event, newValue) => {
                                productCategoryvalidate(newValue);
                              }}
                              // onInputChange={(event, newInputValue) => {
                              //   handleCategoryChange(newInputValue);
                              // }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              className="status-field field-label-css"
                              options={productCategoryList.map((option) => option.categoryName)}
                              sx={{
                                width: '100%',
                                borderRadius: '19px !important'
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="product_category input-field-dropdown-styling required-label"
                                  label="PRODUCT CATEGORY"
                                  sx={{
                                    fontSize: '16px !important',
                                    fontWeight: '600 !important',
                                  }}
                                />
                              )}
                            />
                            {productCategoryError && (
                              <FormHelperText>
                                Please select product category
                              </FormHelperText>
                            )}
                          </FormControl> */}
                              {/* {isFranchisor !== 'true' && (
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 700,
                                    color: '#003087',
                                    textDecoration: 'underline',
                                    marginRight: '5px'
                                    // display: 'none'
                                  }}
                                  className="add-new-category align-right"
                                >
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      addNewCategory();
                                    }}
                                  >
                                    +Add ENTITY
                                  </span>
                                </Typography>
                              )} */}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {/* <div>
                            <TextField
                              fullWidth
                              value={searchTerm}
                              onChange={handleSearchTermChange}
                              placeholder="Search..."
                            />
                            {filteredCategories.length > 0 && (
                              <ul>
                                {filteredCategories.map(category => (
                                  <li key={category} role='button' onClick onKeyDown={() => handleCategorySelect(category)}>
                                    {category}
                                  </li>
                                ))}
                              </ul>
                            )}
                            {!selectedCategory && searchTerm && !categories.includes(searchTerm) && (
                              <button type='button' onClick={handleAddNewCategory}>Add new category: {searchTerm}</button>
                            )}
                          </div> */}

                              <TextField
                                fullWidth
                                name="BUSINESS NAME"
                                autocomplete="BUSINESS NAME"
                                label="BUSINESS NAME"
                                placeholder="BUSINESS NAME"
                                id="locationId"
                                value={businessName}
                                className="location-field input-field-styling required-field"
                                error={Boolean(businessNameError)}
                                helperText={businessNameError}
                                onChange={(e) =>
                                  businessNameValidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="SKU CODE"
                          autocomplete="SKU CODE"
                          label="SKU CODE"
                          className="location-field required-field input-field-styling"
                          error={Boolean(skuCodeError)}
                          helperText={skuCodeError}
                          value={skuCode}
                          placeholder="SKU CODE"
                          onChange={(e) => skuCodeValidate(e.target.value)}
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee required-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid> */}
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="BUSINESS EMAIL"
                                autocomplete="BUSINESS EMAIL"
                                label="BUSINESS EMAIL"
                                className="field-top-margin-edit-location location-field input-field-styling"
                                error={Boolean(businessEmailerror)}
                                helperText={businessEmailerror}
                                value={businessEmail}
                                placeholder="BUSINESS EMAIL"
                                onChange={(e) =>
                                  businessEmailValidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className: 'label-style-create-franchisee'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="BUSINESS PHONE"
                                autocomplete="BUSINESS PHONE"
                                label="BUSINESS PHONE"
                                className="field-top-margin-edit-location location-field input-field-styling"
                                error={Boolean(businessPhoneError)}
                                helperText={businessPhoneError}
                                value={businessPhone}
                                placeholder="BUSINESS PHONE"
                                onChange={(e) =>
                                  businessPhoneValidate(e.target.value)
                                }
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee required-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                name="BUSINESS URL"
                                autocomplete="BUSINESS URL"
                                label="BUSINESS URL"
                                className="field-top-margin-edit-location location-field input-field-styling"
                                value={manufacturerUrl}
                                error={Boolean(manufacturerUrlError)}
                                helperText={manufacturerUrlError}
                                onChange={(e) =>
                                  manufacturerUrlvalidate(e.target.value)
                                }
                                placeholder="BUSINESS URL"
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="ADDRESS"
                                autocomplete="ADDRESS"
                                label="ADDRESS"
                                placeholder="ADDRESS"
                                className=" location-field required-field input-field-styling"
                                // error={Boolean(zoomRoomProductNameError)}
                                // helperText={zoomRoomProductNameError}
                                onChange={(e) =>
                                  zoomRoomProductNamevalidate(e.target.value)
                                }
                                value={zoomRoomProductName}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'

                                  // className:
                                  // 'label-style-create-franchisee edit-address-label required-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="ADDRESS 2"
                                autocomplete="ADDRESS 2"
                                label="ADDRESS 2"
                                placeholder="ADDRESS 2"
                                className=" location-field  input-field-styling"
                                // className=" location-field required-field input-field-styling"
                                // error={Boolean(address2Error)}
                                // helperText={address2Error}
                                onChange={(e) =>
                                  address2validate(e.target.value)
                                }
                                value={address2}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} mt={2}>
                              <FormControl
                                error={stateTypeError}
                                fullWidth
                                className="select-drop-down-label-styling"
                              >
                                <InputLabel
                                  id="demo-simple-select-helper-label"
                                  className="input-field-dropdown-styling"
                                >
                                  STATE
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="STATE"
                                  name="state"
                                  value={stateType}
                                  onChange={(e) =>
                                    stateTypeValidate(e.target.value)
                                  }
                                  // error={Boolean(stateTypeError)}
                                  className="input-field-styling"
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {varientType.length > 0 &&
                                    varientType.map((r, i) => (
                                      <MenuItem key={i} value={r.state}>
                                        {r.state}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {/* {stateTypeError && (
                                  <FormHelperText>
                                    Please select state
                                  </FormHelperText>
                                )} */}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="CITY"
                                autocomplete="CITY"
                                label="CITY"
                                placeholder="CITY"
                                className=" location-field required-field input-field-styling"
                                error={Boolean(cityError)}
                                helperText={cityError}
                                onChange={(e) => cityValidate(e.target.value)}
                                value={city}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="ZIPCODE"
                                autocomplete="ZIPCODE"
                                label="ZIPCODE"
                                placeholder="ZIPCODE"
                                className=" location-field  input-field-styling"
                                error={Boolean(zipCodeError)}
                                helperText={zipCodeError}
                                onChange={(e) =>
                                  zipCodeValidate(e.target.value)
                                }
                                value={zipCode}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            {/* <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidthl
                          name="PRICE"
                          autocomplete="PRICE"
                          label="PRICE"
                          className="field-top-margin-edit-locatio location-field input-field-styling"
                          value={price}
                          // error={Boolean(priceError)}
                          // helperText={priceError}
                          onChange={priceSet}
                          placeholder="PRICE"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid> */}

                            {/* <Grid container sx={{ marginTop: '8px' }} xs={12} > */}

                            {/* </Grid> */}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Typography
                            className="table-heade header-blue-20"
                            mb={2}
                          >
                            Primary Contact Information
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            component="form"
                            id="form-add"
                          >
                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="Contact Name"
                                autocomplete="Contact Name"
                                label="Contact Name"
                                placeholder="Contact Name"
                                className=" location-field input-field-styling"
                                error={Boolean(contactNameError)}
                                helperText={contactNameError}
                                onChange={(e) =>
                                  contactNameValidate(e.target.value)
                                }
                                value={contactName}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="Contact Email"
                                autocomplete="Contact Email"
                                label="Contact Email"
                                placeholder="Contact Email"
                                className=" location-field required-field input-field-styling"
                                error={Boolean(contactEmailError)}
                                helperText={contactEmailError}
                                onChange={(e) =>
                                  contactEmailValidate(e.target.value)
                                }
                                value={contactEmail}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} mt={2}>
                              <TextField
                                fullWidth
                                name="Contact Phone"
                                autocomplete="Contact Phone"
                                label="Contact Phone"
                                placeholder="Contact Phone"
                                className=" location-field input-field-styling"
                                error={Boolean(contactPhoneError)}
                                helperText={contactPhoneError}
                                onChange={(e) =>
                                  contactPhoneValidate(e.target.value)
                                }
                                value={contactPhone}
                                InputLabelProps={{
                                  className:
                                    'label-style-create-franchisee edit-address-label'
                                }}
                                InputProps={{}}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                fullWidth
                                multiline
                                id="my-textarea"
                                name="Notes"
                                autocomplete="Notes"
                                label="Notes"
                                placeholder="Notes"
                                variant="outlined"
                                value={productDescription}
                                className="location-description field-top-margin-edit-location"
                                // error={Boolean(productDescriptionError)}
                                // helperText={productDescriptionError}
                                onChange={(e) =>
                                  productDescriptionSet(e.target.value)
                                }
                                InputLabelProps={{
                                  className: 'label-style-create-franchisee'
                                }}
                                InputProps={{ rows: 6 }}
                              />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidthl
                          name="PRICE"
                          autocomplete="PRICE"
                          label="PRICE"
                          className="field-top-margin-edit-locatio location-field input-field-styling"
                          value={price}
                          // error={Boolean(priceError)}
                          // helperText={priceError}
                          onChange={priceSet}
                          placeholder="PRICE"
                          InputLabelProps={{
                            className:
                              'label-style-create-franchisee edit-address-label'
                          }}
                          InputProps={{}}
                        />
                      </Grid> */}

                            {/* <Grid container sx={{ marginTop: '8px' }} xs={12} > */}

                            {/* </Grid> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Delete confirmation dialog */}
      {/* Delete confirmation dialog */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Save Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                  Please confirm that you want to save your changes?
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Business Entity has been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Create Variant dialog */}
      {/* {openAddVariant && (<CustomizedDialogs />)} */}
      <Dialog
        open={openAddVarientType}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Variant Type'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="VARIANT TYPE"
                    name="variantName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setvarientTypeName(e.target.value)}
                    required
                    value={variantName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateVariantType()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Category dialog */}
      <Dialog
        open={openAddCategory}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Category'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="CATEGORY NAME"
                    name="categoryName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setcategoryName(e.target.value)}
                    required
                    value={categoryName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    multiline
                    id="categoryDescription"
                    name="CatDescription"
                    autocomplete="Category Description"
                    label="CATEGORY DESCRIPTION"
                    placeholder="Description . . . "
                    required
                    value={categoryDescription}
                    variant="outlined"
                    className="location-description field-top-margin-edit-location"
                    // error={Boolean(productDescriptionError)}
                    // helperText={productDescriptionError}
                    onChange={(e) => setcategoryDescription(e.target.value)}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                    InputProps={{ rows: 4 }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateCategory()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClosePopup('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Save Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                Please confirm that you want to save your changes?
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClosePopup('Discard')}
          >
            Go Back to Editing
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleClosePopup('Save')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Variant dialog */}
      <Dialog
        open={openAddVariant}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCloseVariant()}
        aria-describedby="alert-dialog-slide-description"
        // sx={{ width: '350px' }}
      >
        <DialogTitle className="alert-title align-center">
          {'Add Variant'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box
              display="flex"
              justifyContent="center"
              direction="column"
              width={300}
              mt={1}
              mb={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label="VARIANT NAME"
                    name="variantName"
                    className="input-field-styling label-style-create-franchisee"
                    onChange={(e) => setvariantName(e.target.value)}
                    required
                    value={variantName}
                    variant="outlined"
                    InputLabelProps={{
                      sx: { fontWeight: '600', fontSize: '16px' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    className="select-drop-down-label-styling"
                  >
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className="weight-700 font-15"
                    >
                      VARIANT TYPE
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="variantId"
                      onChange={handleVariantTypeChange}
                      value={variantTypeId}
                      label="VARIANT TYPE"
                      className="input-field-styling align-left"
                      required
                      MenuProps={{
                        PaperProps: { style: { maxHeight: 200 } }
                      }}
                    >
                      {varientType.length > 0 &&
                        varientType.map((r, i) => (
                          <MenuItem key={i} value={r.variantId}>
                            {r.variantType}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseVariant()}
          >
            {'Close'}
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleCreateVariant()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditBusnessEntities;
