import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DynamicButton, DynamicModal } from '../../../../components/Checkout';
import { ApiService } from 'src/services/ApiService';

import ReactDOMServer from 'react-dom/server';
// import { useNavigate } from 'react-router-dom';
import { getLocalData } from 'src/utils';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import Receipt from '../Components/Receipt';

export const OrderSuccess = ({
  handlePage,
  from,
  selected,
  setActiveStep,
  setIsSuccess
}) => {
  // const navigate = useNavigate();
  let BASE_URL = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}`;
  const locationId = getLocalData('locationId');

  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [intervalCount, setIntervalCount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentSourceType, setPaymentSourceType] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setIntervalCount(0);
  }, []);

  useEffect(() => {
    const confirmRefresh = (e) => {
      if (processing) {
        e.returnValue =
          'Leaving this page will stop the payment processing. Are you sure?';
      }
    };
    window.onbeforeunload = confirmRefresh;

    // Clean up the event listener when the component unmounts
    return () => {
      window.onbeforeunload = null;
    };
  }, [processing]);

  // CALLING PAYMENT DETAILS API WITH A 2 SEC INTERVAL
  useEffect(() => {
    if (processing && intervalCount < 17) {
      const interval = setInterval(() => {
        setIntervalCount(intervalCount + 1);
        paymentDetails();
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }

    if (intervalCount > 16) {
      setProcessing(false);
      setPaymentStatus('PENDING');
    }

    // If processing is false, return a no-op function
    return () => {};
  }, [processing, intervalCount]);

  const paymentDetails = async () => {
    // const squareLocationId = selected?.squareLocationId;
    const sqaureOrderId = selected?.sqaureOrderId;
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/paymentDetails?locationId=${encodedLocationId}&sqaureOrderId=${sqaureOrderId}`
      );
      if (response.statusCode === 200) {
        if (response?.data?.Items?.[0]?.paymentStatus) {
          let paymentStatus = response?.data?.Items[0]?.paymentStatus;

          if (
            paymentStatus &&
            (paymentStatus === 'COMPLETED' ||
              paymentStatus === 'PENDING' ||
              paymentStatus === 'FAILED' ||
              paymentStatus === 'CANCELLED')
          ) {
            setPaymentStatus(response?.data?.Items[0]?.paymentStatus);
            setPaymentSourceType(response?.data?.Items[0]?.paymentSourceType);
            setProcessing(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const printReceipt = () => {
    const receiptHtml = ReactDOMServer.renderToStaticMarkup(
      <Receipt selected={selected} paymentSourceType={paymentSourceType} />
    );

    const printWindow = window.open('', '', 'width=600,height=600');

    if (printWindow) {
      // Ensure the new window is opened only once
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Receipt</title>
          </head>
          <body>
            ${receiptHtml}
          </body>
        </html>
      `);
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    } else {
      alert('Pop-up window blocked. Please allow pop-ups for this site.');
    }
  };

  const handleEmailReceipt = async () => {
    const ZROrderIdsss = encodeURIComponent(selected?.ZROrderId);
    let encodedLocationId = encodeURIComponent(locationId);

    try {
      let response = await ApiService.get(
        `${BASE_URL}checkout/compEmailReceipt?locationId=${encodedLocationId}&orderId=${ZROrderIdsss}`
      );

      if (response?.statusCode === 200) {
        setShowSuccessMessage(true);
        setSuccessMessage(
          response?.data?.message || 'Email has been sent successfully'
        );
      }
    } catch (error) {
      console.error(error);
      setShowMessage(true);
      setErrorMessage(error?.message || 'Something went Wrong');
    }
  };

  return (
    <>
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={() => setShowSuccessMessage(false)}
        />
      )}
      {processing ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="spinner-box">
            <div className="circle-border">
              <div className="circle-core" />
            </div>
          </div>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            style={{ marginBottom: '15px' }}
          >
            Payment is processing...
          </Typography>
          <Typography
            variant="h1"
            align="center"
            color="primary"
            style={{ marginBottom: '15px' }}
          >
            Please do not press Back or
            <br />
            Refresh
          </Typography>
        </Box>
      ) : (
        <Box className="orderSuccess">
          {paymentStatus === 'COMPLETED' ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                direction="column"
                marginTop="0"
                className="tick-container"
              >
                <img
                  src={'/static/images/logo/Tick.png'}
                  style={{ maxWidth: '100%' }}
                  className="tick-image"
                  alt="zoom-room-logo"
                />
              </Box>
              <Typography
                variant="h1"
                align="center"
                color="primary"
                style={{ marginBottom: '15px' }}
              >
                {from ? 'Transfer Successful' : 'Order Successful'}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                style={{
                  maxWidth: '369px',
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#000000',
                  margin: '0 auto 35px'
                }}
              >
                {from
                  ? 'Congratulations! You have successfully transferred the amount to the store account. Thank you for your purchase.'
                  : 'Congratulations! Your payment has been successfully processed. Thank you for your purchase.'}
              </Typography>
            </>
          ) : paymentStatus === 'PENDING' ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                direction="column"
                marginTop="0"
                className="tick-container"
              >
                <svg
                  width="123"
                  height="123"
                  viewBox="0 0 452 452"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <path
                    d="M225.312 17.0068C340.736 17.0068 434.304 110.576 434.304 225.999C434.304 341.423 340.736 434.991 225.312 434.991M216 107V237.62L320.496 342.116M149.728 31.1027C149.728 31.1027 154.769 29.0848 160.339 27.3443C165.909 25.6039 172.369 23.7793 172.369 23.7793M86.2607 69.916C86.2607 69.916 90.2758 66.2599 94.8841 62.6793C99.4923 59.0987 104.904 55.127 104.904 55.127M40.4048 128.497C40.4048 128.497 42.8858 123.666 45.9478 118.698C49.0099 113.73 52.6876 108.115 52.6876 108.115M17.9678 199.427C17.9678 199.427 18.6 194.033 19.7282 188.307C20.8564 182.582 22.3349 176.034 22.3349 176.034M21.7933 273.723C21.7933 273.723 20.496 268.45 19.5482 262.692C18.6003 256.934 17.6924 250.283 17.6924 250.283M51.3947 341.974C51.3947 341.974 48.3326 337.489 45.4284 332.427C42.5234 327.366 39.3442 321.454 39.3442 321.454M103.023 395.537C103.023 395.537 98.5852 392.408 94.0915 388.684C89.5977 384.961 84.5498 380.537 84.5498 380.537M170.141 427.626C170.141 427.626 164.888 426.25 159.375 424.335C153.862 422.42 147.584 420.045 147.584 420.045"
                    stroke="#FDA91A"
                    strokeWidth="34"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
              <Typography
                variant="h1"
                align="center"
                color="primary"
                style={{ marginBottom: '15px' }}
              >
                Payment status pending
              </Typography>
              <Typography
                variant="body1"
                align="center"
                style={{
                  maxWidth: '369px',
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#000000',
                  margin: '0 auto 35px'
                }}
              >
                We will update you once the payment status updates.
              </Typography>
            </>
          ) : paymentStatus === 'CANCELLED' ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                direction="column"
                marginTop="0"
                className="tick-container"
              >
                <svg
                  width="123"
                  height="123"
                  viewBox="0 0 444 444"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <path
                    d="M222 17.0093C495.333 26.3156 495.333 417.684 222 426.991C-51.3333 417.684 -51.3333 26.3156 222 17.0093Z"
                    stroke="#F44336"
                    strokeWidth="34"
                  />
                  <path
                    d="M246.217 341.504C246.217 328.051 235.312 317.145 221.859 317.145C208.406 317.145 197.5 328.052 197.5 341.504C197.5 354.956 208.407 365.862 221.859 365.862C235.311 365.862 246.217 354.957 246.217 341.504ZM242.093 98.734C242.093 87.6039 232.989 78.5 221.859 78.5C210.729 78.5 201.625 87.6039 201.625 98.734V261.584C201.625 272.714 210.729 281.818 221.859 281.818C232.989 281.818 242.093 272.714 242.093 261.584V98.734Z"
                    fill="#F44336"
                    stroke="#F44336"
                  />
                </svg>
              </Box>
              <Typography
                variant="h1"
                align="center"
                color="primary"
                style={{ marginBottom: '15px' }}
              >
                Payment Cancelled
              </Typography>
              <Typography
                variant="body1"
                align="center"
                style={{
                  maxWidth: '369px',
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#000000',
                  margin: '0 auto 35px'
                }}
              >
                Payment for this order is cancelled
              </Typography>
            </>
          ) : paymentStatus === 'FAILED' ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                direction="column"
                marginTop="0"
                className="tick-container"
              >
                <svg
                  width="123"
                  height="123"
                  viewBox="0 0 444 444"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <path
                    d="M222 17.0093C495.333 26.3156 495.333 417.684 222 426.991C-51.3333 417.684 -51.3333 26.3156 222 17.0093Z"
                    stroke="#F44336"
                    strokeWidth="34"
                  />
                  <path
                    d="M246.217 341.504C246.217 328.051 235.312 317.145 221.859 317.145C208.406 317.145 197.5 328.052 197.5 341.504C197.5 354.956 208.407 365.862 221.859 365.862C235.311 365.862 246.217 354.957 246.217 341.504ZM242.093 98.734C242.093 87.6039 232.989 78.5 221.859 78.5C210.729 78.5 201.625 87.6039 201.625 98.734V261.584C201.625 272.714 210.729 281.818 221.859 281.818C232.989 281.818 242.093 272.714 242.093 261.584V98.734Z"
                    fill="#F44336"
                    stroke="#F44336"
                  />
                </svg>
              </Box>
              <Typography
                variant="h1"
                align="center"
                color="primary"
                style={{ marginBottom: '15px' }}
              >
                Payment Failed
              </Typography>
              <Typography
                variant="body1"
                align="center"
                style={{
                  maxWidth: '369px',
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#000000',
                  margin: '0 auto 35px'
                }}
              >
                Unfortunately your payment was failed
              </Typography>
            </>
          ) : paymentStatus === 'TIMEOUT' ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                direction="column"
                marginTop="0"
                className="tick-container"
              >
                <svg
                  width="123"
                  height="123"
                  viewBox="0 0 452 452"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <path
                    d="M225.312 17.0068C340.736 17.0068 434.304 110.576 434.304 225.999C434.304 341.423 340.736 434.991 225.312 434.991M216 107V237.62L320.496 342.116M149.728 31.1027C149.728 31.1027 154.769 29.0848 160.339 27.3443C165.909 25.6039 172.369 23.7793 172.369 23.7793M86.2607 69.916C86.2607 69.916 90.2758 66.2599 94.8841 62.6793C99.4923 59.0987 104.904 55.127 104.904 55.127M40.4048 128.497C40.4048 128.497 42.8858 123.666 45.9478 118.698C49.0099 113.73 52.6876 108.115 52.6876 108.115M17.9678 199.427C17.9678 199.427 18.6 194.033 19.7282 188.307C20.8564 182.582 22.3349 176.034 22.3349 176.034M21.7933 273.723C21.7933 273.723 20.496 268.45 19.5482 262.692C18.6003 256.934 17.6924 250.283 17.6924 250.283M51.3947 341.974C51.3947 341.974 48.3326 337.489 45.4284 332.427C42.5234 327.366 39.3442 321.454 39.3442 321.454M103.023 395.537C103.023 395.537 98.5852 392.408 94.0915 388.684C89.5977 384.961 84.5498 380.537 84.5498 380.537M170.141 427.626C170.141 427.626 164.888 426.25 159.375 424.335C153.862 422.42 147.584 420.045 147.584 420.045"
                    stroke="#F44336"
                    strokeWidth="34"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
              <Typography
                variant="h1"
                align="center"
                color="primary"
                style={{ marginBottom: '15px' }}
              >
                Timed Out
              </Typography>
              <Typography
                variant="body1"
                align="center"
                style={{
                  maxWidth: '369px',
                  fontWeight: '400',
                  fontSize: '16px',
                  color: '#000000',
                  margin: '0 auto 35px'
                }}
              >
                Your payment is not completed yet.
              </Typography>
            </>
          ) : null}
          <Box
            style={{
              maxWidth: '330px',
              display: 'flex',
              justifyContent: 'space between',
              alignItems: 'center',
              margin: '0 auto'
            }}
          >
            {/* PRINT RECEIPT AND TRY AGAIN BUTTON PLACEMENT */}
            {paymentStatus === 'COMPLETED' && (
              <DynamicButton
                title={'Print Receipt'}
                variant="contained"
                onClick={printReceipt}
                style={{
                  padding: '10px 23px',
                  borderRadius: '10px',
                  textTransform: 'capitalize',
                  maxWidth: 'max-content',
                  fontWeight: '600',
                  fontSize: '16px',
                  margin: '0 auto 24px',
                  border: '2px solid #003087'
                }}
              />
            )}

            {paymentStatus === 'COMPLETED' && paymentSourceType === 'COMP' && (
              <DynamicButton
                title={'Email Receipt'}
                variant="contained"
                onClick={handleEmailReceipt}
                style={{
                  padding: '10px 23px',
                  borderRadius: '10px',
                  textTransform: 'capitalize',
                  maxWidth: 'max-content',
                  fontWeight: '600',
                  fontSize: '16px',
                  margin: '0 auto 24px 0',
                  border: '2px solid #003087'
                }}
              />
            )}

            {(paymentStatus === 'FAILED' ||
              paymentStatus === 'CANCELLED' ||
              paymentStatus === 'TIMEOUT') && (
              <DynamicButton
                title={'Try Again'}
                variant="contained"
                onClick={() => {
                  setActiveStep(3);
                  setIsSuccess(false);
                }}
                style={{
                  padding: '10px 23px',
                  borderRadius: '10px',
                  textTransform: 'capitalize',
                  maxWidth: 'max-content',
                  fontWeight: '600',
                  fontSize: '16px',
                  margin: '0 auto 24px',
                  border: '2px solid #003087'
                }}
              />
            )}
          </Box>
          <DynamicButton
            title={'Go back to Checkout'}
            onClick={() => {
              handlePage();
              // navigate(
              //   updatedString
              //     ? `/sales-history/${updatedString}`
              //     : `/sales-history`
              // );
            }}
            variant="text"
            style={{
              padding: '0',
              maxWidth: 'max-content',
              background: 'transparent',
              textDecoration: 'underline',
              fontWeight: '600',
              fontSize: '15px',
              margin: '0 auto',
              display: 'block'
            }}
          />
        </Box>
      )}
      <DynamicModal
        className={'checkoutModal'}
        open={open}
        handleDialog={() => setOpen(false)}
        selected={selected}
      />
    </>
  );
};
