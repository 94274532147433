/* eslint-disable spaced-comment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/named */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Slide, Box, Container } from '@mui/material';
import CohortsList from './CohortsList';
import Footer from 'src/components/Footer';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import GlobalData from '../../../global';
import { getLocalData } from 'src/utils';
import CreateCohorts from './CreateCohorts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function Cohorts() {
  const navigate = useNavigate();
  const title = GlobalData[0].title;
  const childRef = useRef(null);

  const [showScreen, setShowScreen] = useState(0);
  const [called, setCalled] = useState(false);

  useEffect(() => {
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, [navigate]);



  const handleButtonClick = () => {
    setShowScreen(1);
  };

  const buttons = [
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theme: true // Corrected typo 'theam' to 'theme'
    },
  ];

  return (
    <>
      <Helmet>
        <title>Cohorts | {title}</title>
      </Helmet>


      <Container maxWidth="100%" className="custom-container">
        {(() => {
          switch (showScreen) {
            case 1:
              return (
                <Box justifyContent="center" direction="column">
                  {/* AddCampaign component */}
                  <CreateCohorts setShowScreen={setShowScreen} setCalled={setCalled}/>
                </Box>
              );
            default:
              return (
                <><Box sx={{ pl: '15px', pr: '22px' }}>
                  {showScreen === 0 && <Header title="Cohorts" buttons={buttons} />}

                </Box>
                  <Grid item width={'100%'}>
                    <CohortsList ref={childRef} setShowScreen={setShowScreen} showScreen={showScreen}called={called} />
                  </Grid></>
              );
          }
        })()}
      </Container>
    </>
  );
}

export default function CohortsWrapper() {
  return (
    <>
      <Cohorts />
      <Footer />
    </>
  );
}
