import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Slide,
  Stack,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { Toggle } from 'src/components/Global';
import { useNavigate } from 'react-router-dom';
import { getLocalData, maskCardNumber } from '../../../utils';
import { ApiService } from '../../../services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import debounce from 'lodash/debounce';
import Loader from '../../../components/Loader';
import { useCallback } from 'react';
// import IconButton from '@mui/material/IconButton';
// import ScanCard from '../../../assets/Images/credit-card-scan-outline.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddGiftCard = ({
  setOpenAddGiftCard,
  openAddGiftCardModal,
  setAddGiftCardModal
}) => {
  const navigate = useNavigate();
  const locationId = getLocalData('locationId');
  let userId = getLocalData('userId');
  const [open, setOpen] = useState(false);
  const [custom, setCustom] = useState(false);
  const [preDisValue, setPreDisValue] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [existValue, setExistValue] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardValue, setCardValue] = useState(existValue || '');
  const [cardName, setCardName] = useState('');
  const [cardNameError, setCardNameError] = useState('');
  const [cardValueError, setCardValueError] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [discountError, setDiscountError] = useState('');
  const [cardId, setCardId] = useState('1234');
  const [addNew, setAddNew] = useState('AddNew');
  const [transfer, setTransfer] = useState(false);
  const [pricingDataValue, setPricingDataValue] = useState([]);
  useEffect(() => {
    if (pricingDataValue.length > 0) {
      let obj = pricingDataValue.filter((data) => {
        return data.giftCardPriceValue === existValue.giftCardValue;
      });
      setCardValue(obj[0]);
    }
  }, [pricingDataValue, existValue]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState('');
  const [isExist, setIsExist] = useState(false);
  const [existId, setExistId] = useState('');
  const [isExistError, setIsExistError] = useState(false);

  const transferAmount = cardData.giftCardValue
    ? cardData.giftCardValue - (cardValue?.giftCardPriceValue || cardValue)
    : '0';

  function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }
  useEffect(() => {
    setIsCreated(false);
    setCardId(generateRandomString());
    getGiftCardPricing();
    localStorage.setItem('activeStep', '0');
  }, []);

  useEffect(() => {
    setCardNumber('');
    setCardName('');
    setCardValue('');
    setIsExistError(false);
    if (addNew === 'AddNew') {
      setCardData('');
      setTransfer(false);
    }
    setIsExist(false);
    setExistId('');
    setCardValueError('');
    setCardNameError('');
    setCardNumberError('');
  }, [addNew]);

  const handleClose = () => {
    if (
      preDisValue ||
      custom ||
      discount !== '' ||
      cardNumber !== '' ||
      cardValue !== '' ||
      cardName !== ''
    ) {
      setOpen(true);
    } else {
      setAddGiftCardModal(false);
      setOpenAddGiftCard(false);
    }
  };

  const handleCloseConfirm = (type) => {
    if (type === 'Discard') {
      setAddGiftCardModal(false);
      setOpenAddGiftCard(false);
      setCustom(false);
      setPreDisValue(false);
      setDiscount('');
      setCardNumber('');
      setCardValue('');
      setCardName('');
      setCardNameError('');
      setCardValueError('');
      setCardNumberError('');
      setDiscountError('');
      setTransfer(false);
      setOpen(false);
      setCardData('');
      setIsExist(false);
      setExistId('');
      setIsExistError(false);
    } else {
      setOpen(false);
    }
  };
  const handleToggleChange = (name, value) => {
    if (name === 'custom') {
      setCustom(value);
      if (value) {
        setPreDisValue(false);
      }
      setCardValue('');
      setDiscount(0);
      setCardValueError('');
    }
    if (name === 'preDisValue') {
      setPreDisValue(value);
    }
    if (name === 'transfer') {
      setTransfer(value);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'Card Number') {
      const formattedValue = value?.toUpperCase()?.replace(/[^0-9A-Z]/g, '');
      if (formattedValue?.length <= 12) {
        setCardNumber(formattedValue);
      }
      if (formattedValue?.length > 5 && formattedValue?.length <= 12) {
        if (addNew === 'Existing') {
          getCardNumberDetail(formattedValue);
        } else {
          setIsExistError(true);
          debouncedIsExist(formattedValue);
        }
      }
      if (formattedValue?.length < 6) {
        setCardNumberError('Card number should be at least 6 characters long.');
      } else if (formattedValue?.length <= 12) {
        setCardNumberError('');
      }
    }
    if (name === 'Card Name') {
      setCardName(value);
      if (!value) {
        setCardNameError('Card name is required.');
      } else {
        setCardNameError('');
      }
    }

    if (name === 'Card Value') {
      const formattedValue = value?.replace(/[^0-9.]/g, '');
      const decimalParts = formattedValue?.split('.');

      if (decimalParts.length > 1) {
        decimalParts[1] = decimalParts[1]?.slice(0, 2);
      }

      const newValue = decimalParts?.join('.');
      setCardValue(newValue);
      setCardValueError('');

      if (!newValue || Number(newValue) <= 0) {
        setCardValueError('Invalid card value. Must be a positive number.');
      }
    }
    if (name === 'Discount') {
      setDiscount(value);

      if (!value || Number(value) < 0 || Number(value) > 100) {
        setDiscountError(
          'Invalid discount value. Must be a number between 0 and 100.'
        );
      } else {
        setDiscountError('');
      }
    }
    if (name === 'Transfer') {
      // setTransferValue(value);

      if (!value || Number(value) < 0 || Number(value) > 100) {
        // setTransferError(
        //   'Invalid discount value. Must be a number between 0 and 100.'
        // );
      } else {
        // setTransferError('');
      }
    }
    if (name === 'Card Id') {
      setCardId(value);
    }
  };

  const getGiftCardPricing = async () => {
    setIsLoading(true);
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/getAllGiftCardPrices?locationId=${encodedLocationId}&status=1`
      );

      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];

      const giftcardvalue = Array.from(new Set(Item));

      setPricingDataValue(giftcardvalue);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getCardNumberDetail = async (value) => {
    try {
      let encodedLocationId = encodeURIComponent(locationId);
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/isCardExists?locationId=${encodedLocationId}&giftCardNumber=${value}`
      );

      if (response.statusCode === 200) {
        if (response?.data?.result?.Items?.length) {
          setCardName(response?.data?.result?.Items[0]?.giftCardName);
          setCardData(response?.data?.result?.Items[0]);
          setCardNameError('');
          if (!transfer) {
            setCustom(Boolean(response?.data?.result?.Items[0]?.custom));
          }
          setPreDisValue(
            response?.data?.result?.Items[0]?.presetDiscountedValues
          );
          setCardValue('');
          setCardNumber(
            maskCardNumber(response?.data?.result?.Items[0]?.giftCardNumber)
          );
          setCardNumber(value);
          if (response?.data?.result?.Items?.length > 0) {
            setIsExist(true);
            setExistId(response?.data?.result?.Items[0]?.sortKey);
          }
          // eslint-disable-next-line array-callback-return
          response?.data?.result?.Items.map((item) => {
            item.giftCardPriceValue = item.giftCardValue;
          });
          setCardNumberError('');
          setExistValue(response?.data?.result?.Items[0]);
        } else {
          setCardName('');
          setCardNumberError(response?.message);
        }
      } else {
        setCardName('');
        setShowErrorMessage(true);
        setErrorMessage(response.message);
      }
    } catch (error) {
      setCardName('');
      console.log(error);
      setShowErrorMessage(true);
      setErrorMessage(error.message);
    }
  };

  const checkCardNumberExists = async (value) => {
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/checkCardNumberExists?giftCardNumber=${value}`
      );

      if (response.statusCode === 200) {
        if (response?.isExists) {
          setIsExistError(true);
          // setShowErrorMessage(true);
          setCardNumberError('Gift Card number you entered is already in use');
          // setErrorMessage('Gift Card number you entered is already in use');
        } else {
          setIsExistError(false);
        }
      }
    } catch (error) {
      console.log(error);
      setShowErrorMessage(true);
      setErrorMessage(error.message || 'something went wrong');
    }
  };
  const checkValidations = () => {
    if (cardName === '') {
      setCardNameError('Card Name is Required');
    }
    if (cardNumber === '') {
      setCardNumberError('Card Number is Required');
    }
    if (!cardValue) {
      setCardValueError('Card Value is Required');
    }
    if (
      cardName === '' ||
      cardNumber === '' ||
      !cardValue ||
      cardNumber.length < 6
    ) {
      return false;
    }
    return true;
  };

  const submitValue = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const payload = {
      partitionKey: locationId,
      custom: custom,
      presetDiscountedValues: preDisValue,
      giftCardName: cardName,
      giftCardNumber: cardNumber.replace(/-/g, ''),
      giftCardValue: cardValue?.giftCardPriceValue || cardValue || 0,
      discount: preDisValue
        ? discount
        : custom
        ? cardValue
        : cardValue?.giftCardPriceValue || 0,
      status: 1,
      createdBy: userId,
      isExist: isExist,
      transferAmount: transfer
    };
    let id = encodeURIComponent(existId);
    let queryParams = existId ? `id=${id}` : '';
    try {
      if (checkValidations()) {
        let response = await ApiService.post(
          `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/addNewGiftCard?${queryParams}`,
          payload
        );

        if (response.statusCode === 200) {
          navigate('/checkout', {
            state: {
              type: 'gift',
              itemId: response?.data,
              items: [payload],
              transfer: transfer,
              client: null
            }
          });
        }
      }
    } catch (e) {
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong');
    }
  };
  const handleValueChange = (value) => {
    setCardValue(value);
    setDiscount(value?.price || 0);
    setCardValueError('');
  };

  const debouncedIsExist = useCallback(
    debounce(checkCardNumberExists, 1000),
    []
  );
  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      <Dialog
        open={openAddGiftCardModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right client bg-white"
        maxWidth="sm"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Button
            className="details-remove-icon p-0 no-hover"
            onClick={handleClose}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="13" cy="13" r="13" fill="white" />
              <path
                d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                fill="#003087"
              />
            </svg>
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Container
              component="form"
              onSubmit={submitValue}
              maxWidtth="100%"
              sx={{ paddingTop: '10px' }}
            >
              <Typography className="page-title" variant="h3">
                Manage Gift Cards
              </Typography>
              <Box
                sx={{
                  marginTop: '20px',
                  color: '#000 !important',
                  fontSize: '20px !important'
                }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={addNew}
                    name="radio-buttons-group"
                    onChange={(e) => setAddNew(e.target.value)}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FormControlLabel
                        value="AddNew"
                        control={<Radio />}
                        label={<span className="radioLabel">Add New</span>}
                      />
                      <FormControlLabel
                        value="Existing"
                        control={<Radio />}
                        label={<span className="radioLabel">Existing</span>}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Grid container spacing={2} className="table-container-style">
                {/* <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-required"
                    label={
                      <div className='scanLabel'>
                        <IconButton
                          aria-label="Scan Icon"
                          style={{
                            marginLeft: '16vh',
                            paddingTop: '2px',
                            color: '#003087'
                          }}
                        >
                          <img src={ScanCard} alt="boxImg" />
                        </IconButton>
                        Scan Your Card
                      </div>
                    }
                    disabled
                    className="text-field-styling"
                    InputLabelProps={{
                      className: 'label-style-text-field'
                    }}
                  />
                </Grid> */}
                <Grid item md={12} sm={12} xs={12} sx={{ marginTop: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className="add-pricing-box-text">
                      Custom
                    </Typography>
                    <Toggle
                      value={custom}
                      checked={custom}
                      name="custom"
                      onChange={handleToggleChange}
                    />
                  </Stack>
                </Grid>
                {!custom && !transfer && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography className="add-pricing-box-text">
                        Preset Discounted value
                      </Typography>
                      <Toggle
                        value={preDisValue}
                        name="preDisValue"
                        checked={preDisValue}
                        onChange={handleToggleChange}
                      />
                    </Stack>
                  </Grid>
                )}

                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="Card Id"
                    fullWidth
                    value={`#ZR${cardId}`}
                    id="outlined-required"
                    label="CARD ID"
                    className="location-field input-field-styling"
                    onChange={handleInputChange}
                    disabled
                    InputLabelProps={{
                      className: 'label-style-create-franchisee'
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="Card Name"
                    fullWidth
                    id="outlined-required"
                    label="CARD NAME"
                    className="location-field input-field-styling required-field"
                    disabled={addNew === 'Existing'}
                    onChange={handleInputChange}
                    error={!!cardNameError}
                    helperText={cardNameError}
                    value={cardName}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                </Grid>

                {custom ? (
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      name="Card Value"
                      fullWidth
                      id="outlined-required"
                      label="CARD VALUE"
                      type="number"
                      className="location-field input-field-styling required-field"
                      onChange={handleInputChange}
                      error={!!cardValueError}
                      helperText={cardValueError}
                      value={cardValue}
                      InputLabelProps={{
                        className:
                          'label-style-create-franchisee required-label'
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <FormControl
                      pt={2}
                      fullWidth
                      className="select-drop-down-label-styling required-field"
                      variant="outlined"
                      sx={{
                        width: '100%'
                      }}
                    >
                      <InputLabel
                        className="text-field-styling required-label scanLabel"
                        style={{
                          fontWeight: '800',
                          fontSize: '16px',
                          backgroundColor: '#fff',
                          height: '40px', // Adjust the height value as needed
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        SELECT CARD VALUE
                      </InputLabel>
                      <Select
                        value={cardValue}
                        onChange={(event) =>
                          handleValueChange(event.target.value)
                        }
                        label="Type"
                        className="status-field field-label-css"
                        MenuProps={{
                          PaperProps: { style: { maxHeight: 200 } }
                        }}
                        renderValue={(value) =>
                          `${value?.giftCardPriceName} ${value?.giftCardPriceValue}`
                        }
                      >
                        {pricingDataValue?.length > 0 &&
                          pricingDataValue.map((item, index) => (
                            <MenuItem key={index} id={index} value={item}>
                              {`${item.giftCardPriceName} - ${item.giftCardPriceValue}`}
                            </MenuItem>
                          ))}
                      </Select>
                      {cardValueError && (
                        <FormHelperText>{cardValueError}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}

                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    name="Card Number"
                    fullWidth
                    id="outlined-required"
                    label="CARD NUMBER"
                    className="location-field input-field-styling required-field"
                    onChange={handleInputChange}
                    error={!!cardNumberError}
                    helperText={cardNumberError}
                    value={cardNumber}
                    InputLabelProps={{
                      className: 'label-style-create-franchisee required-label'
                    }}
                  />
                </Grid>

                {preDisValue && !transfer && (
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      name="Discount"
                      fullWidth
                      id="outlined-required"
                      label="DISCOUNT"
                      type="number"
                      disabled
                      className="text-field-styling"
                      onChange={handleInputChange}
                      error={!!discountError}
                      helperText={discountError}
                      value={discount}
                      InputLabelProps={{
                        className: 'label-style-text-field'
                      }}
                    />
                  </Grid>
                )}
                {/* {preDisValue && addNew === 'Existing' && ( */}
                {transfer && addNew === 'Existing' && cardName && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: '16px', color: '#003087' }}
                      variant="h5"
                    >
                      Your Current Card Value is : $
                      {cardData?.giftCardPriceValue
                        ? cardData?.giftCardPriceValue
                        : cardData?.giftCardValue}
                    </Typography>
                  </Grid>
                )}
                {addNew === 'Existing' && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography className="add-pricing-box-text">
                        Transfer to store account
                      </Typography>
                      <Toggle
                        value={transfer}
                        name="transfer"
                        onChange={handleToggleChange}
                      />
                    </Stack>
                    {transfer && (
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography variant="h5">
                          {/* Transfer Amount is : ${cardValue?.price} */}
                          Transfer Amount is : ${transferAmount}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}

                {/* {!preDisValue && !custom && addNew === 'Existing' && (
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      name="Transfer"
                      fullWidth
                      id="outlined-required"
                      label="TRANSFER"
                      type="number"
                      disabled
                      className="text-field-styling"
                      onChange={handleInputChange}
                      error={!!transferError}
                      helperText={transferError}
                      value={transferValue}
                      InputLabelProps={{
                        className: 'label-style-text-field'
                      }}
                    />
                  </Grid>
                )} */}

                <Grid item md={12} sm={12} xs={12}>
                  <Button
                    className="submit-button"
                    fullWidth
                    type="submit"
                    disabled={isExistError}
                  >
                    {addNew === 'AddNew' || !transfer
                      ? 'Buy Now'
                      : 'Transfer Now'}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Gift card has been Created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddGiftCard;