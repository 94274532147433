import React from "react";
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput } from "@mui/material";
import { CiSearch } from "react-icons/ci";
import ClearIcon from "@mui/icons-material/Clear";


export const CustomInput = ({ label, value, setSearchQuery, list, setSelected, isexpand,disabled=false }) => {
  return (
    <div>
      <FormControl
        sx={{
          width: "296px",
          padding: "5px",

        }}
        disabled={disabled}
      >
        <InputLabel
          htmlFor="outlined-adornment-password"
          sx={{ color: "#4c7bd2ed", paddingLeft: "14px", fontSize: "14px" }}
        >
          {label}
        </InputLabel>
        <OutlinedInput
          style={{
            borderRadius: "12px",
            backgroundColor: "#F8FBFF",
            height: "40px",
            outline: "none"
          }}
          value={value}
          onChange={(e) => setSearchQuery(e.target.value)}
          id="outlined-adornment-password"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              {value ? (
                <IconButton
                  aria-label="clear input"
                  edge="end"
                  onClick={() => setSearchQuery("")}
                  sx={{ color: "rgba(0, 48, 135, 0.7)" }}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="search"
                  edge="end"
                  sx={{ color: "rgba(0, 48, 135, 0.7)",pointerEvents:"none" }}
                >
                  <CiSearch />
                </IconButton>
              )}
            </InputAdornment>
          }
          label="Password"
        />
        <Box className="menuItems">
          {value.length > 0 && isexpand ? list.map((item, index) => (
            <MenuItem key={index} onClick={() => {
              setSelected(item);
              setSearchQuery("");
            }}>{(item?.firstName || '') + ' ' + (item?.lastName || '')}</MenuItem>
          )) : null}
        </Box>
      </FormControl>
    </div>
  );
};
