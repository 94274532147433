/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';

export const CustomDatePickerAddSchedule = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    required,
    inputFormat,
    fullWidth,
    disabled,
    minDate,
    maxDate,
    error,
    helperText
  } = props;

  const handleDateChange = (value) => {
    onChange(name, value);
  };

  const handleInputKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <div className={`${fullWidth && 'fullWidth-date'}`}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          value={value}
          minDate={minDate}
          disabled={disabled}
          maxDate={maxDate}
          inputFormat={inputFormat}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              fullWidth={fullWidth}
              error={error}
              helperText={helperText}
              className={`${error && 'helper-error'}`}
              onKeyDown={handleInputKeyDown}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
