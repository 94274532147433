/* eslint-disable no-unused-vars */
import './index.css';
import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { QueryBuilderComponent } from '@syncfusion/ej2-react-querybuilder';
import { closest } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Divider,
  DialogActions,
  TextField,
  Card,
  Button,
  FormHelperText
} from '@mui/material';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
import Loader from '../../../components/Loader';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from '../../../services/ApiService';
import { getLocalData } from 'src/utils';
import { getComponent } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const CreateCohorts = ({
  setShowScreen,
  getFranchisee,
  getCohorts,
  isDuplicate,
  selectedRow
}) => {
  let dropDownObj = useRef(null);
  const [cohortNameError, setCohortNameError] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [columnData, setColumnData] = useState([]);
  const [selectedGroupData, setSelectedGroupData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [columns, setColumns] = useState([]);
  const [importRules, setImportRules] = useState({});
  const [open, setOpen] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [creteriaError, setCreteriaErrors] = useState('');
  const franchiseeId = getLocalData('franchiseeId');
  const locationId = getLocalData('locationId');
  const locationName = getLocalData('locationName');
  const qryBldrObj = useRef(null);

  useEffect(() => {
    if (isDuplicate) {
      setCohortName(selectedRow?.Name);
      if (selectedRow?.Segments.length > 0) {
        const updatedImportRules = { ...selectedRow.Segments[0] };
        mapFieldRules(updatedImportRules.rules);
        setImportRules(updatedImportRules); // Set the modified rules to state if required elsewhere
      }
    } else {
      setImportRules({
        condition: 'and',
        rules: [
          {
            label: '',
            field: '',
            type: '',
            operator: '',
            value: ''
          }
        ]
      });
    }
  }, [selectedRow, isDuplicate]);

  useEffect(() => {
    setSelectedGroupData(importRules);
  }, [importRules]);

  // const handleQueryBuilderChange = () => {
  //   if (qryBldrObj && qryBldrObj.current && qryBldrObj.current.getRules()) {
  //     let rules = qryBldrObj?.current?.getRules() ?? '';
  //     if (rules) setSelectedGroupData(rules);
  //   }
  // };
  function mapFieldRules(rules) {
    rules?.forEach((rule) => {
      if (
        rule.operator === 'between' &&
        Array.isArray(rule.value) &&
        rule.value.length === 2
      ) {
        rule.value[0] = convertDate(rule.value[0]);
        rule.value[1] = convertDate(rule.value[1]);
      }

      if (rule.rules && rule.rules.length > 0) {
        mapFieldRules(rule.rules); // Recursively call the function for nested rules
      }
    });
  }
  function convertDate(inputDate) {
    const date = new Date(inputDate);

    if (Number.isNaN(date)) {
      return null; // Or handle the error in your specific way
    }

    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }
  const dataValueToAppend = (data) => {
    return {
      create: () => {
        const elem = document.createElement('input');
        elem.setAttribute('type', 'text');
        return elem;
      },
      destroy: (args) => {
        dropDownObj.current = getComponent(
          document.getElementById(args.elementId),
          'dropdownlist'
        );
        if (dropDownObj.current) {
          dropDownObj.current.destroy();
        }
      },
      write: (args) => {
        const ds = data;
        dropDownObj.current = new DropDownList({
          dataSource: ds,
          value: args.values ? args.values : ds[0],
          change: (e) => {
            qryBldrObj.current.notifyChange(e.itemData.value, e.element);
          }
        });
        dropDownObj.current.appendTo('#' + args.elements.id);
      }
    };
  };
  useEffect(() => {
    if (columnData.length > 0) {
      const updatedColumnData = columnData.map((field) => {
        if (field?.dataValue) {
          // Append the dataValue field to the object
          return {
            ...field,
            template: dataValueToAppend(field.dataValue)
          };
        }
        return field; // Return unchanged objects
      }); // Set updateColumnData to false to prevent further updates
      setColumns(updatedColumnData);
    }
  }, [columnData]);
  const getAllAttribute = async () => {
    setIsLoading(true);
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/getAttributeFilter?locationId=${encodedLocationId}`
      );

      if (!response.error) {
        setColumnData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getAllAttribute();
  }, []);

  // Handle the back arrow
  const handleCreateCohortClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setShowScreen(0);
    }
  };
  const handleInputChange = (e) => {
    const { name } = e.target;
    if (name === 'cohortName') {
      setIsFieldUpdated(true);
      setCohortName(e.target.value);
      setCohortNameError('');
    }
  };

  // convert the date
  function convertDateFormat(inputDate) {
    let dateParts;
    if (inputDate?.includes('/')) {
      dateParts = inputDate?.split('/');
    } else {
      return inputDate;
    }

    // Reformatting the date parts into the desired format
    const formattedDate = `${dateParts[2]}-${dateParts[0]?.padStart(
      2,
      '0'
    )}-${dateParts[1]?.padStart(2, '0')}T00:00:00.000Z`;
    return formattedDate;
  }
  // Map the filed and set the data based on selected data
  function mapFieldDataToRules(rules) {
    if (rules?.length > 0) {
      rules.forEach((rule) => {
        if (rule.field) {
          const fieldInfo = columnData.find(
            (field) => field.field === rule.field
          );
          if (fieldInfo) {
            rule.label = fieldInfo.label;
            rule.type = fieldInfo.type;
            if (fieldInfo.gsi) {
              rule.gsi = fieldInfo.gsi;
            }
            if (fieldInfo.masterData) {
              rule.masterData = fieldInfo.masterData;
            }
            if (fieldInfo.data) {
              rule.data = fieldInfo.data;
            }
            if (fieldInfo.tables) {
              rule.tables = fieldInfo.tables;
            }
            if (rule.operator === 'between') {
              rule.value[0] = convertDateFormat(rule.value[0]);
              rule.value[1] = convertDateFormat(rule.value[1]);
            }
          }
        }

        // Check if the current rule has nested rules
        if (rule.rules && rule.rules.length > 0) {
          mapFieldDataToRules(rule.rules); // Recursively call the function for nested rules
        }
      });
    }
  }

  // handle dialog box states
  const handleCloseConfirmation = (val) => {
    if (val === 'Save') {
      setOpen(false);
    }

    if (val === 'Discard') {
      setOpen(false);
      getCohorts();
      // Here redirecting to the client details page.
      setShowScreen(0);
    }
  };
  // Validate if any filed is getting blank in creteria
  function validateRules(rules) {
    rules.forEach((rule) => {
      if (rule.rules) {
        // If the rule contains nested rules, recursively validate them
        validateRules(rule.rules);
      } else if (
        !rule.field ||
        !rule.operator ||
        rule.value === 0 ||
        rule.value === ''
      ) {
        setCreteriaErrors('Criteria has missing field, operator or value');
        throw new Error(
          "Rule with blank 'field', 'operator', or 'value' equal to 0 detected"
        );
      }
    });
  }
  // Handle the submission of form
  const handleSubmit = async () => {
    let data = qryBldrObj.current.getRules();
    mapFieldDataToRules(data?.rules);
    console.log(data, '56666666666666666666666');
    setSelectedGroupData(data);
    try {
      // Validate cohort name
      const cohortNameValidation = new Promise((resolve) => {
        if (!cohortName || cohortName.trim() === '') {
          setCohortNameError('Cohort name is required.');
        } else {
          resolve();
        }
      });

      await Promise.all([cohortNameValidation, validateRules(data.rules)]);

      await checkCohortNameExist(data);
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle errors if necessary
    }
  };

  const handleQueryBuilderChange = () => {
    setCreteriaErrors('');
  };
  // API for check the cohort name exist
  const checkCohortNameExist = async (item) => {
    setIsLoading(true);
    let templateNameCheck = cohortName;
    const data = {
      Name: templateNameCheck || ''
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/isExistingCohort`,
        data
      );

      if (response.statusCode && !response.error) {
        if (!response?.data?.isExists) {
          setIsLoading(false);
          CreateCohorts(item);
        } else {
          setIsLoading(false);
          setCohortNameError(
            'Cohort name already exists.Please choose unique name.'
          );
          //  CreateCohorts();
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  console.log(selectedGroupData, '12122222222222222');

  // API for create cohort
  const CreateCohorts = async (item) => {
    setIsLoading(true);
    let data = {
      locationId: locationId,
      franchiseeId: franchiseeId,
      Name: cohortName,
      createdBy: locationName,
      Segments: [item]
    };
    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}pinpoint/duplicateCohorts`,
        data
      );

      if (response.statusCode === 200) {
        if (response.error === false) {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setSuccessMessage('Cohort created successfully');
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowMessage(true);
      setErrorMessage('Something went wrong, try later.');
    }
  };

  // handle close pop-up
  const closePopup = () => {
    setShowSuccessMessage(false);
    setShowScreen(0);
    getCohorts();
  };

  const headerTemplate = (props) => {
    let items;
    let fields;

    items = [
      { key: 'AND', value: 'and' },
      { key: 'OR', value: 'or' }
    ];
    fields = { text: 'key', value: 'value' };
    let state = { ...props };
    const conditionChange = (args) => {
      qryBldrObj.current.notifyChange(args.value, args.element, 'condition');
    };
    const addGroupClick = (args) => {
      let a = qryBldrObj.current.getRules();
      if (a.rules[0].field) {
        let addbtn = args.currentTarget.offsetParent.id;
        let ddb = addbtn.split('_');
        qryBldrObj.current.addGroups(
          [{ condition: 'and', rules: [{}] }],
          ddb[1]
        );
      }
    };
    const addRuleClick = (args) => {
      let addbtn = args.currentTarget.offsetParent.id;
      let ddb = addbtn.split('_');
      qryBldrObj.current.addRules([{}], ddb[1]);
    };
    const onClick = (args) => {
      qryBldrObj.current.deleteGroup(
        closest(args.target.offsetParent, '.e-group-container')
      );
    };
    const args = state;
    return (
      <div className="query-template-control">
        <div className="e-groupheader">
          <DropDownListComponent
            id={args.ruleID + '_cndtn'}
            cssClass="e-custom-group-btn"
            width="100px"
            dataSource={items}
            fields={fields}
            value={args.condition}
            change={conditionChange}
          />
          <div className="e-header">
            {(() => {
              if (
                args.ruleID === 'querybuilder_group0' ||
                args.groupID === null
              ) {
                return (
                  <div className="e-qb-hdr-content">
                    <ButtonComponent
                      className="e-grp-btn btn-style-cohort"
                      cssClass="e-primary"
                      onClick={addGroupClick}
                    >
                      Add Group
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}

            <div className="e-qb-hdr-content">
              <ButtonComponent
                className="e-cond-btn btn-style-cohort"
                cssClass="e-primary"
                onClick={addRuleClick}
              >
                Add Condition
              </ButtonComponent>
            </div>

            {(() => {
              if (
                args.ruleID !== 'querybuilder_group0' ||
                args.groupID !== null
              ) {
                return (
                  <div className="e-qb-hdr-content ">
                    <ButtonComponent
                      id={args.ruleID + '_dltbtn'}
                      cssClass="e-danger"
                      onClick={onClick}
                      className="btn-style-cohort"
                    >
                      Remove
                    </ButtonComponent>
                  </div>
                );
              }
              return null;
            })()}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}
      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
            mt={1}
          >
            <Box
              onClick={() => handleCreateCohortClose()}
              className="back-arrow-close"
            >
              <ArrowLeft />
            </Box>

            <Typography className="header-blue-32">Create Cohorts</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box className="" mt={2} ml={1}>
        <Grid container xs={12} sm={12} mt={2}>
          <Grid item xs={6} sm={6} mt={2}>
            <TextField
              fullWidth
              name="cohortName"
              error={Boolean(cohortNameError)}
              helperText={cohortNameError}
              autoComplete="SENDER NAME"
              label="COHORT NAME *"
              placeholder="Cohort Name"
              className="location-field input-field-styling"
              value={cohortName}
              onChange={handleInputChange}
              InputLabelProps={{
                className: 'label-style-create-franchisee edit-address-label'
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px',
          mt: 5
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          ml={2}
        >
          <Grid item>
            <Typography className="table-heade header-blue-20">
              {' '}
              Criteria
            </Typography>
          </Grid>
        </Grid>
        {columns.length > 0 && (
          <div className="control-pane">
            <div className="control-section">
              <div className="col-lg-12 control-section">
                <QueryBuilderComponent
                  id="querybuilder"
                  columns={columns}
                  change={handleQueryBuilderChange}
                  rule={importRules}
                  headerTemplate={headerTemplate}
                  ref={qryBldrObj}
                />
              </div>
            </div>
          </div>
        )}
        <FormHelperText>{creteriaError}</FormHelperText>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="right"
        gap={2}
        mt={5}
      >
        <Grid item mb={3}>
          <Button
            onClick={() => handleSubmit()}
            className="cohorts-outlined-btn"
            variant="contained"
          >
            Create Cohort
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => handleCloseConfirmation('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirmation('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />

          <Button
            className="font-17 no-hover"
            onClick={() => handleCloseConfirmation('Save')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CreateCohorts;
