import React, { useMemo } from 'react';
import { Avatar, Box } from '@mui/material';

const placeHolderImg = '/static/images/logo/cardboardicon.png';

const UsersList = ({ data, handleSelectUser }) => {
  function formatDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    const now = new Date();

    // Check if the date is today
    if (date.toDateString() === now.toDateString()) {
      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    }

    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  }

  const userProfileImage = useMemo(
    () => data?.profileImage || placeHolderImg,
    [data?.profileImage]
  );

  return (
    <Box className="user-list-item" onClick={() => handleSelectUser(data)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt="userImg"
          src={userProfileImage}
          sx={{ width: '33px', height: '33px' }}
        />
        <Box className="full-name">{data?.clientName}</Box>
      </Box>
      <Box fontSize="small">{formatDateTime(data?.lastSmsTime)}</Box>
    </Box>
  );
};

export default UsersList;
