/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  IconButton,
  useTheme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const AdjustmentTable = (props) => {
  const { tableRows, searchQuery, loading, openDetail } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    limit: 5
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US');

    return formattedDate;
  }

  // To change the page into the table
  const handlePageChange = (event, newPage) => {
    setTablePagination({ ...tablePagination, page: newPage });
  };

  // To change the limit
  const handleLimitChange = (event) => {
    setTablePagination({
      ...tablePagination,
      limit: parseInt(event.target.value)
    });
  };

  // Apply paination on table
  const applyPagination = (tableRows) => {
    const page = tablePagination.page;
    const limit = tablePagination.limit;
    return tableRows.slice(page * limit, page * limit + limit);
  };

  // Apply search filter
  const applyFilters = (tableRows) => {
    let arrayOfMatchedObjects = [];
    if (searchQuery) {
      arrayOfMatchedObjects = tableRows.filter((object) => {
        return JSON.stringify(object)
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    } else {
      arrayOfMatchedObjects = tableRows;
    }
    return arrayOfMatchedObjects;
  };
  
  // useEffect(() => {
  //   const searchText = searchQuery.toLowerCase();
  //   setSearchText(searchText);
  //   setPage(0);
  // }, [searchQuery]);
  
  useEffect(() => {
    setTablePagination({ ...tablePagination, page: 0 });
  }, [tableRows, searchQuery]);

  // Apply filter from object and below is the pagination order
  const filteredRows = applyFilters(tableRows);
  const paginatedRows = applyPagination(filteredRows);

  // const handleEdit = (Item, e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   // setSelectedItem(Item);
  //   navigate('/inventory-adjustment?adjustmentId=' + Item.adjustmentId);
  // };

  return (
    <div>
      <Card
        sx={{
          padding: '15px',
          borderRadius: '19px 19px 0px 0px'
        }}
      >
        <TableContainer className="location-table-container">
          <Table>
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Number Of Products</TableCell>
                <TableCell>Staff Name</TableCell>
                <TableCell>Value</TableCell>
                {/* <TableCell /> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedRows.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.adjustmentId}
                    className="table-row cursor-pointe"
                    // onClick={(e) => openDetail(row.id, 3, e)}
                  >
                    <TableCell className="table-td capitalize">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ marginTop: 1 }}
                        gutterBottom
                        noWrap
                      >
                        {row.adjustmentType}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {formatDate(row.updatedAt)}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.numberOfItems}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {row.operatorName}
                      </Typography>
                    </TableCell>
                    <TableCell className="table-td">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        ${row.totalCost && Number(row.totalCost).toFixed(2)}
                      </Typography>
                    </TableCell>
                    {/* <TableCell className="table-td">
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        className="edit-btn"
                        id={'edit_btn_' + row.adjustmentId}
                        // onClick={(e) => openDetail(row.id, 2, e)}
                        onClick={(e) => handleEdit(row, e)}
                      >
                        Edit
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredRows?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={tablePagination.page}
            rowsPerPage={tablePagination.limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>
    </div>
  );
};
