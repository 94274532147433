import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide
} from '@mui/material';

import './AboutJourney.css';
// import { getLocalData } from '../../../../utils';
// import Loader from '../../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AboutJourney({
  openAlertNotesDialog,
  setOpenAlertNotesDialog,
  setOpenAlertNotes,
  journeyDetails
}) {

  // const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // setOpen(false);
    // setIsUpdated(false);
  }, []);

  /* Variable getting user id from local storage */
  // let userId = getLocalData('userId');

  // const isFranchisor = getLocalData('isFranchisor');
  // const isPrimaryOwner = getLocalData('isPrimaryOwner');
  // const locationId = getLocalData('locationId');
  // let encodedLocationId = encodeURIComponent(locationId);

  /* Function to close the main dialog box */
  const handleClose = () => {

    // setOpen(false);
    setOpenAlertNotesDialog(false);
    setOpenAlertNotes(false);
  };


  return (
    <>
      {/* <Loader IsLoading={isLoading} /> */}
      <Dialog
        open={openAlertNotesDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box-right-addTask"
        maxWidth="sm"
        // maxHeight='550'
        // sx={{ maxHeight: '350px', maxWidth:'350px'}}
      >
        <DialogTitle
          className="alert-title align-right"
          sx={{ backgroundColor: '#FFFFFF ! important', padding: '30px 40px' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography className="header-black-20">ABOUT JOURNEY</Typography>
            {/* remove icon */}
            <Button
              className="details-remove-icon task-cross p-0 no-hover"
              onClick={() => handleClose('Close')}
            >
              <svg
                width="30"
                height="31"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 27.2695C8.385 27.2695 3 21.8845 3 15.2695C3 8.65453 8.385 3.26953 15 3.26953C21.615 3.26953 27 8.65453 27 15.2695C27 21.8845 21.615 27.2695 15 27.2695ZM15 0.269531C6.705 0.269531 0 6.97453 0 15.2695C0 23.5645 6.705 30.2695 15 30.2695C23.295 30.2695 30 23.5645 30 15.2695C30 6.97453 23.295 0.269531 15 0.269531ZM18.885 9.26953L15 13.1545L11.115 9.26953L9 11.3845L12.885 15.2695L9 19.1545L11.115 21.2695L15 17.3845L18.885 21.2695L21 19.1545L17.115 15.2695L21 11.3845L18.885 9.26953Z"
                  fill="#E64646"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#FFFFFF ! important', maxHeight: '550px', maxWidth:'600px', minWidth:'600px', padding: '30px 40px' }}>
          <Box>
            <Grid container className="grid-containe">
              <Grid container className="location-details-row">
                <Grid item xs={12} sm={3} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    NAME
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9} className="location-details-col">
                  <Box className="location-details-value summary-value capitalize">
                    {journeyDetails.name}
                  </Box>
                </Grid>
              </Grid>
              <Grid container mt={2} className="location-details-row">
                <Grid item xs={12} sm={12} className="location-details-col">
                  <Box className="location-details-label summary-heading font-16">
                    DESCRIPTION
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} mt={2} className="location-details-col">
                  <Box className="location-details-value summary-value capitalize">
                    {/* // journeyDetails.mfgName ||  */}
                    {journeyDetails.description}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AboutJourney;
