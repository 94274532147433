
import React from 'react';
import { ChartComponent, Inject, LineSeries, SeriesCollectionDirective, SeriesDirective, Category, DataLabel, Tooltip } from '@syncfusion/ej2-react-charts';
/* eslint import/newline-after-import: "off" */


function LineGraphDashboardWidgets({graphData,xAxisTitle,yAxisTitle}) {

    return (
        <div>
            <ChartComponent
                //  title="Sales Analysis" 
                primaryYAxis={{ title: yAxisTitle }}
                primaryXAxis={{
                    valueType: "Category",
                    title:xAxisTitle
                }}
                
                legend Settings={{ visible: true }}
                tooltip={{ enable: true }}>
                <Inject
                    services={[
                        LineSeries,
                        Category,
                        // Legend,
                        DataLabel,
                        Tooltip
                    ]} />
                   
                <SeriesCollectionDirective>
                    <SeriesDirective
                        type="Line"
                        width={5}
                        dataSource={graphData}
                        xName={xAxisTitle}
                        yName={yAxisTitle}
                        name="Sales"
                        fill='#003078'
                        marker={{
                            dataLabel: { visible: true },
                            visible: true,
                            width:5
                        }}
                    />
                </SeriesCollectionDirective>
            </ChartComponent>
        </div>
    );
}
export default LineGraphDashboardWidgets;

