/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-computed-key */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Slide,
  Typography,
  Button,
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  DialogActions,
  InputAdornment
} from '@mui/material';
import {
  CustomSelect,
  CustomTextField,
  CustomButton,
  CustomDatePicker,
  AutoComplete
} from 'src/components/Global';
import Loader from '../../../components/Loader';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import { ToggleWithoutLabelClick } from 'src/components/Global/ToggleWithoutLabelClick';
import { Toggle } from 'src/components/Global';
import { ApiService } from '../../../services/ApiService';
import { generateTimeOptions } from 'src/components/Schedules';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddPricingOption = ({
  setOpenAddPricing,
  openAddPricingModal,
  setAddPricingModal,
  getPricingOptionDetail
}) => {
  let initials = {
    intervalValue: 1,
    intervalDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
  };

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategoryData] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [open, setOpen] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [postServices, setPostServices] = useState([]);
  const [servicesValue, setServicesValue] = useState([]);
  const userId = getLocalData('userId');
  const [showMessage, setShowMessage] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [isFieldUpdated, setIsFieldUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [newSchedule, setNewSchedule] = useState(initials);
  const [pricingInfo, setPricingInfo] = useState({
    category: '',
    services: [],
    name: '',
    price: '',
    free: false,
    noOfSessions: '',
    sharable: false,
    restricted: false,
    sellOnline: false,
    validThrough: 'startEndDate',
    startDate: null,
    endDate: null,
    expiryDate: null,
    expiresIn: 'Months',
    expiresInValue: '',
    weekDays: false,
    timeRange: false,
    starts: 'Immediately'
  });

  const [assignOptions, setAssignOptions] = useState({
    default: true,
    membership: [],
    contract: []
  });

  const [isError, setIsError] = useState({
    category: false,
    services: false,
    name: false,
    price: false,
    noOfSessions: false,
    startDate: false,
    endDate: false,
    expiryDate: false,
    expiresIn: false,
    expiresInValue: false
  });

  const daysOfWeek = [
    { label: 'M', value: 'Monday' },
    { label: 'T', value: 'Tuesday' },
    { label: 'W', value: 'Wednesday' },
    { label: 'T', value: 'Thursday' },
    { label: 'F', value: 'Friday' },
    { label: 'S', value: 'Saturday' },
    { label: 'S', value: 'Sunday' }
  ];

  const clearValues = () => {
    setPricingInfo({
      category: '',
      services: [],
      name: '',
      price: '',
      free: false,
      noOfSessions: '',
      sellOnline: false,
      includePromotion: true,
      validThrough: 'startEndDate',
      startDate: null,
      endDate: null,
      expiryDate: null,
      expiresIn: 'Months',
      expiresInValue: '',
      weekDays: false,
      timeRange: false,
      starts: 'Immediately'
    });

    setIsError({
      category: false,
      services: false,
      name: false,
      price: false,
      noOfSessions: false,
      startDate: false,
      endDate: false,
      expiryDate: false,
      expiresIn: false,
      expiresInValue: false,
      intervalDays: false
    });

    setAssignOptions({
      default: true,
      membership: [],
      contract: []
    });

    setIsError({ startDate: null, endDate: null });
  };

  const handleDialogClose = () => {
    setOpen(true);
    setIsCreated(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    let encodedLocationId = encodeURIComponent(locationId);
    let url;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/getAllServiceCategories?locationId=${encodedLocationId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`;
    }
    ApiService.get(`${url}`)
      .then((response) => response)
      .then((data) => {
        if (data.statusCode && !data.error) {
          setCategoryData(data.data.Items);
        }
      });
  };

  const handleToggleDay = (day) => {
    let selectedDays = [];
    if (newSchedule.intervalDays.includes(day)) {
      selectedDays = newSchedule.intervalDays.filter(
        (selectedDay) => selectedDay !== day
      );
    } else {
      selectedDays = [...newSchedule.intervalDays, day];
    }
    setNewSchedule({ ...newSchedule, intervalDays: selectedDays });
    setIsError({ ...isError, intervalDays: false });
  };

  const handlePricingInfo = (type, value) => {
    if (type === 'price') {
      const pattern = /^\d{0,4}(\.\d{0,2})?$/;

      if (pattern.test(value)) {
        setPricingInfo({ ...pricingInfo, price: value });
      } else {
        // Handle invalid input, e.g., show an error message
        console.error('Please enter a valid price');
      }
    } else if (type === 'free' && value === true) {
      setPricingInfo({ ...pricingInfo, free: true, price: 0 });
    } else if (type === 'free' && value === false) {
      setPricingInfo({ ...pricingInfo, free: false, price: '' });
    } else if (type === 'Restricted') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, sellOnline: false, restricted: true });
      } else {
        setPricingInfo({ ...pricingInfo, restricted: false });
      }
    } else if (type === 'Sharable') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, sharable: true });
      } else {
        setPricingInfo({ ...pricingInfo, sharable: false });
      }
    } else if (type === 'SellOnline') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, sellOnline: true });
      } else {
        setPricingInfo({ ...pricingInfo, sellOnline: false });
      }
    } else if (type === 'WeekDays') {
      if (value === true) {
        setPricingInfo({ ...pricingInfo, weekDays: true });
      } else {
        setPricingInfo({ ...pricingInfo, weekDays: false });
      }
    } else if (type === 'TimeRange') {
      setPricingInfo({ ...pricingInfo, timeRange: value });
      setEndTimeError('');
      setStartTime('');
      setStartTimeError('');
      setEndTime('');
    } else if (type === 'creditsValidity') {
      setPricingInfo({ ...pricingInfo, starts: value });
    } else if (type === 'category') {
      setPricingInfo({ ...pricingInfo, category: value, services: [] });
    } else if (type === 'services') {
      const selectedServices = value.map((item) => {
        const [Id] = item.split('|');
        return Id;
      });
      setServicesValue(value);
      let postService = allServices.filter((services) => {
        return selectedServices.includes(services.sortKey);
      });
      setPostServices(postService);
    } else {
      setPricingInfo({ ...pricingInfo, [type]: value });
    }
    // check if value then set error to false
    if (value) {
      setIsError({ ...isError, [type]: false });
    } else {
      setIsError({ ...isError, [type]: true });
    }
  };

  const getCreditValue = () => {
    let text = null;
    if (pricingInfo.free) {
      text = 'Price per credit: $0.00';
    } else if (
      !pricingInfo.free &&
      pricingInfo.price > 0 &&
      pricingInfo.noOfSessions > 0
    ) {
      text = `Price per credit: $${(
        Number(pricingInfo.price) / pricingInfo.noOfSessions
      ).toFixed(2)}`;
    }

    return text;
  };

  function checkForEmptyValues() {
    let hasError = false;
    const updatedErrors = {};
    // Always check for empty values
    const alwaysCheck = ['category', 'name', 'noOfSessions'];
    for (const key of alwaysCheck) {
      const value = pricingInfo[key];
      const isEmpty = value === '';
      updatedErrors[key] = isEmpty;
      if (isEmpty) {
        hasError = true;
      }
    }
    // Check additional values based on conditions
    // const { free, validThrough, category } = pricingInfo;
    const { free, category, weekDays, timeRange, starts } = pricingInfo;
    if (!free) {
      const priceIsEmpty = pricingInfo.price === '';
      updatedErrors['price'] = priceIsEmpty;
      if (priceIsEmpty) {
        hasError = true;
      }
    }

    // if week days is true then check for interval days atleast one
    if (weekDays) {
      const intervalDaysIsEmpty = newSchedule.intervalDays.length === 0;
      updatedErrors['intervalDays'] = intervalDaysIsEmpty;
      if (intervalDaysIsEmpty) {
        hasError = true;
      }
    }

    // if time range is true then check for start and end time
    if (timeRange) {
      const startTimeIsEmpty = startTime === '';
      const endTimeIsEmpty = endTime === '';
      updatedErrors['startTime'] = startTimeIsEmpty;
      updatedErrors['endTime'] = endTimeIsEmpty;
      if (startTimeIsEmpty || endTimeIsEmpty) {
        hasError = true;
      }
    }

    console.log(starts, 'startsstartsstartsstarts');

    // if starts is true then check for expires in value
    if (starts === '') {
      const expiresInValueIsEmpty = pricingInfo.expiresInValue === '';
      updatedErrors['expiresInValue'] = expiresInValueIsEmpty;
      if (expiresInValueIsEmpty) {
        hasError = true;
      }
    }
    if (endTime < startTime) {
      setEndTimeError('End time cannot be earlier than start time');
    } else {
      setEndTimeError('');
    }
    setIsError(updatedErrors);
    return hasError;
  }

  const getNameById = (id, array) => {
    const matchedObject = array.find((obj) => obj.sortKey === id);
    const name = matchedObject ? matchedObject.name : '';
    return name;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  }

  function calculateAfterActivationDate(value, unit, type) {
    const currentDate = new Date();

    if (type == 'start') {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${month}-${day}-${year}`;
    }

    if (type == 'end') {
      const futureDate = new Date(currentDate);

      if (unit == 'day') {
        futureDate.setDate(currentDate.getDate() + Number(value));
      } else if (unit == 'month') {
        futureDate.setMonth(currentDate.getMonth() + Number(value));
      }

      const year = futureDate.getFullYear();
      const month = String(futureDate.getMonth() + 1).padStart(2, '0');
      const day = String(futureDate.getDate()).padStart(2, '0');
      return `${month}-${day}-${year}`;
    }

    return null;
  }

  const handleProceed = (e) => {
    e.preventDefault();
    if (!checkForEmptyValues()) {
      const startTimeInMinutes = convertTimeToMinutes(startTime);
      const endTimeInMinutes = convertTimeToMinutes(endTime);
      if (
        endTimeInMinutes < startTimeInMinutes &&
        !(startTimeInMinutes === 0 && endTimeInMinutes === 1380)
      ) {
        setEndTimeError('End time cannot be earlier than start time');
      } else {
        setEndTimeError('');
        addPricingOption();
      }
    } else {
      if (pricingInfo.timeRange) {
        if (startTime === '') {
          setStartTimeError('Start time is required');
        } else {
          setStartTimeError('');
        }
        if (endTime === '') {
          setEndTimeError('End time is required');
        } else {
          setEndTimeError('');
        }
      }
      console.log('Please fill all the required fields');
    }
  };

  useEffect(() => {
    if (!pricingInfo.timeRange) {
      setStartTimeError('');
      setEndTimeError('');
    }
  }, [pricingInfo.timeRange]);
  const addPricingOption = async () => {
    setBtnLoading((prev) => {
      let toggleId;
      if (prev['add_pricing']) {
        toggleId = { ['add_pricing']: false };
      } else {
        toggleId = { ['add_pricing']: true };
      }
      return { ...toggleId };
    });

    let url;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option`;
    }

    let items = [];
    if (postServices.length > 0) {
      let itemServiceObj = postServices.map((serviceData) => {
        const matchingService = allServices.find(
          (service) => service.sortKey === serviceData.sortKey
        );
        return {
          id: serviceData.sortKey,
          name: matchingService ? matchingService.name : ''
        };
      });
      items.push(...itemServiceObj);
    }

    const data = {
      locationId: locationId,
      franchiseeId: franchiseeId,
      name: pricingInfo.name,
      price: pricingInfo.price,
      numberOfSessions: pricingInfo.noOfSessions,
      categoryId: pricingInfo.category,
      categoryName: getNameById(pricingInfo.category, categories),
      services: items,
      sellOnline: pricingInfo.sellOnline,
      free: pricingInfo.free,
      sharable: pricingInfo.sharable,
      restricted: pricingInfo.restricted,
      schedulingLimitation: {
        setWeekDays: pricingInfo.weekDays,
        weekDays: newSchedule.intervalDays
      },
      setTimeRange: pricingInfo.timeRange,
      startTime: startTime,
      endTime: endTime,
      creditsValidityStarts: pricingInfo.starts,

      status: 1,
      createdBy: userId
    };

    if (pricingInfo.expiresInValue) {
      data.creditsValidityExpires = {
        duration: pricingInfo.expiresInValue,
        unit: pricingInfo.expiresIn
      };
    }

    // user id franchisor then remove location id and franchisee id
    if (isFranchisor === 'true') {
      delete data.locationId;
      delete data.franchiseeId;
    }

    // check if free is true then do not send price
    if (pricingInfo.free) {
      delete data.price;
    }

    // check if time range is false then do not send start and end time
    if (!pricingInfo.timeRange) {
      delete data.startTime;
      delete data.endTime;
    }

    if (pricingInfo.endDate)
      data.validThroughEndDate = formatDate(pricingInfo.endDate);
    if (pricingInfo.startDate)
      data.validThroughStartDate = formatDate(pricingInfo.startDate);
    if (!pricingInfo.startDate) delete data.validThroughStartDate;
    if (!pricingInfo.endDate) delete data.validThroughEndDate;

    // check if week days is false then do not send week days
    if (!pricingInfo.weekDays) {
      delete data.schedulingLimitation?.weekDays;
    }

    // check if credit validity is false then do not send credit validity
    if (!pricingInfo.starts) {
      delete data.creditsValidity;
    }

    try {
      setIsLoading(true);
      const response = await ApiService.post(`${url}`, data);
      if (!response.data?.error) {
        setIsLoading(false);
        setDialog(true);
        setOpen(true);
        setIsCreated(true);
      } else {
        setDialog(false);
      }
      setBtnLoading(false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setIsLoading(false);
    }
  };

  const fetchServices = async (id) => {
    let encodedLocationId = encodeURIComponent(locationId);
    let url;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/getSelectedServices?locationId=${encodedLocationId}&categoryId=${id}&status=1`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/getSelectedServices?categoryId=${id}&status=1`;
    }
    try {
      const ServicesResponse = await ApiService.get(`${url}`);
      if (!ServicesResponse?.data?.error) {
        setAllServices(ServicesResponse?.data?.Items || []);
      } else {
        console.log(
          ServicesResponse.message ||
            'Something went wrong with Pricing Services'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
    }
  };

  useEffect(() => {
    // services whenever category option changes
    if (pricingInfo.category !== '') {
      let id = encodeURIComponent(pricingInfo.category);
      fetchServices(id);
    }
  }, [pricingInfo.category]);

  /* Function set time */

  const convertTimeToMinutes = (timeString) => {
    if (!timeString) return;
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  };

  const SetStartTime = (e) => {
    if (!e) return;
    const timeValue = e;
    setStartTime(timeValue);
    const startTimeInMinutes = convertTimeToMinutes(timeValue);
    const endTimeInMinutes = convertTimeToMinutes(endTime);
    if (endTime !== '' && startTimeInMinutes >= endTimeInMinutes) {
      setEndTimeError('End time cannot be earlier than or equal to start time');
    } else {
      setEndTimeError('');
    }
  };

  const SetEndTime = (e) => {
    const timeValue = e;
    setEndTime(timeValue);
    const startTimeInMinutes = convertTimeToMinutes(startTime);
    const endTimeInMinutes = convertTimeToMinutes(timeValue);
    if (timeValue !== '' && endTimeInMinutes <= startTimeInMinutes) {
      setEndTimeError('End time cannot be earlier than or equal to start time');
    } else {
      setEndTimeError('');
    }
  };

  const handleClose = () => {
    if (isFieldUpdated) {
      setOpen(true);
    } else {
      setAddPricingModal(false);
      setOpenAddPricing(false);
    }
  };
  const handleCloseConfirm = (val) => {
    if (val === 'Discard') {
      setAddPricingModal(false);
      setOpenAddPricing(false);
      clearValues();
      setOpen(false);
      getPricingOptionDetail();
    }
    if (val === 'Continue') {
      setOpen(false);
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showMessage && (
        <ErrorMessage message={errorMessage} setShowMessage={setShowMessage} />
      )}

      <Dialog
        open={openAddPricingModal}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right "
        maxWidth="xs"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={handleDialogClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box>
              <Box sx={{ padding: '1rem 0 0rem 0', float: 'left' }}>
                <Typography className="header-blue-20">
                  Add Pricing Option
                </Typography>
              </Box>
              <form
                className="custom-form"
                onSubmit={(e) => {
                  handleProceed(e);
                }}
              >
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={12}>
                    <CustomSelect
                      fullWidth
                      name="category"
                      required={Boolean(true)}
                      value={pricingInfo.category}
                      onChange={handlePricingInfo}
                      label="CATEGORY"
                      error={isError.category}
                      helperText={isError.category && 'Category is required'}
                      options={categories?.map((item) => ({
                        label: item.name,
                        value: item.sortKey
                      }))}
                      classLabelProps="input-field-dropdown-styling"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomSelect
                      fullWidth
                      multiple
                      name="services"
                      value={servicesValue}
                      onChange={handlePricingInfo}
                      label="SERVICES"
                      disabled={pricingInfo.category === ''}
                      options={allServices?.map((item) => ({
                        label: item.name,
                        value: item.sortKey
                      }))}
                      classLabelProps="input-field-dropdown-styling"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextField
                      type="text"
                      name="name"
                      label="NAME"
                      fullWidth
                      required={Boolean(true)}
                      error={isError.name}
                      helperText={isError.name && 'Name is required'}
                      value={pricingInfo.name}
                      onChange={handlePricingInfo}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      type="text"
                      name="price"
                      label="Price"
                      fullWidth
                      required={!pricingInfo.free && Boolean(true)}
                      error={!pricingInfo.free && isError.price}
                      helperText={
                        !pricingInfo.free &&
                        isError.price &&
                        'Price is required'
                      }
                      value={pricingInfo.price}
                      onChange={handlePricingInfo}
                      disabled={pricingInfo.free}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '18px'
                              }}
                            >
                              $
                            </span>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    width="100%"
                    justifyContent="flex-end"
                  >
                    <ToggleWithoutLabelClick
                      name="free"
                      label="Free"
                      labelPlacement="start"
                      value={pricingInfo.free}
                      onChange={handlePricingInfo}
                      className="marginRight: 16px"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextField
                      type="number"
                      name="noOfSessions"
                      label="NO OF SESSIONS (CREDIT)"
                      fullWidth
                      maxLength={3}
                      min={1}
                      required={Boolean(true)}
                      error={isError.noOfSessions}
                      helperText={
                        isError.noOfSessions
                          ? 'No of sessions is required'
                          : getCreditValue()
                      }
                      value={pricingInfo.noOfSessions}
                      onChange={handlePricingInfo}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        className="sell-online-txt"
                        style={{
                          fontWeight: '900',
                          fontSize: '16px',
                          marginLeft: '10px'
                        }}
                      >
                        <b>Shareable</b>
                      </Typography>
                      <Box className="bundle-toggle">
                        <Toggle
                          value={pricingInfo.sharable}
                          name="Sharable"
                          onChange={handlePricingInfo}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        className="sell-online-txt"
                        style={{
                          fontWeight: '900',
                          fontSize: '16px',
                          marginLeft: '10px'
                        }}
                      >
                        <b>Restricted</b>
                      </Typography>
                      <Box className="bundle-toggle">
                        <Toggle
                          value={pricingInfo.restricted}
                          name="Restricted"
                          onChange={handlePricingInfo}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography
                        className="sell-online-txt"
                        style={{
                          fontWeight: '900',
                          fontSize: '16px',
                          marginLeft: '10px'
                        }}
                      >
                        <b>Sell Online</b>
                      </Typography>
                      <Box className="bundle-toggle">
                        <Toggle
                          value={Boolean(pricingInfo.sellOnline)}
                          checked={pricingInfo?.sellOnline}
                          disabled={pricingInfo.restricted}
                          name="SellOnline"
                          onChange={handlePricingInfo}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl className="custom-radio-group">
                      <FormLabel
                        sx={{
                          color: '#000 !important',
                          fontWeight: '600',
                          fontSize: '18px'
                        }}
                      >
                        Valid Through
                      </FormLabel>
                    </FormControl>
                    <Grid item xs={12} mt={2}>
                      <CustomDatePicker
                        label="Start Date"
                        name="startDate"
                        fullWidth
                        disablePast
                        inputFormat="MM/dd/yyyy"
                        maxDate={pricingInfo.endDate}
                        value={pricingInfo.startDate}
                        onChange={handlePricingInfo}
                      />
                    </Grid>

                    <Grid item xs={12} mt={2}>
                      <CustomDatePicker
                        label="End Date"
                        name="endDate"
                        fullWidth
                        disablePast
                        minDate={pricingInfo.startDate && pricingInfo.startDate}
                        inputFormat="MM/dd/yyyy"
                        value={pricingInfo.endDate}
                        onChange={handlePricingInfo}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl className="custom-radio-group">
                      <FormLabel
                        sx={{
                          color: '#000 !important',
                          fontWeight: '600',
                          fontSize: '18px'
                        }}
                      >
                        Scheduling Limitations
                      </FormLabel>
                    </FormControl>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className="sell-online-txt"
                          style={{
                            fontWeight: '900',
                            fontSize: '16px',
                            marginLeft: '10px'
                          }}
                        >
                          <b>Set Week Days</b>
                        </Typography>
                        <Box className="bundle-toggle">
                          <Toggle
                            value={pricingInfo.weekDays}
                            name="WeekDays"
                            onChange={handlePricingInfo}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    {pricingInfo.weekDays && (
                      <Grid item xs={12} sm={12} mt={1} mb={1}>
                        <div className="days-of-week-container">
                          {daysOfWeek.map(({ label, value }) => (
                            <div
                              key={value}
                              role="button"
                              tabIndex={0}
                              className={`day-circle vv-${value} ${
                                newSchedule.intervalDays.includes(value)
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => handleToggleDay(value)}
                              onKeyDown={() => handleToggleDay(value)}
                            >
                              {label}
                            </div>
                          ))}
                        </div>
                        <span className="helper-error">
                          {isError.intervalDays && (
                            <FormHelperText>
                              Select atleast one day
                            </FormHelperText>
                          )}
                        </span>
                      </Grid>
                    )}

                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          className="sell-online-txt"
                          style={{
                            fontWeight: '900',
                            fontSize: '16px',
                            marginLeft: '10px'
                          }}
                        >
                          <b>Set Time Range</b>
                        </Typography>
                        <Box className="bundle-toggle">
                          <Toggle
                            value={pricingInfo.timeRange}
                            name="TimeRange"
                            onChange={handlePricingInfo}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  {pricingInfo.timeRange && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <AutoComplete
                          fullWidth
                          className="input-field-dropdown-to-from"
                          freeSolo
                          disableClearable
                          name="StartTime"
                          label="START TIME"
                          options={generateTimeOptions()}
                          value={startTime}
                          onChange={(type, value) => SetStartTime(value)}
                          helperText={pricingInfo.timeRange && startTimeError}
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <AutoComplete
                          fullWidth
                          freeSolo
                          className="input-field-dropdown-to-from"
                          disableClearable
                          label="END TIME"
                          name="EndTime"
                          options={generateTimeOptions()}
                          value={endTime}
                          onChange={(type, value) => SetEndTime(value)}
                          error={endTimeError !== ''}
                          helperText={pricingInfo.timeRange && endTimeError}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <FormControl className="custom-radio-group">
                      <FormLabel
                        sx={{
                          color: '#000 !important',
                          fontWeight: '600',
                          fontSize: '18px'
                        }}
                      >
                        Credits Validity
                      </FormLabel>
                      <Grid
                        item
                        xs={12}
                        paddingRight="4px"
                        className="mt-10 align-left"
                      >
                        <Typography className="header-blue-">Starts</Typography>
                      </Grid>

                      <RadioGroup
                        defaultValue="Immediately"
                        name="radio-buttons-group"
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            className="header-blue-"
                            style={{
                              fontSize: '15px',
                              marginLeft: '10px'
                            }}
                          >
                            Immediately
                          </Typography>
                          <Box className="bundle-toggle">
                            <FormControlLabel
                              value="Immediately"
                              control={<Radio />}
                              onChange={() =>
                                handlePricingInfo(
                                  'creditsValidity',
                                  'Immediately'
                                )
                              }
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <Typography
                            className="header-blue-"
                            style={{
                              fontSize: '15px',
                              marginLeft: '10px'
                            }}
                          >
                            On First Class
                          </Typography>
                          <Box className="bundle-toggle">
                            <FormControlLabel
                              value="On First Class"
                              control={<Radio />}
                              onChange={() =>
                                handlePricingInfo(
                                  'creditsValidity',
                                  'On First Class'
                                )
                              }
                            />
                          </Box>
                        </Box>
                      </RadioGroup>
                    </FormControl>

                    <Grid item xs={12}>
                      <FormControl className="custom-radio-group">
                        <FormLabel
                          sx={{
                            color: '#000 !important',
                            fontWeight: '600',
                            fontSize: '16px'
                          }}
                        >
                          Expires in
                        </FormLabel>
                      </FormControl>
                    </Grid>
                    <Grid container spacing={2} xs={12} className="mt-10">
                      <Grid item xs={4} sm={4} md={4}>
                        <CustomTextField
                          fullWidth
                          type="number"
                          name="expiresInValue"
                          label="#"
                          maxLength="2"
                          min={1}
                          value={pricingInfo.expiresInValue}
                          onChange={handlePricingInfo}
                          error={
                            pricingInfo.starts === 'On First Class' &&
                            isError.expiresInValue
                          }
                        />
                      </Grid>

                      <Grid item xs={8} sm={8} md={8}>
                        <CustomSelect
                          fullWidth
                          name="expiresIn"
                          value={pricingInfo.expiresIn}
                          onChange={handlePricingInfo}
                          label="MONTHS"
                          options={[
                            { label: 'Months', value: 'Months' },
                            { label: 'Days', value: 'Days' }
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="fullwidth-btn">
                    <CustomButton
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        if (!endTimeError) {
                          document.getElementById('pricing-save').click();
                        }
                      }}
                    >
                      Save
                    </CustomButton>
                    <button hidden type="submit" id="pricing-save">
                      save
                    </button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isCreated ? 'Congratulations!' : 'Discard Changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isCreated ? (
                <Typography className="title-text-sub">
                  {' '}
                  You have unsaved changes.
                  <Typography className="title-text-sub">
                    What do you want to do?
                  </Typography>
                </Typography>
              ) : (
                <Typography className="title-text-sub">
                  Pricing option has been created successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleCloseConfirm('Discard')}
          >
            {isCreated ? 'Close' : 'Discard Changes'}
          </Button>
          {!isCreated && <Divider className="vertical-divider" />}
          {!isCreated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleCloseConfirm('Continue')}
            >
              Go Back to Editing
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPricingOption;
