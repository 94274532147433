import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { ApiService } from "../../../services/ApiService";
// import { getLocalData } from "../../../utils";
import { Helmet } from "react-helmet-async";


const CampaignMetrics = ({ selected, setActive }) => {


//  const franchiseId = getLocalData("franchiseeId");


  const getCampaignMetrics = async () => {
    let id = encodeURIComponent(selected.Id);
    // let encodedOrderId = encodeURIComponent(detail.sortKey);
   // let encodedCampaignId = encodeURIComponent(selected.Id);

    let response = await ApiService.get(
      `${process.env.REACT_APP_API_END_POINT}pinpoint/campaignMetrics?Id=${id}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    console.log(Item, "getCampaignMetrics");
  };



  useEffect(() => {
    getCampaignMetrics();
  }, [selected]);


  return (
    <>
      <Box sx={{mt:2}}>
        <Helmet>
          <title>Campaign</title>
        </Helmet>
        
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "40px",

            marginBottom: "20px",
            justifyContent: "flex-start",
            flexDirection: "row"
          }}
        >
          <Typography className="page-title" variant="h1" onClick={() => setActive(2)}>
            <svg
              width="27"
              height="19"
              viewBox="0 0 27 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                fill="#003087"
              />
            </svg>
            <span style={{marginLeft:4}}>  Campaign Metrics</span>
           
          </Typography>

        </Box>


        <Box className="review-main">
          <Typography className={"blue-heading"}>Campaign Metrics</Typography>
        </Box>
        <Box className="review-mainn">
          <Typography sx={{mx:4, fontSize:16}} >No campaign metrics</Typography>
        </Box>
      </Box>
    </>
  );
};
export default CampaignMetrics;