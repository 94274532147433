import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
// import { HiOutlineDocumentText } from 'react-icons/hi';
import { NotesIcon } from '../../assets/SVG/SvgIcons';

export function Accordian(props) {
  const {label, data } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        style={{
          padding: '0',
          minHeight: '55px !important',
          alignItems: 'flex-start !important'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            display="flex"
            alignItems="center"
            gap={4}
            sx={{ marginBottom: '0px !important' }}
          >
            {/* <HiOutlineDocumentText className="orange-icons" /> */}
            <NotesIcon />
            <h3 className="accordian-header font-myriad ">{label}</h3>
          </Box>
        </AccordionSummary>
        <AccordionDetails
            className="font-myriad"
          sx={{
            padding: '0px 20px 20px 65px'
          }}
        >
          {data ? (
            <>
              {/* <Typography sx={{ marginTop: '16px', fontWeight: '600' }}>
                Notes:{' '}
              </Typography> */}
              <Typography sx={{textAlign:'left'}}>{data}</Typography>
            </>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
