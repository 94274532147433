/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';

export const CustomTextField = ({
  id,
  type,
  value,
  defaultValue,
  onChange,
  name,
  label,
  placeholder,
  helperText,
  error,
  variant,
  required,
  disabled,
  readOnly,
  multiline,
  rows,
  maxRows,
  min,
  max,
  maxLength,
  minLength,
  fullWidth,
  autoComplete,
  InputProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleInput = (event) => {
    let value = event.target.value;
    if (onChange) {
      if (maxLength && value?.length <= maxLength) {
        onChange(name, value);
      } else if (max && value?.length <= max) {
        onChange(name, value);
      } else if (!maxLength && !max) {
        onChange(name, value);
      }
    }
  };

  return (
    <span className="custom-text-field" style={{ width: '100%' }}>
      <TextField
        InputProps={InputProps}
        id={id}
        name={name}
        fullWidth={fullWidth}
        type={type}
        label={label}
        autoComplete={autoComplete}
        placeholder={placeholder}
        variant={variant}
        defaultValue={defaultValue}
        value={value}
        onChange={handleInput}
        // required={required}
        disabled={disabled}
        helperText={helperText}
        error={error}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        className={`${error && 'helper-error'} ${
          required && 'required-field'
        } custom-text-field`}
        sx={{ fontSize: '16px !important' }}
        InputLabelProps={{
          className: `label-style-create-franchisee custom-text-field-label ${
            required && 'required-label'
          } `
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
          max: max,
          min: min,
          readOnly: readOnly,
          rows: rows
        }}
      />
    </span>
  );
};
