import React, { useEffect, forwardRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Autocomplete,
  TextField
} from '@mui/material';
import {
  DynamicPopup,
  DynamicTextField
} from '../../../components/GlobalComponents';
import { getLocalData } from 'src/utils';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ArrowLeft } from 'src/assets/SVG/SvgIcons';
// import { RoomTargetTable } from './RoomTargetTable';
import Loader from 'src/components/Loader';
import { makeStyles } from '@material-ui/core/styles';

import { ApiService } from 'src/services/ApiService';
import RoomsTable from './RoomsTable';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AddRoom = (props) => {
  const {
    handleClose,
    roomsDetail,

    fetchData: fetchRoomData,
    first,
    setfirst,
    setroomDetails,
    setShowSuccessMessage,
    setSuccessMessage,
    showSucessMessage,
    sucessMessage
  } = props;

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefe
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();

  const locationId = getLocalData('locationId');
  const [isLoading, setIsLoading] = useState(false);
  const userId = getLocalData('userId');

  const [roomData, setroomData] = useState('');
  const [roomName, setroomName] = useState('');
  const [description, setDescription] = useState('');
  const [pageType, setPageType] = useState(1);
  console.log(pageType, 'pageTypepageTypepageType');

  const [roomNameError, setroomNameError] = useState(false);
  const [selectedError, setselectedError] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isFieldEdited, setIsFieldEdited] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [categoriesIds, setCategoriesIds] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  // let sampleData = {
  //   locationId: 'LOC#e857e7a7-4fde-4d2a-baad-114d6a85ff63',
  //   name: 'testRoom',
  //   description: 'testRoomDesc',
  //   forAllServices: false,
  //   services: [
  //     {
  //       serviceId: '#SRVCS#2WfEcDoc0bT1jLTctLAGmP45sf8',
  //       serviceName: 'ZR@34434543'
  //     },
  // {
  //   serviceId: '#SRVCS#2WfEp49X3g7W0HAf98ByZGhuc9z',
  //   serviceName: 'ZR@233232'
  // },
  // {
  //   serviceId: '#SRVCS#2WfOKGAuZOXwRH67Cruk2E8VqLA',
  //   serviceName: 'testasdasdad'
  // }
  //   ],
  //   categories: [
  //     {
  //       categoryId: '#SRVCS-CAT#2WZgOfTWhagng0YRKPRKx1tBsxW',
  //       categoryName: 'Class'
  //     },
  //     {
  //       categoryId: '#SRVCS-CAT#2WZgPIM0sYhyNQ566VNFbkFwn9a',
  //       categoryName: 'Evaluation'
  //     }
  //   ],
  //   createdBy: '#USER#2T1E1oHUXFtCAObD1YOlPsCqEfH'
  // };

  const [dialog, setDialog] = useState({
    state: false,
    isCreated: false
  });

  const [servicesList, setServicesList] = useState([]);
  const handleResetStates = () => {
    setDialog({
      state: false,
      isCreated: false
    });

    setroomNameError(false);
  };

  const handleContractInfo = (type, value) => {
    // Set to true if any field is edited
    setIsFieldEdited(true);
    if (type === 'roomName') {
      if (value) {
        setroomName(value);
        setroomNameError(false);
      } else {
        setroomName('');
        setroomNameError(true);
      }
    } else if (type === 'description') {
      setDescription(value);
    }
  };

  function findObjectsByIds(tableData, idsToFind) {
    return idsToFind
      .map((sortKey) => {
        const foundObject = tableData?.find(
          (item) => item?.sortKey === sortKey
        );
        if (foundObject) {
          return {
            serviceId: foundObject?.sortKey,
            serviceName: foundObject?.name
          };
        }
        return null;
      })
      .filter(Boolean);
  }

  console.log(allServices, 'selectedItemsselectedItems');
  console.log(roomData, 'roomDataroomDataroomData');

  // add new room
  const addnewRoom = async () => {
    const availableServicesss = findObjectsByIds(tableData, selectedLists);

    if (!roomName) {
      setroomNameError(true);
    } else if (
      (availableServicesss && availableServicesss?.length !== 0) ||
      (roomsDetail && roomsDetail?.length !== 0)
    ) {
      setroomNameError(false);
      setIsLoading(true);
      let params = {
        locationId: locationId,
        name: roomName,
        description: description ?? '',
        // createdBy: userId,
        forAllServices: false,
        services: availableServicesss || [],
        categories: categoryId || []
      };
      try {
        let response;
        if (roomsDetail) {
          params.updatedBy = userId;
          let roomId = encodeURIComponent(roomsDetail[1]);
          response = await ApiService.put(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms?id=${roomId}`,
            params
          );
        } else {
          params.createdBy = userId;
          response = await ApiService.post(
            `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms`,
            params
          );
        }
        const responseData = response;
        if (responseData.statusCode === 200) {
          fetchRoomData();
          handleClose();
          setIsLoading(false);
          setTimeout(() => {
            setShowSuccessMessage(true);
            if (roomsDetail) {
              setSuccessMessage('Room details has been updated successfully!');
            } else {
              setSuccessMessage('Room has been added successfully!');
            }
            setfirst(false);
          }, 500);
        } else {
          setIsLoading(false);
          setShowErrorMessage(true);
          setErrorMessage(responseData?.message);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    }

    if (
      availableServicesss &&
      availableServicesss?.length <= 0 &&
      !roomsDetail
    ) {
      setselectedError(true);
    }
  };

  const handleCloseScreen = (type) => {
    if (type) {
      handleClose();
    } else {
      handleResetStates();
    }
  };

  const handleDialog = (value) => {
    if (value === 'Continue') {
      setDialog({
        isCreated: false,
        state: false
      });
      // handleClose();
    } else if (value === 'Discard') {
      handleResetStates();
      handleClose();
    } else if (value === 'Cancel') {
      handleCloseScreen();
    }
    handleClose();
  };

  const handleAddService = (e) => {
    e.preventDefault();
  };

  // Methdo to handle the click event for back arrow
  const handleCLick = () => {
    // If the value is confirm then navigate to the list page
    if (isFieldEdited) setConfirmDialog(true);
    else if (first) {
      setroomDetails('');
      setfirst(false);
      handleClose();
    } else {
      handleResetStates();
      handleClose();
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
  };

  // Handler to handle the dialog
  const handleConfirmationDialog = (value) => {
    // If the value is confirm then navigate to the list page
    setConfirmDialog(false);

    if (value === 'Discard') {
      if (first) {
        setroomDetails('');
        setfirst(false);
        handleClose();
      } else {
        handleResetStates();
        handleClose();
      }
    }
  };

  useEffect(() => {
    setroomName('');
    setDescription('');
    setPageType(2);
    fetchServices();
  }, []);

  /* below is used for Edit */
  useEffect(() => {
    if (roomsDetail) {
      setIsFieldEdited(true);
      getDetails();
      getAllServices();
    }
  }, []);

  useEffect(() => {
    setData(roomData);
  }, [roomData]);

  const getDetails = async () => {
    // To show loader
    setIsLoading(true);
    let id = encodeURIComponent(roomsDetail[1]);
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}rooms/getRoomDetails?locationId=${encodedLocationId}&id=${id}`
      );
      if (response.statusCode === 200) {
        setroomData(response?.data?.Items[0]);
        setIsLoading(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('error-->', error);
      setIsLoading(false);
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong');
    }
  };

  const setData = (item) => {
    if (item) {
      setroomName(item?.name);
      setDescription(item?.description);
    }
  };

  useEffect(() => {
    if (roomData?.services?.length > 0) {
      // const availableSarviceIds = roomData?.services?.map((service) => {
      //   service?.categoryId;
      // });
      const availableSarviceIds = roomData?.services?.map((service) => ({
        categoryName: service?.name, // You can change this value as needed
        categoryId: service?.categoryId
      }));
      const availableSarviceIdsList = roomData?.services?.map(
        (service) => service?.sortKey
      );
      setSelectedLists(availableSarviceIdsList);
      setCategoryId(availableSarviceIds);
    }
  }, [roomData]);
  useEffect(() => {
    if (roomData.categories?.length > 0) {
      // setCategoriesIds([
      //     {
      //         "partitionKey": "ZOR#1",
      //         "sortKey": "#SRVCS-CAT#2WZgPBFIkgtSqO8otUssQVRAnuT",
      //         "categoryId": "#SRVCS-CAT#2WZgPBFIkgtSqO8otUssQVRAnuT",
      //         "status": 1,
      //         "serviceTypeId": "#SRVCS-ENROLL",
      //         "createdAt": "2023-10-10T13:44:49.268Z",
      //         "description": "",
      //         "createdBy": "100",
      //         "name": "Playgroup",
      //         "id": "#SRVCS-CAT#2WZgPBFIkgtSqO8otUssQVRAnuT",
      //         "label": "Playgroup",
      //         "value": {
      //             "partitionKey": "ZOR#1",
      //             "sortKey": "#SRVCS-CAT#2WZgPBFIkgtSqO8otUssQVRAnuT",
      //             "categoryId": "#SRVCS-CAT#2WZgPBFIkgtSqO8otUssQVRAnuT",
      //             "status": 1,
      //             "serviceTypeId": "#SRVCS-ENROLL",
      //             "createdAt": "2023-10-10T13:44:49.268Z",
      //             "description": "",
      //             "createdBy": "100",
      //             "name": "Playgroup"
      //         }
      //     }
      // ])
      const availableCategoryIds = roomData?.categories?.map(
        (category) => category?.categoryId
      );
      // inputOptions.find((option) => option.id === specificKeyToAutoSelect;
      setCategoriesIds(availableCategoryIds);
    }
  }, [roomData]);

  useEffect(() => {
    let selectedData = servicesList.filter((item) =>
      categoriesIds.includes(item.sortKey)
    );
    console.log(
      'availableCategoryIds',
      categoriesIds,
      selectedData,
      servicesList
    );
    setSelectedOptions(selectedData);
  }, [servicesList, categoriesIds, roomData]);

  const getAllServices = async () => {
    // Show loader

    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}services/getAllServices?locationId=${encodedLocationId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];
      if (response?.statusCode === 200) {
        setAllServices(Item);
        // Hide loader
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      // Hide loader
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchServicesBasedOnCategory(categoriesIds);
  }, [categoriesIds]);

  const handleServiceInfo = (e, newValue) => {
    console.log('e', e, newValue);
    setSelectedOptions(newValue);
    // const { name, value } = e.target;
    // console.log('idWithName',value);

    const data = newValue?.map((item) => item?.sortKey);
    const idWithName = newValue?.map((item) => ({
      categoryId: item?.sortKey,
      categoryName: item?.name
    }));
    setCategoriesIds(data);
    setCategoryId(idWithName);
    // }
  };

  // Get all service categories
  const fetchServices = async () => {
    try {
      const categoriesResponse = await ApiService.get(
        `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllServiceCategories`
      );
      if (!categoriesResponse?.data?.error) {
        // console.log('all category', categoriesResponse?.data?.data?.Items);
        const modifiedServicesData = categoriesResponse?.data?.Items?.map(
          (item) => ({
            ...item,
            id: item.sortKey,
            label: item.name, // categoryName
            value: item
          })
        );
        setServicesList(modifiedServicesData);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          categoriesResponse.message ||
            'Something went wrong with Service categories'
        );
      }
    } catch (error) {
      console.warn('API Error', error);
      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  const fetchServicesBasedOnCategory = async (idData) => {
    setIsLoading(true);

    let data = {
      locationId: locationId,
      categoryIds: idData
    };
    try {
      const servicesResponse = await ApiService.post(
        `${process.env.REACT_APP_FEATURED_API_END_POINT}staff/getAllServicesWithCategory`,
        data
      );

      if (!servicesResponse.error) {
        let Item =
          servicesResponse &&
          servicesResponse?.data &&
          servicesResponse?.data?.Items
            ? servicesResponse?.data?.Items
            : [];
        const result = Item?.map((obj) => {
          const { sortKey, partitionKey, name, categoryName, typeOfService } =
            obj;

          return {
            sortKey,
            partitionKey,
            name,
            'Category Name ': categoryName,
            'Service Type':  typeOfService==='enrollment'?'Enrollment':'Appointment'
          };
        });
        setTableData(result);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    } catch (error) {
      setIsLoading(false);

      setShowErrorMessage(true);
      setErrorMessage('Internal Server Error');
    }
  };

  return (
    <>
      <Loader IsLoading={isLoading} />
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}

      {showSucessMessage && (
        <SuccessMessage
          message={sucessMessage}
          setShowMessage={setShowSuccessMessage}
          closePopup={closePopup}
        />
      )}
      <div className="contractMain mt-3">
        <Box
          sx={{ width: '100%' }}
          paddingBottom="20px"
          className="Main-container common-form-container"
        >
          <form className="custom-form" onSubmit={handleAddService}>
            <Grid mt={1} marginBottom="10px">
              <div className="contrHeader">
                {/* <h1
                  className="headHeading"
                  style={{ width: '100% !important' }}
                >
                  {contractsDetail ? 'Edit Contract' : 'Add a new contract'}
                </h1> */}

                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  gap="16px"
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box
                        onClick={() => {
                          handleCLick();
                        }}
                        className="back-arrow-close"
                      >
                        <ArrowLeft />
                      </Box>
                      <Typography className="header-blue-32">
                        {roomsDetail ? 'Edit Room' : 'Add Room'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="primary" />
                    <Grid item>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          addnewRoom();
                        }}
                      >
                        {roomsDetail ? 'Update' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <div>
              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <DynamicTextField
                    type="text"
                    name="roomName"
                    label="ROOM NAME"
                    helperText={roomNameError && 'Room name is required'}
                    shrink={Boolean(true)}
                    fullWidth
                    value={roomName}
                    onChange={handleContractInfo}
                    className="required-field"
                    InputLabelClass="required-label"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} columns={12} mt={1}>
                <Grid item xs={12} sm={6}>
                  <DynamicTextField
                    fullWidth
                    label="DESCRIPTION"
                    multiline
                    rows="6"
                    type="text"
                    name="description"
                    value={description}
                    onChange={handleContractInfo}
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </Box>
        <div>
          <Box className="location-details-container">
            <Grid container mt={4} className="location-details-row">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="location-details-col"
              >
                <Typography className="field-heading-text font-24">
                  Select Services
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* Show error message if no item is selected */}

          <Grid container my={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                // value={categoriesIds}
                classes={autoCompleteClasses}
                options={servicesList || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedOptions}
                onChange={handleServiceInfo}
                // onChange={(event, newValue) => {
                //   if (newValue && newValue.sortKey) {
                //     handleServiceInfo({
                //       // target: { name: 'locationId', value: newValue.locationId ? newValue.locationId : '' }
                //       target: { name: 'service', value: newValue }
                //     });
                //   } else {
                //     handleServiceInfo({
                //       target: { name: 'service', value: '' }
                //     });
                //   }
                // }}
                className="height-auto"
                // options={locationList.map((option) => option.locationName)}
                // options={servicesList || []}
                // getOptionLabel={(option) => option?.name}
                sx={{
                  width: '100%',
                  borderRadius: '19px ! important'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params?.label || 'Service Category'}
                    className="required-field"
                    // error={locationError}
                    InputLabelProps={{
                      className: 'required-label top-0'
                    }}
                  />
                )}
              />
              {selectedError && categoryId?.length === 0 && (
                <div className="error-message">
                  Please select at least one service
                </div>
              )}
            </Grid>
          </Grid>
          {/* <RoomTargetTable
            setSelected={setSelectedItems}
            selected={selectedItems}
            pageType={pageType}
            setIsFieldEdited={setIsFieldEdited}
          /> */}
          <RoomsTable
            data={tableData}
            label="All Services"
            selectedLists={selectedLists}
            setSelectedLists={setSelectedLists}
          />
        </div>
      </div>
      <DynamicPopup
        handleDialog={handleDialog}
        open={dialog.state}
        created={dialog.isCreated}
      />
      {/* Confirmation dialog */}
      <Dialog
        open={confirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleConfirmationDialog('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          Discard Changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleConfirmationDialog('Discard')}
          >
            Discard Changes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 no-hover"
            onClick={() => handleConfirmationDialog('Continue')}
          >
            Go Back to Editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default AddRoom;
