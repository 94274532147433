import { Helmet } from 'react-helmet-async';
import { Container, Grid, Box } from '@mui/material';
// import { HeaderContext } from 'src/contexts/HeaderContext';
import ProductListAPI from './ProductListAPI';
import Footer from 'src/components/Footer';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalData from '../../../global';
import Header from '../../../components/Header';
import AddProduct from './AddProduct/AddProduct';
import { getLocalData } from 'src/utils';
import { ApiService } from 'src/services/ApiService';
import ErrorMessage from 'src/components/PopupMessage/ErrorMessage';
import AddTaxModal from './TaxModal/AddTaxModal';

function FranchiseProduct() {
  const navigate = useNavigate();
  const childRef = useRef(null);
  // Get the title from global data
  const title = GlobalData[0].title;
  const [showScreen, setShowScreen] = useState(0);
  const [skuCode, setskuCode] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [taxModal, setTaxModal] = useState(false);


  useEffect(() => {
    let isLogin = getLocalData('Login_Sucess');
    if (isLogin !== 'true') {
      navigate('/login');
    }
  }, []);
  useEffect(() => {
    getAutoGeneratedSkuCode();
  }, []);

  const closeAddProduct = () => {
    childRef.current?.refreshProductData();
  };
  const handleButtonClick = () => {
    setShowScreen(1);
  };

  const handleTaxModal = () => {
    setTaxModal(true)
  }


  const buttons = [
    {
      label: 'Add Tax',
      onClick: handleTaxModal,
      theam: true
    },
    {
      label: 'Add New',
      onClick: handleButtonClick,
      theam: true
    }
  ];

  // function to get sku code==>
  const getAutoGeneratedSkuCode = async () => {
    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_PRODUCT_API_END_POINT}products/maxSku`
      );
      if (!response?.data?.error) {
        setskuCode(response?.data?.sku || '');
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.message || 'Something went wrong with state');
      }
    } catch (error) {
      console.log(error);

      setShowErrorMessage(true);
      setErrorMessage(error.message || 'Internal Server Error');
    }
  };
  return (
    <>
      <Helmet>
        <title> Products | {title}</title>
      </Helmet>
      {showErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          setShowMessage={setShowErrorMessage}
        />
      )}
      <AddTaxModal taxModal={taxModal} setTaxModal={setTaxModal}/>
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <Box sx={{ width: '100%' }}>
                <AddProduct
                  showScreen={showScreen}
                  setShowScreen={setShowScreen}
                  refreshData={closeAddProduct}
                  autoGeneratedSkuCode={skuCode}
                  getAutoGeneratedSkuCode={getAutoGeneratedSkuCode}
                />
              </Box>
            );
          default:
            return (
              <Container maxWidth="100%" className="main-container">
                {showScreen === 0 && (
                  <Header title="Products" buttons={buttons} />
                )}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item width={'100%'}>
                    {/* Now draw the location table */}
                    <ProductListAPI
                      showScreen={showScreen}
                      setShowScreen={setShowScreen}
                      ref={childRef}
                    />
                  </Grid>
                </Grid>
              </Container>
            );
        }
      })()}
      
      <Footer />
    </>
  );
}

export default FranchiseProduct;
