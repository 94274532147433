/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CustomButton, CustomSelect } from 'src/components/Global';
import PercentIcon from '@mui/icons-material/Percent';
import AddCardIcon from '@mui/icons-material/AddCard';
import { CustomRevenueCards } from '../../../components/MainPageComponents';
import {
  Box,
  Card,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  // useTheme,
  CardHeader,
  TextField,
  Avatar,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  CardContent,
  // DialogActions,
  // Divider,
  Slide,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Filter from './filter';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import ClientDetails from './ClientDetails';
// import EditClient from './EditClient';
import EditClient from './EditClient';
import { getLocalData } from '../../../utils';
import Loader from '../../../components/Loader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddAnotherPet from './addAnotherPet';
import PetDetails from './PetDetails';
import EditPet from './EditPet';
import { ActiveStatus, InactiveStatus } from 'src/assets/SVG/SvgIcons';
import AddAlertNotes from './AddAlertNotes/AddAlertNotes';
import {
  franchiseApiRequest,
  franchisorApiRequest
} from './ClientApiServicesWidgets';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// refreshCLientData
const DrawClientTable = ({
  allClientList,
  clientLists,
  addBtnClicked,
  sideBarfilters,
  setSidebarFilters,
  refreshClientData,
  setShowScreen,
  showScreen,
  selectedStatus,
  setSelectedStatus,
  handleBulkAdd,
  btnLoading,
  selectedClientLists,
  setSelectedClientLists,
  allLocationList,
  defaultLocId,
  setDefaultLocId
}) => {
  // const classes = useStyles();
  // const [selectedclientLists] = useState([]);
  const containerRef = React.useRef(null);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  // const [openEdit, setOpenEdit] = React.useState(false);
  const [franchiseeList, setFranchiseeList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClientItem, setSelectedClientItem] = useState([]);
  const [selectedDataClient, setSelectedDataClient] = useState([]);
  // const [openEditClient, setopenEditClient] = useState(false);
  // const [openNewClient, setopenNewClient] = useState(false);
  const [fromTable, setFromTable] = useState(false);
  const [isLoadingEditnew, setIsLoadingEdit] = useState(false);
  const [selectedPetId, setSelectedPetId] = useState('');
  const [selectedClientId, setSelectedClientId] = useState('');
  const [getClientItem, setGetClientItem] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const [openAlertNotes, setOpenAlertNotes] = useState(false);
  const [openAlertNotesDialog, setOpenAlertNotesDialog] = useState(false);
  const [isAllLocation, setAllLocation] = useState(false);
  const [states, SetStates] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [newProspects, setNewProspects] = useState([]);
  const [conversionRate, setConversionRate] = useState([]);

  let FranchiseNewProspects = `${process.env.REACT_APP_API_END_POINT}reports/getFranchiseNewProspects`;
  let FranchiseMemberships = `${process.env.REACT_APP_API_END_POINT}reports/getFranchiseMemberships`;
  let FranchiseConversionRate = `${process.env.REACT_APP_API_END_POINT}reports/getFranchiseConversionRate`;

  let FranchisorNewProspects = `${process.env.REACT_APP_API_END_POINT}reports/getFranchisorNewProspects`;
  // const [selectLocationOpt, setSelectLocationOpt] = useState({});
  // const [selectedLocation, setSelectedLocation] = useState('');
  
  useEffect(() => {
    setFromTable(false);
  }, []);

  useEffect(() => {
    if (isFranchisor === 'true') {
      franchisorApiRequest(FranchisorNewProspects, setNewProspects);
    } else {
      franchiseApiRequest(FranchiseNewProspects, franchiseeId, setNewProspects);
      franchiseApiRequest(
        FranchiseConversionRate,
        franchiseeId,
        setConversionRate
      );
      franchiseApiRequest(FranchiseMemberships, franchiseeId, setMemberships);
    }
  }, []);

  console.log('Conversion', conversionRate.conversionRate);

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));

  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');
  const classes = useStyles();
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  if (addBtnClicked) {
    handleClickOpen();
  }

  const object1 = {
    titleName: isFranchisor === 'true' ? 'MEMBERSHIPS' : 'NEW PROSPECTS',
    Units:
      isFranchisor === 'true'
        ? memberships?.totalMembershipCount || '0'
        : newProspects?.count || '0',
    subTitle: isFranchisor === 'true' ? '30 Days' : '90 Days'
  };

  const object2 = {
    titleName: isFranchisor === 'true' ? 'ATTRITION RATE' : 'CONVERSION RATE',
    Units:
      isFranchisor === 'true'
        ? '45%'
        : `${
            conversionRate?.conversionRate ? conversionRate.conversionRate : ''
          }%` || `0%`,
    subTitle: isFranchisor === 'true' ? '30 Days' : '90 Days'
  };

  const object3 = {
    titleName: isFranchisor === 'true' ? 'NEW PROSPECTS' : 'MEMBERSHIPS',
    Units:
      isFranchisor === 'true'
        ? newProspects?.count || '0'
        : memberships?.totalMembershipCount || '0',
    subTitle: isFranchisor === 'true' ? '30 Days' : '90 Days'
  };

  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  const filteredData = clientLists?.filter((item) => {
    // Combine the first name and last name with a space
    const fullName = `${item?.firstName} ${item?.lastName}`?.toLowerCase();

    // Check if the search text is included in the full name or any other value
    return (
      fullName?.includes(searchText?.toLowerCase()) ||
      Object?.values(item)?.some((value) =>
        value?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
      )
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedclientLists = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Get Franchise method to get the list of franchisee to display in the filter
  const getFranchisee = async () => {
    // Now show the loader
    setIsLoading(true);
    let url;

    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
      url = `${process.env.REACT_APP_API_END_POINT}franchisees?limit=1000&locationId=${locationId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      url = `${process.env.REACT_APP_API_END_POINT}franchisees?limit=1000&locationId=${locationId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}franchisees?limit=1000`;
    }

    // fetch(`${process.env.REACT_APP_API_END_POINT}franchisees?limit=1000`, {
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of franchisee
          setFranchiseeList(data.data.Items);
          // Now hide the loader
          setIsLoading(false);
        }
      });
  };

  // Get locations method to get the list of location to display in the filter
  const getLocations = async () => {
    // Now show the loader
    setIsLoading(true);
    let url;
    let encodedLocationId = encodeURIComponent(franchiseeId);
    if (isFranchisor === 'false' && isPrimaryOwner === 'true' && locationId) {
      url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500&franchiseeId=${encodedLocationId}`;
    } else if (
      isFranchisor === 'false' &&
      isPrimaryOwner === 'false' &&
      locationId
    ) {
      url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500&franchiseeId=${encodedLocationId}`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}locations?limit=500`;
    }
    // fetch(`${process.env.REACT_APP_API_END_POINT}locations?limit=500`, {
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          // Set the list of location
          setLocationList(data.data.Items);
          // Now hide the loader
          setIsLoading(false);
        }
      });
  };

  const showFilter = () => {
    if (states.length === 0) getStates();
    if (franchiseeList.length === 0) getFranchisee();
    if (locationList.length === 0) getLocations();
    setdisplayFilterForm(!displayFilterForm);
  };

  const getStates = async () => {
    fetch(`${process.env.REACT_APP_API_END_POINT}locations/getAllStates`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode && !data.error) {
          let Item = data.data.Items;
          SetStates(Item);
        }
      });
  };

  // const theme = useTheme();
  // Method to open the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Method to close the dialog
  const handleClose = (val) => {
    if (val === 'Delete') {
      // call the api to delete the location
      // deleteLocation();
    }
    // trigger the parent method to refresh the data
    // refreshFranchiseeData();
    setOpen(false);
  };

  // const handleCloseSuccessModal = () => {
  //   setSuccessModalOpen(false);
  // };

  // Location details
  const handleEditClient = (clientItem, e) => {
    e.preventDefault();
    e.stopPropagation();

    // getClientDetails(clientItem.clientId);

    // setopenNewClient(true);
    let clientId = clientItem.sortKey;
    // setopenEditClient(true);
    // set edit mode true
    // setOpenEdit(true);
    getClientDetails(clientId);
    setFromTable(true);
    setShowScreen(2);
    // setopenNewClient(true)
    // setopenEditClient(true);
    // set edit mode true
    setSelectedClientItem(clientItem);
    // handleClickOpen();
  };

  // Method to get theclient details
  const getClientDetails = (clientId) => {
    try {
      setSearchText('');
      // Now show the loader
      // setIsLoading(true);
      setIsLoadingEdit(true);
      let encodedClientId = encodeURIComponent(clientId);
      let encodedLocationId =
        isFranchisor === 'true'
          ? encodeURIComponent(localStorage.getItem('SelectedLocationId'))
          : encodeURIComponent(locationId);
      fetch(
        `${process.env.REACT_APP_API_END_POINT}client/getClientDetails?clientId=${encodedClientId}&locationId=${encodedLocationId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode && !data.error) {
            // Set the list of location
            setSelectedClientItem(data.data.Items);
            setIsLoadingEdit(false);
            // Now hide the loader
            // setIsLoading(false);
          } else {
            // Now hide the loader
            setIsLoadingEdit(false);
          }
        });
    } catch (error) {
      // Now hide the loader
      setIsLoadingEdit(false);
    }
  };

  // View location details
  const handleViewClientDetails = (clientItem, e) => {
    // e.preventDefault();
    e.stopPropagation();

    if (
      e &&
      e.target &&
      e.target.nodeName !== 'INPUT' &&
      !e.target.classList.contains('MuiBackdrop-root')
    ) {
      // set edit mode false
      // setOpenEdit(false);
      // clear the search text on table
      setSearchText('');
      setShowScreen(3);
      setSelectedClientItem(clientItem);
      setSelectedDataClient(clientItem);
      // handleClickOpen();
    }
  };

  // Method to hit the refresh api
  const getEventToRefresh = () => {
    // setSuccessModalOpen(true);
    // setOpenEdit(true);
    refreshClientData();
  };

  // Method to get the item for edit
  const getItemForEdit = (id) => {
    let elm;

    // id is the selected location's edit button id which is passed from the child component
    if (id) elm = document.getElementById(id);

    // Now trigger the click event on edit button when edit button found
    if (elm) elm.click();
  };

  // Method to get the selected pet
  const getSelectedPet = (id, getClientId) => {
    setSelectedClientId(getClientId);
    setSelectedPetId(id);
  };

  // Method to get the selected pet
  const editPetId = (id) => {
    setSelectedPetId(id);
    setShowScreen(5);
  };

  // const addClient = () => {
  //   // handleClickOpen();
  //   setShowScreen(1);
  // };

  const handleSelectAllClientItems = (event) => {
    setSelectedClientLists(
      event.target.checked
        ? clientLists.map((clientItem) => clientItem.sortKey)
        : []
    );
  };

  const handleSelectOneClientItem = (event, clientItemId) => {
    event.stopPropagation();
    // event.preventDefault();
    if (!selectedClientLists.includes(clientItemId)) {
      setSelectedClientLists((prevSelected) => [...prevSelected, clientItemId]);
    } else {
      setSelectedClientLists((prevSelected) =>
        prevSelected.filter((id) => id !== clientItemId)
      );
    }
  };
  const selectedSomeClientItems =
    selectedClientLists.length > 0 &&
    selectedClientLists.length < clientLists.length;
  const selectedAllClientItems =
    selectedClientLists.length === clientLists.length;

  // useEffect(() => {
  //   if(defaultLocId) setSelectedLocation(defaultLocId);
  //   else if (allLocationList && allLocationList?.length > 0) setSelectedLocation(allLocationList[0]?.locationId);
  // }, [defaultLocId]);

  const openMenu = (client, event) => {
    // setStaffId(staffItem.staffId);
    event.preventDefault();
    event.stopPropagation();
    setGetClientItem(client);

    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (client, event, value) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchor(null);

    if (value === 'edit') {
      handleEditClient(getClientItem, event);
    }
    if (value === 'addAlert') {
      setOpenAlertNotes(true);
      setOpenAlertNotesDialog(true);

      // setIsAddTeamOpen(true);
      // setClockIn(true);

      // getStaffDetails(staffId);
      // setOpenClock(true);
    }
  };

  // Method of all location
  const SetAllLocation = (val) => {
    if (val) {
      setAllLocation(val);
      setSidebarFilters((prevState) => ({ ...prevState, isAllLocation: val }));
    } else {
      setAllLocation(false);
      setSidebarFilters((prevState) => ({ ...prevState, isAllLocation: null }));
    }
  };

  // method tp set location
  const setSelectedLocation = (selectLocationOpt) => {
    let item = selectLocationOpt;

    if (item?.props?.value) {
      setDefaultLocId(item?.props?.value);
      localStorage.setItem('SelectedLocationId', item?.props?.value);
    }
    if (item?.props?.franchiseeId)
      localStorage.setItem('SelectedFranchiseeId', item?.props?.franchiseeId);
  };

  // Method to refresh the alert and notes tab
  const refreshAlertNotes = () => {
    // Hit the refresh method
    // getEventToRefresh();
  };

  return (
    <>
      <Loader IsLoading={isLoadingEditnew} />
      {(() => {
        switch (showScreen) {
          case 2:
            return (
              <EditClient
                setFromTable={setFromTable}
                fromTable={fromTable}
                data={selectedClientItem}
                setShowScreen={setShowScreen}
                getEventToRefresh={getEventToRefresh}
                // allClientList={allClientList}
              />
            );
          case 3:
            return (
              <ClientDetails
                data={selectedDataClient}
                getItemForEdit={getItemForEdit}
                getEventToRefresh={getEventToRefresh}
                setShowScreen={setShowScreen}
                showScreen={showScreen}
                getSelectedPet={getSelectedPet}
                setSelectedClientItem={setSelectedClientItem}
                selectedClientItem={selectedClientItem}
              />
            );
          case 4:
            return (
              <AddAnotherPet
                data={selectedClientItem}
                setShowScreen={setShowScreen}
                showScreen={showScreen}
                getEventToRefresh={getEventToRefresh}
              />
            );

          case 6:
            return (
              <PetDetails
                petId={selectedPetId}
                // getEventToRefresh={getEventToRefresh}
                setShowScreen={setShowScreen}
                editPetId={editPetId}
                selectedClientId={selectedClientId}
              />
            );
          case 5:
            return (
              <EditPet
                petId={selectedPetId}
                setShowScreen={setShowScreen}
                selectedClientId={selectedClientId}
              />
            );
          default:
            return (
              <Grid>
                <Container
                  maxWidth="100%"
                  sx={{ mt: 3 }}
                  className="main-container relative"
                >
                  <Grid
                    container
                    justifyContent="end"
                    alignItems="center"
                    className="add-btn-container"
                  >
                    {/* <Grid item>
                      <Button
                        sx={{ mt: { xs: 0, md: 0 } }}
                        variant="contained"
                        className="btn-primary"
                        onClick={addClient}
                      >
                        Add Client
                      </Button>
                    </Grid> */}
                    {/* <Grid item>
            <Button
              sx={{
                mt: { xs: 0, md: 0 },
                mr: 2,
                border: '2px solid #003087 !important',
                color: '#003087 !important',
                padding: '6px 20px !important'
              }}
              variant="outlined"
              className="btn-primary"
              disabled
            >
              Client Groups
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{
                mt: { xs: 0, md: 0 },
                border: '2px solid #003087 !important',
                color: '#003087 !important',
                padding: '6px 20px !important'
              }}
              variant="outlined"
              className="btn-primary"
              disabled
            >
              Referrals
            </Button>
          </Grid> */}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                  >
                    <Container maxWidth="100%">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <CustomRevenueCards
                            title={object1.titleName}
                            subTitle={object1.subTitle}
                            Icon={<PercentIcon />}
                            Units={object1.Units}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <CustomRevenueCards
                            title={object2.titleName}
                            subTitle={object2.subTitle}
                            Icon={<AddCardIcon />}
                            Units={object2.Units}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <CustomRevenueCards
                            title={object3.titleName}
                            subTitle={object3.subTitle}
                            Icon={<AddCardIcon />}
                            Units={object3.Units}
                          />
                        </Grid>
                      </Grid>
                    </Container>
                  </Grid>
                  <Grid xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Grid
                        item
                        // xs={12} md={6} sm={6}
                        sx={{
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                      >
                        <Button
                          onClick={showFilter}
                          ref={containerRef}
                          variant={displayFilterForm ? 'contained' : 'outlined'}
                        >
                          {/* Filter (All) */}
                          Filters
                          <KeyboardArrowDownRoundedIcon
                            className={clsx(
                              !displayFilterForm && classes.closeY,
                              displayFilterForm && classes.openY
                            )}
                          />
                        </Button>
                      </Grid>

                      <Grid
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '10px',
                          marginBottom: '20px'
                        }}
                        className="top-filter-cont"
                      >
                        {isFranchisor === 'false' ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isAllLocation}
                                className="check-box-span text-blue"
                                color="default"
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#003087'
                                  }
                                }}
                                onChange={(e) =>
                                  SetAllLocation(e.target.checked)
                                }
                              />
                            }
                            label={
                              <Typography className="check-box-text">
                                All Locations
                              </Typography>
                            }
                          />
                        ) : (
                          isFranchisor === 'true' && (
                            <>
                              {/* <CustomSelect
                            fullWidth
                            name="selectedLocation"
                            disabled={!allLocationList?.length}
                            value={defaultLocId || (allLocationList && allLocationList?.length > 0 && allLocationList[0]?.locationId)}
                           //  onChange={(type, value) => setDefaultLocId(value)}
                           onChange={(item) => setSelectedLocation(item)}
                            label="Select Location"
                            className='w-50 mr-10 select-location select-loc-55'
                            // sx={{width: '50%', marginRight: '10px'}}
                            options={allLocationList?.map((item) => ({
                              label: item.locationName,
                              value: item.locationId,
                              franId: item.franchiseeId
                            }))}
                          /> */}
                              <FormControl
                                fullWidth
                                className="w-50 mr-10 select-location select-loc-55"
                              >
                                <InputLabel id="demo-simple-select-helper-label">
                                  Location
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  name="locationId"
                                  // onChange={setSelectedLocation}
                                  onChange={(event, selectedOptions) => {
                                    setSelectedLocation(selectedOptions);
                                  }}
                                  value={
                                    defaultLocId ||
                                    (allLocationList &&
                                      allLocationList?.length > 0 &&
                                      allLocationList[0]?.locationId)
                                  }
                                  label="Location"
                                  className="input-field-styling"
                                  MenuProps={{
                                    PaperProps: { style: { maxHeight: 200 } }
                                  }}
                                >
                                  {allLocationList.map((r, i) => (
                                    <MenuItem
                                      key={i}
                                      value={r.locationId}
                                      franchiseeId={r.franchiseeId}
                                    >
                                      {r.locationName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          )
                        )}
                        {/* Search box on table */}
                        <TextField
                          type="search"
                          className="Search-field"
                          label="Search"
                          sx={{ width: '215px' }}
                          InputProps={{
                            style: {},
                            endAdornment: !searchText ? <SearchIcon /> : null
                          }}
                          onChange={handleSearchTextChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    {displayFilterForm === true ? (
                      // <Slide
                      //   direction="right"
                      //   in={displayFilterForm}
                      //   timeout={{ enter: 1200, exit: 10 }}
                      //   container={containerRef.current}
                      // >
                      <Grid item width={getFilterSize()}>
                        <Filter
                          filters={sideBarfilters}
                          setFilters={setSidebarFilters}
                          getFranchiseeList={franchiseeList}
                          getLocationList={locationList}
                          getStates={states}
                        />
                      </Grid>
                    ) : // </Slide>
                    null}

                    <Grid
                      item
                      sx={{
                        width: `calc(100% - ${getFilterSize()}px)})`
                      }}
                    >
                      <Card
                        sx={{
                          padding: '15px',
                          borderRadius: '19px 19px 0px 0px'
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                          >
                            <CardHeader
                              title="All Clients"
                              className="table-header-name"
                              sx={{ fontSize: '20px', color: '#003087' }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '12px'
                            }}
                            className="membership-status-container status-field-ddl"
                          >
                            <CustomSelect
                              fullWidth
                              name="selectedStatus"
                              disabled={!selectedClientLists?.length}
                              value={selectedStatus}
                              onChange={(type, value) =>
                                setSelectedStatus(value)
                              }
                              label="Select Status"
                              options={[
                                {
                                  label: 'Activate',
                                  value: 1
                                },
                                {
                                  label: 'Deactivate',
                                  value: 0
                                }
                              ]}
                            />
                            <CustomButton
                              variant="contained"
                              disabled={
                                selectedStatus === '' ||
                                !selectedClientLists?.length
                              }
                              onClick={handleBulkAdd}
                              loading={btnLoading}
                            >
                              Update
                            </CustomButton>
                          </Grid>
                        </Grid>

                        {/* <Divider /> */}
                        <TableContainer className="client-table-container">
                          <Table>
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={selectedAllClientItems}
                                    indeterminate={selectedSomeClientItems}
                                    onChange={handleSelectAllClientItems}
                                  />
                                </TableCell>
                                <TableCell>Client Name</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Email</TableCell>
                                {/* <TableCell>Referral Resource</TableCell> */}
                                <TableCell className="align-center">
                                  Status
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {paginatedclientLists.map((clientItem) => {
                                const isClientItemSelected =
                                  selectedClientLists.includes(
                                    clientItem.sortKey
                                  );
                                return (
                                  <TableRow
                                    hover
                                    // key={clientItem.franchiseeId}
                                    key={clientItem.sortKey}
                                    selected={isClientItemSelected}
                                    className="table-row cursor-pointer"
                                    onClick={(e) =>
                                      handleViewClientDetails(clientItem, e)
                                    }
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        color="primary"
                                        checked={isClientItemSelected}
                                        onChange={(event) =>
                                          handleSelectOneClientItem(
                                            event,
                                            clientItem.sortKey
                                          )
                                        }
                                        value={isClientItemSelected}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="table-td"
                                      sx={{ display: 'flex' }}
                                    >
                                      {/* Icon of the CLient */}
                                      <Avatar
                                        alt="icon"
                                        src={
                                          clientItem.profileImage ||
                                          '/static/images/logo/clienticon.png'
                                        }
                                        sx={{ marginRight: 2, float: 'left' }}
                                        className="card-avatar-img align-self-center"
                                      />
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        sx={{ marginTop: 1 }}
                                        gutterBottom
                                        // noWrap
                                      >
                                        {clientItem.name
                                          ? clientItem.name
                                          : clientItem.firstName +
                                            ' ' +
                                            clientItem.lastName}
                                        {/* {clientItem.name} */}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {clientItem?.locationName}
                                      </Typography>
                                    </TableCell>
                                    {/* <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {clientItem.referralSource}
                                      </Typography>
                                    </TableCell> */}
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {clientItem?.mobilePhone}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                      >
                                        {clientItem?.email}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        color="text.primary"
                                        gutterBottom
                                        noWrap
                                        className="td-center"
                                      >
                                        {clientItem?.status === 1 ? (
                                          // Active Status icon
                                          <ActiveStatus />
                                        ) : (
                                          // Inactive Status icon
                                          <InactiveStatus />
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell className="table-td">
                                      {/* <Tooltip title="Edit Client" arrow> */}
                                      {/* <IconButton
                                        sx={{
                                          '&:hover': {
                                            background: theme.colors.primary.lighter
                                          },
                                          color: theme.palette.primary.main
                                        }}
                                        color="inherit"
                                        size="small"
                                        className="edit-btn"
                                        id={'edit_btn_' + clientItem.sortKey}
                                        onClick={(e) => handleEditClient(clientItem, e)}
                                      >
                                        Edit
                                      </IconButton> */}
                                      <MoreVertIcon
                                        onClick={(e) => openMenu(clientItem, e)}
                                        color="grey"
                                        variant="contained"
                                        className="three-dots-icon"
                                      />

                                      <Menu
                                        open={Boolean(anchor)}
                                        anchorEl={anchor}
                                        onClose={closeMenu}
                                        keepMounted
                                        PaperProps={{
                                          style: {
                                            maxHeight: 40 * 5,
                                            width: '25ch'
                                          }
                                        }}
                                      >
                                        <MenuItem
                                          className="edit-btn"
                                          id={'edit_btn_' + clientItem.sortKey}
                                          onClick={(e) =>
                                            onMenuItemClick(
                                              clientItem,
                                              e,
                                              'edit'
                                            )
                                          }
                                          // disabled
                                        >
                                          Edit
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) =>
                                            onMenuItemClick(
                                              clientItem,
                                              e,
                                              'addAlert'
                                            )
                                          }
                                        >
                                          Add Alert & Notes
                                        </MenuItem>
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box p={2}>
                          {/* Pagination */}
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            );
        }
      })()}

      {/* Add alert and notes */}
      <Box>
        {openAlertNotes && (
          <AddAlertNotes
            openAlertNotesDialog={openAlertNotesDialog}
            setOpenAlertNotesDialog={setOpenAlertNotesDialog}
            setOpenAlertNotes={setOpenAlertNotes}
            refreshAlertNotes={refreshAlertNotes}
            getClientItem={getClientItem}
            type={'client'}
          />
        )}
      </Box>

      {/* View Location dialog */}

      {/* // <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        //   <EditClient
        //     data={selectedClientItem}
        //     getEventToRefresh={getEventToRefresh}
        //     openEdit={setOpenEdit}
        //     openEditClient={openEditClient}
        //     setopenEditClient={setopenEditClient}
        //   />
        // </Box> */}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="details-dialog"
        className="dialog-box dialog-box-right client bg-white"
        maxWidth="lg"
      >
        <DialogTitle className="alert-title align-right dialog-header">
          <Box>
            {/* remove icon */}
            <Button
              className="details-remove-icon p-0 no-hover"
              onClick={handleClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="13" cy="13" r="13" fill="white" />
                <path
                  d="M17.7785 8.22908C17.7083 8.15877 17.625 8.10298 17.5332 8.06492C17.4415 8.02686 17.3431 8.00726 17.2438 8.00726C17.1444 8.00726 17.0461 8.02686 16.9543 8.06492C16.8625 8.10298 16.7792 8.15877 16.709 8.22908L13 11.9305L9.29097 8.2215C9.22075 8.15127 9.13738 8.09557 9.04563 8.05756C8.95388 8.01956 8.85554 8 8.75623 8C8.65692 8 8.55859 8.01956 8.46683 8.05756C8.37508 8.09557 8.29172 8.15127 8.2215 8.2215C8.15127 8.29172 8.09557 8.37508 8.05756 8.46683C8.01956 8.55859 8 8.65692 8 8.75623C8 8.85554 8.01956 8.95388 8.05756 9.04563C8.09557 9.13738 8.15127 9.22075 8.2215 9.29097L11.9305 13L8.2215 16.709C8.15127 16.7793 8.09557 16.8626 8.05756 16.9544C8.01956 17.0461 8 17.1445 8 17.2438C8 17.3431 8.01956 17.4414 8.05756 17.5332C8.09557 17.6249 8.15127 17.7083 8.2215 17.7785C8.29172 17.8487 8.37508 17.9044 8.46683 17.9424C8.55859 17.9804 8.65692 18 8.75623 18C8.85554 18 8.95388 17.9804 9.04563 17.9424C9.13738 17.9044 9.22075 17.8487 9.29097 17.7785L13 14.0695L16.709 17.7785C16.7793 17.8487 16.8626 17.9044 16.9544 17.9424C17.0461 17.9804 17.1445 18 17.2438 18C17.3431 18 17.4414 17.9804 17.5332 17.9424C17.6249 17.9044 17.7083 17.8487 17.7785 17.7785C17.8487 17.7083 17.9044 17.6249 17.9424 17.5332C17.9804 17.4414 18 17.3431 18 17.2438C18 17.1445 17.9804 17.0461 17.9424 16.9544C17.9044 16.8626 17.8487 16.7793 17.7785 16.709L14.0695 13L17.7785 9.29097C18.0667 9.00274 18.0667 8.51731 17.7785 8.22908Z"
                  fill="#003087"
                />
              </svg>
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent className="bg-white">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription bg-white"
          >
            <Box justifyContent="center" direction="column">
              {/* {openEdit ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <EditClient data={selectedClientItem} getEventToRefresh={getEventToRefresh} />
                </Box>
              ) : ( */}
              {/* // <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                //   <h1 className="client-name mb-0 mt-10">View Client</h1>
                // </Box> */}
              {/* // )} */}
            </Box>
          </DialogContentText>
        </DialogContent>
        {/* <Divider /> {/* <EditFranchisee data={ selectedclientItem } /> */}
        {/* <DialogActions className="alert-button-control">
          <Button className="font-17" onClick={() => handleClose("Delete")}>Delete</Button>
          <Divider className="vertical-divider" />
          <Button className="font-17 cancel-btn" onClick={() => handleClose("Cancel")}>Cancel</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

DrawClientTable.propTypes = {
  clientLists: PropTypes.array.isRequired
};

DrawClientTable.defaultProps = {
  clientLists: []
};

export default DrawClientTable;
