/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

 const TemplateFilter = ({
  setFilters,
  filters,
  tableData,
  getFranchisee
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));
//   const [Date, setDate] = useState(null);
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    channel: false,
    createdBy: false,
  });
  const[createdMenu, setCreatedMenu] =useState([])
  
  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
        createdBy: field === 'createdBy' ? !prevState.createdBy : false,
        channel: field === 'channel' ? !prevState.channel : false,
    }));
  };

  const handleChangeFilter = (value, type) => {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      createdBy: null,
      channel: null
    });

    setOpenFilter({
        createdBy: false,
        channel: false
    });
  };
  const createdByData = Array.from(new Set(tableData.map((obj) => obj.createdBy)));

  // setCreatedMenu(createdByData)
  console.log(tableData,'setCreatedMenu');

  return (
    <>
      <Card className="filter-content custom-filter-fields">
        {/* <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) =>
              // handleChangeFilter(event.target.value, 'isActive')
              handleChangeFilter(event.target.value, 'status')
            }
            label="Active"
            className="status-field field-label-css"
          >
           <MenuItem key={1} id={1} value={-1}>All</MenuItem>
        
            <MenuItem key={2} id={2} value={1}>Active</MenuItem>
            <MenuItem key={3} id={3} value={0}>Inactive</MenuItem>

          </Select>
        </FormControl>

        <Divider /> */}

        <Grid mt={2} mb={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text filter-text-title"
                sx={{
                  fontWeight: '600',

                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid
         mt={2}
          xs={12}
          onClick={() => handleOpenFilter('createdBy')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.createdBy && classes.closeY,
                    openFilter.createdBy && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Created By</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.createdBy && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Created By</InputLabel>
              <Select
                // multiple
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'createdBy')
                }
                value={filters.createdBy}
                sx={{
                  width: '100%'
                }}
                label="Created By "
                className="status-field field-label-css"
              >
              <MenuItem key={1} value='Franchisor'>Franchisor</MenuItem>
              <MenuItem key={2} value={getFranchisee}>{getFranchisee}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        
        <Grid
          xs={12}
          onClick={() => handleOpenFilter('channel')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.channel && classes.closeY,
                    openFilter.channel && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Channel</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.channel && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Channel</InputLabel>
              <Select
                // multiple
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'channel')
                }
                value={filters.channel}
                sx={{
                  width: '100%'
                }}
                label="Channel"
                className="status-field field-label-css"
              >
                 <MenuItem key={1}  value='All'>All</MenuItem>
                <MenuItem key={2} value='EMAIL'>Email</MenuItem>
                <MenuItem key={3} value='SMS'>Text</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}


       
      </Card>
    </>
  );
};
export default TemplateFilter;