
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Autocomplete,
  TextField
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
// import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { makeStyles } from '@material-ui/core/styles';

export const NotesFilters = ({
  setFilters,
  filters
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    notesFor: false
  });
  // const [startDate, setstartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      notesFor: field === 'notesFor' ? !prevState.notesFor : false
    }));
  };

  const changeNotesFor = (selectedOpt) => {
    setFilters((prevState) => ({ ...prevState, notesFor: selectedOpt }));
  };

  const clearFilters = () => {
    setFilters({
      status: filters.status,
      notesFor: null
    });

    setOpenFilter({
      notesFor: false
    });
  };

  const notesForList = [
    // {name : 'All'},
    {name : 'Client'},
    {name : 'Dog'}
  ];

  return (
    <>
      <Card className="filter-content custom-filter-fields">
        {/* <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Types</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) =>
              // handleChangeFilter(event.target.value, 'isActive')
              handleChangeFilter(event.target.value, 'status')
            }
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={null}>All</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider /> */}

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text filter-text-title"
                sx={{
                  fontWeight: '600',

                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item onClick={() => handleOpenFilter('notesFor')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.notesFor && classes.closeY,
                    openFilter.notesFor && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Notes For</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.notesFor && (
          <Box marginTop={1}>
            <Autocomplete
              // disablePortal
              value={filters.notesFor}
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                changeNotesFor(newValue);
              }}
              className="status-field field-label-css"
              options={notesForList && notesForList.map((option) => option && option?.name)}
              sx={{
                width: '100%'
              }}
              renderInput={(params) => (
                <TextField {...params} label={params?.label || 'Notes For'} />
              )}
            />
          </Box>
        )}

        {/* <Grid xs={12} onClick={() => handleOpenFilter('datefilter')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.datefilter && classes.closeY,
                    openFilter.datefilter && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.datefilter && (
          <>
            <Box marginTop={1}>
              <CustomDatePicker label="Start Date" onChange={changeStartDate} />
            </Box>
            <Box marginTop={1}>
              <CustomDatePicker
                label="End Date"
                onChange={changeEndDate}
                minDate={new Date(startDate)}
                disabled={Boolean(!startDate)}
              />
            </Box>
          </>
        )} */}
      </Card>
    </>
  );
};