import React from "react";
import { Box, Typography } from "@mui/material";
import { DynamicButton } from "../../../components/Checkout";
// import { getLocalData, setFullLocationId } from '../../../../utils';


export const ReturnFailed = ({ handlePage }) => {
  // const locationId = setFullLocationId(getLocalData('locationId'));

  return (
    <>
      <Box className="orderSuccess">
        <Typography
          variant="h1"
          align="center"
          color="primary"
          style={{ marginBottom: "15px" }}
        >
          Redirect to Square UI
        </Typography>


        <DynamicButton
          title={"Back to POS Dashboard"}
          onClick={() => {
            handlePage();
            // navigate('/');
          }}
          variant="text"
          style={{
            padding: "0",
            maxWidth: "max-content",
            background: "transparent",
            textDecoration: "underline",
            fontWeight: "600",
            fontSize: "15px",
            margin: "0 auto",
            display: "block"
          }}
        />
      </Box>

    </>
  );
};
