import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { getLocalData } from '../../utils';
import { ApiService } from '../../services/ApiService';



export const BundlesFilter = ({ setFilters, filters, isFeatured }) => {
 
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const classes = useStyles();
  const locationId = getLocalData('locationId');
  const isFranchisor = getLocalData('isFranchisor');
  const [discountData, setDiscountdata] = useState([]);
  const [openFilter, setOpenFilter] = useState({
    itemType: false
  });
  

  const getBundles = async () => {
    let encodedLocationId = encodeURIComponent(locationId);
    try {
      let url;
      if (isFranchisor === 'false') {
        url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}bundles?locationId=${encodedLocationId}${isFeatured ? '&status=1' : ""}`;
      } else {
        url = `${process.env.REACT_APP_API_END_POINT}bundles`;
      }
        let response = await ApiService.get(`${url}`);
        let Item = response && response.data && response.data.Items ? response.data.Items : [];
        const discount = Array.from(new Set(Item.map(obj => obj.discount)));
        setDiscountdata(discount); 
    } catch (error) {
        console.log(error);
    }
};

  useEffect(() => {
    getBundles();
  }, []);

  const changeType = (value) => {
   setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const ChangeItem = (value) => {
    setFilters((prevState) => ({ ...prevState, discount: value }));
  };

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      discount: field === 'discount' ? !prevState.discount : false
    }));
  };

  const clearFilters = () => {
    setFilters({
      // isActive: filters.isActive,
      status: filters.status, 
      discount: ''
    });

    setOpenFilter({
      discount: false,
    });
  };

  return (
    <>
      <Card className="filter-content">
         <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            // value={filters.isActive ?? ''}
            // value={filters.status ?? ''}
            value={filters.status ?? 1}  // Set the default value to 1 for "Active"
            onChange={(event) => changeType(event.target.value)}
            label="Status"
            className="status-field field-label-css"
          >
            {!isFeatured && <MenuItem key={1} id={1} value={-1}>All</MenuItem>}
            {/* <MenuItem key={2} id={2} value={Boolean(true)}>Active</MenuItem>
            <MenuItem key={3} id={3} value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem key={2} id={2} value={1}>Active</MenuItem>
            {!isFeatured && <MenuItem key={3} id={3} value={0}>Inactive</MenuItem>}
            {!isFeatured && <MenuItem key={4} id={4} value={3}>Expired</MenuItem>}
          </Select>
        </FormControl>
        <Divider /> 
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} onClick={() => handleOpenFilter('discount')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.discount && classes.closeY,
                    openFilter.discount && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography> Discounts </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.discount && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Discount</InputLabel>
              <Select
                onChange={(event) => ChangeItem(event.target.value)}
                value={filters.discount ?? ''}
                sx={{
                  width: '100%',
                  fontSize : '18px'
                }}
                label="Select Item"
                className="status-field field-label-css"
                MenuProps={{
                      PaperProps: {
                          style: {
                              maxHeight: 224,
                              overflowY: 'auto',
                          },
                      },
                    }}
              >             
                {discountData.length > 0 &&
                  discountData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}                
              </Select>
            </FormControl>
          </Box>
        )}
      </Card>
    </>
  );
};


