import React from 'react';
import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
  MenuItem,
  Slide,
  FormHelperText
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CreateVariantModal = ({
  openAddVariant,
  handleCloseVariant,
  handleCreateVariant,
  varientType,
  variantName,
  setvariantName,
  handleVariantTypeChange,
  variantTypeId,
  createVariantErrors,
  setCreateVariantErrors
}) => {

  const handleClose = () => {
    setCreateVariantErrors({
      varientName: "",
      variantType: ""
    });
    handleCloseVariant();
  };
  

  const handleSubmit = () => {
    const error = !variantName || !variantTypeId;
    
    setCreateVariantErrors({
      varientName: !variantName ? "Varient Name is required." : "",
      variantType: !variantTypeId ? "Varient Type is required." : "",
    });
  
    return error ? null : handleCreateVariant();
  };
  console.log(createVariantErrors?.variantType, "createVariantErrors are aocndascasd")
  return (
    <Dialog
      open={openAddVariant}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()}
      aria-describedby="alert-dialog-slide-description"
      // sx={{ width: '350px' }}
    >
      <DialogTitle className="alert-title align-center">
        {'Add Variant'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
        >
          <Box
            display="flex"
            justifyContent="center"
            direction="column"
            width={300}
            mt={1}
            mb={2}
          >
            <Grid container spacing={2}>
              
              <Grid item xs={12} sm={12}>
                <FormControl
                  fullWidth
                  className="select-drop-down-label-styling"
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    className="weight-700 font-15"
                  >
                    VARIANT TYPE*
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="variantId"
                    onChange={
                      (e) => {
                        handleVariantTypeChange(e)
                        setCreateVariantErrors({
                          ...createVariantErrors,
                          variantType: "",
                        });
                      }
                    }
                    value={variantTypeId}
                    label="VARIANT TYPE*"
                    className="input-field-styling align-left"
                    required
                    helperText={createVariantErrors?.variantType}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 200 } }
                    }}
                  >
                    {varientType.length > 0 &&
                      varientType.map((r, i) => (
                        <MenuItem key={i} value={r.variantId}>
                          {r.variantType}
                        </MenuItem>
                      ))}
                  </Select>
                  {createVariantErrors?.variantType && 
                    <FormHelperText>
                      {createVariantErrors?.variantType}
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="VARIANT NAME"
                  name="variantName"
                  className="location-field input-field-styling"
                  onChange={
                    (e) =>  { 
                      setvariantName(e.target.value)
                      setCreateVariantErrors({
                        ...createVariantErrors,
                        varientName: "",
                      });
                    }
                  }
                  required
                  helperText={createVariantErrors?.varientName}
                  value={variantName}
                  variant="outlined"
                  InputLabelProps={{
                    className: 'label-style-create-franchisee'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 text-black no-hover"
          onClick={() => handleClose()}
        >
          {'Close'}
        </Button>
        <Divider className="vertical-divider" />
        <Button
          className="font-17 no-hover"
          onClick={() => handleSubmit()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateVariantModal;
