import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Button,
  Slide
} from '@mui/material';
// import axios from 'axios';
// import SearchIcon from '@mui/icons-material/Search';
// import { CustomButton } from 'src/components/Global';
import PricingOptionsDetails from './PricingOptionsDetails';
import AddPricingOption from './AddPricingOption';
import PricingOptionEdit from './PricingOptionEdit';
import { CustomTable } from '../../../components/CustomTable';
import { PricingOptionsFilter } from '../../../components/Filters/PricingOptionsFilter';
import { getLocalData } from '../../../utils'; // setFullLocationId
import { filtersQuery } from '../../../utils/index';
import '../MyStyle/myStyle.css';
import Loader from '../../../components/Loader';
import {
  ActiveStatus,
  InactiveStatus,
  ExpiredStatus
} from 'src/assets/SVG/SvgIcons';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import { ApiService } from '../../../services/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PricingOptionsList = ({
  openAddPricing,
  openAddPricingModal,
  setAddPricingModal,
  setOpenAddPricing,
  showScreen,
  setShowScreen
}) => {
  // const locationId = setFullLocationId(getLocalData('locationId'));
  // alert(locationId);

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [openEditPage, setOpenEditPage] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  let [filters, setFilters] = useState({
    status: 1,
    categoryId: '',
    serviceId: '',
    free: '',
    sellOnline: ''
  });
  const [open, setOpen] = React.useState(false);
  // check updated or saved
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userId = getLocalData('userId');
  // const locationId = getLocalData('locationId');
  // get user role and provide access of that roles
  const isFranchisor = getLocalData('isFranchisor');
  // const isPrimaryOwner = getLocalData('isPrimaryOwner');
  const locationId = getLocalData('locationId');
  // const franchiseeId = getLocalData('franchiseeId');

  useEffect(() => {
    // setPricingList(info.data.Items);
    setShowScreen(0);
    getPricingOptionDetail();
  }, [filters]);

  const getPricingOptionDetail = async () => {
    setLoading(true);
    // status= 0-1-2-3,categoryId = ID, serviceId=ID,ID, free = true, false, sellOnline = true, false
    let queryString = await filtersQuery(filters);
    // let filtersParams = queryString ? `?${queryString}` : '';
    let filtersParams = '';
    let encodedLocationId = encodeURIComponent(locationId);
    let url;
    if (isFranchisor === 'false') {
      filtersParams = queryString ? `&${queryString}` : '';
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options?locationId=${encodedLocationId}${filtersParams}`;
    } else {
      filtersParams = queryString ? `?${queryString}` : '';
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/getAllPricingOptions${filtersParams}`;
    }
    // fetch(`${process.env.REACT_APP_API_END_POINT}pricing-option/getAllPricingOptions${filtersParams}`, {
      ApiService.get(`${url}`)
      .then((response) => response)
      .then((data) => {
        let Item = data && data.data && data.data.Items ? data.data.Items : [];
        console.log(data.data.Items, 'tableDatatableData');
        setLoading(false);
        const result = Item?.map((obj) => {
          const {
            partitionKey,
            sortKey,
            name,
            categoryName,
            // services,

            price,
            numberOfSessions,
            status
          } = obj;

          // const serviceNames = services?.map((service) => service.name);

          return {
            partitionKey,
            sortKey,
            Name: name,
            Category: categoryName,
            // Service: serviceNames,
            'Expiration term': obj?.creditsValidityExpires?.duration
              ? `${obj?.creditsValidityExpires?.duration} ${obj?.creditsValidityExpires?.unit}`
              : '-',
            Price: price ? `$${parseFloat(price)?.toFixed(2)}` :  '$0.00',
            Credits: numberOfSessions,
            Status:
              status === 1 ? (
                <ActiveStatus />
              ) : status === 3 ? (
                <ExpiredStatus />
              ) : (
                <InactiveStatus />
              )
          };
        });

        setTableData(result);

        setData(Item);
      });
  };

  const handleOnClick = (record) => {
    let arr = [record[1]];
    let filterRecord = data?.filter((i) => arr.includes(i.sortKey));
    setSelectedData(filterRecord);

    if (record[2] === 'Detail') {
      setShowScreen(1);
    }
    if (record[2] === 'Edit') {
      setIsOpenEdit(true);
      setOpenEditPage(true);
    }
    console.log(filterRecord, record[2]);
  };

  // method to handle bulk update
  const handleBulkAdd = async () => {
    console.log('handleBulkAdd');
    setIsUpdated(false);
    setOpen(true);
  };

  // Method to handle bulk update status final confirm
  const confirmBulkUpdate = async () => {
    let url;
    let data;
    if (isFranchisor === 'false') {
      url = `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}pricing-options/bulkUpdate`;
    } else {
      url = `${process.env.REACT_APP_API_END_POINT}pricing-option/bulkUpdate`;
    }
    setOpen(false);
    setBtnLoading(true);
    setLoading(true);
    // show loader
    // setIsLoading(true);

    if (isFranchisor === 'false') {
      data = {
        locationId: locationId,
        status: selectedStatus,
        updatedBy: userId,
        pricingOptionIds: selectedLists
      };
    } else {
      data = {
        status: selectedStatus,
        updatedBy: userId,
        pricingOptionIds: selectedLists
      };
    }

    try {
      // const encodedLocationId = encodeURIComponent(locationId);
      const response = await  ApiService.put(`${url}`, data)

      if (!response.data?.error) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage(
          'All the selected pricing options have been updated successfully!'
        );
        setSelectedStatus('');
        setSelectedLists([]);
        setIsUpdated(true);
        // setOpen(true);
        setOpen(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(
          response.data?.message ||
            'Something went wrong with update pricing option status'
        );

        // toast.error(
        //   response.data?.message ||
        //     'Something went wrong with update bundles status'
        // );
      }
      // show loader
      // setIsLoading(false);
      // setLoading(false);
      setLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      setLoading(false);
      // show loader
      // setIsLoading(false);
      // toast.error('Something went wrong, Try again!');
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!');
    }
  };

  // Method to handle close of dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getPricingOptionDetail();
        setIsUpdated(false);
      }
    }
  };

  // Method to trigger when close the popup
  const closePopup = () => {
    setShowSuccessMessage(false);
    getPricingOptionDetail();
  };

  return (
    <>
      <Loader IsLoading={loading} />
      {(() => {
        switch (showScreen) {
          case 1:
            return (
              <PricingOptionsDetails
                getPricingOptionDetail={getPricingOptionDetail}
                selectedData={selectedData}
                setShowScreen={setShowScreen}
                openEditPage={openEditPage}
                setOpenEditPage={setOpenEditPage}
                isOpenEdit={isOpenEdit}
                setIsOpenEdit={setIsOpenEdit}
              />
            );
          default:
            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography className="page-title"> </Typography>
                  {/* <Stack spacing={2} direction="row">
                                        <Button variant="contained" className="top-button-style" onClick={() => AddPricing()}>Add Pricing Option</Button>
                                    </Stack> */}
                </Box>
                {showErrorMessage && (
                  <ErrorMessage
                    message={errorMessage}
                    setShowMessage={setShowErrorMessage}
                  />
                )}

                {/* Added one more callback function to identify when close the popup */}
                {showSucessMessage && (
                  <SuccessMessage
                    message={sucessMessage}
                    setShowMessage={setSuccessMessage}
                    closePopup={closePopup}
                  />
                )}

                <CustomTable
                  data={tableData}
                  handleOnClick={handleOnClick}
                  filterCompoenent={
                    <PricingOptionsFilter
                      setFilters={setFilters}
                      filters={filters}
                    />
                  }
                  label="All Pricing Options"
                  selectedLists={selectedLists}
                  setSelectedLists={setSelectedLists}
                  open={open}
                  setOpen={setOpen}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  handleBulkAdd={handleBulkAdd}
                  btnLoading={btnLoading}
                />

                {openAddPricing ? (
                  <AddPricingOption
                    setOpenAddPricing={setOpenAddPricing}
                    openAddPricingModal={openAddPricingModal}
                    setAddPricingModal={setAddPricingModal}
                    getPricingOptionDetail={getPricingOptionDetail}
                  />
                ) : null}

                {/* Status Update Dialog confirmation */}
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => handleClose('Cancel')}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle className="alert-title align-center">
                    {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      className="alert-decription align-center"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        direction="column"
                      >
                        {!isUpdated ? (
                          <Typography className="title-text-sub">
                            Are you sure you want to mark Activate/Deactivate,
                            <Typography className="title-text-sub">
                            all the selected items?
                          </Typography>
                          </Typography>
                        ) : (
                          <Typography className="title-text-sub">
                           All the selected pricing options have been updated successfully!
                          </Typography>
                        )}
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                  <Divider />
                  <DialogActions className="alert-button-control">
                    <Button
                      className="font-17 text-black no-hover"
                      onClick={() => handleClose('Discard')}
                    >
                      {isUpdated ? 'Close' : 'Cancel'}
                    </Button>
                    {!isUpdated && <Divider className="vertical-divider" />}
                    {!isUpdated && (
                      <Button
                        className="font-17 no-hover"
                        onClick={() => handleClose('Save')}
                      >
                        Confirm
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
              </>
            );
        }
      })()}
      {isOpenEdit ? (
        <PricingOptionEdit
          selectedData={selectedData}
          openEditPage={openEditPage}
          setOpenEditPage={setOpenEditPage}
          setIsOpenEdit={setIsOpenEdit}
          getPricingOptionDetail={getPricingOptionDetail}
          setShowScreen={setShowScreen}
        />
      ) : null}
    </>
  );
};

export default PricingOptionsList;
