import { useState, createContext } from 'react';

export const HeaderContext = createContext({});

export const HeaderProvider = ({ children }) => {
  const [headerToggle, setHeaderToggle] = useState(false);
  const [headerText, setHeaderText] = useState('');
  // half close side bar
  // const [headerToggleHalf, setSidebarToggleHalf] = useState(false);
  const toggleHeader = () => {
    setHeaderToggle(!headerToggle);
  };
  const closeHeader = () => {
    setHeaderToggle(false);
  };

  // const toggleSidebarHalf = () => {
  //   setSidebarToggleHalf(!headerToggleHalf);
  // };
  // , headerToggleHalf, toggleSidebarHalf
  return (
    <HeaderContext.Provider
      value={{
        headerToggle,
        toggleHeader,
        closeHeader,
        headerText,
        setHeaderText
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
