import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  Card,
  Grid,
  Paper,
  // Autocomplete,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';

// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { enterNumber } from 'src/utils';

const Filter = ({ setFilters, filters, getFranchiseeList, locationList }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors

  // const useAutocompleteStyles = makeStyles({
  //   inputRoot: {
  //     // borderRadius: 10, // or any other value you prefer
  //   },
  //   option: {
  //     '&:hover': {
  //       color: 'white'
  //       // or any other color you prefer
  //     }
  //   },
  //   clearIndicator: {
  //     color: '#003087', // or any other color you prefer
  //     backgroundColor: '#F8FBFF',
  //     '&:hover': {
  //       backgroundColor: '#F8FBFF'
  //     }
  //   }
  // });

  const classes = useStyles();
  // const autoCompleteClasses = useAutocompleteStyles();

  // const [productCategoryOpen, setProductCategoryOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  // const [deliveryOpen, setDeliveryOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);

  console.log(getFranchiseeList);
  const handleActiveChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, isActive: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  // const handlFranchiseeChange = (event) => {
  //   // setFranchiseeName(event.target.name);
  //   // filters.franchiseeName = event.target.name;
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     franchiseeId: event.target.value
  //   }));
  // };

  const handleLocationChange = (event) => {
    // setLocationName(event.target.name);
    // filters.locationName = event.target.name;
    setFilters((prevState) => ({
      ...prevState,
      locationId: event.target.value
    }));
  };

  const handleProductChange = (value) => {
     value = enterNumber(value);
    setFilters((prevState) => ({
      ...prevState,
      productCount: value
    }));
  };

  // const handleDeliveryChange = (value) => {
  //    value = enterNumber(value);
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     deliveryTerms: value
  //   }));
  // };

  // const handleProductClick = () => {
  //   setProductCategoryOpen(!productCategoryOpen);
  //   setLocationOpen(false);
  //   setDeliveryOpen(false);
  // };

  const handleProductCountClick = () => {
    setLocationOpen(false);
    // setDeliveryOpen(false);
    // setProductCategoryOpen(false);
    setProductOpen(!productOpen);
  };

  // const handleDeliveryClick = () => {
  //   setProductCategoryOpen(false);
  //   setLocationOpen(false);
  //   setDeliveryOpen(!deliveryOpen);
  // };

  const handleLocationClick = () => {
    setLocationOpen(!locationOpen);
    setProductOpen(false);
    // setDeliveryOpen(false);
    // setProductCategoryOpen(false);
    // setProductCategoryOpen(false);
  };

  const handleClearFilter = () => {
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      // filterLocationId: null,
      productCount: null,
      // deliveryTerms: null
    });
  };

  return (
    <>
      <Card className="filter-content custom-filter-form">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Status</InputLabel>
          <Select
            // value={filters.isActive ?? ''}
            value={filters.status ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={null}>All</MenuItem>
            {/* <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text cursor-pointer"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>
{/* 
        <Grid item onClick={handleProductClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !productCategoryOpen && classes.closeY,
                    productCategoryOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Product Categories</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {productCategoryOpen && (
          <Box marginTop={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                PRODUCT
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="prooduct"
                onChange={handlFranchiseeChange}
                value={filters.franchiseeId}
                label="PRODUCT"
                className="input-field-styling"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                {locationList.map((r, i) => (
                  <MenuItem key={i} value={r.locationId}>
                    {r.locationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )} */}

        <Grid xs={12} onClick={handleLocationClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !locationOpen && classes.closeY,
                    locationOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Location</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {locationOpen && (
          <Box marginTop={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                LOCATION
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="locationId"
                onChange={handleLocationChange}
                value={filters.locationId}
                label="LOCATION"
                className="input-field-styling"
                MenuProps={{
                  PaperProps: { style: { maxHeight: 200 } }
                }}
              >
                {locationList.map((r, i) => (
                  <MenuItem key={i} value={r.locationId}>
                    {r.locationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Grid item onClick={handleProductCountClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !productOpen && classes.closeY,
                    productOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Product provided</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {productOpen && (
          <Box marginTop={1}>
            <TextField
              fullWidth
              name="product-provided"
              label="Product provided"
              placeholder="Product provided"
              value={filters.productCount}
              //   onChange={(e) => handleProductChange(e.target.value)}
            //   onChange={handleProductChange}
            onChange={(event) =>
                handleProductChange(event.target?.value)
              }
              className="status-field field-label-css"
              InputLabelProps={{
                className: 'label-style-create-franchisee',
                shrink: true
              }}
              InputProps={{}}
            />
          </Box>
        )}

        {/* <Grid item onClick={handleDeliveryClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !deliveryOpen && classes.closeY,
                    deliveryOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Delivery term</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {deliveryOpen && (
          <Box marginTop={1}>
            <TextField
              fullWidth
              name="delivery-term"
              label="Delivery term"
              placeholder="Delivery term"
              value={filters.deliveryTerms}
              onChange={(event) =>
                handleDeliveryChange(event.target?.value, 'totalCost')
              }
              className="status-field field-label-css"
              InputLabelProps={{
                className: 'label-style-create-franchisee',
                shrink: true
              }}
              InputProps={{}}
            />
          </Box>
        )} */}
      </Card>
    </>
  );
};

export default Filter;
