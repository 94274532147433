/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,

} from '@mui/material';
import styled from 'styled-components';

export const CustomSelect = ({
  id,
  onChange,
  value,
  defaultValue,
  label,
  options,
  placeholder,
  fullWidth,
  required,
  disabled,
  name,
  error,
  helperText,
  multiple, 
  classLabelProps,
  className
}) => {

  const handleChange
   = (event) => {
    let value = event.target.value;
    if (onChange) {
      if (multiple) {
        onChange(name, typeof value === 'string' ? value.split(',') : value);
      } else {
        onChange(name, value);
      }
    }
  };

  return (

    <FormControl fullWidth={fullWidth} error={error}  className={`${className} ${required && "required-field"} ${ error ? 'helper-error' : ''}`}>
      {label && (
        <InputLabel id={id} className={`label-style-create-order input-value required-label ${classLabelProps}`}>
          {label}
        </InputLabel>
      )}
      <StyledSelect
        id={id}
        fullWidth={fullWidth}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        displayEmpty
        label={label}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 224,
              overflowY: 'auto'
            }
          }
        }}
        disabled={disabled}
        // required={required}
        multiple={multiple}
        sx={{ textAlign: 'left'}}
        // style={{height:"40px"}}
        inputProps={{
          'aria-label': label || '',
          placeholder: placeholder || ''
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value} >
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {helperText && <FormHelperText >{helperText}</FormHelperText>}
    </FormControl>

  );
};

const StyledSelect = styled(Select)(() => ({
  //   background: theme.palette.background.default,
  borderRadius: '12px',
  height: '45px',
  lineHeight: '30px',
  color: '#525252',
  '& .MuiSelect-select': {
    paddingLeft: '10px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSelect-icon': {
    color: '#003087'
  },
 

}));
