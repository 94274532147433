import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
//  TextField,
  Card,
  Grid,
  Paper,
//  Autocomplete,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';

// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const Filter = ({
  setFilters,
  filters,
  getRoleList,
 // getUserTeam
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors
  // const useAutocompleteStyles = makeStyles({
  //   inputRoot: {
  //     // borderRadius: 10, // or any other value you prefer
  //   },
  //   option: {
  //     '&:hover': {
  //       color: 'white'
  //       // or any other color you prefer
  //     }
  //   },
  //   clearIndicator: {
  //     color: '#003087', // or any other color you prefer
  //     backgroundColor: '#F8FBFF',
  //     '&:hover': {
  //       backgroundColor: '#F8FBFF'
  //     }
  //   }
  // });

  const classes = useStyles();
 // const autoCompleteClasses = useAutocompleteStyles();

//  const [teamOpen, setTeamOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
//  const [locationOpen, setLocationOpen] = useState(false);
  // const [locationOpen, setLocationOpen] = useState(false);

//  const [teamSelected, setTeamSelected] = useState([]);
 // const [locationSelected, setLocationSelected] = useState([]);
  const handleActiveChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, isActive: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  // Method to handle role change and set filters
  const handlRoleChange = (value) => {
    setFilters((prevState) => ({ ...prevState, roleName: value }));
  };

  // Method to handle team change and set filters
  // const handlTeamChange = (value) => {
  //   const teamId = value.map((val) => {
  //     return val.teamId;
  //   });

  //   // Remove the same value of array from teamId
  //   const uniqueteamId = teamId.filter(function (item, pos) {
  //     return teamId.indexOf(item) === pos;
  //   });
  //   setFilters((prevState) => ({ ...prevState, teamId: uniqueteamId }));
  // };


  // const handleTeamClick = () => {
  //   setTeamOpen(!teamOpen);
  //   // setLocationOpen(false);
  //   setRoleOpen(false);
  // };


  const handleRoleClick = () => {
    setRoleOpen(!roleOpen);
  //  setTeamOpen(false);
    // setLocationOpen(false);
  };

  const handleClearFilter = () => {
  //  setTeamSelected([]);
   // setLocationSelected([]);
    setFilters({
      // isActive: filters.isActive,
      status: filters.status,
      roleName: null,
      locationName: null,
      teamId: []
    });
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Status</InputLabel>

          <Select
            value={filters.status ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={-1}>All</MenuItem>
            {/* <MenuItem value={Boolean(true)}>Active</MenuItem>
            <MenuItem value={Boolean(false)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text cursor-pointer"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item onClick={handleTeamClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !teamOpen && classes.closeY,
                    teamOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Team</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {teamOpen && (
          <Box marginTop={1}>
            <Autocomplete
              classes={autoCompleteClasses}
              multiple
              value={teamSelected}
              onChange={(event, newValue) => {
                handlTeamChange(newValue);
                setTeamSelected(newValue);
              }}
              className="status-field field-label-css status-field-lbl"
              // options={getUserTeam.map((option) => option.teamName)}
              options={getUserTeam}
              getOptionLabel={(option) => option.teamName}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search Team" />
              )}
            />
          </Box>
        )} */}


        <Grid xs={12} onClick={handleRoleClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !roleOpen && classes.closeY,
                    roleOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Role</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {roleOpen && (
          <Box marginTop={1}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                width: '100%'
              }}
            >
              <InputLabel>Role</InputLabel>
              <Select
                onChange={(event) => handlRoleChange(event.target.value)}
                value={filters.roleName ?? ''}
                label="Region"
                className="status-field field-label-css"
              >
                {getRoleList.map((item) => (
                  <MenuItem id={item.role} value={item.role}>
                    {item.role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {/* <Grid xs={12} onClick={handleLocationClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !locationOpen && classes.closeY,
                    locationOpen && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Location</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {locationOpen && (
          <Box marginTop={1}>
            <Autocomplete
              classes={autoCompleteClasses}
              value={filters.locationName}
              onChange={(event, newValue) => {
                handleLocationChange(newValue);
              }}
              //  onInputChange={(event, newInputValue) => {
              //    handleCityChange(newInputValue);
              //  }}
              className="status-field field-label-css status-field-lbl"
              options={getLocationList.map((option) => option.locationName)}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search Location" />
              )}
            />
          </Box>
        )} */}
      </Card>
    </>
  );
};

export default Filter;
