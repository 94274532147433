// THIS IS THE FILTER COMPONENT FOR THE PRODUCT MANAGEMENT PAGE
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
  Card,
  Grid,
  Paper,
  Autocomplete,
  Divider,
  Box
  // Checkbox,
  // FormControlLabel
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import MenuItem from '@mui/material/MenuItem';

// Import Styles from Material UI
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ApiService } from 'src/services/ApiService';

const Filter = ({ setFilters, filters, categoryList, varientType }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors

  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        // color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const classes = useStyles();
  const autoCompleteClasses = useAutocompleteStyles();

  // const [openBestSeller, setOpenBestSeller] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openVaraintType, setopenVaraintType] = useState(false);

  const [variantValueData, setVariantValueData] = useState([]);

  const handleActiveChange = (value) => {
    // setFilters((prevState) => ({ ...prevState, isDiscontinued: value }));
    setFilters((prevState) => ({ ...prevState, status: value }));
  };

  const handleCategoryChange = (value) => {
    setFilters((prevState) => ({ ...prevState, categoryName: value }));
  };

  const handleVariantChange = (e) => {
    const { name, value } = e.target;
    if (name === 'variantType') {
      const variantId = value.variantId;
      const variantType = value.variantType;

      if (value) getVariantValue(variantId);
      setFilters((prevState) => ({
        ...prevState,
        variantType: variantType,
        variantValue: null
      }));
    }
  };

  const handleVariantValueChange = (value) => {
    setFilters((prevState) => ({ ...prevState, variantValue: value }));
  };

  // const handleBestSellerChange = (event) => {
  //   let { checked } = event.target;
  //   if (!checked) {
  //     checked = null;
  //   }
  //   setFilters((prevState) => ({ ...prevState, isBestSeller: checked }));
  // };

  const getVariantValue = async (id) => {
    let encodedId = encodeURIComponent(id);

    try {
      let response = await ApiService.get(
        `${process.env.REACT_APP_INVENTRY_API_END_POINT}variant-values/getVariantValues?variantId=${encodedId}`
      );
      let Item =
        response && response.data && response.data.Items
          ? response.data.Items
          : [];

      setVariantValueData(Item || []);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVariantClick = () => {
    setopenVaraintType(!openVaraintType);

    // setOpenBestSeller(false);
    setOpenCategory(false);
  };

  const handleCategoryClick = () => {
    setOpenCategory(!openCategory);
    // setOpenBestSeller(false);
    setopenVaraintType(false);
  };

  // const handleBestSeller = () => {
  //   setOpenBestSeller(!openBestSeller);
  //   setOpenCategory(false);
  //   setopenVaraintType(false);
  // };

  const handleClearFilter = () => {
    // setStartDate(null);
    // setEndDate(null);
    setFilters({
      // isDiscontinued:  filters.isDiscontinued,
      status: filters.status,
      categoryName: null,
      // isBestSeller: null,
      variantType: null,
      variantValue: null
    });
    setOpenCategory(false);
    // setOpenBestSeller(false);
    setopenVaraintType(false);
    setVariantValueData([]);
  };

  return (
    <>
      <Card className="filter-content">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>
        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Status</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) => handleActiveChange(event.target.value)}
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value="All">All</MenuItem>
            {/* <MenuItem value={Boolean(false)}>Active</MenuItem>
            <MenuItem value={Boolean(true)}>Inactive</MenuItem> */}
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider />
        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={handleClearFilter}>
              <Typography
                className="filter-text cursor-pointer"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'cursor-pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid onClick={handleCategoryClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openCategory && classes.closeY,
                    openCategory && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Category</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openCategory === true && (
          <Box marginTop={1} sx={{ background: 'white' }}>
            <Autocomplete
              classes={autoCompleteClasses}
              // value={getCity || ''}
              value={filters.categoryName ?? ''}
              onChange={(event, newValue) => {
                handleCategoryChange(newValue);
              }}
              // onInputChange={(event, newInputValue) => {
              //   handleCategoryChange(newInputValue);
              // }}
              className="status-field field-label-css"
              options={categoryList.map((option) => option?.categoryName || '')}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search Category"
                />
              )}
            />
          </Box>
        )}

        <Grid onClick={handleVariantClick} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openVaraintType && classes.closeY,
                    openVaraintType && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Variants</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {openVaraintType === true && (
          <Box marginTop={1} sx={{ background: 'white' }}>
            {/* <Autocomplete
              classes={autoCompleteClasses}
              value={filters?.variantType ?? ''}
              onChange={(event, newValue) => {
                handleVariantChange(newValue);
              }}
              className="status-field field-label-css"
              options={varientType}
              getOptionLabel={(option) => option?.variantType || ''}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label={`Search Variant Type`}
                />
              )}
            /> */}

            <Autocomplete
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                if (newValue && newValue) {
                  handleVariantChange({
                    // target: { name: 'locationId', value: newValue.locationId ? newValue.locationId : '' }
                    target: { name: 'variantType', value: newValue }
                  });
                } else {
                  handleVariantChange({
                    target: { name: 'variantType', value: '' }
                  });
                  setVariantValueData([]);
                }
              }}
              className="status-field field-label-css"
              // options={locationList.map((option) => option.locationName)}
              options={varientType}
              getOptionLabel={(option) => option?.variantType || ''}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={params?.label || 'Search Variant Type'}
                  className="primary-color"
                  // error={locationError}
                  InputLabelProps={{}}
                />
              )}
            />
          </Box>
        )}

        {openVaraintType === true && (
          <Box marginTop={1} sx={{ background: 'white' }}>
            <Autocomplete
              classes={autoCompleteClasses}
              value={filters.variantValue ?? ''}
              onChange={(event, newValue) => {
                handleVariantValueChange(newValue);
              }}
              className="status-field field-label-css"
              options={variantValueData.map((option) => option?.value || '')}
              sx={{
                width: '100%',
                borderRadius: '19px ! important'
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="primary-color"
                  label="Search Variant"
                />
              )}
            />
          </Box>
        )}

        {/* <Grid xs={12} onClick={handleBestSeller} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="cursor-pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openBestSeller && classes.closeY,
                    openBestSeller && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>BestSeller</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openBestSeller && (
          <Box marginTop={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.isBestSeller ?? ''}
                  className="check-box-span"
                  onChange={handleBestSellerChange}
                  color="default"
                  sx={{
                    '&.Mui-checked': {
                      color: '#003087'
                    }
                  }}
                />
              }
              label={
                <Typography className="check-box-text">Best Seller</Typography>
              }
            />
          </Box>
        )} */}
      </Card>
    </>
  );
};

export default Filter;