import React from 'react';
import {
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Slide,
  Typography
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ConfirmationModal = ({ open, handleClose, isUpdated }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose('Cancel')}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className="alert-title align-center">
        {isUpdated ? 'Congratulations!' : 'Discard Changes?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="alert-decription align-center"
        >
          <Box display="flex" justifyContent="center" direction="column">
            {!isUpdated ? (
              <Typography className="title-text-sub">
                You have unsaved changes.
                <Typography className="title-text-sub">
                  What do you want to do?
                </Typography>
              </Typography>
            ) : (
              <Typography className="title-text-sub">
                Product has been added successfully!
              </Typography>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className="alert-button-control">
        <Button
          className="font-17 text-black no-hover"
          onClick={() => handleClose('Discard')}
        >
          {isUpdated ? 'Close' : ' Discard Changes'}
        </Button>
        {!isUpdated && <Divider className="vertical-divider" />}
        {!isUpdated && (
          <Button
            className="font-17 no-hover"
            onClick={() => handleClose('Save')}
          >
            Go Back to Editing
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
