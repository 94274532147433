import React, { useEffect, useState } from 'react';
// Get the loader
import Loader from '../../../components/Loader';
import { ApiService } from 'src/services/ApiService';
import { getLocalData } from 'src/utils';
import Header from 'src/components/Header';
import { Container } from '@mui/material';

const MembershipReport = () => {
  // get the report url
  const [reportUrl, setReportUrl] = React.useState('');
  // check loader is true or false
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const isFranchisor = getLocalData('isFranchisor');
  const locationId = getLocalData('locationId');
  const franchiseeId = getLocalData('franchiseeId');

  useEffect(() => {
    getReport();
  }, []);

  // Get the report method
  const getReport = async () => {
    // Now show the loader
    setIsLoading(true);
    let params = {
      reportName: 'Membership_Report'
    };

    if (isFranchisor === 'true') {
      params.isFranchisor = true;
    } else {
      params.isFranchisor = false;
      params.franchiseeId = franchiseeId;
      params.locationId = locationId;
    }

    try {
      let response = await ApiService.post(
        `${process.env.REACT_APP_API_END_POINT}locations/_get-all-reports`,
        params
      );
      if (response.statusCode === 200) {
        let Items =
          response &&
          response?.data &&
          response?.data?.Items &&
          response?.data?.Items[0]
            ? response?.data?.Items[0]
            : [];
        // Set the list of teams
        let url = Items?.EmbedUrl;
        // console.log('Items--->', Items, url, isError);
        if (!url || url === 'NA') {
          setIsError(true);
        } else {
          setReportUrl(url);
          setIsError(false);
        }
        // Now hide the loader
        setIsLoading(false);
      } else {
        // Now hide the loader
        setIsLoading(false);
        setIsError(true);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.log(error?.message || 'Something went Wrong');
    }
  };
  return (
    <>
      <Loader IsLoading={isLoading} />
      <div className="container align-center">
        <Container maxWidth="100%" className="main-container">
          <Header title="" />
          {/* <iframe title="Reports" width="96%" height="960" src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/395188757585/dashboards/a9ab0bc0-4018-4918-b5d5-6d08b44fe427?directory_alias=zr" /> */}
          {isError ? (
            <h1>Membership report is not available.</h1>
          ) : (
            <iframe
              className="report-iframe"
              title="Reports"
              width="96%"
              height="960"
              src={reportUrl}
            />
          )}
        </Container>
      </div>
    </>
  );
};
export default MembershipReport;
