/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Divider
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const PurchaseFilters = ({ setFilters, filters, allPurchaseData }) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    orderTitle: false,
    supplierName: false,
    orderStatus: false
  });

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      orderTitle: field === 'orderTitle' ? !prevState.orderTitle : false,
      supplierName: field === 'supplierName' ? !prevState.supplierName : false,
      orderStatus: field === 'orderStatus' ? !prevState.orderStatus : false
    }));
  };

  const handleChangeFilter = (value, type) => {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  };

  const clearFilters = () => {
    setFilters({
      status: filters.status,
      orderTitle: null,
      supplierName: null,
      orderStatus: -1,
    });

    setOpenFilter({
      orderTitle: false,
      supplierName: false
    });
  };

  return (
    <>
      <Card className="filter-content custom-filter-fields">
        <Typography className="filter-label" pt={2} pb={3}>
          Status
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel>Status</InputLabel>
          {/* <Select
            value={filters.status ?? 1}
            onChange={(event) =>
              handleChangeFilter(event.target.value, 'status')
            }
            label="Status"
            className="status-field field-label-css"
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select> */}
          <Select
            value={filters.orderStatus ?? -1}
            onChange={(event) =>
              handleChangeFilter(event.target.value, 'orderStatus')
            }
            label="Order Status"
            className="status-field field-label-css"
          >
            <MenuItem value={-1}>All</MenuItem>
            <MenuItem value={'draft'}>Draft</MenuItem>
            <MenuItem value={'send'}>Send</MenuItem>
            <MenuItem value={'closed'}>Closed</MenuItem>
          </Select>
        </FormControl>

        <Divider />

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  color: '#003087',
                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid
          xs={12}
          onClick={() => handleOpenFilter('orderTitle')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.orderTitle && classes.closeY,
                    openFilter.orderTitle && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Order Title</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.orderTitle && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Order Title</InputLabel>
              <Select
                // multiple
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'orderTitle')
                }
                value={filters.orderTitle}
                sx={{
                  width: '100%'
                }}
                label="Order Title"
                className="status-field field-label-css"
                MenuProps={{
                  PaperProps: {
                      style: {
                          maxHeight: 224,
                          overflowY: 'auto',
                      },
                  },
              }}
              >
                <MenuItem value="None">None</MenuItem>

                {allPurchaseData?.length > 0 &&
                  [
                    ...new Set(
                      allPurchaseData.map((option) => option.orderTitle)
                    )
                  ]?.map((orderTitle) => (
                    <MenuItem id={orderTitle} value={orderTitle}>
                      {orderTitle}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )} */}

        <Grid
          item
          onClick={() => handleOpenFilter('supplierName')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.supplierName && classes.closeY,
                    openFilter.supplierName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Supplier Name</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.supplierName && (
          <Box marginTop={1}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Supplier Name</InputLabel>
              <Select
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'supplierName')
                }
                value={filters.supplierName}
                sx={{
                  width: '100%'
                }}
                label="Supplier Name"
                className="status-field field-label-css"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                {/* <MenuItem value="None">None</MenuItem> */}

                {allPurchaseData?.length > 0 &&
                  [
                    ...new Set(
                      allPurchaseData.map((option) => option.supplierName)
                    )
                  ]?.map((supplierName) => (
                    <MenuItem id={supplierName} value={supplierName}>
                      {supplierName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {/* <Grid
          item
          onClick={() => handleOpenFilter('orderStatus')}
          pt={0.5}
          sx={{ cursor: 'pointer' }}
        >
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.supplierName && classes.closeY,
                    openFilter.supplierName && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Order Status</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.orderStatus && (
          <Box marginTop={1}>
            <FormControl
              pt={2}
              fullWidth
              variant="outlined"
              sx={{
                width: '100%',
                marginBottom: '10px'
              }}
            >
              <InputLabel>Order Status</InputLabel>
              <Select
                value={filters.orderStatus ?? 1}
                onChange={(event) =>
                  handleChangeFilter(event.target.value, 'orderStatus')
                }
                label="Order Status"
                className="status-field field-label-css"
              >
                <MenuItem value={'draft'}>Draft</MenuItem>
                <MenuItem value={'send'}>Sent</MenuItem>
                <MenuItem value={'closed'}>Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )} */}
      </Card>
    </>
  );
};
