import React, { useState, useEffect } from 'react';
import { CustomTable } from '../../../components/CustomTable';
import { getLocalData } from '../../../utils';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
  } from '@mui/material';
import axios from 'axios';
import AddPricing from './AddPricing';
import GiftCardPricingDetail from './GiftCardPricingDetail';
import { GiftCardPricingFilter } from '../../../components/Filters/GiftCardPricingFilter';
import { filtersQuery, dateFormatWithSlash } from '../../../utils/index';
import { ActiveStatus, InactiveStatus, ExpiredStatus } from 'src/assets/SVG/SvgIcons';
import { ApiService } from '../../../services/ApiService';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';
import Loader from 'src/components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const GiftCardPricingTable = ({
  setShowScreen,
  setSelectedItem,
  selectedItem
}) => {
  const [tableData, setTableData] = useState([]);
  const [actualData, setActualData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [openAddPricing, setOpenAddPricing] = useState(false);
  const [openAddPricingModal, setOpenAddPricingModal] = useState(false);
  const [openDetailsPricing, setOpenDetailsPricing] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showSucessMessage, setShowSuccessMessage] = useState(false);
  const [sucessMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userId = getLocalData('userId');
  const userRole = getLocalData('userRole');

  const locationId = getLocalData('locationId');

  const handleClosePricingOptionDetails = () => {
    setShowScreen(0);
  };
  const navigate = useNavigate();
  useEffect(() => {
    // if (id) setSearchParams({ Id: id });
    // check if the user is logged in or not
    // let isLogin = getLocalData('Login_Sucess');
    // if (isLogin !== 'true') {
    //   navigate('/login');
    // }
  }, [GiftCardPricingTable, navigate]);
  const openGiftCardPricing = () => {
    setOpenAddPricing(true);
    setOpenAddPricingModal(true);
    setSelectedItem(null);
  };

  let [filters, setFilters] = useState({
    status: 1,
    giftCardPriceValue: '',
    price: ''
  });

  const getGiftCardPricing = async () => {
    let queryString = await filtersQuery(filters);
    let filtersParams = queryString ? `&${queryString}` : '';
    let encodedLocationId = encodeURIComponent(locationId);
    let response = await ApiService.get(
      `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/getAllGiftCardPrices?locationId=${encodedLocationId}${filtersParams}`
    );
    let Item =
      response && response.data && response.data.Items
        ? response.data.Items
        : [];
    setActualData(Item);
    const result = Item.map((obj) => {
      const {
        partitionKey,
        sortKey,
        giftCardPriceName,
        giftCardPriceValue,
        price,
        status,
        validThrough
      } = obj;
      return {
        partitionKey,
        sortKey,
        Title: giftCardPriceName,
        'Card Value': `$${parseFloat(giftCardPriceValue)?.toFixed(2)}`,
        Price: `$${parseFloat(price)?.toFixed(2)}`,
        Status: status === 1 ? <ActiveStatus /> : status === 3 ? <ExpiredStatus /> : <InactiveStatus />,
        'Valid through': validThrough ? dateFormatWithSlash(validThrough) : '-'
      };
    });
    setLoading(false);
    // setData(Item);
    setTableData(result);
    console.log(result);
  };
  
  useEffect(() => {
    getGiftCardPricing();
  }, [filters, openAddPricing, openDetailsPricing]);

  const handleOnClick = (value) => {
    console.log(value)
  if(userRole === 'Front Desk'){
    return;
   }
    let data = actualData?.find(
      // (item) => item?.sortKey === value?.value?.sortKey
      (item) => item?.sortKey === value[1]
    );
    setSelectedItem(data);

    if (value[2] === 'Detail') {
      setSelected(true);
      setOpenDetailsPricing(true);
    }
    if (value[2] === 'Edit') {
      setOpenAddPricing(true);
      setOpenAddPricingModal(true);
    }
    
  };

  const handleBulkAdd = async () => {
    console.log('handleBulkAdd');
    setIsUpdated(false);
    setOpen(true);
  };

  const confirmBulkUpdate = async () => {
    setOpen(false);
    setBtnLoading(true);
    setLoading(true);
    // show loader
    // setIsLoading(true);
    const data = {
      status: selectedStatus,
      updatedBy: userId,
      giftCardIds: selectedLists
    };

    try {
      const encodedLocationId = encodeURIComponent(locationId);
      const response = await axios.put(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}gift-cards/updateBulkStatus?locationId=${encodedLocationId}`,
        data
      );

      if (!response.data?.error) {
        setLoading(false);
        setShowSuccessMessage(true);
        setSuccessMessage('All the selected gift card pricings have been updated successfully!');
        setSelectedStatus('');
        setSelectedLists([]);
        setIsUpdated(true);
        // setOpen(true);
        setOpen(false);
      } else {
        setShowErrorMessage(true);
        setErrorMessage(response.data?.message || 'Something went wrong with update gift card status')

        // toast.error(
        //   response.data?.message ||
        //     'Something went wrong with update bundles status'
        // );
      }
      // show loader
      // setIsLoading(false);
      // setLoading(false);
      setBtnLoading(false);
    } catch (error) {
      console.error(error);
      setBtnLoading(false);
      // setLoading(false);
      // show loader
      // setIsLoading(false);
      // toast.error('Something went wrong, Try again!');
      setShowErrorMessage(true);
      setErrorMessage('Something went wrong, Try again!')
    }
  };

  // Method to handle close of dialog
  const handleClose = (val) => {
    if (val === 'Save') {
      setOpen(false);
      confirmBulkUpdate();
    } else if (val === 'Discard') {
      setOpen(false);
      if (isUpdated) {
        getGiftCardPricing();
        setIsUpdated(false);
      }
    }
  };

  const closePopup = () => {
    setShowSuccessMessage(false);
    getGiftCardPricing();
  };

  const handleDetailClick = (type) => {
    if (type !== false) {
      setShowScreen(1);
      setSelected(false);
    } else {
      setSelected(type);
      setOpenAddPricing(true);
      setOpenAddPricingModal(true);
    }
  };
  return (
    <>
      <Loader IsLoading={loading} />
      {selected ? (
        <GiftCardPricingDetail
          setShowScreen={handleDetailClick}
          selectedItem={selectedItem}
          // openDetailsPricing={openDetailsPricing}
          setOpenDetailsPricing={setOpenDetailsPricing}
        />
      ) : (
        <Container maxWidth="100%">
          <Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <Box
                onClick={handleClosePricingOptionDetails}
                className="back-arrow-close"
              >
                <svg
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.625 7.90885H5.96042L11.1812 2.67344L9.125 0.617188L0.375 9.36719L9.125 18.1172L11.1812 16.0464L5.96042 10.8255H26.625V7.90885Z"
                    fill="#003087"
                  />
                </svg>
              </Box>
              <Typography className="page-title" variant="h1">
                Gift Card Pricing
              </Typography>
              {showErrorMessage &&
                  <ErrorMessage message={errorMessage} setShowMessage={setShowErrorMessage} />
                }

                {/* Added one more callback function to identify when close the popup */}
                {showSucessMessage &&
                  <SuccessMessage message={sucessMessage} setShowMessage={setSuccessMessage} closePopup={closePopup} />
                }
              <Box sx={{ marginLeft: 'auto', marginRight: '40px', mt: 2 }}>
                <Button
                  variant="contained"
                  className="top-button-style"
                  onClick={openGiftCardPricing}
                >
                  Add New Gift Card Pricing
                </Button>
              </Box>
            </Box>
          </Grid>

          <CustomTable
            data={tableData}
            loading={loading}
            filterCompoenent={
              <GiftCardPricingFilter
                setFilters={setFilters}
                filters={filters}
              />
            }
            label="All Gift Card Pricing"
            handleOnClick={handleOnClick}
            selectedLists={selectedLists}
            setSelectedLists={setSelectedLists}
            open={open}
            setOpen={setOpen}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            handleBulkAdd={handleBulkAdd}
            btnLoading={btnLoading}
          />

          {openAddPricing ? (
            <AddPricing
              selectedItem={selectedItem}
              openAddPricingModal={openAddPricingModal}
              setOpenAddPricingModal={setOpenAddPricingModal}
              setOpenAddPricing={setOpenAddPricing}
            />
          ) : null}
        </Container>
      )}
       <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose('Cancel')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center">
          {isUpdated ? 'Congratulations!' : 'Activate/Deactivate Selected Items'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              {!isUpdated ? (
                <Typography className="title-text-sub">
                Are you sure you want to mark Activate/Deactivate,
                <Typography className="title-text-sub">
                all the selected items?
              </Typography>
              </Typography>
              ) : (
                <Typography className="title-text-sub">
                 All the selected gift card pricings have been updated successfully!
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 text-black no-hover"
            onClick={() => handleClose('Discard')}
          >
            {isUpdated ? 'Close' : 'Cancel'}
          </Button>
          {!isUpdated && <Divider className="vertical-divider" />}
          {!isUpdated && (
            <Button
              className="font-17 no-hover"
              onClick={() => handleClose('Save')}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GiftCardPricingTable;
