import React, { useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  FormHelperText,
  Grid,
  Typography
} from '@mui/material';

import SignaturePad from 'react-signature-canvas';
import { handleSave } from '../Functions/OrderSummaryFunctions';
import { useEffect } from 'react';

const SignatureDialog = ({
  open,
  signature,
  setSignature,
  handleClose,
  setLoading,
  componentConsts
}) => {
  const signatureCanvasRef = useRef(null);

  const [errors, setErrors] = useState({
    signatureCanvas: ''
  });

  useEffect(()=> {
    setErrors({
      ...errors,
      signatureCanvas: ''
    })
  },[handleClose])

  const handleSignature = () => {
    if (signature?.value) {
      setSignature({ ...signature, value: '' });
    } else {
      signatureCanvasRef.current.clear();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid
        container
        item
        sx={{
          width: '450px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          gap: 1.5
        }}
      >
        <Typography variant="h3" color="primary">
          {' '}
          Sign the Terms
        </Typography>
        {signature?.value ? (
          <img
            src={signature?.value}
            alt="my signature"
            style={{
              height: '130px',
              width: '100%',
              border: '2px solid rgba(0, 48, 135, 0.5)',
              borderRadius: '10px'
            }}
          />
        ) : (
          <SignaturePad
            canvasProps={{
              className: 'signatureCanvas',
              alt: 'Sign Here',
              style: {
                height: '130px',
                width: '100%',
                border: '2px solid rgba(0, 48, 135, 0.5)',
                borderRadius: '10px'
              }
            }}
            penColor={'#003087'}
            backgroundColor={'#fff'}
            ref={signatureCanvasRef}
          />
        )}

        {errors?.signatureCanvas && (
          <FormHelperText>{errors?.signatureCanvas}</FormHelperText>
        )}

        <Button
          sx={{
            border: '2px solid #003087 !important',
            color: '#003087 !important',
            display: 'inline-block'
          }}
          variant="outlined"
          className="btn-primary"
          onClick={handleSignature}
        >
          {' '}
          Clear{' '}
        </Button>
      </Grid>

      <DialogActions sx={{ padding: 2 }}>
        <Button variant="outlined" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={signature?.value}
          onClick={() =>
            handleSave(
              signatureCanvasRef,
              setSignature,
              setLoading,
              errors,
              setErrors,
              componentConsts
            )
          }
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureDialog;
