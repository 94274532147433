import React, { useState, useEffect, createContext } from 'react';

export const CheckoutContext = createContext({});

export const CheckoutProvider = ({ children }) => {
  // Initialize activeStep with the value from localStorage or default to 0
  const [activeStep, setActiveStep] = useState(
    parseInt(localStorage.getItem('activeStep')) || 0
  );

  // Use useEffect to watch for changes in activeStep and update localStorage
  useEffect(() => {
    localStorage.setItem('activeStep', activeStep.toString());
  }, [activeStep]);

  return (
    <CheckoutContext.Provider
      value={{
        activeStep,
        setActiveStep
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
