import React from 'react'
import { Button,CircularProgress } from "@mui/material";


export const DynamicButton=({style,variant,title,onClick,src,disabled=false,loading=false})=>{


  return (
    <>
      <Button
        type="button"
        variant={variant}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        {loading&&<CircularProgress />}
        {title}
        {src&&<img src={src} alt="cancel" />}

      </Button>
    </>
  )
}